<template>
    <b-nav vertical >
        <input type="hidden" v-model="refreshcount">
    <template :style="AppSecondayColor()" v-if="is_logged_in">
        <b-nav-item active-class="active" v-if="createAny('users') && user&&user.sign_contact==1" to="/tasks/2">
            <font-awesome-icon icon="hourglass-half" />{{ $t("labels.check_hours") }} {{ $t('labels.byzzpr') }}
        </b-nav-item>
        <b-nav-item active-class="active" v-if="createAny('users') &&  user&&user.sign_contact==1" to="/tasks/3">
            <font-awesome-icon icon="check" />{{ $t("buttons.hoursvalidated") }}  {{ $t('labels.byzzpr') }}
        </b-nav-item>        
        <b-nav-item  active-class="active" :style="AppSecondaryColor()" v-if="!createAny('users')" :to="locprofile">
            <div :style="AppSecondaryColor()"><font-awesome-icon  icon="user-circle" :style="AppSecondaryColor()" /> {{ $t("titles.profile") }}</div>
        </b-nav-item>            
        <b-nav-item active-class="active" v-if="createAny('users')" to="/users">
            <font-awesome-icon icon="users" /> {{ $t("titles.users") }}
        </b-nav-item>
        <b-nav-item  active-class="active" v-if="!createAny('users') && current_user && current_user.role == 'user'" to="/tasks/1">
            <div :style="AppSecondaryColor()"><font-awesome-icon icon="list" :style="AppSecondaryColor()" /> {{ $t("titles.tasks") }}</div>
        </b-nav-item>
        <b-nav-item  active-class="active" v-if="!createAny('users') && current_user && current_user.role != 'user'" to="/tasks/2">
            <div :style="AppSecondaryColor()"><font-awesome-icon icon="list" :style="AppSecondaryColor()" /> {{ $t("titles.tasks") }}</div>
        </b-nav-item>        
        <b-nav-item active-class="active"  v-if="current_user && current_user.role=='admin' && createAny('bedrijf')" to="/companies" >
            <font-awesome-icon icon="building" /> {{ $t("titles.companies") }}
        </b-nav-item>
        <b-nav-item active-class="active" v-if="current_user && (current_user.role=='user'&& current_user_active())"  to="/public_projects">
            <div :style="AppSecondaryColor()"><font-awesome-icon icon="hammer" :style="AppSecondaryColor()"/> {{ $t("titles.projects") }}</div>
        </b-nav-item>  
        <b-nav-item active-class="active" v-if="current_user && (current_user.role=='admin')"  to="/projects">
            <div :style="AppSecondaryColor()"><font-awesome-icon icon="hammer" :style="AppSecondaryColor()"/> {{ $t("titles.projects") }}</div>
        </b-nav-item>         
        <b-nav-item active-class="active" v-if="settings.EnableScheduler&& specialRights('URSchedule')"  to="/resources">
            <div :style="AppSecondaryColor()"><font-awesome-icon icon="screwdriver" :style="AppSecondaryColor()"/> {{ $t("titles.resources") }}</div>
        </b-nav-item>                  
        <b-nav-item active-class="active"  v-if="createAny('users')" to="/hours">
            <font-awesome-icon icon="hourglass-half" /> {{ $t("labels.check_hours") }}
        </b-nav-item>       
        <b-nav-item active-class="active" v-if="this.settings.ShowInvoicePurchaseNav && current_user&& current_user.role=='admin'" to="/invoices/purchase">
            <font-awesome-icon icon="file-invoice" />{{ $t("labels.purchase") }}{{ $t("titles.invoices").toLowerCase() }}
        </b-nav-item>   
        <b-nav-item active-class="active" v-if="this.settings.ShowInvoiceSaleNav &&current_user&& current_user.role=='admin'" to="/invoices/sale">
            <font-awesome-icon icon="file-invoice" /> {{ $t("labels.sale") }}{{ $t("titles.invoices").toLowerCase() }} 
        </b-nav-item>                      
        <b-nav-item active-class="active" v-if="this.settings.ShowPlanningNav && current_user&& current_user.role=='admin' && createAny('planning')" to="/planning">
            <font-awesome-icon icon="calendar" /> {{ $t("titles.planning") }}
        </b-nav-item>   
        <b-nav-item active-class="active" v-if="this.settings.ShowPlanningPerWeekNav && current_user&& current_user.role=='admin' && createAny('planning')" to="/planningperweek">
            <font-awesome-icon icon="calendar" /> {{ $t("titles.planning")  }} per week
        </b-nav-item>                               
        <b-nav-item active-class="active"  v-if="this.settings.ShowSearchNav && createAny('hourcodes')" to="/search">
            <font-awesome-icon icon="search" /> {{ $t("labels.search") }}
        </b-nav-item>    
        <b-nav-item active-class="active"  v-if="this.settings.ShowTodoNav &&  createAny('hourcodes')" to="/todo">
            <font-awesome-icon icon="check" /> {{  $t('titles.todo') }} {{ activeToDos }}
        </b-nav-item>             
        <b-nav-item active-class="active"  v-if="current_user&& current_user.role=='admin'" to="/admin">
            <font-awesome-icon icon="cog" /> {{ $t("labels.settings") }}
        </b-nav-item> 
        <b-nav-item active-class="active"  v-if="settings.EnableScheduler && specialRights('URSchedule')" to="/schedule">
            <font-awesome-icon icon="clipboard" />{{$t('titles.schedule')}}
        </b-nav-item>                    
   
        <b-nav-item :style="AppPrimaryColor()" v-if="current_user" active-class="active" @click="logout">
            <div :style="AppSecondaryColor()"><font-awesome-icon icon="sign-out-alt" :style="AppSecondaryColor()" /> {{ $t("titles.logout") }}</div>
        </b-nav-item>     

        <b-nav-item :style="AppPrimaryColor()" v-if="current_user" active-class="active" href="https://api.whatsapp.com/send?phone=31649365916">
            <div :style="AppSecondaryColor()"><b-img style="margin:0;margin-right:1.4rem;;width:20px;height:20px" src="../assets/whatsapp.png" alt="Flying Freelancers Support" id="logo" class="logo"  />Support</div>
        </b-nav-item> 
    </template>
    </b-nav>
</template>        

<script>

import { mapGetters, mapState, mapActions } from 'vuex'
require('dotenv').config()

export default {
    name: 'navigation',
    data() {
        return {
            jumpdate: "",
            isdev:false,
            refreshcount:0
        }
    },
    computed: {
    ...mapGetters([
      'createAny',
      'readAny',
      'updateAny',
      'deleteAny',
      'user_by_id',
      'is_logged_in',
      'todo'

    ]),
    ...mapState([
        'current_user',
        'users'
    ]),
    user(){
        if(this.current_user&&this.users){
            return this.user_by_id(this.current_user.id)
        } else{
            return {sign_contact:0}
       }
     },

    locprofile:{
        get(){
            return this.current_user?"/user/"+this.current_user.id:""
        },
        set(){}
    },
    activeToDos(){
        var today = new Date()
        var lastmonth = new Date()
        lastmonth.setMonth(lastmonth.getMonth()-1)
        let c = this.todo.filter(atd => new Date(atd.due_date)<=today && new Date(atd.due_date)>=lastmonth && atd.processed==0 &&(atd.creator_id==this.current_user.id || atd.notify_id == this.current_user.id))
        return c?"("+c.length+")":""

    }
  },
    methods: {
        ...mapActions([
            'logout',
            'resetPortal'
        ]),

    },
    components: {},
    created: function() {
        
    },
    watch: {

    users(){
    //   console.log(" nav users",this.users.length)
      this.refreshcount++
    }
  },    
}
</script>