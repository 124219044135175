<template>
  <b-modal id="BSSignWorkdayID" centered size="lg" @show="showMe">
    <template #modal-header>
      <h2 class="modal-title">{{ $t("buttons.sign") }} {{username_by_id(signature.user_id)}} {{ dateToNL(signature.workdate) }}</h2>
    </template>
    <div class="container">
      <dl v-if="settings.RegisterWorkhours && showhours">
        <dt>{{ $t('labels.workhoursstart') }}</dt>
        <dd>{{signature.workhoursstart}}</dd>
        <dt>{{ $t('labels.workhoursend') }}</dt>
        <dd>{{signature.workhoursend}}</dd>
        <dt>{{ $t('labels.workhourspause') }}</dt>
        <dd>{{signature.workhourspause}}</dd>
        <dt>{{ $t('labels.standardhours') }}</dt>
        <dd>{{normalhours}}</dd>
      </dl>
      <div class="form-row">
        <div class="col-4">
          {{ $t('labels.sign_status') }}
        </div>
        <div class="col">
          <b-form-input type=text class="form-control" v-model="signed_by" />
        </div>
      </div>
      <VueSignaturePad style="border:solid" 
          id="signature"
          width="100%"
          height="300px"

          ref="signaturePad"
        />
    </div>
    
    

<template #modal-footer>
  <button class="btn btn-secondary" v-if="!isEmpty()" @click="empty()">{{ $t('buttons.clear') }}</button>
  <button class="btn btn-secondary" v-if="!isEmpty() && signed_by!=''" @click="saveSignature()"><font-awesome-icon icon="signature" />{{ $t("buttons.sign") }}</button>

  <span v-if="isEmpty() && signature.id==0">{{$t('validation.signforagree')}}</span>
  <button class="btn btn-secondairy" @click="close_modal">{{ $t( "buttons.close") }}</button>
</template>

  </b-modal>
</template>


<script>
import axios from 'axios';
import { mapGetters,mapActions,  mapState } from 'vuex'

export default {
  name: 'signature-modal',
  data() {
    return {
      signed_by:"",
      filter:"",
      modalshown:false
    }
  },
  props:{
    signature:Object,
    normalhours:Number,
    showhours:Boolean
  },
  async mounted() {  
  },

  computed: {
    ...mapGetters([
    'user_photo_by_id',
    'username_by_id',
    'zzpers'

    ]),
    ...mapState([
      'users',
    ]),

  },
  methods: {
    ...mapActions([ 
    ]),
    signatureImage(){
      return "/api/media_signatures/"+this.signature.user_id+"-"+this.signature.workdate+"-"+this.signature.contract_id+".png"
    },
    isEmpty(){
      if(this.$refs.signaturePad){
        const { isEmpty } = this.$refs.signaturePad.saveSignature();
        return isEmpty;
      } else {
         return false
        }
    },
    empty(){
      this.$refs.signaturePad.clearSignature()

    },
    showMe(){
      this.signed_by = this.signature.signed_by
      setTimeout(this.prepareCanvas, 150)
    },
    prepareCanvas(){
      // var vm = this
      this.$nextTick(() => {
        this.$refs.signaturePad.resizeCanvas()
        this.$nextTick(() => {
        console.log("prep------------------canvas", this.signatureImage())
        if(this.signature.id!=0){
          fetch(this.signatureImage())
          .then((raw) => raw.blob())
          .then(blob => {
            const reader = new FileReader();
            // console.log(blob)
            // reader.addEventListener("loadend", ()=>{
            //   console.log("event", reader.result)
            // this.$refs.signaturePad.fromDataURL(reader.result)
            // });

            reader.onloadend = () => {
              this.$refs.signaturePad.fromDataURL(reader.result)
            }
            reader.readAsDataURL(blob)

          })
        }
        })
      })
    },
    async saveSignature(){
      const { data } = this.$refs.signaturePad.saveSignature();
      let clone = Object.assign({}, this.signature)
      clone.data = data
      clone.signed_by = this.signed_by
      clone.added_date = new Date().toUTCString()
      await axios.put('/api/workhourscheck/'+clone.id, clone)
      this.$store.commit('new_workhourcheck', {  id: 0,workhoursstart:null,workhoursend:null, workhourspause:0, signed_by:"" })
      this.$store.dispatch('get_signatures')
      this.close_modal()
    },
    close_modal() {

      this.$bvModal.hide('BSSignWorkdayID')
    },
  },
}    
</script>
