<template>
  <div class="popup">

    <div class="popup-header">
      {{user.firstname}} {{user.middlename}} {{user.lastname}}
      <div class="popup-close" @click="save_and_close">
        <font-awesome-icon icon="times" />
      </div>
    </div>

    <div class="popup-body">
      <b-container>
        <div class="form-row">
          <div class="col-4">

          </div>
          <div class="col-8">
            <h5 v-if='this.user.mobile!=undefined'>
              <font-awesome-icon icon="mobile" /> {{this.user.mobile}}
            </h5>
            <h5 v-if='this.user.phone!=undefined'>
              <font-awesome-icon icon="phone" /> {{this.user.phone}}
            </h5>
          </div>
        </div>
        <div class="form-row">
          <div class="col-4">
            <label for=""> {{ $t("labels.call") }}</label>
          </div>
          <div class="col-6">
            <b-form-input type="week" class="form-control" v-model="user.callat" />
          </div>
        </div>
        <div class="form-row">
          <div class="col-4">
            <label for=""> {{ $t("meta.remarks") }}</label>
          </div>
          <div class="col-8">
            <b-input-group>
              <b-form-textarea size="sm" rows="8" type="text" class="form-control" v-model="user.callbacknote"
                :ref="callbacknote" />
              <b-input-group-append v-if="createAny('users')">
                <b-button squared class="multi-append"  href="javascript:;"
                  @click="append_data_multi('callbacknote')">
                  <font-awesome-icon icon="plus-circle" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </b-container>
    </div>

    <div class="popup-footer">
      <a :href=phoneto(this.user.mobile) class="btn btn-primary">
        <font-awesome-icon icon="phone" /> {{$t('labels.call')}}
      </a>
      <div @click="save_and_close" class="btn btn-primary">{{ $t("buttons.saveandclose") }}</div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'user-call',
  data() {
    return {
      callbacknote: "callbacknote"
    }
  },
  props: {
    user_id: Number
  },

  computed: {
    user() {
      return this.user_by_id(this.user_id)
    },
    ...mapGetters([
      'user_metas',
      'user_by_id',
      'createAny',
      'append_data_string'

    ]),
  },
  methods: {
    save_and_close() {
      this.save_user(this.user.id)
      this.$emit('close')
    },
    ...mapActions([
      'save_user',
    ]),
    phoneto(id) {
      return "tel:" + id;
    },
    append_data_multi(key) {
      var current = this.user[key]
      this.user[key] = this.append_data_string + '\n' + current
      this.componentKey += 1;
      if (this.$refs[key]) {
        if (this.$refs[key][0]) {
          this.$refs[key][0].focus()
          this.$nextTick(() => {
            this.$refs[key][0].selectionEnd = 9
          })
        } else {
          this.$refs[key].focus()
          this.$nextTick(() => {
            this.$refs[key].selectionEnd = 9
          })
        }
      }
    },
  }
}
</script>