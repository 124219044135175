<template>
	<div class="popup">
        <div class="popup-header">
              {{$t('labels.subscribe_project')}} {{this.project_by_id(this.project_id).title}}
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
          <b-container fluid>        
            <div class="form-row" >
                <div class="col-3">
                    {{$t("titles.subject")}}
                </div>  
  
                <div class="col-8">
                    <b-form-input class="form-control" v-model="subject" />
                </div>               
            </div>                          
            <div id="app">
                <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
            </div>  
          </b-container>
        </div>
        <div class="popup-footer">
            <div @click="save_and_close" class="btn btn-primary">{{ $t("buttons.sendmessage") }}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
// const querystring = require("querystring");

export default {
    name: 'subscribe_to_project_modal',
    data() {
        return {
                editor: ClassicEditor,
                editorData: '<p></p>',
                editorConfig: {
        toolbar: {
          items:[ 'heading', '|', 'undo', 'redo', '-', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ,'insertTable'],
          shouldNotGroupWhenFull: true
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
          ]
        },
        table: {
            contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        }
      },
                subject:"",
                selected:[],
        }
    },
    props: {
        project_id: Number,
    },
    
    beforeMount() {
      this.subject = this.$i18n.t('labels.subscribe_project') + " " + this.project_by_id(this.project_id).title
      this.editorData = this.$i18n.t('labels.subscribe_project') + " " + this.project_by_id(this.project_id).title + ',<br>'+this.$i18n.t('labels.greetinx')+ ',<br>'+ this.username_by_id(this.current_user.id)
    },
    computed: {

 
        user() {
            return this.user_by_id(this.current_user.id)
        },          
       
        ...mapGetters([
            'planning_by_id',
            'project_by_id',
            'contract_by_id',
            'contracts_metas',
            'contract_new',
            'username_by_id',
            'user_by_id',
            'company_by_id',
            
        ]),
        ...mapState([
            'users',
            'settings',
            'contracts',
            'current_user',
            'documents'

        ])
    },
    methods: {
        save_and_close() {
            let email=this.user.email
            let name=this.user.fullname
            let message ={}
            message.email = "e.van.pinxteren@zzpr.nl"
            message.name = name
            message.message = this.editorData
            message.subject = this.subject
            message.cc = email
            try{
                axios.post('/api/interested', message)  .then((response) => {
                    console.log('succes',response)
                })
            } catch(err){
                console.log (err)
            }               
            this.$emit('close')
        },
        ...mapActions([

        ]),

    },
}
</script>