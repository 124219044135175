<template>
	<div class="popup">

        <div class="popup-header">
            <div>
                 {{ $t("labels.confirm") }}
            </div>
            <div  class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body" style="overflow:hidden">
          <b-container>
          <h4>{{ message }}</h4>
          <h5 style="color:#ff0000">{{ alert }}</h5>
          </b-container>
        </div>
        <div class="popup-footer">
            <div class="btn btn-primary" @click="$emit('close')">{{ $t("labels.no") }}</div>
            <div @click="save_and_close" class="btn btn-primary">{{ $t("labels.yes") }}</div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'confirm-modal',
    data() {
        return {
          confirmed:false
        }
    },
    props: {
        message: String ,
        alert: String
    },
    mounted() {
        this.$store.commit('modal_confirm',{ id: 0})
    },
    computed: {

    },
    methods: {
        save_and_close() {
          this.$store.commit('modal_confirm',{ id: 1})
          this.$emit('close')
        },
    }
}
</script>