<template>
    <div class="popup">

        <div class="popup-header">
            <div v-if="company.id > 0">
                {{ company.company_name }}
            </div>
            <div v-else>
                {{ $t("labels.newcompany") }}
            </div>
            <div v-if="company.id == 0" class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
            <div v-else class="popup-close" @click="cancel_edit">
                <font-awesome-icon icon="times" />
            </div>
        </div>

        <div class="popup-body">
            <input type="hidden" :key="componentKey" />
            <b-tabs vertical v-if="company_id != 0">
                <b-tab active>
                    <template v-slot:title>{{ $t("labels.general") }}</template>
                    <CompanyDefault v-bind:company_id="company_id" ref="default" @change="companycheck">
                    </CompanyDefault>
                </b-tab>
                <b-tab title="Werkgebieden">
                    <template v-slot:title>{{ $t("meta.administratie") }}</template>
                    <CompanySettings v-bind:company_id="company_id"></CompanySettings>
                </b-tab>
                <b-tab title="Werkgebieden">
                    <template v-slot:title>{{ $t("labels.workfields") }}</template>
                    <CompanyWorkfields v-bind:company_id="company_id"></CompanyWorkfields>
                </b-tab>
                <b-tab v-if="company.id != 0">
                    <template v-slot:title>{{ $t("labels.logo") }}</template>
                    <CompanyImage v-bind:company_id="company_id"></CompanyImage>
                </b-tab>
                <b-tab v-if="company.id != 0">
                    <template v-slot:title>{{ $t("labels.UserDocuments") }}</template>
                    <CompanyDocuments v-bind:company_id="company_id" ref="document"></CompanyDocuments>
                </b-tab>
                <b-tab>
                    <template v-slot:title>{{ $t("labels.UserTags") }}</template>
                    <CompanyTags v-bind:company_id="company_id"></CompanyTags>
                </b-tab>
            </b-tabs>
            <CompanyDefault v-else v-bind:company_id="company_id" ref="default" @change="companycheck"></CompanyDefault>

        </div>

        <div class="popup-footer">
            <div v-if="company.id == 0" class="btn btn-primary" @click="my_add_company(); ">{{ addcompanyText() }}</div>
            <div v-else @click="save_and_close" class="btn btn-primary">{{ addcompanyText() }}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import CompanyDefault from './settings-screens/CompanyDefault'
import CompanyWorkfields from './settings-screens/CompanyWorkfield'
// const querystring = require("querystring");

import CompanyTags from './settings-screens/CompanyTags'
import CompanyImage from './settings-screens/CompanyImage.vue'
import CompanySettings from './settings-screens/CompanySettings.vue'
import CompanyDocuments from './settings-screens/CompanyDocuments.vue'

export default {
    name: 'company-modal',
    data() {
        return {
            componentKey: 0
        }
    },
    props: {
        company_id: Number
    },
    mounted: function () {
        this.$nextTick(function () {
            this.componentKey++
        })
    },
    computed: {
        company() {
            return this.company_id > 0 ? this.company_by_id(this.company_id) : this.company_new
        },
        ...mapGetters([
            'companies_metas',
            'company_by_id',
            'opdrachtgevers',
            'activebranches',
            'activehourcategories',
            'company_new'
        ]),
        ...mapState([
            'companies',
            'branches',
            'current_user',
            'settings'
        ]),


    },
    methods: {
        translate(path) {
            return this.$i18n.t('meta.' + path.toLowerCase())
        },
        addcompanyText() {
            if (this.$refs.default && this.$refs.default.validated() == true) {
                if (this.company_id.id == 0) {
                    return this.$i18n.t("buttons.add")
                } else {
                    return this.$i18n.t("buttons.saveandclose")
                }
            } else {
                return this.$i18n.t("buttons.invalid")
            }
        },
        companycheck() {
            this.componentKey++
        },
        genselector(label, key) {
            let selector = "<select name='selector-";
            selector += label + "' class='form-control' v-model='" + this.company[key] + "' >";
            if (label.toLowerCase() == "waardering") {
                selector += "<option val='1'>*</option>";
                selector += "<option val='2'>**</option>";
                selector += "<option val='3'>***</option>";
                selector += "<option val='4'>****</option>";
                selector += "<option val='5'>*****</option>";
            }
            selector += "</select> ";
            return selector
        },
        save_and_close() {
            if (this.$refs.default.validated() == true) {
                this.save_company(this.company.id)
                this.$store.commit('new_company', { id: 0, owner_id: this.current_user.id })
                this.$emit('close')
                // this.update_debiteur(this.company.id);
            }
        },
        cancel_edit() {
            this.get_companies()
            this.$emit('close')

        },
        my_add_company() {
            if (this.$refs.default.validated() == true) {
                this.add_company(this.company)
                this.$store.commit('new_company', { id: 0, owner_id: this.current_user.id })
                this.$emit('close')
                //this.update_debiteur();
            }
        },

        ...mapActions([
            'save_company',
            'add_company',
            'get_companies'
        ]),

    },
    components: { CompanyTags, CompanyDefault, CompanyWorkfields, CompanyImage, CompanySettings, CompanyDocuments }


}
</script>