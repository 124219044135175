<template>
	<div class="popup">

        <div class="popup-header">
            <div>
                 {{ $t("labels.confirm") }}
            </div>
            <div  class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body" style="overflow:hidden">
          <b-container>
          <div class="row">
            <div class="col-3">{{$t('labels.user')}}</div>
            <div class="col">{{ username_by_id(user_id) }}</div>
          </div>
          <div class="row">
            <div class="col-3">{{$t('labels.project')}} gtrt</div>
            <div class="col">{{ project_by_id(project_id).title }}</div>
          </div>
          <div class="row">
            <div class="col">{{$t('labels.startdate')}} </div>
            <div class="col"><input type="date" v-model='initstart'></div>
            <div class="col">{{$t('labels.enddate')}} </div>
            <div class="col"><input type="date" v-model='initend'></div>
          </div>
          <div class="row">
            <div class="col">{{$t('meta.starttijd')}}</div>
            <div class="col"><input type="time" v-model='initstartt'></div>
            <div class="col">{{$t('meta.eindtijd')}}</div>
            <div class="col"><input type="time" v-model='initendt'></div>
          </div>
          <div  class = "row" v-for="(res, idx) in user_resource" :key="res+idx">
            <div class="col-2">{{$t('labels.resource')}}</div>
            <div class="col-5"><b-form-checkbox  v-model="resourcecheck[res.id]">{{$t('labels.planresource')}}</b-form-checkbox></div>
            <div class="col-5">{{ res.name }} - {{ res.identification }}</div>  
          </div>          
          <checkuserplan :user_id="user_id" :startdate="initstart" :enddate="initend" />

          </b-container>
        </div>
        <div class="popup-footer">
            <div class="btn btn-primary" @click="$emit('close')">{{ $t("buttons.cancel") }}</div>
            <div @click="save_and_close" class="btn btn-primary">{{ $t("buttons.saveandclose") }}</div>
        </div>
    </div>
</template>

<script>
import {  mapGetters, mapState, mapActions} from 'vuex'
import checkuserplan from './CheckUserPlanning.vue';

export default {
    name: 'dropuseronproject-modal',
    data() {
        return {
          confirmed:false,
          initstart:null,
          initend:null,
          initstartt:null,
          initendt:null,
          resource_id:null,
          resourcecheck:[]

        }
    },
    props: {
        project_id:Number,
        user_id: Number
    },
    mounted() {
        this.$store.commit('modal_confirm',{ id: 0})
        this.initstart = this.project.startdate
        this.initend = this.project.enddate
        this.initstartt = this.project.starttime
        this.initendt = this.project.endtime
    },
    computed: {
      ...mapGetters ([
      'zzpers',
      'project_by_id',
      'user_by_id',
      'username_by_id',
       'createAny'

  ]),
  project(){
    return this.project_by_id(this.project_id)
  },
  start_offset(){
    return Math.round(( new Date(this.initstart).getTime() - new Date(this.project.startdate).getTime())/86400000)
  },
  end_offset() {
    return Math.round(( new Date(this.initend).getTime() - new Date(this.initstart).getTime())/86400000)

  },
  user_resource(){
      return this.resources.filter(r=>r.user_id==this.user_id).filter(r => this.projectresourcesfromproject.filter(prt=>prt.resource_id==r.type&&prt.filled==0).length>0)
      
    },  
    projectresourcesfromproject(){
      return this.projectresources.filter(pr=>pr.project_id==this.project_id && pr.resource_id!=null)
    },         
  ...mapState ([
      'ffwax',
      'resources',
      'projectresources',


    ]), 
    },
    methods: {
      ...mapActions([
      'add_ffwax'
    ]),      
        save_and_close() {
          // var start_offset = this
          let ffwax = {id:0, user_id:this.user_id,project_id:this.project_id, resource_id:null, start_offset:this.start_offset,end_offset:this.end_offset, start_time:this.initstartt,end_time:this.initendt}
          this.add_ffwax(ffwax)
          for(var i=0; i<this.user_resource.length;i++){
            if(this.resourcecheck[this.user_resource[i].id]==true){
              let ffwax = {id:0, user_id:null,project_id:this.project_id, resource_id:this.user_resource[i].id, start_offset:this.start_offset,end_offset:this.end_offset, start_time:this.initstartt,end_time:this.initendt}
              this.add_ffwax(ffwax)
            }
          }          
          this.$emit('close')
        },

    },
    components :{checkuserplan}

}
</script>