<template>
  <div>
  <b-table sort-icon-left :items="user_projects" :fields="userfields" @row-clicked="onProjectClicked"
    :tbody-tr-attr="rowClass">

    <template #cell(pulldown)="row">
      <b-dropdown id="dropdown-dropup" dropup :text="$t('buttons.doaction')" variant="primary">
        <b-dropdown-item @click="generate_urenbonpdf(row.item.contract_id)">
          <font-awesome-icon icon="hourglass-half" /> {{ $t('labels.showurenbon') }}
        </b-dropdown-item>

        <b-button v-if="false" href="javascript:;" variant="primary" @click="generate_urenbonpdf(contract_id)"
                :title="$t('labels.showurenbon')">
                <font-awesome-icon icon="hourglass-half" /> {{ $t('labels.showurenbon') }}
            </b-button>        
        
        <b-dropdown-item @click="clone_contract(row.item)">
          <font-awesome-icon icon="clone" /> {{ $t('labels.duplicateopdracht') }}
        </b-dropdown-item>        
        <b-dropdown-item @click="delete_user_project(row.item)" :title="$t('labels.deleteuser')">
          <font-awesome-icon icon="trash" /> {{ $t('labels.deletecontract') }}
        </b-dropdown-item>
        <b-dropdown-item @click="generate_company_pdf(row.item.contract_id)">
          <font-awesome-icon icon="file-pdf" /> {{ $t('labels.showcontractcompany') }}
        </b-dropdown-item>
        <b-dropdown-item @click="generate_pdf(row.item)">
          <font-awesome-icon icon="file-pdf" /> {{ $t('labels.showcontract') }}
        </b-dropdown-item>

        <b-dropdown-item @click="sendConfirmModal(row.item.contract_id)">
          <font-awesome-icon icon="envelope-open" /> {{ $t('buttons.sendconfirm') }}
        </b-dropdown-item>
        <b-dropdown-item @click="sendContractModal(row.item.contract_id)">
          <font-awesome-icon icon="envelope-open" /> {{ $t('buttons.sendcontract') }}
        </b-dropdown-item>

        <b-dropdown-item @click="editContractModal(row.item.project_id, row.item.zzpr)">
          <font-awesome-icon icon="cog" /> {{ $t('labels.editcontract') }}
        </b-dropdown-item>
        <b-dropdown-item @click="showUserHours(row.item.contract_id)">
          <font-awesome-icon icon="clipboard-list" /> {{ $t('labels.showhours') }}
        </b-dropdown-item>

      </b-dropdown>
      <span v-if="current_user.id==1">{{ row.item.contract_id }}</span>
    </template>
    <template #cell(company_id)="row">
      <a href="javascript:;" @click="companyto(row.value)">{{ companyname_by_id(row.value) }}</a>
    </template>
    <template #cell(zzpr)="row">
      <img class="profile-photo" :src="profileto(row.item.zzpr)" />
    </template>
    <template #cell(zzprname)="row">
      {{ username_by_id(row.item.zzpr) }}
    </template>
    <template #cell(signid)="row">
      {{ username_by_id(row.item.signid) }}
    </template>
    <template #cell(zzprmobile)="row">
      <a :href=phoneto(row.value)>{{ row.value }}</a>

    </template>

  </b-table>
</div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import popup_modal from '../modals/PopupModal.vue'
import axios from 'axios'

export default {
  name: 'admin-project-overview',
  data() {
    return {
      selected_contractID:null,
      selected_week:-1,
      selected_year:2024
    }
  },
  props: {
    user_id: Number,
    show_archive: String
  },

  computed: {
    user: {
      get() {
        return this.user_id?this.user_by_id(this.user_id):null
      },
    },
    userfields() {
      return [
        { key: 'pulldown', label: this.$i18n.t('labels.actions') },
        { key: 'startdate', label: this.$i18n.t('labels.startdate'), sortable: true, sortDirection: 'desc' },
        { key: 'enddate', label: this.$i18n.t('labels.enddate'), sortable: true, sortDirection: 'desc' },
        { key: 'zzpr', label: this.$i18n.t('labels.photo') },
        { key: 'zzprname', label: this.$i18n.t('labels.name') },
        { key: 'zzprmobile', label: this.$i18n.t('meta.mobiel') },
        { key: 'company_id', label: this.$i18n.t('labels.bycompany'), sortable: true, sortDirection: 'desc' },
        // { key: 'signid', label:this.$i18n.t('labels.authorizedsignatory')},
        // { key: 'signmobile',label: this.$i18n.t('meta.mobiel')},
        { key: 'title', label: this.$i18n.t('labels.project'), sortable: true, sortDirection: 'desc' },
        { key: 'location', label: this.$i18n.t('labels.location'), sortable: true, sortDirection: 'desc' },
      ]
    },
    user_projects() {
      let myprojects = []
      var now = new Date();
      for (var i = 0; i < this.projects.length; i++) {
        if (this.user.role == "user") {
          if (this.projects[i].zzprusers.indexOf(this.user_id) != -1) {
            let aplanning = this.find_planning(this.projects[i].id, this.user_id)
            if (aplanning != null) {
              let row = {}
              row.id = this.projects[i].id
              row.project_id = this.projects[i].id
              row.company_id = this.projects[i].company_id
              row.planning_id = aplanning.id
              row.contract_id = this.projects[i].id?this.find_contract_id(this.projects[i].id, this.user_id):null
              // var count = 0
              if(row.contract_id) {
                this.contractHours(row.contract_id).forEach(weeks => {
                  if (!weeks.closed || weeks.closed && weeks.signed == 0) {
                    // count++
                  }
                })
                row.color = this.contract_by_id(row.contract_id).color != undefined ? this.contract_by_id(row.contract_id).color : '#eeeeee';
              }
              row.title = aplanning.project_name
              row.sortdate = aplanning.startdate
              row.startdate = this.formatDate(aplanning.startdate)
              row.enddate = this.formatDate(aplanning.enddate)
              row.location = aplanning.project_location
              row.zzpr = this.user.id
              row.zzprname = this.user.id
              row.zzprmobile = this.user_by_id(this.user.id).mobile
              row.signid = aplanning.sign_id
              row.signmobile = this.user_by_id(aplanning.sign_id).mobile
              if (now < this.plus1week(aplanning.enddate) || this.show_archive != "0") {
                myprojects.push(row)
              }
            }
          }
        } else {
          // find allcontracts per project
          for (var zzprinproject = 0; zzprinproject < this.projects[i].zzprusers.length; zzprinproject++) {
            let aplanning = this.find_planning(this.projects[i].id, this.projects[i].zzprusers[zzprinproject])
            if (aplanning != null && aplanning.sign_id == this.user.id) {
              let row = {}
              row.id = this.projects[i].id
              row.project_id = this.projects[i].id
              row.company_id = this.projects[i].company_id
              row.user_id = this.projects[i].zzprusers[zzprinproject]
              row.planning_id = aplanning.id
              row.contract_id = this.find_contract_id(this.projects[i].id, this.projects[i].zzprusers[zzprinproject])
              row.title = aplanning.project_name
              row.sortdate = aplanning.startdate
              row.startdate = this.formatDate(aplanning.startdate)
              row.enddate = this.formatDate(aplanning.enddate)
              row.location = aplanning.project_location
              row.zzpr = this.projects[i].zzprusers[zzprinproject]
              row.color = this.contract_by_id(row.contract_id).color != undefined ? this.contract_by_id(row.contract_id).color : '#eeeeee';

              if (now < new Date(aplanning.enddate) || this.show_archive != "0") {
                myprojects.push(row)
              }
            }
          }
        }
      }
      return this.sortprojects(myprojects)
    },
    thisweek() {
      return parseInt(this.getWeekNumber(new Date)[1])
    },


    ...mapGetters([
      'user_metas',
      'user_by_id',
      'activebranches',
      'user_new',
      'branche_by_id',
      'createAny',
      'planning_by_id',
      'contract_by_id',
      'project_by_id',
      'username_by_id',
      'find_planning',
      'find_contract_id',
      'user_photo_by_id',
      'server_url',
      'append_data_string',
      'confirm_modal',
      'company_by_id',
      'find_last_planning_user',
      'companyname_by_id'



    ]),
    ...mapState([
      'users',
      'current_user',
      'branches',
      'workfields',
      'avatars',
      'projects',
      'contracts',
      'hours',
      'companies',
      'planning'
    ]),
  },
  methods: {
    companyto(id) {
      this.$router.push({ path: '/company/' + id });
    },
    phoneto(id) {
      return "tel:" + id;
    },
    onProjectClicked(row) {
      if (this.createAny('users')) {
        this.$router.push({ path: '/project/' + row.id });
      }

    },
    rowClass(item) {
      return { "style": "background-color:" + item.color }
    },

    usershours(contract_id, user_id) {
      return this.hours.filter(obj => {
        return obj.user_id === user_id
      }).filter(obj2 => {
        return obj2.contract_id === contract_id
      })
    },
    sortprojects(myprojects) {
      let a = myprojects.sort((a, b) => (a.sortdate > b.sortdate) ? -1 : ((b.sortdate > a.sortdate) ? 1 : 0))
      return a
    },
    ...mapActions([
      'save_user',
      'add_user',
      'save_contract',
    ]),
    has_hours(contract_id) {
      let daysFilled = 0;
      for (var i = 0; i < this.hours.length; i++) {
        if (this.hours[i].contract_id == contract_id) {
          daysFilled++
        }
      }
      return daysFilled;

    },


    sign_by_zzpr(contract) {
      this.contract_by_id(contract.id).signed_zzpr = 1
      this.save_contract(contract.id);
    },


    delete_user_project(id) {
      this.selectedID = id.project_id
      let message = this.$i18n.t('labels.confirmdeletehead') + id.title + " " + this.$i18n.t('labels.confirmdeletetail')
      let alert = ""

      if(this.hours.filter(h => h.contract_id== id.contract_id).length>0){
        alert +=  this.$i18n.t('validation.contracthashours')

      }
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
        message: message,
        alert:alert
      }, {
        name: 'popup_modal',
        draggable: false,
        height: 'auto',
        width: '500px'
      }, {
        'before-close': this.modalCloseDeleteUserProject
      })

      this.$emit('close')
    },
    modalCloseDeleteUserProject() {
      if (this.confirm_modal.id == 1) {
        let project_id = this.selectedID
        var datatosend = { project_id: project_id, user_id: this.user.id }
        this.$store.dispatch('remove_project_relations', datatosend);
        let project = this.project_by_id(project_id);
        project.zzprusers.splice(project.zzprusers.indexOf(this.user.id), 1)
        let spliceplanning = -1
        for (var i = 0; i < this.planning.length; i++) {
          if (this.planning[i].user_id == this.user.id && this.planning[i].project_id == project_id) {
            spliceplanning = i
            this.$store.dispatch('delete_planning', this.planning[i].id);
            break
          }
        }
        if (spliceplanning != -1) {
          this.planning.splice(spliceplanning, 1)
        }

      }
    },
    async clone_contract(item) {
      // console.log(item)
      let contract = this.contract_by_id(item.contract_id)
      // first clone project
      let project_clone = Object.assign({}, this.project_by_id(this.planning_by_id(item.planning_id).project_id))
      project_clone.id = 0;
      project_clone.title = "(COPY) " + project_clone.title
      project_clone.is_public = 0
      project_clone.projectnumber = ""

      console.log("step 1", project_clone)
      await axios.put("/api/project/" + project_clone.id, project_clone).then(async (response) => {
          let project_id = response.data.id
          //add user to project
          var datatosend = { project_id: project_id, user_id: item.zzpr }
          this.$store.dispatch('save_project_relations', datatosend);
          console.log("step 2",datatosend)

          //create contract    
          let clone = Object.assign({}, this.planning_by_id(item.planning_id))
          clone.id = 0;
          clone.project_name = "(COPY) " + clone.project_name
          clone.contract_id = 0
          clone.project_id = project_id
          clone.registerdeclarations = contract.registerdeclarations
          ///call axois
          console.log("step 3",datatosend)
          await axios.put("/api/planning/" + clone.id, clone).then(async (response) => {
            let last_contract_id = response.data.contract_id
            clone.id = response.data.id
            console.log("step 4",clone)
            // console.log("step 4",contract)
            let colorset = {}
            colorset.id=last_contract_id
            colorset.color= contract.color
            console.log("step 5",colorset)

            await axios.post("/api/set_contract_color/",colorset)
            let count = 0
            if (contract.onregelmatig.length > 0) {
              for (const decla of contract.onregelmatig) {
                console.log("onregelmatig", decla)
                decla.contract_id = last_contract_id
                decla.zzper = decla.zzpr_amount
                decla.client = decla.client_amount
                await axios.put("/api/onregelmatig/", decla).then(() => {
                  count++
                  if (count == contract.onregelmatig.length) {
                    this.$store.dispatch('get_planning');
                    this.$store.dispatch('get_contracts');
                    this.$store.dispatch('get_projects');
                  }
                })
              }
            } else {
              this.$store.dispatch('get_planning');
              this.$store.dispatch('get_contracts');
              this.$store.dispatch('get_projects');
            }
          })
      })
    }
  },
  components: {},

}
</script>