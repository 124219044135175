<template>
  <b-container fluid>
    <input type="hidden" :key="componentKey" />
    <input type="hidden" :key="`tp`+ totalPending()" />
    <input type="hidden" :key="`tc`+ totalClosed()" />
    <input type="hidden" :key="`ts`+ totalSigned()" />
    <input type="hidden" :key="`stc`+ signerTotalClosed()" />
    <input type="hidden" :key="`sts`+ signerTotalSigned()" />

    <div v-if=" false && current_user && current_user.role == 'user'" class ="row navtask" >
      <div :style="isActive(tabindex==1)" :class="{ active: tabindex==1 }" class="col bottom-nav" @click="onTaskClicked(1)"><font-awesome-icon icon="list" />       <div class="counter" :style="AppCounter()">{{  }}</div></div>
      <div :style="isActive(tabindex==2)" :class="{ active: tabindex==2 }" class="col bottom-nav" @click="onTaskClicked(2)"><font-awesome-icon icon="hourglass-half" /> <div class="counter" :style="AppCounter()">{{totalClosed()}}</div></div>
      <div :style="isActive(tabindex==3)" :class="{ active: tabindex==3 }" class="col bottom-nav" @click="onTaskClicked(3)"><font-awesome-icon icon="check" /> <div class="counter" :style="AppCounter()">{{totalSigned()}}</div></div>
    </div>
    <div v-if="false && current_user && current_user.role != 'user'" class ="row navtask" >
      <div :class="{ active: tabindex==2 }" class="col bottom-nav" @click="onTaskClicked(2)"><font-awesome-icon icon="hourglass-half" /> <div class="counter">{{signerTotalClosed()}}</div></div>
      <div :class="{ active: tabindex==3 }" class="col bottom-nav" @click="onTaskClicked(3)"><font-awesome-icon icon="check" /> <div class="counter">{{signerTotalSigned()}}</div></div>
    </div>    
    <b-row
      v-if="current_user && (current_user.role == 'user' || current_user.role == 'contactpersoon' || current_user.role == 'opdrachtgever')">
      <b-col style="margin-top:20px">
        <h3 v-if="tabindex==1" :style="AppSecondaryColor()"> {{$t('labels.hoursstatusfill')}} </h3>
        <h3 v-if="tabindex==2 && current_user.role == 'user'" :style="AppSecondaryColor()"> {{$t('labels.hourswaiting')}} </h3>
        <h3 v-if="tabindex==2 && (current_user.role == 'contactpersoon' || current_user.role == 'opdrachtgever')" :style="AppSecondaryColor()"> {{$t('labels.hourstocheck')}} </h3>
        <h3 v-if="tabindex==3" :style="AppSecondaryColor()"> {{$t('labels.hoursstatuschecked')}} </h3>
      </b-col>
    </b-row>
    <b-row v-if="current_user && current_user.role == 'user'">
      <UserProjectsOverview @totalsdone="totalsdoneuser" ref="userprojectsoverview" :user_id="current_user.id" :show_archive="show_archive" :index="tabindex" />
    </b-row>
    <b-row v-if="current_user && current_user.role != 'user'">
      <!-- tekenbevoegde -->
      <SignerProjectsOverview @totalsdone="totalsdonesigner" ref="signerprojectsoverview" :user_id="current_user.id" :show_archive="show_archive" :index="tabindex" />
    </b-row>
    <b-row style="height:300px"></b-row>

  </b-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import UserProjectsOverview from './parts/UserProjectsOverview.vue'
import SignerProjectsOverview from './parts/SignerProjectsOverview.vue'

export default {
  name: 'user-taks',
  data() {
    return {
      show_archive: "1",
      tabindex:1, 
      componentKey:0
    }
  },
  mounted() {
    this.tabindex = parseInt(this.$route.params.id)
    this.$nextTick(() => {
        this.componentKey++

      })
  },
  beforeRouteUpdate (to, from, next) {
    this.tabindex = parseInt(to.params.id);
    next();
  },  
  computed: {
    ...mapGetters([
      'user_metas',
      'user_by_id',
    ]),
    ...mapState([
      'current_user',
      'hours'
    ]),

  },
  methods:{
    onTaskClicked(id) {
      this.$router.push({ path: '/tasks/' + id });
    },     
    totalPending() {
      let tp = this.$refs.userprojectsoverview?this.$refs.userprojectsoverview.totalPending:0
      this.$store.commit('totalPending',tp)
      return tp
    },
    totalSigned(){
      let ts =  this.$refs.userprojectsoverview?this.$refs.userprojectsoverview.totalSigned:0
      this.$store.commit('totalSigned',ts)
      return ts
    },
    totalClosed(){
      let tc = this.$refs.userprojectsoverview?this.$refs.userprojectsoverview.totalClosed:0
      this.$store.commit('totalClosed',tc)
      return tc      
    },
    signerTotalClosed() {
      let tc = this.$refs.signerprojectsoverview?this.$refs.signerprojectsoverview.totalClosed:0
      this.$store.commit('signerTotalClosed',tc)
      return tc  
    },
    signerTotalSigned(){
      let ts = this.$refs.signerprojectsoverview?this.$refs.signerprojectsoverview.totalSigned:0
      this.$store.commit('signerTotalSigned',ts)
      return ts  
    },
    totalsdoneuser(){
      if(this.$refs.userprojectsoverview){
        this.componentKey++
      }
    },
    totalsdonesigner(){
      if(this.$refs.signerprojectsoverview){
        this.componentKey++
      }
    }    
  },
  components: { UserProjectsOverview, SignerProjectsOverview},
  watch: {
    hours: function () {
      this.flushContractHours();
      this.totalClosed()
      this.totalPending()
      this.totalSigned()
      this.signerTotalClosed()
      this.signerTotalSigned()
    },
    settings: function(){
      this.componentKey++
    }
  }
}
</script>         