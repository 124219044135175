<template>

  <b-container fluid  :style="AppSecondaryColor()" class="apppage">
    <b-button-group vertical>
      <b-button :style="AppPrimaryButton()" active-class="active" @click="$router.go(-1)">
        <font-awesome-icon icon="chevron-left" /> {{ $t("titles.newproject") }}
      </b-button>
    </b-button-group>
    <div v-html="settings.newprojectpage"/> 
    <form v-if="!send" class="form" @submit="onSubmit">
      <div class="form-row">
        <div class="col-3">
          <label for=""> {{ $t("labels.companyname") }}</label>
        </div>
        <div class="col">
          <b-form-input type="text" class="form-control" v-model="companyname" />
        </div>
      </div>    
      <div class="form-row">
        <div class="col-3">
          <label for=""> {{ $t("meta.woonplaats") }}</label>
        </div>
        <div class="col">
          <b-form-input type="text" class="form-control" v-model="city" />
        </div>
      </div>    
      <div class="form-row">
        <b-form-group>
          <b-form-radio v-model="sex" value="man">{{ $t("meta.aanhefman") }}</b-form-radio>
          <b-form-radio v-model="sex" value="vrouw">{{ $t("meta.aanhefvrouw") }}</b-form-radio>
        </b-form-group>
      </div>
      <div class="form-row">
        <div class="col-3">
          <label for=""> {{ $t("labels.name") }}</label>
        </div>
        <div class="col">
          <b-form-input type="text" class="form-control" v-model="name" />
        </div>
      </div>    
      <div class="form-row">
        <div class="col-3">
          <label for=""> {{ $t("labels.email") }}</label>
        </div>
        <div class="col">
          <b-form-input type="email" class="form-control" v-model="email" />
        </div>
      </div>    
      <div class="form-row">
        <div class="col-3">
          <label for=""> {{ $t("tableheaders.mobile") }}</label>
        </div>
        <div class="col">
          <b-form-input type="tel" class="form-control" v-model="mobile" />
        </div>
      </div>              
      <div class="form-row">
        <div class="col-3">
          <label for=""> {{ $t("labels.description") }}</label>
        </div>
        <div class="col">
          <textarea class="form-control" v-model="remarks" />
        </div>
      </div>          
      <div class="form-buttons">
        <b-button :style="AppPrimaryButton()"  type="submit" variant="primary">
          {{ $t('buttons.sendmessage') }} <font-awesome-icon icon="arrow-right" />
        </b-button>
      </div>

    </form>
    <div v-if="send" v-html="settings.newprojectthankyou">
    </div>    
  </b-container>
</template>
<script>
import axios from 'axios';
import {  mapState } from 'vuex'

export default {
  name: 'newproject',
  data() {
    return {
      sex : "man",
      name:"",
      companyname:"",
      remarks:"",
      city:"",
      email:"",
      mobile:"",
      send:false
    }
  },
  mounted() {

  },
  computed: {
    
    ...mapState([
      'settings',
    ]),

  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let data = {}
      data.Companyname = this.companyname
      data.City = this.city
      data.Sex = this.sex
      data.Name = this.name
      data.Email = this.email
      data.Mobile = this.mobile
      data.Message = this.remarks
      let message ={}
      message.message = "New project request:<br>";
      var key
      for (key in data) {
        message.message += key + " : <strong> " + data[key]+"</strong><br>"
      }

      message.subject = "New project from app"
      try{
          axios.post('/api/app_contact', message)  .then((response) => {
              console.log('succes',response)
              this.send = true

          })
      } catch(err){
          console.log (err)
      }          
    },        
  },
}
</script>
