<template>
  <b-modal id="BSSetinvoiceDataID" centered size="lg" @show="fillLocal" @hide="clearlocal">
    <template #modal-header>
      <h2 class="modal-title">{{ $t("labels.invoice") }} {{ username_by_id(contract_by_id(factuur.contract_id).user_id) }} {{ $t("labels.week") }} {{ factuur.week }}</h2>
      {{ factuur_id }}
    </template>
    
    <form class="form" v-if="(factuur_id!=0)">
      <div class="form-row">
        <div class="col-3">
          <label for=""> {{ $t("labels.invoice_number") }}</label>
        </div>
        <div class="col">
          <b-form-input  class="form-control" v-model="facnuminkoop"  />
        </div>
      </div>
      <div class="form-row">
        <div class="col-3">
          <label for=""> {{ $t("labels.invoice_date") }}</label>
        </div>
        <div class="col">
          <b-form-input type="date" class="form-control"  v-model="senddate"  />
        </div>
      </div>
    </form>
    <template #modal-footer>
      <div @click="saveData" class="btn btn-primary">
       {{ $t("buttons.saveandclose") }}
      </div>
    </template>
  </b-modal>
</template>


<script>
import { mapGetters,mapActions,  mapState } from 'vuex'
import axios from 'axios'

export default {
  name: 'invoice-data-modal',
  data() {
    return {
      facnuminkoop: null,
      senddate: null,
    }
  },
  props: {
    factuur_id: Number,
  },
  computed: {
    ...mapGetters([
      'server_url',
      'factuur_by_id',
      'contract_by_id',
      'username_by_id'
    ]),
    ...mapState([
      'users',
      'branches',
      'avatars',
      'urenbonnen',
    ]),
    factuur() {
        return this.factuur_id==0?null:this.factuur_by_id(this.factuur_id)
    },
  },
  methods: {
    ...mapActions([ 
    ]),
    fillLocal() {
      if(this.factuur_id!=0) {
        this.facnuminkoop = this.factuur.facnuminkoop
        this.senddate = this.factuur.senddate
      }

    },
    clearlocal(){
      this.senddate = null
      this.facnuminkoop = null
    },
    async saveData(){
      this.factuur.senddate = this.senddate
      this.factuur.facnuminkoop = this.facnuminkoop
      await axios.post('/api/set_manual_invoice',{id:this.factuur_id, senddate:this.senddate, facnuminkoop:this.facnuminkoop})
      let sendinvoice = {}
      sendinvoice.id = this.factuur.id
      sendinvoice.user_id = this.contract_by_id(this.factuur.contract_id).user_id
      sendinvoice.contract_id = this.factuur.contract_id
      sendinvoice.week = this.factuur.week
      // sendinvoice.year = this.yearnumber
      sendinvoice.force = true
      sendinvoice.hourcode = this.factuur.hourcode?parseInt(this.factuur.hourcode):null
      sendinvoice.dates = this.workdaysincontractweek(this.factuur.contract_id, this.factuur.week)
      await axios.post('/api/generate_inkoop', sendinvoice)
      
      this.close_modal()
    },

    close_modal() {

      this.$bvModal.hide('BSSetinvoiceDataID')
    },
  },
  watch:{
    factuur_id(){
      this.fillLocal()
    }
  }
}    
</script>
