<template>
  <b-container v-if="createAny('bedrijf')" fluid>
    <input type="hidden" :key="componentKey" />

    <div class="title-bar">
      <h1>{{ company.company_name }}</h1>
      <b-button variant="primary" @click="editCompanyModal(company)">
        <font-awesome-icon icon="cog" /> {{ $t("labels.edit") }}
      </b-button>
    </div>
    <b-row v-if="createAny('users')">
      <b-col :style="statusColor" cols="4" class="text-center">
        <h5> {{ $t("meta.extra") }} {{ companystatus() }}</h5>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        {{ $t("labels.client") }}
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3">
        <img style="height: 15rem;width: 15rem;object-fit: contain;"
          :src="companyimageto(this.company.id)" />
      </b-col>
      <b-col>
        <h3>{{ $t("meta.address") }}</h3>
        <p>
          {{ company.adres }} {{ company.housenumber }}<br />
          {{ company.postcode }} {{ company.city }} <br />
          {{ company.province }} {{ company.country }}<br />
        </p>
      </b-col>
      <b-col>
        <h3>{{ $t("labels.contact") }}</h3>
        <p>
          <a :href=mailto(this.company.email)>
            <font-awesome-icon icon="envelope" /> {{ company.email }}
          </a><br />
          <a :href=phoneto(this.company.phone)>
            <font-awesome-icon icon="phone" /> {{ company.phone }}
          </a><br />
          <a :href=this.company.website v-bind:target="'_blank'">
            <font-awesome-icon icon="globe" /> {{ company.website }}
          </a><br />

        </p>
      </b-col>
      <b-col v-if="createAny('users')">
        <h3>{{ $t("labels.scores") }}</h3>
        <b-row>
          <b-col cols="4">{{ $t("meta.waardering") }}</b-col>
          <b-col cols="7">{{ company.rating }}</b-col>
          <b-col cols="4">{{ $t("labels.addedby") }}</b-col>
          <b-col cols="7">{{ this.username_by_id(company.owner_id) }}</b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="filter-bar">
      <b-col>
        {{ $t('labels.note') }}
        <b-input-group v-if="createAny('users')">
          <b-form-textarea size="sm" rows="8" v-bind:type="remarks" v-bind:id="'bind-remarks'" :ref="remarks"
            class="form-control" v-model="company['remarks']" @blur="savecompany()"/>
          <b-input-group-append>
            <b-button class="multi-append" squared href="javascript:;"
              @click="append_data_multi('remarks')">
              <font-awesome-icon icon="plus-circle" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <pre v-else>{{ company['remarks'] }}</pre>
        <a href="javascript:;" v-if="createAny('bedrijf')" class="action-item" @click="savecompany()">
          <font-awesome-icon icon="save" />
        </a>
        <h3 v-if="userblock.length>0">{{ $t("labels.blockingcompanies") }}</h3>
        <div v-for="(com, index) in userblock" :key="index">
          <a v-if="current_user.role == 'admin'" @click="show_user(com.id)" class="link-item"
                style="cursor:pointer"> {{ username_by_id(com.id) }} </a>
          </div>
      </b-col>
      <b-col>
        <b-form-group :label="$t('labels.branche')">
          <span v-html="this.companybrancheName()"></span>
        </b-form-group>
      </b-col>
      <b-col>
        <h3>{{ $t("labels.UserDocuments") }}</h3>
        <b-table  :items="sorteddocs()" :fields="docfields">
          <template #cell(link)="row">
            <strong v-if="row.item.required==1" style="color:red">! </strong>

            <a :href="docto(row.value)" v-bind:target="'_blank'">{{ row.item.name }}</a>
          </template>

        </b-table>
      </b-col>
    </b-row>

     <b-row>
      <b-col>
        <h3 style="cursor:pointer" @click="showProjects=!showProjects">{{ $t("titles.projects") }} 
        <font-awesome-icon v-if="showProjects" icon="folder-open" /> 
        <font-awesome-icon v-else icon="folder" /> </h3>
      </b-col>
      <b-col v-if="showProjects">
        <b-form-group :label="$t('labels.showproject')">
          <b-form-checkbox v-model="only_public" name="checkbox-1" value="1" unchecked-value="0"></b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="showProjects">
      <b-col>
        <b-table sort-icon-left :items="company_projects" :fields="projectfields" @row-clicked="onProjectClicked">
          <template #cell(pulldown)="row">
            <b-dropdown id="dropdown-dropup" dropup :text="$t('buttons.doaction')" variant="primary">
              <b-dropdown-item v-if="row.item.zzprusers  && row.item.zzprusers.length==0" @click="confirm_delete_project(row.item.id)">
                <font-awesome-icon icon="trash" /> {{ $t('labels.deleteproject') }}
              </b-dropdown-item>
              <b-dropdown-item @click="edit_project(row.item)">
                <font-awesome-icon icon="cog" /> {{ $t('labels.editproject') }}
              </b-dropdown-item>
              <b-dropdown-item @click="clone_project(row.item)">
                <font-awesome-icon icon="clone" /> {{ $t('labels.duplicate') }}
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
      </b-col>
    </b-row>
    <b-row v-if="showProjects" align-h="end">
      <b-col cols="4">
        <b-button variant="primary" @click="edit_project({ id: 0 })">
          <font-awesome-icon icon="plus" /> {{ $t("buttons.addproject") }}
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h3 style="cursor:pointer" @click="(showZZPer=!showZZPer)">{{ $t("labels.ZZPersinproject") }} 
        <font-awesome-icon v-if="showZZPer" icon="folder-open" /> 
        <font-awesome-icon v-else icon="folder" /></h3>
      </b-col>
      <b-col v-if="showZZPer">
        <b-form-group :label="$t('labels.active')">
          <b-form-checkbox v-model="only_active" name="checkbox-1" value="1" unchecked-value="0"></b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="(this.company_zzprs.length > 0  && showZZPer)">
      <b-col>
        <b-table sort-icon-left :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
          :items="company_zzprs" :fields="zzprfields" @row-clicked="onUserClicked" :tbody-tr-attr="rowClassZZPR">
          <template #cell(photo)="row">
            <img class="profile-photo" :src="profileto(row.item.id)" />
          </template>
          <template #cell(lastname)="row">
            {{ username_by_id(row.item.id) }}
          </template>             
          <template #cell(active)="row">
            <font-awesome-icon v-if="row.item.active" icon="check" style="color: green;" />
            <font-awesome-icon v-else icon="times" style="color: red;" />
          </template>
          <template #cell(pulldown)="row">
            <b-dropdown id="dropdown-dropup" dropup :text="$t('buttons.doaction')" variant="primary">
              <b-dropdown-item @click="generate_company_pdf(row.item.contract_id)">
                <font-awesome-icon icon="file-pdf" /> {{ $t('labels.showcontractcompany') }}
              </b-dropdown-item>
              <b-dropdown-item @click="generate_pdf(row.item)">
                <font-awesome-icon icon="file-pdf" /> {{ $t('labels.showcontract') }}
              </b-dropdown-item>
              <b-dropdown-item @click="sendConfirmModal(row.item.contract_id)">
                <font-awesome-icon icon="envelope-open" /> {{ $t('buttons.sendconfirm') }}
              </b-dropdown-item>
              <b-dropdown-item @click="sendContractModal(row.item.contract_id)">
                <font-awesome-icon icon="envelope-open" /> {{ $t('buttons.sendcontract') }}
              </b-dropdown-item>
              <b-dropdown-item
                @click="editContractModal(contract_by_id(row.item.contract_id).project_id, contract_by_id(row.item.contract_id).user_id)">
                <font-awesome-icon icon="cog" /> {{ $t('labels.editcontract') }}
              </b-dropdown-item>
              <b-dropdown-item @click="showUserHours(row.item.contract_id, thisweek)">
                <font-awesome-icon icon="clipboard-list" /> {{ $t('labels.showhours') }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3 style="cursor:pointer" @click="(showContacts=!showContacts)">{{ $t("labels.contactpersons") }} 
        <font-awesome-icon v-if="showContacts" icon="folder-open" /> 
        <font-awesome-icon v-else icon="folder" /> </h3>
      </b-col>
    </b-row>
    <b-row v-if="showContacts">
      <b-col>
        <b-table :tbody-tr-class="rowClass" sort-icon-left :items="contactpersons_in_company" :fields="fields" @row-clicked="onUserClicked" :sort-by.sync="cpsortBy" :sort-desc.sync="cpsortDesc" :sort-direction="cpsortDirection">
          <template #cell(branche_id)="row">
            <span v-html='brancheName(row.item.id)'></span>
          </template>
          <template #cell(photo)="row">
            <img class="profile-photo" :src="profileto(row.item.id)" />
          </template>
          <template #cell(email)="row">
            <a :href=mailto(row.value)>{{ row.value }}</a>
          </template>
          <template #cell(mobile)="row">
            <a :href=phoneto(row.value)>{{ row.value }}</a>
          </template>
          <template #cell(roles)="row">
            <font-awesome-icon class="table-contacts-roles" 
              v-if="(row.item.role == 'contactpersoon' || row.item.role == 'opdrachtgever') && row.item.company_contact == '1'"
              icon="address-book" :title="$t('labels.company_contact')" />
            <font-awesome-icon class="table-contacts-roles" 
              v-if="(row.item.role == 'contactpersoon' || row.item.role == 'opdrachtgever') && row.item.document_contact == '1'"
              icon="file" :title="$t('labels.document_contact')" />
            <font-awesome-icon class="table-contacts-roles" 
              v-if="(row.item.role == 'contactpersoon' || row.item.role == 'opdrachtgever') && row.item.invoice_contant == '1'"
              icon="euro-sign" :title="$t('labels.invoice_contant')" />
            <font-awesome-icon class="table-contacts-roles" 
              v-if="(row.item.role == 'contactpersoon' || row.item.role == 'opdrachtgever') && row.item.sign_contact == '1'"
              icon="pencil-alt" :title="$t('labels.sign_contact')" />
            <font-awesome-icon class="table-contacts-roles" 
              v-if="(row.item.role == 'contactpersoon' || row.item.role == 'opdrachtgever') && row.item.location_contact == '1'"
              icon="street-view" :title="$t('labels.location_contact')" />
          </template>
          <template #cell(pulldown)="row">
            <b-dropdown  id="dropdown-dropup" dropup :text="$t('buttons.doaction')" variant="primary">
              <!-- toggle user roles -->
              <b-dropdown-item  v-if="row.item.company_contact == '1'" @click="company_contact(row.item)">
                <font-awesome-icon icon="address-book" style="color:#2ecc71"/> {{ $t('labels.company_contact') }}
              </b-dropdown-item>
              <b-dropdown-item  v-else @click="company_contact(row.item)"> 
                <font-awesome-icon icon="address-book" style="color:#e74c3c" /> {{ $t('labels.company_contact') }}
              </b-dropdown-item>   
              <b-dropdown-item  v-if="row.item.document_contact == '1'" @click="document_contact(row.item)">
                <font-awesome-icon icon="file" style="color:#2ecc71"/> {{ $t('labels.document_contact') }}
              </b-dropdown-item>
              <b-dropdown-item  v-else @click="document_contact(row.item)">
                <font-awesome-icon icon="file" style="color:#e74c3c" /> {{ $t('labels.document_contact') }}
              </b-dropdown-item>    

              <b-dropdown-item  v-if="row.item.invoice_contant == '1'" @click="invoice_contant(row.item)">
                <font-awesome-icon icon="euro-sign" style="color:#2ecc71"/> {{ $t('labels.invoice_contant') }}
              </b-dropdown-item>
              <b-dropdown-item  v-else @click="invoice_contant(row.item)">
                <font-awesome-icon icon="euro-sign" style="color:#e74c3c" /> {{ $t('labels.invoice_contant') }}
              </b-dropdown-item>                               

              <b-dropdown-item  v-if="row.item.sign_contact == '1'" @click="sign_contact(row.item)">
                <font-awesome-icon icon="pencil-alt" style="color:#2ecc71"/> {{ $t('labels.sign_contact') }}
              </b-dropdown-item>
              <b-dropdown-item  v-else @click="sign_contact(row.item)">
                <font-awesome-icon icon="pencil-alt" style="color:#e74c3c" /> {{ $t('labels.sign_contact') }}
              </b-dropdown-item>                               

              <b-dropdown-item  v-if="row.item.location_contact == '1'" @click="location_contact(row.item)">
                <font-awesome-icon icon="street-view" style="color:#2ecc71"/> {{ $t('labels.location_contact') }}
              </b-dropdown-item>
              <b-dropdown-item  v-else @click="location_contact(row.item)">
                <font-awesome-icon icon="street-view" style="color:#e74c3c" /> {{ $t('labels.location_contact') }}
              </b-dropdown-item>                               

              <b-dropdown-item @click="delete_user(row.item.id)">
                <font-awesome-icon icon="trash" /> {{ $t('labels.deletecontact') }}
              </b-dropdown-item>              
              <b-dropdown-item @click="editUserModal(row.item.id)">
                <font-awesome-icon icon="cog" /> {{ $t('labels.edituser') }}
              </b-dropdown-item>

            </b-dropdown>
          </template>
        
        </b-table>
      </b-col>
    </b-row>
    <b-row align-h="end" v-if="showContacts">
      <b-col v-if="contactpersonsnotincompany.length > 0" cols="6">
        <b-form-group :label="$t('labels.addcontactperson')">
          <v-select label="fullname" :options="vselcontactpersonsnotincompany" class="contactpersoon"
            v-model="selectedcontactpersoon"></v-select>
        </b-form-group>
        <b-button v-if="selectedcontactpersoon != null" variant="primary" @click="save_contactperson()">
          <font-awesome-icon icon="plus" /> {{ $t("labels.addcontactperson") }}
        </b-button>

      </b-col>
      <b-col>
        <div @click="new_contact({ id: 0 })" class="btn btn-primary">
          <font-awesome-icon icon="plus" /> {{ $t("labels.newcontact") }}
        </div>
      </b-col>
    </b-row>    
    <a v-if="false" href="javascript:;" class="action-item" @click="generate_combined_pdf()" title="combined factuur">
      <font-awesome-icon icon="file-pdf" />
    </a>
  </b-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import popup_modal from './modals/PopupModal.vue'
import new_contact_modal from './modals/NewContactpersonModal'
import axios from 'axios'

export default {
  name: 'companyscreen',
  data() {
    return {
      selectedcontactpersoon: null,
      selectedID: null,
      remarks: "remarks",
      componentKey: 0,
      only_public: "1",
      only_active: "1",
      sortBy: 'lastname',
      sortDesc: false,
      sortDirection: 'desc',
      cpsortBy: 'lastname',
      cpsortDesc: false,
      cpsortDirection: 'desc',      
      statusColor: 'background-color:#ff0000',
      showProjects:false,
      showContacts:false,
      showZZPer:true,
      sortedDocumenten:[]
    }
  },
  computed: {
    fields() {
      return [
        { key: 'photo', label: this.$i18n.t('labels.photo'), },
        { key: 'fullname', label: this.$i18n.t('labels.name'), sortable: true, sortDirection: 'desc' },
        { key: 'mobile', label: this.$i18n.t('meta.mobiel'), },
        { key: 'email', label: this.$i18n.t('meta.email'), },
        { key: 'userfunction', label: this.$i18n.t('meta.userfunction'), sortable: true, sortDirection: 'desc' },
        { key: 'roles', label: this.$i18n.t('labels.responsibilities') },
        { key: 'pulldown', label: this.$i18n.t('labels.actions') },
      ]
    },
    zzprfields() {
      return [
        { key: 'photo', label: this.$i18n.t('labels.photo'), },
        { key: 'lastname', label: this.$i18n.t('labels.name'), sortable: true, sortDirection: 'desc' },
        { key: 'title', label: this.$i18n.t('labels.title'), sortable: true, sortDirection: 'desc' },
        { key: 'location', label: this.$i18n.t('labels.location'), sortable: true, sortDirection: 'desc' },
        { key: 'startdate', label: this.$i18n.t('labels.startdate'), formatter: this.formatDate, sortable: true, sortDirection: 'desc' },
        { key: 'enddate', label: this.$i18n.t('labels.enddate'), formatter: this.formatDate, sortable: true, sortDirection: 'desc' },
        { key: 'active', label: this.$i18n.t('labels.active') },
        { key: 'pulldown', label: this.$i18n.t('labels.actions') },
        // { key: 'actions', label: this.$i18n.t('labels.actions') + " " + this.$i18n.t('labels.ZZPer') },
        // { key: 'actionscompany', label: this.$i18n.t('labels.actions') + " " + this.$i18n.t('labels.bycompany') },

      ]
    },
    projectfields() {
      return [
        { key: 'title', label: this.$i18n.t('labels.title'), sortable: true, sortDirection: 'desc' },
        { key: 'location', label: this.$i18n.t('labels.location'), sortable: true, sortDirection: 'desc' },
        { key: 'projectnumber' , label: this.$i18n.t('meta.projectnumber')},
        { key: 'startdate', label: this.$i18n.t('labels.startdate'), formatter: this.formatDate, sortable: true, sortDirection: 'desc' },
        { key: 'enddate', label: this.$i18n.t('labels.enddate'), formatter: this.formatDate, sortable: true, sortDirection: 'desc' },
        { key: 'pulldown', label: this.$i18n.t('labels.actions') },
      ]
    },
    docfields() {
      return [
        { key: 'expires', label: this.$i18n.t('meta.aangemaakt'), formatter: this.formatDate },
        { key: 'link', label: this.$i18n.t('labels.link') },

      ]
    },
    userblock(){
      var res = [];
      this.users.filter(u =>u.blockcompanies!=undefined && u.blockcompanies.length>0).forEach(u =>{
        // console.log(u.fullname, u.blockcompanies.filter(b=>{
        //   console.log(b, this.$route.params.id)
        //   return b.company_id==this.$route.params.id
        // }))
        if(u.blockcompanies.filter(b=>b.company_id==this.$route.params.id).length>0){
          // console.log(u.fullname)
          res.push(u)
        }
      })
      return res
    },
    
    ...mapState([
      'companies',
      'branches',
      'projects',
      'current_user',
      'workfields',
      'contracts',
      'planning',
      'users'
    ]),
    ...mapGetters([
      'company_by_id',
      'opdrachtgevers',
      'contactpersonen',
      'user_by_id',
      'confirm_modal',
      'project_by_id',
      'activebranches',
      'username_by_id',
      'branche_by_id',
      'last_user_id',
      'planning_by_id',
      'current_company',
      'user_photo_by_id',
      'append_data_string',
      'createAny',
      'server_url',
      'contract_by_id',
      'find_last_planning_company'
    ]),
    thisweek() {
      return parseInt(this.getWeekNumber(new Date)[1])
    },
    company: {
      get() {
        return this.company_by_id(this.$route.params.id)
      }
    },
    contactpersonsnotincompany: {
      get() {
        return this.contactpersonen.filter(user => this.company.contactpersons.indexOf(user.id)==-1 )
      }
    },

    vselcontactpersonsnotincompany() {
      return this.contactpersonen.filter(user => this.company.contactpersons.indexOf(user.id)==-1 )
    },
    contactpersons_in_company: {
      get() {
        return this.users.filter(user => this.company.contactpersons.indexOf(user.id)!=-1 || user.id==this.company.user_id)
      }
    },
    company_projects() {
      let myprojects = []
      for (var i = 0; i < this.projects.length; i++) {
        if (this.projects[i].company_id == this.company.id) {
          let aproject = this.projects[i]
          aproject.branche_id = this.company.branche_id
          if (this.projects[i].ispublic == 1) {
            myprojects.push(aproject)
          }

          if (this.only_public == "0" && (this.projects[i].ispublic == 0 || this.projects[i].ispublic == null)) {
            myprojects.push(aproject)
          }
        }
      }
      return myprojects
    },
    company_zzprs() {
      let companyzzprs = []
      for (let index = 0; index < this.contracts.length; index++) {
        let proj = this.project_by_id(this.contracts[index].project_id)
        let plan = this.planning_by_id(this.contracts[index].planning_id)
        if (proj && proj.company_id == this.company.id) {
          let thisuser = {}
          thisuser.id = this.contracts[index].user_id
          thisuser.lastname = this.user_by_id(this.contracts[index].user_id).lastname?this.user_by_id(this.contracts[index].user_id).lastname:""
          thisuser.title = plan.project_name
          thisuser.location = plan.project_location
          thisuser.startdate = plan.startdate
          thisuser.enddate = plan.enddate
          thisuser.contract_id = this.contracts[index].id
          thisuser.color = this.contracts[index].color != undefined ? this.contracts[index].color : '#eeeeee'
          thisuser.active = new Date(plan.enddate).setHours(0,0,0,0) >= new Date().setHours(0,0,0,0)
          if (!(this.only_active == "1" && thisuser.active == false)) {
            companyzzprs.push(thisuser)
          }
        }

      }
      return companyzzprs

    },

  },
  methods: {
    companystatus() {
      //difference in days
      let timediff = (this.find_last_planning_company(this.company.id) - new Date()) / (1000 * 60 * 60 * 24)
      let loccompany = this.company_by_id(this.company.id)
      if (loccompany && loccompany.blacklist != undefined && loccompany.blacklist == 1) {
        this.statusColor = 'background: #2c3e50; padding:3px;'
        return this.$t("labels.statusblacklist")
      } else {
        if (timediff > 0 || timediff > -7) {
          this.statusColor = 'background: #69b06a; padding:3px;'
          return this.$t("labels.statusactive")
        }
        if (timediff < -7 && timediff > -90) {
          this.statusColor = 'background: #5499da; padding:3px;'
          return this.$t("labels.statusinactive")
        }
        if (timediff < -90 && timediff > -356) {
          this.statusColor = 'background: #f0EE9d; padding:3px;'
          return this.$t("labels.statusinactive2")
        }
        if (timediff < -356 && timediff > -712) {
          this.statusColor = 'background: #f99d61; padding:3px;'
          return this.$t("labels.statusinactive3")
        }
        if (timediff < -712 && timediff > -7120) {
          this.statusColor = 'background: #EB6d6d; padding:3px;'
          return this.$t("labels.statusinactive4")
        }
        if (timediff < -7120) {
          this.statusColor = 'background: #; padding:3px;'
          return this.$t("labels.statusinactive5")
        }
      }
      return "none";
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return

      if (item.blacklist && item.blacklist == 1) {
        return 'status-blacklisted'
      } 
    },    
    sorteddocs() {
      return this.sortedDocumenten
      // if(this.company.documents.length>1) { 
      //   return this.company.documents.sort(function compareFunction(x, y) {
      //     console.log("sort!@#")
      //     if (x.expires > y.expires) {
      //       return 1;
      //     }
      //     if (x.expires < y.expires) {
      //       return -1;
      //     }
      //     return 0;})
      //   } else {
      //     return null
      //   }
    },    
    savecompany() {
      this.save_company(this.company.id)
    },
    brancheName(value) {
      var user = this.user_by_id(value)
      const branch = this.branche_by_id(user.branche_id);
      var ret = ""
      if (branch) {
        for (var i = 0; i < this.workfields.length; i++) {
          if (this.workfields[i].branche_id == user.branche_id) {
            if (user.workfields.indexOf(this.workfields[i]["id"]) != -1) {
              ret += this.workfields[i]["name"] + "<br>"
            }
          }
        }
        ret += "<b>" + branch.name + "</b>"
      }
      return ret
    },
    mailto(id) {
      return "mailto:" + id;
    },
    phoneto(id) {
      return "tel:" + id;
    },
    company_contact(row) {
      if (this.createAny('users')) {
        if (this.user_by_id(row.id).company_contact == "1") {
          this.user_by_id(row.id).company_contact = "0"
          this.company.company_contacts.splice(this.company.company_contacts.indexOf(row.id),1)

        } else {
          this.user_by_id(row.id).company_contact = "1"
          this.company.company_contacts.push(row.id)
        }
        this.save_user(row.id);
      }
    },
    document_contact(row) {
      if (this.createAny('users')) {
        if (this.user_by_id(row.id).document_contact == "1") {
          this.user_by_id(row.id).document_contact = "0"
          this.company.document_contacts.splice(this.company.document_contacts.indexOf(row.id),1)
        } else {
          this.user_by_id(row.id).document_contact = "1"
          this.company.document_contacts.push(row.id)
        }
        this.save_user(row.id);
      }
    },
    invoice_contant(row) {
      if (this.createAny('users')) {
        if (this.user_by_id(row.id).invoice_contant == "1") {
          this.user_by_id(row.id).invoice_contant = "0"
          this.company.invoice_contants.splice(this.company.invoice_contants.indexOf(row.id),1)
        } else {
          this.user_by_id(row.id).invoice_contant = "1"
          this.company.invoice_contants.push(row.id)
        }
        this.save_user(row.id);
      }
    },
    sign_contact(row) {
      if (this.createAny('users')) {
        if (this.user_by_id(row.id).sign_contact == "1") {
          this.user_by_id(row.id).sign_contact = "0"
          this.company.sign_contacts.splice(this.company.sign_contacts.indexOf(row.id),1)

        } else {
          this.user_by_id(row.id).sign_contact = "1"
          this.company.sign_contacts.push(row.id)
        }
        this.save_user(row.id);
      }
    },
    location_contact(row) {
      if (this.createAny('users')) {
        if (this.user_by_id(row.id).location_contact == "1") {
          this.user_by_id(row.id).location_contact = "0"
          this.company.location_contacts.splice(this.company.location_contacts.indexOf(row.id),1)
        } else {
          this.user_by_id(row.id).location_contact = "1"
          this.company.location_contacts.push(row.id)
        }
        this.save_user(row.id);
      }
    },    
    confirm_delete_project(id) {
      this.selectedID = id
      let message = this.$i18n.t('labels.confirmdeletehead') + this.project_by_id(id).title + this.$i18n.t('labels.confirmdeletetail')
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
        message: message,
      }, {
        name: 'popup_modal',
        draggable: false,
        height: 'auto',
        width: '500px'
      }, {
        'before-close': this.modalClose
      })

      this.$emit('close')

    },
    new_contact(user) {
      if (user.id == 0) {
        this.$store.commit('new_user', {
          id: 0,
          branche_id: this.company.branche_id,
          workfields: [],
          role: 'contactpersoon',
          owner_id: this.current_user.id,
          country: "NL",
          active: 1,
          language: "nl",
          notifications: 1,
          newsletter: 1,
          mail: 1,
          sms: 1,
          inapp: 1,
          company_contact: 0,
          invoice_contant: 0,
          document_contact: 0,
          sign_contact: 0,
          location_contact: 0,
          isemployee:0
        })
      }
      this.$store.commit('current_company', this.company.id)
      this.$modal.hide('new_contact_modal')
      this.$modal.show(new_contact_modal, {
        user_id: user.id,
      }, {
        name: 'new_contact_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '1000px'
      })
      this.$emit('close')

    },

    modalClose() {
      if (this.confirm_modal.id == 1) {
        this.delete_project(this.selectedID);
        for (let i = 0; i < this.projects.length; i++) {
          if (this.projects[i].id == this.selectedID) {
            this.projects.splice(i, 1);
          }
        }
      }
    },
    ...mapActions([
      'save_company',
      'save_company_relations',
      'remove_company_relations',
      'delete_project',
      'add_project'
    ]),
    delete_user(user_id) {
      var datatosend = { company_id: this.company.id, user_id: user_id }
      this.$store.dispatch('remove_company_relations', datatosend);
      this.company.contactpersons.splice(this.company.contactpersons.indexOf(user_id), 1)
    },
    save_contactperson() {
      if (this.company.contactpersons.indexOf(this.selectedcontactpersoon.id) == -1) {
        var datatosend = { company_id: this.company.id, user_id: this.selectedcontactpersoon.id }
        this.$store.dispatch('save_company_relations', datatosend);
        this.company.contactpersons.push(this.selectedcontactpersoon.id)
      }
    },

    onProjectClicked(row) {
      this.$router.push({ path: '/project/' + row.id });

    },
    show_user(id){
        this.$router.push({ path: '/user/'+ id });
    },
    onUserClicked(row) {
      this.$router.push({ path: '/user/' + row.id });

    },
    edit_project(project) {
      const dateObj = new Date();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');
      const year = dateObj.getFullYear();
      // let projectnumber = 0
      // for (const pr of this.projects) {
      //   if (pr.id > projectnumber) {
      //     projectnumber = pr.id
      //   }
      // }
      // projectnumber++

      this.$store.commit('new_project', { id: 0, branche_id: this.company.branche_id, company_id: this.company.id, ispublic: 0, contactpersons: [], workfields: [], publishdate: year + "-" + month + "-" + day })
      this.editProjectModal(project)

    },
    append_data_multi(key) {
      var current = ""
      if (this.company[key] && this.company[key] != null) {
        current = "\n" + this.company[key]
      }
      this.company[key] = this.append_data_string + current
      this.componentKey += 1;
      if (this.$refs[key]) {
        if (this.$refs[key][0]) {
          this.$refs[key][0].focus()
          this.$nextTick(() => {
            this.$refs[key][0].selectionEnd = 9
          })
        } else {
          this.$refs[key].focus()
          this.$nextTick(() => {
            this.$refs[key].selectionEnd = 9;
          })
        }
      }
    },
    oldrowClass(item, type) {
      if (!item || type !== 'row') return
      let expireDate = new Date(item.expires)
      var today = new Date();
      var diff = (expireDate.getTime() - today.getTime()) / (1000 * 3600 * 24)
      if ((diff < 14)) {
        return 'status-expires'
      }
      return 'status-active'

    },
    docto(id) {
      return this.server_url + '/media_' + id;
    },
    companybrancheName() {
      const branch = this.branche_by_id(this.company.branche_id);
      var ret = ""
      if (branch) {
        ret += "<b>" + branch.name + "</b><br>"
        var hasworkfields = false
        for (var i = 0; i < this.workfields.length; i++) {
          if (this.workfields[i].branche_id == this.company.branche_id) {
            if (this.company.workfields.indexOf(this.workfields[i]["id"]) != -1) {
              ret += this.workfields[i]["name"] + "<br>"
              hasworkfields = true
            }
          }
        }
        if (!hasworkfields) {
          ret += '<span style="color:red">no workfields</span>'
        }
      }
      return ret
    },
    rowClassZZPR(item) {
      return { "style": "background-color:" + item.color }
    },
    clone_project(item) {
      let clone = Object.assign({}, this.project_by_id(item.id))
      clone.id = 0;
      clone.title = "(COPY) " + clone.title
      clone.projectnumber = ""
      this.add_project(clone)

    },
    generate_combined_pdf() {
      let combined_contracts = []
      this.company_zzprs.forEach(contract => {
        combined_contracts.push(contract.contract_id)

      });

      let data = {}
      data.contracts = combined_contracts
      data.dates = this.getWeekDates(11, 2022)
      data.week = 11
      console.log(data)
      try {
        axios.post('/api/generate_verkoop_combined', data).then((response) => {
          console.log('succes', response)
        })
      } catch (err) {
        console.log(err)
      }
    }
  },
  components: {},
  watch: {
    company(){
      this.sortedDocumenten = this.company.documents.sort(function compareFunction(x, y) {
          if (x.expires > y.expires) {
            return 1;
          }
          if (x.expires < y.expires) {
            return -1;
          }
          return 0;})    
    }

  }

}

</script>