
<template>
	<div class="popup">

        <div class="popup-header">
            <div >
              <h2>{{ $t("buttons.adddocument") }}</h2>
            </div>
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
          <b-container>
            <div class="form-row">
                <div class="col-3">
                    <label for=""> {{ $t("labels.name") }}</label>
                </div>
                <div class="col-1">
                    <font-awesome-icon icon="exclamation" color="red" v-if="checkfilecharacters(this)==false"></font-awesome-icon>
                    <font-awesome-icon icon="check" color="green" v-else></font-awesome-icon>
                </div>

                <div class="col">
                    <b-form-input v-on:keypress="isLetter($event)"  @blur="removeInvalidChars()" type="text" class="form-control" v-model="name" />              
                </div>
            </div>    
            <div class="form-row">
                <div class="col-4">
                    <label for=""> {{ $t("labels.expirationdate") }}</label>
                </div>
                <div class="col">
      <flat-pickr 
        v-model="expires"
        :config="config"
        class="form-control"
        placeholder="Select date"
        name="startdate"/>                              
                </div>
            </div>
            <div class="form-row">
                <div class="col-3">
                    <label for=""> {{ $t("labels.file") }}</label>
                </div>
                <div class="col-1">
                    <font-awesome-icon icon="exclamation" color="red" v-if="file1==null || checkfilecharacters(file1)==false"></font-awesome-icon>
                    <font-awesome-icon icon="check" color="green" v-else></font-awesome-icon>
                </div>
                <div class="col">
                    <b-form-file ref="file-input" class="form-control" accept="*/*" v-model="file1" :state="Boolean(file1)" />
                </div>
                
            </div> 

          </b-container>
        </div>
        <div class="popup-footer">
            <div  @click="sendFile" class="btn btn-primary"><font-awesome-icon icon="plus" /> {{ validateText() }}</div>
        </div>
    </div>          
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import axios from 'axios'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
const Dutch = require("flatpickr/dist/l10n/nl.js").default.nl;

export default {
    name: 'adduserdocumentssscreen',
    data() {
        return {
          name:"new",
          expires:null,
          file1:null,
          config:{
            weekNumbers:true,
            locale: Dutch,
            altInput: true,
            altFormat: "d-m-Y",
            dateFormat: "Y-m-d",
          }
        }
    },
    props: {
        user_id: Number
    },
    computed: {
        user() {
            return this.user_id > 0 ? this.user_by_id(this.user_id) : this.user_new
        },
        ...mapGetters([
            'user_metas',
            'user_by_id',
            'activebranches',
            'user_new',
            'server_url'
        ]),
        ...mapState([
            'users',
            'branches',
            'avatars'
        ]),   
        
    },
    methods: {
        validateText(){
            if(this.name!="" &&this.checkfilecharacters(this)&&this.file1!=null&&this.checkfilecharacters(this.file1)){
                return this.$i18n.t("buttons.adddocument")

        } else {
                return this.$i18n.t("buttons.invalid")
            }
        },
      sendFile(){
        if(this.name!="" &&this.checkfilecharacters(this)&&this.file1!=null&&this.checkfilecharacters(this.file1)){
            const formData = new FormData();
            formData.append('name', this.name.replace(/[^a-zA-Z0-9 ]/g, ''));
            formData.append('expires', this.expires)
            formData.append('file', this.file1)
            formData.append('user_id', this.user_id)
            let adoc={}
            for (var pair of formData.entries()) {
                adoc[pair[0]] = pair[1]
            }  
            adoc.link = "documents/"+this.user_id+"-"+this.name+"-"+this.file1.name
            this.user.documents.push(adoc)
            try{
            axios.post('/api/user/document/', formData)  
                .then((response) => {
                console.log(response)
                this.close_modal();        
            })
                this.close_modal();        

            } catch(err){
            console.log (err)
            }
        } else{ console.log("nope")}
      },
        close_modal() {
            this.$emit('close')
        } ,      
        ...mapActions([
            'update_user_document'
        ]),

        isLetter(e) {
            this.name = this.name.replace(/[^ a-zA-Z0-9 -]/g, '')
            let char = String.fromCharCode(e.keyCode); 
            if(/^[A-Za-z0-9 -]+$/.test(char)) return true;
            else e.preventDefault(); 
        },
        removeInvalidChars() {
            this.name = this.name.replace(/\//g,'')
        }

    },
    components:{flatPickr}

}    
</script>
