<template>
  <div class="projectpeopleplanning">
    <div class="row">
      <div class="col-3">{{ $t('labels.workfield') }}</div>
      <div class="col-2">{{ $t('labels.startdate') }}</div>
      <div class="col-2">{{$t('labels.enddate')}}</div>
      <div class="col-2">{{$t('meta.starttijd')}}</div>
      <div class="col-2">{{$t('meta.eindtijd')}}</div>
    </div> 
    
    <div class="row">
      <div class="col-3"><v-select  v-model="workfield" label="name" :options="projectWorkfields" :placeholder="$t('labels.choose')"></v-select></div>
      <div class="col-2"><b-form-input type="date" v-model="startdate" /></div>
      <div class="col-2"><b-form-input type="date" v-model="enddate" /></div>
      <div class="col-2"><b-form-input type="time" v-model="starttime" /></div>
      <div class="col-2"><b-form-input type="time" v-model="endtime" /></div>      
      <div class="col-1"><button v-if="workfield!=null" class="btn btn-primary" @click="saveResource()">+</button></div>
    </div>
    <b-table :items="projectresourcesfromproject" :fields="fields" >
      <template #cell(workfield_id)="row">
          {{workfield_by_id(row.item.workfield_id).name}}
      </template>
      <template #cell(start_offset)="row">
          <input type="date" v-model="row.item.start_date" />
      </template>
      <template #cell(end_offset)="row">
        <input type="date" v-model="row.item.end_date" />
      </template>      
      <template #cell(start_time)="row">
        <input type="time" v-model="row.item.start_time" />
      </template>  
      <template #cell(end_time)="row">
        <input type="time" v-model="row.item.end_time" />
      </template>                    
      <template #cell(actions)="row">
        <div class="action-item" style="float:left" @click="saveItem(row.item)"> <font-awesome-icon icon="save" /> </div>
        <div class="action-item" style="float:left" @click="cloneItem(row.item)"> <font-awesome-icon icon="clone" /> </div>
        <div class="action-item" style="float:left" @click="deleteItem(row.item.id)"> <font-awesome-icon icon="trash" /> </div>
      </template>          
    </b-table>
        
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import popup_modal from '../PopupModal.vue'
import axios from 'axios'

export default {
    name: 'projectpeopleplanningsscreen',
    data() {
        return {
          startdate:null,
          enddate:null,
          starttime:null,
          endtime:null,
          workfield:null,
          selectedID:null

        }      
    },
    props: {
      project_id:Number,
    },
    mounted: function () {
      this.startdate = this.project.startdate
      this.enddate = this.project.enddate
      this.starttime = this.project.starttime
      this.endtime = this.project.endtime      
    },
    computed: {
        project() {
            return this.project_id > 0 ? this.project_by_id(this.project_id) : this.project_new
        },     
        fields () {
            return [
            { key: 'workfield_id', label: this.$i18n.t('labels.workfieldname')  , sortable: true, sortDirection: 'desc' },
            { key: 'start_offset', label: this.$i18n.t('labels.startdate')  , sortable: true, sortDirection: 'desc' },
            { key: 'end_offset', label: this.$i18n.t('labels.enddate')  , sortable: true, sortDirection: 'desc' },
            { key: 'start_time', label: this.$i18n.t('meta.starttijd')  , sortable: true, sortDirection: 'desc' },
            { key: 'end_time', label: this.$i18n.t('meta.eindtijd')  , sortable: true, sortDirection: 'desc' },
            { key: 'actions', label: this.$i18n.t('labels.actions') },
            ]   
        },   
        projectresourcesfromproject(){
          let prres = this.projectresources.filter(pr=>pr.project_id==this.project_id && pr.workfield_id!=null)
          prres.forEach(element => {
            let startdate = new Date(this.project.startdate)
            startdate.setHours(0,0,0,0)
            startdate.setDate(startdate.getDate() + element.start_offset);
            let enddate = new Date(startdate)
            enddate.setDate(enddate.getDate() + element.end_offset);
            element.start_date = this.dateToYYYYMMDD(startdate)
            element.end_date = this.dateToYYYYMMDD(enddate)
          });
          return prres
        },       
        ...mapGetters([
            'user_by_id',
            'branche_by_id',
            'company_by_id',
            'project_by_id',
            'project_new',
            'workfield_by_id',
            'projectresource_by_id'

        ]),
        ...mapState([
            'projects',
            'workfields',
            'companies',
            'projectresources'

        ]),
        projectWorkfields() {
          let currentfields=[]
          let company =  this.project.company_id==0?this.companies[0]:this.company_by_id(this.project.company_id)
          if(company){
            var branche = this.branche_by_id(company.branche_id)
            for(var i=0;i<this.workfields.length;i++){
              if(this.workfields[i].branche_id==branche.id && this.workfields[i].active=='1'){
                  var aworkfield={}
                  aworkfield["name"]=this.workfields[i]["name"];
                  aworkfield["id"]=this.workfields[i]["id"];
                  aworkfield["active"]=this.project.workfields.indexOf(this.workfields[i]["id"])!=-1
                  currentfields.push(aworkfield)
              }
            }
          }
          return currentfields
        },
        start_offset(){
          return Math.round(( new Date(this.startdate).getTime() - new Date(this.project.startdate).getTime())/86400000)
        },
        end_offset() {
          return Math.round(( new Date(this.enddate).getTime() - new Date(this.startdate).getTime())/86400000)
        },            
    },
    methods:{
        ...mapActions([
            'save_settings',
            'save_user_workfield',
            'delete_user_workfield',
            'add_projectresource',
            'save_projectresource',
            'delete_projectresource'

        ]),
        saveItem(item){
          item.start_offset = Math.round(( new Date(item.start_date).getTime() - new Date(this.project.startdate).getTime())/86400000)
          item.end_offset=  Math.round(( new Date(item.end_date).getTime() - new Date(item.start_date).getTime())/86400000)
          axios.put("/api/projectresource/" + item.id, item)
        },
        cloneItem(copy){
          // let copy = this.projectresource_by_id(item)
          // console.log(copy)
          this.add_projectresource({id:0, project_id:copy.project_id, resource_id:null, workfield_id:copy.workfield_id, start_offset:copy.start_offset, end_offset:copy.end_offset,start_time:copy.start_time, end_time:copy.end_time})

        },
        deleteItem(item){
          this.selectedID = item
          let message = this.$i18n.t('labels.confirmdeletehead') + this.workfield_by_id(this.projectresource_by_id(item).workfield_id).name + this.$i18n.t('labels.confirmdeletetail')

          this.$modal.hide('popup_modal')
          this.$modal.show(popup_modal, {
            message: message,
          }, {
            name: 'popup_modal',
            draggable: false,
            height: 'auto',
            width: '500px'
          }, {
            'before-close': this.modalClose
          })

          this.$emit('close')
        },
        modalClose() {
          if (this.confirm_modal.id == 1) {
            this.delete_projectresource(this.selectedID);
            for (let i = 0; i < this.projectresources.length; i++) {
              if (this.projectresources[i].id == this.selectedID) {
                this.projectresources.splice(i, 1);
              }
            }
          }
        },        
        saveResource(){
          this.add_projectresource({id:0, project_id:this.project_id, resource_id:null, workfield_id:this.workfield.id, start_offset:this.start_offset, end_offset:this.end_offset, start_time:this.starttime, end_time:this.endtime})
        }       
    },
    components: { }
}
</script>
