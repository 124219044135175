<template>
  <div v-if="waxes.length>0">
    <h3>{{ $t('labels.useralreadyplanned') }}</h3>
    <div class="row" v-for="(p, idx) in waxes" :key="idx" >
      <div class="col">{{ label(p) }}</div>
      <div class="col">{{dateInfo(p)}}</div>
      <div class="col">{{p.start_time }} - {{p.end_time }} </div>
    </div>
  </div>
</template>
<script>
import { mapGetters,mapState } from 'vuex';

export default {
  name: 'check_user_planning',
	data() {
		return {
		}
	},  
  props: {
    user_id: Number,
    startdate: String,
    enddate: String
  },
  computed: {
    thisisme() {
      return this.user_by_id(this.user_id).firstname
    },
    ...mapGetters([    
    'user_by_id',
    'project_by_id',
    ]),
    ...mapState ([
      'ffwax',
    ]),  
    waxes() {
      return this.ffwax.filter(f => f.user_id == this.user_id)
      .filter(ff => {
        let gendday = new Date(this.enddate)
        let startday = new Date(this.startdate)
        var startdate
        if(ff.project_id>9990000){
          let year = ff.project_id - 9990000
          startdate = new Date(Date.UTC(year, 0, 1))
        } else {
          startdate = new Date(this.project_by_id(ff.project_id).startdate)
        }
        // let startdate = new Date(this.project_by_id(ff.project_id).startdate)
        startdate.setDate(startdate.getDate() + ff.start_offset);
        let enddate = new Date(startdate)
        enddate.setDate(enddate.getDate() + ff.end_offset);
        return this.dateRangeOverlaps(startday, gendday, startdate, enddate)

      })
  
    },    
    
  },
  methods: {
    dateInfo(p){
      var sd
      if(p.project_id>9990000){
          let year = p.project_id - 9990000
          sd = new Date(Date.UTC(year, 0, 1))
        } else {      
         sd = new Date(this.project_by_id(p.project_id).startdate)
        }
        sd.setDate(sd.getDate() + p.start_offset);

      let enddate = new Date(sd)
      enddate.setDate(enddate.getDate() + p.end_offset);
      return this.dateToNL(sd) +" - "+this.dateToNL(enddate)
    },
    label(task){
      if(task.project_id>9990000){
        return "vrij"
      }
      return this.project_by_id(task.project_id).title
    }      
  },

}
</script>