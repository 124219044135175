<template>
  <b-container v-if="createAny('users') && currentUserIsSuperAdmin()" fluid>
    <h1>{{$t('buttons.cronsettings')}}</h1>

    <h3>{{$t('labels.cronremindsigners')}}</h3>
    <b-form-checkbox  v-model="settings.cronremindsigners">
            {{$t('labels.statusactive')}}
    </b-form-checkbox>
    <b-form-group :label="$t('labels.crontime')">
      <b-form-input type="text" class="form-control" v-model="settings.crontimesigners" />
    </b-form-group>    

    <h3>{{$t('labels.cronremindzzpers')}}</h3>
    <b-form-checkbox  v-model="settings.cronremindzzpers">
            {{$t('labels.statusactive')}}
      </b-form-checkbox>
    <b-form-group :label="$t('labels.crontime')">
      <b-form-input type="text" class="form-control" v-model="settings.crontimezzpers" />
    </b-form-group>        
    

    <h3>{{$t('labels.cronremindtodo')}}</h3>
    <b-form-checkbox  v-model="settings.cronremindtodo">
            {{$t('labels.statusactive')}}
      </b-form-checkbox>
    <b-form-group :label="$t('labels.crontime')">
      <b-form-input type="text" class="form-control" v-model="settings.crontimetodo" />
    </b-form-group>     

    <!-- new contract-->
    <h3>{{$t('labels.crontimenewcontract')}}</h3>
    <b-form-checkbox  v-model="settings.cronremindnewcontract">
            {{$t('labels.statusactive')}}
      </b-form-checkbox>
    <b-form-group :label="$t('labels.crontime')">
      <b-form-input type="text" class="form-control" v-model="settings.crontimenewcontract" />
    </b-form-group>     
    <!-- new contract-->
    <h3>{{$t('labels.cronremindendworkday')}}</h3>
    <b-form-checkbox  v-model="settings.cronremindendworkday">
            {{$t('labels.statusactive')}}
      </b-form-checkbox>
    <b-form-group :label="$t('labels.crontime')">
      <b-form-input type="text" class="form-control" v-model="settings.crontimeendworkday" />
    </b-form-group>     


    
  <!-- //Terugbellen   -->
    <h3>{{$t('labels.call')}}</h3>
    <b-form-checkbox  v-model="settings.cronremindcallback">
            {{$t('labels.statusactive')}}
      </b-form-checkbox>
    <b-form-group :label="$t('labels.crontime')">
      <b-form-input type="text" class="form-control" v-model="settings.crontimecallback" />
    </b-form-group>     
    <b-form-group :label="$t('templates.subject')">
      <b-form-input type="text" class="form-control" v-model="settings.callbacksubject" />
    </b-form-group>
        <b-form-group :label="$t('templates.message')">
          <ckeditor :editor="editor" v-model="settings.callbackmessage" :config="editorConfig"></ckeditor>
        </b-form-group>
        <b-form-group :label="$t('labels.sendto')">
          <b-form-input type="text" class="form-control" v-model="settings.callbackcc" />
        </b-form-group>        
    <!-- Verjaardagen -->
    <h3>Verjaardagen</h3>
    <b-form-checkbox  v-model="settings.cronremindbirthday">
            {{$t('labels.statusactive')}}
      </b-form-checkbox>
    <b-form-group :label="$t('labels.crontime')">
      <b-form-input type="text" class="form-control" v-model="settings.crontimebirthday" />
    </b-form-group>     
    <b-form-group :label="$t('templates.subject')">
      <b-form-input type="text" class="form-control" v-model="settings.birthdaysubject" />
    </b-form-group>
        <b-form-group :label="$t('templates.message')">
          <ckeditor :editor="editor" v-model="settings.birthdaymessage" :config="editorConfig"></ckeditor>
        </b-form-group>
        <b-form-group :label="$t('labels.sendto')">
          <b-form-input type="text" class="form-control" v-model="settings.birthdaycc" />
        </b-form-group>        

    <!-- Verjaardagen -->
    <h3>BTW NummerCheck</h3>
    <b-form-checkbox  v-model="settings.cronremindvat">
            {{$t('labels.statusactive')}}
      </b-form-checkbox>
    <b-form-group :label="$t('labels.crontime')">
      <b-form-input type="text" class="form-control" v-model="settings.crontimevat" />
    </b-form-group>     
    <b-form-group :label="$t('templates.subject')">
      <b-form-input type="text" class="form-control" v-model="settings.vatchecksubject" />
    </b-form-group>
        <b-form-group :label="$t('templates.message')">
          <ckeditor :editor="editor" v-model="settings.vatcheckmessage" :config="editorConfig"></ckeditor>
        </b-form-group>
        <b-form-group :label="$t('labels.sendto')">
          <b-form-input type="text" class="form-control" v-model="settings.vatcheckcc" />
        </b-form-group>        

    <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="plus" /> {{ $t("buttons.savesettings") }}
    </div>     
  </b-container>
</template>
<script>

import axios from 'axios'
import { mapGetters, mapState, mapActions } from 'vuex'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: 'emailsenders',
  data() {
    return {
      editor: ClassicEditor,

      editorConfig: {
        toolbar: {
          items:[ 'heading', '|', 'undo', 'redo', '-', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ,'insertTable'],
          shouldNotGroupWhenFull: true
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
          ]
        },
        table: {
            contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        }
      }, 
    }
  },
  mounted() {
  },  
  computed: {
    ...mapGetters([
      'createAny',
    ]),
    ...mapState([
      'current_user',
      'settings'

    ]),
    ip(){
      return location.host
    }
  },
  methods: {
    ...mapActions([
    ]),
    save_settings() {
      try {
        axios.patch('/api/settings/',
          {
            cronremindzzpers: this.settings.cronremindzzpers,
            crontimezzpers: this.settings.crontimezzpers,
            cronremindsigners: this.settings.cronremindsigners,
            crontimesigners: this.settings.crontimesigners,
            cronremindtodo:this.settings.cronremindtodo,
            crontimetodo: this.settings.crontimetodo,
            cronremindcallback: this.settings.cronremindcallback,
            crontimecallback:this.settings.crontimecallback,
            callbacksubject:this.settings.callbacksubject,
            callbackmessage:this.settings.callbackmessage,
            callbackcc:this.settings.callbackcc,
            cronremindbirthday: this.settings.cronremindbirthday,
            crontimebirthday:this.settings.crontimebirthday,
            birthdaysubject:this.settings.birthdaysubject,
            birthdaymessage:this.settings.birthdaymessage,
            birthdaycc:this.settings.birthdaycc,
            cronremindvat:this.settings.cronremindvat,
            crontimevat:this.settings.crontimevat,
            vatchecksubject:this.settings.vatchecksubject,
            vatcheckmessage:this.settings.vatcheckmessage,
            vatcheckcc:this.settings.vatcheckcc,
            cronremindnewcontract:this.settings.cronremindnewcontract,
            crontimenewcontract:this.settings.crontimenewcontract,
            cronremindendworkday:this.settings.cronremindendworkday,
            crontimeendworkday:this.settings.crontimeendworkday,
            


          },
        )
          .then((response) => {
            console.log(response)
            axios.get('/api/cronset/');
          })
      } catch (err) {
        console.log(err)
      }
    },    

  },
  components: {},
}
</script>