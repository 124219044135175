<template>

  <b-container fluid :style="AppSecondaryColor()" class="apppage">
    <b-button-group vertical>
      <b-button :style="AppPrimaryButton()" active-class="active" @click="$router.go(-1)">
        <font-awesome-icon icon="chevron-left" />
      </b-button>
    </b-button-group>
    <div class="public_project" :style="projectTitleAppClass()">
      <p class="projectnumber">{{$t('meta.projectnumber')}} {{project.id}}</p>
      <p class="title" :style="AppPrimaryColor()" >{{project.omschrijvingapp}}</p>
      <p class="date" :style="AppPrimaryColor()">{{$t('labels.date')}}</p>
      <p>{{$t('labels.from')}} {{project.startdate}} {{$t('labels.to')}} {{project.enddate}}</p>
      <p class="location" :style="AppPrimaryColor()">{{$t('labels.location')}}</p>
      <p>{{project.location}}</p>
      <br>
      <p>{{project.omschrijvingwebsite}}</p>
      <a v-if= "current_user" href="javascript:;" :style="AppPrimaryButton()" class="btn btn-primary" @click="react_to_project()">
          <font-awesome-icon icon="heart" /> {{ $t('labels.subscribe_project') }}
      </a>
    </div>
    <div v-html="settings.projectpage"/> 
    <b-button :style="AppPrimaryButton()" style="width:100%;margin:1%;" variant="primary" :href=settings.projectpagebutton1link>{{ settings.projectpagebutton1text }}</b-button>
    <!-- <b-button :style="AppPrimaryButton()" style="width:100%;margin:1%;" variant="primary" href="tel:0413379279">{{$t('this.settings.projectpagebutton1text')}}</b-button> -->

    <b-button :style="AppPrimaryButton()" style="width:100%;margin:1%;" variant="primary" :href=settings.projectpagebutton2link>{{ settings.projectpagebutton2text }}</b-button>
    <!-- <b-button :style="AppPrimaryButton()" style="width:100%;margin:1%;" variant="primary" href="mailto:info@zzpr.nl">{{$t('buttons.appmail')}}</b-button> -->
    <b-button v-if= "!current_user" :style="AppSecondaryButton()" style="width:100%;margin:1%;" variant="secondary" href="/#/register">{{$t('titles.registeras')}}</b-button>


  </b-container>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import subscribe_to_project_modal from './modals/SubscribeToProjectModal.vue'


export default {
  name: 'publicprojects',
  data() {
    return {
    }
  },
  computed: {
    project: {
      get() {
        return this.project_by_id(this.$route.params.id)
      },
    },

    ...mapState([
      'projects',
      'settings'
    ]),
    ...mapGetters([
      'project_metas',
      'project_by_id',
  ])

  },
  methods: {
    projectInfo(row) {
      this.$router.push({ path: '/public_projects/' + row });
    },
    react_to_project() {
      console.log(this.project.id)
      this.$modal.hide('subscribe_to_project_modal')
      this.$modal.show(subscribe_to_project_modal, {
        project_id: this.project.id,
      }, {
        name: 'subscribe_to_project_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '90%',
        shiftY: 0
      })

      this.$emit('close')
    },    
  },
}
</script>
