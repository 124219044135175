<template>
  <div class="workfield-settings">
        <b-table 
        striped hover 
        :items="currentworkfields"
        :fields="fields" 
        @row-clicked="onWorkfieldClicked"        
        >
          <template #cell(active)="row"> 
            <font-awesome-icon v-if="row.item.active" icon="check" style="color: green;"/>
            <font-awesome-icon v-else icon="times" style="color: red;"/>
          </template>        
        </b-table>
    </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
export default {
    name: 'companyWorkfieldsscreen',
    data() {
        return {

        }      
    },
    props: {
      company_id:Number,
    },
    computed: {
        company() {
            return this.company_id > 0 ? this.company_by_id(this.company_id) : this.company_new
        },     
        fields () {
            return [
            { key: 'name', label: this.$i18n.t('labels.workfieldname')  , sortable: true, sortDirection: 'desc' },
            { key: 'active', label: this.$i18n.t('labels.active') },
            ]   
        },        
        ...mapGetters([
            'user_by_id',
            'branche_by_id',
            'company_by_id',
            'company_by_id',
            'company_new'

        ]),
        ...mapState([
            'companys',
            'workfields',
            'companies'

        ]),
        currentworkfields() {
          let currentfields=[]
          var branche = this.branche_by_id(this.company.branche_id)
          for(var i=0;i<this.workfields.length;i++){
            if(this.workfields[i].branche_id==branche.id){
                let aworkfield=[]
                aworkfield["name"]=this.workfields[i]["name"];
                aworkfield["id"]=this.workfields[i]["id"];
                aworkfield["active"]=this.company.workfields.indexOf(this.workfields[i]["id"])!=-1
                if (this.workfields[i]["active"]) {
                  currentfields.push(aworkfield)
                }
            }
          }
          return currentfields
        }
    },
    methods:{
        ...mapActions([
            'save_settings',
            'save_company_workfield',
            'delete_company_workfield'

        ]),
        onWorkfieldClicked(row){
            var datatosend={workfield_id:row.id, company_id:this.company_id}
            if(this.company.workfields.indexOf(row.id)!=-1){
                if(this.company_id>0){
                  this.$store.dispatch('delete_company_workfield',datatosend); 
                }
                this.company.workfields.splice(this.company.workfields.indexOf(row.id),1)
            }else{
                if(this.company_id>0){
                  this.$store.dispatch('save_company_workfield',datatosend); 
                }
                this.company.workfields.push(row.id)
            }         
          
        },          
    },
    components: { }
}
</script>
