<template>
	<div class="popup">

        <div class="popup-header">
            <div v-if="branche.id > 0">
                {{branche.name}}
            </div>
            <div v-else>
                 {{ $t("labels.newbranche") }}
            </div>
            <div v-if="branche.id == 0" class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
            <div v-else class="popup-close" @click="save_and_close">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
            <b-container>
                <form class="form" v-on:submit='save_and_close'>
                    <!-- branche name -->
                    <div class="form-row">
                        <div class="col-3">
                            <font-awesome-icon v-if="checkbranche" icon="check" style="color: green;"/>
                            <font-awesome-icon v-else icon="times" style="color: red;"/>     
                            <label for="">{{ $t("labels.branchename") }}</label>
                        </div>
                        <div class="col">
                            <b-input ref="deffield" :state="checkbranche" type="text" class="form-control" v-model="branche.name"></b-input>
                            <b-form-invalid-feedback v-if= "branche.id==0">
                                {{ $t("validation.branchecheck")}}
                            </b-form-invalid-feedback> 
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">{{ $t("labels.active") }}</label>
                        </div>
                        <div class="col">
                            <select v-model="branche.active" class="form-control">
                                <option value="1">{{ $t("option.active") }}</option>
                                <option value="0">{{ $t("option.inactive") }}</option>
                            </select>                         
                        </div>
                    </div>                
                </form>
            </b-container>
        </div>
        <div class="popup-footer">
            <div v-if="branche.id == 0" class="btn btn-primary" @click="my_add_branche(branche);">{{ addbrancheText() }}</div>
            <div v-else @click="save_and_close" class="btn btn-primary">{{ $t("buttons.saveandclose") }}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
    name: 'branche-modal',
    data() {
        return {
            new_branche: { id: 0, active:1}
        }
    },
    props: {
        branche_id: Number
    },
    mounted() {
        this.focusInput();
    },
    computed: {
        branche() {
            return this.branche_id > 0 ? this.branche_by_id(this.branche_id) : this.new_branche
        },
        ...mapGetters([
            'branche_by_id',
            'workfield_by_id',
        ]),
        ...mapState([
            'branches',
            'workfields',
        ]),
        checkbranche(){
            if(this.branche.id>0){
                return true
            }
            let found=false;
            if(this.branche.name!=undefined){
                for(var i=0;i<this.branches.length;i++){
                    if(this.branche.name.toLowerCase()==this.branches[i].name.toLowerCase()){
                        found = true;
                        break;
                    }
                }
            }
            return !found &&this.branche.name!=undefined&& this.branche.name.length>2
        }
    },
    methods: {
        addbrancheText(){
            if (this.checkbranche){
                return this.$i18n.t("buttons.add") 
            } else { 
                return this.$i18n.t("buttons.invalid") 
            }
        },          
        save_and_close(e) {
            e.preventDefault() // it prevent from page reload
            if(this.branche.id==0){
                this.my_add_branche()
            }else{
                this.save_branche(this.branche.id)
                this.$emit('close')
            }
        },
        ...mapActions([
            'save_branche',
            'add_branche'
        ]),
        my_add_branche(){
            if(this.checkbranche){
                this.add_branche(this.branche);
                this.$emit('close')
            }
        },
    focusInput() {
      this.$refs.deffield.focus();
    }        

    }
}
</script>