<template>
  <b-container v-if="createAny('facturen')" fluid>

    <!--Title bar -->
    <div class="title-bar">
      <h1> {{ invoiceType }}{{ $t("titles.invoices").toLowerCase() }}</h1>
    </div>
    <b-row class="filter-bar">
      <b-col md="4" xl>
        <b-form-group :label="$t('labels.from')">
          <flat-pickr v-model="startdate" :config="config" class="form-control" placeholder="Select date"
            name="startdate" />
        </b-form-group>
      </b-col>
      <b-col md="4" xl>
        <b-form-group :label="$t('labels.to')">
          <flat-pickr v-model="enddate" :config="config" class="form-control" placeholder="Select date"
            name="enddate" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="filter-bar" align-h="end">
      <b-col md="4" xl>
        <b-row>
          <b-col md="4" xl>
          {{ $t('labels.searchinvoice') }}</b-col>
          <b-col cols="6">

            <v-select v-if="purchase=='yes'" :options="allinvoices" label="facnuminkoop" v-model="selectedinvoice"></v-select>
            <v-select v-else :options="allinvoices" label="facnumverkoop" v-model="selectedinvoice" :placeholder="$t('labels.invoice_verkoop')"></v-select>
          </b-col>
          <b-col >
            <a v-if="current_user.role=='admin' && purchase=='yes'  && selectedinvoice" href='javascript:;' class='btn btn-primary float-left'
              @click="gen_invoice()" :title="$t('labels.invoice_inkoop')">
              <font-awesome-icon v-if="!gen_invoice_busy" size="2x" icon="eye" />
              <b-spinner small v-else></b-spinner>{{ $t('labels.view') }}
            </a>
            <a v-if="current_user.role=='admin' && purchase!='yes' && selectedinvoice" href='javascript:;' class='btn btn-primary float-left'
              @click="gen_invoice()" :title="$t('labels.view')">
              <font-awesome-icon v-if="!gen_invoice_busy" size="2x" icon="eye" />
              <b-spinner small v-else></b-spinner>{{ $t('labels.view') }}
            </a>
          </b-col>
        </b-row>

      </b-col>
      <b-col md="4" xl>
        <b-dropdown id="dropdown-dropup" dropup :text="$t('buttons.selectemailinvoices')" variant="primary">
            <b-dropdown-item @click="select_all_email()" >
              <font-awesome-icon icon="plus" /> {{ $t('buttons.selectall') }}
            </b-dropdown-item>
            <b-dropdown-item @click="select_none_email()">
              <font-awesome-icon icon="minus" /> {{ $t('buttons.selectnone') }}
            </b-dropdown-item>
            <b-dropdown-item @click="select_not_emailed()">
              <font-awesome-icon icon="check" /> {{ $t('buttons.selectnotmailed') }}
            </b-dropdown-item>            
          </b-dropdown>

        <b-dropdown id="dropdown-dropup" dropup :text="$t('buttons.selectexportinvoices')" variant="primary">
            <b-dropdown-item @click="select_all_export()">
              <font-awesome-icon icon="plus" /> {{ $t('buttons.selectall') }}
            </b-dropdown-item>
            <b-dropdown-item @click="select_none_export()">
              <font-awesome-icon icon="minus" /> {{ $t('buttons.selectnone') }}
            </b-dropdown-item>
            <b-dropdown-item @click="select_not_exported()">
              <font-awesome-icon icon="check" /> {{ $t('buttons.selectnotexported') }}
            </b-dropdown-item>            
          </b-dropdown>        
      </b-col>
    </b-row>
    <b-row v-if="true"><b-col cols="8">
      <b-row v-if="purchase=='yes'">
          <b-col cols="2">{{ $t('option.zzpr') }}</b-col>
          <b-col>
            <v-select  multiple v-if="purchase=='yes'" :options="current_zzpers" label="fullname" v-model="selected_id"></v-select>
          </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="2">{{ $t('titles.companies') }}</b-col>
        <b-col cols="10">
          <v-select  multiple  :options="current_companies" label="company_name" v-model="selected_companies"></v-select>
        </b-col>
        <b-col cols="2">{{ $t('option.zzpr') }}</b-col>
        <b-col cols="10">
          <v-select  multiple  :options="current_zzpers" label="fullname" v-model="selected_id"></v-select>
        </b-col>
      </b-row>
    </b-col></b-row>
    <div v-if="notrunning" class="row">
      <div @click="email_invoices()" class="btn btn-primary">
        <font-awesome-icon icon="envelope" /> {{ $t("buttons.emailinvoices") }}
      </div>
      <div @click="export_invoices()" class="btn btn-primary">
        <font-awesome-icon icon="file-export" /> {{ $t("buttons.exportinvoices") }}  {{ settings.boekhoudprogramma }}
      </div>
    </div>
    <div v-if="notrunning" class="row">
      <div class="col">
        <b-form-checkbox v-model="showcomplete" name="checkbox-1" value="1" unchecked-value="0">
          {{ $t('buttons.showcomplete')}}
        </b-form-checkbox>
      </div>
      <div class="col" v-if="false">
        <b-form-group :label="$t('titles.itemsvisible')">
          <select name="admin" class="form-control admins" v-model="perPage">
            <option value=1000> {{ $t("option.all") }}</option>
            <option value=10>10</option>
            <option value=20>20</option>
            <option value=50>50</option>
            <option value=100>100</option>
          </select>
        </b-form-group>
      </div>
    </div>
    <b-table :items="filteredinvoices" :fields="fields" :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc" :sort-direction="sortDirection" sort-icon-left>
      <template #cell(sale_invoice)="row">
        <a  class="action-item" href="javascript:;" @click="generate_verkoop_factuur(row.item)" :title="$t('labels.invoice_verkoop')">
          <font-awesome-icon v-if="!row.item.pdfgenerating" icon="file-invoice" />
          <b-spinner small v-else></b-spinner>
        </a>
      </template>
      <template #cell(facnuminkoop)="row">
        <p v-if="row.item.inkoopfactuur=='no'">
          <strong>
            <font-awesome-icon v-if="row.item.senddate==null" color="red" icon="exclamation" />

            {{row.item.facnuminkoop}}<br>
            {{dateToNL(row.item.senddate)}}
          </strong>
        </p>
        <p v-else>{{row.item.facnuminkoop}}
        </p>
      </template>
      <template #cell(purchase_invoice)="row">
        <a class="action-item" href ="javascript:;" @click="set_invoice_values(row.item)" :title="$t('buttons.invoiceextrainfo')">
            <font-awesome-icon icon="pen" />
          </a>
        <a class="action-item" v-if="row.item.inkoopfactuur=='yes'" href="javascript:;" @click="generate_factuur(row.item)" :title="$t('labels.invoice_inkoop')">
          <font-awesome-icon v-if="!row.item.pdfgenerating" icon="file-invoice" />
          <b-spinner small v-else></b-spinner>
          </a>
          
        <div v-else>

          <a class="action-item" href ="javascript:;" @click="upload_factuur(row.item)" :title="$t('buttons.addurenbon')">
            <font-awesome-icon icon="upload" />
          </a>
        </div>


      </template>
      <template #cell(sale)="row">
        {{ row.value.toLocaleString(undefined, { minimumFractionDigits: 2, style: "currency", currency: "EUR" }) }}
        <strong>{{ row.item.hourcode?hourcode_by_id(Number(row.item.hourcode))?hourcode_by_id(Number(row.item.hourcode)).name:$t('labels.hourcategoryname'):$t('labels.total') }}</strong>
      </template>
      <template #cell(purchase)="row">
        {{ row.value.toLocaleString(undefined, { minimumFractionDigits: 2, style: "currency", currency: "EUR" }) }} 
        <strong>{{ row.item.hourcode?hourcode_by_id(Number(row.item.hourcode))?hourcode_by_id(Number(row.item.hourcode)).name:$t('labels.hourcategoryname'):$t('labels.total')}}</strong>
      </template>
      <template #cell(purchasemailed)="row">
        <font-awesome-icon v-if="row.item.purchasemailed" icon="check" style="color: green;" />
        <font-awesome-icon v-else icon="times" style="color: red;" />
      </template>
      <template #cell(salemailed)="row">
        <font-awesome-icon v-if="row.item.salemailed" icon="check" style="color: green;" />
        <font-awesome-icon v-else icon="times" style="color: red;" />
      </template>
      <template #cell(purchasemailselect)="row">
        <input  v-if="row.item.inkoopfactuur!='no'" type="checkbox" :id="'pcheck-' + row.item.id" v-model="row.item.purchasemailselect"
          @change="pmailcheck(row.item)">
        <font-awesome-icon v-if="row.item.purchasemailwarning" icon="question"
        class="table-contacts-roles"  />

      </template>
      <template #cell(salemailselect)="row">
        <input type="checkbox" :id="'scheck-' + row.item.id" v-model="row.item.salemailselect"
          @change="smailcheck(row.item)" v-if="company_attachment(row.item)!=1">

        <font-awesome-icon v-else icon="exclamation" class="table-contacts-roles" :title="$t('labels.companyattachmentadd')" />
        <font-awesome-icon v-if="row.item.salemailwarning" icon="question" class="table-contacts-roles"  />
      </template>
      <template #cell(purchaseexported)="row">
        <font-awesome-icon v-if="row.item.purchaseexported" icon="check" style="color: green;" />
        <font-awesome-icon v-else icon="times" style="color: red;" />
      </template>
      <template #cell(saleexported)="row">
        <font-awesome-icon v-if="row.item.saleexported" icon="check" style="color: green;" />
        <font-awesome-icon v-else icon="times" style="color: red;" />
      </template>
      <template #cell(purchaseexportselect)="row">
        <input type="checkbox" :id="'pexportcheck-' + row.item.id" v-model="row.item.purchaseexportselect"
          @change="pexportcheck(row.item)">
        <font-awesome-icon v-if="(row.item.purchaseexportwarning || (row.item.inkoopfactuur=='no' && row.item.senddate==null))" icon="question"
          class="table-contacts-roles" />
      </template>
      <template #cell(saleexportselect)="row">
        <input type="checkbox" :id="'sexportcheck-' + row.item.id" v-model="row.item.saleexportselect"
          @change="sexportcheck(row.item)">
        <font-awesome-icon v-if="row.item.saleexportwarning" icon="question" class="table-contacts-roles" />
      </template>
      <template #cell(lastname)="row" >
        <p v-html="userinfo(row)"  />
      </template>
      <template #cell(invoiceemail)="row">
        <p v-html="invoiceuserinfo(row)" />
      </template>
      <template #cell(company_name)="row">
        <a :href="'/#/company/' + row.item.company_id " :style="{ cursor: 'pointer'}" class="link-item"> {{company_by_id(row.item.company_id).company_name}} </a>
        <div  @click="upload_company_attachment(row.item)" class="btn btn-primary" v-if="company_attachment(row.item)==1" >
          <font-awesome-icon  icon="file-contract" color="white"/> {{ $t('labels.companyattachmentadd') }} 
        </div>
        <div  @click="view_company_attachment(row.item)" class="btn btn-primary" v-if="company_attachment(row.item)==2" >
          <font-awesome-icon  icon="file-contract" color="green" /> {{ $t('labels.companyattachmentview') }}
        </div>        
      </template>
      <template #cell(urenbonnen)="row" style="width:80px"> 
        <a v-if="row.item.urenbonnen.length>0" class="action-item" href ="javascript:;" @click="showurenbonnen(row.item)" :title="$t('labels.showurenbon')">
            <font-awesome-icon icon="upload" /> 
        </a>
        <font-awesome-icon v-else icon="times" color="red"/>
        <font-awesome-icon v-if="row.item.urenbonnen.length>0 && row.item.urenbonnen.filter(u => u.name.substring(0,4)=='bon_').length>0" icon="question" color="red" />

        <font-awesome-icon v-if="current_user.id==1" icon="trash" color="red" @click="removeinvoice(row)"/>  
      </template>      
    </b-table>
    <b-pagination v-if="false" v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>

    <div v-if="notrunning">
      <div @click="email_invoices()" class="btn btn-primary">
        <font-awesome-icon icon="envelope" /> {{ $t("buttons.emailinvoices") }}
      </div>
      <div @click="export_invoices()" class="btn btn-primary">
        <font-awesome-icon icon="file-export" /> {{ $t("buttons.exportinvoices") }} {{ settings.boekhoudprogramma }}
      </div>
      <div v-if="current_user.id==1" @click="email_status(1)" class="btn btn-primary">
        <font-awesome-icon icon="check" /> set email send
      </div>
      <div v-if="current_user.id==1" @click="email_status(0)" class="btn btn-primary">
        <font-awesome-icon icon="times" /> set email not send
      </div>      
      <div v-if="current_user.id==1" @click="export_status(1)" class="btn btn-primary">
        <font-awesome-icon icon="check" /> set export on
      </div>   
      <div v-if="current_user.id==1" @click="export_status(0)" class="btn btn-primary">
        <font-awesome-icon icon="times" /> set export off
      </div>            
    </div>
    <BSUploadUserModal :contract_id="contract_id" :weeknumber="weeknumber" :yearnumber="yearnumber"  :companyattachment="companyattachment"/>

    <BSSetInvoiceData :factuur_id="showinvoice" />
    <BSManageUrenbonnenModalID :weekurenbonnen="urenbonnenfromrow" />
    <BSShowinvoiceDataID v-if="selectedinvoice!=null"  :contract="selectedinvoice" :inkoop="purchase=='yes'"/>
  </b-container>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import axios from 'axios'
import popup_modal from './modals/PopupModal.vue'
import BSSetInvoiceData from './modals/BSSetInvoiceData.vue'
import BSUploadUserModal from './modals/BSUploadUrenModal.vue'
import BSManageUrenbonnenModalID from './modals/BSManageUrenbonnenModal.vue'
import BSShowinvoiceDataID from './modals/BSShowInvoiceData.vue'
import popup_confirm from './modals/PopupConfirm.vue'

// import router from '../router'

const Dutch = require("flatpickr/dist/l10n/nl.js").default.nl;



export default {
  name: 'InvoicesScreen',
  data() {
    return {
      startdate: "",
      enddate: "",
      purchase: 'yes',
      sortBy: 'agree_date',
      sortDesc: true,
      sortDirection: 'asc',       
      notrunning: true,
      filter: null,
      showinvoice:0,
      weeknumber:0,
      yearnumber:0,
      contract_id:0,
      companyattachment:false,
      internattachment:false,
      showcomplete:0,
      currentPage: 1,
      perPage: 20,       
      totalRows:0,
      urenbonnenfromrow:[],
      selectedinvoice:null,
      config: {
        weekNumbers: true,
        locale: Dutch,
        altInput: true,
        altFormat: "d-m-Y",
        dateFormat: "Y-m-d",
      },
      selectedID:0,
      gen_invoice_busy:false,
      allinvoices_gen:[],
      selected_id:null,
      selected_companies:null
    }
  },
  mounted() {
    console.log("mounted invoices")
    this.startdate =this.getMonday(new Date())
    this.enddate = new Date();
    this.enddate.setDate(this.startdate.getDate()+7)
    // this.startdate = new Date("2023-12-25");
    // this.enddate = new Date("2023-11-27");
  },
  beforeMount() {
    this.purchase = this.$route.params.sale=='purchase'?"yes":"no"
  },  
  computed: {
    filteredinvoices(){
    
        if(this.purchase=='yes'){
          if(this.selected_id==null || this.selected_id.length==0){
            return this.invoicedata
          } else {
            let ids = [...new Set(this.selected_id.map(item => item.id))]
            return this.invoicedata.filter(iv=>ids.indexOf(iv.user_id)!=-1)
          } 
        } else {
          if((this.selected_companies==null || this.selected_companies.length==0) && (this.selected_id==null || this.selected_id.length==0)){
            return this.invoicedata
          } else {
            if((this.selected_companies!=null && this.selected_companies.length>0) && (this.selected_id==null || this.selected_id.length==0)){
              let company_ids = [...new Set(this.selected_companies.map(item => item.id))]
              return this.invoicedata.filter(iv=>company_ids.indexOf(iv.company_id)!=-1)
            }
            if((this.selected_companies==null || this.selected_companies.length==0) && (this.selected_id!=null || this.selected_id.length>0)){
              let ids = [...new Set(this.selected_id.map(item => item.id))]
              return this.invoicedata.filter(iv=>ids.indexOf(iv.user_id)!=-1)
            }
            if((this.selected_companies!=null || this.selected_companies.length>0) && (this.selected_id!=null || this.selected_id.length>0)){
              let company_ids = [...new Set(this.selected_companies.map(item => item.id))]
              let ids = [...new Set(this.selected_id.map(item => item.id))]
              return this.invoicedata.filter(iv=>ids.indexOf(iv.user_id)!=-1).filter(iv=>company_ids.indexOf(iv.company_id)!=-1)
            }
          }
        }
        return null
    },
    invoiceType() {
      return this.purchase=="yes"?this.$i18n.t('labels.purchase'):this.$i18n.t('labels.sale')
    },
    current_zzpers(){
      let ids = [...new Set(this.invoicedata.map(item => item.user_id))]

      return this.zzpers.filter(u => ids.indexOf(u.id) !=-1)
    },
    current_companies(){
      let ids = [...new Set(this.invoicedata.map(item => item.company_id))]

      return this.companies.filter(c => ids.indexOf(c.id) != -1)
    },
    dates() {
      var loop = new Date(this.startdate);
      var endd = new Date(this.enddate);
      let alldates = []
      let weeks =[]
      while (loop <= endd) {
        let thisday = this.dateToYYYYMMDD(loop)
        var newDate = loop.setDate(loop.getDate() + 1);
        alldates.push(thisday)
        loop = new Date(newDate);
        var week = this.getWeekNumbervalue(new Date(thisday))
        if(weeks.indexOf(week)==-1){
          weeks.push(week)
        }

      }
      return alldates;
    },
    weeks() {
      var loop = new Date(this.startdate);
      var endd = new Date(this.enddate);
      let locweeks =[]
      while (loop <= endd) {
        let thisday = this.dateToYYYYMMDD(loop)
        var newDate = loop.setDate(loop.getDate() + 1);
        loop = new Date(newDate);
        var week = this.getWeekNumbervalue(new Date(thisday))
        if(locweeks.indexOf(week)==-1){
          locweeks.push(week)
        }

      }
      return locweeks;
    },    
    fields() {
      return this.purchase == 'yes' ?
        [
          { key: 'agree_date' ,label: this.$i18n.t('tableheaders.signed'), sortable: true, sortDirection: 'asc'},
          { key: 'week', label: this.$i18n.t('labels.week'), sortable: true, sortDirection: 'desc' },
          { key: 'facnuminkoop' , sortable: true, sortDirection: 'asc'},
          { key: 'boekstuknummer' , sortable: true, sortDirection: 'asc'},
          { key: 'lastname', label: this.$i18n.t('labels.ZZPer'), sortable: true, sortDirection: 'desc' },
          { key: 'company_name', label: this.$i18n.t('labels.client'), sortable: true, sortDirection: 'desc' },
          { key: 'purchase', label: this.$i18n.t('labels.amount'), sortable: true, sortDirection: 'desc' },
          { key: 'purchasemailed', label: this.$i18n.t('labels.ismailed'), sortable: true, sortDirection: 'desc'},
          { key: 'purchasemailselect', label: this.$i18n.t('labels.mail') },
          { key: 'purchase_invoice', label: this.$i18n.t('labels.invoice'), sortable: false, sortDirection: 'desc' },
          { key : 'urenbonnen', label: this.$i18n.t('titles.urenbon')},
          { key: 'purchaseexported', label: this.$i18n.t('labels.isexported'), sortable: true, sortDirection: 'desc'},
          { key: 'purchaseexportselect', label: this.$i18n.t('labels.export') },

        ] :
        [
          { key: 'agree_date' ,label: this.$i18n.t('tableheaders.signed'), sortable: true, sortDirection: 'asc'},
          { key: 'week', label: this.$i18n.t('labels.week'), sortable: true, sortDirection: 'desc' },
          { key: 'facnumverkoop' , sortable: true, sortDirection: 'asc'},
          { key: 'company_name', label: this.$i18n.t('labels.client') , sortable: true, sortDirection: 'desc' },
          { key: 'invoiceemail' , label: this.$i18n.t('labels.contactperson')},
          { key: 'lastname', label: this.$i18n.t('labels.ZZPer'), formatter: "username_by_id", sortable: true, sortDirection: 'desc' },
          { key: 'sale', label: this.$i18n.t('labels.amount'), sortable: true, sortDirection: 'desc' },
          { key: 'salemailed', label: this.$i18n.t('labels.ismailed') , sortable: true, sortDirection: 'desc' },
          { key: 'salemailselect', label: this.$i18n.t('labels.mail') },
          { key: 'sale_invoice', label: this.$i18n.t('labels.invoice'), sortable: true, sortDirection: 'desc' },

          { key : 'urenbonnen', label: this.$i18n.t('titles.urenbon')},
          { key: 'saleexported', label: this.$i18n.t('labels.isexported') , sortable: false, sortDirection: 'desc' },
          { key: 'saleexportselect', label: this.$i18n.t('labels.export') },
        ]
    },
    invoicedata() {
      let ret = []
      //  return ret
      let locweek = this.weeks

      this.facturen.filter(f => locweek.indexOf(f.week)!=-1 && this.validinperiode(f)).forEach(factuur => {
        let contract = this.contract_by_id(factuur.contract_id)
        if (contract) {
          let inkoopfactuur = (this.planning.filter(plan => plan.user_id == contract.user_id).filter(plan =>{
            return plan.project_id == contract.project_id
            }))[0].inkoopfactuur
            let week = factuur.week
            let thisYear = this.getYearvalue(new Date(this.startdate))
            if(week<locweek[0]){
              thisYear+=1
            }
            ret.push(
            {
              id: factuur.id,
              week: factuur.week<10? "0"+factuur.week + "-" + thisYear:factuur.week + "-" + thisYear,
              contract_id: factuur.contract_id,
              hourcode: factuur.hourcode,
              company_id: this.project_by_id(contract.project_id).company_id,
              company_name: this.company_by_id(this.project_by_id(contract.project_id).company_id).company_name,
              lastname: this.user_by_id(contract.user_id).lastname,
              user_id: contract.user_id,
              inkoopfactuur:inkoopfactuur,
              purchase:this.getWeekTotalInvoice(factuur.contract_id, factuur.week, thisYear, false,factuur.hourcode),
              sale: this.getWeekTotalInvoice(factuur.contract_id, factuur.week, thisYear, true,factuur.hourcode),
              purchase_invoice: this.server_url + '/media_invoices/factuur-' + factuur.contract_id + "-" + contract.user_id + "-" + week + ".pdf",
              sale_invoice: this.server_url + '/media_invoices/verkoopfactuur-' + factuur.contract_id + "-" + contract.user_id + "-" + week + ".pdf",
              purchasemailed: inkoopfactuur!='no'?factuur.mailedinkoop:true,
              salemailed: factuur.mailedverkoop,
              purchasemailselect: factuur.mailedinkoop == 1 ? 0 : inkoopfactuur!='no'?1:0,
              salemailselect: this.checkSaleEmailSelect(factuur), //factuur.mailedverkoop == 1 ? 0 : 1,
              purchasemailwarning: false,
              salemailwarning: false,
              purchaseexported: factuur.exportedinkoop,
              saleexported: factuur.exportedverkoop,
              purchaseexportselect: factuur.exportedinkoop == 1 ? 0 : 1,
              saleexportselect: factuur.exportedverkoop == 1 ? 0 : 1,
              purchaseexportwarning: false,
              saleexportwarning: false,
              pdfgenerating: false,
              facnumverkoop: factuur.facnumverkoop,
              facnuminkoop: factuur.facnuminkoop,
              senddate: factuur.senddate,
              boekstuknummer: factuur.boekstuknummer,
              urenbonnen: this.urenbonnen.filter(bon => bon.contract_id == factuur.contract_id && bon.week == factuur.week),
              invoiceemail: this.company_by_id(this.project_by_id(contract.project_id).company_id).invoice_contants[0] ? this.user_by_id(this.company_by_id(this.project_by_id(contract.project_id).company_id).invoice_contants[0]).id : null,
              agree_date: this.getWeekInvoiceSignDate(factuur.contract_id, factuur.week, thisYear),
              _cellVariants: this.debnumcheck(contract.user_id)
            })
        }
      });      
      // this.facturen.forEach(factuur => {
      //   for (let date of this.dates) {
      //     var week = this.getWeekNumbervalue(new Date(date))
      //     if (factuur.week == week) {
      //       let contract = this.contract_by_id(factuur.contract_id)
      //       if (contract) {
      //         let inkoopfactuur = (this.planning.filter(plan => plan.user_id == contract.user_id).filter(plan =>{
      //           return plan.project_id == contract.project_id
      //           }))[0].inkoopfactuur
      //         ret.push(
      //           {
      //             id: factuur.id,
      //             week: factuur.week<10? "0"+factuur.week + "-" + this.getYearvalue(new Date(date)):factuur.week + "-" + this.getYearvalue(new Date(date)),
      //             contract_id: factuur.contract_id,
      //             hourcode: factuur.hourcode,
      //             company_name: this.company_by_id(this.project_by_id(contract.project_id).company_id).company_name,
      //             lastname: this.user_by_id(contract.user_id).lastname,
      //             user_id: contract.user_id,
      //             inkoopfactuur:inkoopfactuur,
      //             purchase: this.getWeekTotalInvoice(factuur.contract_id, factuur.week, this.getYearvalue(new Date(date)), false,factuur.hourcode),
      //             sale: this.getWeekTotalInvoice(factuur.contract_id, factuur.week, this.getYearvalue(new Date(date)), true,factuur.hourcode),
      //             purchase_invoice: this.server_url + '/media_invoices/factuur-' + factuur.contract_id + "-" + contract.user_id + "-" + week + ".pdf",
      //             sale_invoice: this.server_url + '/media_invoices/verkoopfactuur-' + factuur.contract_id + "-" + contract.user_id + "-" + week + ".pdf",
      //             purchasemailed: inkoopfactuur!='no'?factuur.mailedinkoop:true,
      //             salemailed: factuur.mailedverkoop,
      //             purchasemailselect: factuur.mailedinkoop == 1 ? 0 : inkoopfactuur!='no'?1:0,
      //             salemailselect: factuur.mailedverkoop == 1 ? 0 : 1,
      //             purchasemailwarning: false,
      //             salemailwarning: false,
      //             purchaseexported: factuur.exportedinkoop,
      //             saleexported: factuur.exportedverkoop,
      //             purchaseexportselect: factuur.exportedinkoop == 1 ? 0 : 1,
      //             saleexportselect: factuur.exportedverkoop == 1 ? 0 : 1,
      //             purchaseexportwarning: false,
      //             saleexportwarning: false,
      //             pdfgenerating: false,
      //             facnumverkoop: factuur.facnumverkoop,
      //             facnuminkoop: factuur.facnuminkoop,
      //             senddate: factuur.senddate,
      //             boekstuknummer: factuur.boekstuknummer,
      //             urenbonnen: this.urenbonnen.filter(bon => bon.contract_id == factuur.contract_id && bon.week == factuur.week),
      //             invoiceemail: this.company_by_id(this.project_by_id(contract.project_id).company_id).invoice_contants[0] ? this.user_by_id(this.company_by_id(this.project_by_id(contract.project_id).company_id).invoice_contants[0]).id : null,
      //             agree_date: this.getWeekInvoiceSignDate(factuur.contract_id, factuur.week, this.getYearvalue(new Date(date))),
      //           })
      //       }
      //       break
      //     }
      //   }
      // });
    
      return ret.filter(id =>{
        if(this.purchase=="yes"){
          return (id.purchaseexported!=true || id.purchasemailed !=true) ||
                (id.purchaseexported==true && this.showcomplete=='1' && id.purchasemailed==true)

        } else {
          return (id.saleexported!=true || id.salemailed !=true) ||
                (id.saleexported==true && this.showcomplete=='1' && id.salemailed==true)
        }
      })
    },
    allinvoices(){
      if(this.allinvoices_gen.length>0){
        return this.allinvoices_gen
      } else {
        this.generateallinvoices()
        return this.allinvoices_gen;
      }
    },

    ...mapState([
      'facturen',
      'hours',
      'settings',
      'urenbonnen',
      'planning',
      'current_user',
      'users',
      'companies'
    ]),
    ...mapGetters([
      'server_url',
      'username_by_id',
      'user_by_id',
      'project_by_id',
      'contract_by_id',
      'company_by_id',
      'factuur_by_id',
      'confirm_modal',
      'createAny',
      'hourcode_by_id',
      'zzpers'
    ]),
  },
  methods: {
    ...mapActions([
      'delete_factuur'
    ]),
    debnumcheck(debcheck){
      // console.log("debcheck", debcheck)
      if(debcheck && this.user_by_id(debcheck).debnum==undefined){
        return {lastname:'warning'}
      }
      return {}
    },
    validinperiode(invoice){
      let facyear =  "20"+invoice.facnumverkoop.substring(1,3)
      let d = new Date("Jan 01, " +facyear + " 01:00:00");
      var w
      if(d.getDay()==1){
        w = d.getTime() + 604800000 * (invoice.week-1);
      } else{
        w = d.getTime() + 604800000 * (invoice.week);
      }
      // console.log(new Date(w))



      // console.log(invoice.facnumverkoop.substring(1,3),invoice.week,   this.weeks)
      return new Date(w)>=new Date(this.startdate)
    },
    generateallinvoices(){
      let result = [];
        this.facturen.forEach(fac => {
          let contract = this.contract_by_id(fac.contract_id)
          if(contract){
            // remove purchase invoices when ZZP-er generates own invoices
            let cpfac={}
            cpfac.week  = fac.week;
            cpfac.hourcode  = fac.hourcode;
            cpfac.facnuminkoop = fac.facnuminkoop + " " + this.username_by_id(contract.user_id)+ " Wk" + fac.week
            cpfac.facnumverkoop = fac.facnumverkoop + " " + this.username_by_id(contract.user_id)+ " Wk" + fac.week
            cpfac.user_id = contract.user_id
            cpfac.contract_id = fac.contract_id

            // let inkoopfactuur = (this.planning.filter(plan => plan.user_id == this.contract_by_id(fac.contract_id).user_id).filter(plan =>{
            //   return plan.project_id == this.contract_by_id(fac.contract_id).project_id
            //   }))[0].inkoopfactuur
            //   console.log(inkoopfactuur, this.purchase)


            if(this.purchase=='no' ||
              this.planning.filter(plan => plan.user_id==cpfac.user_id && plan.project_id == contract.project_id)[0].inkoopfactuur=='yes') {
              result.push(cpfac)
            }
          }
        })
        this.allinvoices_gen = result.reverse();      
    },
    userinfo(row) {
      return '<a href="/#/user/' + row.item.user_id + '" :style="{ cursor: \'pointer\'}" class="link-item"> ' + this.username_by_id(row.item.user_id) + '</a><br/>' +
        '<a href="mailto:' + this.user_by_id(row.item.user_id).email + '" class="link-item">' + this.user_by_id(row.item.user_id).email + '</a>'
    },
    invoiceuserinfo(row) {
      return row.item.invoiceemail ? '<a href="/#/user/' + row.item.invoiceemail + '" :style="{ cursor: \'pointer\'}" class="link-item"> ' + this.username_by_id(row.item.invoiceemail) + '</a><br/>' +
        '<a href="mailto:' + this.user_by_id(row.item.invoiceemail).email + '" class="link-item">' + this.user_by_id(row.item.invoiceemail).email + '</a>' : this.$i18n.t('validation.invoicecontactmissing')
    },
    company_attachment(item){
      // console.log("><>",this.company_by_id(item.company_id).needsattachment)
      if(this.company_by_id(item.company_id).needsattachment=='yes'){
        if(item.urenbonnen.filter(u => u.name.substring(0,8)=='company_').length>0){
          return 2
        } else {
          return 1
        }
      } else {
        return 0
      }
    },
    
    companyinfo(row) {
      let company = this.company_by_id(this.project_by_id(this.contract_by_id(row.item.contract_id).project_id).company_id)
      return '<a href="/#/company/' + company.id + '" :style="{ cursor: \'pointer\'}" class="link-item"> ' + company.company_name + '</a>'
    },
    email_invoices() {
      //check for items already exported
      var showWarning = false
      this.filteredinvoices.forEach(invoiceline => {
        if (this.purchase == 'yes') {
          if (invoiceline.purchasemailwarning) {
            showWarning = true;
          }
        } else {
          if (invoiceline.salemailwarning) {
            showWarning = true;
          }
        }
      })
      if (showWarning) {
        let message = this.$i18n.t('labels.confirmmailinvoices')
        this.$modal.hide('popup_modal')
        this.$modal.show(popup_modal, {
          message: message,
        }, {
          name: 'popup_modal',
          draggable: false,
          height: 'auto',
          width: '500px'
        }, {
          'before-close': this.checkEmailModalCheck
        })

        this.$emit('close')
      } else {
        this.reallyMail()
      }
    },
    checkEmailModalCheck() {
      if (this.confirm_modal.id == 1) {
        this.reallyMail()
      }
    },
    checkSaleEmailSelect(factuur){
      let ret = 0
      if (factuur.mailedverkoop == 1) {
        ret = 0
      } else {
        let contract = this.contract_by_id(factuur.contract_id)
        if(this.company_by_id(this.project_by_id(contract.project_id).company_id).needsattachment=='yes'){
          let cu =  this.urenbonnen.filter(u=>u.contract_id == factuur.contract_id && u.week == parseInt(factuur.week) && u.name.substring(0,8)=='company_')
          // console.log("ÜB", cu)
          if(cu.length>0){
            ret = 1
          } else {
            ret = 0
          }
        } else {
          ret = 1
        }
      }
      return ret

    },
    async reallyMail() {
      this.notrunning = false
      // var invoice
      // await this.invoicedata.forEach(invoiceline => {
      for await(const invoiceline of this.filteredinvoices){
        if (this.purchase == 'yes') {
          if (invoiceline.purchasemailselect == 1) {
            // console.log(invoiceline)
            // generate invoice
            let data = {}
            data.dates = this.getWeekDates(invoiceline.week.substring(0, 2), invoiceline.week.substring(3))
            data.user_id = invoiceline.user_id
            data.contract_id = invoiceline.contract_id
            data.hourcode = invoiceline.hourcode?parseInt(invoiceline.hourcode):null

            data.week = parseInt(invoiceline.week.substring(0, 2))
            data.inkoopfactuur =  invoiceline.inkoopfactuur
            data.id = invoiceline.id
            await this.sendPurchaseInvoiceEmail(data)
          }
        } else {
          if (invoiceline.salemailselect == 1) {
            // generate invoice
            let data = {}
            data.dates = this.getWeekDates(invoiceline.week.substring(0, 2), invoiceline.week.substring(3))
            data.user_id = invoiceline.user_id
            data.contract_id = invoiceline.contract_id
            data.week = parseInt(invoiceline.week.substring(0, 2))
            data.year = invoiceline.week.substring(3)
            data.hourcode = invoiceline.hourcode?parseInt(invoiceline.hourcode):null
            data.id = invoiceline.id
            data.invoiceemail = invoiceline.invoiceemail
            await this.sendSaleInvoiceEmail(data)
          }
        }
      }
      this.notrunning = true

    },
    export_invoices() {
      //check for items already exported
      var showWarning = false
      var manualinvoicesValid = true
      this.filteredinvoices.forEach(invoiceline => {
        if (this.purchase == 'yes') {
          if (invoiceline.purchaseexportwarning) {
            showWarning = true;
          }
          if(invoiceline.inkoopfactuur=='no' && invoiceline.senddate==null && invoiceline.purchaseexportselect == 1){
            manualinvoicesValid = false
          }
        } else {
          if (invoiceline.saleexportwarning) {
            showWarning = true;
          }
        }
      })
      if (showWarning || !manualinvoicesValid) {

        let message =""
        if(showWarning) {
          message = this.$i18n.t('labels.confirmexportinvoices')
        }
        if(!manualinvoicesValid){
          if(message=="") {
            message = " "+this.$i18n.t('labels.confirmexportmanual')
          } else {
            message += "\n"+this.$i18n.t('labels.confirmexportmanual')
          }
        }
        this.$modal.hide('popup_modal')
        this.$modal.show(popup_modal, {
          message: message,
        }, {
          name: 'popup_modal',
          draggable: false,
          height: 'auto',
          width: '500px'
        }, {
          'before-close': this.checkExportModalCheck
        })

        this.$emit('close')
      } else {
        this.reallyExport()
      }
    },
    checkExportModalCheck() {
      if (this.confirm_modal.id == 1) {
        this.reallyExport()
      }
    },

    async reallyExport() {
      // make sure boekhoudgemak is active
      console.log(this.settings.boekhoudprogramma, this.settings.database, this.settings.database=="" || this.settings.database==undefined)
      if(this.settings.boekhoudprogramma!='snelstart'){
        if (this.settings.boekhoudprogramma=='' ||this.settings.boekhoudprogramma==undefined || this.settings.database=="" || this.settings.database==undefined) {
          console.log("boekhoudgemak not active, return")
          return
        }
      }
      this.notrunning = false
      for await (const invoiceline of this.filteredinvoices.filter(iv => iv.purchaseexportselect == 1 || iv.saleexportselect ==1)) {
        // console.log(invoiceline, this.purchase)
        // console.log(this.planning_by_id(this.contract_by_id(invoiceline.contract_id).planning_id))
        // console.log("project",this.project_by_id(this.contract_by_id(invoiceline.contract_id).project_id))
        // console.log("user",this.user_by_id(this.contract_by_id(invoiceline.contract_id).user_id))
        // console.log("company",this.company_by_id(this.project_by_id(this.contract_by_id(invoiceline.contract_id).project_id).company_id))
        var planningdata = this.planning_by_id(this.contract_by_id(invoiceline.contract_id).planning_id)
        var invoicedata = {}
        var now = new Date()
        var customerInvoiceLine = {}
        var vatTransactionLine = {}
        var invoice
        if (this.purchase == 'yes') {
          if (invoiceline.purchaseexportselect == 1) {
            // console.log(invoiceline)
            if(this.settings.boekhoudprogramma=='boekhoudgemak'){
              invoicedata = {}
              now = new Date()
              invoicedata.fiscalYear = now.getFullYear()
              if(invoiceline.inkoopfactuur=='yes') {
                invoicedata.invoiceDate = this.dateToNL(now)
              } else {
                invoicedata.invoiceDate = invoiceline.senddate
              }
              now.setDate(now.getDate() + planningdata.payment_zzprnl_zzpr);
              invoicedata.invoiceExpirationDate = this.dateToNL(now)
              if (this.user_by_id(this.contract_by_id(invoiceline.contract_id).user_id).country != "NL") {
                invoicedata.journalId = this.settings.purchasenotshiftEUlogbook
                invoicedata.vatScenarioId = Number(this.settings.purchasenotshiftEUvatscenario)
              } else {
                if (planningdata.btw_verlegd == "yes") {
                  invoicedata.journalId = this.settings.purchaseshiftlogbook
                  invoicedata.vatScenarioId = Number(this.settings.purchaseshiftvatscenario)
                } else {
                  invoicedata.journalId = this.settings.purchasenotshiftlogbook
                  invoicedata.vatScenarioId = Number(this.settings.purchasenotshiftvatscenario)
                }
              }
              invoicedata.paymentConditionId = planningdata.payment_zzprnl_zzpr
              invoicedata.paymentReference = invoiceline.facnuminkoop
              invoicedata.periodNumber = new Date().getMonth() + 1 //0 == january
              if(invoiceline.inkoopfactuur=='yes') {
                invoicedata.rebateExpirationDate = this.dateToNL(now)
              } else {
                invoicedata.rebateExpirationDate = invoiceline.senddate
              }
              invoicedata.reference = planningdata.project_reference_number ?
                (invoiceline.facnuminkoop + " " + "Wk "+invoiceline.week +  " " +  planningdata.project_reference_number + " " + planningdata.project_name).substring(0, 30) :
                (invoiceline.facnuminkoop + " " + "Wk "+invoiceline.week +  " " +  planningdata.project_name).substring(0, 30)
              invoicedata.supplierId = this.user_by_id(this.contract_by_id(invoiceline.contract_id).user_id).debnum

              if (this.user_by_id(this.contract_by_id(invoiceline.contract_id).user_id).country != "NL") {
                invoicedata.VatAmountCur = (0.21 * invoiceline.purchase).toFixed(2)
                invoicedata.vatScenarioId = Number(this.settings.purchasenotshiftEUvatscenario)
                invoicedata.totalAmountVatExclCur = invoiceline.purchase.toFixed(2)
                invoicedata.vatOnInvoice = true
              } else {
                if (planningdata.btw_verlegd == "yes") {
                  // invoicedata.VatAmountCur = 0
                  invoicedata.VatAmountCur = (0.21 * invoiceline.purchase).toFixed(2)
                  invoicedata.vatScenarioId = Number(this.settings.purchaseshiftvatscenario)
                  invoicedata.totalAmountVatExclCur = invoiceline.purchase.toFixed(2)
                  invoicedata.vatOnInvoice = false

                } else {
                  invoicedata.VatAmountCur = (0.21 * invoiceline.purchase).toFixed(2)
                  invoicedata.vatScenarioId =  Number(this.settings.purchasenotshiftvatscenario)
                  invoicedata.totalAmountVatExclCur = invoiceline.purchase.toFixed(2)
                  invoicedata.vatOnInvoice = true
                  // invoice.vatAdjusted = false

                }
              }
              invoicedata.supplierInvoiceLines = []
              // invoiceLines
              customerInvoiceLine = {}
              if (this.user_by_id(this.contract_by_id(invoiceline.contract_id).user_id).country != "NL") {
                customerInvoiceLine.accountId = this.settings.purchasenotshiftEUledgeraccount
                customerInvoiceLine.vatAmount = "0.00"
              } else {
                if (planningdata.btw_verlegd == "yes") {
  //                invoicedata.VatAmountCur = 0.21 * invoiceline.purchase
                  invoicedata.VatAmountCur = "0.00"
                  customerInvoiceLine.accountId = this.settings.purchaseshiftledgeraccount
                  customerInvoiceLine.vatAmountCur = "0.00"
                  customerInvoiceLine.vatAmount = "0.00"


                } else {
                  customerInvoiceLine.accountId = this.settings.purchasenotshiftledgeraccount
                  customerInvoiceLine.vatAmount = "0.00"
                }
              }
              customerInvoiceLine.debitAmountCur = invoiceline.purchase.toFixed(2)
              customerInvoiceLine.transactionDate = this.dateToNL(new Date())
              customerInvoiceLine.vatCodeId = 2
              customerInvoiceLine.vatType = 0
              customerInvoiceLine.description = this.username_by_id(this.contract_by_id(invoiceline.contract_id).user_id) + " Week "+invoiceline.week + " " + invoiceline.facnuminkoop
              invoicedata.supplierInvoiceLines.push(customerInvoiceLine)

              //vattransactionlines
              invoicedata.vatTransactionLines = []
              vatTransactionLine = {}
              vatTransactionLine.amountTurnoverCur = invoiceline.purchase
              vatTransactionLine.vatType = 0

              if (this.user_by_id(this.contract_by_id(invoiceline.contract_id).user_id).country != "NL") {
                vatTransactionLine.VatAmountCur = (0.21 * invoiceline.purchase).toFixed(2)
                vatTransactionLine.vatCodeId = Number(this.settings.purchasenotshiftEUvatcode)
                vatTransactionLine.vatScenarioId = Number(this.settings.purchasenotshiftEUvatscenario)
              } else {
                if (planningdata.btw_verlegd == "yes") {
                  // vatTransactionLine.VatAmountCur = 0
                  vatTransactionLine.VatAmountCur = (0.21 * invoiceline.purchase).toFixed(2)
                  vatTransactionLine.vatCodeId = Number(this.settings.purchaseshiftvatcode)
                  vatTransactionLine.vatScenarioId = Number(this.settings.purchaseshiftvatscenario)

                } else {
                  vatTransactionLine.VatAmountCur = (0.21 * invoiceline.purchase).toFixed(2)
                  vatTransactionLine.vatCodeId = Number(this.settings.purchasenotshiftvatcode)
                  vatTransactionLine.vatScenarioId = Number(this.settings.purchasenotshiftvatscenario)
                }
              }
              invoicedata.vatTransactionLines.push(vatTransactionLine)

              console.log(invoicedata, invoice)


              try {
                const response = await axios.post('/api/create_inkoop', invoicedata)
                if (response) {
                  console.log('succes', response)
                  console.log('iv',invoiceline.id)
                  if (response.status == 200) {
                    // console.log("mark as exported")
                    invoice = this.factuur_by_id(invoiceline.id)
                    invoice.exportedinkoop = 1
                    await axios.post('/api/set_purchase_exported', { id: invoiceline.id, exportedinkoop: 1 })
                    if(response.data.invoiceId){
                      invoice.boekstuknummer = response.data.invoiceId
                      await axios.post('/api/set_purchase_boekstuknummer', { id: invoiceline.id, boekstuknummer: response.data.invoiceId })
                    }
                  }
                  if (response.data.status && response.data.status != 200) {
                    console.log("DAS MIS", response.data.messages)
                    let errormessages = ""
                    response.data.messages.forEach(element => {
                        errormessages += element.message + "<br>"

                    });
                    invoice = this.factuur_by_id(invoiceline.id)
                    invoice.exportedinkoop = 0
                    axios.post('/api/set_purchase_exported', { id: invoiceline.id, exportedinkoop: 0 })
                    this.$modal.hide('popup_modal')
                    this.$modal.hide('popup_confirm')
                    this.$modal.show(popup_confirm, {
                        message: errormessages,
                        header: this.$t('labels.boekhoudgemakfout')+ " "+invoice.facnuminkoop,
                    }, {
                        name: 'popup_confirm',
                        draggable: false,
                        height: 'auto',
                        width: '90%'
                    })
                  }                
                }
              } catch (err) {
                console.log(err)
              }
            }
            if(this.settings.boekhoudprogramma=='snelstart'){
              console.log("Snelstart inkoop", invoiceline)
              invoicedata = {}
              now = new Date()
              invoicedata.factuurnummer  = invoiceline.facnuminkoop
              if(invoiceline.inkoopfactuur=='yes') {
                invoicedata.factuurdatum = this.dateToYYYYMMDD(now)
              } else {
                invoicedata.factuurdatum = invoiceline.senddate
              }
              invoicedata.boekstuk = invoiceline.boekstuknummer
              invoicedata.leverancier={}
              invoicedata.leverancier.id = this.user_by_id(invoiceline.user_id).debnum
              invoicedata.omschrijving = this.username_by_id(this.contract_by_id(invoiceline.contract_id).user_id) + " Week "+invoiceline.week + " " + invoiceline.facnuminkoop
              invoicedata.factuurbedrag = invoiceline.purchase.toFixed(2)
              invoicedata.boekingsregels = [];
              invoicedata.btw = [];
              var gbline = {}
              var btwline = {}
              gbline.omschrijving = "Week "+invoiceline.week + " " + invoiceline.facnuminkoop,
              gbline.bedrag =  invoiceline.purchase.toFixed(2)
              gbline.grootboek = {}
              gbline.grootboek.id = ""
              if (this.user_by_id(this.contract_by_id(invoiceline.contract_id).user_id).country != "NL") {
                customerInvoiceLine.accountId = this.settings.purchasenotshiftEUledgeraccount
                gbline.grootboek.id = this.settings.purchasenotshiftEUledgeraccountss.guid
                gbline.btwSoort = "Geen"

              } else {
                if (planningdata.btw_verlegd == "yes" ||planningdata.btw_verlegd == "kor") {
                  gbline.grootboek.id =  this.settings.purchaseshiftledgeraccountss.guid
                  if(planningdata.btw_verlegd == "kor"){
                    gbline.grootboek.id =  this.settings.purchasekors.guid
                  }
                  // gbline.btwSoort = ""
                  // btwline.btwSoort = ""
                  // btwline.btwBedrag = 0
                  // invoicedata.btw.push(btwline)                  
                } else {
                  if (planningdata.btw_verlegd == "low") {
                    gbline.grootboek.id =  this.settings.purchasenotshiftledgeraccountss.guid
                    gbline.btwSoort = "Laag"
                    let total = invoiceline.purchase*1.09
                    invoicedata.factuurbedrag = total.toFixed(3)

                    gbline.bedrag =  invoiceline.purchase.toFixed(3)

                    btwline.btwSoort = "InkopenLaag"
                    btwline.btwBedrag = (parseFloat(invoicedata.factuurbedrag)-parseFloat(gbline.bedrag)).toFixed(2)
                    invoicedata.btw.push(btwline)

                  } else{
                    gbline.grootboek.id =  this.settings.purchasenotshiftledgeraccountss.guid
                    let total = invoiceline.purchase*1.21
                    invoicedata.factuurbedrag = total.toFixed(2)

                    gbline.btwSoort = "Hoog"
                    gbline.bedrag =  invoiceline.purchase.toFixed(2)

                    btwline.btwSoort = "InkopenHoog"
                    btwline.btwBedrag = (parseFloat(invoicedata.factuurbedrag)-parseFloat(gbline.bedrag)).toFixed(2)
                    invoicedata.btw.push(btwline)

                  }
                }
              }

              invoicedata.boekingsregels.push(gbline)

              console.log(invoicedata)
              try {
                const response = await axios.post('/api/snelstart_create_inkoop', invoicedata)
                if (response) {
                  console.log('succes', response)
                  console.log('iv',invoiceline.id)
                  if (response.status == 200) {
                    // console.log("mark as exported")
                    invoice = this.factuur_by_id(invoiceline.id)
                    invoice.exportedinkoop = 1
                    await axios.post('/api/set_purchase_exported', { id: invoiceline.id, exportedinkoop: 1 })
                    if(response.data.invoiceId){
                      invoice.boekstuknummer = response.data.invoiceId
                      await axios.post('/api/set_purchase_boekstuknummer', { id: invoiceline.id, boekstuknummer: response.data.invoiceId })
                    }
                    if(response.data.id){
                      let ss_invoiceid = response.data.id;
                      // generate invoice

                      let data = {}
                      data.dates = this.getWeekDates(invoiceline.week.substring(0, 2), invoiceline.week.substring(3))
                      data.user_id = invoiceline.user_id
                      data.contract_id = invoiceline.contract_id
                      data.hourcode = invoiceline.hourcode?parseInt(invoiceline.hourcode):null

                      data.week = parseInt(invoiceline.week.substring(0, 2))
                      data.inkoopfactuur =  invoiceline.inkoopfactuur
                      axios.post('/api/generate_inkoop', data).then(() => {
                      var invoice 
                      if(data.hourcode==null){
                        invoice =  'factuur-' + data.contract_id + "-" + data.user_id + "-" + data.week + ".pdf"
                      } else{
                        invoice = this.server_url + 'factuur-' + data.contract_id + "-" + data.user_id + "-" + data.week + "-" + data.hourcode + ".pdf"
                      }
                      axios.post('/api/snelstart_invoice_inkoop', {invoice:invoice, id: ss_invoiceid})
                      // this.generate_inkoop = false

                    })

                    }
                  }
                  if (response.data.status && response.data.status != 200) {
                    console.log("DAS MIS", response.data.messages)
                    let errormessages = ""
                    response.data.messages.forEach(element => {
                        errormessages += element.message + "<br>"

                    });
                    invoice = this.factuur_by_id(invoiceline.id)
                    invoice.exportedinkoop = 0
                    axios.post('/api/set_purchase_exported', { id: invoiceline.id, exportedinkoop: 0 })
                    this.$modal.hide('popup_modal')
                    this.$modal.hide('popup_confirm')
                    this.$modal.show(popup_confirm, {
                        message: errormessages,
                        header: this.$t('labels.boekhoudgemakfout')+ " "+invoice.facnuminkoop,
                    }, {
                        name: 'popup_confirm',
                        draggable: false,
                        height: 'auto',
                        width: '90%'
                    })
                  }                
                }
              } catch (err) {
                console.log(err)
              }
            }
          }
        } else {
          console.log("verkoop", invoiceline.saleexportselect)
          if (invoiceline.saleexportselect == 1) {
            if(this.settings.boekhoudprogramma=='boekhoudgemak'){

              invoicedata = {}
              now = new Date()
              invoicedata.invoiceId = invoiceline.facnumverkoop?invoiceline.facnumverkoop.substring(invoiceline.facnumverkoop.length - 8):"unknown"
              invoicedata.fiscalYear = now.getFullYear()
              invoicedata.invoiceDate = this.dateToNL(now)
              now.setDate(now.getDate() + planningdata.payment_client_zzprnl);
              invoicedata.invoiceExpirationDate = this.dateToNL(now)
              if (planningdata.btw_verlegd == "yes") {
                invoicedata.journalId = this.settings.saleshiftlogbook
                invoicedata.vatScenarioId = Number(this.settings.saleshiftvatscenario)
              } else {
                invoicedata.journalId = this.settings.salenotshiftlogbook
                invoicedata.vatScenarioId = Number(this.settings.salenotshiftvatscenario)
              }
              invoicedata.paymentConditionId = planningdata.payment_client_zzprnl
              invoicedata.periodNumber = new Date().getMonth() + 1 //0 == january
              invoicedata.rebateExpirationDate = this.dateToNL(now)
              invoicedata.reference = planningdata.project_reference_number ?
                ("Week "+invoiceline.week + " " + invoiceline.facnumverkoop + " " + planningdata.project_reference_number + " " + planningdata.project_name).substring(0, 30) :
                ("Week "+invoiceline.week + " " + invoiceline.facnumverkoop + " " + planningdata.project_name).substring(0, 30)
              invoicedata.customerId = this.company_by_id(this.project_by_id(this.contract_by_id(invoiceline.contract_id).project_id).company_id).debnum
              invoicedata.customerInvoiceLines = []
              customerInvoiceLine = {}
              if (planningdata.btw_verlegd == "yes") {
                customerInvoiceLine.accountId = this.settings.saleshiftledgeraccount
              } else {
                customerInvoiceLine.accountId = this.settings.salenotshiftledgeraccount
              }
              customerInvoiceLine.description = this.username_by_id(this.contract_by_id(invoiceline.contract_id).user_id) + " Week "+invoiceline.week + " " + invoiceline.facnumverkoop

              customerInvoiceLine.creditAmountCur = invoiceline.sale.toFixed(2)
              customerInvoiceLine.transactionDate = this.dateToNL(new Date())
              if (planningdata.btw_verlegd == "yes") {
                customerInvoiceLine.vatCodeId = Number(this.settings.saleshiftvatcode)
              } else {
                customerInvoiceLine.vatCodeId = Number(this.settings.salenotshiftvatcode)
              }
              invoicedata.customerInvoiceLines.push(customerInvoiceLine)

              invoicedata.vatTransactionLines = []
              vatTransactionLine = {}
              vatTransactionLine.amountTurnoverCur = invoiceline.sale
              if (planningdata.btw_verlegd == "yes") {
                vatTransactionLine.VatAmountCur = "0.00"
                vatTransactionLine.vatCodeId = Number(this.settings.saleshiftvatcode)
              } else {
                vatTransactionLine.VatAmountCur = (0.21 * invoiceline.sale).toFixed(2)
                vatTransactionLine.vatCodeId = Number(this.settings.salenotshiftvatcode)
              }
              invoicedata.vatTransactionLines.push(vatTransactionLine)
              console.log(invoicedata)
              try {
                const response = await axios.post('/api/create_verkoop', invoicedata)
                if (response) {
                  // console.log('response', response)

                  if (response.status == 200 ) {
                    invoice = this.factuur_by_id(invoiceline.id)
                    invoice.exportedverkoop = 1
                    await axios.post('/api/set_sale_exported', { id: invoiceline.id, exportedverkoop: 1 })
                  }
                  if (response.data.status && response.data.status != 200) {
                          console.log("DAS MIS", response.data.messages)
                          let errormessages = ""
                          response.data.messages.forEach(element => {
                              errormessages += element.message + "<br>"

                          });
                          invoice = this.factuur_by_id(invoiceline.id)
                          invoice.exportedverkoop = 0
                          await axios.post('/api/set_sale_exported', { id: invoiceline.id, exportedverkoop: 0 })
                          this.$modal.hide('popup_modal')
                          this.$modal.hide('popup_confirm')
                          this.$modal.show(popup_confirm, {
                              message: errormessages,
                              header: this.$t('labels.boekhoudgemakfout')+ " "+invoice.facnumverkoop,
                          }, {
                              name: 'popup_confirm',
                              draggable: false,
                              height: 'auto',
                              width: '90%'
                          })
                      }                   
                }
              } catch (err) {
                console.log(err)
              }
            }
            if(this.settings.boekhoudprogramma=='snelstart'){
              console.log("Snelstart verkoop", invoiceline)
              invoicedata = {}
              now = new Date()
              invoicedata.factuurnummer  = invoiceline.facnumverkoop?invoiceline.facnumverkoop.substring(invoiceline.facnumverkoop.length - 8):"unknown"
              invoicedata.factuurdatum = this.dateToYYYYMMDD(now)
              invoicedata.boekstuk = invoiceline.boekstuknummer
              invoicedata.klant={}
              invoicedata.klant.id = this.company_by_id(invoiceline.company_id).debnum
              invoicedata.omschrijving = planningdata.project_reference_number ?
                ("Week "+invoiceline.week + " " + invoiceline.facnumverkoop + " " + planningdata.project_reference_number + " " + planningdata.project_name).substring(0, 30) :
                ("Week "+invoiceline.week + " " + invoiceline.facnumverkoop + " " + planningdata.project_name).substring(0, 30)
              invoicedata.factuurbedrag = invoiceline.sale.toFixed(2)
              invoicedata.boekingsregels = [];
              invoicedata.btw = [];
              var btwlinev = {}
              var gblinev = {}
              gblinev.omschrijving = "Week "+invoiceline.week + " " + invoiceline.facnuminkoop,
              gblinev.bedrag =  invoiceline.sale.toFixed(2)
              gblinev.grootboek = {}
              if (planningdata.btw_verlegd == "yes") {
                gblinev.grootboek.id =  this.settings.saleshiftledgeraccountss.guid
                gblinev.btwSoort = "Geen"
              } else {
                if (planningdata.btw_verlegd == "low") {
                  gblinev.grootboek.id =  this.settings.salenotshiftledgeraccountss.guid
                  gblinev.btwSoort = "Laag"
                  gblinev.bedrag =  (1.09*invoiceline.sale).toFixed(2)

                  gblinev.bedrag =  (invoiceline.purchase).toFixed(2)

                  btwlinev.btwSoort = "VerkopenLaag"
                  btwlinev.btwBedrag = (invoiceline.purchase*0.09).toFixed(2)
                  invoicedata.btw.push(btwlinev)

                } else {
                    gblinev.grootboek.id =  this.settings.purchasenotshiftledgeraccountss.guid
                    gblinev.bedrag =  (1.21*invoiceline.sale).toFixed(2)

                    gblinev.btwSoort = "Hoog"
                    gblinev.bedrag =  (invoiceline.purchase).toFixed(2)

                    btwlinev.btwSoort = "VerkopenHoog"
                    btwlinev.btwBedrag = (invoiceline.purchase*0.21).toFixed(2)
                    invoicedata.btw.push(btwlinev)

                  }
              }

              invoicedata.boekingsregels.push(gblinev)

              console.log(invoicedata)
              try {
                const response = await axios.post('/api/snelstart_create_verkoop', invoicedata)
                if (response) {
                  if (response.status == 200 ) {
                    invoice = this.factuur_by_id(invoiceline.id)
                    invoice.exportedverkoop = 1
                    await axios.post('/api/set_sale_exported', { id: invoiceline.id, exportedverkoop: 1 })
                  }
                  if (response.data.status && response.data.status != 200) {
                          console.log("DAS MIS", response.data.messages)
                          let errormessages = ""
                          response.data.messages.forEach(element => {
                              errormessages += element.message + "<br>"

                          });
                          invoice = this.factuur_by_id(invoiceline.id)
                          invoice.exportedverkoop = 0
                          await axios.post('/api/set_sale_exported', { id: invoiceline.id, exportedverkoop: 0 })
                          this.$modal.hide('popup_modal')
                          this.$modal.hide('popup_confirm')
                          this.$modal.show(popup_confirm, {
                              message: errormessages,
                              header: this.$t('labels.boekhoudgemakfout')+ " "+invoice.facnumverkoop,
                          }, {
                              name: 'popup_confirm',
                              draggable: false,
                              height: 'auto',
                              width: '90%'
                          })
                      }                   

                }
              } catch (err) {
                console.log(err)
              }

          }
          }
        }
      }
      this.notrunning = true
    },
    generate_factuur(index) {
      index.pdfgenerating = true
      let data = {}
      data.dates = this.getWeekDates(index.week.substring(0, 2), index.week.substring(3))
      data.user_id = index.user_id
      data.contract_id = index.contract_id
      data.hourcode = index.hourcode!=undefined?parseInt(index.hourcode):null
      data.week = parseInt(index.week.substring(0, 2))
      console.log("data", data, index)

      try {
        axios.post('/api/generate_inkoop', data).then(() => {
          var routeUrl 
          if(data.hourcode==null){          
            routeUrl = this.server_url + '/media_invoices/factuur-' + index.contract_id + "-" + index.user_id + "-" + parseInt(index.week.substring(0, 2)) + ".pdf"
          } else{
            routeUrl = this.server_url + '/media_invoices/factuur-' + index.contract_id + "-" + index.user_id + "-" + parseInt(index.week.substring(0, 2))  + "-" + data.hourcode + ".pdf"
          }
          window.open(routeUrl, '_blank')
          index.pdfgenerating = false

        })
      } catch (err) {
        console.log(err)
      }


    },
    async generate_verkoop_factuur(index) {
      index.pdfgenerating = true
      let data = {}
      data.dates = this.getWeekDates(index.week.substring(0, 2), index.week.substring(3))
      data.user_id = index.user_id
      data.contract_id = index.contract_id
      data.hourcode = index.hourcode
      data.week = parseInt(index.week.substring(0, 2))
      // data.year = parseInt(index.week.substring(3))
      // data.week = index.week.substring(0, 2)
      data.year = index.week.substring(3)
      try {
        await axios.post('/api/generate_verkoop', data).then(() => {
              console.log('succes',data)
              var routeUrl 
              if(data.hourcode==null){
                routeUrl = this.server_url + '/media_invoices/verkoopfactuur-' + index.contract_id + "-" + index.user_id + "-" + parseInt(index.week.substring(0, 2)) + ".pdf"
              } else{
                routeUrl = this.server_url + '/media_invoices/verkoopfactuur-' + index.contract_id + "-" + index.user_id + "-" + parseInt(index.week.substring(0, 2)) + "-" + data.hourcode + ".pdf"
              }              
              window.open(routeUrl, '_blank')
              index.pdfgenerating = false

        })
      } catch (err) {
        console.log(err)
      }

    },
    async gen_invoice(){
      this.$bvModal.show('BSShowinvoiceDataID')
    },
    set_invoice_values(item) {
      this.showinvoice = item.id
      this.$bvModal.show('BSSetinvoiceDataID')
    },
    removeinvoice(row) {
      this.selectedID = row.item.id
      let message = this.$i18n.t('labels.confirmdeletehead') + row.item.boekstuknummer + this.$i18n.t('labels.confirmdeletetail')
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
        message: message,
      }, {
        name: 'popup_modal',
        draggable: false,
        height: 'auto',
        width: '500px'
      }, {
        'before-close': this.modalClose
      })

      this.$emit('close')

    },
    modalClose() {
      if (this.confirm_modal.id == 1) {
        this.delete_factuur(this.selectedID);
        for (let i = 0; i < this.facturen.length; i++) {
          if (this.facturen[i].id == this.selectedID) {
            this.facturen.splice(i, 1);
          }
        }
      }
    },
    upload_factuur(item) {
      this.contract_id = item.contract_id
      this.weeknumber = parseInt(item.week.substring(0,2))
      this.yearnumber = parseInt(item.week.substring(3))
      this.companyattachment = false
      this.internattachment = true
    this.$bvModal.show('BSUploadUrenModalID')
    },
    upload_company_attachment(item) {
      this.contract_id = item.contract_id
      this.weeknumber = parseInt(item.week.substring(0,2))
      this.yearnumber = parseInt(item.week.substring(3))
      this.companyattachment = true
      this.internattachment = false

      this.$bvModal.show('BSUploadUrenModalID')
    },  
    view_company_attachment(item){
      let cu =  this.urenbonnen.filter(u=>u.contract_id == item.contract_id && u.week == parseInt(item.week.substring(0,2)) && u.name.substring(0,8)=='company_')
      if(cu && cu[0].file)
        window.open(this.server_url + '/media_' + cu[0].file, '_blank')

    } , 
    select_all_email() {
      this.filteredinvoices.forEach(element => {
        if (this.purchase == 'yes') {
          element.purchasemailselect = element.inkoopfactuur=='no'?0:1
          element.purchasemailwarning = element.purchasemailed == 1 && element.purchasemailselect
        } else {
          element.salemailselect = 1
          element.salemailwarning = element.salemailed == 1 && element.salemailselect
        }
      });
    },
    select_none_email() {
      this.filteredinvoices.forEach(element => {
        if (this.purchase == 'yes') {
          element.purchasemailselect = 0
          element.purchasemailwarning = false
        } else {
          element.salemailselect = 0
          element.salemailwarning = false
        }
      });
    },
    select_not_emailed() {
      this.filteredinvoices.forEach(element => {
        if (this.purchase == 'yes') {
          element.purchasemailselect = element.purchasemailed == 1 ? 0 : 1
          element.purchasemailwarning = false
        } else {
          element.salemailselect = element.salemailed == 1 ? 0 : 1
          element.salemailwarning = false
        }
      });
    },
    select_all_export() {
      this.filteredinvoices.forEach(element => {
        if (this.purchase == 'yes') {
          element.purchaseexportselect = 1
          element.purchaseexportwarning = element.purchaseexported == 1 && element.purchaseexportselect
        } else {
          element.saleexportselect = 1
          element.saleexportwarning = element.saleexported == 1 && element.saleexportselect
        }
      });
    },
    select_none_export() {
      this.filteredinvoices.forEach(element => {
        if (this.purchase == 'yes') {
          element.purchaseexportselect = 0
          element.purchaseexportwarning = false
        } else {
          element.saleexportselect = 0
          element.saleexportwarning = false
        }
      });
    },
    select_not_exported() {
      this.filteredinvoices.forEach(element => {
        if (this.purchase == 'yes') {
          element.purchaseexportselect = element.purchaseexported == 1 ? 0 : 1
          element.purchaseexportwarning = element.purchaseexported == 1 && element.purchaseexportselect
        } else {
          element.saleexportselect = element.saleexported == 1 ? 0 : 1
          element.saleexportwarning = element.saleexported == 1 && element.saleexportselect
        }
      });
    },
    needtoexport() {
      var ret = false;
      this.filteredinvoices.forEach(element => {
        if (this.purchase == 'yes') {
          if (!ret) {
            ret = element.purchaseexportselect == 1
          }
        } else {
          if (!ret) {
            ret = element.saleexportselect == 1
          }
        }
      });
      return ret;
    },
    pexportcheck(item) {
      item.purchaseexportwarning = item.purchaseexported == 1 && item.purchaseexportselect
    },
    sexportcheck(item) {
      item.saleexportwarning = item.saleexported == 1 && item.saleexportselect
    },
    pmailcheck(item) {
      item.purchasemailwarning = item.purchasemailed == 1 && item.purchasemailselect
    },
    smailcheck(item) {
      item.salemailwarning = item.salemailed == 1 && item.salemailselect
    },
    email_status(status){
      for (const invoiceline of this.filteredinvoices) {
        let invoice = this.factuur_by_id(invoiceline.id)

        if (this.purchase == 'yes') {    
          if (invoiceline.purchasemailselect == 1) {
            invoice.mailedinkoop = status
            axios.post('/api/set_purchase_mailed', { id: invoiceline.id, mailedinkoop: status })
          }
        } else {
          if (invoiceline.salemailselect == 1) {
            invoice.mailedverkoop = status
            axios.post('/api/set_sale_mailed', { id: invoiceline.id, mailedverkoop: status })
          }
        }
      }

    },
    export_status(status){
      for (const invoiceline of this.filteredinvoices) {
        let invoice = this.factuur_by_id(invoiceline.id)

        if (this.purchase == 'yes') { 
          if (invoiceline.purchaseexportselect == 1) {
            invoice.exportedinkoop = status
            axios.post('/api/set_purchase_exported', { id: invoiceline.id, exportedinkoop: status })
          }
        } else {
          if (invoiceline.saleexportselect == 1) {
            invoice.exportedverkoop = status
            axios.post('/api/set_sale_exported', { id: invoiceline.id, exportedverkoop: status })
          }
        }
      }
    },
    showurenbonnen(row){
      this.urenbonnenfromrow = row.urenbonnen
      this.$bvModal.show('BSManageUrenbonnenModalID')

    }
  },
  components: {
    flatPickr,BSSetInvoiceData, BSUploadUserModal,BSManageUrenbonnenModalID,BSShowinvoiceDataID
  },
  watch:{
    $route (){
      this.purchase = this.$route.params.sale=='purchase'?"yes":"no"
    }
}   
}
</script>