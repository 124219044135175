<template>
    <div >
      <font-awesome-icon ref="NavIcon" @click="showappnav" v-if="!appnavvisible" class="menu" icon="chevron-right" :style="AppPrimaryColor()"/>
      <font-awesome-icon  @click="hideappnav" v-if="appnavvisible" class="menuvisible" icon="chevron-left" :style="AppNavButton()"/>
    <transition  name="nav">

      <b-nav v-if="appnavvisible" vertical class="appnav" :style="AppPrimaryButton()" >
        <template :style="AppSecondayColor()"   >

          <b-nav-item v-if="!current_user && buildtarget=='apprelease'" active-class="active" to="/login">
              <div :style="AppNavButton()"> {{ $t("titles.login") }} <font-awesome-icon class="arrow"  icon="sign-in-alt" :style="AppNavButton()"  /></div>
            </b-nav-item>   
            <b-nav-item v-if="!current_user && buildtarget!='apprelease'" active-class="active" to="/">
              <div :style="AppNavButton()"> {{ $t("titles.login") }} <font-awesome-icon class="arrow"  icon="sign-in-alt" :style="AppNavButton()"  /></div>
            </b-nav-item>                           
            <b-nav-item  v-if="!current_user_active()" active-class="active" to="/public_projects">
                <div :style="AppNavButton()">{{ $t("titles.projects") }} <font-awesome-icon class="arrow" :style="AppNavButton()" icon="chevron-right" /></div>
            </b-nav-item>   
            <b-nav-item  v-if="portal_has_extras" active-class="active" to="/register">
                <div :style="AppNavButton()">{{ $t("titles.register") }}  <font-awesome-icon class="arrow" :style="AppNavButton()" icon="chevron-right" /></div>
            </b-nav-item>   
            <b-nav-item   v-if="portal_has_extras" active-class="active" to="/new_project">
                <div :style="AppNavButton()">{{ $t("titles.newproject") }} <font-awesome-icon  class="arrow" :style="AppNavButton()" icon="chevron-right" /></div>
            </b-nav-item> 
            <b-nav-item  v-if="portal_has_extras" active-class="active" to="/aboutapp">
                <div :style="AppNavButton()">{{ $t("titles.aboutapp") }} <font-awesome-icon  class="arrow" :style="AppNavButton()" icon="chevron-right" /></div>
            </b-nav-item>   
            <b-nav-item  active-class="active" to="/aboutcompany">
                <div :style="AppNavButton()">{{ settings.abouttitle }} <font-awesome-icon  class="arrow" :style="AppNavButton()" icon="chevron-right" /></div>
            </b-nav-item>   
            <b-nav-item  active-class="active" to="/privacy">
                <div :style="AppNavButton()">{{ settings.privacytitle }} <font-awesome-icon  class="arrow" :style="AppNavButton()" icon="chevron-right" /></div>
            </b-nav-item>   
            <b-nav-item  active-class="active" to="/terms">
                <div :style="AppNavButton()">{{ settings.termstitle }} <font-awesome-icon  class="arrow" :style="AppNavButton()" icon="chevron-right" /></div>
            </b-nav-item>   
            <b-nav-item  v-if="false"  active-class="active" to="/aboutff">
                <div :style="AppNavButton()">{{ $t("titles.aboutff") }} <font-awesome-icon  class="arrow" :style="AppNavButton()" icon="chevron-right" /></div>
            </b-nav-item>               
            <b-nav-item v-if="current_user" active-class="active" @click="logout">
              <div :style="AppNavButton()"> {{ $t("titles.logout") }} <font-awesome-icon class="arrow"  icon="sign-out-alt" :style="AppNavButton()"  /></div>
            </b-nav-item> 
        </template>    
        <FrontendLogo v-if="buildtarget=='apprelease'" />

      </b-nav>
    </transition>
    <transition  name="fade">
      <div v-if="appnavvisible" class="backdrop" @click="hideappnav"/>
    </transition>
    
  </div>
</template>        

<script>

import { mapGetters, mapState, mapActions } from 'vuex'
import FrontendLogo from './parts/FrontendLogo.vue'
require('dotenv').config()

export default {
  name: 'navigation',
  data() {
      return {
          jumpdate: "",
          isdev:false,
          appnavvisible: false,

      }
  },
  computed: {
  ...mapGetters([
    'is_logged_in',
    'portal_has_extras'

  ]),
  ...mapState([
      'current_user',
  ]),

},
mounted() {
		this.$router.afterEach(()  => {
      this.$store.commit('appnavvisible', false)
      this.appnavvisible = false
      if(this.$refs["NavIcon"]){
        // console.log(">>NavIcon")
				document.getElementById('app').scrollIntoView({ behavior: "smooth" })
				// this.$refs["NavIcon"].scrollIntoView({ behavior: "smooth" })
			}

		})
  },
  methods: {
      ...mapActions([
          'logout',
      ]),
      hideappnav(){
        this.$store.commit('appnavvisible', false)
        this.appnavvisible = false
      },
      showappnav(){
        this.$store.commit('appnavvisible', true)
        this.appnavvisible = true
      }

  },
  components: {FrontendLogo},
  watch:{
    appnavvisible(){
      const html = document.querySelector("html");
      if (html) {
        html.style.overflow = this.appnavvisible ? "hidden" : "auto";
      }
    }
  }

}
</script>
<style >
.appnav {
  position:fixed;
  top :0;
  left:0;
  padding: 1rem !important;
  padding-top: 5rem !important;
  width:70%;
  height:100vh;
  z-index: 999;
  background-color: #fff;
  
}
.arrow{
  margin-top:0.3rem;
  float:right;
}
.menu{
  position:fixed;
  background-color: rgba(255,0,0,0);
  top :45px;
  left:1rem;
  cursor: pointer;
  width:3rem !important;
  height: 3rem;
  padding-top: 0 !important;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  z-index: 200;
  display: block;

}
.menuvisible{
  position:fixed;
  background-color: rgba(255,0,0,0);
  top :45px;
  left:1rem;
  z-index: 1999;
  cursor: pointer;
  width: 3rem !important;
  height: 3rem;
  padding-top: o !important;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;

}
.b-nav-item {
  font-weight: bold;
}
.backdrop {
  position:fixed;
  top :0;
  left:0;  
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 199;
  opacity: 0.7;

  }
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.nav-enter-active{
  transition: all 0.3s ease-out;
}
.nav-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.nav-enter,
.nav-leave-to {
  transform: translateX(-200px);
  opacity: 0;
}

.show-enter-active,
.show-leave-active {
  transition: opacity 0.8s ease-in;
}
.show-enter-from,
.show-leave-to {
  opacity: 0;
}
</style>