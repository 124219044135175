<template>
  <div class="workfield-settings">
    <v-select multiple :options="activebranches" v-model="extra_branches" label="name"/>
        <b-table 
        striped hover 
        :items="currentworkfields"
        :fields="fields" 
        @row-clicked="onWorkfieldClicked"    
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
         :sort-direction="sortDirection"    
        >
          <template #cell(active)="row"> 
            <font-awesome-icon v-if="row.item.active" icon="check" style="color: green;"/>
            <font-awesome-icon v-else icon="times" style="color: red;"/>
          </template>        
        </b-table>
    </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
export default {
    name: 'userWorkfieldsscreen',
    data() {
        return {
            extra_branches:null,
            update:0,
            sortBy:"name"
        }
    },   
    props: {
        user_id: Number
    },
    mounted(){
        console.log(this.user.extra_branches)
        if(this.user.extra_branches!= undefined){
            console.log("uhm")
            let userbranches = JSON.parse(this.user.extra_branches)
            this.extra_branches = this.activebranches.filter(b=>userbranches.indexOf(b.id)!=-1)
        }
    },
    computed: {
        user() {
            return this.user_id > 0 ? this.user_by_id(this.user_id) : this.user_new
        },        
        fields () {
            return [
            { key: 'name', label: this.$i18n.t('labels.workfieldname')  , sortable: true, sortDirection: 'desc' },
            { key: 'active', label: this.$i18n.t('labels.active') },
            ]   
        },        
        ...mapGetters([
            'user_by_id',
            'user_new',
            'activebranches',
            'branche_by_id'


        ]),
        ...mapState([
            'users',
            'workfields',
            'current_user',

        ]),
        currentworkfields() {
          let currentfields=[]
          let allbranches = []
          console.log("REDO",this.update)
          if(this.user.extra_branches!= undefined){
            allbranches = JSON.parse(this.user.extra_branches)
          }
          allbranches.push(this.user.branche_id)
          for(var i=0;i<this.workfields.length;i++){
            if(allbranches.indexOf(this.workfields[i].branche_id)!=-1){
                let aworkfield=[]
                aworkfield["name"]="["+this.branche_by_id(this.workfields[i].branche_id).name+"] - "+this.workfields[i]["name"];
                aworkfield["id"]=this.workfields[i]["id"];
                if(this.user.workfields){
                    aworkfield["active"]=this.user.workfields.indexOf(this.workfields[i]["id"])!=-1
                }else{
                    aworkfield["active"]=false
                }
                
              if (this.workfields[i]["active"]) {
                  currentfields.push(aworkfield)
              }
            }
          }
          return currentfields
        }
    },
    methods:{
        ...mapActions([
            'save_settings',
            'save_user_workfield',
            'delete_user_workfield',
            'save_user'

        ]),

        onWorkfieldClicked(row){
            var datatosend={workfield_id:row.id, user_id:this.user_id}
            if(this.user.workfields.indexOf(row.id)!=-1){
                if(this.user_id>0){
                    this.$store.dispatch('delete_user_workfield',datatosend); 
                }
                this.user.workfields.splice(this.user.workfields.indexOf(row.id),1)
            }else{
                if(this.user_id>0){
                    this.$store.dispatch('save_user_workfield',datatosend); 
                }
                this.user.workfields.push(row.id)
            }   
        },          
    },
    components: { },
    watch:{
        extra_branches(){
            console.log(">EB", this.extra_branches)
            let eb = new Array()
            this.extra_branches.forEach(element => {
                eb.push(element.id)
            });
            this.user.extra_branches = JSON.stringify(eb)
            this.save_user(this.user_id)
            this.update++
        }
    }
}
</script>
