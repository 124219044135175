<template>
  <div>
    <a @click="show_user()" :style="{ cursor: 'pointer', color:'black !important', fontSize:'large'}" class="link-item"> {{this.username_by_id(this.user.id)}} </a><br/>
    <!-- <font-awesome-icon class="multi-append"  icon="mobile"/> <strong>{{this.user.mobile}}</strong><br/> -->
<!--     
    {{ $t("labels.call") }} {{this.formatDate(this.user.callat)}}<br/>
      <pre>{{this.user.callbacknote}}</pre>
      <a href="javascript:;" v-if="checkcallat==2" class="action-item float-left" @click="callUserModal(user_id)"> <font-awesome-icon icon="exclamation" style="color: red;"/><font-awesome-icon icon="phone"/></a>
      <a href="javascript:;" v-else-if="checkcallat==1" class="action-item float-left" @click="callUserModal(user_id)"> <font-awesome-icon icon="phone"/></a>
      <a v-else href="javascript:;" @click="callUserModal(user_id)"><font-awesome-icon  icon="question" style="color: green;"/><font-awesome-icon icon="phone"/></a>
    <br> -->
  </div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'

export default {
    name: 'user_block_planning',
    data() {
        return {
        }
    },
    props: {
      user_id:Number
    },    
    mounted() {
    },
    computed: {
        checkcallat(){
          if(this.user.callat==null){
            return 0
          } else{
            let calldate = new Date(this.user.callat)
            var today = new Date();
            var diff = (calldate.getTime() - today.getTime())/(1000*3600*24)
            if(calldate<today || diff<14){
              return 2
            }
          }
          return 1
        },       
      user(){
        return this.user_by_id(this.user_id)
      },
      ...mapState([
          'current_user',
          'hours'
      ]), 
      ...mapGetters([
          'user_by_id',
          'username_by_id',
      ]),  

    },
    methods:{
      
      show_user(){
        this.$router.push({ path: '/user/'+ this.user_id });
      }
    },
    watch:{

    }
}
</script>