<template>
	<div class="popup">

        <div class="popup-header">
            <div >
              {{$t("labels.change_doc")}}
            </div>
            <div class="popup-close" @click="$emit('close',{id:docid,dname:docname, ddate:docdate})">
                <font-awesome-icon icon="times" />
            </div>

        </div>
        <div class="popup-body">
            <b-container>
                <form class="form" v-on:submit='save_and_close'>
                  <div class="form-row">
                      <div class="col-3">
                          <label for=""> {{ $t("labels.name") }}</label>
                      </div>
                      <div class="col-1">
                        <font-awesome-icon icon="exclamation" color="red" v-if="checkfilecharacters(this)==false"></font-awesome-icon>
                        <font-awesome-icon icon="check" color="green" v-else></font-awesome-icon>
                    </div>

                      <div class="col">
                          <input type="text" class="form-control" v-model="name"  />              
                      </div>
                  </div>    
                  <div class="form-row">
                      <div class="col-4">
                          <label for=""> {{ $t("labels.expirationdate") }}</label>
                      </div>
                      <div class="col">
                          <input type="date" class="form-control" v-model="docdate"  />            
                      </div>
                  </div>
                    <div class="form-row" v-if="docreq!=-1">
                        <div class="col-3"></div>
                        <div class="col">
                            <b-form-checkbox value="1"
                            unchecked-value="0" v-model="docreq">{{ $t("labels.documentmandatory") }}</b-form-checkbox>
                        </div>
                    </div>                   
                </form>
            </b-container>
        </div>
        <div class="popup-footer">
            <div v-if="name!=''&&checkfilecharacters(this)" @click="$emit('close',{id:docid,dname:name, ddate:docdate, dreq:docreq})" class="btn btn-primary">{{ validateText()}}</div>
            <div v-else  class="btn btn-primary">{{ validateText()}}</div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'document-modal',
    data() {
        return {
          name:String,
          docdate:String,
          docid:Number,
          docreq:Number
        }
    },
    props: {
        document_name: String,
        document_date:String,
        document_id:Number,
        document_required:Number
    },
    mounted() {
      this.name = this.document_name
      this.docdate = this.document_date
      this.docid = this.document_id
      this.docreq = parseInt(this.document_required)
    },
    computed: {
    },
    methods: {
        validateText(){
            if(this.name!="" &&this.checkfilecharacters(this)){
                return this.$i18n.t("buttons.saveandclose")

        } else {
                return this.$i18n.t("buttons.invalid")
            }
        },

        save_and_close(e) {
            e.preventDefault() // it prevent from page reload
              
        },
        
    }
}
</script>