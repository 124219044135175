<template>
  <b-modal id="BSSetPasswordID" centered size="lg" @show="clear">
    <template #modal-header>
      <h2 class="modal-title">{{ $t("labels.setpassword") }} {{ username_by_id(user_id) }}</h2>
      {{ user_id }}
    </template>

    <form class="form">
      <div class="form-row">
        <div class="col-3">
          <label for=""> {{ $t("labels.password") }}</label>
        </div>
        <div class="col">
          <b-input-group class="mt-3">
            <b-form-input :type="pwtype" v-model="newpw" :state="passwordcheck"  placeholder="" />
            <b-input-group-append>
                <b-button class="multi-append" href="javascript:;" @click="reveal()"><font-awesome-icon :icon = btnText /></b-button>
            </b-input-group-append>
            <b-form-invalid-feedback >
              {{ $t("validation.password")}}
            </b-form-invalid-feedback>         
          </b-input-group>        
        </div>
      </div>
      <div class="form-row" >
        <div class="col-3">
          <label for="">{{ $t("labels.passwordrepeat") }}</label>
        </div>
        <div class="col">
          <b-form-input  :state="passwordequal" type="password" class="form-control" v-model="newpw2"/>
        </div>
      </div>        
    </form>

    <template #modal-footer>
      <div @click="close_modal" class="btn btn-secondary">{{ $t("buttons.close") }}</div>
      <div v-if="passwordequal"  @click="savenewpass" class="btn btn-primary">{{ $t("labels.confirmpass") }}</div>
    </template>
  </b-modal>
</template>


<script>
import { mapGetters, mapState } from 'vuex'
import axios from 'axios'

export default {
  name: 'invoice-data-modal',
  data() {
    return {
      pwtype:"password",
      btnText : 'eye',
      newpw:null,
      newpw2:"" 
    }
  },
  props: {
    user_id: Number
  },
  computed: {

    ...mapState([
      'invoiceextra'

    ]),
    ...mapGetters([
      'username_by_id'
    ]),
    passwordcheck(){
            return this.newpw!=undefined &&this.newpw.length>5;
        },     
        passwordequal(){
            return this.newpw!=undefined && this.newpw==this.newpw2
        }       

  },
  methods: {
    clear(){
      this.newpw = null
      this.newpw2 = null

    },
    reveal(){
            if(this.pwtype === 'password') {
                this.pwtype = 'text'
                this.btnText = 'eye-slash'
            } else {
                this.pwtype = 'password'
                this.btnText = 'eye'
            }
        },          
      savenewpass(){
        axios.post('/api/user/changepasswordadmin',{user_id:this.user_id,newpassword:this.newpw})
        .then((response) => {
          console.log(response.data)
            if(response.status!=-1){
              this.$bvModal.hide('BSSetPasswordID')
            }
        })
      }  , 

    close_modal() {
      this.$bvModal.hide('BSSetPasswordID')
    },
  },
  watch:{
    weeknumber(){
      let data = this.invoiceextra.filter(i => i.contract_id == this.contract_id && i.week == this.weeknumber && i.year == this.yearnumber)
      if(data.length>0){
        this.description = data[0].description
        this.extra = data[0].extra
      }
    }
  }
}    
</script>
