<template>
  <div class="workfield-settings">
    <b-table v-if="this.project.zzprusers && this.project.zzprusers.length>0" striped hover :items="currentzzpers"
      :fields="fields">
      <template #cell(photo)="row">
        <img class="profile-photo" :src="profileto(row.item.id)" />
      </template>
      <template #cell(actions)="row">
        <a href="javascript:;" class="action-item" @click="delete_user(row.item.id)">
          <font-awesome-icon icon="trash" />
        </a>
        <a href="javascript:;" class="action-item" @click="edit_zzpr_in_project(row.item.id)">
          <font-awesome-icon icon="cog" />
        </a>
      </template>
    </b-table>
    <b-row align-h="end">
      <b-col cols="5">
        <div @click="find_ZZPR()" class="btn btn-primary">
          <font-awesome-icon icon="plus" /> {{ $t("labels.addcontract") }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import popup_modal from '../PopupModal'
import contract_modal from '../ContractModal'
import project_zzper_modal from '../ProjectZZPerModal'

export default {
  name: 'projectZZPersscreen',
  data() {
    return {

    }
  },
  props: {
    project_id: Number,
  },
  computed: {
    project() {
      return this.project_id > 0 ? this.project_by_id(this.project_id) : this.project_new
    },
    fields() {
      return [
        { key: 'photo', label: this.$i18n.t('labels.photo'), },
        { key: 'id', label: this.$i18n.t('labels.name'), formatter: "username_by_id", sortable: true, sortDirection: 'desc' },
        { key: 'actions', label: this.$i18n.t('labels.actions') },
      ]
    },
    ...mapGetters([
      'user_by_id',
      'branche_by_id',
      'company_by_id',
      'project_by_id',
      'users',
      'user_photo_by_id',
      'username_by_id'

    ]),
    ...mapState([
      'projects',
      'workfields',
      'companies'

    ]),
    currentzzpers() {
      let users = []
      for (var fi = 0; fi < this.project.zzprusers.length; fi++) {
        users.push(this.user_by_id(this.project.zzprusers[fi]))

      }

      return users
    }
  },
  methods: {
    ...mapActions([
      'save_settings',
      'save_user_workfield',
      'delete_user_workfield'

    ]),
    edit_zzpr_in_project(user_id) {
      this.$store.commit('selectedZZPr', user_id)
      this.$modal.hide('contract_modal')
      this.$modal.show(contract_modal, {
        project_id: this.project.id,
        user_id: user_id,
      }, {
        name: 'contract_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '1000px'
      })
      this.$emit('close')
    },
    delete_user(id) {
      this.selectedID = id
      let message = this.$i18n.t('labels.confirmdeletehead') + this.username_by_id(id) + this.$i18n.t('labels.confirmdeletetail')
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
        message: message,
      }, {
        name: 'popup_modal',
        draggable: false,
        height: 'auto',
        width: '500px'
      }, {
        'before-close': this.modalClose
      })

      this.$emit('close')

    },
    modalClose() {
      if (this.confirm_modal.id == 1) {
        let user_id = this.selectedID
        var datatosend = { project_id: this.project.id, user_id: user_id }
        this.$store.dispatch('remove_project_relations', datatosend);
        this.project.zzprusers.splice(this.project.zzprusers.indexOf(user_id), 1)
        let spliceplanning = -1
        for (var i = 0; i < this.planning.length; i++) {
          if (this.planning[i].user_id == user_id && this.planning[i].project_id == this.project.id) {
            spliceplanning = i
            this.$store.dispatch('delete_planning', this.planning[i].id);
            break
          }
        }
        if (spliceplanning != -1) {
          this.planning.splice(spliceplanning, 1)
        }

      }
    },
    find_ZZPR() {
      this.$modal.hide('project_zzper_modal')
      this.$modal.show(project_zzper_modal, {
        project_id: this.project.id,
        company_id: this.project.company_id
      }, {
        name: 'project_zzper_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '1000px'
      })

      this.$emit('close')
    },

  },
  components: {}
}
</script>
