<template>
	<div class="popup">

        <div class="popup-header">
            <div v-if="tag.id > 0">
                {{tag.tag}}
            </div>
            <div v-else>
                 {{ $t("labels.newtag") }}
            </div>
            <div v-if="tag.id == 0" class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
            <div v-else class="popup-close" @click="save_and_close">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
            <b-container>
                <form class="form" v-on:submit='save_and_close'>
                    <!-- branche name -->
                    <div class="form-row">
                        <div class="col-3">
                            <font-awesome-icon v-if="checktag" icon="check" style="color: green;"/>
                            <font-awesome-icon v-else icon="times" style="color: red;"/>                  
                            <label for="">{{ $t("labels.tag") }}</label>
                        </div>
                        <div class="col">
                            <b-input ref="deffield" :state="checktag" type="text" class="form-control" v-model="tag.tag"></b-input>
                            <b-form-invalid-feedback >
                                {{ $t("validation.tagcheck")}}
                            </b-form-invalid-feedback>  
                        </div>
                         
                    </div>
                </form>
            </b-container>
        </div>
        <div class="popup-footer">
            <div v-if="tag.id == 0" class="btn btn-primary" @click="my_add_tag();">{{ addTagText() }}</div>
            <div v-else @click="save_and_close" class="btn btn-primary">{{ $t("buttons.saveandclose") }}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
    name: 'tag-modal',
    data() {
        return {
            new_tag:{id:0, tag:""}
        }
    },
    props: {
        tag_id: Number
    },
    mounted() {
        this.focusInput();
    },
    computed: {
        tag() {
            return this.tag_id > 0 ? this.tag_by_id(this.tag_id) : this.new_tag
        },
        ...mapGetters([
            'tag_by_id',
        ]),
        ...mapState([
            'tags',
        ]),
        checktag(){
            if(this.tag.id>0){
                return true
            }
            let found=false;
            if(this.tag.tag!=undefined){
                for(var i=0;i<this.tags.length;i++){
                    if(this.tag.tag.toLowerCase()==this.tags[i].tag.toLowerCase()){
                        found = true;
                        break;
                    }
                }
            }
            return !found &&this.tag.tag!=undefined&& this.tag.tag.length>2
        }        
    },
    methods: {
        addTagText(){
            if (this.checktag){
                return this.$i18n.t("buttons.add") 
            } else { 
                return this.$i18n.t("buttons.invalid") 
            }
        },
        save_and_close(e) {
            e.preventDefault() // it prevent from page reload
            if(this.tag.id==0){
                this.my_add_tag()
            }else{
                this.save_tag(this.tag.id)
                this.$emit('close')
            }            
        },
        ...mapActions([
            'save_tag',
            'add_tag'
        ]),
        my_add_tag(){
            if(this.checktag){
                this.add_tag(this.tag);
                this.$emit('close')
            }
        },
    focusInput() {
      this.$refs.deffield.focus();
    }        

    }
}
</script>