<template>
  <b-container v-if="createAny('users') && currentUserIsSuperAdmin()" fluid>

    <!-- TItle bar -->
    <div class="title-bar">
      <h1>{{$t('labels.usernotification')}}</h1>
    </div>

    <!-- Close Week -->
    <h2 class="mt-5">{{$t('labels.usernotificationsignzzpr')}}</h2>
    <textarea style="width:100%; height:50px"  v-model="settings.closeweekzzpr"></textarea>

    <!-- Agree to hours -->
    <h2 class="mt-5">{{$t('labels.usernotificationsignsigner')}}</h2>
    <textarea style="width:100%; height:50px"  v-model="settings.closeweeksigner"></textarea>

    <!-- Agree to hours -->
    <h2 class="mt-5">{{$t('labels.check_hours_incorrect')}}</h2>
    <textarea style="width:100%; height:50px"  v-model="settings.check_hours_incorrect"></textarea>

    <h2 class="mt-5">{{$t('labels.cronremindendworkday')}}</h2>
    <div style="border:2px solid black; padding:1rem;">
      <h3 class="mt-5"> {{$t('templates.subject')}}</h3>
      <b-input v-model="settings.endworkdaysubject" text="text"/>
      <h3 class="mt-5">  {{$t('templates.message')}}</h3>
      <textarea style="width:100%; height:50px"  v-model="settings.endworkdaymessage"></textarea>
      <h3 class="mt-5"> {{$t('labels.cronremindendhoursbefore')}}</h3>
      <b-input v-model="settings.endworkdayhoursbefore" text="number"/>
    </div>

    <h2 class="mt-5">{{$t('labels.crontimenewcontract')}}</h2>
    <div style="border:2px solid black; padding:1rem;">
      <h3 class="mt-5"> {{$t('templates.subject')}}</h3>
      <b-input v-model="settings.newcontractsubject" text="text"/>
      <h3 class="mt-5">  {{$t('templates.message')}}</h3>
      <p>{{ $t('templates.newcontractnotification') }}</p>
      <textarea style="width:100%; height:100px"  v-model="settings.newcontractmessage"></textarea>
    </div>

    <h2 class="mt-5">{{$t('labels.contractnotification')}}</h2>
    <p>{{ $t('templates.contractnotification') }}</p>
    <textarea style="width:100%; height:100px"  v-model="settings.contractnotification"></textarea>

    <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="plus" /> {{ $t("buttons.savesettings") }}
    </div>

  </b-container>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import axios from 'axios'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: 'usernotifications-screen',
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items:[ 'heading', '|', 'undo', 'redo', '-', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ,'insertTable'],
          shouldNotGroupWhenFull: true
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
          ]
        },
        table: {
            contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        }
      },
      subject: ",mn,ndn nfklajljflaj kls",
      selected: [],
      file1:null

    }
  },

  computed: {

    ...mapState([
      'settings',
      'users',
      'contracts',
      'hours'

    ]),
    ...mapGetters([
      'server_url',
      'username_by_id',
      'user_by_id',
      'project_by_id',
      'contract_by_id',
      'company_by_id',
      'factuur_by_id',
      'confirm_modal',
      'planning_by_id',
      'createAny'
    ]),
  },
  methods: {
    ...mapActions([
    ]),
    save_settings() {
      try {
        axios.patch('/api/settings/',
          {
            closeweekzzpr: this.settings.closeweekzzpr,
            closeweeksigner: this.settings.closeweeksigner,
            check_hours_incorrect: this.settings.check_hours_incorrect,
            endworkdaysubject:this.settings.endworkdaysubject,
            endworkdaymessage:this.settings.endworkdaymessage,
            endworkdayhoursbefore:this.settings.endworkdayhoursbefore,
            contractnotification:this.settings.contractnotification,
            newcontractsubject: this.settings.newcontractsubject,
            newcontractmessage:this.settings.newcontractmessage
          },
        )
          .then((response) => {
            console.log(response)
          })
      } catch (err) {
        console.log(err)
      }
    },
  },

}
</script>