<template>
    <div class="popup">
        <div class="popup-header">
            {{ $t('labels.sendcontract') }} {{ this.username_by_id(this.contract.user_id) }} @ {{ planning.project_name }} 
            <b-button href="javascript:;" variant="primary" @click="generate_pdf(generate)"
                :title="$t('labels.showcontract')">
                <font-awesome-icon icon="file-pdf" /> {{ $t('labels.showcontract') }}
            </b-button>
            <b-button href="javascript:;" variant="primary" @click="generate_urenbonpdf(contract_id)"
                :title="$t('labels.showurenbon')">
                <font-awesome-icon icon="hourglass-half" /> {{ $t('labels.showurenbon') }}
            </b-button>

            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
            <b-container fluid>
                <div class="form-row">
                    <div class="col-3">
                        {{ $t("labels.emailsendersname") }}
                    </div>
                    <div class="col-8">
                        <v-select v-if="false" :options="this.activeemailsenders.concat(authemailsenders)" label="name" v-model="sender" />
                        <v-select :options="this.authemailsenders" label="name" v-model="sender" />
                    </div>
                </div>                    
                <div class="form-row">
                    <div class="col-3">
                        {{ $t("labels.sendcc") }}
                    </div>
                    <div class="col-8">
                        <v-select multiple :options="this.cc_bcc_users" v-model="cc" />
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-3">
                        {{ $t("labels.sendbcc") }}
                    </div>
                    <div class="col-8">
                        <v-select multiple :options="this.cc_bcc_users" v-model="bcc" />
                    </div>
                </div>
                <hr>
                <div class="form-row">
                    <div class="col-3">
                        {{ $t("titles.subject") }}
                    </div>

                    <div class="col-8">
                        <b-input  :state="subject!=null&&subject!=''" v-model="subject" required :placeholder='$t("titles.subject")' />
                    </div>
                </div>
                <div class="form-row">

                    <div class="col-3">
                        {{ $t("labels.contactcompanydocuments") }} <strong style="color:red" v-if="companydocuments.filter(cd=>cd.required==1).length>0">!</strong>
                    </div>

                    <div class="col-8">
                        <v-select multiple :options="this.companydocuments" label="name" v-model="companyselected" >
                            <template slot="option" slot-scope="option">
                                {{ option.name }}
                                    <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.required==1" icon="exclamation" />
                            </template>
                            </v-select>
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-3">
                        {{ $t("labels.contactmaindocuments") }}
                    </div>

                    <div class="col-8">
                        <v-select multiple :options="this.documents" label="name" v-model="selected" />
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-3">
                        {{ $t("labels.zzpdocuments") }}
                    </div>

                    <div class="col-8">
                        <v-select multiple :options="this.zzprdocuments" label="name" v-model="zzprselected" />
                    </div>
                </div>        
                <div class="form-row" >
                    <div class="col-3">
                        {{ $t("titles.documents") }}
                    </div>

                    <div class="col-1">
                        ID
                    </div>
                    <div class="col-2">
                    </div>
                    <div class="col" :class="rowClass('ID')">
                        {{$t('labels.expirationdate')}} {{ docdate('ID') }}
                    </div>
                </div>                        
                <div class="form-row" v-if="hasdoc('KvK')">
                    <div class="col-3">
                        <!-- {{ $t("titles.documents") }} -->
                    </div>

                    <div class="col-1">
                        <a :href="docto('KvK')" v-bind:target="'_blank'">KVK</a>
                    </div>
                    <div class="col-2">
                        <b-form-checkbox v-model="addKVK" name="checkbox-kvk" value=1
                            unchecked-value=0>KvK</b-form-checkbox>
                    </div>
                    <div class="col" :class="rowClass('KvK')">
                        {{$t('labels.expirationdate')}} {{ docdate('KvK') }}
                    </div>
                </div>
                <div class="form-row" v-if="hasdoc('VCA')">
                    <div class="col-3"></div>
                    <div class="col-1">
                        <a  :href="docto('VCA')" v-bind:target="'_blank'">VCA</a>
                    </div>
                    <div class="col-2">
                        <b-form-checkbox v-model="addVCA" name="checkbox-vca" value=1
                            unchecked-value=0>VCA</b-form-checkbox>
                    </div>
                    <div class="col" :class="rowClass('VCA')">
                        {{$t('labels.expirationdate')}} {{ docdate('VCA') }}
                    </div>
                </div>
                <div class="form-row" v-if="hasdoc('GPI')">
                    <div class="col-3">
                    </div>
                    <div class="col-1">
                        <a :href="docto('GPI')" v-bind:target="'_blank'">GPI</a>
                    </div>
                    <div class="col-2">
                        <b-form-checkbox v-model="addGPI" name="checkbox-gpi" value=1
                            unchecked-value=0>GPI</b-form-checkbox>
                    </div>
                    <div class="col" :class="rowClass('GPI')">
                        {{$t('labels.expirationdate')}} {{ docdate('GPI') }}
                    </div>
                </div> 
                <div class="form-row">
                    <div class="col-3">
                    </div>
                    <div class="col-1">
                        <a href="javascript:void(0)"  @click="generate_urenbonpdf(contract_id)">{{ $t('labels.hourregistrationbon') }}</a>
                    </div>                    
                    <div class="col-1">
                        <b-form-checkbox v-model="addURENBON" name="checkbox-urenbon" value=1 
                            unchecked-value=0 />
                    </div>
                    <div class="col-2">
                        <a href="javascript:void(0)"  @click="generate_pdf(generate)">{{ $t('labels.contract') }}</a>
                    </div>                    
                    <div class="col-1">
                        <b-form-checkbox v-model="addOVEREENKOMST" name="checkbox-urenbon" value=1 
                            unchecked-value=0 />
                    </div>                    
                </div>
                {{ planning.project_description }}
                <div id="app" :style="ckeditorState(editorData)" >
                    <ckeditor  :editor="editor" v-model="editorData" :config="editorConfig" ></ckeditor>
                </div>
                <h5>{{$t('labels.contractnotification')}}</h5>
                <div class="row">
                 
                    <div class="col-3">
                        <b-form-radio v-model="sendnotification" value=1  > {{ $t('labels.no') }} </b-form-radio>
                    </div> 
                    <div class="col-3" v-if="user.sms==1 && smsEnabled()">
                        <b-form-radio v-model="sendnotification" value=2 > {{ $t('labels.sendsms') }} </b-form-radio>
                    </div> 
                    <div class="col-3" v-if="user.inapp==1 && user.token!=null && user.token!=undefined">
                        <b-form-radio v-model="sendnotification" value=3 > {{ $t('meta.inapp') }} </b-form-radio>
                    </div>                                                    
                </div>
                <textarea style="width:100%; height:100px"  v-model="notificationtext"></textarea>

            </b-container>
        </div>
        <div class="popup-footer">
            <div v-if="validated()" @click="save_and_close" class="btn btn-primary">{{ $t("buttons.sendcontract") }}</div>
            <div v-else  class="btn btn-primary">{{ $t("buttons.invalid") }}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import popup_confirm from './PopupConfirm.vue'

import axios from 'axios';

export default {
    name: 'send_contract_modal',
    data() {
        return {
            editor: ClassicEditor,
            editorData: '',
            editorConfig: {
                toolbar: {
                items:[ 'heading', '|', 'undo', 'redo', '-', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ,'insertTable'],
                shouldNotGroupWhenFull: true
                },
                heading: {
                options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
                ]
                },
                table: {
                    contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
                },
                placeholder:this.$t('labels.message')
            },
            sendcontact: 1,
            sendzzpr: 1,
            selected: [],
            companyselected: [],
            zzprselected:[],
            subject: '',
            cc: [],
            bcc: [],
            addKVK: "0",
            addVCA: "0",
            addGPI: "0",
            addURENBON: "0",
            addOVEREENKOMST:"1",
            sender:null,
            sendnotification:1,
            // notificationtext:""

        }
    },
    props: {
        contract_id: Number,
    },

    mounted() {
        this.companyselected = this.companydocuments.filter(cd=>cd.required==1)
    },
    computed: {
        generate() {
            let contract = {}
            contract.contract_id = this.contract_id
            return contract
        },
        notificationtext(){
            let d = this.settings.contractnotification.replace(/\[firstname\]/gi, this.user_by_id(this.contract.user_id).firstname)

            let sendername =["onbekend"]
            if(this.sender) sendername = this.sender.name.split(" ")

            d = d.replace(/\[sender\]/gi, sendername[0])
            return d
        },
        company() {
            return this.company_by_id(this.project_by_id(this.contract.project_id).company_id)
        },
        cc_bcc_users() {
            let persons = []
            this.company.contactpersons.forEach(aperson => {
                if(this.user_by_id(aperson)&& this.user_by_id(aperson).blacklist!=1){
                    persons.push({ id: aperson, label: this.username_by_id(aperson) })
                }
            });
            this.owners.forEach(aperson => {
                if(this.user_by_id(aperson) && this.user_by_id(aperson).blacklist!=1){
                    persons.push({ id: aperson.id, label: this.username_by_id(aperson.id) })
                }
            });
            return persons
        },
        contract() {
            return this.contract_id > 0 ? this.contract_by_id(this.contract_id) : this.contract_new
        },
        planning() {
            return this.planning_by_id(this.contract.planning_id)
        },
        user() {
            return this.user_by_id(this.contract.user_id)
        },
        companydocuments() {
            return this.company_by_id(this.project_by_id(this.contract.project_id).company_id).documents
        },
        zzprdocuments() {
            return this.user.documents.filter(d => d.name!='KvK' && d.name!='VCA' && d.name!='GPI')
        },
        ...mapGetters([
            'planning_by_id',
            'project_by_id',
            'contract_by_id',
            'contracts_metas',
            'contract_new',
            'username_by_id',
            'user_by_id',
            'company_by_id',
            'server_url',
            'owners',
            'activeemailsenders'


        ]),
        ...mapState([
            'users',
            'settings',
            'contracts',
            'current_user',
            'documents',
            'emailsenders',
            'authemailsenders'


        ])
    },
    methods: {
        validated(){
            return this.subject !=null && this.subject !="" && this.editorData!=null && this.editorData!=""
        },
        async save_and_close() {
            // generate contract and urenenbriefjes
            await this.generate_contract(this.contract_id);
            await this.generate_urenbon(this.contract_id);

            //let email=this.user_by_id(this.contract.user_id).email
            let name = this.username_by_id(this.contract.user_id)
            let message = {}
            message.email = this.user_by_id(this.contract.user_id).email
            message.name = name
            message.message = this.editorData
            message.subject = this.subject
            message.sender = this.sender ? this.sender.id : -1
            message.sendermail =  this.sender ? this.sender.email : -1
            message.sendername =  this.sender ? this.sender.name : -1

            message.userdocuments = []
            if(this.addOVEREENKOMST == "1"){
                message.userdocuments.push({ file: 'contracts/overeenkomst-' + this.contract_id + ".pdf", name: "Contract" });
            }
            if (this.addURENBON == '1') {
                message.userdocuments.push({ file: 'contracts/urenbon-' + this.contract_id + ".pdf", name: "Urenbon" });
            }
            this.zzprselected.forEach(element => {
                message.userdocuments.push({ file: element.link, name: element.name })
            });
            
            this.user.documents.forEach(element => {
                if ((this.addKVK == "1" && element.name == "KvK") || (this.addVCA == "1" && element.name == "VCA") || (this.addGPI == "1" && element.name == "GPI"))
                    message.userdocuments.push({ file: element.link, name: element.name + " " + name })
            });
            message.companydocuments = []
            this.companyselected.forEach(element => {
                message.companydocuments.push({ file: element.link, name: element.name })
            });

            message.maindocuments = []
            this.selected.forEach(element => {
                message.maindocuments.push({ file: element.file, name: element.name })

            });
            message.cc = []
            if(this.sender){
                message.cc.push(this.sender.email)
            }             
            this.cc.forEach(element => {
                message.cc.push(this.user_by_id(element.id).email)
            })
            message.bcc = []
            this.bcc.forEach(element => {
                message.bcc.push(this.user_by_id(element.id).email)
            })

            try {
                axios.post('/api/send_contract_zzper', message).then((response) => {
                    // console.log('succes', response)
                    if(response.data.status && response.data.status!="ok"){
                        this.$modal.show(popup_confirm, {
                        message:this.$t('validation.mailfailed'),
                        header: this.$t('titles.mailfailed'),
                        }, {
                            name: 'popup_confirm',
                            draggable: false,
                            height: 'auto',
                            width: '90%'
                        })                   
                    }                    
                })
            } catch (err) {
                console.log(err)
            }
            // check sendnotification
            if(this.sendnotification==2){
                // send SMS
                let message ={}
              message.sms = this.notificationtext
              message.sendto = this.user.mobile
              message.sendfrom  = this.users.filter(u=>u.email == this.sender.email)[0].mobile
                console.log("SnedSMS ", message)
                try{
                    axios.post('/api/send_sms', message)  .then((response) => {
                        console.log('succes',response)
        
                    })
                }
                catch(err){
                    console.log (err)
                }  
            }
            if(this.sendnotification==3){
                //send inapp push
                let message = {}
                message.message = this.notificationtext
                message.subject = this.subject

                message.user_id = this.user.id
                if(this.settings.PMHistory) {
                    this.add_pm( { id: 0, from: this.current_user.id, to: this.user_id, message:this.subject+"<br>"+message.message })
                }
                try {
                    axios.post('/api/notify', message).then((response) => {
                        console.log('succes', response)
                    })
                } catch (err) {
                    console.log(err)
                }

            }

            this.$emit('close')
        },
        ...mapActions([
            'add_contract',
            'generate_contract',
            'generate_urenbon',
            'add_pm'

        ]),
        rowClass(item) {
            if (!item ) return
            var expireDate = null
            this.user.documents.forEach(adoc => {
                if (adoc.name == item){
                    expireDate = new Date(adoc.expires)
                }
            })      
            if(item=="ID")      {
                expireDate = new Date(this.user.identification_endate)
            }
            var today = new Date();
            var diff = (expireDate.getTime() - today.getTime()) / (1000 * 3600 * 24)
            if ((diff < 2)) {
                return 'status-expires-dialog'
            }
            if ((diff < 31)) {
                return 'status-expires-soon-dialog'
            }      
            return 'status-active-dialog'

        },        
        hasdoc(id) {
            var _hasdoc = false
            this.user.documents.forEach(adoc => {
                if (!_hasdoc) {
                    _hasdoc = (adoc.name == id)
                }
            })
            return _hasdoc

        },
        
        docdate(id) {
            let ret =""
            if(id=="ID"){
                ret = this.dateToNL(this.user.identification_endate)
            }else {
            this.user.documents.forEach(adoc => {
                if (adoc.name == id){
                    ret = this.dateToNL(adoc.expires)
                }
            })
        }
            return ret

        },        
        docto(id) {
            var _link = ''
            this.user.documents.forEach(adoc => {
                if ((adoc.name == id)) {
                    _link = adoc.link
                }
            })
            return this.server_url + '/media_' + _link;
        },

    },
}
</script>