<template>
  <b-modal id="BSShowinvoiceDataID" centered size="lg">
    <template #modal-header v-if="!inkoop">
      <h2   class="modal-title">{{ $t("labels.invoice") }}
      {{ contract.facnumverkoop }}</h2>         <a  href='javascript:;' class='btn btn-primary float-left'
          @click="gen_invoice()" :title="$t('labels.invoice_verkoop')">
          <font-awesome-icon v-if="!gen_invoice_busy" size="2x" icon="file-invoice" />
          <b-spinner small v-else></b-spinner>{{ $t('labels.invoice_verkoop') }}
        </a>
    </template>
    <template #modal-header v-else>
      <h2   class="modal-title">{{ $t("labels.invoice") }}
      {{ contract.facnuminkoop }}</h2>         <a  href='javascript:;' class='btn btn-primary float-left'
          @click="gen_invoice()" :title="$t('labels.invoice_inkoop')">
          <font-awesome-icon v-if="!gen_invoice_busy" size="2x" icon="file-invoice" />
          <b-spinner small v-else></b-spinner>{{ $t('labels.invoice_inkoop') }}
        </a>
    </template>    
    <div class="form-row">
      <div class="col-3">
        {{ $t("tableheaders.company_id")}} 
      </div>
      <div class="col-6">
        <strong> {{ company_by_id(project_by_id(contract_by_id(contract.contract_id).project_id).company_id).company_name }} </strong>
      </div>
    </div>
    
    <div class="form-row">
      <div class="col-3">
        {{ $t("labels.projectname")}}
      </div>
      <div class="col-6">
        <strong> {{ planning_by_id(contract_by_id(contract.contract_id).planning_id).project_name }} </strong>
      </div>
    </div>
    <div class="form-row">
      <div class="col-3">
        {{ $t("labels.authorizedsignatory")}}
      </div>
      <div class="col-6">
        <strong> {{ username_by_id(planning_by_id(contract_by_id(contract.contract_id).planning_id).sign_id) }} </strong>
      </div>
    </div>
    <div class="form-row" v-if="contract.hourcode">
      <div class="col-3">
        {{ $t("labels.hourcategoryname")}}
      </div>
      <div class="col-6">
        <strong> {{ hourcode_by_id(contract.hourcode).name }} </strong>
      </div>
    </div>
    <div class="form-row">
      <div class="col-3">
        {{ $t("labels.week")}} 
      </div>
      <div class="col-6">
        <strong> {{ this.contract.week }} </strong>
      </div>
    </div>
    <div class="form-row">
      <div class="col-3">
        {{ $t("labels.year")}}
      </div>
      <div class="col-6">
        <strong> {{ year }} </strong>
      </div>
    </div>
    <div class="form-row" v-if="!inkoop">
      <div class="col">
        <UrenBonnen :contract_id="contract.contract_id" :week_number="contract.week" :year_number="year"
          :isclosed="true"/>
      </div>
    </div>
    <div class="form-row">
      <div class="col-3">
          {{ $t("labels.emailsendersname") }}
      </div>
      <div class="col-8">
          <v-select :options="this.authemailsenders" label="name" v-model="sender" />
      </div>
    </div>                
    <div class="form-row" v-if="!inkoop">
      <div class="col-3">
          {{ $t("labels.sendto") }}
      </div>
      <div class="col-8">
        <v-select :options="this.cc_bcc_users" v-model="to">
          <template slot="option" slot-scope="option">
              {{ option.label }}
              <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.company_contact" icon="address-book" />
              <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.document_contact" icon="file" />
              <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.invoice_contant" icon="euro-sign" />
              <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.sign_contact" icon="pencil-alt" />
              <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.location_contact" icon="street-view" />
          </template>
        </v-select>
      </div>
    </div>
    <div class="form-row" v-if="!inkoop">
      <div class="col-3">
          {{ $t("labels.sendcc") }}
      </div>
      <div class="col-8">
      <v-select multiple :options="this.cc_bcc_users" v-model="cc" >
        <template slot="option" slot-scope="option">
          {{ option.label }}
          <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.company_contact" icon="address-book" />
          <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.document_contact" icon="file" />
          <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.invoice_contant" icon="euro-sign" />
          <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.sign_contact" icon="pencil-alt" />
          <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.location_contact" icon="street-view" />
        </template>
      </v-select>
    </div>
    </div>      
    <div class="form-row">
      <div class="col-3">
        {{ $t("titles.subject") }}
      </div>
      <div class="col-8">
          <b-input  :state="subject!=null&&subject!=''" v-model="subject" required :placeholder='$t("titles.subject")' />
      </div>
    </div>                   
              <div id="app" :style="ckeditorState(editorData)" >
                    <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
                </div>              
      <template #modal-footer>
        <!-- <div class="btn btn-primary" @click="close_modal">{{ $t("buttons.close") }}</div> -->
        <div v-if="subject==null||subject==''||editorData==null||editorData==''" class="btn btn-primary">{{ $t("buttons.invalid") }}</div>
        <div v-else @click="save_and_close" class="btn btn-primary">{{ $t("buttons.emailinvoice") }}</div>

          </template>
  </b-modal>
</template>


<script>
import { mapGetters,  mapState } from 'vuex'
import axios from 'axios'
import UrenBonnen from '../parts/ActiveUrenbonnen.vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: 'invoice-data-modal',
  data() {
    return {
      gen_invoice_busy:false,
      editor: ClassicEditor,
            editorData: '',
            editorConfig: {
              toolbar: {
                items:[ 'heading', '|', 'undo', 'redo', '-', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ,'insertTable'],
                shouldNotGroupWhenFull: true
              },
              heading: {
                options: [
                  { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                  { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                  { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
                ]
              },
              table: {
                  contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
              },
              placeholder:this.$t('labels.message')
            },
            to: null,
            cc: [],
            bcc: [],
            subject:"",
            sender:null

    }
  },
  props: {
    contract: Object,
    inkoop:Boolean
  },
  computed: {
    ...mapGetters([
      'server_url',
      'factuur_by_id',
      'project_by_id',
      'username_by_id',

    ]),
    ...mapState([
      'users',
      'branches',
      'avatars',
      'urenbonnen',
      'authemailsenders'
    ]),
    year(){
      return new Date(this.planning_by_id(this.contract_by_id(this.contract.contract_id).planning_id).startdate).getFullYear()
    },
    cc_bcc_users() {
        let persons = []
        this.company_by_id(this.project_by_id(this.contract_by_id(this.contract.contract_id).project_id).company_id).contactpersons.forEach(aperson => {
            persons.push({ id: aperson, label: this.username_by_id(aperson), 
                company_contact:this.user_by_id(aperson).company_contact == '1',
                document_contact:this.user_by_id(aperson).document_contact == '1',
                invoice_contant:this.user_by_id(aperson).invoice_contant == '1',
                sign_contact:this.user_by_id(aperson).sign_contact == '1',
                location_contact:this.user_by_id(aperson).location_contact == '1'
              })
        });
        return persons
    },    

  },
  methods: {
    async gen_invoice(){

      this.gen_invoice_busy = true
      let data = {}
      data.dates = this.getWeekDates(this.contract.week, this.year)
      data.user_id = this.contract.user_id
      data.contract_id = this.contract.contract_id
      data.week = this.contract.week
      data.hourcode = this.contract.hourcode?parseInt(this.contract.hourcode):null
      console.log(data, "<<")
      if(this.inkoop){
      try {
        await axios.post('/api/generate_inkoop', data).then(() => {

          var routeUrl 
          if(data.hourcode==null){
            routeUrl = this.server_url + '/media_invoices/factuur-' + data.contract_id + "-" + data.user_id + "-" + data.week + ".pdf"
            
          } else{
            routeUrl = this.server_url + '/media_invoices/factuur-' + data.contract_id + "-" + data.user_id + "-" + data.week + "-" + data.hourcode + ".pdf"
          }
          window.open(routeUrl, '_blank')
          this.gen_invoice_busy = false
        })
      } catch (err) {
        console.log(err)
      }
    } else {
      try {
        await axios.post('/api/generate_verkoop', data).then(() => {
          var routeUrl 
          if(data.hourcode==null){
            routeUrl = this.server_url + '/media_invoices/verkoopfactuur-' + data.contract_id + "-" + data.user_id + "-" + data.week + ".pdf"
          } else{
            routeUrl = this.server_url + '/media_invoices/verkoopfactuur-' + data.contract_id + "-" + data.user_id + "-" + data.week + "-" + data.hourcode + ".pdf"
          }
          window.open(routeUrl, '_blank')
          this.gen_invoice_busy = false
        })
      } catch (err) {
        console.log(err)
      }      
    }
    },
    async save_and_close() {
  
      this.gen_invoice_busy = true
      let data = {}
      data.dates = this.getWeekDates(this.contract.week, this.year)
      data.user_id = this.contract.user_id
      data.contract_id = this.contract.contract_id
      data.week = this.contract.week
      data.hourcode = parseInt(this.contract.hourcode)
      console.log(data)
      if(this.inkoop){
        try {
        await axios.post('/api/generate_inkoop', data).then(() => {
          // let routeUrl = this.server_url + '/media_invoices/verkoopfactuur-' + data.contract_id + "-" + data.user_id + "-" + data.week + ".pdf"
          // window.open(routeUrl, '_blank')
          this.gen_invoice_busy = false

            let to_id = this.contract.user_id
            let name = this.username_by_id(to_id)
            let message = {}
            // message.email = this.user_by_id(this.planning.sign_id).email
            message.email = this.user_by_id(to_id).email
            message.name = name
            message.message = this.editorData
            message.subject = this.subject
            message.sender = this.sender ? this.sender.id : -1
            message.sendermail =  this.sender ? this.sender.email : -1
            message.sendername =  this.sender ? this.sender.name : -1
            message.maindocuments = []
            if(data.hourcode){
              message.maindocuments.push({ file: '/invoices/factuur-' + data.contract_id + "-" + data.user_id + "-" + data.week +"-" + data.hourcode + ".pdf", name: "factuur" });

            } else {
              message.maindocuments.push({ file: '/invoices/factuur-' + data.contract_id + "-" + data.user_id + "-" + data.week + ".pdf", name: "factuur" });

            }
            //urenbonnen worden nooit meegesturud bij een inkoop factuur
            // let bonnen = this.urenbonnen.filter(bon => {
            //   return bon.contract_id == this.contract.contract_id && bon.week == this.contract.week
            // })
            // if (bonnen.length > 0) {
            //   bonnen.forEach(bon => {
            //     if(bon.name.substring(0,6)!="intern"){
            //       message.maindocuments.push({ file: '/' + bon.file, name: bon.name });
            //     }
            //   })
            // }            
            // add to, cc and bcc
            message.to = []
            message.cc = []
            // copy to sender
            if(this.sender){
                message.cc.push(this.sender.email)
            }             
            this.cc.forEach(element => {
                message.cc.push(this.user_by_id(element.id).email)
            })
            if (this.extra_cc) {
                this.extra_cc.split(",").forEach(element => {
                    message.cc.push(element)
                })
            }
            // console.log('lets send', message)

            try {
                axios.post('/api/contact_user',message).then((response) => {
                    console.log('succes', response)
                    this.close_modal();
                })
            } catch (err) {
                console.log(err)
            }

          })
      } catch (err) {
        console.log(err)
      }

      } else {
        try {
          await axios.post('/api/generate_verkoop', data).then(() => {
            // let routeUrl = this.server_url + '/media_invoices/verkoopfactuur-' + data.contract_id + "-" + data.user_id + "-" + data.week + ".pdf"
            // window.open(routeUrl, '_blank')
            this.gen_invoice_busy = false

              let to_id = this.to.id?this.to.id:this.planning.sign_id
              let name = this.username_by_id(to_id)
              let message = {}
              // message.email = this.user_by_id(this.planning.sign_id).email
              message.email = this.user_by_id(to_id).email
              message.name = name
              message.message = this.editorData
              message.subject = this.subject
              message.sender = this.sender ? this.sender.id : -1
              message.sendermail =  this.sender ? this.sender.email : -1
              message.sendername =  this.sender ? this.sender.name : -1

              message.maindocuments = []
              if(data.hourcode){
                message.maindocuments.push({ file: '/invoices/verkoopfactuur-' + data.contract_id + "-" + data.user_id + "-" + data.week +  "-" + data.hourcode + ".pdf", name: "factuur" });

              } else {
                message.maindocuments.push({ file: '/invoices/verkoopfactuur-' + data.contract_id + "-" + data.user_id + "-" + data.week + ".pdf", name: "factuur" });
              }
              //urenbonnen
              let bonnen = this.urenbonnen.filter(bon => {
                return bon.contract_id == this.contract.contract_id && bon.week == this.contract.week
              })
              if (bonnen.length > 0) {
                bonnen.forEach(bon => {
                  if(bon.name.substring(0,6)!="intern"){
                    message.maindocuments.push({ file: '/' + bon.file, name: bon.name });
                  }
                })
              }            // add to, cc and bcc
              message.to = []
              // this.to.forEach(element => {
              //     message.to.push(this.user_by_id(element.id).email)
              // })
              message.cc = []
              // copy to sender
              if(this.sender){
                  message.cc.push(this.sender.email)
              }             
              this.cc.forEach(element => {
                  message.cc.push(this.user_by_id(element.id).email)
              })
              if (this.extra_cc) {
                  this.extra_cc.split(",").forEach(element => {
                      message.cc.push(element)
                  })
              }
              console.log('lets send', message)

              try {
                  axios.post('/api/contact_user',message).then((response) => {
                      console.log('succes', response)
                      this.close_modal();
                  })
              } catch (err) {
                  console.log(err)
              }

            })
        } catch (err) {
          console.log(err)
        }
      }
      this.$emit('close')
    },
    close_modal() {
      this.$bvModal.hide('BSShowinvoiceDataID')
    },
  },
  components: {UrenBonnen },

}    
</script>
