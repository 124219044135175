<template>
  <div class="popup">
    <div class="popup-header">
      Send test Whatsapp
      <div class="popup-close" @click="$emit('close')">
        <font-awesome-icon icon="times" />
      </div>
    </div>
    <div class="popup-body">
      <div class="form-row">
        <div class="col-3">
          Number
        </div>

        <div class="col-8">
          <b-form-input class="form-control" v-model="number" />
        </div>
      </div>
      <b-container fluid>
        <div id="app">
          <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
        </div>
      </b-container>
    </div>
    <div class="popup-footer">
      <div @click="save_and_close" class="btn btn-primary">Send</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';

export default {
  name: 'send_whatsapp_modal',
  data() {
    return {
      editor: ClassicEditor,
      editorData: '<p>Message for user.</p>',
      editorConfig: {
        toolbar: {
          items:[ 'heading', '|', 'undo', 'redo', '-', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ,'insertTable'],
          shouldNotGroupWhenFull: true
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
          ]
        },
        table: {
            contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        }
      },
      number: "",
      selected: [],
    }
  },
  mounted() {
  },
  computed: {


    user() {
      return this.user_by_id(this.user_id)
    },

    ...mapGetters([
      'planning_by_id',
      'project_by_id',
      'contract_by_id',
      'contracts_metas',
      'contract_new',
      'username_by_id',
      'user_by_id',
      'company_by_id',

    ]),
    ...mapState([
      'users',
      'settings',
      'contracts',
      'current_user',
      'documents'

    ])
  },
  methods: {
    save_and_close() {
      let message = {}
      message.message = this.editorData
      message.number = this.number
      try {
        axios.post('/api/testwhatsapp', message).then((response) => {
          console.log('succes', response)
        })
      } catch (err) {
        console.log(err)
      }
      this.$emit('close')
    },
    ...mapActions([

    ]),

  },
}
</script>