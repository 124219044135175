<template>
	<div class="popup">

        <div class="popup-header">

            <div v-if="document.id > 0">
                {{document.name}}
            </div>
            <div v-else>
                 {{ $t("buttons.adddocument") }}
            </div>
            <div v-if="document.id == 0" class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
            <div v-else class="popup-close" @click="save_and_close">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
            <b-container>
            <div class="form-row">
                <div class="col-3">
                    <label for=""> {{ $t("labels.name") }} lala</label>
                </div>
                <div class="col-1">
                    <font-awesome-icon icon="exclamation" color="red" v-if="checkfilecharacters(document)==false"></font-awesome-icon>
                    <font-awesome-icon icon="check" color="green" v-else></font-awesome-icon>
                </div>

                <div class="col">
                    <b-form-input type="text" class="form-control" v-model="document.name"  v-on:keypress="isLetter($event)"  @blur="removeInvalidChars()" />              
                </div>
            </div>    
            <div class="form-row">
                <div class="col-4">
                    <label for=""> {{ $t("labels.expirationdate") }}</label>
                </div>
                <div class="col">
                    <b-form-input type="date" class="form-control" v-model="document.expires"  />            
                </div>
            </div>
            <div v-if="document.id == 0" class="form-row">
                <div class="col-3">
                    <label for=""> {{ $t("labels.file") }}</label>
                </div>
                <div class="col-1">
                    <font-awesome-icon icon="exclamation" color="red" v-if="file1==null || checkfilecharacters(file1)==false"></font-awesome-icon>
                    <font-awesome-icon icon="check" color="green" v-else></font-awesome-icon>
                </div>

                <div class="col">
                    <b-form-file ref="file-input" class="form-control" accept="*/*" v-model="file1"  />
                </div>
                
            </div> 
            </b-container>
        </div>
        <div class="popup-footer">
            <div  @click="save_and_close" class="btn btn-primary">{{ validateText()}}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import axios from 'axios'

export default {
    name: 'documents-modal',
    data() {
        return {
            new_document:{id:0, name:"new", active:1},
            file1:null

        }
    },
    props: {
        document_id: Number
    },
    mounted() {
    },
    computed: {
        document() {
            return this.document_id > 0 ? this.document_by_id(this.document_id) : this.new_document
        },
        ...mapGetters([
            'document_by_id',
        ]),
        ...mapState([
            'documents',
        ]),
    },
    methods: {
        validateText(){
            if(document.name!="" &&this.checkfilecharacters(document)&&(this.document_id!=0||(this.file1!=null&&this.checkfilecharacters(this.file1)))){
                if(this.document_id == 0 ){
                    return this.$i18n.t("buttons.adddocument")
                } else{
                    return this.$i18n.t("buttons.saveandclose")
                
                }

        } else {
                return this.$i18n.t("buttons.invalid")
            }
        },

        save_and_close(e) {
            if(this.name!="" &&this.checkfilecharacters(this)&&(this.document_id!=0||(this.file1!=null&&this.checkfilecharacters(this.file1)))){
                e.preventDefault() // it prevent from page reload
                if(this.document.id==0){
                    this.my_add_document()
                }else{
                    this.save_document(this.document.id)
                    this.$emit('close')
                }            
            }
        },
        ...mapActions([
            'save_document',
            'add_document'
        ]),
        isLetter(e) {
            let char = String.fromCharCode(e.keyCode); 
            if(/^[A-Za-z0-9 -]+$/.test(char)) return true;
            else e.preventDefault(); 
        },
        removeInvalidChars() {
            this.document.name = this.document.name.replace(/\//g,'')
        },          
        my_add_document(){
            const formData = new FormData();
            formData.append('name', this.document.name);
            formData.append('expires', this.document.expires)
            formData.append('file', this.file1)
            let adoc={}
            for (var pair of formData.entries()) {
            adoc[pair[0]] = pair[1]
            }  
            adoc.link = "main_documents/"+this.document.name+"-"+this.file1.name
            this.documents.push(adoc)
            try{
            axios.post('/api/maindocument/', formData)  .then((response) => {
                console.log('succes',response)
            this.close_modal();        
            })
            this.close_modal();        

            } catch(err){
            console.log (err)
            }
        },
        close_modal() {
            this.$emit('close')
        } ,
      
    },
 
}
</script>