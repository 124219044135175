<template>
  <b-container v-if="createAny('users') && currentUserIsSuperAdmin()" fluid>
    <div class="title-bar">
      <h1>{{ $t("titles.documents") }}</h1>
      <div @click="edit_document({ id: 0 })" class="btn btn-primary">
        <font-awesome-icon icon="plus" /> {{ $t("buttons.adddocument") }}
      </div>
    </div>
    <b-table :items=documents :fields=fields @row-clicked="onDocumentClicked">
      <template #cell(actions)="row">
        <a href="javascript:;" class="action-item" @click="delete_a_document(row.item.id)"
          :title="$t('labels.deletedocument')">
          <font-awesome-icon icon="trash" />
        </a>
        <a href="javascript:;" class="action-item" @click="onDocumentClicked(row.item)"
          :title="$t('labels.editdocument')">
          <font-awesome-icon icon="cog" />
        </a>
      </template>
      <template #cell(id)="row">
        <a :href=docto(row) v-bind:target="'_blank'">{{row.item.name}}</a>
      </template>
    </b-table>

    <h2 class="mt-5">{{$t('labels.contract')}} {{$t('titles.documents')}}</h2>
    <p> {{$t("validation.contractdocuments")}}</p>
    <!-- <div class="row">
      <div class="col-2">
        <a href="/api/media_main_documents/ALG_Main_contract.pdf" target="_blank">{{$t('titles.agreement_contract')}}</a>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-3">
            <label for=""> {{ $t("labels.file") }}</label>
          </div>
          <div class="col">
            <b-form-file ref="file-input" class="form-control" accept="*/*" v-model="file1"  />
          </div>
        </div> 
      </div>
      <div class="row">
        <div class="col-3">
          <div v-if="file1" class="btn btn-primary" @click="my_add_document();">{{ $t("buttons.replace") }}</div>
        </div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-2">
        <a href="/api/media_main_documents/ALG_Voorwaarden.pdf" target="_blank">{{$t('titles.termsandconditions')}}</a>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-3">
            <label for=""> {{ $t("labels.file") }}</label>
          </div>
          <div class="col">
            <b-form-file ref="file-input" class="form-control" accept="*/*" v-model="file2"  />
          </div>
        </div> 
      </div>
      <div class="row">
        <div class="col-3">
          <div v-if="file2" class="btn btn-primary" @click="my_add_terms();">{{ $t("buttons.replace") }}</div>
        </div>
      </div>
    </div>

  </b-container>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import documents_details_modal from './modals/DocumentsModal'
import popup_modal from './modals/PopupModal.vue'
import axios from 'axios'

export default {
  name: 'declaratiesscreen',
  data() {
    return {
      selectedID: null,
      file1:null,
      file2:null
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: this.$i18n.t('labels.name'), sortable: true, sortDirection: 'desc' },
        { key: 'expires', label: this.$i18n.t('labels.expirationdate') },
        { key: 'actions', label: this.$i18n.t('labels.actions') },
      ]
    },

    ...mapState([
      'documents',
    ]),
    ...mapGetters([
      'document_by_id',
      'confirm_modal',
      'server_url',
      'createAny'

    ]),
  },
  methods: {
    ...mapActions([
      'delete_document',
      'save_document'
    ]),
    delete_a_document(id) {
      this.selectedID = id
      let message = this.$i18n.t('labels.confirmdeletehead') + this.document_by_id(id).name + " " + this.$i18n.t('labels.confirmdeletetail')
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
        message: message,
      }, {
        name: 'popup_modal',
        draggable: false,
        height: 'auto',
        width: '500px'
      }, {
        'before-close': this.modalClose
      })

      this.$emit('close')

    },
    modalClose() {
      if (this.confirm_modal.id == 1) {
        this.delete_document(this.selectedID);
        for (let i = 0; i < this.documents.length; i++) {
          if (this.documents[i].id == this.selectedID) {
            this.documents.splice(i, 1);
          }
        }
      }
    },
    onDocumentClicked(row) {
      this.$modal.hide('documents_details_modal')
      this.$modal.show(documents_details_modal, {
        document_id: row.id,
      }, {
        name: 'documents_details_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '800px'
      })

      this.$emit('close')
    },
    edit_document(row) {
      this.$modal.hide('documents_details_modal')
      this.$modal.show(documents_details_modal, {
        document_id: row.id,
      }, {
        name: 'documents_details_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '800px'
      })

      this.$emit('close')
    },
    docto(id) {
      if (id.value) {
        return this.server_url + '/media_' + this.document_by_id(id.value).file;

      }
    },
    my_add_document(){
        const formData = new FormData();
        formData.append('file', this.file1)
        try{
          axios.post('/api/algemene_voorwaarden/', formData)  .then((response) => {
              console.log('succes',response)
          })
        } catch(err){
          console.log (err)
        }
      },   
      my_add_terms(){
        const formData = new FormData();
        formData.append('file', this.file2)
        try{
          axios.post('/api/terms_and_conditions/', formData)  .then((response) => {
              console.log('succes',response)
          })
        } catch(err){
          console.log (err)
        }
      },          
  },
}
</script>