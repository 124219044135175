<template>
	<div class="popup">
        <div class="popup-header">
          <div >
            {{ username_by_id(contract.user_id) }}  {{userplanning.project_name}}

          </div>
          <div class="popup-close" @click="close_modal">
            <font-awesome-icon icon="times" />
          </div>
        </div>

        <div class="popup-body">
          <b-container style="width:100% !important; max-width: 100% !important;">
            <b-row>
                <b-col cols="2">
                    {{$t("labels.client")}} 
                </b-col >
                <b-col>
                    <strong>{{company_by_id(project_by_id(contract.project_id).company_id).company_name }}</strong>
                </b-col>
                <b-col cols="2">
                    {{$t("labels.authorizedsignatory")}} 
                </b-col>
                <b-col>
                    <strong>{{username_by_id(userplanning.sign_id)}}</strong>
                </b-col>                
            </b-row>
            <b-row>
                <b-col cols="2">
                    {{$t("labels.title")}}
                </b-col>
                <b-col>
                    <strong>{{userplanning.project_name}}</strong>
                </b-col>
                <b-col cols="2">
                    {{$t("labels.duration")}}
                </b-col>
                <b-col>
                    <strong>{{new Date(userplanning.startdate).toLocaleDateString()}} {{$t("labels.to")}} {{new Date(userplanning.enddate).toLocaleDateString()}}</strong>
                </b-col>                
            </b-row>            
            <hr>              
            <HoursSchedule ref="schedule" :contract_id="this.contract_id" :user_id="this.contract.user_id" :week_number="week_number"></HoursSchedule>
            <b-row v-if="!weekisclosed" >
                <b-col>
                    {{ settings.check_hours_incorrect }}
                </b-col>
            </b-row>
          </b-container>
        </div>

        <div class="popup-footer">
            <div v-if="!weekisclosed" class="btn btn-primary" @click="hours_invalid()">{{ $t("buttons.hoursinvalide") }}</div>
            <div  class="btn btn-primary" @click="close_modal()">{{ $t("buttons.close") }}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import popup_confirm from './PopupConfirm.vue'
import HoursSchedule from '../parts/HoursSchedule'
import axios from 'axios'

export default {
    name: 'check-hours-modal',
    data() {
        return {
            weekisclosed:true

        }        
    },
	mounted: function () {
        this.weekisclosed = this.$refs && this.$refs.schedule && this.$refs.schedule.week_is_closed()

	},    
    props: {
        contract_id: Number,
        week_number:Number

    },
    computed: {
        contract() {
            return this.contract_by_id(this.contract_id) 
        },
        userplanning() {
          return this.planning_by_id(this.contract.planning_id)
        },
        ...mapGetters([
            'project_metas',
            'project_by_id',
            'project_new',
            'contract_by_id',
            'username_by_id',
            'planning_by_id',
            'company_by_id',
            'user_by_id'
        ]),
        ...mapState([
            'projects',
            'settings',
            'companies', 
        ])
    },
    methods: {
    
        hours_invalid() {
            var mailmessage = "Uren niet akkoord voor "+this.username_by_id(this.contract.user_id) +" in project "+this.userplanning.project_name+ " weeknummer "+ this.$refs.schedule.weeknumber
              let message ={}
              message.email = this.user_by_id(this.userplanning.sign_id).email
              message.name = this.username_by_id(this.userplanning.sign_id)
              message.message = mailmessage
              message.subject = "Uren niet akkoord"
              message.maindocuments = []

                try{
                    axios.post('/api/contact_user', message)  .then((response) => {
                        console.log('succes',response)
                        this.$modal.hide('popup_confirm')
                        this.$modal.show(popup_confirm, {
                            message:mailmessage,
                            header: "Message has sent"
                        }, {
                            name: 'popup_confirm',
                            draggable: false,
                            height: 'auto',
                            width: '500px'
                        }, {
                            'before-close':this.modalClose   
                        })                                    
                    })
                } catch(err){
                    console.log (err)
                } 

            this.$emit('close')
        },
        ...mapActions([
            'save_project',
            'add_project'
        ]),
        close_modal() {
            this.$emit('close')
        }        
    },
    components:{HoursSchedule}
}
</script>