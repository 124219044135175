<template>
  <div class="popup">
      <div class="popup-header">
          <div v-if="todo.id > 0">
              {{  $t("labels.edittodo") }}
          </div>
          <div v-else>
              {{ $t("labels.newtodo") }}
          </div>
          <div v-if="todo.id == 0" class="popup-close" @click="close_modal">
              <font-awesome-icon icon="times" />
          </div>
          <div v-else class="popup-close" @click="cancel_edit">
              <font-awesome-icon icon="times" />
          </div>
      </div>

      <div class="popup-body" ref="bodyTarget">
        <b-container>
          <div ref="bodyTop"></div>
          <input type="hidden" :key="componentKey" />
          <div class="row" >
            <div class="col-3">
              <label for=""> {{ $t("labels.user") }}</label>
            </div>
            <div class="col">
                <v-select label="fullname" :options="users" class="contactpersoon" v-model="contact_id" :filter="fuseSearch"></v-select>
            </div>
          </div>
          <div class="row" >
            <div class="col-3">
              <label for=""> {{ $t("labels.todoassign") }}</label>
            </div>
            <div class="col">
                <v-select label="fullname" :options="owners" class="contactpersoon" v-model="notify_id"></v-select>
            </div>
          </div>  
          <div class="row" >
            <div class="col-3"/>
            <div class="col">
                <b-form-checkbox v-model="sendmail" name="checkbox-1" value="1" unchecked-value="0">{{
                $t("buttons.sendmessage") }}</b-form-checkbox>
            </div>
        </div>
        <div class="row" >
            <div class="col-3">
                <label for=""> {{ $t("labels.sendcc") }}</label>
            </div>
            <div class="col">
                <b-form-input v-model="cc" />
            </div>
        </div>        
          <div class="row" >
            <div class="col-3">
              <label for=""> {{ $t("labels.date") }}</label>
            </div>
            <div class="col-6">
                <flat-pickr 
                v-model="due_date"
                :config="config"
                class="form-control"
                :placeholder="$t('labels.selectdate')"
              />    
                <!-- <DatePicker value-type="YYYY-MM-DD" format="DD-MM-YYYY" v-model="due_date" show-week-number :language="nl" class="contactpersoon"  /> -->

                <!-- <input type="date"  class="contactpersoon" v-model="due_date"/>  -->
                </div>
                <div class="col"><strong>{{ $t("tableheaders.week") }} {{ weeknumber }}</strong> 
            </div>
          </div>  
          <div class="row" >
            <div class="col-3">
              <label for=""> {{ $t("labels.todorepeat") }}</label>
            </div>
            <div class="col">
                <b-form-radio-group id="radio-group-1" v-model="repeattask" name="radio-options">
                    <b-form-radio name="showby" value="n">{{$t('labels.no')}}</b-form-radio>
                    <b-form-radio name="showby" value="d">{{$t('labels.repeatday')}}</b-form-radio>
                    <b-form-radio name="showby" value="w">{{$t('labels.repeatweek')}}</b-form-radio>
                    <b-form-radio name="showby" value="m">{{$t('labels.repeatmonth')}}</b-form-radio>
                    <b-form-radio name="showby" value="y">{{$t('labels.repeatyear')}}</b-form-radio>
                </b-form-radio-group>    
            </div>
        </div>
        <!-- <div class="form-row">
            <div class="col-3">
                {{ $t("titles.subject") }}
            </div>
            <div class="col-8">
                <b-input  v-model="subject" required :placeholder='$t("titles.subject")' />
            </div>
        </div>         -->
        <div class="form-row">
            <div class="col-3">
                {{ $t("labels.message") }}
            </div>
            <div class="col-8">
                <b-input-group>
                    <b-form-textarea type="text" class="form-control" rows="10" v-model="message" :ref="Kremarks" :placeholder='$t("labels.message")' />
                    <b-input-group-append v-if="createAny('users')">
                        <b-button squared class="multi-append"  href="javascript:;"
                            @click="append_data_multi('Kremarks')">
                            <font-awesome-icon icon="plus-circle" />
                        </b-button>
                    </b-input-group-append>
                </b-input-group>                
            </div>
        </div>            
        </b-container>
      </div>

      <div class="popup-footer">
          <div v-if="todo.id == 0" class="btn btn-primary" @click="my_add_todo(); ">{{ addtodoText() }}</div>
          <div v-else @click="save_and_close" class="btn btn-primary">{{ addtodoText() }}</div>
      </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
// import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';
// import 'vue2-datepicker/locale/nl';

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

const Dutch = require("flatpickr/dist/l10n/nl.js").default.nl;

export default {
  name: 'todo-modal',
  data() {
      return {
          componentKey: 0,
          contact_id:null,
          notify_id:null,
          repeattask:null,
          subject:"To do",
          message:"",
          due_date:null,
          sendmail:0,
          Kremarks: "Kremarks",
          cc:"",
          config:{
            weekNumbers:true,
            locale: Dutch,
            altInput: true,
            altFormat: "d-m-Y",              
            dateFormat: "Y-m-d",
          },



      }
  },
  props: {
      todo_id: Number
  },
  mounted: function () {
    this.contact_id = this.users.filter(u =>u.id ==this.todo.contact_id)
    this.notify_id = this.users.filter(u =>u.id ==this.todo.notify_id)
    this.repeattask = this.todo.repeattask
    this.subject = this.todo.subject
    this.message = this.todo.message
    this.sendmail = this.todo.sendmail
    this.due_date = this.todo.due_date
    this.cc = this.todo.cc

  },
  computed: {
      todo() {
          return this.todo_id > 0 ? this.todo_by_id(this.todo_id) : {id:this.todo_id, sendmail:1, subject:"To do"}
      },
      weeknumber(){
        return this.due_date!=null?this.getWeekNumbervalue(new Date(this.due_date)):""
      },
      ...mapGetters([
          'todo_metas',
          'todo_by_id',
          'activebranches',
          'todo_new',
          'createAny',
          'owners',
          'append_data_string'


      ]),
      ...mapState([
          'todos',
          'settings',
          'current_user',
          'branches',
          'users',

      ])
  },
  methods: {
    fuseSearch(options, search) {
        return options.filter(u => (u.firstname && u.firstname.toLowerCase().includes(search.toLowerCase())) ||
                                    (u.lastname && u.lastname.toLowerCase().includes(search.toLowerCase())) ||
                                    (u.firstname && u.lastname && (u.firstname.toLowerCase()+" "+u.lastname.toLowerCase()).includes(search.toLowerCase())))
    },
    append_data_multi(key) {
            var current = ""
            if (this.message) {
                current = "\n" + this.message
            }
            this.message = this.append_data_string + current
            this.componentKey += 1;
            if (this.$refs[key]) {
                if (this.$refs[key][0]) {
                    this.$refs[key][0].focus()
                    this.$nextTick(() => {
                        this.$refs[key][0].selectionEnd = 9
                    })
                } else {
                    this.$refs[key].focus()
                    this.$nextTick(() => {
                        this.$refs[key].selectionEnd = 9
                    })
                }
            }
        },    
      addtodoText() {
          if (this.contact_id!=null && this.due_date!=null) {
              if (this.todo_id.id == 0) {
                  return this.$i18n.t("buttons.add")
              } else {
                  return this.$i18n.t("buttons.saveandclose")
              }
          } else {
              return this.$i18n.t("buttons.invalid")
          }
      },
      todocheck() {
          this.componentKey++
      },
      translate(path) {
          return this.$i18n.t('meta.' + path.toLowerCase())
      },
      save_and_close() {
        console.log(this.contact_id)
        if(Array.isArray(this.contact_id)){
            this.todo.contact_id = this.contact_id[0].id
        } else {
            this.todo.contact_id = this.contact_id.id
        }
        if(this.notify_id) {
            if(Array.isArray(this.notify_id)){
                this.todo.notify_id = this.notify_id[0].id
            } else {
                this.todo.notify_id = this.notify_id.id
            }    
        } else {
            this.todo.notify_id = 0
        }        
        this.todo.create_date = this.dateToYYYYMMDD(new Date())
        this.todo.due_date = this.due_date
        this.todo.repeattask = this.repeattask
        this.todo.subject = this.subject
        this.todo.message = this.message
        this.todo.cc = this.cc
        this.todo.sendmail = parseInt(this.sendmail)
        console.log(this.todo)
        this.save_todo(this.todo.id)
        this.close_modal()
      },
      cancel_edit() {
        this.close_modal()
      },

      ...mapActions([
          'save_todo',
          'add_todo',
          'get_todos'
      ]),
      close_modal() {
          this.$emit('close')
      },
      my_add_todo() {
        console.log(this.contact_id.id, this.notify_id.id, this.due_date)
        this.todo.creator_id = this.current_user.id
        this.todo.contact_id = this.contact_id.id
        this.todo.notify_id = this.notify_id.id
        this.todo.create_date = this.dateToYYYYMMDD(new Date())
        this.todo.due_date = this.due_date
        this.todo.repeattask = this.repeattask
        this.todo.subject = this.subject
        this.todo.message = this.message
        this.todo.sendmail = parseInt(this.sendmail)
        this.todo.cc = this.cc
        this.add_todo(this.todo)
        this.close_modal()
      },
  },
  components: {flatPickr }
}
</script>