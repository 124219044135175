<template>
	<div class="popup">

        <div class="popup-header">
            <div v-if="code.id > 0">
                {{code.code}}
            </div>
            <div v-else>
                 {{ $t("labels.newbiccode") }}
            </div>
            <div v-if="code.id == 0" class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
            <div v-else class="popup-close" @click="save_and_close">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
            <b-container>
                <form class="form" v-on:submit='save_and_close'>
                    <!-- branche name -->
                    <div class="form-row">
                        <div class="col-3">
                            <font-awesome-icon v-if="checkcode" icon="check" style="color: green;"/>
                            <font-awesome-icon v-else icon="times" style="color: red;"/>                  
                            <label for="">{{ $t("labels.BIC") }}</label>
                        </div>
                        <div class="col">
                            <b-input ref="deffield" :state="checkcode" type="text" class="form-control" v-model="code.code"></b-input>
                            <b-form-invalid-feedback >
                                {{ $t("validation.bicnotvalid")}}
                            </b-form-invalid-feedback>  
                        </div>
                         
                    </div>
                </form>
            </b-container>
        </div>
        <div class="popup-footer">
            <div v-if="code.id == 0" class="btn btn-primary" @click="my_add_code();">{{ addcodeText() }}</div>
            <div v-else @click="save_and_close" class="btn btn-primary">{{ $t("buttons.saveandclose") }}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
    name: 'code-modal',
    data() {
        return {
            new_code:{id:0, code:""}
        }
    },
    props: {
        code_id: Number
    },
    mounted() {
        this.focusInput();
    },
    computed: {
        code() {
            return this.code_id > 0 ? this.biccode_by_id(this.code_id) : this.new_code
        },
        ...mapGetters([
            'biccode_by_id',
        ]),
        ...mapState([
            'biccodes',
        ]),
        checkcode(){
            if(this.code.id>0){
                return true
            }
            let found=false;
            if(this.code.code!=undefined){
                for(var i=0;i<this.biccodes.length;i++){
                    if(this.code.code.toLowerCase()==this.biccodes[i].code.toLowerCase()){
                        found = true;
                        break;
                    }
                }
            }
            return !found &&this.code.code!=undefined&& this.code.code.length>2
        }        
    },
    methods: {
        addcodeText(){
            if (this.checkcode){
                return this.$i18n.t("buttons.add") 
            } else { 
                return this.$i18n.t("buttons.invalid") 
            }
        },
        save_and_close(e) {
            e.preventDefault() // it prevent from page reload
            if(this.code.id==0){
                this.my_add_code()
            }else{
                this.save_biccode(this.code.id)
                this.$emit('close')
            }            
        },
        ...mapActions([
            'save_biccode',
            'add_biccode'
        ]),
        my_add_code(){
            if(this.checkcode){
                this.add_biccode(this.code);
                this.$emit('close')
            }
        },
    focusInput() {
      this.$refs.deffield.focus();
    }        

    }
}
</script>