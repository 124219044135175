<template>
	<div id="app" class="wrapper">
		<TitleBar v-if="current_user && current_user.role=='admin' && is_logged_in" />
		<AppNav  />
		<NotificationLayer />
		<TimerLayer v-if="settings.EnableWorkTimer && (current_user && current_user.role=='user')" />
		<main  :id="$route.name.toLowerCase().trim()" :style="AppMainView()">
			<router-view ></router-view>
		</main>
		<Footer  v-if="current_user && current_user.role!='admin'"/>
	</div>
</template>

<script>
// FIX pods for iOS Archive!!
// ios/Pods/Target Support Files/Pods-App/Pods-App-frameworks.sh
// replace source="$(readlink "${source}")"
// replace source="$(readlink -f "${source}")"

import { mapGetters, mapActions, mapState } from 'vuex'
import { FCM }  from "@capacitor-community/fcm";
import { PushNotifications }  from "@capacitor/push-notifications";
import { Browser } from '@capacitor/browser';

// import Login from './components/Login'
import TitleBar from './components/TitleBar.vue'
import AppNav from './components/AppNav.vue'
import Footer from './components/Footer.vue'
import NotificationLayer from './components/NotificationLayer.vue';
import { Capacitor } from '@capacitor/core';
import TimerLayer from './components/TimerLayer.vue';

export default {
	name: 'app',
	data() {
		return {
			resize_timeout: null,
		}
	},

	computed: {
		...mapGetters([
			'is_logged_in',
			'server_url',
			'is_portal_selected'

		]),
		...mapState([
			'loading',
			'current_user',
			'settings'
		])
	},
	mounted: function () {
		this.initialize()
	},
	beforeDestroy: function () {
	},
	methods: {
		...mapActions([
			'initialize',
			'login',
			'add_notification'
		]),
	},

  async created() {
		if (process.env.VUE_APP_BUILD_TARGET=="apprelease") {
			let usernamestorage = await this.getObject("username")
			let passwordstorage = await this.getObject("password")
			let portalstorage = await this.getObject("PORTAL")
			console.log(">>KOEKJEROOT USER>>>>",usernamestorage.name)
			console.log(">>KOEKJEROOT PASSWORD>>>>",passwordstorage.name)
			console.log(">>KOEKJE PORTAL>>>>",portalstorage.name)
			if(this.is_portal_selected) {
				if(usernamestorage.name && passwordstorage.name){
					console.log("LOGIN APP")
					this.login({"username":usernamestorage.name, "password":passwordstorage.name})
				}
			}
			if (Capacitor.isNativePlatform()) {
				window.open = (async (url) => {
					Browser.open({ url }); 
					console.log("window.opend!!!!!!!!!!!!!!")
				})
			}			

			await PushNotifications.requestPermissions();
			await PushNotifications.register();

			await PushNotifications.addListener('pushNotificationReceived', notification => {
				console.log('Push notification received: ', JSON.stringify( notification));
				this.add_notification(notification)
			});
			
			PushNotifications.addListener('pushNotificationActionPerformed', data => {
				console.log('pushNotificationActionPerformed notification received: ', JSON.stringify(data.notification));
				this.add_notification(data.notification)
			})
			// Get FCM token instead the APN one returned by Capacitor
			FCM.getToken()
				.then((r) => {
					console.log(">>>>TOKEN>>>",`${r.token}`)
					this.$store.commit('firebasetoken', `${r.token}`)
				})
				.catch((err) => {
					console.log(" get fcm error")
					console.log(err)
				});
		}
  },	
	components: { TitleBar, AppNav, Footer, NotificationLayer, TimerLayer }
}
</script>

