<template>
  <b-container v-if="createAny('users')" fluid>

    <!-- Title bar -->
    <div class="title-bar">
      <h1>{{ $t("titles.tags") }}</h1>
      <div @click="edit_tag({ id: 0 })" class="btn btn-primary">
        <font-awesome-icon icon="plus" /> {{ $t("buttons.addtag") }}
      </div>
    </div>

    <!-- Table -->
    <b-table :items=tags :fields=fields @row-clicked="onTagClicked">
      <template #cell(actions)="row">
        <div class="action-items">
          <a href="javascript:;" class="btn" @click="delete_a_tag(row.item.id)" :title="$t('labels.deletetag')">
            <font-awesome-icon icon="trash" /> {{$t('labels.deletetag')}}
          </a>
        </div>
      </template>
    </b-table>

  </b-container>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import tags_details_modal from './modals/TagModal'
import popup_modal from './modals/PopupModal.vue'

export default {
  name: 'tagsscreen',
  data() {
    return {
      selectedID: null
    }
  },
  computed: {
    fields() {
      return [
        { key: 'tag', label: this.$i18n.t('labels.name'), sortable: true, sortDirection: 'desc' },
        { key: 'actions', label: this.$i18n.t('labels.actions') },
      ]
    },

    ...mapState([
      'tags',
    ]),
    ...mapGetters([
      'tag_by_id',
      'confirm_modal',
      'createAny'

    ]),
  },
  methods: {
    ...mapActions([
      'delete_tag',
      'save_tag'
    ]),
    delete_a_tag(id) {
      this.selectedID = id
      let message = this.$i18n.t('labels.confirmdeletehead') + this.tag_by_id(id).tag + this.$i18n.t('labels.confirmdeletetail')
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
        message: message,
      }, {
        name: 'popup_modal',
        draggable: false,
        height: 'auto',
        width: '500px'
      }, {
        'before-close': this.modalClose
      })

      this.$emit('close')

    },
    modalClose() {
      if (this.confirm_modal.id == 1) {
        this.delete_tag(this.selectedID);
        for (let i = 0; i < this.tags.length; i++) {
          if (this.tags[i].id == this.selectedID) {
            this.tags.splice(i, 1);
          }
        }
      }
    },
    onTagClicked(row) {
      this.$modal.hide('tags_details_modal')
      this.$modal.show(tags_details_modal, {
        tag_id: row.id,
      }, {
        name: 'tags_details_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '800px'
      })

      this.$emit('close')
    },
    edit_tag(tag) {
      this.$modal.hide('tags_details_modal')
      this.$modal.show(tags_details_modal, {
        tag_id: tag.id,
      }, {
        name: 'tags_details_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '800px'
      })

      this.$emit('close')
    },
  },
}
</script>