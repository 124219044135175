<template>
  <div v-if="!singleproject">
      <div class="row planning_row planning_header"> 
        <div class="col-2"><h3>{{ $t("titles.projects") }}</h3>  </div>
        <div class="col-1"><b-button v-if="createAny('project')" @click="edit_project({ id: 0 })" class="btn btn-primary"><font-awesome-icon icon="plus" /> </b-button></div>
        <div class="col-2">
              <b-form-input ref="searchbox" id="filter-input" v-model="filter" type="search" :placeholder="$t('strings.typetosearch')">
              </b-form-input>
        </div>

      </div>
      <div class="project_section">
        <div class = "planning_row" v-for="(project, idx)  in search_filter_projects" :key="'projects'+idx">
          <div class="planning_title" draggable @dragstart="startDrag($event, project, 'project')" :title="project.title"
            @drop="onDropProject($event, project.id, n)"
            @dragover.prevent
            @dragenter.prevent
          > <input type="color" style="width: 30px;height:30px;" v-model="project.planningcolor" @change="saveProject(project)">
          {{ project.title.substring(0,25)}} 
          <div class="planning-item" style="float:right" @click="editProjectModal(project)"> <font-awesome-icon icon="cog" /> </div>
          <div class="planning-item" v-if="resourcesfilled(project)!=-1" style="float:right" @click="openProject(project)"> <font-awesome-icon icon="eye" /> </div>
          <font-awesome-icon v-if="resourcesfilled(project)==-1" icon="question" style="color: orange;float:right;margin:5px;" />
          <font-awesome-icon v-if="resourcesfilled(project)==1" icon="check" style="color: green;float:right;margin:5px;" />
          <font-awesome-icon v-if="resourcesfilled(project)==0" icon="times" style="color: red;float:right;margin:5px;" />
        </div>
          <div v-for="n in weeks " :key="n"  :class="divclass" 
            @drop="onDropProject($event, project.id, n)"
            @dragover.prevent
            @dragenter.prevent v-html="projectTitle(n-1,project)">
          </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row planning_row planning_header"> 
        <div class="col-3"><h3 @click="editProjectModal(selectedproject)">{{selectedproject.title }}</h3>  </div>
        <div class="col"><button  class="btn btn-primary" @click="gotoOverview()"><font-awesome-icon icon="eye-slash" /></button></div>
      </div>
      <div class="project_section">
        <!-- workfields -->
        <div class = "planning_row" v-for="(resource, idx)  in projectworkfieldssfromproject" :key="'projects'+idx">
          <div class="planning_title" draggable @dragstart="startDrag($event, resource, 'workfield')" @dragend="endDrag($event, resource, 'workfield')" 
            @drop="onDropProjectResource($event, resource.id, n)"
            @dragover.prevent
            @dragenter.prevent
            :title="legendatitle(resource)"
          >{{ workfield_by_id(resource.workfield_id).name }} 
          <div class="planning-item" style="float:right" @click="filteronWorkfield(resource)"> <font-awesome-icon icon="filter" /> </div>
          <font-awesome-icon v-if="resource.filled!=0" icon="check" style="color: green;float:right;margin:5px;" />
          <font-awesome-icon v-else icon="times" style="color: red;float:right;margin:5px;" />
        </div>
          <div v-for="n in weeks " :key="n"  :class="divclass" 
            @drop="onDropProjectResource($event, resource.id, n)"
            @dragover.prevent
            @dragenter.prevent>
            <div v-for="(task) in userwaxes(n,resource)" :key="task.id" draggable 
              @dragstart="dragUser($event,n, task, 'task')" 
              @click="onClickTaskUser(task)"
              :style="taskStyle(n,task)"
              :title="taskUserTooltip(n,task)">
                {{ username_by_id(task.user_id).substring(0,2) }}
              </div>          
          </div>
        </div>
        <!-- resource -->
        <div class = "planning_row" v-for="(resource)  in projectresourcesfromproject" :key="'projects'+resource.id">
          <div class="planning_title" draggable @dragstart="startDrag($event, resource, 'resource')" @dragend="endDrag($event, resource, 'resource')" 
            @drop="onDropProjectResource($event, resource.id, n)"
            @dragover.prevent
            @dragenter.prevent
            :title="legendatitle(resource)"

          >{{ resourcetype_by_id(resource.resource_id).label }} 
          <div class="planning-item" style="float:right" @click="filteronResource(resource)"> <font-awesome-icon icon="filter" /> </div>
          <font-awesome-icon v-if="resource.filled!=0" icon="check" style="color: green;float:right;margin:5px;" />
          <font-awesome-icon v-else icon="times" style="color: red;float:right;margin:5px;" />
        </div>
          <div v-for="n in weeks " :key="n"  :class="divclass" 
          @drop="onDropProjectResource($event, resource.id, n)"
          @dragover.prevent
          @dragenter.prevent>
          <div v-for="(task) in resourcewaxes(n,resource)" :key="task.id" draggable 
              @dragstart="dragResource($event,n, task, 'task')" 
              @click="onClickTaskResource(task)"
              :style="taskStyle(n,task)"
              :title="taskResourceTooltip(n,task)">
                {{ resource_by_id(task.resource_id).identification.substring(0,2) }}
              </div>          
        
        </div>
        </div>
    </div>
  </div>
</template>
<script>
import {  mapState, mapGetters, mapActions} from 'vuex'

export default {
  name: 'projects-planning',
  data() {
    return {
      filter:"",
      singleproject:false,
      selectedproject:null

    }
  },
  props: {
    numweeks: Number,
    startdate:String
  },  
  computed:{
    ...mapState ([
      'projects',
      'projectresources',
      'ffwax',

  ]),
  ...mapGetters ([
    'createAny',
    'workfield_by_id',
    'project_by_id'

  ]),
  filter_projects(){
    var viewstart = new Date(this.startdate);
    var viewend = new Date(this.startdate);
    viewend.setDate(viewend.getDate() + this.weeks)
    return this.projects.filter(p => {
      return this.dateRangeOverlaps(new Date(p.startdate),new Date(p.enddate),viewstart, viewend) && p.title.indexOf("CREDIT") ==-1
    })

  },
  search_filter_projects(){
      if(this.filter!=""){
        return this.filter_projects.filter(p=>
          (p.title && p.title.toLowerCase().includes(this.filter)) 
        )
      } else {
        return this.filter_projects
      }
    },
    weeks(){
      return this.numweeks*7;
    },
    divclass(){
      return "planning_day planning_day"+this.numweeks
    },
    projectworkfieldssfromproject(){
          return this.projectresources.filter(pr=>pr.project_id==this.selectedproject.id && pr.workfield_id!=null)
        },  
    projectresourcesfromproject(){
      return this.projectresources.filter(pr=>pr.project_id==this.selectedproject.id && pr.resource_id!=null)
    }, 

  },
  methods:{
    ...mapActions([
      'save_project',
    ]),
    userwaxes(n,res){
      // console.log("uwax", res)
      let day = new Date(this.startdate)
      day.setDate(day.getDate()+n-1)
      return this.ffwax.filter(f => f.user_id == res.filled && f.project_id==this.selectedproject.id).filter(ff => {
        var res = false
        let startdate = new Date(this.project_by_id(ff.project_id).startdate)
        startdate.setHours(0,0,0,0)
        startdate.setDate(startdate.getDate() + ff.start_offset);
        let enddate = new Date(startdate)
        enddate.setDate(enddate.getDate() + ff.end_offset);
          if(day.setHours(0,0,0,0)>=startdate &&  day.setHours(0,0,0,0)<=enddate){
            res =  true
          }
          return res
        })
    },
    resourcewaxes(n,res){
      let day = new Date(this.startdate)
      day.setDate(day.getDate()+n-1)
      return this.ffwax.filter(f => f.resource_id == res.filled && f.project_id==this.selectedproject.id).filter(ff => {
        var res = false
        let startdate = new Date(this.project_by_id(ff.project_id).startdate)
        startdate.setHours(0,0,0,0)
        startdate.setDate(startdate.getDate() + ff.start_offset);
        let enddate = new Date(startdate)
        enddate.setDate(enddate.getDate() + ff.end_offset);
          if(day.setHours(0,0,0,0)>=startdate &&  day.setHours(0,0,0,0)<=enddate){
            res =  true
          }
          return res
        })
    },
    taskStyle( n, task){
      let startpers = task.start_time?parseFloat(parseInt(task.start_time.split(":")[0])/24)*100:10
      let  endpers = task.end_time?parseFloat((parseInt(task.end_time.split(":")[0])-parseInt(task.start_time.split(":")[0]))/24)*100:80
      let color = this.project_by_id(task.project_id).planningcolor
        return "position:absolute; user-select: none;cursor:pointer; background-color:"+color+";left:"+startpers+"%; width:"+endpers+"%;height:100%;"

    },
    taskUserTooltip( n, task){
      let startdate = new Date(this.project_by_id(task.project_id).startdate)
        startdate.setHours(0,0,0,0)
        startdate.setDate(startdate.getDate() + task.start_offset);
        let enddate = new Date(startdate)
        enddate.setDate(enddate.getDate() + task.end_offset);
        var tooltip =  this.project_by_id(task.project_id).title + "\n"+this.dateToNL(startdate) + " - "+ this.dateToNL(enddate) +"\n"+task.start_time + " - "+ task.end_time +"\n"
        tooltip += this.username_by_id(task.user_id)+"\n"
        return tooltip

    },    
    taskResourceTooltip( n, task){
      let startdate = new Date(this.project_by_id(task.project_id).startdate)
        startdate.setHours(0,0,0,0)
        startdate.setDate(startdate.getDate() + task.start_offset);
        let enddate = new Date(startdate)
        enddate.setDate(enddate.getDate() + task.end_offset);
        var tooltip =  this.project_by_id(task.project_id).title + "\n"+this.dateToNL(startdate) + " - "+ this.dateToNL(enddate) +"\n"+task.start_time + " - "+ task.end_time +"\n"
        tooltip += this.resource_by_id(task.resource_id).name+ " - "+this.resource_by_id(task.resource_id).identification+"\n"
        return tooltip

    },     
    legendatitle(task){
      let startdate = new Date(this.project_by_id(task.project_id).startdate)
        startdate.setHours(0,0,0,0)
        startdate.setDate(startdate.getDate() + task.start_offset);
        let enddate = new Date(startdate)
        enddate.setDate(enddate.getDate() + task.end_offset);
      var tooltip =  this.project_by_id(task.project_id).title + "\n"+this.dateToNL(startdate) + " - "+ this.dateToNL(enddate) +"\n"+task.start_time + " - "+ task.end_time +"\n"
      return tooltip
    } ,
    projectStyle(n, project){
      var style="{}";
      var date = new Date(this.startdate)
      date.setDate(date.getDate()+n)
      if(date>new Date(project.startdate) && date<new Date(project.enddate)){
        style = "background-color:"+project.planningcolor+ " !important;"
      }
      return style
    },
    projectTitle(n ,project){
    var ret = ""
    var color ="";
    var tooltip=""
    let startpers = project.starttime?parseFloat(parseInt(project.starttime.split(":")[0])/24)*100:10
    let endpers = project.endtime?parseFloat((parseInt(project.endtime.split(":")[0])-parseInt(project.starttime.split(":")[0]))/24)*100:80

    let day = new Date(this.startdate)
      day.setDate(day.getDate()+n-1)
      if( day.setHours(0,0,0,0)>=new Date(project.startdate).setHours(0,0,0,0) &&  day.setHours(0,0,0,0)<=new Date(project.enddate).setHours(0,0,0,0)){
        ret =  project.title.substring(0,2)
        tooltip =  project.title + "\n"+project.startdate + " - "+ project.enddate +"\n"+project.starttime + " - "+ project.endtime +"\n"

        tooltip+= this.$t('labels.people')+"\n"
        this.peopleInProject(project).forEach(f => {
          tooltip += this.username_by_id(f)+"\n"
        });
        tooltip+= "\n"+this.$t('titles.resources')+"\n"
        this.resourceInProject(project).forEach(f => {
          tooltip += this.resource_by_id(f).name+" "+this.resource_by_id(f).identification+"\n"
        });        
        color = project.planningcolor
      }
      if(ret!=""){
          return "<div style='position:absolute; user-select: none;cursor:pointer; background-color:"+color+";left:"+startpers+"%; width:"+endpers+"%;height:100%' title='"+ tooltip+ "'>"+ ret+"</div>"
      }
      return ret

    },
    peopleInProject(project){
      return [...new Set(this.ffwax.filter(f =>f.project_id==project.id && f.resource_id==null).map(item => item.user_id))]
    }, 
    resourceInProject(project){
      return [...new Set(this.ffwax.filter(f =>f.project_id==project.id && f.user_id==null).map(item => item.resource_id))]
      
    } ,    
    resourceStyle(n,resource){
      var style="background-color:#7f7f7f !important;";
      var date = new Date(this.startdate)
      date.setDate(date.getDate()+n)
      if(date>new Date(resource.start_date) && date<new Date(resource.end_date)){
        style = "background-color:"+this.project_by_id(resource.project_id).planningcolor+ " !important;"
        console.log(resource)
      }      
      return style
    },
    resourcesfilled(project){
      let res = this.projectresources.filter(pr=>pr.project_id==project.id)
      let filled = res.filter(r=> r.filled==0)
      if(res.length==0){
        return -1
      } else {
        if (filled.length==0){
          return 1
        } else {
          return 0
        }
      }

    },
    edit_project(project) {
      if (project.id == 0) {
        const dateObj = new Date();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        const year = dateObj.getFullYear();
        this.$store.commit('new_project', { id: 0, ispublic: 0, company_id: 0, publishdate: year + "-" + month + "-" + day, workfields: [], zzprusers: [] })
        this.editProjectModal(project)
      }
    },    
    saveProject(project){
      this.save_project(project.id)

    },
    openProject(p){
      this.selectedproject = p
      this.singleproject = true
    },
    filteronWorkfield(resource){
      this.$emit('filterPeople', resource)
    },
    filteronResource(resource){
      this.$emit('filterResource', resource)
    },
    gotoOverview(){
      this.singleproject = false

    },
    userTitle(n ,resource){
    var ret = ""
    let day = new Date(this.startdate)
      day.setDate(day.getDate()+n-1)
      if(resource.filled!=0){
        if(day.setHours(0,0,0,0)==new Date(resource.start_date).setHours(0,0,0,0)){
          ret =  this.username_by_id(resource.filled)
        }
        if(n == 0 && day.setHours(0,0,0,0)>new Date(resource.start_date).setHours(0,0,0,0) &&  day.setHours(0,0,0,0)<=new Date(resource.end_date).setHours(0,0,0,0)){
          ret =  this.username_by_id(resource.filled)
        }
      }
      if(ret!=""){
        return "<div class='projectonday'>"+ ret+"</div>"
      }
      return ret

    },
    resourceTitle(n ,resource){
    var ret = ""
    let day = new Date(this.startdate)
      day.setDate(day.getDate()+n-1)
      if(resource.filled!=0){
        if(day.setHours(0,0,0,0)==new Date(resource.start_date).setHours(0,0,0,0)){
          ret =  this.resource_by_id(resource.filled).name + " - " + this.resource_by_id(resource.filled).identification
        }
        if(n == 0 && day.setHours(0,0,0,0)>new Date(resource.start_date).setHours(0,0,0,0) &&  day.setHours(0,0,0,0)<=new Date(resource.end_date).setHours(0,0,0,0)){
          ret =  this.resource_by_id(resource.filled).name + " - " + this.resource_by_id(resource.filled).identification
        }
      }
      if(ret!=""){
        return "<div class='projectonday'>"+ ret+"</div>"
      }
      return ret

    }
  }
}
</script>