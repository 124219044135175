<template>

  <b-container fluid :style="AppSecondaryColor()" class="apppage">
    <b-button-group vertical>
      <b-button :style="AppPrimaryButton()" active-class="active" @click="$router.go(-1)">
        <font-awesome-icon icon="chevron-left" /> {{ $t("titles.aboutff") }}
      </b-button>
    </b-button-group>
    <div v-html="content.content.rendered"/> 
  </b-container>
</template>
<script>
import {  mapState, mapGetters } from 'vuex'

export default {
  name: 'aboutapp',
  data() {
    return {
      content:""
    }
  },
  async mounted() {
    await fetch(`${'https://flyingfreelancers.nl/wp-json/wp/v2/pages/255'}`)
      .then((response) => response.json())
      .then((data) => this.content = data)


  },
  computed: {
    ...mapGetters([
      'is_logged_in',
    ]),    
    ...mapState([
      'settings',
    ]),

  },
  methods: {
  },
}
</script>
