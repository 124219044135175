<template>
	<div class="popup">

        <div class="popup-header">
            <div v-if="workfield.id > 0">
                {{workfield.name}}
            </div>
            <div v-else>
                 {{ $t("labels.newworkfield") }}
            </div>
            <div v-if="workfield.id == 0" class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
            <div v-else class="popup-close" @click="save_and_close">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
            <b-container>
                <form class="form" v-on:submit='save_and_close'>
                    <!-- branche name -->
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">{{ $t("labels.workfieldname") }}</label>
                        </div>
                        <div class="col">
                            <input ref="deffield" type="text" class="form-control" v-model="workfield.name">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">{{ $t("labels.active") }}</label>
                        </div>
                        <div class="col">
                            <select v-model="workfield.active" class="form-control">
                                <option value="1">{{ $t("option.active") }}</option>
                                <option value="0">{{ $t("option.inactive") }}</option>
                            </select> 
                        </div>
                    </div>  
                    <input type="hidden" class="form-control" v-model="workfield.branche_id">                 
                </form>
            </b-container>
        </div>
        <div class="popup-footer">
            <div v-if="workfield.id == 0" class="btn btn-primary" @click="add_workfield(workfield); $emit('close')">{{ $t("buttons.add") }}</div>
            <div v-else @click="save_and_close" class="btn btn-primary">{{ $t("buttons.saveandclose") }}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
    name: 'workfield-modal',
    data() {
        return {
          new_workfield: { id: 0, active:1}  
        }
    },
    props: {
        workfield_id: Object,
        branche_id:Number
    },

    computed: {
      
      workfield: {
        get() {
          let wfobject = this.workfield_id > 0 ? this.workfield_by_id(this.workfield_id) : this.new_workfield
          wfobject.branche_id = this.branche_id
          return wfobject
        }
      },
      ...mapGetters([
        'branche_by_id',
        'workfield_by_id',
      ]),
      ...mapState([
        'branches',
        'workfields',
      ])
    },
    mounted() {
        this.focusInput();
    },
    methods: {
        save_and_close(e) {
            e.preventDefault() // it prevent from page reload
            if(this.workfield.id==0){
                this.add_workfield(this.workfield)
            }else{
                this.save_workfield(this.workfield.id)
            }
            this.$emit('close')
        },
        ...mapActions([
            'save_workfield',
            'add_workfield'
        ]),
    focusInput() {
      this.$refs.deffield.focus();
    }
    }
}
</script>