<template>
  <b-container class="ffwax">
    <div class="period">
      <div class="row">
        <div class="col-2" style="padding-top:8px;"><label for=""> <h4>{{ $t("labels.startdate") }}</h4></label></div>
        <div class="col-2" style="position:relative">
          <div class="btn btn-primary" style="float:right" :title="$t('buttons.previousweek')" @click="prevweek()"><font-awesome-icon icon="chevron-left" /> </div>
          <div class="btn btn-primary" style="float:right" :title="$t('buttons.previousperiod')" @click="prevperiod()"><font-awesome-icon icon="chevron-double-left" /> </div>
          </div>
          <div class="col-">
          <b-form-input type="date" v-model="startdate" />
        </div>
        <div class="col-2">

          <div class="btn btn-primary"  @click="nextweek()"  :title="$t('buttons.nextweek')" ><font-awesome-icon icon="chevron-right" /> </div>
          <div class="btn btn-primary" @click="nextperiod()" :title="$t('buttons.nextperiod')" ><font-awesome-icon icon="chevron-double-right" /> </div>

        </div>
        <div class="col-2" style="padding-top:8px;"><label for=""> <h4>{{ $t("labels.numberofweeks") }}</h4></label></div>

          <div class=col-2 mt >
            <select v-model="numweeks" class="form-control">
              <option v-for="n in 6 " :key="n" :value="n">{{ n }}</option>
            </select>
          </div>
      </div>
      <div class="planning_row"  style="position:relative">
        <div class="planning_title">{{$t('labels.month')}}</div>
        <div style="width:80% ;position: relative;">
          <div v-for="n in months()" :key="n" class="planning_day" :style="monthstyle(n)">{{monthNames[n.month]}}</div>
          </div>
      </div>
      <div class="planning_row">
        <div class="planning_title">{{ $t('labels.day') }}</div>
          <div v-for="n in weeks" :key="n" :value="n" :class="divclass">{{dateforstart(n)}}</div>
      </div>
      <div class="planning_row">
        <div class="planning_title" style="text-shadow:0px">{{ $t('titles.users') }}</div>
          <div v-for="n in weeks" :key="n" :value="n" :class="userclass" v-html="planned(n)" ></div>
      </div>      
    </div>
    <Projects :numweeks="numweeks" :startdate="startdate" @filterPeople="filterPeople"  @workfieldDrag="workfieldDrag"  @resourceDrag="resourceDrag"/>
    <People v-if="showpeople" :numweeks="numweeks" :startdate="startdate" ref="waxpeople"/>
    <Resources v-if="showresource"  :numweeks="numweeks" :startdate="startdate" ref="waxresource" />
  </b-container>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import People from './planning/People.vue'
import Projects from './planning/Projects.vue'
import Resources from './planning/Resources.vue'
export default {
  name: 'ffinvoice-screen',
  data() {
    return {
      numweeks:2,
      startdate:null,
      showpeople:true,
      showresource:true   ,
      monthNames : ["Januari", "Februari", "Maart", "April", "Mei", "Juni",   "Juli", "Augustus", "September", "Oktober", "November", "December"]   

    }
  },
  mounted(){
    this.startdate =  this.dateToYYYYMMDD(new Date())
  },
  computed:{
    weeks(){
      return this.numweeks*7;
    },
    divclass(){
      return "planning_day planning_day"+this.numweeks
    },
    userclass(){
      return "planning_day"+this.numweeks
    },    
    ...mapGetters ([
      'zzpers',
  ]),    
    ...mapState ([
      'ffwax',
      'projects'
    ]),   
  },
  methods:{
    prevweek(){
      let d = new Date(this.startdate);
      d.setDate(d.getDate()-7);
      this.startdate = this.dateToYYYYMMDD(d)
    },
    prevperiod(){
      let d = new Date(this.startdate);
      d.setDate(d.getDate()-this.weeks);
      this.startdate = this.dateToYYYYMMDD(d)
    },    
    nextweek(){
      let d = new Date(this.startdate);
      d.setDate(d.getDate()+7);
      this.startdate = this.dateToYYYYMMDD(d)
    },
    nextperiod(){
      let d = new Date(this.startdate);
      d.setDate(d.getDate()+this.weeks);
      this.startdate = this.dateToYYYYMMDD(d)
    },        
    workfieldDrag(id){
      this.$refs["waxpeople"].filterPeople({workfield_id:id})
      this.showresource = id==null

    },
    resourceDrag(id){
      this.$refs["waxresource"].filterResource(id)
      this.showpeople = id==null
    },
    filterPeople(filter){
      console.log("TIMEWAX", filter)
      this.$refs["waxpeople"].filterPeople(filter)
    },
    dateforstart(n){
      var t = new Date(this.startdate);
      t.setDate(t.getDate() + (n-1))
      return t.getDate()
    },     
    months(){
      var p = new Array()
      var d = new Date(this.startdate);
      let enddate = new Date(this.startdate)
      enddate.setDate(enddate.getDate()+ this.weeks)
      enddate.setHours(0,0,0,0)
      var lastDay = new Date(d.getFullYear(), d.getMonth()+1, 0);
      console.log(lastDay)
      p.push({month:lastDay.getMonth(),left:0,width:lastDay.getDate() - d.getDate()+1})
      let left = lastDay.getDate() - d.getDate()+1
      let checkend = new Date(lastDay)
      checkend.setDate(checkend.getDate()+1)
      checkend.setHours(0,0,0,0)
      while (checkend<enddate){
        lastDay = new Date(checkend.getFullYear(), checkend.getMonth()+1, 0);
        checkend = new Date(lastDay)
        checkend.setDate(checkend.getDate()+1)
        checkend.setHours(0,0,0,0)
        if(checkend>enddate){
          p.push({month:lastDay.getMonth(),left:left, width:enddate.getDate() - checkend.getDate()})

        } else {
          p.push({month:lastDay.getMonth(),left:left, width:lastDay.getDate() - checkend.getDate()+1})

        }
        left += lastDay.getDate() - checkend.getDate()+1
      }

      return p

    },
    monthstyle(m){
      let percent = 0;
      switch(this.numweeks){
        case 1:   percent = 11.428; break;
        case 2:   percent = 5.714; break;
        case 3:   percent = 3.809; break;
        case 4:   percent = 2.857; break;
        case 5:   percent = 2.285; break;
        case 6:   percent = 1.905; break;
    }
    percent*=(100/80)
    return "border:1px solid #DDD;height:40px;text-align:center;position:absolute; user-select: none;cursor:pointer; left:"+m.left*percent+"%; width:"+m.width*percent+"%;"


    },
    Ndate(n){
      var t = new Date(this.startdate);
      t.setDate(t.getDate() + (n-1))
      return t
    },
    planned(n){

      let ids = [...new Set(
        this.projects.filter(p => {
          return this.dateRangeOverlaps(new Date(p.startdate),new Date(p.enddate),this.Ndate(n), this.Ndate(n)) && p.title.indexOf("CREDIT") ==-1
        }).map(item => item.id))]
      let usersplanned = this.ffwax.filter(f => f.user_id!=null && ids.indexOf(f.project_id)!=-1).length
      return "<span style='color:green'>"+usersplanned +"</span>/<span style='color: red'>"+parseInt(this.zzpers.length-usersplanned)+ "</span>"
    }
  },
  
  components:{People,Resources,Projects}
}
</script>