<template>
    <form class="form">
        <!-- companyname -->
        <h3>{{$t('labels.companysettings')}}</h3>

        <div class="form-row">
            <div class="col-3">
                <label for=""> {{ $t("labels.zzpcompanyname") }}</label>
            </div>
            <div class="col">
                <b-form-input type="text" class="form-control" v-model="user.companyname" :state="companynamecheck" />
                <b-form-invalid-feedback>
                    {{ $t("validation.zzprcompanyname")}}
                </b-form-invalid-feedback>
            </div>
        </div>
        <div class="form-row">
            <div class="col-3">
                <label for=""> {{ $t("labels.kvk") }}</label>
            </div>
            <div class="col">
                <b-form-group>
                    <b-form-radio v-model="user.kvk" value="no">{{ $t("labels.no") }}</b-form-radio>
                    <b-form-radio v-model="user.kvk" value="yes">{{ $t("labels.yes") }}</b-form-radio>
                </b-form-group>
            </div>
            <div v-if="user.kvk=='yes'" class="col-2">
                <label for=""> {{ $t("labels.kvknumber") }}</label>

            </div>
            <div v-if="user.kvk=='yes'" class="col">
                <b-form-input type="text" class="form-control" v-model="user.kvkcode" />
            </div>
        </div>
        <div v-if="user.kvk=='yes'" class="form-row">
            <div class="col-3">
                <label for=""> {{ $t("labels.expirationdate") }}</label>
            </div>
            <div class="col-3">
                <b-form-input type="date" class="form-control" v-model="kvk_enddate" />
            </div>
            <div v-if="kvkfile" class="col-1">
                <font-awesome-icon icon="exclamation" color="red" v-if="checkfilecharacters(kvkfile)==false"></font-awesome-icon>
                <font-awesome-icon icon="check" color="green" v-else></font-awesome-icon>
            </div>
            <div class="col">
                <b-form-file v-if="checkfile('KvK')==false" :placeholder="$t('labels.file')"
                    :drop-placeholder="$t('labels.filedrop')" :browse-text="$t('labels.browse')" ref="file-input"
                    class="form-control" accept="*/*" v-model="kvkfile" :state="Boolean(kvkfile)" />
                <div v-else>
                    <a href="javascript:;" class="action-item" @click="delete_file('KvK')"
                        :title="$t('labels.deletefile')">
                        <font-awesome-icon icon="trash" />
                    </a>
                    <a :href="filelink('KvK')" v-bind:target="'_blank'">{{$t("labels.view")}} KVK</a>
                </div>
            </div>
        </div>
        <hr>
        <h3>{{$t('meta.administratie')}}</h3>
        <div class="form-row">
            <div class="col-3">
                <label for=""> {{ $t("labels.iban") }}</label>
            </div>
            <div class="col">
                <b-form-input type="text" class="form-control" v-model="user.iban" />
            </div>
        </div>
        <div class="form-row">
            <div class="col-3">
                <label for=""> {{ $t("labels.BIC") }}</label>
            </div>
            <div class="col">
                <v-select
                    label="code"
                    :options="barebiccodes"
                    v-model="user.bic" />
            </div>
        </div>
        <div class="form-row">
            <div class="col-3">
                <label for=""> {{ $t("labels.vatknown") }}</label>
            </div>
            <div class="col">
                <b-form-group>
                    <b-form-radio v-model="user.vat" value="no">{{ $t("labels.no") }}</b-form-radio>
                    <b-form-radio v-model="user.vat" value="submitted">{{ $t("labels.vatnobut") }}</b-form-radio>
                    <b-form-radio v-model="user.vat" value="yes">{{ $t("labels.yes") }}</b-form-radio>
                </b-form-group>
            </div>
            <div v-if="user.vat=='yes'" class="col-2">
                <label for=""> {{ $t("labels.vat") }}</label>
            </div>
            <div v-if="user.vat=='yes'" class="col">
                <b-form-input type="text" class="form-control" v-model="user.vatcode" @blur="checkvat()" :state="vatchecked"/>
                <b-form-invalid-feedback>
                    {{ this.vatcheckedname }}
                </b-form-invalid-feedback>
                <b-form-valid-feedback>
                    {{ this.vatcheckedname }}
                </b-form-valid-feedback>                
                <b-form-checkbox v-model="user.skipvat" name="checkbox-1" value="1" unchecked-value="0">BTW check negeren</b-form-checkbox>
            </div>
        </div>
        <div class="form-row">
            <div class="col-3">
                <label for=""> {{ $t("labels.purchaseinvoice") }}</label>
            </div>
            <div class="col">
                <b-form-group>
                    <b-form-radio v-model="user.inkoopfactuur" value="no">{{ $t("labels.no") }}</b-form-radio>
                    <b-form-radio v-model="user.inkoopfactuur" value="yes">{{ $t("labels.yes") }}</b-form-radio>
                </b-form-group>
            </div>
            <div class="col-2">
                {{ $t("labels.vatshifted") }}
            </div>
            <div class="col">
                <b-form-group>
                <b-form-radio v-model="user.btw_verlegd" value="yes">{{ $t("labels.vatverlegd") }}
                </b-form-radio>
                <b-form-radio  v-model="user.btw_verlegd" value="low">{{ $t("labels.vat9") }}
                </b-form-radio>          
                <b-form-radio  v-model="user.btw_verlegd" value="no">{{ $t("labels.vat21") }}
                </b-form-radio>
                <b-form-radio  v-model="user.btw_verlegd" value="kor">{{ $t("labels.vatkors") }}
                </b-form-radio>            
                </b-form-group>
            </div>
        </div>
        <div class="form-row">
            <div class="col-3">
                <label for=""> {{ $t("labels.hourrate") }}</label>
            </div>
            <div class="col">
                <b-form-input type="number" class="form-control" v-model="user.tarief" />
            </div>
            <div class="col-2">
                <label for=""> {{ $t("labels.payment_term") }}</label>

            </div>
            <div class="col">
                <b-form-input type="number" class="form-control" v-model="user.betalingstermijn" />
            </div>
        </div>
        
        <hr>
        <h3>{{$t('labels.identification')}}</h3>
        <div v-if = "settings.ShowBSN" class="form-row">
            <div class="col-3">
                <label for=""> {{ $t("labels.bsn") }}</label>
            </div>
            <div class="col">
                <b-form-input type="text" class="form-control" v-model="user.bsn" :state="bsncheck" />
                <b-form-invalid-feedback>
                    {{ $t("validation.bsn")}}
                </b-form-invalid-feedback>
            </div>
        </div>
        <div class="form-row">
            <div class="col-3">
                <label for=""> {{ $t("labels.identification") }}</label>
            </div>
            <div class="col">
                <b-form-group>
                    <b-form-radio v-model="user.identification" value="card">{{ $t("labels.identificationcard") }}
                    </b-form-radio>
                    <b-form-radio v-model="user.identification" value="passport">{{ $t("labels.identificationpassport")
                    }}</b-form-radio>
                </b-form-group>
            </div>
            <div class="col-2">
                <label for=""> {{ $t("labels.identificationnumber") }}</label>
            </div>
            <div class="col">
                <b-form-input type="text" class="form-control" v-model="user.identificationcode" />
                <b-form-invalid-feedback>
                    {{ $t("validation.username")}}
                </b-form-invalid-feedback>
            </div>
        </div>
        <div class="form-row">
            <div class="col-3">
                <label for=""> {{ $t("labels.expirationdate") }}</label>
            </div>
            <div class="col-3">
                <b-form-input type="date" class="form-control" v-model="identification_endate" />
            </div>
            <div class="col" v-if="false">
                <b-form-file v-if="checkfile('ID')==false" ref="file-input" :placeholder="$t('labels.file')"
                    :drop-placeholder="$t('labels.filedrop')" :browse-text="$t('labels.browse')" class="form-control"
                    accept="*/*" v-model="idfile" :state="Boolean(idfile)" />
                <div v-else>
                    <a href="javascript:;" class="action-item" @click="delete_file('ID')"
                        :title="$t('labels.deletefile')">
                        <font-awesome-icon icon="trash" />
                    </a>
                    <a :href="filelink('ID')" v-bind:target="'_blank'">{{$t("labels.view")}} ID</a>
                </div>
            </div>
        </div>
        <hr>
        <div class="form-row">
            <div class="col-3">
                <label for=""> {{ $t("labels.vca") }}</label>
            </div>
            <div class="col">
                <b-form-group>
                    <b-form-radio v-model="user.vca" value="no">{{ $t("labels.no") }}</b-form-radio>
                    <b-form-radio v-model="user.vca" value="yes">{{ $t("labels.yes") }}</b-form-radio>
                </b-form-group>
            </div>
            <div class="col-3" v-if="user.lastname!=undefined && user.birthday!=undefined"><div @click="checkVCA()" class="btn btn-primary">Check VCA</div></div>
        </div>
        <div v-if="user.vca=='yes'" class="form-row">
            <div class="col-3">
                <label for=""> {{ $t("labels.expirationdate") }}</label>
            </div>
            <div class="col-3">
                <b-form-input type="date" class="form-control" v-model="vca_endate" />
            </div>
            <div v-if="vcafile" class="col-1">
                <font-awesome-icon icon="exclamation" color="red" v-if="checkfilecharacters(vcafile)==false"></font-awesome-icon>
                <font-awesome-icon icon="check" color="green" v-else></font-awesome-icon>
            </div>

            <div class="col">
                <b-form-file v-if="checkfile('VCA')==false" :placeholder="$t('labels.file')"
                    :drop-placeholder="$t('labels.filedrop')" :browse-text="$t('labels.browse')" ref="file-input"
                    class="form-control" accept="*/*" v-model="vcafile" :state="Boolean(vcafile)" />
                <div v-else>
                    <a href="javascript:;" class="action-item" @click="delete_file('VCA')"
                        :title="$t('labels.deletefile')">
                        <font-awesome-icon icon="trash" />
                    </a>
                    <a :href="filelink('VCA')" v-bind:target="'_blank'">{{$t("labels.view")}} VCA</a>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="col-3">
                <label for=""> {{ $t("labels.gpi") }}</label>
            </div>
            <div class="col">
                <b-form-group>
                    <b-form-radio v-model="user.gpi" value="no">{{ $t("labels.no") }}</b-form-radio>
                    <b-form-radio v-model="user.gpi" value="yes">{{ $t("labels.yes") }}</b-form-radio>
                </b-form-group>
            </div>
        </div>
        <div v-if="user.gpi=='yes'" class="form-row">
            <div class="col-3">
                <label for=""> {{ $t("labels.expirationdate") }}</label>
            </div>
            <div class="col-3">
                <b-form-input type="date" class="form-control" v-model="gpi_endate" />
            </div>
            <div class="col">
                <b-form-file v-if="checkfile('GPI')==false" :placeholder="$t('labels.file')"
                    :drop-placeholder="$t('labels.filedrop')" :browse-text="$t('labels.browse')" ref="file-input"
                    class="form-control" accept="*/*" v-model="gpifile" :state="Boolean(gpifile)" />
                <div v-else>
                    <a href="javascript:;" class="action-item" @click="delete_file('GPI')"
                        :title="$t('labels.deletefile')">
                        <font-awesome-icon icon="trash" />
                    </a>
                    <a :href="filelink('GPI')" v-bind:target="'_blank'">{{$t("labels.view")}} GPI</a>
                </div>
            </div>
        </div>

    </form>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import axios from 'axios'

export default {
    name: 'usercompanyscreen',
    data() {
        return {
            idfile: null,
            kvkfile: null,
            vcafile: null,
            gpifile: null,
            gpi_endate: "",
            kvk_enddate: "",
            identification_endate: "",
            vca_endate: "",
            vatchecked:false,
            vatcheckedname:""
        }
    },
    props: {
        user_id: Number
    },
    computed: {
        user() {
            return this.user_id > 0 ? this.user_by_id(this.user_id) : this.user_new
        },
        ...mapGetters([
            'user_metas',
            'user_by_id',
            'activebranches',
            'user_new',
            'server_url',
            'barebiccodes'

        ]),
        ...mapState([
            'users',
            'settings',
            'current_user',
            'branches',
            'biccodes'
        ]),

        companynamecheck() {
            return this.user.companyname != undefined && this.user.companyname.length >= 3;

        },
        bsncheck() {
            return this.user.bsn != undefined && this.user.bsn.length > 2;

        }
    },
    methods: {
        translate(path) {
            return this.$i18n.t('meta.' + path.toLowerCase())
        },
        checkfile(name) {
            let res = false;
            for (let i = 0; i < this.user.documents.length; i++) {
                if (this.user.documents[i].name == name) {
                    res = true;
                    break;
                }
            }
            return res;
        },
        delete_file(name) {
            let res = false;
            console.log(name)
            for (let i = 0; i < this.user.documents.length; i++) {
                console.log(this.user.documents[i])
                if (this.user.documents[i].link.search(this.user_id +"-"+ name) != -1) {
                    let id = this.user.documents[i].id
                    this.delete_user_document(id)
                    this.user.documents.splice(i, 1)
                    console.log("delete")
                    break;
                }
            }
            return res;
        },
        filelink(name) {
            let res = "#";
            for (let i = 0; i < this.user.documents.length; i++) {
                if (this.user.documents[i].link.search(this.user_id + "-" + name + "-") != -1) {
                    res = this.server_url + '/media_' + this.user.documents[i].link;
                    break;
                }
            }
            return res;
        },
        savedata() {
            if (this.vcafile != null) {
                const formData = new FormData();
                formData.append('name', "VCA");
                formData.append('expires', this.user.vca_endate)
                formData.append('file', this.vcafile)
                formData.append('user_id', this.user_id)
                let adoc = {}
                for (var pair of formData.entries()) {
                    adoc[pair[0]] = pair[1]
                }
                adoc.link = "documents/" + this.user_id + "-VCA-" + this.vcafile.name
                this.user.documents.push(adoc)
                try {
                    axios.post('/api/user/document/', formData)
                } catch (err) {
                    console.log(err)
                }
            }
            if (this.kvkfile != null) {
                const formData = new FormData();
                formData.append('name', "KvK");
                formData.append('expires', this.user.kvk_enddate)
                formData.append('file', this.kvkfile)
                formData.append('user_id', this.user_id)
                let adoc = {}
                for (var pairkvk of formData.entries()) {
                    adoc[pairkvk[0]] = pairkvk[1]
                }
                adoc.link = "documents/" + this.user_id + "-KvK-" + this.kvkfile.name
                this.user.documents.push(adoc)
                try {
                    axios.post('/api/user/document/', formData)
                } catch (err) {
                    console.log(err)
                }
            }
            if (this.idfile != null) {
                const formData = new FormData();
                formData.append('name', "ID");
                formData.append('expires', this.user.identification_endate)
                formData.append('file', this.idfile)
                formData.append('user_id', this.user_id)
                let adoc = {}
                for (var pairid of formData.entries()) {
                    adoc[pairid[0]] = pairid[1]
                }
                adoc.link = "documents/" + this.user_id + "-ID-" + this.idfile.name
                this.user.documents.push(adoc)
                try {
                    axios.post('/api/user/document/', formData)
                } catch (err) {
                    console.log(err)
                }
            }
            if (this.gpifile != null) {
                const formData = new FormData();
                formData.append('name', "GPI");
                formData.append('expires', this.user.gpi_endate)
                formData.append('file', this.gpifile)
                formData.append('user_id', this.user_id)
                let adoc = {}
                for (var pairgpi of formData.entries()) {
                    adoc[pairgpi[0]] = pairgpi[1]
                }
                adoc.link = "documents/" + this.user_id + "-GPI-" + this.gpifile.name
                this.user.documents.push(adoc)
                try {
                    axios.post('/api/user/document/', formData)
                } catch (err) {
                    console.log(err)
                }
            }
        },
        ...mapActions([
            'save_user',
            'add_user',
            'delete_user_document'
        ]),
        checkvat(){
            fetch(
                    "https://controleerbtwnummer.eu/api/validate/" + this.user.vatcode + ".json",
                    {
                        method: "GET",
                    }
                ).then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    //   throw new Error('Something went wrong');
                })
                .then((data) => {
                    this.vatchecked = data.valid == true
                    if(this.vatchecked){
                        this.vatcheckedname = data.name
                    } else{
                        this.vatcheckedname = this.$i18n.t('labels.vatcodeunknown')
                    }
                })
                .catch((error) => {
                    console.log(error)
                });            
        },
        checkVCA(){
            window.open("https://cdr.ssvv.nl/nl-NL/searchresults/?name="+this.user.lastname+"&birthValue="+this.user.birthday, "_blank")
        },
        validated(){
            let validate=true
            if(validate && this.kvkfile){
                validate = this.checkfilecharacters(this.kvkfile)
            }
            if(validate && this.vcafile){
                validate = this.checkfilecharacters(this.vcafile)
            }
            return validate
        }


    },
    mounted() {
        this.kvk_enddate = this.user.kvk_enddate
        this.identification_endate = this.user.identification_endate
        this.vca_endate = this.user.vca_endate
        this.gpi_endate = this.user.gpi_endate
        this.checkvat()
    },
    watch: {
        kvk_enddate: function () {
            this.user.kvk_enddate = this.kvk_enddate
            for (let i = 0; i < this.user.documents.length; i++) {
                if (this.user.documents[i].link.search(this.user_id + "-KvK-") != -1) {
                    this.user.documents[i].expires = this.user.kvk_enddate
                    var datatosend = { document_id: this.user.documents[i].id, name: this.user.documents[i].name, expire: this.user.documents[i].expires }
                    this.$store.dispatch('update_user_document', datatosend);
                }
            }
        },
        identification_endate: function () {
            this.user.identification_endate = this.identification_endate
            for (let i = 0; i < this.user.documents.length; i++) {
                if (this.user.documents[i].link.search(this.user_id + "-ID-") != -1) {
                    this.user.documents[i].expires = this.user.identification_endate
                    var datatosend = { document_id: this.user.documents[i].id, name: this.user.documents[i].name, expire: this.user.documents[i].expires }
                    this.$store.dispatch('update_user_document', datatosend);
                }
            }
        },
        vca_endate: function () {
            this.user.vca_endate = this.vca_endate
            for (let i = 0; i < this.user.documents.length; i++) {
                if (this.user.documents[i].link.search(this.user_id + "-VCA-") != -1) {
                    this.user.documents[i].expires = this.user.vca_endate
                    var datatosend = { document_id: this.user.documents[i].id, name: this.user.documents[i].name, expire: this.user.documents[i].expires }
                    this.$store.dispatch('update_user_document', datatosend);
                }
            }
        },
        gpi_endate: function () {
            this.user.gpi_endate = this.gpi_endate
            for (let i = 0; i < this.user.documents.length; i++) {
                if (this.user.documents[i].link.search(this.user_id + "-GPI-") != -1) {
                    this.user.documents[i].expires = this.user.gpi_endate
                    var datatosend = { document_id: this.user.documents[i].id, name: this.user.documents[i].name, expire: this.user.documents[i].expires }
                    this.$store.dispatch('update_user_document', datatosend);
                }
            }
        },

    },
}
</script>
