<template>
  <div class="popup">
      <div class="popup-header">
          {{ $t('titles.contact') }} {{ this.username_by_id(this.user_id) }}
          <div class="popup-close" @click="$emit('close')">
              <font-awesome-icon icon="times" />
          </div>
      </div>
      <div class="popup-body">
          <b-container fluid>
              <div class="form-row">
                  <div class="col-3">
                      {{ $t("labels.contactmaindocuments") }}
                  </div>

                  <div class="col-8">
                      <v-select multiple :options="this.documents" label="name" v-model="selected" />
                  </div>
              </div>
              <div class="form-row">
                  <div class="col-3">
                      {{ $t("titles.subject") }}
                  </div>

                  <div class="col-8">
                      <b-input  :state="subject!=null&&subject!=''" v-model="subject" required :placeholder='$t("titles.subject")' />
                  </div>
              </div>
              <div id="app" :style="ckeditorState(editorData)" >
                  <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
              </div>
          </b-container>
      </div>
      <div class="popup-footer">
          <div @click="save_and_close" class="btn btn-primary"><font-awesome-icon icon="envelope" /> {{ $t("buttons.sendmessage") }}</div>
      </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
// const querystring = require("querystring");

export default {
  name: 'msal_contact_user',
  data() {
      return {
          editor: ClassicEditor,
          editorData: '',
          editorConfig: {
      toolbar: {
        items:[ 'heading', '|', 'undo', 'redo', '-', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ,'insertTable'],
        shouldNotGroupWhenFull: true
      },
      heading: {
        options: [
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
          { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
        ]
      },
      table: {
          contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
      },
      placeholder:this.$t('labels.message')
    },
          subject: "",
          selected: [],
          sender: 0
      }
  },
  props: {
      user_id: Number,
  },

  mounted() {
  },
  computed: {


      user() {
          return this.user_by_id(this.user_id)
      },

      ...mapGetters([
          'planning_by_id',
          'project_by_id',
          'contract_by_id',
          'contracts_metas',
          'contract_new',
          'username_by_id',
          'user_by_id',
          'company_by_id',
          'activeemailsenders'

      ]),
      ...mapState([
          'users',
          'settings',
          'contracts',
          'current_user',
          'documents',
      ])
  },
  methods: {
      save_and_close() {
          let email = this.user.email
          let name = this.user.fullname
          let message = {}
          message.email = email
          message.name = name
          message.message = this.editorData
          message.subject = this.subject
          message.maindocuments = []
          this.selected.forEach(element => {
              message.maindocuments.push({ file: element.file, name: element.name })
          });
          message.sender = this.sender ? this.sender.id : -1
          try {
              axios.post('/api/msal/MSAL_contact_user', message).then((response) => {
                  console.log('succes', response)
              })
          } catch (err) {
              console.log(err)
          }
          this.$emit('close')
      },
      ...mapActions([

      ]),

  },
}
</script>