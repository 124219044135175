<template>
  <b-container v-if="haspassed()==false">
    <div v-if="!future()">

    <a @click="show_company()" class="link-item"> {{this.company.company_name}} </a><br />
    {{ $t("labels.hourrate") }} € {{this.user_planning.tarief}}, {{ $t("labels.payment_term") }}
    {{this.user_planning.payment_zzprnl_zzpr }} {{ $t("labels.days") }}<br />
    {{ $t("labels.contactperson") }} {{this.username_by_id(this.user_planning.pointcontact_id)}}<br />
    {{ $t("meta.mobiel") }} {{this.user_by_id(this.user_planning.pointcontact_id).mobile}}<br />
    {{ $t("labels.hourrate") }} € {{this.user_planning.verkooptarief}}, {{ $t("labels.payment_term") }}
    {{this.user_planning.payment_client_zzpr }} {{ $t("labels.days") }}<br />
    <div v-html="weektotalhtml(contract_id, week)"></div>
    <strong>{{ $t('titles.planning') }}</strong><br />
      {{ $t("labels.period") }} {{this.formatDate(this.user_planning.startdate)}} {{ $t("labels.to") }}
      {{this.formatDate(this.user_planning.enddate)}}
      <b-row>
      <b-col cols="6">
        <a href="javascript:;" class="float-right colorset"
          v-bind:style="{ color: classcolor1, 'background-color':'#ffffff', border: '2px solid white'}"
          @click="setcolor(0)">
          <font-awesome-icon icon="square" />
        </a>
        <a href="javascript:;" class="float-right colorset"
          v-bind:style="{ color: classcolor2, 'background-color':'#ffffff', border: '2px solid white'}"
          @click="setcolor(1)">
          <font-awesome-icon icon="square" />
        </a>
        <a href="javascript:;" class="float-right colorset"
          v-bind:style="{ color: classcolor3, 'background-color':'#ffffff', border: '2px solid white'}"
          @click="setcolor(2)">
          <font-awesome-icon icon="square" />
        </a>
      </b-col>
    </b-row>
    </div>
  </b-container>
  <b-container v-else></b-container>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'user_planning',
  data() {
    return {
      classcolor1: "#ff0000",
      classcolor2: "#ff0000",
      classcolor3: "#ff0000",
    }
  },
  props: {
    contract_id: Number,
    week: String
  },
  mounted() {
    this.classcolor1 = this.settings.status_colors[0].color
    this.classcolor2 = this.settings.status_colors[1].color
    this.classcolor3 = this.settings.status_colors[2].color
  },
  computed: {
    contract() {
      return this.contract_by_id(this.contract_id);
    },
    user() {
      return this.user_by_id(this.contract.user_id)
    },
    user_planning() {
      return this.find_planning(this.contract.project_id, this.contract.user_id)
    },
    company() {
      return this.company_by_id(this.project_by_id(this.contract.project_id).company_id)
    },
    ...mapState([
      'projects',
      'users',
      'companies',
      'contracts',
      'branches',
      'planning',
      'settings',
      'current_user',
      'hours'
    ]),
    ...mapGetters([
      'project_by_id',
      'company_by_id',
      'contract_by_id',
      'zzpers',
      'user_by_id',
      'username_by_id',
      'hours_by_id',
      'find_planning'
    ]),
    checkcallat() {
      if (this.user_by_id(this.user_planning.pointcontact_id).callat == null) {
        return 0
      } else {
        let calldate = new Date(this.user_by_id(this.user_planning.pointcontact_id).callat)
        var today = new Date();
        var diff = (calldate.getTime() - today.getTime()) / (1000 * 3600 * 24)
        if (calldate < today || diff < 14) {
          return 2
        }
      }
      return 1
    },
  },
  methods: {
    setcolor(id) {
      this.contract.color = this.settings.status_colors[id].color
      this.save_contract(this.contract.id)
    },

    show_company() {
      this.$router.push({ path: '/company/' + this.project_by_id(this.contract.project_id).company_id });
    },
    getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    periode_endig() {
      let endyear = this.week.substring(3)
      let endweek = Number(this.week.substring(0, 2))
      let ed = new Date(endyear, 0, 1);
      ed.setDate(ed.getDate() + (endweek * 7));
      return new Date(this.user_planning.enddate) <= ed
    },
    haspassed() {
      let startyear = this.week.substring(3)
      let startweek = Number(this.week.substring(0, 2))
      var d = new Date(startyear, 0, 1);
      if(d.getDay()!=1  ){
        d.setDate(d.getDate() + (startweek * 7));
      } else {
        d.setDate(d.getDate() + ((startweek-1) * 7));
      }
      var startdate = this.getMonday(d)
      return startdate > new Date(this.user_planning.enddate)
    },
    future() {
      let startyear = this.week.substring(this.week.indexOf('-') + 1)
      let startweek = Number(this.week.substring(0, this.week.indexOf('-')))
      var d = new Date(startyear, 0, 1);
      if(d.getDay()!=1  || startweek>1){
        d.setDate(d.getDate() + (startweek * 7));
      }
      var startdate = this.getMonday(d)
      let showweek = this.getWeekNumber(startdate) 
      let planning_week = this.getWeekNumber(new Date(this.user_planning.startdate));
      return showweek[0]==planning_week[0]?showweek[1] < planning_week[1]:showweek[0]<planning_week[0]
    },

    agreeForm() {
      this.agreemode = true;
      if (this.$refs.submit) {
        this.$refs.submit.click();
      }
    },
    has_hours() {
      let daysFilled = 0;
      for (var i = 0; i < this.hours.length; i++) {
        if (this.hours[i].contract_id == this.contract.id) {
          daysFilled++
        }
      }
      return daysFilled > 0
    },
    ...mapActions([
      'save_hours',
      'add_hours'
    ]),

    userplanning(user_id) {
      let _planning = []
      for (var i = 0; i < this.planning.length; i++) {
        if (this.planning[i].user_id == user_id) {
          _planning.push(this.planning[i])
        }
      }
      return _planning
    },
  },
  watch: {

  },
}
</script>