<template>
	<div class="popup">
        <div class="popup-header">
              {{$t('titles.contact')}}  {{ user_set.length }} {{ $t('titles.users') }}
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
          <b-container fluid>
            <div class="form-row">
                  <div class="col-3">
                    <label for=""> {{ $t("titles.subject") }}</label>
                  
                  </div>

                  <div class="col-8">
                      <b-input  :state="subject!=null&&subject!=''" v-model="subject" required :placeholder='$t("titles.subject")' />
                  </div>
              </div>

            <div class="form-row" >
                <div class="col-3">
                    <label for=""> {{$t("labels.message")}}</label>
                </div>  
                <div class="col-8">
                    {{ $t('templates.searchemail') }}            

                    <b-form-textarea  rows="3" v-model="inappmessage"></b-form-textarea>
                </div>               
            </div>             
          </b-container>
        </div>
        <div class="popup-footer">
            <div @click="save_and_close" class="btn btn-primary"><font-awesome-icon icon="bell" /> {{ modalValid()  }}</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters,mapState, mapActions } from 'vuex'

// const querystring = require("querystring");

export default {
    name: 'inapp_search_modal',
    data() {
        return {
          inappmessage:"",
            sender:null,
            subject:""
        }
    },
    props: {
      user_set: Array,
    },
    
    mounted() {
        this.inappmessage = this.message
    },
    computed: {
        ...mapGetters([
            'username_by_id',
            'user_by_id',
            'owners'
            
        ]),
        ...mapState([
          'settings',
          'current_user',
      ])

    },
    methods: {
        modalValid(){
          return this.$i18n.t("buttons.sendmessage")
        },
        async save_and_close() {
          let index = 0;

          while (index<this.user_set.length){
              let message ={}
              message.message = this.inappmessage.replaceAll(/\[voornaam\]/gi, this.user_set[index].firstname)
              message.subject = this.subject
              message.user_id = this.user_set[index].id
              // index++
            // console.log(message)
              if(this.settings.PMHistory) {
                  this.add_pm( { id: 0, from: this.current_user.id, to: message.user_id, message:this.subject+"<br>"+message.message })
              }
              try {
                  await axios.post('/api/notify', message).then((response) => {
                    console.log('succes',response)
                    if (response.status==200) {
                      index++
                    }
                  })
              } catch (err) {
                  console.log(err)
                  index++

              }
          }

          this.$emit('close')
        },
        ...mapActions([
        'add_pm'

      ]),

    },
}
</script>