
<template>
  <b-container>
    <b-table :items=this.company.tags :fields=fields>
      <template #cell(actions)="row">
        <a v-if="row.item.id!=0" href="javascript:;" class="action-item" @click="delete_tag(row.item.id)">
          <font-awesome-icon icon="trash" />
        </a>
      </template>
    </b-table>
    <b-row>
      <b-col>
        <h2>{{ $t("buttons.addtag") }}</h2>
      </b-col>
    </b-row>
    <div class="row">
      <div class="col-3">
        <label for="">{{ $t("labels.tag") }}</label>
      </div>
      <div class="col">
        <b-form-group :label="$t('labels.tag')">
          <v-select label="tag" :options="other_tags" class="contactpersoon" v-model="tag_id"></v-select>
        </b-form-group>
      </div>

    </div>
    <div class="form-row">
      <div class="col-3">
        <div v-if="tag_id!=null" @click="save_tag" class="btn btn-primary">{{ $t("buttons.addtag") }}</div>
      </div>
    </div>
  </b-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'companytagssscreen',
  data() {
    return {
      tag_id: null,
    }
  },
  props: {
    company_id: Number
  },
  computed: {
    fields() {
      return [
        { key: 'tag_id', label: this.$i18n.t('labels.name'), formatter: "tagname", sortable: true, sortDirection: 'desc' },
        { key: 'actions', label: this.$i18n.t('labels.actions') },
      ]
    },
    company() {
      return this.company_id > 0 ? this.company_by_id(this.company_id) : this.company_new
    },
    other_tags() {
      let other = []
      for (let i = 0; i < this.tags.length; i++) {
        let addtag = true;
        for (var f = 0; f < this.company.tags.length; f++) {
          if (this.company.tags[f].tag_id == this.tags[i].id) {
            addtag = false;
            break;
          }
        }
        if (addtag) {
          other.push(this.tags[i]);
        }
      }
      return other
    },
    ...mapGetters([
      'user_metas',
      'company_by_id',
      'activebranches',
      'user_new',
      "tag_by_id",
      'company_new'

    ]),
    ...mapState([
      'companies',
      'branches',
      'avatars',
      'tags'
    ]),

  },
  methods: {
    tagname(id) {
      return this.tag_by_id(id).tag

    },
    delete_tag(id) {
      for (let i = 0; i < this.company.tags.length; i++) {
        if (this.company.tags[i].id == id) {
          this.company.tags.splice(i, 1);
        }
      }
      this.delete_company_tag(id);
    },

    save_tag() {
      var datatosend = { tag_id: this.tag_id.id, company_id: this.company_id, id: 0 }
      this.company.tags.push(datatosend)
      this.$store.dispatch('save_company_tag', datatosend);
    },
    ...mapActions([
      'save_company_tag',
      'delete_company_tag'
    ]),



  }

}    
</script>
