<template>
  <b-container v-if="!haspassed()">
    <div v-if="!future()">
      <UserPlanningBlock :user_id=this.contract.user_id></UserPlanningBlock>
      {{ $t("labels.hourrate") }} € {{this.user_planning.tarief}}, {{ $t('labels.purchase') }}<br/>
      <!-- <br />
      <strong>{{this.user_planning.project_name}}</strong><br />
      {{ $t("labels.location") }} {{this.user_planning.project_location}}<br /> -->
      {{ $t("labels.contactperson") }} {{this.username_by_id(this.user_planning.pointcontact_id)}}<br />
      <!-- {{ $t("meta.mobiel") }} {{this.user_by_id(this.user_planning.pointcontact_id).mobile}}<br /> -->
      <!-- {{ $t("labels.call") }} {{this.formatDate(this.user_by_id(this.user_planning.pointcontact_id).callat)}}<br />
      <pre>{{this.user_by_id(this.user_planning.pointcontact_id).callbacknote}}</pre>
      <a href="javascript:;" v-if="checkcallat==2" class="action-item"
        @click="callUserModal(user_planning.pointcontact_id)">
        <font-awesome-icon icon="exclamation" style="color: red;" />
        <font-awesome-icon icon="phone" />
      </a>
      <a href="javascript:;" v-else-if="checkcallat==1" class="action-item"
        @click="callUserModal(user_planning.pointcontact_id)">
        <font-awesome-icon icon="phone" />
      </a>
      <a v-else href="javascript:;" @click="callUserModal(user_planning.pointcontact_id)">
        <font-awesome-icon icon="question" style="color: green;" />
        <font-awesome-icon icon="phone" />
      </a>
      <br /> -->


      {{ $t("labels.hourrate") }} € {{this.user_planning.verkooptarief}}, {{ $t('labels.sale') }}<br />
      <!-- <div v-html="weektotalhtml(contract_id, week)"></div>

        <strong>{{ $t('titles.planning') }}</strong><br /> -->
      {{ $t("labels.period") }} {{this.formatDate(this.user_planning.startdate)}} {{ $t("labels.to") }}
      {{this.formatDate(this.user_planning.enddate)}}
      <br/>
<!--      <b-row>
        <b-col v-if="periode_endig()">
          <font-awesome-icon icon="exclamation" style="color: red;" />
          {{$t("labels.agreement_ends")}}
        </b-col>
        <b-col v-else>
          <font-awesome-icon icon="check" style="color: green;" />
          {{$t("labels.agreement_doesnotend")}}
        </b-col>
        <b-col v-if="this.contract.signed_zzpr==0">
          <font-awesome-icon icon="hammer" style="color: red;" />{{$t("labels.contractnotsignedzzp")}}
        </b-col>
        <b-col v-else>
          <font-awesome-icon icon="hammer" style="color: green;" />{{$t("labels.contractsignedzzp")}}
        </b-col>
        <b-col v-if="this.contract.signed_company==0">
          <font-awesome-icon icon="pen" style="color: red;" />{{$t("labels.contractnotsignedcompany")}}
        </b-col>
        <b-col v-else>
          <font-awesome-icon icon="pen" style="color: green;" />{{$t("labels.contractsignedcompany")}}
        </b-col>
      </b-row> -->
      <b-row>
        <!-- <b-col cols="3">
          <a v-if="has_hours()==true" href="javascript:;" class="btn"
            @click="checkHoursModal(contract_id, Number(week.substring(0,week.indexOf('-'))))">
            <font-awesome-icon icon="signature" />{{$t("labels.edithours")}}
          </a>
        </b-col>
        <b-col cols="3">
          <a href="javascript:;" class="btn" @click="editContractModal(contract.project_id,contract.user_id)">
            <font-awesome-icon icon="cog" />{{$t("labels.editcontract")}}
          </a>
        </b-col> -->
        <b-col cols="6">
          <br/>
          <a href="javascript:;" class="colorset"
            v-bind:style="{ color: classcolor1, 'background-color':'#ffffff', border: '2px solid white'}"
            @click="setcolor(0)">
            <font-awesome-icon icon="square" />
          </a>
          <a href="javascript:;" class="colorset"
            v-bind:style="{ color: classcolor2, 'background-color':'#ffffff', border: '2px solid white'}"
            @click="setcolor(1)">
            <font-awesome-icon icon="square" />
          </a>
          <a href="javascript:;" class="colorset"
            v-bind:style="{ color: classcolor3, 'background-color':'#ffffff', border: '2px solid white'}"
            @click="setcolor(2)">
            <font-awesome-icon icon="square" />
          </a>
        </b-col>
      </b-row>





    </div>
  </b-container>
  <b-container v-else>
  </b-container>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import UserPlanningBlock from './UserPlanningBlock'

export default {
  name: 'user_planning',
  data() {
    return {
      classcolor1: "#ff0000",
      classcolor2: "#ff0000",
      classcolor3: "#ff0000",
    }
  },
  props: {
    contract_id: Number,
    week: String
  },
  mounted() {
    this.classcolor1 = this.settings.status_colors[0].color
    this.classcolor2 = this.settings.status_colors[1].color
    this.classcolor3 = this.settings.status_colors[2].color
  },
  computed: {
    checkcallat() {
      if (this.user_by_id(this.user_planning.pointcontact_id).callat == null) {
        return 0
      } else {
        let calldate = new Date(this.user_by_id(this.user_planning.pointcontact_id).callat)
        var today = new Date();
        var diff = (calldate.getTime() - today.getTime()) / (1000 * 3600 * 24)
        if (calldate < today || diff < 14) {
          return 2
        }
      }
      return 1
    },

    contract() {
      return this.contract_by_id(this.contract_id);
    },
    user() {
      return this.user_by_id(this.contract.user_id)
    },
    user_planning() {
      return this.find_planning(this.contract.project_id, this.contract.user_id)
    },

    ...mapState([
      'projects',
      'users',
      'companies',
      'contracts',
      'branches',
      'planning',
      'settings',
      'current_user',
      'hours'
    ]),
    ...mapGetters([
      'project_by_id',
      'company_by_id',
      'contract_by_id',
      'zzpers',
      'user_by_id',
      'username_by_id',
      'hours_by_id',
      'find_planning'
    ]),

  },
  methods: {
    setcolor(id) {
      this.contract.color = this.settings.status_colors[id].color
      this.save_contract(this.contract.id)
    },

    // getMonday(d) {
    //   d = new Date(d);
    //   var day = d.getDay(),
    //     diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    //   return new Date(d.setDate(diff));
    // },
    periode_endig() {
      let endyear = this.week.substring(this.week.indexOf('-') + 1)
      let endweek = Number(this.week.substring(0, this.week.indexOf('-')))
      let ed = new Date(endyear, 0, 1);
      ed.setDate(ed.getDate() + (endweek * 7));
      return new Date(this.user_planning.enddate) <= ed
    },
    haspassed() {
      let startyear = this.week.substring(this.week.indexOf('-') + 1)
      let startweek = Number(this.week.substring(0, this.week.indexOf('-')))
      var d = new Date(startyear, 0, 1);
      if(d.getDay()!=1  ){
        d.setDate(d.getDate() + (startweek * 7));
      } else {
        d.setDate(d.getDate() + ((startweek-1) * 7));
      }
      var startdate = this.getMonday(d)
      return startdate > new Date(this.user_planning.enddate)
    },
    future() {
      let startyear = this.week.substring(this.week.indexOf('-') + 1)
      let startweek = Number(this.week.substring(0, this.week.indexOf('-')))
      var d = new Date(startyear, 0, 1);
      if(d.getDay()!=1  ){
        d.setDate(d.getDate() + (startweek * 7));
      } else {
        d.setDate(d.getDate() + ((startweek-1) * 7));
      }
      var startdate = this.getMonday(d)
      let showweek = this.getWeekNumber(startdate) 
      let planning_week = this.getWeekNumber(new Date(this.user_planning.startdate));
      return showweek[0]==planning_week[0]?showweek[1] < planning_week[1]:showweek[0]<planning_week[0]
    },
    agreeForm() {
      this.agreemode = true;
      if (this.$refs.submit) {
        this.$refs.submit.click();
      }
    },
    has_hours() {
      return this.hours.filter(h => h.contract_id = this.contract_id).length>0
      // let daysFilled = 0;
      // for (var i = 0; i < this.hours.length; i++) {
      //   if (this.hours[i].contract_id == this.contract.id) {
      //     daysFilled++
      //   }
      // }
      // return daysFilled > 0

    },
    ...mapActions([
      'save_contract'
    ]),

    userplanning(user_id) {
      return this.planning.filter(p => p.user_id == user_id)
      // let _planning = []
      // for (var i = 0; i < this.planning.length; i++) {
      //   if (this.planning[i].user_id == user_id) {
      //     _planning.push(this.planning[i])
      //   }
      // }
      // return _planning
    },
  },
  watch: {

  },
  components: { UserPlanningBlock }
}
</script>