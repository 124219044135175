<template>
  <b-container v-if="createAny('users')" fluid>
    <div class="title-bar">
      <h1>{{ $t("labels.hourcategoryname") }} <strong> {{hourcategory.name}}</strong></h1>
      <div @click="edit_hourcode(0)" class="btn btn-primary">
        <font-awesome-icon icon="plus" /> {{ $t("buttons.addhourcode") }}
      </div>
    </div>
    <b-row>
      <b-col cols="12" v-if="createAny('project')">
        <b-form-checkbox v-model="only_active" name="checkbox-1" value=1 unchecked-value=0>
          {{ $t('labels.hideinactive')}}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-table :items="hourcodes_in_category" :fields="fields" :sort-by.sync="sortBy" @row-clicked="onHourcodeClicked">
      <template #cell(active)="row">
        <font-awesome-icon v-if="row.item.active" icon="check" style="color: green;"
          :title="$t('labels.edituurcodeoff')" />
        <font-awesome-icon v-else icon="times" style="color: red;" :title="$t('labels.edituurcodeon')" />
      </template>
      <template #cell(name)="row">
        <strong v-if="row.item.ishoofd">{{row.item.name}} </strong>
        <p v-else>{{row.item.trail}} {{row.item.name}} </p>
      </template>
      <template #cell(pulldown)="row">
        <b-dropdown id="dropdown-dropup" dropup :text="$t('buttons.doaction')" variant="primary">
          <b-dropdown-item @click="confirm_delete_hourcode(row.item.id)">
            <font-awesome-icon icon="trash" /> {{$t('labels.deleteuurcode')}}
          </b-dropdown-item>
          <b-dropdown-item @click="edit_hourcode(row.item.id)">
            <font-awesome-icon icon="cog" /> {{$t('labels.edituurcode')}}
          </b-dropdown-item>
          <b-dropdown-item @click="clone_code(row.item.id)">
            <font-awesome-icon icon="clone" /> {{$t('labels.duplicate')}}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-container>

</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import hourcodesmodal from './modals/HourCodesModal'
import popup_modal from './modals/PopupModal.vue'

export default {
  name: 'hourcodesscreen',
  data() {
    return {
      sortBy: 'sort',
      selectedID: null,
      only_active:1

    }
  },
  computed: {
    fields() {
      return [
        { key: 'name', label: this.$i18n.t('labels.hourcodename') },
        { key: 'sort', label: this.$i18n.t('labels.hourcodecode'), sortable: true, sortDirection: 'desc' },
        { key: 'active', label: this.$i18n.t('labels.active') },
        { key: 'pulldown', label: this.$i18n.t('labels.actions') },
      ]
    },
    ...mapState([
      'hourcodes',
      'hourcategories',
    ]),
    ...mapGetters([
      'hourcode_by_id',
      'hourcategory_by_id',
      'confirm_modal',
      'createAny'

    ]),
    hourcodes_in_category: {
      get() {
        let codes = [];
        // var hoofdcode = 1;

        let hourcodesincategory = []
        for (var i = 0; i < this.hourcodes.length; i++) {
          if (this.hourcodes[i].hour_category_id == this.hourcategory.id) {
            let copy = {}
            copy.active = this.hourcodes[i].active
            copy.code = this.hourcodes[i].code
            copy.hour_category_id = this.hourcodes[i].hour_category_id
            copy.id = this.hourcodes[i].id
            copy.name = this.hourcodes[i].name
            copy.parent = this.hourcodes[i].parent
            hourcodesincategory.push(copy)
            if (this.hourcodes[i].parent == -1) {
              codes[this.hourcodes[i].id] = copy.code
              copy.sort = copy.code
              copy.ishoofd = true
              //  hoofdcode++
            } else {
              var cp = this.hourcode_by_id(this.hourcodes[i].parent)
              if (cp.parent != -1) {
                copy.trail = "----"
                copy.sort = codes[cp.parent] + "." + cp.code + "." + this.hourcodes[i].code
                copy.ishoofd = false
              } else {
                copy.trail = "--"
                copy.sort = codes[this.hourcodes[i].parent] + "." + this.hourcodes[i].code
                copy.ishoofd = false
              }

            }
          }
        }
        if(this.only_active==true){
          console.log("filtered", this.only_active)
          return hourcodesincategory.filter(hc =>hc.active==true)
        } else {
          console.log("unfiltered")
          return hourcodesincategory
        }
        
      }
    },
    hourcategory: {
      get() {
        return this.hourcategory_by_id(this.$route.params.id)
      },
    },
  },
  methods: {

    confirm_delete_hourcode(id) {
      this.selectedID = id
      let message = this.$i18n.t('labels.confirmdeletehead') + this.hourcode_by_id(id).name + this.$i18n.t('labels.confirmdeletetail')
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
        message: message,
      }, {
        name: 'popup_modal',
        draggable: false,
        height: 'auto',
        width: '500px'
      }, {
        'before-close': this.modalClose
      })

      this.$emit('close')

    },
    modalClose() {
      if (this.confirm_modal.id == 1) {
        this.delete_hourcode(this.selectedID);
        for (let i = 0; i < this.hourcodes.length; i++) {
          if (this.hourcodes[i].id == this.selectedID) {
            this.hourcodes.splice(i, 1);
          }
        }
      }
    },
    ...mapActions([
      'add_hourcode',
      'save_hourcode',
      'delete_hourcode'
    ]),
    edit_hourcode(hourcode) {
      if (hourcode == 0) {
        this.$store.commit('new_hourcode', {
          id: 0,
          active: 1,
          parent: -1,
        })
      }
      this.$modal.hide('hourcodesmodal')
      this.$modal.show(hourcodesmodal, {
        hourcode_id: hourcode,
        hour_category_id: this.hourcategory.id
      }, {
        name: 'hourcodes_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '800px'
      })

      this.$emit('close')
    },
    clone_code(hourcode) {
      var clone = this.hourcode_by_id(hourcode)
      this.$store.commit('new_hourcode', {
        id: 0,
        name: clone.name,
        active: 1,
        code: clone.code,
        parent: clone.parent,
        hour_category_id: clone.hour_category_id

      })
      this.$modal.hide('hourcodesmodal')
      this.$modal.show(hourcodesmodal, {
        hourcode_id: 0,
        hour_category_id: clone.hour_category_id
      }, {
        name: 'hourcodes_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '800px'
      })

      this.$emit('close')
    },
    onHourcodeClicked(row) {
      let hourcodeclicked = this.hourcode_by_id(row.id)
      if (hourcodeclicked.active == 1) {
        hourcodeclicked.active = 0
      } else {
        hourcodeclicked.active = 1
      }
      this.save_hourcode(row.id)
    },
    subcode(id) {
      let code = this.hourcode_by_id(id)
      if (code.parent == -1) {
        return code.code
      } else {
        let parent = this.hourcode_by_id(code.parent)
        return parent.code + "-" + code.code
      }
    }
  },
}
</script>

