<template>
  <b-container v-if="createAny('users') && currentUserIsSuperAdmin()" fluid>

    <!-- TItle bar -->
    <div class="title-bar">
      <h1>Meta fields</h1>
    </div>
    Fields will be replaced on save. Use with care!
    <h2>User metas 
      <font-awesome-icon v-if="usermetacheck" icon="check" style="color: green;" />
      <font-awesome-icon v-else icon="times" style="color: red;" />
    </h2>
    <b-form-textarea v-model="user_metas" rows="6"/>
    <h2>Project metas
      <font-awesome-icon v-if="projectmetacheck" icon="check" style="color: green;" />
      <font-awesome-icon v-else icon="times" style="color: red;" />

    </h2>
    <b-form-textarea v-model="project_metas" rows="6"/>
    <h2>Companies metas
      <font-awesome-icon v-if="companiesmetacheck" icon="check" style="color: green;" />
      <font-awesome-icon v-else icon="times" style="color: red;" />

    </h2>
    <b-form-textarea v-model="companies_metas" rows="6"/>
    <h2>ZZPR in project metas
      <font-awesome-icon v-if="zzpmetacheck" icon="check" style="color: green;" />
      <font-awesome-icon v-else icon="times" style="color: red;" />

    </h2>
    <b-form-textarea v-model="zzpr_in_project_metas" rows="6"/>
    <h2>User settings metas
      <font-awesome-icon v-if="usermetacheck" icon="check" style="color: green;" />
      <font-awesome-icon v-else icon="times" style="color: red;" />

    </h2>
    <b-form-textarea v-model="user_settings_metas" rows="6"/>
    <h2>Contract metas
      <font-awesome-icon v-if="contractmetacheck" icon="check" style="color: green;" />
      <font-awesome-icon v-else icon="times" style="color: red;" />

    </h2>
    <b-form-textarea v-model="contracts_metas" rows="6"/>
  <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="save" /> {{ $t("buttons.savepages") }}
    </div>       
  </b-container>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'metas-screen',
  data() {
    return {
      user_metas:null,
      project_metas:null,
      companies_metas:null,
      zzpr_in_project_metas:null,
      user_settings_metas:null,
      contracts_metas:null,
      devmetas:null
    }
  },

  computed: {

    ...mapState([
      'settings',
    ]),
    ...mapGetters([
      'server_url',
      'createAny'
    ]),
    usermetacheck(){
      if(this.user_metas && this.devmetas){
        return this.user_metas == JSON.stringify(this.devmetas.user_metas)
      } 
      return false
    },
    projectmetacheck(){
      if(this.user_metas && this.devmetas){
      return this.project_metas == JSON.stringify(this.devmetas.project_metas)
    } 
      return false
    },
    companiesmetacheck(){
      if(this.user_metas && this.devmetas){
      return this.companies_metas == JSON.stringify(this.devmetas.companies_metas)
    } 
      return false
    },
    zzpmetacheck(){
      if(this.user_metas && this.devmetas){
      return this.zzpr_in_project_metas == JSON.stringify(this.devmetas.zzpr_in_project_metas)
    } 
      return false
    },    
    usersettingsmetacheck(){
      if(this.user_metas && this.devmetas){
      return this.user_settings_metas == JSON.stringify(this.devmetas.user_settings_metas)
    } 
      return false
    },
    contractmetacheck(){
      if(this.user_metas && this.devmetas){
      return this.contracts_metas == JSON.stringify(this.devmetas.contracts_metas)
    } 
      return false
    },        
  },
  async mounted(){
    this.user_metas = JSON.stringify(this.settings.user_metas)
    this.project_metas = JSON.stringify(this.settings.project_metas)
    this.companies_metas = JSON.stringify(this.settings.companies_metas)
    this.zzpr_in_project_metas = JSON.stringify(this.settings.zzpr_in_project_metas)
    this.user_settings_metas = JSON.stringify(this.settings.user_settings_metas)
    this.contracts_metas = JSON.stringify(this.settings.contracts_metas)
  //  await fetch(`${'https://zzpr-intranet.flyingkiwi.dev/api/public_settings'}`)
    //  .then((response) => response.json())
      //.then((data) => this.devmetas = data)
      await fetch(`${'https://development.flyingfreelancers.nl/api/public_settings'}`)
      .then((response) => response.json())
      .then((data) => this.devmetas = data)

  },
  methods: {
    ...mapActions([
    ]),

    save_settings() {
      try {
        axios.patch('/api/settings/',
          {
            user_metas: JSON.parse(this.user_metas),
            project_metas: JSON.parse(this.project_metas),
            companies_metas: JSON.parse(this.companies_metas),
            zzpr_in_project_metas: JSON.parse(this.zzpr_in_project_metas),
            user_settings_metas: JSON.parse(this.user_settings_metas),
            contracts_metas: JSON.parse(this.contracts_metas)
          },
        )
          .then((response) => {
            console.log(response)
          })
      } catch (err) {
        console.log(err)
      }
    },
  },

}
</script>