<template>
	<div class="popup">

        <div class="popup-header">
            <div v-if="hourcode.id > 0">
                {{hourcode.name}}
            </div>
            <div v-else>
                 {{ $t("labels.newhourcode") }}
            </div>
            <div v-if="hourcode.id == 0" class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
            <div v-else class="popup-close" @click="save_and_close">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
            <b-container>
                <form class="form">
                    <!-- branche name -->
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">{{ $t("labels.hourcodename") }}</label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" v-model="hourcode.name">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">{{ $t("labels.rootlevel") }}</label>
                        </div>                            
                        <div class="col">
                        <b-form-select v-model="hourcode.parent" class="form-control">
                            <option value="-1"> {{ $t("labels.rootlevel") }}</option>
                            <option v-for="hourcodes in hourcodes_in_category" :key="hourcodes.id" :value="hourcodes.id">
                                {{ constructname(hourcodes.id) }}
                            </option>

                        </b-form-select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">{{ $t("labels.hourcodecode") }}</label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" v-model="hourcode.code">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">{{ $t("labels.active") }}</label>
                        </div>
                        <div class="col">
                            <select v-model="hourcode.active" class="form-control">
                                <option value="1">{{ $t("option.active") }}</option>
                                <option value="0">{{ $t("option.inactive") }}</option>
                            </select> 
                        </div>
                    </div>  
                    <input type="hidden" class="form-control" v-model="hourcode.hour_category_id">                 
                </form>
            </b-container>
        </div>
        <div class="popup-footer">
            <div v-if="hourcode.id == 0" class="btn btn-primary" @click="add_hourcode(hourcode); $emit('close')">{{ $t("buttons.add") }}</div>
            <div v-else @click="save_and_close" class="btn btn-primary">{{ $t("buttons.saveandclose") }}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
    name: 'hourcode-modal',
    data() {
        return {
    //      new_hourcode: { id: 0, active:1, parent:-1}  
        }
    },
    props: {
        hourcode_id: Number,
        hour_category_id:Number
    },
    mounted() {
    },
    computed: {
      
      hourcode: {
        get() {
          let wfobject = this.hourcode_id > 0 ? this.hourcode_by_id(this.hourcode_id) : this.new_hourcode
          wfobject.hour_category_id = this.hour_category_id
          return wfobject
        }
      },
      hourcodes_in_category:{
          get() {
            let hourcodesincategory=[]
            for(var i=0;i<this.hourcodes.length;i++){
              if(this.hourcodes[i].hour_category_id==this.hour_category_id && this.hourcodes[i].active==1 && this.hourcodes[i].id!=this.hourcode_id){
                hourcodesincategory.push(this.hourcodes[i])
              }
            }
            return hourcodesincategory
          }
        } ,
      ...mapGetters([
        'hourcode_by_id',
      ]),
      ...mapState([
        'hourcodes',
        'new_hourcode'
      ])
    },
    methods: {
        constructname(id) {
            var cp = this.hourcode_by_id(id)
            var ret = cp.name
            if(cp.parent!=-1){
                var sub = this.hourcode_by_id(cp.parent)
                ret = sub.name + " > " + cp.name
                if(sub.parent!=-1){
                    ret = this.hourcode_by_id(sub.parent).name + " > " + sub.name + " > " + cp.name
                }
            } 
            return ret

        },
        save_and_close() {
            this.save_hourcode(this.hourcode.id)
            this.$emit('close')
        },
        ...mapActions([
            'save_hourcode',
            'add_hourcode'
        ]),

    }
}
</script>