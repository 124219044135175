<template>
	<div class="popup">

        <div class="popup-header">
            <div v-if="declaratie.id > 0">
                {{declaratie.name}}
            </div>
            <div v-else>
                 {{ $t("buttons.adddeclaratie") }}
            </div>
            <div v-if="declaratie.id == 0" class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
            <div v-else class="popup-close" @click="save_and_close">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
            <b-container>
                <form class="form" v-on:submit='save_and_close'>
                    <div class="form-row">
                        <div class="col-3">
                            <font-awesome-icon v-if="checkdeclaratie" icon="check" style="color: green;"/>
                            <font-awesome-icon v-else icon="times" style="color: red;"/>                  

                            <label for="">{{ $t("labels.declaratiename") }}</label>
                        </div>
                        <div class="col">
                            <b-input ref="deffield" :state="checkdeclaratie" type="text" class="form-control" v-model="declaratie.name"></b-input>
                            <b-form-invalid-feedback >
                                {{ $t("validation.declaratiecheck")}}
                            </b-form-invalid-feedback>  
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">{{ $t("labels.legenda") }}</label>
                        </div>
                        <div class="col">
                            <b-input ref="deffield" type="text" class="form-control" v-model="declaratie.legenda"></b-input>
                            <b-form-invalid-feedback >
                                {{ $t("validation.declaratiecheck")}}
                            </b-form-invalid-feedback>  
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">{{ $t("labels.declaratietype") }}</label>
                        </div>
                        <div class="col">
                          <b-form-select v-model="declaratie.type" class="form-control">
                              <option value="hours"> {{ $t("option.hours") }}</option>
                              <option value="distance">{{ $t("option.distance") }}</option>
                              <option value="total">{{ $t("option.total") }}</option>
                          </b-form-select>      
                        </div>
                      </div>                    
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">{{ $t("labels.active") }}</label>
                        </div>
                        <div class="col">
                            <select v-model="declaratie.active" class="form-control">
                                <option value="1">{{ $t("option.active") }}</option>
                                <option value="0">{{ $t("option.inactive") }}</option>
                            </select> 
                        </div>
                    </div>      
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">{{ $t("labels.purchase") }}</label>
                        </div>
                        <div class="col">
                            <b-input ref="deffield" type="text" class="form-control" v-model="declaratie.purchase"></b-input>
                        </div>
                    </div>   
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">{{ $t("labels.sale") }}</label>
                        </div>
                        <div class="col">
                            <b-input ref="deffield" type="text" class="form-control" v-model="declaratie.sale"></b-input>
                        </div>
                    </div>    
                    <div class="form-row" v-if="declaratie.type=='total'">
                        <div class="col-3">
                        </div>
                        <div class="col">
                            <b-form-checkbox v-model="declaratie.attachment" name="checkbox-1" value="1" unchecked-value="0">{{ $t('labels.attachmentneededheader') }}
                                </b-form-checkbox>
                                                    </div>
                    </div>                                                                                 
                </form>
            </b-container>
        </div>
        <div class="popup-footer">
            <div v-if="declaratie.id == 0" class="btn btn-primary" @click="my_add_declaratie();">{{ addDeclaratieText() }}</div>
            <div v-else @click="save_and_close" class="btn btn-primary">{{ $t("buttons.saveandclose") }}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
    name: 'declaratie-modal',
    data() {
        return {
            new_declaratie:{id:0, name:"", active:1, attachment:1}
        }
    },
    props: {
        declaratie_id: Number
    },
    mounted() {
        this.focusInput();
    },
    computed: {
        declaratie() {
            return this.declaratie_id > 0 ? this.declaratie_by_id(this.declaratie_id) : this.new_declaratie
        },
        ...mapGetters([
            'declaratie_by_id',
        ]),
        ...mapState([
            'declaraties',
        ]),
        checkdeclaratie(){
            if(this.declaratie.id>0){
                return true
            }
            let found=false;
            if(this.declaratie.name!=undefined){
                for(var i=0;i<this.declaraties.length;i++){
                    if(this.declaratie.name.toLowerCase()==this.declaraties[i].name.toLowerCase()){
                        found = true;
                        break;
                    }
                }
            }
            return !found &&this.declaratie.name!=undefined&& this.declaratie.name.length>2
        }        
    },
    methods: {
        addDeclaratieText(){
            if (this.checkdeclaratie){
                return this.$i18n.t("buttons.add") 
            } else { 
                return this.$i18n.t("buttons.invalid") 
            }
        },
        save_and_close(e) {
            e.preventDefault() // it prevent from page reload
            if(this.declaratie.id==0){
                this.my_add_declaratie()
            }else{
                this.save_declaratie(this.declaratie.id)
                this.$emit('close')
            }            
        },
        ...mapActions([
            'save_declaratie',
            'add_declaratie'
        ]),
        my_add_declaratie(){
            if(this.checkdeclaratie){
                this.add_declaratie(this.declaratie);
                this.$emit('close')
            }
        },
    focusInput() {
      this.$refs.deffield.focus();
    }        

    }
}
</script>