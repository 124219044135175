<template>
  <b-container fluid>
      <!-- Title bar -->
      <div class="title-bar">
        <h1>{{ $t("titles.todo") }} {{ filteredTodo.length }}</h1>
        <div @click="edit_todo({ id: 0 })" class="btn btn-primary">
          <font-awesome-icon icon="plus" /> {{ $t("buttons.addtodo") }}
        </div>
      </div>
          <!-- Filter bar -->
      <b-row class="filter-bar">
        <b-col md="4" xl>
          <b-form-group :label="$t('labels.from')">
            <b-form-input type="date" v-model="showDateFrom" />
          </b-form-group>
        </b-col>
        <b-col md="4" xl>
          <b-form-group :label="$t('labels.to')">
            <b-form-input type="date" v-model="showDateTo" />
          </b-form-group>
        </b-col>
        <b-col md="4" xl>
            <b-form-group :label="$t('labels.todoassign')">
              <v-select label="fullname" :options="owners" class="contactpersoon" v-model="notify_id"></v-select>
            </b-form-group>
        </b-col>
        <b-col md="4" xl>
          <b-form-checkbox v-model="showall" name="checkbox-1" value=1 unchecked-value=0>{{
                $t("buttons.showall")
            }}</b-form-checkbox> 
          <b-form-checkbox v-model="showdone" name="checkbox-1" value=1 unchecked-value=0>{{
              $t("labels.tododone")
          }}</b-form-checkbox>
          <b-form-checkbox v-model="showothers" name="checkbox-1" value=1 unchecked-value=0>{{
              $t("labels.showotherstodo")
          }}</b-form-checkbox>
          
        </b-col>
      </b-row>
      <div v-for="(atodo) in filteredTodo" :key="atodo.id" class="public_project">
      <b-row>
          <b-col cols="3">{{ $t('labels.user') }}</b-col><b-col v-html="userinfo(atodo.contact_id)"></b-col>
        </b-row>
      
        <b-row>
          <b-col cols="3">{{ $t('labels.message') }}</b-col><b-col>{{ atodo.message }}</b-col>
        </b-row>  
        <b-row>
          <b-col cols="3">{{ $t('labels.addedby') }}</b-col><b-col v-html="userinfo(atodo.creator_id)"></b-col>
          <b-col cols="3">{{ $t('labels.sendcopy') }}</b-col><b-col v-html="userinfo(atodo.notify_id)"></b-col>
        </b-row>  
        <b-row>
          <b-col cols="3">{{ $t('labels.sendcc') }}</b-col><b-col>{{ atodo.cc }}</b-col>
        </b-row>          
        <b-row>
          <b-col cols="3">{{ $t('meta.aangemaakt') }}</b-col><b-col>{{ dateToNL(atodo.create_date) }}</b-col>
        </b-row>                    
        <b-row>
          <b-col cols="3">{{ $t('tableheaders.startdate') }}</b-col><b-col>{{ dateToNL(atodo.due_date) }} </b-col>
          <b-col cols="3" v-if="atodo.repeattask!='n'">{{ $t('labels.todorepeat') }}</b-col>
          <b-col v-if="atodo.repeattask=='d'">{{ $t('labels.repeatday') }}</b-col>
          <b-col v-if="atodo.repeattask=='w'">{{ $t('labels.repeatweek') }}</b-col>
          <b-col v-if="atodo.repeattask=='m'">{{ $t('labels.repeatmonth') }}</b-col>
          <b-col v-if="atodo.repeattask=='y'">{{ $t('labels.repeatyear') }}</b-col>
        </b-row>    
        <b-button @click="edit_todo({ id: atodo.id })"><font-awesome-icon icon="pen" /> {{$t('labels.edit')}}</b-button>
        <b-button @click="remove_todo(atodo.id)"><font-awesome-icon icon="trash" /> {{$t('labels.deletetodo')}}</b-button>
        <b-button v-if="atodo.processed!=1" @click="set_complete( atodo.id )">
          <font-awesome-icon  color="red" icon="times" />{{$t('buttons.todonotdone')}}
        </b-button>
        <b-button v-else @click="set_complete( atodo.id )">
          <font-awesome-icon color="green" icon="check" />{{$t('buttons.tododone')}}
        </b-button>        
                
      </div>
  </b-container>
</template>
<script>
// import { mapActions, mapState, mapGetters } from 'vuex'
import { mapActions,mapState, mapGetters } from 'vuex'
import todo_modal from '../components/modals/ToDoModal.vue'
import popup_modal from './modals/PopupModal.vue'
import axios from 'axios'

export default {
  name: 'todoscreen',
  data() {
    return {
      selectedID: null,
      showDateFrom:"",
      showDateTo:"",
      showall:0,
      showdone:0,
      showothers:0,
      notify_id:3

    }
  },
  computed:{
    ...mapGetters([
      'users',
      'todo',
      'username_by_id',
      'todo_by_id',
      'confirm_modal',
      'owners',

    ]),
    ...mapState([
          'todos',
          'settings',
          'current_user',
          'branches',
          'users',

      ]),
      filteredTodo(){
        let date = this.todo.filter(atd =>(new Date(atd.due_date)>=new Date(this.showDateFrom) && new Date(atd.due_date)<=new Date(this.showDateTo))||this.showall==1)
        var creator = date
        if(this.showothers==0){
          creator = date.filter(atd =>(atd.creator_id == this.notify_id.id)||(atd.notify_id == this.notify_id.id))
        } 
        var processed = creator
        if(this.showdone==0){
          processed = creator.filter(atd =>atd.processed!=1)
        }
        return processed 
      }
  },
  mounted() {
    var f = new Date()
    f.setMonth(f.getMonth()-1)
    this.showDateFrom = f.toISOString().split('T')[0]
    this.showDateTo = new Date().toISOString().split('T')[0]
    console.log(this.current_user)
    this.notify_id = this.users.filter(u =>u.id ==this.current_user.id).length>0?this.users.filter(u =>u.id ==this.current_user.id)[0]:{id:1}

  },
  methods:{
    ...mapActions([
          'delete_todo',
  ]),    
    edit_todo(todo) {
      // find contract for project + user
      this.$modal.hide('todo_modal')
      this.$modal.show(todo_modal, {
        todo_id: todo.id,
      }, {
        name: 'todo_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '800px',
        shiftY: 0
      })

      this.$emit('close')      
    },    
    userinfo(user_id) {
      return '<a href="/#/user/' + user_id + '" :style="{ cursor: \'pointer\'}" class="link-item"> ' + this.username_by_id(user_id) + '</a>'
    }, 
    remove_todo(id) {
      this.selectedID = id
      let message = this.$i18n.t('labels.confirmdeletehead') + this.todo_by_id(id).subject + this.$i18n.t('labels.confirmdeletetail')
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
        message: message,
      }, {
        name: 'popup_modal',
        draggable: false,
        height: 'auto',
        width: '500px'
      }, {
        'before-close': this.modalClose
      })

      this.$emit('close')

    },
    modalClose() {
      if (this.confirm_modal.id == 1) {
        this.delete_todo(this.selectedID);
        for (let i = 0; i < this.todo.length; i++) {
          if (this.todo[i].id == this.selectedID) {
            this.todo.splice(i, 1);
          }
        }
      }
    },
    async set_complete(id){
      let set_todo = {}
      set_todo.id = id;
      set_todo.processed = this.todo_by_id(id).processed!=1?1:0
      this.todo_by_id(id).processed = set_todo.processed
      try {
        await axios.post('/api/todo_complete', set_todo).then((response) => {
            console.log('succes', response)
            this.$store.dispatch('get_todo')
        })
      } catch (err) {
          console.log(err)
      }      
    }
  }
}
</script>