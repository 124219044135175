<template>

  <b-container fluid :style="AppSecondaryColor()" class="apppage">
    <b-button-group vertical>
      <b-button :style="AppPrimaryButton()" active-class="active" @click="$router.go(-1)">
        <font-awesome-icon icon="chevron-left" /> {{ settings.termstitle }}
      </b-button>   
    </b-button-group>
    <div v-html="settings.termspage"/> 

  </b-container>
</template>
<script>
import {  mapGetters, mapState  } from 'vuex'

export default {
  name: 'terms',
  data() {
    return {
    }
  },
  mounted() {

  },
  computed: {
    ...mapGetters([
      'is_logged_in',
    ]),   
    ...mapState([
      'settings',
    ]),
  },
  methods: {
  },
}
</script>
