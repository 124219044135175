<template>
  <div class="workfield-settings">
        <b-table 
        striped hover 
        :items="currentworkfields"
        :fields="fields" 
        @row-clicked="onWorkfieldClicked"        
        >
          <template #cell(active)="row"> 
            <font-awesome-icon v-if="row.item.active" icon="check" style="color: green;"/>
            <font-awesome-icon v-else icon="times" style="color: red;"/>
          </template>        
        </b-table>
    </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
export default {
    name: 'projectWorkfieldsscreen',
    data() {
        return {

        }      
    },
    props: {
      project_id:Number,
    },
    computed: {
        project() {
            return this.project_id > 0 ? this.project_by_id(this.project_id) : this.project_new
        },     
        fields () {
            return [
            { key: 'name', label: this.$i18n.t('labels.workfieldname')  , sortable: true, sortDirection: 'desc' },
            { key: 'active', label: this.$i18n.t('labels.active') },
            ]   
        },        
        ...mapGetters([
            'user_by_id',
            'branche_by_id',
            'company_by_id',
            'project_by_id',
            'project_new'

        ]),
        ...mapState([
            'projects',
            'workfields',
            'companies'

        ]),
        currentworkfields() {
          let currentfields=[]
          let company =  this.project.company_id==0?this.companies[0]:this.company_by_id(this.project.company_id)
          if(company){
            var branche = this.branche_by_id(company.branche_id)
            for(var i=0;i<this.workfields.length;i++){
              if(this.workfields[i].branche_id==branche.id && this.workfields[i].active=='1'){
                  let aworkfield=[]
                  aworkfield["name"]=this.workfields[i]["name"];
                  aworkfield["id"]=this.workfields[i]["id"];
                  aworkfield["active"]=this.project.workfields.indexOf(this.workfields[i]["id"])!=-1
                  currentfields.push(aworkfield)
              }
            }
          }
          return currentfields
        }
    },
    methods:{
        ...mapActions([
            'save_settings',
            'save_user_workfield',
            'delete_user_workfield'

        ]),
        onWorkfieldClicked(row){
            var datatosend={workfield_id:row.id, project_id:this.project_id}
            if(this.project.workfields.indexOf(row.id)!=-1){
                if(this.project_id>0){
                  this.$store.dispatch('delete_project_workfield',datatosend); 
                }
                this.project.workfields.splice(this.project.workfields.indexOf(row.id),1)
            }else{
                if(this.project_id>0){
                  this.$store.dispatch('save_project_workfield',datatosend); 
                }
                this.project.workfields.push(row.id)
            }         
          
        },          
    },
    components: { }
}
</script>
