import Vue from 'vue'
import { mapGetters, mapState, mapActions} from 'vuex'

// import popup_modal from '../components/modals/PopupModal.vue'
import dropuseronproject from '../components/planning/modals/DropUserOnProjectModal.vue'
import dropresourceonproject from '../components/planning/modals/DropResourceOnProjectModal.vue'
import droprojectonresource  from '../components/planning/modals/DropProjectOnResourceModal.vue'
import dropresourceonresource from '../components/planning/modals/DropResourceOnResourceModal.vue'
import dropprojectonuser from '../components/planning/modals/DropProjectOnUserModal.vue'
import dropworkfieldonuser from '../components/planning/modals/DropWorkfieldOnUserModal.vue'
import dropuseronworkfield from '../components/planning/modals/DropUserOnWorkfieldModal.vue'
import clickonuser from '../components/planning/modals/ClickOnUserModal.vue'
import clickonresource from '../components/planning/modals/ClickOnResourceModal.vue'
import modifyresourceonusermove from '../components/planning/modals/ModifyResourceOnUserMove.vue'

Vue.mixin({
  data() {
    return {
      user_id: null,
      project_id:null,
      resource_id :null
    }
  },
  computed: {
    ...mapGetters([
      'username_by_id',
      'project_by_id',
      'declaratie_by_id',
      'company_by_id',
      'user_by_id',
      'find_last_planning_user',
      'server_url',
      'hourcode_by_id',
      'factuur_by_id',
      'username_by_id',
      'confirm_modal',
      'resource_by_id',
      'workfield_by_id',
      'projectresource_by_id',
      'ffwax_by_id'
    ]),
    ...mapState([
      'current_user',
      'hours',
      'contracts',
      'planning',
      'settings',
      'signatures',
      'ffwax',
      'projectresources',
      'resources'

    ]),
    resourceTypes(){
      return [
        {id:1,value:"vervoer" , label: "Vervoer"},
        {id:2,value:"materiaal" , label: "Materiaal"},
        {id:3,value:"gereedschap" , label: "Gereedschap"},
      ]
    },

  },  
  methods: {
    ...mapActions([
      'add_ffwax',
      'save_ffwax',

    ]),
    resourcetype_by_id(id){
      return this.resourceTypes.filter(r=>r.id==id)[0]
    },
    resource_id_by_type(type){
      return this.resourceTypes.filter(r=>r.value==type)[0]
    },
    startDrag(evt, item, type) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('itemID', item.id)
      evt.dataTransfer.setData('type', type)
      console.log( ">STARTDRAG > ", item, type)
      if(type=="workfield"){
        this.$emit('workfieldDrag', item.workfield_id)
      }
      if(type=="resource"){
        this.$emit('resourceDrag', item.resource_id)
      }      
    },
    endDrag(evt, item, type) {
      console.log( ">END > ", item, type)
      if(type=="workfield"){
        this.$emit('workfieldDrag', null)
      }
      if(type=="resource"){
        this.$emit('resourceDrag', null)
      }      
    },

    dragUser(evt, n, item, type) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('day', n)
      evt.dataTransfer.setData('itemID', item.id)
      evt.dataTransfer.setData('type', type)
      console.log( ">STARTDRAG > ", item, type,n)
    },    
    dragResource(evt, n, item, type) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('day', n)
      evt.dataTransfer.setData('itemID', item.id)
      evt.dataTransfer.setData('type', type)
      console.log( ">STARTDRAG > ", item, type,n)
    },    
    onDrop(evt, list) {
      const itemID = evt.dataTransfer.getData('itemID')
      console.log(">>>>dropped" , itemID,list)
      // const item = this.items.find((item) => item.id == itemID)
      // item.list = list
    },
    onDropProject(evt, project_id, day) {
      const itemID = evt.dataTransfer.getData('itemID')
      const type = evt.dataTransfer.getData('type')
      console.log("onDropProject",day, itemID, type)
      if(day!=undefined){
        if(type=="user"){
          this.$modal.hide('dropuseronproject')
          this.$modal.show(dropuseronproject, {
            user_id:parseInt(itemID) ,
            project_id:parseInt(project_id)
          }, {
            name: 'dropuseronproject',
            draggable: false,
            height: 'auto',
            width: '800px'
          })
        }
        if(type == "resource"){
            this.$modal.hide('dropresourceonproject')
            this.$modal.show(dropresourceonproject, {
              resource_id:parseInt(itemID) ,
              project_id:parseInt(project_id)
            }, {
              name: 'dropresourceonproject',
              draggable: false,
              height: 'auto',
              width: '800px'
            })        
        }
      }

      this.$emit('close')

    },
    onDropProjectResource(evt, resource_id, day){
      const itemID = evt.dataTransfer.getData('itemID')
      const type = evt.dataTransfer.getData('type')
      console.log("onDropProjectResource",day, itemID, type, resource_id)
      if(type == "user"){
        this.$modal.hide('dropuseronworkfield')
        this.$modal.show(dropuseronworkfield, {
          user_id:parseInt(itemID) ,
          workfield_id:parseInt(resource_id)
        }, {
          name: 'dropuseronworkfield',
          draggable: false,
          height: 'auto',
          width: '800px'
        })   
        }
        if(type == "ressource"){
          this.$modal.hide('dropresourceonproject')
          this.$modal.show(dropresourceonproject, {
            resource_id:parseInt(itemID) ,
            project_id:parseInt(resource_id)
          }, {
            name: 'dropresourceonproject',
            draggable: false,
            height: 'auto',
            width: '800px'
          })        

        }

    },

    onDropUser(evt, user_id, day) {
      const itemID = evt.dataTransfer.getData('itemID')
      const type = evt.dataTransfer.getData('type')
      console.log(">>>>onDropUser" , itemID,user_id, day, type)
      
      if(type == "project"){
        this.$modal.hide('dropprojectonuser')
        this.$modal.show(dropprojectonuser, {
          user_id:parseInt(user_id) ,
          project_id:parseInt(itemID)
        }, {
          name: 'dropprojectonuser',
          draggable: false,
          height: 'auto',
          width: '800px'
        })        
      }
      if(type == "workfield"){
        this.$modal.hide('dropworkfieldonuser')
        this.$modal.show(dropworkfieldonuser, {
          user_id:parseInt(user_id) ,
          workfield_id:parseInt(itemID)
        }, {
          name: 'dropworkfieldonuser',
          draggable: false,
          height: 'auto',
          width: '800px'
        })    
      }
      if(type == "task"){
        const sourceday =  evt.dataTransfer.getData('day')
        let ff = this.ffwax_by_id(itemID)
        console.log(ff)

        console.log("user2user", day, sourceday)
        if(user_id == ff.user_id){
          if(evt.ctrlKey){
            console.log( "same user_id, copy")
            let moveofsset = day-sourceday
            ff.start_offset+=moveofsset
            ff.id = 0
            this.add_ffwax(ff) 
          } else {
            console.log( "same user_id, move", day-sourceday)
            let moveofsset = day-sourceday
            ff.start_offset+=moveofsset
            this.save_ffwax(ff.id)
            // check if we must ask to move resources also
            let res =  this.resources.filter(r=>r.user_id==user_id).filter(r => this.projectresources.filter(pr=>pr.project_id==ff.project_id && pr.resource_id!=null).filter(prt=>prt.resource_id==r.type).length>0)
            if(res.length>0){
              this.$modal.hide('modifyresourceonusermove')
              this.$modal.show(modifyresourceonusermove, {
                modify_resources:res,
                ffwaxid:ff.id
              }, {
                name: 'modifyresourceonusermove',
                draggable: false,
                height: 'auto',
                width: '800px'
              })                  
            }
            
          }
        } else {
          if(evt.ctrlKey){
            //copy task to new user
            ff.user_id = parseInt(user_id)
            let moveofsset = day-sourceday
            ff.start_offset+=moveofsset
            ff.id = 0
            this.add_ffwax(ff)   
          } else {
            // move task to new suer
            ff.user_id = parseInt(user_id)
            let moveofsset = day-sourceday
            ff.start_offset+=moveofsset
            this.save_ffwax(ff.id)              
          }       
        }
      }


    },
    onClickUser(startdate, user_id, n) {
      let day = new Date(startdate)
      day.setDate(day.getDate()+n)
      day.setHours(0,0,0,0)
      this.ffwax.filter(f => f.user_id ==user_id).forEach(ff => {
        if(ff.project_id<9990000){
          let startdate = new Date(this.project_by_id(ff.project_id).startdate)
          startdate.setHours(0,0,0,0)
          startdate.setDate(startdate.getDate() + ff.start_offset);
          let enddate = new Date(startdate)
          enddate.setDate(enddate.getDate() + ff.end_offset);
          if(day>=new Date(startdate)&& day<=new Date(enddate)){
            this.$modal.hide('clickonuser')
            this.$modal.show(clickonuser, {
              ffwax_id:ff.id
            }, {
              name: 'clickonuser',
              draggable: false,
              height: 'auto',
              width: '800px'
            })        
    
          }
        }        
      });

    },
    onClickTaskUser(task) {
      console.log("CICKUSER", task)
      if(task.project_id<9990000){
        this.$modal.hide('clickonuser')
        this.$modal.show(clickonuser, {
          ffwax_id:task.id
        }, {
          name: 'clickonuser',
          draggable: false,
          height: 'auto',
          width: '800px'
        }) 
      }       
    },
    onClickTaskResource(task) {
      this.$modal.hide('clickonresource')
      this.$modal.show(clickonresource, {
        ffwax_id:task.id
      }, {
        name: 'clickonresource',
        draggable: false,
        height: 'auto',
        width: '800px'
      })        
    
  },
    onDropResource(evt, resource_id, day) {
      const itemID = evt.dataTransfer.getData('itemID')
      const type = evt.dataTransfer.getData('type')
      console.log("onDropResource",itemID, type,day)

      if(type == "project"){
        this.$modal.hide('droprojectonresource')
        this.$modal.show(droprojectonresource, {
          resource_id:parseInt(resource_id) ,
          project_id:parseInt(itemID)
        }, {
          name: 'droprojectonresource',
          draggable: false,
          height: 'auto',
          width: '800px'
        })        
      }
      if(type == "resource"){
        console.log(this.projectresource_by_id(itemID))
        console.log(this.resource_by_id(resource_id))
        if(parseInt(this.projectresource_by_id(itemID).resource_id)==parseInt(this.resource_by_id(resource_id).type)){
          this.$modal.hide('dropresourceonresource')
          this.$modal.show(dropresourceonresource, {
            resource_id:parseInt(resource_id) ,
            workfield_id:parseInt(itemID)
          }, {
            name: 'dropresourceonresource',
            draggable: false,
            height: 'auto',
            width: '800px'
          })   
        } 
      }      
      if(type == "task"){
        const sourceday =  evt.dataTransfer.getData('day')
        let ff = this.ffwax_by_id(itemID)
        console.log(ff)

        console.log("user2user", day, sourceday)
        if(resource_id == ff.resource_id){
          if(evt.ctrlKey){
            console.log( "same resource, copy")
            let moveofsset = day-sourceday
            ff.start_offset+=moveofsset
            ff.id = 0
            this.add_ffwax(ff) 
          } else {
            console.log( "same user_id, move", day-sourceday)
            let moveofsset = day-sourceday
            ff.start_offset+=moveofsset
            // ff.end_offset+=moveofsset
            this.save_ffwax(ff.id)
            

          }
        } else {
          if(evt.ctrlKey){
            //copy task to new user
            ff.resource_id = parseInt(resource_id)
            let moveofsset = day-sourceday
            ff.start_offset+=moveofsset
            ff.id = 0
            this.add_ffwax(ff)   
          } else {
            // move task to new suer
            ff.resource_id = parseInt(resource_id)
            let moveofsset = day-sourceday
            ff.start_offset+=moveofsset
            this.save_ffwax(ff.id)              
          }       
        }
      }



    },
    onClickResource(startdate, resource_id, n) {
      console.log("onClickResource", startdate, resource_id, n)
      let day = new Date(startdate)
      day.setDate(day.getDate()+n)
      this.ffwax.filter(f => f.resource_id ==resource_id).forEach(ff => {
        let startdate = new Date(this.project_by_id(ff.project_id).startdate)
        startdate.setHours(0,0,0,0)
        startdate.setDate(startdate.getDate() + ff.start_offset);
        let enddate = new Date(startdate)
        enddate.setDate(enddate.getDate() + ff.end_offset);
        if(day>=new Date(startdate)&& day<=new Date(enddate)){
          this.$modal.hide('clickonresource')
          this.$modal.show(clickonresource, {
            ffwax_id:ff.id
          }, {
            name: 'clickonresource',
            draggable: false,
            height: 'auto',
            width: '800px'
          })        
  
        }
        
      });

    },    

  }
})