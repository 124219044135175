<template>
    <b-container>
        <b-row>
            <b-col>
                <h4>{{ $t("labels.sign_status") }} </h4>
            </b-col>
            <b-col>
                <b-form-checkbox v-model="contract.signed_zzpr" name="signed_zzpr" value=1 @input="set_signed_zzpr"
                    unchecked-value=0>{{ $t("labels.contractsignedzzp") }} </b-form-checkbox>
            </b-col>
            <b-col>
                <b-form-checkbox v-model="contract.signed_company" name="signed_company" value=1
                    @input="set_signed_company" unchecked-value=0>{{ $t("labels.contractsignedcompany") }}
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row v-if="contract">
            <b-col>
                <h4>{{ $t("labels.statuscolor") }}</h4>
            </b-col>
            <b-col :style="myStyle">
                <b-form-radio-group id="contract_colors" v-model="kleur" name="radio-sub-componentw"
                    :options="status_colors" >
                </b-form-radio-group>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
    name: 'contract_modal_default',
    data() {
        return {
            kleur:"#D2E1EE",
        }
    },
    mounted (){
        // this.kleur = this.contract.color
    },
    props: {
        contract_id: Number,
    },
    computed: {
        myStyle() {
            let style = {}
            style.backgroundColor = this.contract.color
            return style
        },
        contract() {
            // return this.contract_id > 0 ? this.contract_by_id(this.contract_id) : this.contract_new
            return this.contract_by_id(this.contract_id)
        },
        ...mapGetters([
            'contract_by_id',
            'contract_new',

        ]),
        ...mapState([
            'contracts',
            'settings'

        ]),
        status_colors() {
            let options = []
            for (const key in this.settings.status_colors) {
                options.push({ text: this.$i18n.t('labels.' + this.settings.status_colors[key].name), value: this.settings.status_colors[key].color , item: this.settings.status_colors[key].color })
            }
            return options
        }

    },
    methods: {
        set_signed_zzpr() {
            //todo fix date on first sign
            let now = new Date()
            if (this.contract.signed_zzpr == 1 && this.contract.zzprsigndate == undefined) {
                this.contract.zzprsigndate = now.toDateString();
                this.save_data()
            }
        },
        set_signed_company() {
            let now = new Date()
            if (this.contract.signed_zzpr == 1 && this.contract.companysigndate == undefined) {
                this.contract.companysigndate = now.toDateString();
                this.save_data()
            }
        },
        save_data() {
            console.log("default, save data", this.contract)
            this.save_contract(this.contract_id)
        },
        set_planning_id(id) {
            this.contract.planning_id = id
        },
        ...mapActions([
            'save_contract',
        ]),

    },
    watch :{
        kleur(){
            this.contract.color = this.kleur
        },
        contract(){
            this.kleur = this.contract.color
        }
    }
}
</script>