<template>
  <b-modal id="BSCreditInvoiceID" centered size="lg" @show="clearlocal">
    <template #modal-header>
      <h2 class="modal-title">{{ $t("buttons.addcreditinvoice") }}
      {{ username_by_id(user_id)}}</h2>
    </template>
     <form class="form">
      <div class="form-row">
        <div class="col-3">
          <label for=""> {{ $t("labels.invoice_number") }}</label>
        </div>
        <div class="col">
          <v-select :options="userinvoices" label="pulldownname" v-model="selectedinvoice"></v-select>

        </div>
      </div>
     <div class="form-row"  v-if="selectedinvoice" >
        <div class="col-3">
          <table>
            <tr><td><strong>{{ $t('labels.week') }}</strong></td></tr><tr><td>{{ selectedinvoice.week }}</td></tr>
            <tr><td><strong>{{ $t('tableheaders.project_name') }}</strong></td></tr><tr><td>{{ planning_by_id(contract_by_id(selectedinvoice.contract_id).planning_id).project_name }}</td></tr>
            <tr><td><strong>{{ $t('tableheaders.company_id') }}</strong></td></tr><tr><td>{{ company_by_id(project_by_id(contract_by_id(selectedinvoice.contract_id).project_id).company_id).company_name }}</td></tr>
            <tr><td><strong>{{ $t('labels.rate') }}</strong></td></tr><tr><td>{{ planning_by_id(contract_by_id(selectedinvoice.contract_id).planning_id).tarief }}</td></tr>
            <tr><td><strong>{{ $t('labels.ratesell') }}</strong></td></tr><tr><td>{{ planning_by_id(contract_by_id(selectedinvoice.contract_id).planning_id).verkooptarief }}</td></tr>
            <tr v-if="parseInt(selectedinvoice.hourcode)"><td><strong>{{ $t('labels.hourcategoryname') }}</strong></td></tr><tr><td>{{ this.hourcode_by_id(selectedinvoice.hourcode)?this.hourcode_by_id(selectedinvoice.hourcode).name:""}}</td></tr>
          </table>
        </div>
        <div v-html="hoursoverviewHTML(selectedinvoice.contract_id, weekyearstring)"> </div>
      </div>
       <div class="form-row"  v-if="selectedinvoice">
          <div class="col-3">
            <label for=""> {{ $t("buttons.removehours") }}</label>
          </div>
          <div class="col">
            <b-form-input type="number" class="form-control"  v-model="credithours"  />
          </div>
        </div>
        <div class="form-row"  v-for="(deccat, index) in contract_declarations" :key="index">
          <div class="col-3" v-if="deccat.active=='1'">
            <label for=""> {{declaratie_by_id(deccat.declaratie_id).name}}</label>
          </div>
          <div class="col" v-if="deccat.active=='1'">
            <b-form-input type="number" class="form-control"  v-model="deccat.hours"  />
          </div>
        </div>       
        <div class="form-row"  v-if="selectedinvoice">
          <div class="col-3">
            <label for=""> {{ $t("meta.remarks") }}</label>
          </div>
          <div class="col">
            <b-textarea v-model="remarks"></b-textarea>
          </div>
        </div>       
    </form> 

    <template #modal-footer>
      <div @click="saveData" class="btn btn-primary">
       {{ $t("buttons.saveandclose") }}
      </div>
    </template>
  </b-modal>
</template>


<script>
import axios from 'axios'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'invoice-data-modal',
  data() {
    return {
      selectedinvoice:null,
      credithours:0,
      values:[],
      remarks:null

    }
  },
  props: {
    user_id: Number,
  },
  
  computed: {
    ...mapState([
      'facturen'
    ]),
    ...mapGetters([
      'user_metas',
      'user_by_id',
      'username_by_id',
      'contract_by_id',
      'project_by_id',
      'company_by_id',
      'planning_by_id',
      'declaratie_by_id',

    ]),    
    user: {
      get() {
        return this.user_by_id(this.user_id)
      },
      
    },
    contract_declarations() {
      if(this.selectedinvoice && this.contract_by_id(this.selectedinvoice.contract_id)){
        return this.contract_by_id(this.selectedinvoice.contract_id).onregelmatig
      }
      return [];
    },
    weekyearstring(){
      if(this.selectedinvoice){
        const zeroPad = (num, places) => String(num).padStart(places, '0')

        return zeroPad(this.selectedinvoice.week,2)+"-"+this.year

      }
      return  ""
    },
    year(){
      return "20"+this.selectedinvoice.facnumverkoop.substring(1,3)
    },
    userinvoices(){
      return this.facturen
      .filter(f=>{
        if(f.contract_id && this.contract_by_id(f.contract_id)){
          f.pulldownname = f.facnuminkoop+" Week "+f.week
          return this.contract_by_id(f.contract_id).user_id == this.user_id
        } else{
          return false
        }
      }
      )
    }
  },
  methods: {
    ...mapActions([
      'add_factuur'
    ]),    
    saveData(){
      // clone project + contract and add minus hours on first day of contract
      let contract = this.contract_by_id(this.selectedinvoice.contract_id)
      // first clone project
      let project_clone = Object.assign({}, this.project_by_id(this.contract_by_id(this.selectedinvoice.contract_id).project_id))
      project_clone.id = 0;
      project_clone.title = "(CREDIT) week "+this.selectedinvoice.week+" - " + project_clone.title
      project_clone.is_public = 0
      project_clone.projectnumber = ""
      let weekdates = this.getWeekDates(this.getWeekNumbervalue(new Date()), new Date().getFullYear())
      project_clone.startdate = weekdates[0]
      project_clone.enddate = weekdates[4]
      console.log(contract)
      console.log(project_clone)
      var vm = this
      axios.put("/api/project/" + project_clone.id, project_clone).then(async (response) => {
        let project_id = response.data.id
        //add user to project
        var datatosend = { project_id: project_id, user_id: this.user.id }
        this.$store.dispatch('save_project_relations', datatosend);
        //create contract    
        let clone = Object.assign({}, this.planning_by_id(this.contract_by_id(vm.selectedinvoice.contract_id).planning_id))
        clone.id = 0;
        clone.project_name = "(CREDIT) week "+this.selectedinvoice.week+" - " + clone.project_name
        clone.contract_id = 0
        clone.project_id = project_id
        clone.startdate = weekdates[0]
        clone.enddate = weekdates[4]   
        clone.extrainfoinvoice = this.remarks     
        clone.registerdeclarations = this.contract_by_id(this.selectedinvoice.contract_id).registerdeclarations
        clone.registernormalhours = this.contract_by_id(this.selectedinvoice.contract_id).registernormalhours
        console.log("><><", clone)
        ///call axois
        await axios.put("/api/planning/" + clone.id, clone).then(async (response) => {
          let last_contract_id = response.data.contract_id
          clone.id = response.data.id
          await axios.post("/api/set_contract_color/", { id: last_contract_id, color: contract.contract_color })
          //add declarations categories
          for (const decla of this.contract_declarations) {
            decla.contract_id = last_contract_id
            decla.zzper = decla.zzpr_amount
            decla.client = decla.client_amount
            if(decla.active==1){
              console.log("decla",decla)
              await axios.put("/api/onregelmatig/", decla)
            }
          }
          // add hours (minus) to first date 
          var newhours = {
            id: 0,
            user_id: this.user_id,
            contract_id: last_contract_id,
            workdate: weekdates[0],
            added_date: new Date().toUTCString(),
            hours: -this.credithours,
            comment: "Credit from invoice "+this.selectedinvoice.facnuminkoop,
            hourcode: this.selectedinvoice.hourcode,
            agree_date: new Date().toUTCString(),
            close_hours: 1
          }
          for (const item of this.contract_declarations) {
            if (item.active == 1) {
              newhours[this.declaratie_by_id(item.declaratie_id).name] = item.hours==null?0:-item.hours
            }
            item.hours = null;
          }   
          // console.log(">newhours", newhours)
          axios.put("/api/hours/" + newhours.id, newhours).then(() => {
            // console.log("hours created", response)
            //hours added, create invoice
            var newFact = { id: 0, week: this.getWeekNumbervalue(new Date()), contract_id: last_contract_id, user_id: this.user_id }
            // console.log(newFact);

            this.add_factuur(newFact)
            this.$store.dispatch('get_planning');
            this.$store.dispatch('get_contracts');
            this.$store.dispatch('get_projects');
            this.$store.dispatch('get_facturen');
            this.$store.dispatch('get_users');
          })



        })
      })      
     this.close_modal()
     
    },

    close_modal() {
      this.$bvModal.hide('BSCreditInvoiceID')
    },
    clearlocal(){
      this.selectedinvoice = null
      this.credithours = 0
    },    
  },

}    
</script>
<style>
table {
  font-size: small;
}
td {
  padding-left:10px;
  padding-right: 10px;
}
</style>
