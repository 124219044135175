<template>
  <div class="booked-hours" :title="hourdata.comment" :style= "AppSecondaryColor()" >
    <div class="comment" v-if="hourdata.comment && createAny('users')">
      <font-awesome-icon icon="comment"/>
      {{hourdata.comment}}
    </div>
    <dl v-if="shownormalhours">
      <dt>{{$t("titles.project_hours")}}</dt>
      <dd>{{hourdata.hours}}</dd>
    </dl>

    <dl v-for="(decitem,decindex) in hourdata.declaraties" :key="decindex">
      <dt v-if="decitem.value!=0">{{decitem.label}}</dt>
      <dd v-if="decitem.value!=0">{{decitem.value}}</dd>
    </dl>
    <dl v-if="hourcatname(hourdata.project)">
      <dt>
        {{$t("labels.hourcategoryname")}}
      </dt>
      <dd>
        {{hourcatname(hourdata.project)}}
      </dd>
    </dl>
    <dl v-if="hourdata.free1">
      <dt>
        {{planning_by_id(contract_by_id(hourdata.contract_id).planning_id).label1}}
      </dt>
      <dd>
        {{hourdata.free1}}
      </dd>
    </dl>
    <dl v-if="hourdata.free2">
      <dt>
        {{planning_by_id(contract_by_id(hourdata.contract_id).planning_id).label2}}
      </dt>
      <dd>
        {{hourdata.free2}}
      </dd>
    </dl>    
    <b-button :style= "AppPrimaryColor()" variant="link" v-if="!issigned()" @click="trashhours"><font-awesome-icon icon="trash"/>{{removehourslabel}}</b-button>
    <b-button :style= "AppPrimaryColor()" variant="link" v-if="showEdit" @click="clickHour"><font-awesome-icon icon="pen"/>{{$t("labels.edit")}}</b-button>
    <b-button :style= "AppPrimaryColor()" variant="link" v-if="confirm" @click="removehours"><font-awesome-icon icon="check" color="green" />{{removehourslabel}}</b-button>
    <b-button :style= "AppPrimaryColor()" variant="link" v-if="confirm" @click="canceltrash"><font-awesome-icon  icon="times"  color="red" />{{$t("buttons.cancel")}}</b-button>
    <b-button :style= "AppPrimaryColor()" variant="link" v-if="hourdata.agree_company==1"><font-awesome-icon icon="check" color="green" />{{$t("labels.hourschecked")}}</b-button>

  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    name: 'filled_hours',
    data() {
        return {
          confirm:false
        }
    },
    props: {
        hourdata: Object,
        showEdit:Boolean
        
    },
    computed:{
        ...mapState([
            'hourcodes',
            'hours'
        ]),
        ...mapGetters(['hourcode_by_id',
        'createAny']),
      contract(){
        return this.contract_by_id(this.hourdata.contract_id)
      },
      shownormalhours(){
        if(this.contract && this.contract.registernormalhours!=undefined && this.contract.registernormalhours=='no'){
          return false
        } 
        return true
      },     
      removehourslabel(){
      var r = this.$i18n.t('buttons.removehours')
      if(!this.shownormalhours){
        r =this.$i18n.t('labels.delete')
      } 
      return r    
    }         
    },
    methods:{
      ...mapActions([
        'delete_hours',
      ]),      
      hourcatname(id){
        var findhourCode = this.hourcodes.filter(fhc => {
            return fhc.id == id        
        })
        var returnlabel=""
        var hc = findhourCode[0]

        if (id && findhourCode.length>0) {
          if(hc.parent==-1){
            returnlabel = hc.code+" - "+hc.name
          }else{
            let parent = this.hourcode_by_id(hc.parent);
            returnlabel = parent.code+"."+hc.code+" - "+"["+parent.name+"]-"+hc.name
          }
        }
        return returnlabel

      },
    clickHour(){
      this.$emit('clickHour', this.hourdata)
      },
    trashhours(){
      this.confirm=true
    },
    canceltrash(){
      this.confirm=false
    },
    issigned(){
      return this.hourdata.agree_company==1 || this.hourdata.close_hours==1
    },
    removehours(){
      this.delete_hours(this.hourdata.id)
      for(let i=0;i<this.hours.length;i++){
          if(this.hours[i].id==this.hourdata.id){
              this.hours.splice(i,1);
          }
      }        
    }
  }
}
</script>
