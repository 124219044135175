<template>
	<div class="popup">
        <div class="popup-header"  @click="$emit('close')">
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>  
             <p v-if="token==null">
              {{ $t("labels.requestpasswordreset")}}
            </p>
            <p v-else-if="token=='recieved'">
              {{ $t("validation.tokenreceivedok")}}
            </p>
            <p v-if="token=='validated'" >
              {{ $t("labels.choosepassword")}}
            </p>

        </div>
        <div class="popup-body">
            <b-container>
                <div v-if="token==null" class="form-row" >
                    <div class="col-3">
                        <label for="">{{ $t("labels.email") }}</label>
                    </div>
                    <div class="col">
                        <input  type="text" class="form-control" v-model="reqemail">
                    </div>
                </div>
                <div v-if="token==null && smsEnabled()" class="form-row" >
                    <div class="col-3"/>
                    <div class="col">
                        <b-form-checkbox v-model="sms" name="checkbox-1" value="1" unchecked-value="0">
                        {{ $t('meta.sms')}}
                        </b-form-checkbox>
                    </div>
                </div>                
                <div v-if="false &&token=='recieved'" class="form-row" >
                    <div class="col-3">
                        <label for="">{{ $t("labels.tokencode") }}</label>
                    </div>
                    <div class="col">
                        <b-form-input :type="text" v-model="tokencode"  />
                    </div>
                </div>     
                <div v-if="token=='validated'" class="form-row" >
                    <div class="col-3">
                        <label for="">{{ $t("labels.password") }}</label>
                    </div>
                    <div class="col">
                        <b-input-group class="mt-3">
                            <b-form-input :type="pwtype" v-model="newpw" :state="passwordcheck"  placeholder="" />
                            <b-input-group-append>
                                <b-button class="multi-append"  href="javascript:;" @click="reveal()"><font-awesome-icon :icon = btnText /></b-button>
                            </b-input-group-append>
                                <b-form-invalid-feedback >
                                    {{ $t("validation.password")}}
                                </b-form-invalid-feedback>         
                        </b-input-group>
                    </div>
                </div>  
                <div v-if="token=='validated'" class="form-row" >
                    <div class="col-3">
                        <label for="">{{ $t("labels.passwordrepeat") }}</label>
                    </div>
                    <div class="col">
                        <b-form-input  :state="passwordequal" type="password" class="form-control" v-model="newpw2"/>
                    </div>
                </div>                                           
            </b-container>
        </div>
        <div class="popup-footer">
            <p>{{statustext}}</p>
            <div v-if="token==null"  @click="sendrequest" :style="AppPrimaryButton()" class="btn btn-primary">{{ $t("buttons.sendrequest") }}</div>
            <div v-if="false && token=='recieved'"  @click="validatetoken" class="btn btn-primary">{{ $t("labels.confirm") }}</div>
            <div v-if="passwordequal"  @click="savenewpass" class="btn btn-primary">{{ $t("labels.confirmpass") }}</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'password_reset_request',
    data() {
        return {
          reqemail:null,
          token:null,
          tokencode:null,
          statustext:"",
          newpw:null,
          newpw2:null,
          pwtype:"password",
          btnText : 'eye',
          user_id:null,
          sms:"0"

        }
    },
    mounted() {
        if(this.isMobile && this.smsEnabled()){
            this.sms="1"
        }

    },

    computed: {
        passwordcheck(){
            return this.newpw!=undefined &&this.newpw.length>5;
        },     
        passwordequal(){
            return this.newpw!=undefined && this.newpw==this.newpw2
        }   
    },
    methods: {
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        sendrequest() {
            const formData = new FormData();
            formData.append('email', this.reqemail);            
            formData.append('sms', this.sms);            
            try{
                axios.post('/api/user/passwordrequest', formData)  .then((response) => {
                if(response.data.status=='email sent'){
                    this.user_id = response.data.user_id
                    this.token="recieved"
                    this.statustext=this.$t('validation.tokenreceivedok');
                    setTimeout(()=>{
                        this.$emit('close');
                    }, 3000)

                } else {
                    this.statustext=this.$t('validation.tokenreceivednotok');
                
                }
            })
            } catch(err){
                console.log (err)
            }
        },
        validatetoken(){
            axios.post('/api/user/checktoken', {token:this.tokencode,user_id:this.user_id})  .then((response) => {
                if(response.data.status==1){
                    this.token="validated"
                    this.statustext="Enter new password"
                }else{
                    this.statustext=response.data.error
                }
            })
        },
        reveal(){
            if(this.pwtype === 'password') {
                this.pwtype = 'text'
                this.btnText = 'eye-slash'
            } else {
                this.pwtype = 'password'
                this.btnText = 'eye'
            }
        },    
        savenewpass(){
            axios.post('/api/user/changepassword',{token:this.tokencode,user_id:this.user_id,newpassword:this.newpw})
            .then((response) => {
                if(response.status!=-1){
                    this.$emit('close');
                }
            })
        }    
           
    }
}
</script>