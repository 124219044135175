
<template>
	<div class="popup">

        <div class="popup-header">
            <div >
              <h2>{{ $t("buttons.addnewsletter") }}</h2>
            </div>
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
          <b-container>
            <div class="form-row">
                <div class="col-3">
                    <label for=""> {{ $t("labels.name") }}</label>
                </div>
                <div class="col">
                    <b-form-input type="text" class="form-control" v-model="name" v-on:keypress="isLetter($event)"  @blur="removeInvalidChars()"  />              
                </div>
            </div>    
            <div class="form-row">
                <div class="col-3">
                    <label for=""> {{ $t("labels.file") }}</label>
                </div>
                <div class="col">
                    <b-form-file ref="file-input" class="form-control" accept="*/*" v-model="file1" :state="Boolean(file1)" />
                </div>
                
            </div> 
          </b-container>
        </div>
        <div class="popup-footer">
            <div @click="sendFile" class="btn btn-primary"><font-awesome-icon icon="plus" /> {{ $t("buttons.addnewsletter") }}</div>
        </div>
    </div>          
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import axios from 'axios'

export default {
    name: 'addnewsletterscreen',
    data() {
        return {
          name:"",
          file1:null,
        }
    },
    props: {
        company_id: Number
    },
    computed: {
        company() {
            return this.company_id > 0 ? this.company_by_id(this.company_id) : this.company_new
        },
        ...mapGetters([
            'user_metas',
            'company_by_id',
            'activebranches',
            'user_new',
            'server_url'
        ]),
        ...mapState([
            'users',
            'branches',
            'avatars'
        ]),   
        
    },
    methods: {
      sendFile(){
        const formData = new FormData();
        formData.append('name', this.name.replace(/[^ a-zA-Z0-9 -]/g, ''));
        formData.append('file', this.file1)
        let adoc={}
        for (var pair of formData.entries()) {
            adoc[pair[0]] = pair[1]
        }  
        try{
          axios.post('/api/newsletter/', formData)  .then((response) => {
              console.log(response)
              this.close_modal();        
          })
              this.close_modal();        

        } catch(err){
          console.log (err)
        }
      },
        close_modal() {
            this.$emit('close')
        } ,      
        ...mapActions([
            'update_user_document'
        ]),
        isLetter(e) {
            this.name = this.name.replace(/[^ a-zA-Z0-9 -]/g, '')
            let char = String.fromCharCode(e.keyCode); 
            if(/^[A-Za-z0-9 -]+$/.test(char)) return true;
            else e.preventDefault(); 
        },
        removeInvalidChars() {
            this.name = this.name.replace(/\//g,'')
        }        
    },
}    
</script>
