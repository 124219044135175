<template>
  <div v-if="waxes.length>0">
    <h3>{{ $t('labels.resourcealreadyplanned') }}</h3>
    <div class="row" v-for="(p, idx) in waxes" :key="idx" >
      <div class="col">{{ project_by_id(p.project_id).title }}</div>
      <div class="col">{{dateInfo(p)}}</div>
      <div class="col">{{p.start_time }} - {{p.end_time }} </div>
    </div>
  </div>
</template>
<script>
import { mapGetters,mapState } from 'vuex';

export default {
  name: 'check_resource_planning',
	data() {
		return {
		}
	},  
  props: {
    resource_id: Number,
    startdate: String,
    enddate: String
  },
  computed: {
    thisisme() {
      return this.resource_by_id(this.resource_id).firstname
    },
    ...mapGetters([    
    'resource_by_id',
    'project_by_id',
    ]),
    ...mapState ([
      'ffwax',
    ]),  
    waxes() {
      console.log("waxes ", this.startdate, this.enddate)
      return this.ffwax.filter(f => f.resource_id == this.resource_id)
      .filter(ff => {
        let gendday = new Date(this.enddate)
        let startday = new Date(this.startdate)
        let startdate = new Date(this.project_by_id(ff.project_id).startdate)
        startdate.setDate(startdate.getDate() + ff.start_offset);
        let enddate = new Date(startdate)
        enddate.setDate(enddate.getDate() + ff.end_offset);
        return this.dateRangeOverlaps(startday, gendday, startdate, enddate)

      })
  
    },    
    
  },
  methods: {
    dateInfo(p){
      let sd = new Date(this.project_by_id(p.project_id).startdate)
      let enddate = new Date(sd)
      enddate.setDate(enddate.getDate() + p.end_offset);
      return this.dateToNL(sd) +" - "+this.dateToNL(enddate)
    }
  }
}
</script>