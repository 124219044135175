
<template>
    <b-container>
        <b-table :items=this.user.documents :fields=fields>
            <template #cell(link)="row">
                <a :href=docto(row.value) v-bind:target="'_blank'">{{row.item.name}}</a>
            </template>
            <template #cell(actions)="row">
                <a href="javascript:;" class="action-item" @click="delete_file(row.item.id)">
                    <font-awesome-icon icon="trash" />
                </a>
                <a href="javascript:;" class="action-item" @click="edit_file(row.item.id)">
                    <font-awesome-icon icon="cog" />
                </a>
            </template>
        </b-table>
        <div class="form-row">
            <div class="col-6">
                <div @click="showAddDocument" class="btn btn-primary">
                    <font-awesome-icon icon="plus" /> {{ $t("buttons.adddocument") }}
                </div>
            </div>
        </div>
    </b-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import docdetails from '../DocumentModal'
import adduserdocumentssscreen from '../AddDocumentModal.vue'

export default {
    name: 'userdocumentssscreen',
    data() {
        return {
            name: "",
            expires: null,
            file1: null
        }
    },
    props: {
        user_id: Number
    },
    computed: {
        fields() {
            return [
                { key: 'name', label: this.$i18n.t('labels.name'), sortable: true, sortDirection: 'desc' },
                { key: 'expires', formatter: this.formatDate, label: this.$i18n.t('labels.expirationdate') },
                { key: 'link', label: this.$i18n.t('labels.link') },
                { key: 'actions', label: this.$i18n.t('labels.actions') },
            ]
        },
        user() {
            return this.user_id > 0 ? this.user_by_id(this.user_id) : this.user_new
        },
        ...mapGetters([
            'user_metas',
            'user_by_id',
            'activebranches',
            'user_new',
            'server_url'
        ]),
        ...mapState([
            'users',
            'branches',
            'avatars'
        ]),

    },
    methods: {
        docto(id) {
            return this.server_url + '/media_' + id;
        },
        showAddDocument() {
            this.$modal.hide('adduserdocumentssscreen')
            this.$modal.show(adduserdocumentssscreen, {
                user_id: this.user_id
            }, {
                name: 'adduserdocumentssscreen',
                draggable: '.popup-header',
                height: 'auto',
                width: '600px'
            })
            this.$emit('close')
        },

        delete_file(id) {
            for (let i = 0; i < this.user.documents.length; i++) {
                if (this.user.documents[i].id == id) {
                    let id = this.user.documents[i].id
                    this.delete_user_document(id)
                    this.user.documents.splice(i, 1)
                }
            }

        },
        edit_file(id) {
            for (let i = 0; i < this.user.documents.length; i++) {
                if (this.user.documents[i].id == id) {
                    let docname = this.user.documents[i].name
                    let docdate = this.user.documents[i].expires
                    this.$modal.hide('docdetails')
                    this.$modal.show(docdetails, {
                        document_name: docname,
                        document_date: docdate,
                        document_id: i,
                        document_required:-1

                    }, {
                        name: 'docdetails',
                        draggable: '.popup-header',
                        height: 'auto',
                        width: '600px'
                    }, {
                        'before-close': this.modalClose
                    })
                    this.$emit('close')

                }
            }
        },
        modalClose(e) {
            let newname = e.params["dname"]
            let newdate = e.params["ddate"]
            let docid = e.params["id"]
            this.user.documents[docid].name = newname
            this.user.documents[docid].expires = newdate
            var datatosend = { document_id: this.user.documents[docid].id, name: newname, expire: newdate }
            this.$store.dispatch('update_user_document', datatosend);
            //check if we need to change enddate of id, vca, kvk or GPI
            if (this.user.documents[docid].link.search(this.user_id + "-ID-") != -1) {
                this.user["identification_endate"] = newdate
            }
            if (this.user.documents[docid].link.search(this.user_id + "-GPI-") != -1) {
                this.user["gpi_enddate"] = newdate

            }
            if (this.user.documents[docid].link.search(this.user_id + "-KvK-") != -1) {
                this.user["kvk_enddate"] = newdate
            }
            if (this.user.documents[docid].link.search(this.user_id + "-vca-") != -1) {
                this.user["vca_endate"] = newdate
            }
        },
        ...mapActions([
            'save_avatar',
            'delete_user_document',
            'update_user_document'
        ]),



    }

}    
</script>
