import Vue from 'vue'

Vue.mixin({
  data() {
    return {
      feestdagen:[],
      generated_contract_hours:[]
    };
  },
  created() {
    let now = new Date()
    let year = now.getFullYear()
    var easter = this.easter(year)
    this.feestdagen.push({"name":"Nieuwjaarsdag "+year, "date": this.dateToNL(new Date("01-01-"+year))})
    this.feestdagen.push({"name":"Koningsdag "+year, "date": this.dateToNL(new Date("04-27-"+year))})
    // this.feestdagen.push({"name":"Bevrijdingdag "+year, "date": this.dateToNL(new Date("05-05-"+year))})
    this.feestdagen.push({"name":"Eerste kerstdag "+year, "date": this.dateToNL(new Date("12-25-"+year))})
    this.feestdagen.push({"name":"Tweede kerstdag "+year, "date": this.dateToNL(new Date("12-26-"+year))})
    this.feestdagen.push({"name":"Eerste paasdag "+year, "date": this.dateToNL(easter)})
    this.feestdagen.push({"name":"Tweede paasdag "+year, "date": this.dateToNL(easter.getTime()+(24*60*60*1000))})
    this.feestdagen.push({"name":"Hemelvaartsdag "+year, "date": this.dateToNL(easter.getTime()+(39*24*60*60*1000))})
    this.feestdagen.push({"name":"Pinksteren "+year, "date": this.dateToNL(easter.getTime()+(49*24*60*60*1000))})
    this.feestdagen.push({"name":"Pinkstermaandag "+year, "date": this.dateToNL(easter.getTime()+(50*24*60*60*1000))})
    // add next year
    year ++
    easter = this.easter(year)
    this.feestdagen.push({"name":"Nieuwjaarsdag "+year, "date": this.dateToNL(new Date("01-01-"+year))})
    this.feestdagen.push({"name":"Koningsdag "+year, "date": this.dateToNL(new Date("04-27-"+year))})
    // this.feestdagen.push({"name":"Bevrijdingdag "+year, "date": this.dateToNL(new Date("05-05-"+year))})
    this.feestdagen.push({"name":"Eerste kerstdag "+year, "date": this.dateToNL(new Date("12-25-"+year))})
    this.feestdagen.push({"name":"Tweede kerstdag "+year, "date": this.dateToNL(new Date("12-26-"+year))})
    this.feestdagen.push({"name":"Eerste paasdag "+year, "date": this.dateToNL(easter)})
    this.feestdagen.push({"name":"Tweede paasdag "+year, "date": this.dateToNL(easter.getTime()+(24*60*60*1000))})
    this.feestdagen.push({"name":"Hemelvaartsdag "+year, "date": this.dateToNL(easter.getTime()+(39*24*60*60*1000))})
    this.feestdagen.push({"name":"Pinksteren "+year, "date": this.dateToNL(easter.getTime()+(49*24*60*60*1000))})
    this.feestdagen.push({"name":"Pinkstermaandag "+year, "date": this.dateToNL(easter.getTime()+(50*24*60*60*1000))})
  },
  methods:{
    easter(Y) {
      var C = Math.floor(Y/100);
      var N = Y - 19*Math.floor(Y/19);
      var K = Math.floor((C - 17)/25);
      var I = C - Math.floor(C/4) - Math.floor((C - K)/3) + 19*N + 15;
      I = I - 30*Math.floor((I/30));
      I = I - Math.floor(I/28)*(1 - Math.floor(I/28)*Math.floor(29/(I + 1))*Math.floor((21 - N)/11));
      var J = Y + Math.floor(Y/4) + I + 2 - C + Math.floor(C/4);
      J = J - 7*Math.floor(J/7);
      var L = I - J;
      var M = 3 + Math.floor((L + 40)/44);
      var D = L + 28 - 31*Math.floor(M/4);
      return new Date(this.padout(M) + '-' + this.padout(D)+ '-' + Y);
    },
    padout(number) { return (number < 10) ? '0' + number : number; },

    feestdag(fdate){
      let day  = this.feestdagen.filter(fd => fd.date == fdate)
      if(day.length>0){
        return day[0].name
      } else{
        return ""
      }
    }
  },    
})



// // Geen parameter? Doen we gewoon dit jaar toch.
// $jaar = date('Y');

// ini_set('date.timezone', 'Europe/Brussels');
// $formatter = new \IntlDateFormatter('nl_BE', null, null);
// $formatter->setPattern('E dd LLL Y');

// $format = function(\DateTime $datum, $omschrijving) use ($formatter) {
//     $formattedDatum = $formatter->format($datum);
//     if (\strlen($formattedDatum) === 14) {
//         $formattedDatum = \substr($formattedDatum, 0, 10) . ' ' . \substr($formattedDatum, -4);
//     }

//     return "$formattedDatum : $omschrijving\n";
// };

// /* ***** Voor Nederland ***************************************************** */

// // vaste dagen
// $nieuwjaar      = new \DateTime("$jaar-01-01");
// $bevrijdingsdag = new \DateTime("$jaar-05-05");
// $kerstmis       = new \DateTime("$jaar-12-25");
// $tweedekerstdag = new \DateTime("$jaar-12-26");

// $koningsdag = new \DateTime("$jaar-04-27");
// // Als Koningsdag op zondag valt is het de dag ervoor
// if ($koningsdag->format('w') === '0') {
//     $koningsdag->sub(new DateInterval('P1D'));
// }

// // berekend
// $pasen = new \DateTime();
// $pasen->setTimestamp(\easter_date($jaar)); // bedankt PHP
// $paasMaandag = clone $pasen;
// $paasMaandag->add(new \DateInterVal('P1D')); // 1 dag na pasen
// $goedeVrijdag = clone $pasen;
// $goedeVrijdag->sub(new \DateInterval('P2D')); // 2 dag voor pasen
// $olhHemelvaart = clone $pasen;
// $olhHemelvaart->add(new \DateInterVal('P39D')); // 39 dagen na pasen
// $pinksteren = clone $olhHemelvaart;
// $pinksteren->add(new \DateInterVal('P10D')); // 10 dagen na OLH hemelvaart
// $pinksterMaandag = clone $pinksteren;
// $pinksterMaandag->add(new \DateInterVal('P1D')); // 1 dag na pinksteren

// echo "\nFeestdagen Nederland $jaar\n--------------------------\n\n" . 
//     $format($nieuwjaar, 'Nieuwjaar') .
//     $format($goedeVrijdag, 'Goede vrijdag') .
//     $format($pasen, 'Pasen') .
//     $format($paasMaandag, 'Paasmaandag') .
//     $format($koningsdag, 'Koningsdag') .
//     $format($bevrijdingsdag, 'Bevrijdingsdag') .
//     $format($olhHemelvaart, 'O.L.H. Hemelvaart') .
//     $format($pinksteren, 'Pinksteren') .
//     $format($pinksterMaandag, 'Pinkstermaandag') .
//     $format($kerstmis, 'Kerstmis') .
//     $format($tweedekerstdag, '2e Kerstdag') .
//     "\n";