<template>
    <b-container fluid>
        <input type="hidden" :key="distanceResult" />
        <input type="hidden" v-model="componentKey">
           <template v-if="current_user.role=='admin'">
            <div class="title-bar">
                <h1>{{ $t("labels.search") }} ({{this.totalRows}})</h1>
                <div @click="resetSettings()" class="btn btn-primary">
                      <font-awesome-icon icon="undo" /> {{ $t("buttons.reset") }}
                        </div>

            </div>
            <b-tabs v-model="search_settings.tabIndex">
                <b-tab>
                    <template v-slot:title>{{ $t("titles.users") }}</template>

                    <b-row class="filter-bar">
                        <b-col md="4" xl>
                            <b-form-group :label="$t('labels.newsletter')">
                                <b-form-radio v-model="search_settings.nieuwsbrief" value="0" >
                                    {{$t('labels.doesnotmatter')}}</b-form-radio>
                                <b-form-radio v-model="search_settings.nieuwsbrief"  value="1" >
                                    {{$t('labels.yes')}}</b-form-radio>
                            </b-form-group>
                            <b-form-group :label="$t('labels.role')">
                            <b-form-select v-model="search_settings.currentrole" class="form-control">
                                <option value="-1"> {{ $t("option.all") }}</option>
                                <option value="user"> {{ $t("option.zzpr") }}</option>
                                <option value="contactpersoon">{{ $t("option.contactperson") }}</option>
                                <!-- <option value="opdrachtgever">{{ $t("option.client") }}</option> -->
                                <option value="admin">{{ $t("option.admin") }}</option>
                            </b-form-select>
                        </b-form-group>                        
                        </b-col>
                        <b-col md="4" xl>
                            <b-form-group :label="$t('meta.waardering')">
                                <b-form-checkbox v-model="search_settings.waardering" name="checkbox-1" value="0" unchecked-value="0">Geen
                                </b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.waardering" name="checkbox-1" value="1" unchecked-value="1">*
                                </b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.waardering" name="checkbox-1" value="2" unchecked-value="0">**
                                </b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.waardering" name="checkbox-1" value="3" unchecked-value="0">***
                                </b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.waardering" name="checkbox-1" value="4" unchecked-value="0">****
                                </b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.waardering" name="checkbox-1" value="5" unchecked-value="0">*****
                                </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col md="4" xl>
                            <b-form-group :label="$t('meta.extra')">
                                <b-form-checkbox v-model="search_settings.status" name="checkbox-1" value="0" unchecked-value="0">
                                    {{$t('labels.statusactive')}}</b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.status" name="checkbox-1" value=1 unchecked-value="1">
                                    {{$t('labels.statusinactive')}}</b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.status" name="checkbox-1" value=2 unchecked-value="0">
                                    {{$t('labels.statusinactive2')}}</b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.status" name="checkbox-1" value=3 unchecked-value="0">
                                    {{$t('labels.statusinactive3')}}</b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.status" name="checkbox-1" value=4 unchecked-value="0">
                                    {{$t('labels.statusinactive4')}}</b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.status" name="checkbox-1" value=5 uunchecked-value="0">
                                    {{$t('labels.statusinactive5')}}</b-form-checkbox>
                                <b-form-checkbox v-if="settings.EnableBlacklist" v-model="search_settings.status" name="checkbox-1" value=6 uunchecked-value="0">
                                    {{$t('labels.statusblacklist')}}</b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col md="4" xl>
                            <b-form-group label="Verlopen Documenten">
                                <b-form-checkbox v-model="search_settings.docKVK" name="checkbox-1" value="1" unchecked-value="0">
                                    {{$t('labels.kvk')}}</b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.docGPI" name="checkbox-1" value="1" unchecked-value="0">
                                    {{$t('labels.gpi')}}</b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.docVCA" name="checkbox-1" value="1" unchecked-value="0">
                                    {{$t('labels.vca')}}</b-form-checkbox>
                                    <b-form-checkbox v-model="search_settings.docID" name="checkbox-1" value="1" unchecked-value="0">
                                    {{$t('labels.identificationcard')}}</b-form-checkbox>
                                    <b-form-checkbox v-model="search_settings.docAny" name="checkbox-1" value="1" unchecked-value="0">
                                    {{$t('labels.otherdoc')}}</b-form-checkbox>                            
                                    <b-form-checkbox v-if="false" v-model="search_settings.docBTW" name="checkbox-1" value="1" unchecked-value="0">
                                    {{$t('labels.vat')}}</b-form-checkbox>                            
                            </b-form-group>     
                            <label for="expireweek">Verloopt binnen (weken):</label>

                            <select v-model ="search_settings.expireWeeks" id="expireweek">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                            </select>               
                            <!-- <b-form-group :label="$t('labels.accountstatus')">
                                <b-form-checkbox v-model="accountstatus" name="checkbox-1" value="0" unchecked-value="0">
                                    {{$t('option.active')}}</b-form-checkbox>
                                <b-form-checkbox v-model="accountstatus" name="checkbox-1" value=1 unchecked-value="1">
                                    {{$t('option.inactive')}}</b-form-checkbox>
                            </b-form-group> -->
                        </b-col>
                    </b-row>

                    <b-row class="filter-bar">
                        <b-col md="4" xl>
                            <b-form-group :label="$t('labels.lastclient')">
                                {{$t('labels.branche')}}
                                <b-form-select name="branches" class="form-control" v-model="search_settings.currentbranche">
                                    <option value="-1"> {{ $t("option.all") }}</option>
                                    <option v-for="(branche,idx) in activebranches" :key="idx" v-bind:value="branche.id">
                                        {{ branche["name"]}}
                                    </option>
                                </b-form-select>
                                {{$t('labels.client')}}
                                <b-form-select :options="companies_in_branche" class="contactpersoon" v-model="search_settings.company">
                                </b-form-select>

                            </b-form-group>
                        </b-col>
                        <b-col md="4" xl>
                            <b-form-group :label="$t('labels.distance')">
                                {{$t('meta.zipcode')}} 
                                <b-form-input id="filter-input" v-model="search_settings.zipcode" @change="calcdistance"></b-form-input>
                                {{$t('labels.radius')}}
                                <b-form-input id="filter-input" v-model="search_settings.radius" type="number" @change="calcdistance"></b-form-input>
                            </b-form-group>
                            <b-button variant="primary" @click="calcdistance()">
                                <font-awesome-icon icon="location" /><font-awesome-icon icon="save" />
                            </b-button>
                        </b-col>
                        <b-col md="4" xl>
                            <b-form-group :label="$t('meta.geboortedatum')">
                                {{$t('labels.from')}}
                                <b-form-input id="filter-input" type="date" v-model="search_settings.dobfrom"></b-form-input>
                                {{$t('labels.to')}}
                                <b-form-input id="filter-input" v-model="search_settings.dobto" type="date"></b-form-input>
                            </b-form-group>
                            <b-button variant="primary" @click="direct_dob()">
                                <font-awesome-icon icon="clock" />{{ $t("buttons.direct") }}
                            </b-button>
                            <b-button variant="primary" @click="reset_dob()">
                                <font-awesome-icon icon="undo" />{{ $t("buttons.reset") }}
                            </b-button>

                        </b-col>
                        <b-col md="4" xl>
                            <b-form-group :label="$t('labels.call')">
                                {{$t('labels.from')}}
                                <b-form-input id="filter-input" type="date" v-model="search_settings.nabellenfrom"></b-form-input>
                                {{$t('labels.to')}}
                                <b-form-input id="filter-input" v-model="search_settings.nabellento" type="date"></b-form-input>
                            </b-form-group>
                            <b-button variant="primary" @click="direct_call()">
                                <font-awesome-icon icon="clock" />{{ $t("buttons.direct") }}
                            </b-button>
                            <b-button variant="primary" @click="reset_call()">
                                <font-awesome-icon icon="undo" />{{ $t("buttons.reset") }}
                            </b-button>

                        </b-col>
                    </b-row>
                    <b-row class="filter-bar">
                        <b-col md="4" xl>
                            <b-form-group :label="$t('labels.available')">
                                <b-form-radio v-model="search_settings.available_filter" name="checkbox-available" value="1"
                                    unchecked-value="0">{{$t('labels.available_filter1')}}</b-form-radio>
                            </b-form-group>
                            Inschrijfdatum
                            {{$t('labels.from')}}
                            <b-form-input id="filter-input" type="date" v-model="search_settings.signupdate_from"></b-form-input>
                            {{$t('labels.to')}}
                            <b-form-input id="filter-input" v-model="search_settings.signupdate_to" type="date"></b-form-input>                    
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('labels.available')">
                                <b-form-radio v-model="search_settings.available_filter" name="checkbox-available" value="2"
                                    unchecked-value="0">{{$t('labels.available_filter2')}}</b-form-radio>
                                {{$t('labels.from')}}
                                <b-form-input id="filter-input" type="date" v-model="search_settings.date_working_from"></b-form-input>
                                {{$t('labels.to')}}
                                <b-form-input id="filter-input" v-model="search_settings.date_working_to" type="date"></b-form-input>
                            </b-form-group>
                            <b-button variant="primary" @click="direct_worked()">
                                <font-awesome-icon icon="clock" />{{ $t("buttons.direct") }}
                            </b-button>
                            <b-button variant="primary" @click="reset_worked()">
                                <font-awesome-icon icon="undo" />{{ $t("buttons.reset") }}
                            </b-button>

                        </b-col>
                        <b-col md="4" xl>
                            <b-form-group :label="$t('labels.available')">
                                <b-form-radio v-model="search_settings.available_filter" name="checkbox-available" value="3"
                                    unchecked-value="0">{{$t('labels.available_filter3')}}</b-form-radio>
                                {{$t('labels.from')}}
                                <b-form-input id="filter-input" type="date" v-model="search_settings.date_available_from"></b-form-input>
                                {{$t('labels.to')}}
                                <b-form-input id="filter-input" v-model="search_settings.date_available_to" type="date"></b-form-input>
                            </b-form-group>
                            <b-button variant="primary" @click="direct_available()">
                                <font-awesome-icon icon="clock" />{{ $t("buttons.direct") }}
                            </b-button>
                            <b-button variant="primary" @click="reset_available()">
                                <font-awesome-icon icon="undo" />{{ $t("buttons.reset") }}
                            </b-button>

                        </b-col>
                    </b-row>
                    <b-row class="filter-bar">

                        <b-col md="4" xl>
                            <b-form-group :label="$t('labels.search')">
                                <b-form-input id="filter-input" v-model="search_settings.filter" type="search"
                                    :placeholder="$t('strings.typetosearch')"></b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col>
                            <b-form-group :label="$t('labels.addedby')">
                                <select name="admin" class="form-control admins" v-model="search_settings.owner">
                                    <option value="-1"> {{ $t("option.all") }}</option>

                                    <option v-for="(user,idx) in owners" :key="idx" v-bind:value="user.id">
                                        {{ user["firstname"]}} {{ user["middlename"] }} {{ user["lastname"] }}
                                    </option>
                                </select>
                            </b-form-group>
                        </b-col>

                    </b-row>
                    <b-row>
                        <strong>{{$t('titles.branches')}}</strong>
                        <b-col md="4" xl>
                            <b-form-radio v-model="search_settings.selected_branches" name="checkbox-1" value=0 unchecked-value="0">All
                            </b-form-radio>
                        </b-col>
                        <b-col v-for="(branche,idx) in activebranches" :key="idx">
                            <b-form-radio v-model="search_settings.selected_branches" name="checkbox-1" :value="branche.id" unchecked-value="0">
                                {{branche.name}}</b-form-radio>

                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col v-for="(branche,idx) in shownbranches" :key="idx">
                            {{branche.name}}<br />
                            <b-form-checkbox v-model="search_settings.selected_workfields"
                                v-for="(workfield, idx3) in active_workfields_in_branche(branche.id)" :key="idx3"
                                name="checkbox-1" :value="workfield.id" unchecked-value="0">{{workfield.name}}</b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-tab>
                <b-tab>
                    <template v-slot:title>{{ $t("titles.companies") }}</template>  
                    <b-row class="filter-bar">
                        <b-col>
                            <b-form-group :label="$t('meta.waardering')">
                                <b-form-checkbox v-model="search_settings.waardering" name="checkbox-1" value="0" unchecked-value="0">Geen
                                </b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.waardering" name="checkbox-1" value="1" unchecked-value="1">*
                                </b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.waardering" name="checkbox-1" value="2" unchecked-value="0">**
                                </b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.waardering" name="checkbox-1" value="3" unchecked-value="0">***
                                </b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.waardering" name="checkbox-1" value="4" unchecked-value="0">****
                                </b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.waardering" name="checkbox-1" value="5" unchecked-value="0">*****
                                </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('meta.extra')">
                                <b-form-checkbox v-model="search_settings.status" name="checkbox-1" value="0" unchecked-value="0">
                                    {{$t('labels.statusactive')}}</b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.status" name="checkbox-1" value=1 unchecked-value="1">
                                    {{$t('labels.statusinactive')}}</b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.status" name="checkbox-1" value=2 unchecked-value="0">
                                    {{$t('labels.statusinactive2')}}</b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.status" name="checkbox-1" value=3 unchecked-value="0">
                                    {{$t('labels.statusinactive3')}}</b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.status" name="checkbox-1" value=4 unchecked-value="0">
                                    {{$t('labels.statusinactive4')}}</b-form-checkbox>
                                <b-form-checkbox v-model="search_settings.status" name="checkbox-1" value=5 uunchecked-value="0">
                                    {{$t('labels.statusinactive5')}}</b-form-checkbox>
                                <b-form-checkbox v-if="settings.EnableBlacklist" v-model="search_settings.status" name="checkbox-1" value=6 uunchecked-value="0">
                                    {{$t('labels.statusblacklist')}}</b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col v-if="false">
                            <b-form-group :label="$t('labels.distance')">
                                {{$t('meta.zipcode')}}
                                <b-form-input id="filter-input" v-model="zipcode" @change="calcdistance()"></b-form-input>
                                {{$t('labels.radius')}}
                                <b-form-input id="filter-input" v-model="radius" type="number"  @change="calcdistance()"></b-form-input>
                            </b-form-group>
                            <b-button variant="primary" @click="calcdistance()">
                                <font-awesome-icon icon="location" />{{ $t("buttons.save") }}
                            </b-button>
                        </b-col>
                    </b-row>
                    <b-row class="filter-bar">
                        <b-col>
                            <b-form-group :label="$t('labels.role')">
                            <b-form-select v-model="search_settings.contactrole" class="form-control"  @change="setcontactrole()">
                                <option value="selectall"> {{ $t("buttons.selectall") }}</option>
                                <option value="company_contact"> {{ $t("labels.company_contact") }}</option>
                                <option value="location_contact">{{ $t("labels.location_contact") }}</option>
                                <!-- <option value="opdrachtgever">{{ $t("option.client") }}</option> -->
                                <option value="selectnone">{{ $t("buttons.selectnone") }}</option>
                            </b-form-select>
                            </b-form-group>
                        </b-col>

                        <b-col>
                            <b-form-group :label="$t('meta.geboortedatum')+' '+ $t('labels.contactpersons')">
                                {{$t('labels.from')}}
                                <b-form-input id="filter-input" type="date" v-model="search_settings.dobfrom"></b-form-input>
                                {{$t('labels.to')}}
                                <b-form-input id="filter-input" v-model="search_settings.dobto" type="date"></b-form-input>
                            </b-form-group>
                            <b-button variant="primary" @click="direct_dob()">
                                <font-awesome-icon icon="clock" />{{ $t("buttons.direct") }}
                            </b-button>
                            <b-button variant="primary" @click="reset_dob()">
                                <font-awesome-icon icon="undo" />{{ $t("buttons.reset") }}
                            </b-button>

                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('labels.call')+' '+ $t('labels.contactpersons')">
                                {{$t('labels.from')}}
                                <b-form-input id="filter-input" type="date" v-model="search_settings.nabellenfrom"></b-form-input>
                                {{$t('labels.to')}}
                                <b-form-input id="filter-input" v-model="search_settings.nabellento" type="date"></b-form-input>
                            </b-form-group>
                            <b-button variant="primary" @click="direct_call()">
                                <font-awesome-icon icon="clock" />{{ $t("buttons.direct") }}
                            </b-button>
                            <b-button variant="primary" @click="reset_call()">
                                <font-awesome-icon icon="undo" />{{ $t("buttons.reset") }}
                            </b-button>
                        </b-col>                        

                    </b-row>
                    <b-row class="filter-bar">
                        <b-col>
                            <b-form-group :label="$t('labels.search')">
                                <b-form-input id="filter-input" v-model="search_settings.filter" type="search"
                                    :placeholder="$t('strings.typetosearch')"></b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col>
                            <b-form-group :label="$t('labels.addedby')">
                                <select name="admin" class="form-control admins" v-model="search_settings.owner">
                                    <option value="-1"> {{ $t("option.all") }}</option>

                                    <option v-for="(user,idx) in owners" :key="idx" v-bind:value="user.id">
                                        {{ user["firstname"]}} {{ user["middlename"] }} {{ user["lastname"] }}
                                    </option>
                                </select>
                            </b-form-group>
                        </b-col>

                        </b-row>

                    <b-row>
                        <strong>{{$t('titles.branches')}}</strong>
                        <b-col>
                            <b-form-radio v-model="search_settings.selected_branches" name="checkbox-1" value=0 unchecked-value="0">All
                            </b-form-radio>
                        </b-col>
                        <b-col v-for="(branche,idx) in activebranches" :key="idx">
                            <b-form-radio v-model="search_settings.selected_branches" name="checkbox-1" :value="branche.id" unchecked-value="0">
                                {{branche.name}}</b-form-radio>

                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col v-for="(branche,idx) in shownbranches" :key="idx">
                            {{branche.name}}<br />
                            <b-form-checkbox v-model="search_settings.selected_workfields"
                                v-for="(workfield, idx3) in active_workfields_in_branche(branche.id)" :key="idx3"
                                name="checkbox-1" :value="workfield.id" unchecked-value="0">{{workfield.name}}</b-form-checkbox>
                        </b-col>
                    </b-row>                
            </b-tab>              
            </b-tabs>
            <hr>
            <b-row>
                <b-col md="4" xl>
                    <b-form-group :label="$t('titles.itemsvisible')">
                        <select name="admin" class="form-control admins" v-model="perPage">
                        <option value=1000> {{ $t("option.all") }}</option>
                        <option value=10>10</option>
                        <option value=20>20</option>
                        <option value=50>50</option>
                        <option value=100>100</option>
                        </select>
                    </b-form-group>
                </b-col>
                <b-col md="4" xl>
                    <b-dropdown id="dropdown-dropup" dropup :text="$t('tableheaders.email')" variant="primary">
                        <b-dropdown-item @click="select_all_email()" >
                        <font-awesome-icon icon="plus" /> {{ $t('buttons.selectall') }}
                        </b-dropdown-item>
                        <b-dropdown-item @click="select_none_email()">
                        <font-awesome-icon icon="minus" /> {{ $t('buttons.selectnone') }}
                        </b-dropdown-item>    
                        <b-dropdown-item @click="send_mail()">
                        <font-awesome-icon icon="envelope" /> {{ $t('buttons.sendmessage') }}
                        </b-dropdown-item>                     
                        <b-dropdown-item v-if="this.settings.EnableNewsletter" @click="send_newsletter()">
                        <font-awesome-icon icon="envelope" /> {{ $t('titles.newsletter') }}
                        </b-dropdown-item>                     
                    </b-dropdown>

                    <b-dropdown v-if="smsEnabled()" id="dropdown-dropup" dropup :text="$t('meta.sms')" variant="primary">
                        <b-dropdown-item @click="select_all_sms()">
                        <font-awesome-icon icon="plus" /> {{ $t('buttons.selectall') }}
                        </b-dropdown-item>
                        <b-dropdown-item @click="select_none_sms()">
                        <font-awesome-icon icon="minus" /> {{ $t('buttons.selectnone') }}
                        </b-dropdown-item>
                        <b-dropdown-item @click="send_sms()">
                        <font-awesome-icon icon="sms" /> {{ $t('buttons.sendmessage') }}
                        </b-dropdown-item>               
                    </b-dropdown>     
                    <b-dropdown id="dropdown-dropup" dropup :text="$t('meta.inapp')" variant="primary">
                        <b-dropdown-item @click="select_all_inapp()">
                        <font-awesome-icon icon="plus" /> {{ $t('buttons.selectall') }}
                        </b-dropdown-item>
                        <b-dropdown-item @click="select_none_inapp()">
                        <font-awesome-icon icon="minus" /> {{ $t('buttons.selectnone') }}
                        </b-dropdown-item>
                        <b-dropdown-item @click="send_inapp()">
                        <font-awesome-icon icon="bell" /> {{ $t('buttons.sendmessage') }}
                        </b-dropdown-item>               
                    </b-dropdown>     


                    <b-dropdown id="dropdown-dropup" dropup :text="$t('labels.export')" variant="primary">
                        <b-dropdown-item @click="export_users()">
                        <font-awesome-icon icon="download" /> {{ $t('labels.export') }} {{ $t('titles.users') }}
                        </b-dropdown-item>
                        <b-dropdown-item v-if="search_settings.tabIndex==1" @click="export_companies()">
                        <font-awesome-icon icon="download" /> {{ $t('labels.export') }} {{ $t('titles.companies') }}
                        </b-dropdown-item>                        
                         
                    </b-dropdown>              
                </b-col>
          </b-row>
          <input type="hidden" v-model="rerender" />

        <b-table v-if="search_settings.tabIndex==0" sort-icon-left :items="branche_users" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="search_settings.filter" :filter-included-fields="filterOn" :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :tbody-tr-class="rowClass"
            @filtered="onFiltered">
            <template #cell(branche_id)="row">
                <span v-html='brancheName(row.item.id)'></span>
            </template>
            <template #cell(id)="row">
                <p v-html="userinfo(row)" />
                <div v-if="search_settings.docBTW==1 && row.item.role=='user' && row.item.checkcode.valid">
                    <strong>{{ $t('labels.vat') }} </strong>
                    <font-awesome-icon v-if="row.item.checkcode.valid==false" icon="times" color="red" />
                    <font-awesome-icon  v-else icon="check" color="green"/>
                    <div v-if="row.item.checkcode.valid==true" >{{ row.item.checkcode.name }}</div>
                </div> 
            </template>
            <!-- <template #cell(latest)="row">
                <div v-if="company_by_id(row.item.latest)">
                    {{company_by_id( row.item.latest).company_name }}
                </div>
            </template> -->

            <template #cell(actions)="row">
                <a href="javascript:;" class="action-item" @click="edit_user(row.item)"
                    :title="$t('labels.edituser')">
                    <font-awesome-icon icon="cog" />
                </a>

                <b-form-checkbox v-if="smsEnabled() && row.item.sms==1" v-model="row.item.sms_user_selected" name="checkbox-1">SMS</b-form-checkbox>
                <b-form-checkbox v-if="row.item.mail==1" v-model="row.item.mail_user_selected" name="checkbox-1">MAIL</b-form-checkbox>
                <b-form-checkbox v-if="row.item.inapp==1 &&row.item.token!=null&&row.item.token!=undefined" v-model="row.item.inapp_user_selected" name="checkbox-1">APP</b-form-checkbox>
            </template>
            <template #cell(photo)="row">
                <img class="profile-photo" :src="profileto(row.item.id)" />
                <b-button variant="primary" @click="callUserModal(row.item.id)">
                    <font-awesome-icon icon="phone" />
                </b-button>

            </template>
            <template #cell(email)="row">
                <a :href=mailto(row.value)>{{row.value}}</a>
            </template>
            <template #cell(phone)="row">
                <a :href=phoneto(row.value)>{{row.value}}</a>
            </template>
        </b-table>
        <b-table v-if="search_settings.tabIndex==1" sort-icon-left :items="branche_companies" :fields="companyfields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :filter-included-fields="filterOn" :sort-by.sync="companysortBy"
            :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :tbody-tr-class="rowClass"
            @filtered="onFiltered" @row-clicked="onCompanyClicked">
            <template #cell(company_name)="row">
                <img class="profile-photo" :src="companyimageto(row.item.id)" /><br/>
                {{ row.item.company_name }}
            </template>            
            <template #cell(branche_id)="row">
                <span v-html='brancheName(row.item.id)'></span>
            </template>    
            <template #cell(id)="row">
                <table>
                    <tr v-for="id in cpincompany(row.item)" :key="id">
                        <td>{{username_by_id(id)}} </td>
                        <td v-if="user_by_id(id).company_contact == '1'"><font-awesome-icon class="table-contacts-roles"  icon="address-book" :title="$t('labels.company_contact')" /></td>
                        <td v-if="user_by_id(id).location_contact == '1'"><font-awesome-icon class="table-contacts-roles"  icon="street-view" :title="$t('labels.location_contact')" /></td>
                        <td v-if="hasBirthday(user_by_id(id))"><font-awesome-icon class="table-contacts-roles"  icon="birthday-cake" :title="$t('labels.hasbirthday')" /></td>
                        <td v-if="row.item.cb_ids.indexOf(id)!=-1"><font-awesome-icon class="table-contacts-roles"  icon="phone" :title="$t('labels.call')" /></td>
                        <td><b-form-checkbox  v-if="user_by_id(id).sms==1" v-model="user_by_id(id).sms_user_selected" name="checkbox-1">SMS</b-form-checkbox></td>
                        <td><b-form-checkbox v-if="user_by_id(id).mail==1" v-model="user_by_id(id).mail_user_selected" name="checkbox-1">MAIL</b-form-checkbox></td>
                        <td><b-form-checkbox v-if="user_by_id(id).inapp==1 &&user_by_id(id).token!=null&&user_by_id(id).token!=undefined" v-model="user_by_id(id).inapp_user_selected" name="checkbox-1">APP</b-form-checkbox></td>
                            

                    </tr>
                </table>
            </template>
            <template #cell(actions)="row">
                <a href="javascript:;" class="action-item" @click="edit_company(row.item)"
                    :title="$t('labels.edituser')">
                    <font-awesome-icon icon="cog" />
                </a>
            </template>            
            </b-table>
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
        </template>
        <template v-else>
            Invalid access
            <a :href=profile()> profile</a>
        </template>
    </b-container>

</template>
<script>
// import axios from 'axios'
import { mapActions, mapState, mapGetters } from 'vuex'
import popup_modal from './modals/PopupModal.vue'
import mail_search_modal from './modals/MailSearchModal'
import newsletter_search_modal from './modals/NewsletterSearchModal.vue'
import sms_search_modal from './modals/SMSSearchModal.vue'
import export_search_modal from './modals/ExportSearchModal.vue'
import inapp_search_modal from './modals/InAppSearchModal.vue'

export default {
    name: 'seacrh-screen',
    data() {
        return {
            currentPage: 1,
            perPage: 10,
            sortBy: 'lastname',
            companysortBy: 'company_name',
            sortDesc: false,
            sortDirection: 'desc',
            filter: null,
            filterOn: [],
            // currentbranche: -1,
            // owner: -1,
            // selectedtag: null,
            // selectedID: null,
            // waardering: [],
            // nieuwsbrief: 0,
            // status: ["0", "1", "2", "3", "4", "5"],
            // accountstatus: [],
            // company: null,
            // zipcode: "",
            // radius: "",
            componentKey: 0,
            distanceResult: null,
            // dobfrom: null,
            // dobto: null,
            // selected_branches: 0,
            // selected_workfields: [],
            // available_filter: 1,
            // date_available_from: null,
            // date_available_to: null,
            // date_working_from: null,
            // date_working_to: null,
            // date_working_full: null,
            // nabellenfrom: null,
            // nabellento: null,
            // docID:0,
            // docKVK:0,
            // docGPI:0,
            // docVCA:0,
            // docAny:0,
            // docBTW:0,
            rerender:0,
            // currentrole: -1,
            // signupdate_from:null,
            // signupdate_to:null,
            // tabIndex:0,
            // contactrole:"selectall"



        }
    },
    mounted() {
        this.search_settings.signupdate_to =this.dateToYYYYMMDD(new Date())
    },
    watch: {

        selected_branches: function () {
            //    this.selected_workfields = []

        }

    },
    computed: {
        totalRows() {
            return this.search_settings.tabIndex==0?this.branche_users.length:this.branche_companies.length
        },
        companies_in_branche() {
            let resultcompanies = []
            this.companies.forEach(company => {
                if (this.search_settings.currentbranche == -1 || company.branche_id == this.search_settings.currentbranche) {
                    resultcompanies.push({ value: company.id, text: company.company_name })
                }
            });
            return resultcompanies//this.companies.filter(company => this.currentbranche == -1 || company.branche_id == this.currentbranche)
        },
        branche_users() {
            // console.log("brb", this.search_settings)
            // let brancheusers = []
            // this.users.forEach(user => {
            //     if (user.branche_id == this.currentbranche || this.currentbranche == -1) {
            //         brancheusers.push(user)
            //     }
            // })
            //console.log("branche_users", new Date())
            return this.checkNewsLetter(this.users.filter(user => user.branche_id==this.search_settings.currentbranche||this.checkExtraBranche(user,this.search_settings.currentbranche )||this.search_settings.currentbranche == -1))
        },
        branche_companies(){   
            return this.checkRating(this.companies.filter(company => company.branche_id==this.search_settings.currentbranche||this.search_settings.currentbranche == -1))

        },
        shownbranches() {
            // let branches_to_show = []
            // this.activebranches.forEach(br => {
            //     if (this.selected_branches == br.id || this.selected_branches == 0) {
            //         branches_to_show.push(br)
            //     }
            // })
            return this.activebranches.filter(br => this.search_settings.selected_branches == br.id || this.search_settings.selected_branches == 0)
        },
        fields() {
            if(this.currentrole=='user'){
                return [
                    { key: 'photo', label: this.$i18n.t('labels.photo'), },
                    { key: 'id', label: this.$i18n.t('labels.name'), formatter: "username_by_id", sortable: true, sortDirection: 'desc' },
                    { key: 'latest', label: this.$i18n.t('labels.lastclient'), sortable: true, sortDirection: 'desc' },
                    { key: 'rating', label: this.$i18n.t('meta.waardering'), sortable: true, sortDirection: 'desc' },
                    { key: 'mobile', label: this.$i18n.t('meta.phone'), },

                    // { key: 'email', label: this.$i18n.t('meta.email'), sortable: false, sortDirection: 'desc' },
                    { key: 'branche_id', label: this.$i18n.t('labels.workfields'), sortable: true, sortDirection: 'desc' },
                    { key: 'actions', label: this.$i18n.t('labels.actions') },
                ]
            } else{
                return [
                    { key: 'photo', label: this.$i18n.t('labels.photo'), },
                    { key: 'id', label: this.$i18n.t('labels.name'), formatter: "username_by_id", sortable: true, sortDirection: 'desc' },
                    { key: 'rating', label: this.$i18n.t('meta.waardering'), sortable: true, sortDirection: 'desc' },
                    { key: 'mobile', label: this.$i18n.t('meta.phone'), },
                    // { key: 'lng', label: "latitude", },
                    // { key: 'lat', label: "longitude", },
                    

                    // { key: 'email', label: this.$i18n.t('meta.email'), sortable: false, sortDirection: 'desc' },
                    { key: 'branche_id', label: this.$i18n.t('labels.workfields'), sortable: true, sortDirection: 'desc' },
                    { key: 'actions', label: this.$i18n.t('labels.actions') },
                ]

            }
        }, 
        companyfields() {
            return [
                { key: 'company_name', label: "Bedrijfsnaam", sortable: true, sortDirection: 'desc' },
                { key: 'rating', label: this.$i18n.t('meta.waardering'), sortable: true, sortDirection: 'desc' },
                { key: 'id'},

                { key: 'email', label: this.$i18n.t('meta.email'), sortable: false, sortDirection: 'desc' },
                { key: 'branche_id', label: this.$i18n.t('labels.workfields'), sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: this.$i18n.t('labels.actions') },
                ]
        },
        ...mapState([
            'companies',
            'users',
            'branches',
            'avatars',
            'planning',
            'workfields',
            'tags',
            'current_user',
            'contracts',
            'search_settings'

        ]),
        ...mapGetters([
            'branche_by_id',
            'user_by_id',
            'user_photo_by_id',
            'activebranches',
            'username_by_id',
            'confirm_modal',
            'owners',
            'find_last_planning_user',
            'find_last_company_user',
            'active_workfields_in_branche',
            'find_last_planning_company'
        
        ]),

    },
    methods: {
        calcdistance(){
            if (this.search_settings.zipcode != "" && this.search_settings.radius != "") {
                fetch(
                    // "https://api.pro6pp.nl/v2/autocomplete/nl?authKey=1u9iXssOsxr8wRR5&postalCode=" + this.search_settings.zipcode +"&streetNumberAndPremise=2",
                    "https://api.pro6pp.nl/v2/range/nl?authKey=1u9iXssOsxr8wRR5&postalCode=" + this.search_settings.zipcode ,//+"&streetNumberAndPremise=2",

                    {
                        method: "GET",
                    }
                ).then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    //   throw new Error('Something went wrong');
                })
                .then((data) => {   
                    this.distanceResult = data[0]
                })             

            }

        },
        userinfo(row) {
            // console.log(row)
            let def ='<a href="/#/user/' + row.item.id + '" :style="{ cursor: \'pointer\'}" class="link-item"> ' + this.username_by_id(row.item.id) + '</a><br/>'
            let user = this.user_by_id(row.item.id)
            console.log(user)
            let option = '<a href="mailto:' + user.email + '" class="link-item">' +this.user_by_id(row.item.id).email + '</a><br>'
            if(user.role=='user'){
                option += user.adres?(user.adres+" "):" "
                option += user.housenumber?(user.housenumber+" "):" "
                option += user.postcode?(user.postcode+" "):" "
                option += user.city?(user.city+" "):" "
            }
            return def+option
        },
        resetSettings(){
          this.$store.commit('search_settings',{            
            currentbranche: -1,
            owner: -1,
            selectedtag: null,
            selectedID: null,
            waardering: [],
            nieuwsbrief: 0,
            status: ["0", "1", "2", "3", "4", "5"],
            accountstatus: [],
            company: null,
            zipcode: "",
            radius: "",
            componentKey: 0,
            distanceResult: null,
            dobfrom: null,
            dobto: null,
            selected_branches: 0,
            selected_workfields: [],
            available_filter: 1,
            date_available_from: null,
            date_available_to: null,
            date_working_from: null,
            date_working_to: null,
            date_working_full: null,
            nabellenfrom: null,
            nabellento: null,
            docID:0,
            docKVK:0,
            docGPI:0,
            docVCA:0,
            docAny:0,
            docBTW:0,
            expireWeeks:2,
            currentrole: -1,
            signupdate_from:null,
            signupdate_to:null,
            tabIndex:0,
            contactrole:"selectall",
            filter:""
            },
            )  
        },
        ...mapActions([
            'delete_user',
            'save_user'
        ]),
        select_all_email() {
            if(this.search_settings.tabIndex==0){
                this.branche_users.forEach(user =>{
                    if(user.mail==1){
                        user.mail_user_selected=true
                    }
                })
            } else {
                this.branche_companies.forEach(c =>{
                    this.cpincompany(c).forEach(u=>{
                        if(this.user_by_id(u).mail==1){
                            this.user_by_id(u).mail_user_selected=true
                        }
                    })

                })
            }
            this.rerender++

        },
        select_none_email() {
            if(this.search_settings.tabIndex==0){
                this.branche_users.forEach(user =>{
                user.mail_user_selected=false
                })
            } else {
                this.branche_companies.forEach(c =>{
                    this.cpincompany(c).forEach(u=>{
                        this.user_by_id(u).mail_user_selected=false
                    })

                })
            }


            this.rerender++
        },
        send_mail(){
            var userset=[]
            if(this.search_settings.tabIndex==0){
                userset = this.branche_users.filter(u =>u.mail_user_selected==true)
            } else {
                this.branche_companies.forEach(c =>{
                    this.cpincompany(c).forEach(u=>{
                        if(this.user_by_id(u).mail_user_selected==true){
                            userset.push(this.user_by_id(u))
                        }
                    })

                })
            }

            this.$modal.hide('mail_search_modal')
            this.$modal.show(mail_search_modal, {
                user_set: userset//this.branche_users.filter(u =>u.mail_user_selected==true),
            }, {
            name: 'mail_search_modal',
            draggable: '.popup-header',
            height: 'auto',
            width: '1000px'
            })
            this.$emit('close')
        },
        send_newsletter(){
            var userset=[]
            if(this.search_settings.tabIndex==0){
                userset = this.branche_users.filter(u =>u.mail_user_selected==true)
            } else {
                this.branche_companies.forEach(c =>{
                    this.cpincompany(c).forEach(u=>{
                        if(this.user_by_id(u).mail_user_selected==true){
                            userset.push(this.user_by_id(u))
                        }
                    })

                })
            }

            this.$modal.hide('newsletter_search_modal')
            this.$modal.show(newsletter_search_modal, {
                user_set: userset//this.branche_users.filter(u =>u.mail_user_selected==true),
            }, {
            name: 'newsletter_search_modal',
            draggable: '.popup-header',
            height: 'auto',
            width: '1000px'
            })
            this.$emit('close')
        },
        select_all_sms() {
            if(this.search_settings.tabIndex==0){
                this.branche_users.forEach(user =>{
                    if(user.sms==1){
                        user.sms_user_selected=true
                    }
                })
            } else {
                this.branche_companies.forEach(c =>{
                    this.cpincompany(c).forEach(u=>{
                        if(this.user_by_id(u).sms==1){
                            this.user_by_id(u).sms_user_selected=true
                        }
                    })

                })
            }


            this.rerender++

        },
        select_none_sms() {
            if(this.search_settings.tabIndex==0){
                this.branche_users.forEach(user =>{
                user.sms_user_selected=false
                })
            } else {
                this.branche_companies.forEach(c =>{
                    this.cpincompany(c).forEach(u=>{
                        this.user_by_id(u).sms_user_selected=false
                    })

                })
            }
            this.rerender++
        },    
        send_sms() {
            var userset=[]
            if(this.search_settings.tabIndex==0){
                userset = this.branche_users.filter(u =>u.sms_user_selected==true)
            } else {
                this.branche_companies.forEach(c =>{
                    this.cpincompany(c).forEach(u=>{
                        if(this.user_by_id(u).sms_user_selected==true){
                            userset.push(this.user_by_id(u))
                        }
                    })

                })
            }
            this.$modal.hide('sms_search_modal')
            this.$modal.show(sms_search_modal, {
                user_set: userset//this.branche_users.filter(u =>u.sms_user_selected==true),
            }, {
            name: 'sms_search_modal',
            draggable: '.popup-header',
            height: 'auto',
            width: '1000px'
            })
            this.$emit('close')
        },

        select_all_inapp() {
            if(this.search_settings.tabIndex==0){
                this.branche_users.forEach(user =>{
                    if(user.inapp==1 && user.token!=null&&user.token!=undefined){
                        user.inapp_user_selected=true
                    }
                })
            } else {
                this.branche_companies.forEach(c =>{
                    this.cpincompany(c).forEach(u=>{
                        if(this.user_by_id(u).inapp==1 && this.user_by_id(u).token!=null&&this.user_by_id(u).token!=undefined){
                            this.user_by_id(u).inapp_user_selected=true
                        }
                    })

                })
            }


            this.rerender++

        },
        select_none_inapp() {
            if(this.search_settings.tabIndex==0){
                this.branche_users.forEach(user =>{
                user.inapp_user_selected=false
                })
            } else {
                this.branche_companies.forEach(c =>{
                    this.cpincompany(c).forEach(u=>{
                        this.user_by_id(u).inapp_user_selected=false
                    })

                })
            }
            this.rerender++
        },    
        send_inapp() {
            var userset=[]
            if(this.search_settings.tabIndex==0){
                userset = this.branche_users.filter(u =>u.inapp_user_selected==true)
            } else {
                this.branche_companies.forEach(c =>{
                    this.cpincompany(c).forEach(u=>{
                        if(this.user_by_id(u).inapp_user_selected==true){
                            userset.push(this.user_by_id(u))
                        }
                    })

                })
            }
            this.$modal.hide('inapp_search_modal')
            this.$modal.show(inapp_search_modal, {
                user_set: userset//this.branche_users.filter(u =>u.sms_user_selected==true),
            }, {
            name: 'inapp_search_modal',
            draggable: '.popup-header',
            height: 'auto',
            width: '1000px'
            })
            this.$emit('close')
        },

        setcontactrole(){
            this.companies.forEach(c=>{
                c.dob_ids = undefined
                c.cb_ids = undefined
            })
        },
        cpincompany(company){
            if(company.dob_ids==undefined && company.cb_ids==undefined){
                switch (this.search_settings.contactrole) {
                    case "selectall":
                        company.dob_ids = [...new Set([...company.location_contacts,...company.company_contacts])]
                        company.cb_ids=[]

                    break;
                    case "company_contact":
                        company.dob_ids = company.company_contacts
                        company.cb_ids=[]
                    break;
                    case "location_contact":
                        company.dob_ids = company.location_contacts
                        company.cb_ids=[]
                    break;
                    case "selectnone":
                        company.dob_ids=[]
                        company.cb_ids=[]
                    break;
                } 
            }
            if(company.cb_ids==undefined){
                    company.cb_ids=[]
            }
            if(company.dob_ids==undefined){
                    company.dob_ids=[]
            }
            return [...new Set([...company.dob_ids,...company.cb_ids])]

        },
        export_companies(){
        var sep = ";"
        var props = ["company_name", "adres", "housenumber", "postcode", "city", "kvkcode"]
          let index = 0;
          var csv ="";
          for(const key in props){
            if(props[key]){csv += '"'+props[key]+'"'+sep}
          }
          csv+="\n";
          while (index<this.branche_companies.length){
            for(const key in props){
              if(props[key]){
                if(this.branche_companies[index][props[key]]) {
                  csv +=  '"'+this.branche_companies[index][props[key]]+'"'+sep
                } else {
                  csv += '""'+sep
                }
              }
            }
            csv+="\n";
            index++
           }
           const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
            anchor.target = '_blank';
            anchor.download = 'Export.csv';
            anchor.click();                  
        },
        export_users(){
            var userset =[]
            if(this.search_settings.tabIndex==0){
                userset = this.branche_users
            } else {
                this.branche_companies.forEach(c =>{
                    this.cpincompany(c).forEach(u=>{
                        userset.push(this.user_by_id(u))
                    })

                })
            }
            this.$modal.hide('export_search_modal')
            this.$modal.show(export_search_modal, {
                user_set: userset//.branche_users,
            }, {
            name: 'export_search_modal',
            draggable: '.popup-header',
            height: 'auto',
            width: '1000px'
            })
            this.$emit('close')
        },
        direct_available() {
            var today = new Date();
            var first = today.getDate() - today.getDay(); // First day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6
            this.search_settings.date_available_from = this.dateToYYYYMMDD(new Date(today.setDate(first)).toUTCString())
            this.search_settings.date_available_to = this.dateToYYYYMMDD(new Date(today.setDate(last)).toUTCString())
            this.search_settings.available_filter = 3
            this.componentKey++

        },
        reset_available() {
            this.search_settings.date_available_from = null
            this.search_settings.date_available_to = null;
            this.search_settings.available_filter = 1
        },
        direct_worked() {
            var today = new Date();
            var first = today.getDate() - today.getDay(); // First day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6
            this.search_settings.date_working_from = this.dateToYYYYMMDD(new Date(today.setDate(first)).toUTCString())
            this.search_settings.date_working_to = this.dateToYYYYMMDD(new Date(today.setDate(last)).toUTCString())
            this.search_settings.available_filter = 2
            this.componentKey++

        },
        reset_worked() {
            this.search_settings.date_working_from = null
            this.search_settings.date_working_to = null;
            this.search_settings.available_filter = 1

        },
        direct_call() {
            var today = new Date();
            var first = today.getDate() - today.getDay(); // First day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6
            this.search_settings.nabellenfrom = this.dateToYYYYMMDD(new Date(today.setDate(first)).toUTCString())
            this.search_settings.nabellento = this.dateToYYYYMMDD(new Date(today.setDate(last)).toUTCString())
            this.componentKey++
        },
        reset_call() {
            this.search_settings.nabellenfrom = null
            this.search_settings.nabellento = null;
            this.companies.forEach(c=>{
                c.dob_ids = undefined
                c.cb_ids = undefined
            })

        },
        direct_dob() {
            var today = new Date();
            var first = today.getDate() - today.getDay(); // First day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6
            this.search_settings.dobfrom = this.dateToYYYYMMDD(new Date(today.setDate(first)).toUTCString())
            this.search_settings.dobto = this.dateToYYYYMMDD(new Date(today.setDate(last)).toUTCString())
            this.componentKey++

        },
        reset_dob() {
            this.search_settings.dobfrom = null
            this.search_settings.dobto = null;
            this.companies.forEach(c=>{
                c.dob_ids = undefined
                c.cb_ids = undefined
            })

        },
        confirm_delete_user(id) {
            this.selectedID = id
            let message = this.$i18n.t('labels.confirmdeletehead') + this.username_by_id(id) + this.$i18n.t('labels.confirmdeletetail')
            this.$modal.hide('popup_modal')
            this.$modal.show(popup_modal, {
                message: message,
            }, {
                name: 'popup_modal',
                draggable: false,
                height: 'auto',
                width: '500px'
            }, {
                'before-close': this.modalClose
            })

            this.$emit('close')

        },
        modalClose() {
            if (this.confirm_modal.id == 1) {
                this.delete_user(this.selectedID);
                for (let i = 0; i < this.users.length; i++) {
                    if (this.users[i].id == this.selectedID) {
                        this.users.splice(i, 1);
                    }
                }
            }
        },
        onUserClicked(row) {
            this.$router.push({ path: '/user/' + row.id });

        },
        onCompanyClicked(row) {
            this.$router.push({ path: '/company/' + row.id });

        },        
        checkNewsLetter(items) {
            // console.log("checkNewsLetter",items.length)
            if (this.search_settings.nieuwsbrief == 0) {
                return this.checkRating(items)
            }
            // let checkNewsLetters = []
            // items.forEach(user => {
            //     if (user.newsletter == 1) {
            //         checkNewsLetters.push(user)
            //     }
            // })
            return this.checkRating(items.filter(item => item.newsletter == 1))
        },
        checkRating(items) {
            // console.log("rating",items.length)
            if(this.search_settings.tabIndex==0){
                if (this.search_settings.waardering.length == 0) {
                    return this.checkStatus(items)
                }
                return this.checkStatus(items.filter(user => (user.rating == undefined && this.search_settings.waardering.indexOf("0") != -1) || (user.rating != undefined && this.search_settings.waardering.indexOf(String(user.rating.length)) != -1)))
            } else {
                if (this.search_settings.waardering.length == 0) {
                    return this.checkStatus(items)
                }
                return this.checkStatus(items.filter(company => (company.rating == undefined && this.search_settings.waardering.indexOf("0") != -1) || (company.rating != undefined && this.search_settings.waardering.indexOf(String(company.rating.length)) != -1)))
                
            }
        },
        checkStatus(items) {
            // console.log("checkStatus",items.length)

            // filter all blacklisted 
            if(this.search_settings.status.indexOf("6") == -1){
                items = items.filter(u =>{ 
                    return u.blacklist!=1
                })
            }

            if(this.search_settings.tabIndex==0){
                if (this.search_settings.status.length == 0) {
                    //filter blacklist
                    return this.checkAccountStatus(items)
                }
                return this.checkAccountStatus(items.filter(user => {
                    const d = new Date()
                    const day = d.getDay();
                    let timediff = (this.find_last_planning_user(user.id) - this.getMonday(d)) / (1000 * 60 * 60 * 24)

                    return (user.blacklist && user.blacklist == 1 && this.search_settings.status.indexOf("6") != -1) ||
                    ((timediff > 0 || timediff > -(day-1)) && this.search_settings.status.indexOf("0") != -1) ||
                    (timediff < -7 && timediff > -90 && this.search_settings.status.indexOf("1") != -1) ||
                    (timediff < -90 && timediff > -356 && this.search_settings.status.indexOf("2") != -1) ||
                    (timediff < -356 && timediff > -712 && this.search_settings.status.indexOf("3") != -1) ||
                    (timediff < -712 && timediff > -7120 && this.search_settings.status.indexOf("4") != -1) ||
                    (timediff < -7120 && this.search_settings.status.indexOf("5") != -1) 
                }))
            } else {
                if (this.search_settings.status.length == 0) {
                    return this.checkDistance(items)
                }   
                return this.checkDistance(items.filter(company => {    
                    const d = new Date()
                    const day = d.getDay();

                let timediff = (this.find_last_planning_company(company.id) - this.getMonday(d)) / (1000 * 60 * 60 * 24)
                return (company.blacklist && company.blacklist == 1 && this.search_settings.status.indexOf("6") != -1) ||
                    ((timediff > 0 || timediff > -(day-1)) && this.search_settings.status.indexOf("0") != -1) ||
                    (timediff < -7 && timediff > -90 && this.search_settings.status.indexOf("1") != -1) ||
                    (timediff < -90 && timediff > -356 && this.search_settings.status.indexOf("2") != -1) ||
                    (timediff < -356 && timediff > -712 && this.search_settings.status.indexOf("3") != -1) ||
                    (timediff < -712 && timediff > -7120 && this.search_settings.status.indexOf("4") != -1) ||
                    (timediff < -7120 && this.search_settings.status.indexOf("5") != -1) 
                }))
            }
        },
        checkAccountStatus(items) {

            if (this.search_settings.docKVK == 0 && this.search_settings.docGPI == 0 && this.search_settings.docVCA ==  0 && this.search_settings.docID == 0 && this.search_settings.docAny == 0 && this.search_settings.docBTW == 0) {
                return this.checkLastCompany(items)
            }
            let checkdocuments = []
            var today = new Date()
            today.setDate(today.getDate() + this.search_settings.expireWeeks * 7);

            for (let user of items){
                let expired = false;
                if(this.search_settings.docID==1&&user.identification_endate!= undefined && user.identification_endate!=null){
                    expired = new Date(user.identification_endate) < today
                }
                user.documents.forEach(doc =>{
                    if(!expired && this.search_settings.docKVK==1 && doc.name=='KvK'){
                        expired = new Date(doc.expires) < today
                    }
                    if(!expired && this.search_settings.docVCA==1 && doc.name=='VCA'){
                        expired = new Date(doc.expires) < today
                    }
                    if(!expired && this.search_settings.docID==1 && doc.name=='ID'){
                        expired = new Date(doc.expires) < today
                    }
                    if(!expired && this.search_settings.docGPI==1 && doc.name=='GPI'){
                        expired = new Date(doc.expires) < today
                    }
                    if(!expired && this.search_settings.docAny==1 && doc.name!='GPI'&& doc.name!='ID'&& doc.name!='VCA'&& doc.name!='KvK'){
                        expired = new Date(doc.expires) < today
                    }                    
                })
                // check VAT
                if(user.role=="user" && this.search_settings.docBTW == 1){
                    // console.log(user.vatcode)
                    expired=true
                    if(user.vatcode!=undefined && user.vatcode!= null){
                        fetch(
                            "https://controleerbtwnummer.eu/api/validate/" + user.vatcode + ".json",
                            {
                                method: "GET",
                            }
                        ).then((response) => {
                            if (response.ok) {
                                return response.json();
                            }
                            //   throw new Error('Something went wrong');
                        })
                        .then((data) => {
                            this.vatchecked = data.valid == true
                            user.checkcode = data
                            this.componentKey++
                            // if(!this.vatchecked){
                            //     expired = true
                            // } else { 
                            //     expired = true
                            //     user.checkcode = data.name}
                        })
                        .catch((error) => {
                            console.log(error)
                        }); 
                    } else {
                        expired = true
                        user.checkcode = {valid:false}

                    }
                }
                if(expired){
                    checkdocuments.push(user)
                }
            }

            return this.checkLastCompany(checkdocuments)            
        },
        checkLastCompany(items) {
            // console.log("checkLastCompany",items.length)

            //console.log("checkLastCompany", new Date())
            if (this.search_settings.company == null) {
                return this.checkDistance(items)
            }
            // let checkLastCompany = []
            // items.forEach(user => {
            //     let last_company_id = this.find_last_company_user(user.id)
            //     if (this.company == last_company_id) {
            //         checkLastCompany.push(user)
            //     }
            // })
            return this.checkDistance(items.filter(user => this.search_settings.company==this.find_last_company_user(user.id)))

        },
        checkDistance(items) {
            // console.log("checkDistance",items.length)

            if(this.search_settings.tabIndex==0) {
                if (this.search_settings.zipcode == "" || this.search_settings.radius == "") {
                    return this.checkDOB(items)
                }
                let checkRadius = []
                items.forEach(user => {
                    // console.log(user)
                    // console.log(user.lng , this.distanceResult)
                    if (user.lat != undefined && user.lng != undefined && this.distanceResult!=null && this.distanceResult.lat) {
                        
                        if (this.getDistance(this.distanceResult.lat, this.distanceResult.lng, user.lat, user.lng) <= this.search_settings.radius) {
                            checkRadius.push(user)
                        }
                    }
                })
                return this.checkDOB(checkRadius)
            } else {
                return this.checkDOB(items)
            }
        },
        checkDOB(items) {

            //console.log("checkDOB", new Date())
            if(this.search_settings.tabIndex==0) {
                if (this.search_settings.dobfrom == null || this.search_settings.dobto == null) {
                    return this.checkCallBack(items)
                }
                let checkDOB = []
                items.forEach(user => {
                    if (user.birthday != undefined) {
                        let birthDate = new Date(user.birthday)
                        var today = new Date();
                        let checkbd = new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate())
                        let frombd = new Date(this.search_settings.dobfrom)
                        let tobd = new Date(this.search_settings.dobto)
                        if (checkbd >= frombd && checkbd <= tobd) {
                            checkDOB.push(user)
                        }
                    }
                })
                return this.checkCallBack(checkDOB)
            } else {
                if (this.search_settings.dobfrom == null || this.search_settings.dobto == null || this.search_settings.contactrole=="selectnone") {
                    return this.checkCallBack(items)
                }
                let checkDOB = []
                items.forEach(company => {
                    var company_added=false
                    company.dob_ids=[]
                    var user_ids=[]
                    switch (this.search_settings.contactrole) {
                        case "selectall":
                            user_ids = [...new Set([...company.location_contacts,...company.company_contacts])]
                            
                            break;
                        case "company_contact":
                            user_ids = company.company_contacts
                        
                        break;
                        case "location_contact":
                            user_ids = company.location_contacts
                        
                        break;
                    
                        default:
                            break;
                    }
                    // console.log(user_ids)
                    user_ids.forEach(id =>{
                        let user = this.user_by_id(id)
                        if (user.birthday != undefined) {
                            let birthDate = new Date(user.birthday)
                            var today = new Date();
                            let checkbd = new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate())
                            let frombd = new Date(this.search_settings.dobfrom)
                            let tobd = new Date(this.search_settings.dobto)
                            if (checkbd >= frombd && checkbd <= tobd) {
                                if(company_added==false){
                                    checkDOB.push(company)
                                    company.dob_ids.push(id)
                                    company_added=true
                                } else {
                                    company.dob_ids.push(id)
                                }
                            }
                        }
                    })
                    

                })
                return this.checkCallBack(checkDOB)
            }
        },
        checkCallBack(items) {
            // console.log("checkCallback",items.length)

            if(this.search_settings.tabIndex==0) {
                if (this.search_settings.nabellenfrom == null || this.search_settings.nabellento == null) {
                    return this.checkWorkfields(items)
                }
                let checkCallbackAt = []
                items.forEach(user => {
                    if (user.callat != undefined) {
                        // get dates within week
                        let startyear = user.callat.substring(0, 4)
                        let startweek = parseInt(user.callat.substring(6))
                        var d = new Date(startyear, 0, 1);
                        if (d.getDay() == 1) {
                            d.setDate(d.getDate() + ((startweek - 1) * 7));
                        } else {
                            d.setDate(d.getDate() + (startweek * 7));
                        }                     
                        var checkstart = this.getMonday(d)
                        var enddate = new Date(checkstart)
                        enddate.setDate(enddate.getDate() + 6);
                        // let checkbd = new Date(user.callat)
                        let frombd = new Date(this.search_settings.nabellenfrom)
                        let tobd = new Date(this.search_settings.nabellento)
                        // console.log(">>user",user.fullname)
                        if (this.dateRangeOverlaps(checkstart, enddate, frombd, tobd)) {
                            checkCallbackAt.push(user)
                        }
                    }
                })
                return this.checkWorkfields(checkCallbackAt)
            } else {
                if (this.search_settings.nabellenfrom == null || this.search_settings.nabellento == null|| this.search_settings.contactrole=="selectnone") {
                    return this.checkWorkfields(items)
                }
                let checkCallbackAt = []
                items.forEach(company => {
                    var company_added=false
                    company.cb_ids=[]

                    var user_ids=[]
                    switch (this.search_settings.contactrole) {
                        case "selectall":
                            user_ids = [...new Set([...company.location_contacts,...company.company_contacts])]
                            
                            break;
                        case "company_contact":
                            user_ids = company.company_contacts
                        
                        break;
                        case "location_contact":
                            user_ids = company.location_contacts
                        
                        break;
                    
                        default:
                            break;
                    } 
                    user_ids.forEach(id =>{
                        let user = this.user_by_id(id)
                        if (user.callat != undefined) {
                            // get dates within week
                            let startyear = user.callat.substring(0, 4)
                            let startweek = parseInt(user.callat.substring(6))
                            var d = new Date(startyear, 0, 1);
                            if (d.getDay() == 1) {
                                d.setDate(d.getDate() + ((startweek - 1) * 7));
                            } else {
                                d.setDate(d.getDate() + (startweek * 7));
                            }
                            // d.setDate(d.getDate() + (startweek * 7));
                            var checkstart = this.getMonday(d)
                            var enddate = new Date(checkstart)
                            enddate.setDate(enddate.getDate() + 6);
                            // let checkbd = new Date(user.callat)
                            let frombd = new Date(this.search_settings.nabellenfrom)
                            let tobd = new Date(this.search_settings.nabellento)
                            if (this.dateRangeOverlaps(checkstart, enddate, frombd, tobd)) {
                                // checkCallbackAt.push(company)
                                if(company_added==false){
                                    checkCallbackAt.push(company)
                                    company.cb_ids.push(id)
                                    company_added=true
                                } else {
                                    company.cb_ids.push(id)
                                }                                
                            }
                        }
                    })
                })
                return this.checkWorkfields(checkCallbackAt)

            }
        },

        checkWorkfields(items) {
            // console.log("checkWorkfields",items.length)
            if(this.search_settings.tabIndex==0){
                if (this.search_settings.selected_workfields.length == 0) {
                    return this.checkAvailable(items)
                }
                let checkWF = []
                items.forEach(user => {
                    if (user.workfields.filter(val => this.search_settings.selected_workfields.includes(val)).length > 0) {
                        checkWF.push(user)
                    }
                })
                return this.checkAvailable(checkWF)
            } else {
                if (this.search_settings.selected_workfields.length == 0) {
                    return this.checkowner(items)
                }
                let checkWF = []
                items.forEach(company => {
                    if (company.workfields.filter(val => this.search_settings.selected_workfields.includes(val)).length > 0) {
                        checkWF.push(company)
                    }
                })
                return this.checkowner(checkWF)
            }
        },
        checkAvailable(items) {
            //console.log("checkAvailable", new Date())
            if (this.search_settings.available_filter == 1) {
                return this.checkowner(items)
            }
            let checkAvail = []
            if (this.search_settings.available_filter == 3) {
                items.forEach(user => {
                    if (user.available_from != undefined) {
                        let checkbd = new Date(user.available_from)
                        let frombd = new Date(this.search_settings.date_available_from)
                        let tobd = new Date(this.search_settings.date_available_to)
                        if (checkbd >= frombd && checkbd <= tobd) {
                            checkAvail.push(user)
                        }
                    }
                })
            }
            if (this.search_settings.available_filter == 2) {
                items.forEach(user => {
                    this.contracts.filter(contract => {
                        return contract.user_id == user.id
                    }).forEach(contract => {
                        let plan = this.planning_by_id(contract.planning_id)
                        let planstart = new Date(plan.startdate)
                        let planend = new Date(plan.enddate)
                        let frombd = new Date(this.search_settings.date_working_from)
                        let tobd = new Date(this.search_settings.date_working_to)
                        if (planstart <= frombd && planend >= tobd) {
                            if (checkAvail.indexOf(user) == -1) {
                                checkAvail.push(user)
                            }
                        }
                    })
                })
            }
            return this.checkowner(checkAvail)
        },
        checkowner(items) {
            // console.log("checkowner", items.length, this.owner)

            if(this.search_settings.tabIndex==0){
                if (this.search_settings.owner == -1) {
                    return this.checkrole(items)
                }
                return this.checkrole(items.filter(user => user.owner_id == this.search_settings.owner))
            } else{
                if (this.search_settings.owner == -1) {
                    return items
                }
                return items.filter(company => company.owner_id == this.search_settings.owner)
                
            }

        },
        checkrole(items){
            if (this.search_settings.currentrole == -1) {
                return this.signupcheck(items)
            }
            // add latest company ico zzp-er
            if(this.currentrole=='user'){
                items.forEach(u =>{
                    let company_id = this.find_last_company_user(u.id)
                    u.latest = this.company_by_id( company_id)?this.company_by_id(company_id).company_name:""
                })

            }
            return this.signupcheck(items.filter(user =>user.role==this.search_settings.currentrole))

        },
        signupcheck(items){
            if (this.search_settings.signupdate_from == null || this.search_settings.signupdate_to ==null) {
                return items
            }
            let fromsu = new Date(this.search_settings.signupdate_from)
            let tosu = new Date(this.search_settings.signupdate_to)            
            return items.filter(user => {
                let sudate=new Date(user.signupdate)
                return user.signupdate!=null && sudate>=fromsu && sudate<=tosu
            })



        },
        onFiltered() {
            //    this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        sort_role(users) {
            users.sort((a, b) => a.role > b.role && 1 || -1)
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            //difference in days
            let timediff = (this.find_last_planning_user(item.id) - new Date()) / (1000 * 60 * 60 * 24)

            if (item.blacklist && item.blacklist == 1) {
                return 'status-blacklisted'
            } else {
                if (timediff > 0 || timediff > -7) { return 'status-active' }
                if (timediff < -7 && timediff > -90) { return 'status-inactive' }
                if (timediff < -90 && timediff > -356) { return 'status-inactive2' }
                if (timediff < -356 && timediff > -712) { return 'status-inactive3' }
                if (timediff < -712 && timediff > -7120) { return 'status-inactive4' }
                if (timediff < -7120) { return 'status-inactive5' }
            }
        },
        edit_user(user) {
            if (user.id == 0) {
                this.$store.commit('new_user', {
                    id: 0,
                    branche_id: 0,
                    workfields: [],
                    role:'user',
                    owner_id: this.current_user.id,
                    active: 1,
                    language: "nl",
                    country: "NL",
                    notifications: 1,
                    newsletter: 1,
                    mail: 1,
                    sms: 1,
                    inapp: 1,
                    company_contact: 1,
                    invoice_contant: 1,
                    document_contact: 1,
                    sign_contact: 1,
                    location_contact: 1,
                      isemployee:0
                })

            }
            this.editUserModal(user.id)
        },
        edit_company(company) {
            this.editCompanyModal(company)
        },
        brancheName(value) {
            var user
            if(this.search_settings.tabIndex==0) {
                user = this.user_by_id(value)
            } else {
                user = this.company_by_id(value)
            }

                const branch = this.branche_by_id(user.branche_id);
                var ret = ""
                if (branch) {
                    ret += "<b>" + branch.name + "</b><br>"
                    for (var i = 0; i < this.workfields.length; i++) {
                        if (this.workfields[i].branche_id == user.branche_id) {
                            if (user.workfields.indexOf(this.workfields[i]["id"]) != -1) {
                                ret += this.workfields[i]["name"] + "<br>"
                            }
                        }
                    }

                }
                return ret
        },
        mailto(id) {
            return "mailto:" + id;
        },
        phoneto(id) {
            return "tel:" + id;
        },
        hasBirthday(user) {
            if (user.birthday != null) {
                let birthDate = new Date(user.birthday)
                var today = new Date();
                let checkbd = new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate())
                var diff = (checkbd.getTime() - today.getTime()) / (1000 * 3600 * 24)
                if ((diff > -1 && diff < 7)) {
                    return true
                }
            }
            return false
        },
        checkcallat(user) {
            if (user.callat == null) {
                return 0
            } else {
                let calldate = new Date(user.callat)
                var today = new Date();
                var diff = (calldate.getTime() - today.getTime()) / (1000 * 3600 * 24)
                if (calldate < today || diff < 14) {

                    return 2
                }
            }
            return 1
        },

    },
}
</script>
