<template>
    <b-container fluid>        
    <div class="title-bar">
        <h1>{{ $t("titles.project_hours") }}</h1>
    </div>
    <div v-for="item in contractinfo" :key="item.id" >
      <div id="userhours">
        <h2>  {{username_by_id(item.user_id) }}</h2>
            {{ item.project }} {{ item.startdate }} {{ item.enddate }}
        <Schedule :contract_id="item.contract_id" :user_id="item.user_id"></Schedule>
      </div>
    </div>
    </b-container>

</template>
<script>
import { mapState,mapGetters,mapActions } from 'vuex'
import Schedule from './Schedule'

export default {
    name: 'project_hours_screen',
    data() {
        return {

        }
    },
    mounted() {
      
          },
    computed: {

      contractinfo(){
          let values =[];
          for(var i=0;i<this.contracts.length;i++){
            let userplan = this.userplanning(this.contracts[i].user_id)
            for(var p=0;p<userplan.length;p++){
              let row ={};
              if(this.contracts[i].signed_zzpr==1 && this.contracts[i].signed_company==1 &&userplan[p].project_id==this.contracts[i].project_id){
                let project = this.project_by_id(this.contracts[i].project_id)
                if(project){
                  row.project = userplan[p].project_name
                  row.contract_id = this.contracts[i].id
                  row.user_id = this.contracts[i].user_id
                  row.startdate = userplan[p].startdate
                  row.enddate = userplan[p].enddate
                }
              //  if(this.contracts[i].user_id==this.current_user.id){
                  values.push(row)
            //   }
              }
          }
        }
        return values
      },
      fields () {
        return [
          ]
      },  
      ...mapState([
          'projects',
          'users',
          'companies',
          'contracts',
          'branches',
          'planning',
          'settings',
          'current_user'
      ]), 
      ...mapGetters([
          'project_by_id',
          'company_by_id',
          'contract_by_id',
          'zzpers',
          'user_by_id',
          'username_by_id'
      ]),  

    },
    methods:{

      ...mapActions([
          'save_hours',
      ]),      

      getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        return new Date(d.setDate(diff));
      },
      startweekinit() {
        let now = new Date()
        let sweek = this.getWeekNumber(now)
        this.startw = sweek[0]+"-W"+sweek[1]
        let eweek = this.getWeekNumber(new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000))
        this.endw = eweek[0]+"-W"+eweek[1]
      },
      userplanning(user_id){
        let _planning =[]
        for(var i=0; i<this.planning.length;i++){
          if(this.planning[i].user_id== user_id){
            _planning.push(this.planning[i])
          }
        }
        return _planning
      },
      hourschedule(item){
        let result=""
        let startdate = new Date(item.startdate);
        let enddate = new Date(item.enddate);
        let startmonday = this.getMonday(startdate);
        var loop = new Date(startmonday);
        while(loop <= enddate){
          if(loop.getDay()==1){
            if(result==""){
              result+="<table><tr>"
            } else {
              result+="</tr><tr>"
            }
          }
          if(loop.getDay()>=1 && loop.getDay()<=5){
            result+="<td>"
            if(loop>=startdate) {
              result+="<input type='number' name='hours'><input type='hidden' name='date' value='"
              result+=this.dateToYYYYMMDD(loop)//+"-"+(loop.getMonth()+1)+"-"+loop.getDate()
              result+="'>"
              
            }
            result+="</td>"   
          }       
          var newDate = loop.setDate(loop.getDate() + 1);
          loop = new Date(newDate);
        }
        return "<h1>schedule</h1>"+result
      },
      formSubmit(e) {
        e.preventDefault() // it prevent from page reload

      },
   
    },
    watch:{

    },
    components: {Schedule}

}
</script>