<template>
  <b-modal id="BSFindSnelstartID" centered size="lg" @show="clear">
    <template #modal-header>
      <h2 class="modal-title">Koppel Snelstart {{ username_by_id(user_id) }}</h2>
      {{ user_id }}
    </template>

    <form class="form">
      <div class="form-row">
        <div class="col-3">
          <label for="">Relatiecode</label>
        </div>
        <div class="col">
          <b-input-group class="mt-3">
            <b-form-input :type="text" v-model="relatiecode" placeholder="" />
          </b-input-group>        
        </div>
      </div>
      <div v-if="companyname!=''"  class="row">
        <div class="col-3"> {{$t('labels.companyname')}} </div>
        <div class="col-9">{{ companyname }}</div>
        <div class="col-3"> {{$t('labels.kvk')}} </div>
        <div class="col-9">{{ kvk }}</div>        
      </div>
      <div v-if="error!=''"  class="row">
        <div class="col-3"></div>
        <div class="col-9">{{ $t( 'labels.invalid_token') }}</div>
      </div>

    </form>


    <template #modal-footer>
      <div @click="close_modal" class="btn btn-secondary">{{ $t("buttons.close") }}</div>
      <div @click="finduser" class="btn btn-primary">{{ $t("labels.search") }}</div>
      <div v-if="companyname!=''" @click="saveuser()" class="btn btn-primary">{{ $t("buttons.saveandclose") }}</div>
    </template>
  </b-modal>
</template>


<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import axios from 'axios'

export default {
  name: 'invoice-data-modal',
  data() {
    return {
      relatiecode:null,
      companyname:"",
      kvk:"",
      result:null,
      error:""
    }
  },
  props: {
    user_id: Number
  },
  computed: {

    ...mapState([
    ]),
    ...mapGetters([
      'username_by_id'
    ]),
  },
  methods: {
    clear(){
      this.relatiecode = null
    },
    ...mapActions([
            'save_user',
        ]),    
    async finduser(){
      this.error=""
      await axios.get('/api/snelstart_get_relatie',  { params: {
        id: this.relatiecode,
      }})
      .then((response) => {
          console.log('snelstart_get_relatie', response.data)
          this.companyname = response.data[0].naam
          this.kvk= response.data[0].kvkNummer
          this.result = response.data[0]
        })
        .catch((error) => {
          console.log(">S>S>",error)
          this.error ="NOTFound"
        });
    }  , 
    saveuser(){
      this.user_by_id(this.user_id).debnum = this.result.id
      this.save_user(this.user_id)
      this.$bvModal.hide('BSFindSnelstartID')
    },
    close_modal() {
      this.$bvModal.hide('BSFindSnelstartID')
    },
  },

}    
</script>
