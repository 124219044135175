<template>
	<div class="popup">
        <div class="popup-header">
              {{$t('labels.export')}}  {{ user_set.length }} {{ $t('titles.users') }}
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
          <b-container fluid>   
            <b-row>
              <b-col cols="2">
                <strong>{{ $t('labels.seperator') }}</strong>
              </b-col>
              <b-col cols="2">
                <b-form-checkbox value=";" v-model="seperator">;</b-form-checkbox>
              </b-col>
              <b-col cols="2">
                <b-form-checkbox value="tab" v-model="seperator">tab</b-form-checkbox>
              </b-col>   
              <b-col cols="2">
                <b-form-checkbox value="other" v-model="seperator"><input v-model="custom"></b-form-checkbox>
              </b-col>            
            </b-row>
            <b-row>
              <b-col><h3>Basis gegevens</h3></b-col>
              <b-col><a href="#" @click="selectnaw()">{{$t('buttons.selectall')}}</a></b-col>
              <b-col><a href="#" @click="deselectnaw()">{{$t('buttons.selectnone')}}</a></b-col>
            </b-row>
            
            <b-row>
            <b-col cols="3" v-for="key of naw" :key="key" >
              <b-form-checkbox @change="exampleupdate()" v-model="props[key]">{{translate(key)}}</b-form-checkbox>        
              </b-col>   
            </b-row>
            <b-row>
              <b-col><h3>Bedrijfsgegevens</h3></b-col>
              <b-col><a href="#" @click="selectcompany()">{{$t('buttons.selectall')}}</a></b-col>
              <b-col><a href="#" @click="deselectcompany()">{{$t('buttons.selectnone')}}</a></b-col>
            </b-row>
            <b-row>
            <b-col cols="3" v-for="key of company" :key="key" >
              <b-form-checkbox @change="exampleupdate()" v-model="props[key]">{{translate(key)}}</b-form-checkbox>        
              </b-col>   
            </b-row>
            <b-row>
              <b-col><h3>Beoordeling</h3></b-col>
              <b-col><a href="#" @click="selectscoring()">{{$t('buttons.selectall')}}</a></b-col>
              <b-col><a href="#" @click="deselectscoring()">{{$t('buttons.selectnone')}}</a></b-col>
            </b-row>

            <b-row>
            <b-col cols="3" v-for="key of scoring" :key="key" >
              <b-form-checkbox @change="exampleupdate()" v-model="props[key]">{{translate(key)}}</b-form-checkbox>        
              </b-col>   
            </b-row>
            <div>
              {{ example }}
            </div>

          </b-container>
        </div>
        <div class="popup-footer">
            <div @click="save_and_close" class="btn btn-primary"><font-awesome-icon icon="download" /> {{$t("labels.export")  }}</div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
import { mapGetters } from 'vuex'

// const querystring = require("querystring");

export default {
    name: 'export_search_modal',
    data() {
        return {
          props:[],
          seperator:";",
          custom:",",
          redraw:0,
          example:"",
          naw:["role","email","sex","firstname","middlename","lastname","userfunction","mobile","birthday","postcode","adres","housenumber","city","province"],
          company:["companyname","bsn","identificationcode","identification_endate","kvkcode","kvk_enddate","vatcode","iban","bic","vca","vca_endate","gpi","gpi_endate","latest"],
          scoring:["rating","V","Vremarks","A","Aremarks","K","Kremarks"],
          preferences:[ "newsletter","blacklist","notifications","callat","available_from","latest"]
        }
    },
    props: {
      user_set: Array,
    },
    mounted() {
      for(const [key] of Object.entries(this.user_set[0])){
        this.props[key] = false;
      }
    },


    computed: {
        ...mapGetters([
            'username_by_id',
            'user_by_id',
            'owners'
            
        ]),  

    },
    methods: {
      translate(key){
        let t  = key
        
        if(this.$i18n.te('labels.'+key.toLowerCase())){
          t = this.$i18n.t('labels.'+key.toLowerCase())
        } else {
          if(this.$i18n.te('meta.'+key.toLowerCase())){
            t = this.$i18n.t('meta.'+key.toLowerCase())
          } 
        }
        return(t)
      },
      selectnaw(){
        this.naw.forEach(s => {this.props[s]= true})
        this.exampleupdate()
      },
      deselectnaw(){
        this.naw.forEach(s => {this.props[s]= false})
        this.exampleupdate()
      },
      selectcompany(){
        this.company.forEach(s => {this.props[s]= true})
        this.exampleupdate()
      },
      deselectcompany(){
        this.company.forEach(s => {this.props[s]= false})
        this.exampleupdate()
      },
      selectscoring(){
        this.scoring.forEach(s => {this.props[s]= true})
        this.exampleupdate()
      },
      deselectscoring(){
        this.scoring.forEach(s => {this.props[s]= false})
        this.exampleupdate()
      },
      exampleupdate(){
        let csv=""
          var sep = ";"
          if(this.seperator=="tab"){
            sep = '\t';
          }
          if(this.seperator=="other"){
            sep = this.custom;
          }

          for(const key in this.props){
              if(this.props[key]){
                if(this.user_set[0][key]) {
                  csv +=  '"'+this.user_set[0][key]+'"'+sep
                } else {
                  csv += '""'+sep
                }
              }
            }

        this.example = csv
      },
        modalValid(){
          return this.$i18n.t("buttons.sendmessage")
        },
        async save_and_close() {
          // console.log(this.props)
          var sep = ";"
          if(this.seperator=="tab"){
            sep = '\t';
          }
          if(this.seperator=="other"){
            sep = this.custom;
          }
          let index = 0;
          var csv ="";
          for(const key in this.props){
            if(this.props[key]){csv += '"'+key+'"'+sep}
          }
          csv+="\n";
          while (index<this.user_set.length){
            for(const key in this.props){
              if(this.props[key]){
                if(this.user_set[index][key]) {
                  csv +=  '"'+this.user_set[index][key]+'"'+sep
                } else {
                  csv += '""'+sep
                }
              }
            }
            csv+="\n";
            index++
           }
           console.log(csv)
           const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
            anchor.target = '_blank';
            anchor.download = 'Export.csv';
            anchor.click();           
        },
    },
}
</script>