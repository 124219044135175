<template>
  <b-container fluid>
    
    <div class="title-bar">
      <h1>{{ $t("titles.contracts") }}</h1>
      <div @click="addContractModal({ id: 0 })" class="btn btn-primary">
        <font-awesome-icon icon="plus" /> {{ $t("buttons.addcontract") }}
      </div>

    </div>
    <b-row class="filter-bar">
      <b-col>
        <b-form-group :label="$t('labels.search')">
          <b-form-input id="filter-input" v-model="filter" type="search" :placeholder="$t('strings.typetosearch')">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group :label="$t('labels.branche')">
          <b-form-select name="branches" class="form-control" v-model="currentbranche">
            <option value="-1"> All</option>
            <option v-for="(branche,idx) in activebranches" :key="idx" v-bind:value="branche.id">
              {{ branche["name"]}}
            </option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-table sort-icon-left :items="contractinfo" :fields="fields" :tbody-tr-attr="rowClass" :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" @row-clicked="onContractClicked">
      <template #cell(color)="row">
        <a href="javascript:;" class="float-right colorset" v-bind:style="{ color: classcolor1}"
          @click="color1(row.item)">
          <font-awesome-icon icon="square" />
        </a>
        <a href="javascript:;" class="float-right colorset" v-bind:style="{ color: classcolor2}"
          @click="color2(row.item)">
          <font-awesome-icon icon="square" />
        </a>
        <a href="javascript:;" class="float-right colorset" v-bind:style="{ color: classcolor3}"
          @click="color3(row.item)">
          <font-awesome-icon icon="square" />
        </a>
      </template>
      <template #cell(actions)="row">
        <a href="javascript:;" class="action-item" @click="editContractModal(row.item.project_id,row.item.user_id)">
          <font-awesome-icon icon="cog" />
        </a>
        <font-awesome-icon v-if="row.item.signed_zzpr==0" icon="hammer" style="color: red;" />
        <font-awesome-icon v-else icon="hammer" style="color: green;" />
        <font-awesome-icon v-if="row.item.signed_company==0" icon="pen" style="color: red;" />
        <font-awesome-icon v-else icon="pen" style="color: green;" />
      </template>
    </b-table>
  </b-container>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'planningscreen',
  data() {
    return {
      sortBy: 'name',
      sortDesc: false,
      currentbranche: -1,
      startw: "",
      endw: "",
      filter: null,
      filterOn: [],

      classcolor1: "#ff0000",
      classcolor2: "#ff0000",
      classcolor3: "#ff0000",
    }
  },
  mounted() {
    this.startweekinit();
    this.classcolor1 = this.settings.status_colors[0].color
    this.classcolor2 = this.settings.status_colors[1].color
    this.classcolor3 = this.settings.status_colors[2].color

  },
  computed: {

    contractinfo() {
      let values = [];
      for (var i = 0; i < this.contracts.length; i++) {
        let userplan = this.userplanning(this.contracts[i].user_id)
        for (var p = 0; p < userplan.length; p++) {
          let row = {};
          // let user =this.user_by_id(this.contracts[i].user_id)
          if (!(this.contracts[i].signed_zzpr == 1 && this.contracts[i].signed_company == 1) && userplan[p].project_id == this.contracts[i].project_id) {
            let project = this.project_by_id(this.contracts[i].project_id)
            if (project) {
              row.project = userplan[p].project_name
              row.id = userplan[p].id
              row.user_id = this.contracts[i].user_id
              row.sign_id = userplan[p].sign_id

              row.contact_id = this.contracts[i].user_id
              row.project_id = userplan[p].project_id
              row.startdate = userplan[p].startdate
              row.enddate = userplan[p].enddate
              row.hourregistration = userplan[p].hourregistration
              let company = this.company_by_id(project.company_id)
              row.company_name = company.company_name
              row.description = userplan[p].project_description
              row.branche_id = company.branche_id
              row.signed_zzpr = this.contracts[i].signed_zzpr
              row.signed_company = this.contracts[i].signed_company

              row.color = this.contracts[i].color
              row.contract_id = this.contracts[i].id
            }
            values.push(row)
          }
        }
      }
      return values
    },
    fields() {
      return [
        { key: 'user_id', label: this.$i18n.t('labels.ZZPer'), formatter: "ZZPer", sortable: true, sortDirection: 'desc' },
        { key: 'sign_id', label: this.$i18n.t('labels.contactperson'), formatter: "signer" },
        { key: 'company_name', label: this.$i18n.t('labels.companyname'), sortable: true, sortDirection: 'desc' },
        { key: 'project', label: this.$i18n.t('labels.project'), sortable: false, sortDirection: 'desc' },
        { key: 'startdate', label: this.$i18n.t('labels.startdate'), sortable: true, sortDirection: 'desc' },
        { key: 'enddate', label: this.$i18n.t('labels.enddate'), sortable: true, sortDirection: 'desc' },
        { key: 'hourregistration',sortable: true},
        { key: 'actions', label: this.$i18n.t('labels.actions') },
      ]
    },
    ...mapState([
      'projects',
      'users',
      'companies',
      'contracts',
      'branches',
      'planning',
      'settings'
    ]),
    ...mapGetters([
      'project_by_id',
      'company_by_id',
      'contract_by_id',
      'zzpers',
      'user_by_id',
      'username_by_id',
      'activebranches'
    ]),

  },
  methods: {
    onContractClicked(row) {
      this.$router.push({ path: '/project/' + row.project_id });
    },
    ZZPer(user_id) {
      return this.username_by_id(user_id)
    },
    signer(user_id) {
      return this.username_by_id(user_id)
    },
    ...mapActions([
      'save_contract',
    ]),
    color1(item) {
      this.contract_by_id(item.contract_id).color = this.settings.status_colors[0].color
      this.save_contract(item.contract_id)
      item.color = this.settings.status_colors[0].color
    },

    color2(item) {
      this.contract_by_id(item.contract_id).color = this.settings.status_colors[1].color
      this.save_contract(item.contract_id)
      item.color = this.settings.status_colors[1].color
    },
    color3(item) {
      this.contract_by_id(item.contract_id).color = this.settings.status_colors[2].color
      this.save_contract(item.contract_id)
      item.color = this.settings.status_colors[2].color
    },
    rowClass(item) {
      return { "style": "box-shadow:0px 0px 0px 5px " + item.color + " inset" }
    },

    getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    startweekinit() {
      let now = new Date()
      let sweek = this.getWeekNumber(now)
      this.startw = sweek[0] + "-W" + sweek[1]
      let eweek = this.getWeekNumber(new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000))
      this.endw = eweek[0] + "-W" + eweek[1]
    },
    userplanning(user_id) {
      let _planning = []
      for (var i = 0; i < this.planning.length; i++) {
        if (this.planning[i].user_id == user_id) {
          _planning.push(this.planning[i])
        }
      }
      return _planning
    },

  },
  watch: {

  }
}
</script>