<template>
  <div class="container">
    <FrontendLogo/>
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-8">
        <h1>{{ $t('titles.login') }}</h1>
        <b-form @submit="onSubmit" @reset="onReset">
          <b-form-group id="usernamegroup" :label="$t('labels.email')" label-for="username" description="">
            <b-form-input id="username" type="text" v-model="form.username" required
              :placeholder="$t('labels.loginuser')"></b-form-input>
          </b-form-group>

          <b-form-group id="passwordgroup" :label="$t('labels.password')" label-for="password" description="">
            <b-input-group>

              <b-form-input ref="password" id="password" :type="showpass ? 'text' : 'password'" v-model="form.password"
                required :placeholder="$t('labels.loginpassword')" autocomplete="plantool-password"></b-form-input>

              <div class="input-group-append">
                <div id="toggle-password" v-on:click="togglePass">
                  <font-awesome-icon v-if="showpass" icon="eye" />
                  <font-awesome-icon v-else icon="eye-slash" />
                </div>
              </div>
            </b-input-group>
          </b-form-group>
          <b-row>
            <b-col cols="1" >
              <b-form-checkbox  v-model="agreed" name="agreeterms" />
            </b-col>
            <b-col>
              {{ termspre }}<router-link to="/terms" :style="AppPrimaryColor()">{{ this.settings.termstitle }}</router-link> {{ termsbetween }}<router-link to="/privacy" :style="AppPrimaryColor()">{{ this.settings.privacytitle }}</router-link>
            </b-col>
          </b-row>
          <div class="form-buttons">
            <b-button type="reset" variant="link">{{ $t('buttons.resetpassword') }}</b-button>
            <b-button :style="AppPrimaryButton()" v-if="agreed" type="submit" variant="primary">
              <font-awesome-icon icon="arrow-right" />{{ $t('titles.login') }}
            </b-button>
          </div>

          <div class="mt-3">
            <b-alert :variant="messageVariant" :show="showMessage">{{ message }}</b-alert>
          </div>
        </b-form>
        <b-button v-if="buildtarget=='apprelease'" href="#" active-class="active" @click="switchPortal()">
          <font-awesome-icon icon="portal-exit" /> {{ $t("titles.switchportal") }}
        </b-button>  
        <div class="mt-3">
            {{ settings.loginnote }}
          </div>
      </div>

    </div>

  </div>


</template>

<script>
// import axios from 'axios';
import axios from 'axios';
import { mapActions, mapState, mapGetters } from 'vuex';
import RequestPassword from './modals/RequestPassword.vue'
import FrontendLogo from './parts/FrontendLogo.vue'

require('dotenv').config()

export default {
  name: 'login',
  data() {
    return {
      checkcolor:"",
      form: {
        username: '',
        password: ''
      },
      showpass: false,
      message: '',
      messageVariant: 'info',
      showMessage: false,
      agreed:null
    }
  },
  mounted() {
    this.checkcolor = this.primaryColor
    // check if argument code exists (boekhoudgemak)
    if(window.location.href.indexOf("code=")!=-1) {
      let code = window.location.href.substring(window.location.href.indexOf("code=")+5).slice(0, -2)
      if(code) {
        axios.post('/api/token', {code:code})
      }
    }
    // check if firebasetoken exists
    if(window.location.href.indexOf("fcmToken=")!=-1) {
      let token = window.location.href.substring(window.location.href.indexOf("fcmToken=")+9).slice(0, -2)
      this.$store.commit('firebasetoken', `${token}`)
    }
    console.log("LOGIN mounted ", JSON.stringify(this.current_user))
    if(this.current_user) {
      if(this.current_user.role != "admin") {
        this.$router.push({ path: '/tasks' });
      } else {
        this.$router.push({ path: '/users' });
      }
    }
  },
  computed: {
    ...mapGetters([
      'server_url',
      'portal_logo'
  ]),
  ...mapState([
      'current_user',
      'firebasetoken',
      'loginfailed',
      'settings'
    ]),
    termspre(){
      return this.settings.loginterms?this.settings.loginterms.substring(0, this.settings.loginterms.indexOf('[conditions]')):""
    },
    termsbetween(){
      return this.settings.loginterms?this.settings.loginterms.substring(this.settings.loginterms.indexOf('[conditions]')+13,this.settings.loginterms.indexOf('[privacy]')):""
    },


  },
  methods: {
    ...mapActions([
      'login',
      'logout'
    ]),

    onSubmit(evt) {
      if(this.agreed){
        evt.preventDefault();
        this.message = this.$i18n.t('validation.loginloading');
        this.messageVariant = 'success';
        this.showMessage = true;

        this.login(this.form)    
      }
    },
    togglePass() {
      this.showpass = !this.showpass
    },
    onReset(evt) {
      evt.preventDefault();
      this.$modal.hide('RequestPassword')
      this.$modal.show(RequestPassword, {
      }, {
        name: 'RequestPassword',
        draggable: false,
        height: 'auto',
        width: '90%'
      })
      this.$emit('close')
    }
  },
  components: {FrontendLogo},
  watch:{
    loginfailed(){
      if(this.loginfailed){
        this.message = this.$i18n.t('validation.loginfailed');
        this.messageVariant = 'danger';
      }

    },
    current_user(){
      if(this.current_user) {
        if(this.current_user.role != "admin") {
          this.$router.push({ path: '/user/'+this.current_user.id });
        } else {
          this.$router.push({ path: '/users' });
        }
      }     
    },
    settings(){
      this.checkcolor = this.primaryColor

    }
  }
}
</script>
<style>
/* .custom-control-input:checked~.custom-control-label::before {
  background-color: v-bind(checkcolor) !important;
  border-color: v-bind(checkcolor) !important;
} */

</style>