<template>
  <b-container fluid>
    <input type="hidden" :key="componentKey" />

    <div class="title-bar">
      <h1>{{project.title}}</h1>
    </div>
    <b-row>
      <b-col cols="8">
        <h2>{{ $t("labels.description") }}</h2>
        {{project["description"]}}
      </b-col>
      <b-col>
        <b-row>
          <b-col>
            <h2>{{ $t("labels.duration") }}</h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            {{this.formatDate(project["startdate"])}}
          </b-col>
          <b-col>
            {{this.formatDate(project["enddate"])}}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2>{{ $t("labels.location") }}</h2>
            {{project["location"]}}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h2>{{ $t("labels.workfields") }}</h2>
        <span v-html="this.brancheName()"></span>

      </b-col>
    </b-row>
    <br />
    <b-row class="filter-bar">
      <b-col>
        <img style="height: 10rem;width: 10rem;object-fit: contain;"
          :src="companyimageto(project.company_id)" />


        <b-form-group :label="$t('labels.client')">
          {{this.company_by_id(project.company_id).company_name }}
        </b-form-group>
      </b-col>
      <b-col>
        <img style="height: 10rem;width: 10rem;object-fit: contain;;"
        :src="profileto(project.user_id)" />
        <b-form-group :label="$t('labels.contactperson')">
          {{this.username_by_id(this.user.id)}}
        </b-form-group>

      </b-col>
    </b-row>
    <a href="javascript:;" class="btn btn-primary" @click="react_to_project()">
      <font-awesome-icon icon="heart" /> {{ $t('labels.subscribe_project') }}
    </a>

  </b-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import subscribe_to_project_modal from './modals/SubscribeToProjectModal.vue'

export default {
  name: 'projectscreen',
  data() {
    return {
      selectedZZPer: null,
      selectedID: null,
      componentKey: 0,
      callbacknote: "callbacknote",


    }
  },
  computed: {
    fields() {
      return [
        { key: 'photo', label: this.$i18n.t('labels.photo'), },
        { key: 'id', label: this.$i18n.t('labels.name'), formatter: "username_by_id", sortable: true, sortDirection: 'desc' },
        { key: 'startdate', formatter: this.formatDate, label: this.$i18n.t('labels.startdate'), sortable: true, sortDirection: 'desc' },
        { key: 'enddate', formatter: this.formatDate, label: this.$i18n.t('labels.enddate'), sortable: true, sortDirection: 'desc' },
        { key: 'description', label: this.$i18n.t('labels.description') },
        { key: 'actions', label: this.$i18n.t('labels.actions') },
      ]
    },
    ...mapState([
      'projects',
      'users',
      'companies',
      'contracts',
      'planning',
      'selectedZZPr',
      'workfields'
    ]),
    ...mapGetters([
      'project_metas',
      'project_by_id',
      'company_by_id',
      'opdrachtgevers',
      'contactpersonen',
      'zzpers',
      'branche_by_id',
      'user_by_id',
      'workfield_by_id',
      'username_by_id',
      'confirm_modal',
      'user_photo_by_id',
      'createAny',
      'append_data_string',
      'find_contract_id',


    ]),
    project: {
      get() {
        return this.project_by_id(this.$route.params.id)
      },
    },
    user() {
      return this.user_by_id(this.project.user_id)
    },
    zzprers_in_project: {
      get() {
        let users = []
        if (this.project.zzprusers) {
          for (var i = 0; i < this.project.zzprusers.length; i++) {
            let auser = this.user_by_id(this.project.zzprusers[i])
            if (auser) {
              for (var p = 0; p < this.planning.length; p++) {
                if (this.planning[p].project_id == this.project.id && this.planning[p].user_id == auser.id) {
                  auser.startdate = this.planning[p].startdate
                  auser.enddate = this.planning[p].enddate
                  auser.description = this.planning[p].project_description;
                  let contract_id = this.find_contract_id(this.planning[p].project_id, auser.id)
                  auser.color = this.contract_by_id(contract_id).color != undefined ? this.contract_by_id(contract_id).color : '#eeeeee'

                }
              }
              users.push(auser)
            }
          }
        }
        return users
      }
    },
    company_contact_persons: {
      get() {
        let users = []
        let company_contacts = this.company_by_id(this.project.company_id).contactpersons
        for (var i = 0; i < company_contacts.length; i++) {
          if (company_contacts[i]) {
            users.push(this.user_by_id(company_contacts[i]))
          }
        }
        return users
      }
    },
    // workfields: {
    //   get() {
    //     let workfields = "";
    //     for (var i = 0; i < this.project.workfields.length; i++) {
    //       let workfield = this.workfield_by_id(this.project.workfields[i])
    //       workfields += workfield.name + ", "
    //     }
    //     return workfields
    //   }
    // }



  },
  methods: {
    react_to_project() {
      this.$modal.hide('subscribe_to_project_modal')
      this.$modal.show(subscribe_to_project_modal, {
        project_id: this.project.id,
      }, {
        name: 'subscribe_to_project_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '90%',
        shiftY: 0
      })

      this.$emit('close')
    },

    brancheName() {
      const branch = this.branche_by_id(this.company_by_id(this.project.company_id).branche_id);
      var ret = ""
      if (branch) {
        ret += "<b>" + branch.name + "</b><br>"
        for (var i = 0; i < this.workfields.length; i++) {
          if (this.project.workfields.indexOf(this.workfields[i]["id"]) != -1) {
            ret += this.workfields[i]["name"] + "<br>"
          }
        }
      }
      return ret
    },
    ...mapActions([
      'save_project',
      'save_project_relations',
      'remove_project_relations',
      'save_contract',
      'save_user'

    ]),



  },

}
</script>

