<template>
  <b-container v-if="createAny('users') && (currentUserIsSuperAdmin() || specialRights('URsenderemail'))" fluid>

    <!-- Title bar -->
    <div class="title-bar">
      <h1>{{ $t("buttons.emailsenders") }}</h1>
    </div>
    <p>
      {{ $t("validation.emailsenderexplain") }}
    </p>
    <b-button v-if="authurl" @click="authenticate">        <font-awesome-icon icon="plus" /> {{ $t("buttons.addemailsender") }}</b-button>
    <b-table sort-icon-left :items="authemailsenders" :fields="fields" :current-page="currentPage" :per-page="perPage"
      :filter="filter" :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection" @row-clicked="onAuthEmailSenderClicked">
      <template #cell(pulldown)="row">
          <b-dropdown id="dropdown-dropup" dropup :text="$t('buttons.doaction')" variant="primary">
          <b-dropdown-item @click="confirm_delete_auth_user(row.item)">
            <font-awesome-icon icon="trash" /> {{ $t('labels.deleteemailsender') }}
          </b-dropdown-item>
          <b-dropdown-item @click="onAuthEmailSenderClicked(row.item)">
            <font-awesome-icon icon="cog" /> {{ $t('labels.editemailsender') }}
          </b-dropdown-item>
        </b-dropdown>          
      </template>
    </b-table>
    <!-- old stuff  -->
    <div v-if="false">
      <b-form-group :label="$t('labels.defaultmailname')">
        <b-form-input type="text" class="form-control" v-model="settings.defaultmailname" />
      </b-form-group>
      <b-form-group :label="$t('labels.defaultmailuser')">
        <b-form-input type="text" class="form-control" v-model="settings.defaultmailuser" />
      </b-form-group>
      <b-form-group :label="$t('labels.defaultmailpassword')">
        <b-form-input type="password" class="form-control" v-model="settings.defaultmailpassword" />
      </b-form-group>        

      <!-- Save button -->
      <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
        <font-awesome-icon icon="plus" /> {{ $t("buttons.savesettings") }}
      </div>


    <div @click="edit_emailsender({ id: 0 })" class="btn btn-primary">
        <font-awesome-icon icon="plus" /> {{ $t("buttons.addemailsender") }}
      </div>
    <!-- Table -->
    <b-table sort-icon-left :items="emailsenders" :fields="fields" :current-page="currentPage" :per-page="perPage"
      :filter="filter" :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection" @filtered="onFiltered" @row-clicked="onEmailSenderClicked">
      <template #cell(active)="row">
        <font-awesome-icon v-if="row.item.active" icon="check" style="color: green;"
          :title="$t('labels.editemailsenderoff')" />
        <font-awesome-icon v-else icon="times" style="color: red;" :title="$t('labels.editemailsenderon')" />
      </template>
      <template #cell(pulldown)="row">
        <b-dropdown id="dropdown-dropup" dropup :text="$t('buttons.doaction')" variant="primary">
          <b-dropdown-item @click="confirm_delete_emailsender(row.item)">
            <font-awesome-icon icon="trash" /> {{ $t('labels.deleteemailsender') }}
          </b-dropdown-item>
          <b-dropdown-item @click="edit_emailsender(row.item)">
            <font-awesome-icon icon="cog" /> {{ $t('labels.editemailsender') }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-pagination v-if="totalRows > perPage" v-model="currentPage" :total-rows="totalRows" :per-page="perPage">
    </b-pagination>
  </div>
  </b-container>
</template>
<script>

import axios from 'axios'
import { mapGetters, mapState, mapActions } from 'vuex'
import emailsender_details_modal from './modals/EmailSenderModal.vue'
import popup_modal from './modals/PopupModal.vue'

export default {
  name: 'emailsenders',
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      sortBy: 'id',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      selectedID: null,
      authurl:"",
      authenticatedUsers:[],
      addedAuthEmail:""

    }
  },
  mounted() {
      fetch('/api/msal/authurl')
      .then((response) => response.json())
      .then((data) => this.authurl = data)      
    },  
  computed: {
    ...mapGetters([
      'createAny',
      'confirm_modal',
      'emailsender_by_id'
    ]),
    ...mapState([
      'current_user',
      'emailsenders',
      'authemailsenders'
    ]),
    totalRows() {
      return this.emailsenders.length
    },
    fields() {
      return [
        { key: 'name', sortable: true, sortDirection: 'desc' },
        { key: 'email', sortable: true, sortDirection: 'desc' },
        { key: 'pulldown', label: this.$i18n.t('labels.actions') },
      ]
    },
  },
  methods: {
    ...mapActions([
      'save_emailsender',
      'add_emailsender',
      'delete_emailsender'
    ]),

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onAuthEmailSenderClicked(row){
      let signature = this.emailsenders.filter(es =>es.email==row.email)
      if(signature.length>0){
        this.$modal.hide('emailsender_details_modal')
        this.$modal.show(emailsender_details_modal, {
        emailsender_id: signature[0].id,
          }, {
            name: 'emailsender_details_modal',
            draggable: '.popup-header',
            height: 'auto',
            width: '800px'
          })

          this.$emit('close')        
            
      } else{
        this.addedAuthEmail = row.email
        this.add_emailsender({id:0, name: row.name, email : row.email, signature:"", active: 1})

      }
    },  
    onEmailSenderClicked(row) {
      let emaillsenderclicked = this.emailsender_by_id(row.id)
      if (emaillsenderclicked.active == 1) {
        emaillsenderclicked.active = 0
      } else {
        emaillsenderclicked.active = 1
      }
      this.save_emailsender(row.id)
    },
    edit_emailsender(emailsender) {
      this.$modal.hide('emailsender_details_modal')
      this.$modal.show(emailsender_details_modal, {
        emailsender_id: emailsender.id,
      }, {
        name: 'emailsender_details_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '800px'
      })

      this.$emit('close')
    },
    confirm_delete_auth_user(item) {
      this.selectedID = item
      let message = this.$i18n.t('labels.confirmdeletehead') + item.name + this.$i18n.t('labels.confirmdeletetail')
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
        message: message,
      }, {
        name: 'popup_modal',
        draggable: false,
        height: 'auto',
        width: '500px'
      }, {
        'before-close': this.modalCloseAuth
      })

      this.$emit('close')

    },    
    modalCloseAuth() {
      if (this.confirm_modal.id == 1) {
        axios.post('/api/msal/logout', { email : this.selectedID.email})
        .then(()=>{  
          this.$store.dispatch('get_authemailsenders')
        })
      }
    },
    authenticate(){
      window.open(this.authurl);
    }   

  },
  components: {},
  watch:{
    emailsenders(){
      let signature = this.emailsenders.filter(es =>es.email==this.addedAuthEmail)
      if(signature.length>0){
        this.addedAuthEmail=""
        this.$modal.hide('emailsender_details_modal')
        this.$modal.show(emailsender_details_modal, {
        emailsender_id: signature[0].id,
          }, {
            name: 'emailsender_details_modal',
            draggable: '.popup-header',
            height: 'auto',
            width: '800px'
          })

          this.$emit('close')        
            
      }      
    }
  }
}
</script>