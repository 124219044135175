<template>
  <b-container v-if="createAny('users')" fluid>
    <h1>Kosten portal laatste 4 weken</h1>
    <dl>
      <dt>License</dt>
      <dd>{{ invoicedata.license }}</dd>
      <dt>countZZPers</dt>
      <dd>{{ invoicedata.countZZPers }}</dd>
      <dt>countSigners</dt>
      <dd>{{ invoicedata.countSigners }}</dd>
      <dt>Navigation Modules</dt>
      <dd>{{ invoicedata.navigationmodules }}</dd>
      <dt>Buttons Modules</dt>
      <dd>{{ invoicedata.buttonmodules }}</dd>
      <dt>Option Modules</dt>
      <dd>{{ invoicedata.optionmodules }}</dd>
      <dt>Totale kosten</dt>
      <dd>{{ totalcosts()}}</dd>
    </dl>
    <table>
      <tr>
        <td v-for="(week, index) in invoicedata.perweek" :key="index">
          {{ week.startdate }} - {{ week.enddate }}<br>
          {{ week.usercount }} ZZP'ers<br>
          {{ week.signerscount }} Signers <br>
          Licentiekosten : {{ licensecosts }}<br>
          Gebruikerskosten  : {{ userscost(week) }}<br>
        </td>
      </tr>
    </table>
    {{ invoicedata.adresinfo }}<br>
    {{ invoicedata.leverinfo }}<br>
    
  </b-container>
</template>
<script>
import axios from 'axios';
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'ffinvoice-screen',
  data() {
    return {
      invoicedata:""

    }
  },
  async mounted(){
    var response = await axios.get('/api/licenseinfo')
    this.invoicedata = response.data
  },

  computed: {

    ...mapState([
      'settings',
      'users',
      'contracts',
      'hours'

    ]),
    ...mapGetters([
      'server_url',
      'createAny'
    ]),
    licensecosts(){
      var costs = 0
      if(this.invoicedata.licenseid==2){
        costs = 150;
      }
      if(this.invoicedata.licenseid==3){
        costs = 300;
      }      
      return costs
    }
  },
  methods: {
    ...mapActions([
    ]),
    userscost(week){
      var usercosts = 0
      if(this.invoicedata.licenseid==1){
        usercosts = 25;
      }
      if(this.invoicedata.licenseid==2){
        usercosts = 6.5;
      }       
      var users=0
      if(this.invoicedata.countZZPers){
        users +=week.usercount
      }
      if(this.invoicedata.countSigners){
        users +=week.signerscount
      }      
      return users*usercosts
    },
    totalcosts(){
      var totalcosts = 0;
      this.invoicedata.perweek.forEach(week=>{
        totalcosts+= this.licensecosts;
        totalcosts+= this.userscost(week) 
      })
      return totalcosts
    }   

  },

}
</script>