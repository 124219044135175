<template>
  <div class="notification"  v-if="settings.PMHistory && current_user">
    <div v-if="pms.length>0" class="indicator" @click="showNotifications">{{ pms.filter(pm=>pm.isread==0 && pm.from_id!=current_user.id).length }}</div>
  </div>
</template>
<script>

import { mapGetters, mapState, mapActions } from 'vuex'
// import ViewNotificationModal from './modals/ViewNotificationModal.vue';

export default {
    name: 'notificationLayer',
    data() {
        return {
        }
    },
    computed: {
    ...mapGetters([
      'createAny',
      'readAny',
      'updateAny',
      'deleteAny',
      'user_by_id',
      'is_logged_in',
      'notifications',
      'pms',

    ]),
    ...mapState([
        'current_user',
        'settings'

    ]),
  },
    methods: {
        ...mapActions([
            'logout',
        ]),
        showNotifications(){
          this.$router.push('/pms')
        //   this.$modal.hide('ViewNotificationModal')
        //   this.$modal.show(ViewNotificationModal, {}, {
				// 		name: 'ViewNotificationModal',
				// 		draggable: false,
				// 		width: '90%',
        //     height: '80%'
				// })	
        }
    },
    components: {},

}
</script>
<style>
.notification{
	height:2.5rem; 
	width:2.5rem;
	position:fixed; 
	right:15px;
	top:35px;
	z-index:99;
  /* background-color: blue; */
}
.indicator {
	position:fixed;
	right:1rem;
	top:35px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ff0000;
	color: #fff;
	border-radius: 50%;
	font-size: .8rem;
	width: 2rem;
	height: 2rem;
  cursor: pointer;
}
  </style>