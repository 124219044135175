<template>
	<div class="popup">

        <div class="popup-header">
            <div>
                 {{ $t("labels.editplanning") }}
            </div>
            <div  class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body" style="overflow:hidden">
          <b-container>

          <div class="row">
            <div class="col-3">{{$t('labels.user')}}</div>
            <div class="col">{{ username_by_id(user.id) }}</div>
          </div>
          <div class="row">
            <div class="col-3">{{$t('labels.project')}}</div>
            <div class="col">{{project.title }}</div>
          </div>
          <div class="row">
            <div class="col">{{$t('labels.startdate')}} </div>
            <div class="col"><input type="date" v-model='initstart'></div>
            <div class="col">{{$t('labels.enddate')}} </div>
            <div class="col"><input type="date" v-model='initend'></div>
          </div>
          <div class="row">
            <div class="col">{{$t('meta.starttijd')}}</div>
            <div class="col"><input type="time" v-model='thiswax.start_time'></div>
            <div class="col">{{$t('meta.eindtijd')}}</div>
            <div class="col"><input type="time" v-model='thiswax.end_time'></div>
          </div>     
          <div  class = "row" v-for="(res, idx) in user_resource" :key="res+idx">
            <div class="col-2">{{$t('labels.resource')}}</div>
            <div class="col-5"><b-form-checkbox  v-model="resourcecheck[res.id]">{{$t('labels.planresourcechange')}}</b-form-checkbox></div>
            <div class="col-5">{{ res.name }} - {{ res.identification }}</div>  
          </div>             
          <checkuserplan :user_id="user_id" :startdate="initstart" :enddate="initend" />

          </b-container>
        </div>
        <div class="popup-footer">
            <div class="btn btn-primary" @click="$emit('close')">{{ $t("buttons.cancel") }}</div>
            <div @click="delete_wax" class="btn btn-primary">{{ $t("labels.deleteproject") }}</div>
            <div @click="save_and_close" class="btn btn-primary">{{ $t("buttons.saveandclose") }}</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {  mapGetters, mapState, mapActions} from 'vuex'
import checkuserplan from './CheckUserPlanning.vue';

export default {
    name: 'clickonuser-modal',
    data() {
        return {
          confirmed:false,
          initstart:null,
          initend:null,
          initstartt:null,
          initendt:null,          
          resource_id:null,
          resourcecheck:[]

        }
    },
    props: {
        ffwax_id:Number,
    },
    mounted() {
        let initstart = new Date(this.project.startdate)
        initstart.setHours(0,0,0,0)
        initstart.setDate(initstart.getDate() + this.thiswax.start_offset);
        this.initstart = this.dateToYYYYMMDD(initstart)
        let initend = new Date(this.initstart)
        initend.setDate(initend.getDate() + this.thiswax.end_offset);
        this.initend = this.dateToYYYYMMDD(initend)

    },
    computed: {
      ...mapGetters ([
      'zzpers',
      'project_by_id',
      'user_by_id',
      'username_by_id',
       'createAny',
       'ffwax_by_id'

  ]),
  ...mapState ([
      'ffwax',
      'projectresources',
      'resources'

    ]), 
    thiswax(){
      return this.ffwax_by_id(this.ffwax_id)
    },
    user() {
     return this.user_by_id(this.thiswax.user_id)
    },
    project() {
     return this.project_by_id(this.thiswax.project_id)
    },
    start_offset(){
    return Math.round(( new Date(this.initstart).getTime() - new Date(this.project.startdate).getTime())/86400000)
    },
    end_offset() {
      return Math.round(( new Date(this.initend).getTime() - new Date(this.initstart).getTime())/86400000)
    },    
    user_resource(){
      return this.resources.filter(r=>r.user_id==this.user.id).filter(r => this.projectresourcesfromproject.filter(prt=>prt.resource_id==r.type).length>0)
      
    },  
    projectresourcesfromproject(){
      return this.projectresources.filter(pr=>pr.project_id==this.project.id && pr.resource_id!=null)
    },     
    },
    methods: {
      ...mapActions([
      'add_ffwax',
      'save_ffwax',
      'delete_ffwax'
    ]),      
        save_and_close() {
          //overwrite offset
          this.thiswax.start_offset = this.start_offset
          this.thiswax.end_offset = this.end_offset
          this.save_ffwax(this.thiswax.id)
          for(var i=0; i<this.user_resource.length;i++){
            if(this.resourcecheck[this.user_resource[i].id]==true){
              // get ffwax
              let ffw = this.ffwax.filter(ff => ff.resource_id == this.user_resource[i].id && ff.project_id==this.thiswax.project_id)[0]
                ffw.start_offset = this.start_offset
                ffw.end_offset = this.end_offset
                ffw.start_time = this.thiswax.start_time
                ffw.end_time = this.thiswax.end_time
                this.save_ffwax(ffw.id)
              }
          }        
          this.$emit('close')
        },
        delete_wax() {
          let restoclear = this.projectresources.filter(pr => pr.filled==this.thiswax.user_id && pr.project_id==this.thiswax.project_id)[0]
          if(restoclear){
            axios.put('/api/set_projectresource_filled', {id:restoclear.id, filled: 0})
            this.projectresource_by_id(restoclear.id).filled=0         
          }

          for(var i=0; i<this.user_resource.length;i++){
            if(this.resourcecheck[this.user_resource[i].id]==true){
              // get ffwax
              let ffw = this.ffwax.filter(ff => ff.resource_id == this.user_resource[i].id && ff.project_id==this.thiswax.project_id)[0]
              this.delete_ffwax(ffw.id)
            }
          }        
          this.delete_ffwax(this.thiswax.id)

          this.$emit('close')
        }

    },
    components :{checkuserplan}

}
</script>