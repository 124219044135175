<template>
	<div class="popup">

        <div class="popup-header">
            <div v-if="hourcategory.id > 0">
                {{hourcategory.name}}
            </div>
            <div v-else>
                 {{ $t("labels.newhourcategory") }}
            </div>
            <div v-if="hourcategory.id == 0" class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
            <div v-else class="popup-close" @click="save_and_close">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
            <b-container>
                <form class="form"  v-on:submit='save_and_close'>
                    <!-- branche name -->
                    <div class="form-row">
                        <div class="col-3">
                          <font-awesome-icon v-if="checkhourcategory" icon="check" style="color: green;"/>
                          <font-awesome-icon v-else icon="times" style="color: red;"/>                         
                          <label for="">{{ $t("labels.hourcategoryname") }}</label>
                        </div>
                        <div class="col">
                            <b-input  ref="deffield"  :state="checkhourcategory" type="text" class="form-control" v-model="hourcategory.name"></b-input>
                            <b-form-invalid-feedback >
                                {{ $t("validation.hourcategorycheck")}}
                            </b-form-invalid-feedback> 

                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">{{ $t("labels.active") }}</label>
                        </div>
                        <div class="col">
                            <select v-model="hourcategory.active" class="form-control">
                                <option value="1">{{ $t("option.active") }}</option>
                                <option value="0">{{ $t("option.inactive") }}</option>
                            </select>                         
                        </div>
                    </div>                
                </form>
            </b-container>
        </div>
        <div class="popup-footer">
            <div v-if="hourcategory.id == 0" class="btn btn-primary" @click="my_add_hourcategory();">{{ addhourcategoryText() }}</div>
            <div v-else @click="save_and_close" class="btn btn-primary">{{ $t("buttons.saveandclose") }}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
    name: 'hourcategory-modal',
    data() {
        return {
            new_hourcategory: { id: 0, active:1}
        }
    },
    props: {
        hourcategory_id: Number
    },
    mounted() {
        this.focusInput()
    },
    computed: {
        hourcategory() {
            return this.hourcategory_id > 0 ? this.hourcategory_by_id(this.hourcategory_id) : this.new_hourcategory
        },
        ...mapGetters([
            'hourcategory_by_id',
        ]),
        ...mapState([
            'hourcategories',
        ]),
        checkhourcategory(){
            if(this.hourcategory.id>0){
                return true
            }            
            let found=false;
            if(this.hourcategory.name!=undefined){
                for(var i=0;i<this.hourcategories.length;i++){
                    if(this.hourcategory.name.toLowerCase()==this.hourcategories[i].name.toLowerCase()){
                        found = true;
                        break;
                    }
                }
            }
            return !found &&this.hourcategory.name!=undefined&& this.hourcategory.name.length>2
        }        
    },
    methods: {
        addhourcategoryText(){
            if (this.checkhourcategory){
                return this.$i18n.t("buttons.add") 
            } else { 
                return this.$i18n.t("buttons.invalid") 
            }
        },          
        save_and_close(e) {
            e.preventDefault() // it prevent from page reload
            if(this.hourcategory.id==0){
                this.my_add_hourcategory()
            }else{
                this.save_hourcategory(this.hourcategory.id)
                this.$emit('close')
            }
        },
        ...mapActions([
            'save_hourcategory',
            'add_hourcategory'
        ]),
        my_add_hourcategory(){
            if(this.checkhourcategory){
                this.add_hourcategory(this.hourcategory);
                this.$emit('close')
            }
        },
    focusInput() {
      this.$refs.deffield.focus();
    }        

    }
}
</script>