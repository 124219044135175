<template>
	<div class="popup">

        <div class="popup-header">
            <div v-if="resource.id > 0">
                {{resource.name}} 
            </div>  
            <div v-else>
                 {{ $t("titles.newresource") }}
            </div>
            <div v-if="resource.id == 0" class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
            <div v-else class="popup-close" @click="save_and_close">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
            <b-container>
                <form class="form" v-on:submit='save_and_close'>
                    <!-- branche name -->
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">{{ $t("labels.name") }}</label>
                        </div>
                        <div class="col">
                            <b-input ref="deffield"  type="text" class="form-control" v-model="resource.name"></b-input>
                        </div>
                         
                    </div>
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">{{ $t("labels.resourcetype") }}</label>
                        </div>
                        <div class="col">
                          <select v-model="resource.type" class="form-control">
                            <option value="onbekend" selected>{{ $t('labels.selecthourcode') }}</option>
                            <option v-for= "(option,idx) in resourceTypes" :key="idx" :value="option.id">{{ option.label }}</option>
                          </select>                            
                        </div>
                         
                    </div>
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">{{ $t("labels.resourceidentification") }}</label>
                        </div>
                        <div class="col">
                            <b-input ref="deffield" :state="checkresource" type="text" class="form-control" v-model="resource.identification"></b-input>
                        </div>
                         
                    </div>
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">{{ $t("labels.invoice_extra_description") }}</label>
                        </div>
                        <div class="col">
                            <b-input ref="deffield" type="text" class="form-control" v-model="resource.description"></b-input>
                        </div>
                         
                    </div>
                    <div class="form-row" style="height:150px">
                        <div class="col-3">
                            <label for="">{{ $t("labels.owner") }}</label>
                        </div>
                        <div class="col" style="z-index:33">
                            <v-select  :options="zzpers" label="fullname" :item-value="'id'" v-model="user" ></v-select>
                        </div>
                         
                    </div>                    
                </form>
            </b-container>
        </div>
        <div class="popup-footer">
            <div v-if="resource.id == 0" class="btn btn-primary" @click="my_add_resource();">{{ addresourceText() }}</div>
            <div v-else @click="save_and_close" class="btn btn-primary">{{ $t("buttons.saveandclose") }}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
    name: 'resource-modal',
    data() {
        return {
            new_resource:{id:0, name:"", type:"onbekend"},
            user:null
        }
    },
    props: {
        resource_id: Number
    },
    mounted() {
        // this.focusInput();
        this.user = this.user_by_id(this.resource.user_id)
    },
    computed: {
        resource() {
            return this.resource_id > 0 ? this.resource_by_id(this.resource_id) : this.new_resource
        },
        ...mapGetters([
            'resource_by_id',
            'user_by_id',
            'zzpers'

        ]),
        ...mapState([
            'resources',

        ]),
        checkresource(){
            if(this.resource.id>0){
                return true
            }
            let found=false;
            if(this.resource.identification!=undefined){
                for(var i=0;i<this.resources.length;i++){
                    if(this.resource.identification.toLowerCase()==this.resources[i].identification.toLowerCase()){
                        found = true;
                        break;
                    }
                }
            }
            return !found &&this.resource.identification!=undefined&& this.resource.identification.length>=2
        }        
    },
    methods: {
        addresourceText(){
            if (this.checkresource){
                return this.$i18n.t("buttons.add") 
            } else { 
                return this.$i18n.t("buttons.invalid") 
            }
        },
        save_and_close(e) {
            e.preventDefault() // it prevent from page reload
            if(this.resource.id==0){
                this.my_add_resource()
            }else{
                this.save_resource(this.resource.id)
                this.$emit('close')
            }            
        },
        ...mapActions([
            'save_resource',
            'add_resource'
        ]),
        my_add_resource(){
            if(this.checkresource){
                this.add_resource(this.resource);
                this.$emit('close')
            }
        },
    },
    watch:{
        user(){
            this.resource.user_id = this.user.id
            console.log(this.resource)
        }
    }
}
</script>