<template>
  <div class="popup">
    <div class="popup-header">
      {{$t('labels.notify')}}

      <div class="popup-close" @click="save_and_close">
        <font-awesome-icon icon="times" />
      </div>
    </div>

    <div class="popup-body">
      <b-container>
        <h3>{{project.title}}</h3>
        <b-row>
          <b-col>
            {{project.description}}<br />
            {{project.startdate}} - {{project.enddate}} @ {{project.location}}<br />
            <strong>{{this.company_by_id(project.company_id).company_name}}</strong><br />
            <div class="form-row">
                <div class="col-3">
                  {{ $t('titles.subject') }}
                </div>
                <div class="col-9">
                  <b-form-input type="text" class="form-control"  v-model="description" />
                </div>
            </div>
          </b-col>
          <b-col>
            <div v-for="wf in workfields4project" :key="'wf'+wf.id" class="col-12">
            <!-- <input type="checkbox" :id="'pcheck-' + user.id" v-model="user.alert" :label="user.fullname"> -->
            <b-form-checkbox  v-model="wf.selected" @change="checkwf()">
              {{wf.name}}
            </b-form-checkbox>
          </div>
          </b-col>
        </b-row>
        <hr>
        <div @click="select_all()" class="btn btn-primary">
          <font-awesome-icon icon="plus" /> {{$t('buttons.selectall')}}
        </div>
        <div @click="select_none()" class="btn btn-primary">
          <font-awesome-icon icon="minus" /> {{$t('buttons.selectnone')}}
        </div>
        <div v-if="project.workfields.length > 0" class="btn btn-primary" @click="matchworkfield = !matchworkfield">
          <input type="checkbox" v-model="matchworkfield">
          {{$t('labels.matchworkfields')}}

        </div>
        <div v-if="zzprs.length>0" class="row">
          <div v-for="user in zzprs" :key="'user'+user.id" class="col-3">
            <!-- <input type="checkbox" :id="'pcheck-' + user.id" v-model="user.alert" :label="user.fullname"> -->
            <b-form-checkbox v-model="user.alert">
              {{user.fullname}}
            </b-form-checkbox>
          </div>
        </div>
        <div v-else>{{$t('validation.nozzpersfound')}}</div>
      </b-container>
    </div>

    <div class="popup-footer">
      <div @click="save_and_close" class="btn btn-primary">{{ $t('buttons.sendmessage')}}</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'project-alert-modal',
  data() {
    return {
      alertusers: [],
      matchworkfield: true,
      description:"We zoeken mensen voor dit nieuwe project"
    }
  },
  props: {
    project_id: Number
  },
  computed: {
    project() {
      return this.project_id > 0 ? this.project_by_id(this.project_id) : this.project_new
    },
    zzprs() {
      this.users.filter(user => user.role == 'user').sort(this.usersort).forEach(element => {
        if (this.matchworkfield) {
          if (this.workfields4project.filter(value => value.selected && element.workfields.includes(value.id)).length > 0) {
            this.alertusers.push({ id: element.id, fullname: element.fullname, alert: false })
          }
        } else {
          this.alertusers.push({ id: element.id, fullname: element.fullname, alert: false })
        }

      });

      return this.alertusers
    },
    workfields4project() {
      let wf = [];
      for (var i = 0; i < this.workfields.length; i++) {
        if (this.project.workfields.indexOf(this.workfields[i]["id"]) != -1) {
          wf.push({id:this.workfields[i].id, name:this.workfields[i].name, selected:true})
        }
      }
      return wf;      
    },    
    ...mapGetters([
      'project_metas',
      'project_by_id',
      'project_new',
      'company_by_id',
      'branche_by_id'
    ]),
    ...mapState([
      'projects',
      'settings',
      'companies',
      'users',
      'workfields'
    ])
  },
  mounted: function () {
    this.matchworkfield = this.project.workfields.length > 0

  },

  methods: {
    usersort(a, b) {
      if (a.fullname < b.fullname) {
        return -1;
      }
      if (a.fullname > b.fullname) {
        return 1;
      }
      return 0;
    },
    checkwf(){
      this.alertusers=[];
    },
    save_and_close() {
      // construct message
      let message = {}
      message.project_id = this.project_id
      message.title  = this.description
      message.body = this.project.omschrijvingwebsite?this.project.omschrijvingwebsite:this.project.title
      message.sendto = this.alertusers.filter(user => user.alert).map(a => a.id)

      console.log(message.sendto )
      //send pm to all users selected
      message.sendto.forEach(user_id =>{
        let mes ={}
        mes.message = message.body
        mes.subject = message.title
        mes.project_id = message.project_id
        if(this.settings.PMHistory) {
          this.add_pm( { id: 0, to:  user_id, from: this.current_user.id, message:mes })
        }

      })


      axios.post('/api/notify_project', message).then((response)=>{console.log(response)})
      this.close_modal()
    },
    ...mapActions([
      'add_pm'
    ]),
    close_modal() {
      this.$emit('close')
    },
    select_all() {
      this.alertusers.forEach(u => {
        u.alert = true
      })
    },
    select_none() {
      this.alertusers.forEach(u => {
        u.alert = false
      })
    },

    projectworkfields() {
      var ret = ""
      if (this.company_by_id(this.project.company_id).branche_id) {
        const branch = this.branche_by_id(this.company_by_id(this.project.company_id).branche_id);
        if (branch) {
          ret += "<b>" + branch.name + "</b><br>"
          for (var i = 0; i < this.workfields.length; i++) {
            if (this.project.workfields.indexOf(this.workfields[i]["id"]) != -1) {
              ret += this.workfields[i]["name"] + "<br>"
            }
          }
        }
      }
      return ret
    },
  },
  watch: {
    matchworkfield: function () {
      this.alertusers = [];
    }
  },
  components: {}
}
</script>