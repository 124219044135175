<template>
  <b-modal id="BSWaxVacationID" centered size="lg">
    <template #modal-header>
      <h2 class="modal-title">{{ username_by_id(user_id) }} BSWaxVacation </h2>
    </template>
    <b-container ref="container">
      <div class="row">
      <div class="col-5">{{ $t('labels.startdate') }}</div>
      <div class="col-5">{{$t('labels.enddate')}}</div>
    </div> 
    <div class="row">
      <div class="col-5"><b-form-input type="date" v-model="startdate" /></div>
      <div class="col-5"><b-form-input type="date" v-model="enddate" /></div>
      <div class="col-1"><button class="btn btn-primary" @click="saveResource()">+</button></div>
    </div>   
    <b-table :items="vacations" :fields="fields" >
      <template #cell(start_offset)="row">
          <input type="date" v-model="row.item.startdate" />
      </template>
      <template #cell(end_offset)="row">
        <input type="date" v-model="row.item.enddate" />
      </template>      
      <template #cell(actions)="row">
        <div class="action-item" style="float:left" @click="saveItem(row.item)"> <font-awesome-icon icon="save" /> </div>
        <div class="action-item" style="float:left" @click="deleteItem(row.item)"> <font-awesome-icon icon="trash" /> </div>
        <b-button :style= "AppPrimaryColor()" variant="link" v-if="confirm && selectedID.id==row.item.id" @click="removevacation"><font-awesome-icon icon="check" color="green" />{{$t('labels.delete')}}</b-button>
        <b-button :style= "AppPrimaryColor()" variant="link" v-if="confirm && selectedID.id==row.item.id" @click="canceltrash"><font-awesome-icon  icon="times"  color="red" />{{$t("buttons.cancel")}}</b-button>

      </template>          
    </b-table>
    </b-container>
    <template #modal-footer>
      <div @click="saveData" class="btn btn-primary">
       {{ $t("buttons.close") }}
      </div>
    </template>
  </b-modal>
</template>


<script>
import { mapGetters, mapState,mapActions } from 'vuex'

export default {
  name: 'userweekplanning-modal',
  data() {
    return {
      year:0,
      startdate:null,
      enddate:null,    
      confirm:false 
    }
  },
  props: {
    user_id: Number,
  },
  mounted(){
    this.year = new Date().getFullYear();
    this.startdate = this.dateToYYYYMMDD(new Date(Date.UTC(this.year, 0, 1)))
    this.enddate = new Date(this.startdate)
    this.enddate= this.dateToYYYYMMDD(this.enddate.setDate(this.enddate.getDay()+7))
  },
  created(){},
  computed: {
    ...mapGetters([
      'username_by_id',
    ]),

    fields () {
            return [
            { key: 'start_offset', label: this.$i18n.t('labels.startdate')  , sortable: true, sortDirection: 'desc' },
            { key: 'end_offset', label: this.$i18n.t('labels.enddate')  , sortable: true, sortDirection: 'desc' },
            { key: 'actions', label: this.$i18n.t('labels.actions') },
            ]   
        },   
    vacations(){
      let ffs =  this.ffwax.filter(f =>f.user_id==this.user_id && f.project_id>9990000)
      ffs.forEach(aff => {
        let year = aff.project_id - 9990000
        aff.startdate = new Date(Date.UTC(year, 0, 1))
        aff.startdate = aff.startdate.setDate(aff.startdate.getDate()+ aff.start_offset)
        aff.enddate = new Date(aff.startdate)
        aff.enddate= this.dateToYYYYMMDD(aff.enddate.setDate(aff.enddate.getDate()+aff.end_offset))
        aff.startdate= this.dateToYYYYMMDD(aff.startdate)
        
      });
      return ffs

    },
    ...mapState([
      'users',
      'ffwax',
    ]),
    user() {
        return this.user_id==0?null:this.user_by_id(this.user_id)
    },
    start_offset(){
      return Math.round( (new Date(this.startdate).getTime() - new Date(Date.UTC(this.year, 0, 1)).getTime())/86400000)
    },
    end_offset() {
      return Math.round(( new Date(this.enddate).getTime()  - new Date(this.startdate).getTime())/86400000)

  },     
  },
  methods: {
    ...mapActions([
      'add_ffwax',
      'save_ffwax',
      'delete_ffwax'
    ]),    
    async saveData(){
      this.$bvModal.hide('BSWaxVacationID')

    },
    saveItem(item){
      let id = item.id
      let year = item.project_id - 9990000
      item.start_offset =  Math.round( (new Date(item.startdate).getTime() - new Date(Date.UTC(year, 0, 1)).getTime())/86400000)
      item.end_offset=  Math.round(( new Date(item.enddate).getTime()  - new Date(item.startdate).getTime())/86400000)
      // axios.put("/api/ffwax/" + id, item)
      this.save_ffwax(id)
    },

    deleteItem(item){
      this.selectedID = item
      this.confirm = true
      console.log("delete", item)

    },
    removevacation() {
      this.delete_ffwax(this.selectedID.id);
      this.confirm = false;
      this.selectedID = -1;
    },   
    canceltrash(){
      this.confirm = false;
      this.selectedID = -1;
    }      ,
    close_modal() {
    this.$bvModal.hide('BSWaxVacationID')
    },
    saveResource(){
      let ffwax = {id:0, user_id:this.user_id,project_id:9990000+this.year, resource_id:null, start_offset:this.start_offset,end_offset:this.end_offset, start_time:null,end_time:null}
      this.add_ffwax(ffwax)        
      }        
  },
  watch:{
  },
}    
</script>
