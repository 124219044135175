<template>
	<div class="popup">

        <div class="popup-header">
            <div v-if="user.id==0">{{$t("labels.newcontact")}}</div>
            <div v-else>
                {{ user.firstname }} {{ user.middlename }} {{ user.lastname }}
            </div>
            <div class="popup-close" @click="close_modal">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
          <input type="hidden" :key="componentKey" />
          <div ref="bodyTop"></div>
            <b-tabs vertical no-nav-style>
                <b-tab active>
                <template v-slot:title>{{ $t("labels.general") }}</template>                    
                    <UserDefault v-bind:user_id="user.id" ref="default" ></UserDefault>
                </b-tab>
                <b-tab v-if="user.id != 0">
                    <template v-slot:title>{{ $t("labels.settings") }}</template>
                    <UserSettings v-bind:user_id="user.id"></UserSettings>
                </b-tab>
                <b-tab v-if="user.id != 0">
                    <template v-slot:title>{{ $t("labels.photo") }}</template>
                    <UserProfileImage v-bind:user_id="user.id"></UserProfileImage>
                </b-tab>
            </b-tabs>
        </div>

        <div class="popup-footer">
            <div v-if="user.id == 0" class="btn btn-primary" @click="my_add_user(); ">{{adduserText() }}</div>
            <div v-else @click="save_and_close" class="btn btn-primary">{{ $t("buttons.saveandclose") }}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import UserDefault from './settings-screens/UserDefault'
import UserProfileImage from './settings-screens/UserProfileImage'
import UserSettings from './settings-screens/UserSettings'

export default {
    name: 'user-modal',
    data() {
        return {
            componentKey:0
        }
    },
    props: {
        user_id: Number
    },
    computed: {
        user() {
            return this.user_id > 0 ? this.user_by_id(this.user_id) : this.user_new
        },
        ...mapGetters([
            'user_metas',
            'user_by_id',
            'activebranches',
            'user_new'

        ]),
        ...mapState([
            'users',
            'settings',
            'current_user',
            'branches',
        ])
    },
    methods: {
        adduserText(){
            if (this.$refs.default && this.$refs.default.validated()==true){
                return this.$i18n.t("buttons.add") 
            } else { 
                return this.$i18n.t("buttons.invalid") 
            }
        },     
        usercheck(){
            this.componentKey++
        },

        translate(path){
            return this.$i18n.t('meta.'+path.toLowerCase())
        },        
        save_and_close() {
            this.save_user(this.user.id)
            if(this.user.role == 'user'){
                this.$refs.company.savedata();
            }
            this.close_modal()
        },
        ...mapActions([
            'save_user',
            'add_contact'
        ]),
        close_modal() {
            this.$store.commit('new_user',{
          id: 0,
          branche_id: 1,
          workfields: [],
          owner_id: this.current_user.id,
          role:'user',
          active: 1,
          language: "nl",
          country: "NL",
          notifications: 1,
          newsletter: 1,
          mail: 1,
          sms: 1,
          inapp: 1,
          company_contact: 0,
          invoice_contant: 0,
          document_contact: 0,
          sign_contact: 0,
          location_contact: 0,
          isemployee:0
        })
            this.$emit('close')
        } ,
        my_add_user(){
            if(this.$refs.default.validated()==true){
                this.add_contact(this.user)
                // this.close_modal()
                // scroll to top
                this.$refs["bodyTop"].scrollIntoView({ behavior: "smooth" })
            }            
        }        

    },
    components: {UserDefault,UserProfileImage,UserSettings}
}
</script>