<template>
  <b-modal id="BSUploadUrenModalID" centered size="lg" @hide="hideMe" @show="showMe">
    <template #modal-header>
      <h2 v-if="!internal" class="modal-title">{{ $t("buttons.addurenbon") }}</h2>
      <h2 v-else class="modal-title">{{ $t("buttons.invoicezzper") }}</h2>
      {{ contract_id }} {{weeknumber}} {{yearnumber}} {{ date }}
    </template>

    <form class="form">
      <div class="form-row">
        <div class="col-3">
          <label for=""> {{ $t("labels.file") }}</label>
        </div>
        <div class="col">
          <b-form-file ref="file-input" class="form-control" accept="*/*" v-model="file1" :state="Boolean(file1)" />
        </div>
      </div>
      <div class="form-row" v-if="createAny('users') && !internal">
        <div class="col-3" v-if="true"></div>
        <div class="col" v-if="true" >
          <b-form-checkbox v-model="intern" name="checkbox-1" value="1" unchecked-value="0">{{
        $t('labels.interndocument') }}</b-form-checkbox>
        </div>
        <div class="col-3" v-if="companyattachment"></div>
        <div class="col" v-if="companyattachment">
          <b-form-checkbox v-model="company" name="checkbox-1" value="1" unchecked-value="0">{{
        $t('labels.companyattachment') }}</b-form-checkbox>
        </div>        
      </div>
    </form>

    <template #modal-footer>
      <div @click="sendFile" class="btn btn-primary">
        <font-awesome-icon icon="plus" /> {{ $t("buttons.addurenbon") }}
      </div>
    </template>
  </b-modal>
</template>


<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import axios from 'axios'

export default {
  name: 'upload-urenbon-modal',
  data() {
    return {
      file1: null,
      intern:"0",
      company:"0"
    }
  },
  props: {
    contract_id: Number,
    weeknumber: Number,
    yearnumber: Number,
    internal:Boolean,
    companyattachment:Boolean,
    date: String
  },
  computed: {
    ...mapGetters([
      'server_url',
      'createAny'

    ]),
    ...mapState([
      'users',
      'branches',
      'avatars',
      'urenbonnen'
    ]),

  },
  methods: {
    showMe(){
      // console.log("hasdate", this.date, this.date=="")
      if(this.companyattachment){
        this.company = "1"
      }
    },
    hideMe() {
      this.intern = "0"
    },
    sendFile() {
      let formData = new FormData();
      let adoc = {}
      if(this.company=="1"){
        formData.append('name', "company_" + this.contract_id + "_" + this.weeknumber + "_");
        adoc.name = "company_" + this.contract_id + "_" + this.weeknumber + "_"

      } else {      
        if(this.intern=="1" || this.internal){
          formData.append('name', "intern_" + this.contract_id + "_" + this.weeknumber + "_");
          adoc.name = "intern_" + this.contract_id + "_" + this.weeknumber + "_"

        } else {
          if(this.date=="") {
            formData.append('name', "bon_" + this.contract_id + "_" + this.weeknumber + "_");
          } else {
            formData.append('name', "bon_" + this.contract_id + "_" + this.weeknumber + "_"+ this.date + "_");
          }
          adoc.name = "bon_" + this.contract_id + "_" + this.weeknumber + "_"

        }
      }
      formData.append('week', this.weeknumber)
      formData.append('year', this.yearnumber)
      formData.append('file', this.file1)
      formData.append('contract_id', this.contract_id)
console.log(formData)
      adoc.week = this.weeknumber
      adoc.contract_id = this.contract_id
      adoc.file = "urenbon/" + adoc.name + "-" + this.file1.name
      adoc.link = "urenbon/" + adoc.name + "-" + this.file1.name
      this.urenbonnen.push(adoc)
      console.log(adoc)
      // Display the key/value pairs
      require('dotenv').config()

        try {
          var config = {
              method: 'post',
              url: '/api/urenbon/',
              headers: {
                  'Content-Type': 'multipart/form-data'
              },
              data: formData
          };        
          // axios.post('/api/urenbon/', formData ,{
          //   headers: { "Content-Type": `multipart/form-data; boundary=${formData._boundary}` }          
          // })
          axios(config)
            .then((response) => {
              console.log('succes', response)
              this.close_modal();
            })
          this.close_modal();

        } catch (err) {
          console.log(err)
        }
      // }

    },
    close_modal() {
      this.$bvModal.hide('BSUploadUrenModalID')
      this.$store.dispatch('get_urenbonnen');      

    },
    ...mapActions([
      'update_user_document'
    ]),
    estimateContentLength(formData) {
    // Seems to be 44 in WebKit browsers (e.g. Chrome, Safari, etc.),
    // but varies at least in Firefox.
    const baseLength = 50; // estimated max value
    // Seems to be 87 in WebKit browsers (e.g. Chrome, Safari, etc.),
    // but varies at least in Firefox.
    const separatorLength = 115; // estimated max value
    let length = baseLength;
    const entries = formData.entries();
    for (const [key, value] of entries) {
        length += key.length + separatorLength;
        if (typeof value === 'object') {
            length += value.size;
        } else {
            length += String(value).length;
        }
    }
    return length;
}
  },
}    
</script>
