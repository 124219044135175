import Vue from 'vue'
import {  mapState } from 'vuex';

Vue.mixin({
  computed: {
    ...mapState([
      'settings',
    ]),
    isApp(){ 
      return this.buildtarget=="apprelease"
      // return true
    },
    primaryColor(){
      return this.settings.primarycolor
    },
    secondaryColor(){
      return this.settings.secondarycolor
    }
  },
  methods: {
    projectTitleClass(display) {
      if(this.isApp){
        if(display){
          return {"display":"none"}
        } else {
          return {"display":"block"}
        }    
      }

    },
    projectTitleAppClass() {
      if(this.isApp){
        let backgroundcolor = "#fff !important"
          return {"background-color": backgroundcolor, "border":  "1px solid "+ this.primaryColor+" !important", "color": this.secondaryColor+" !important"}
      }
    }, 
    AppMainView() {
      if(this.isApp){
        return { "margin": "1rem !important"}      
      }
    },
    AppNavButton(){
      if(this.isApp){
        return { "color": "#fff !important", "font-weight":"bold"}      
      }
    },
    AppCheckBox() {
      if(this.isApp){
        return {"background-color":  this.primaryColor +" !important"}
    }
    },
    AppCounter(){
      if(this.isApp){
          return {"background-color":  this.primaryColor +" !important"}
      }
    } ,
    AppPrimaryColor(){
      if(this.isApp){
          return { "color": this.primaryColor+" !important"}      
      }
    }, 
    AppRootViewButton(){
      if(this.isApp){
          return { "background-color":"#fff", "color": String(this.primaryColor)+" !important", "border":  "2px solid "+ String(this.primaryColor)+" !important", }      
      }
    },  
    AppPrimaryButton(){
      if(this.isApp){
          return { "background-color": String(this.primaryColor)+" !important", "border":  "1px solid "+ String(this.primaryColor)+" !important", }      
      }
    },  
    AppSecondaryButton(){
      if(this.isApp){
          return { "background-color": this.secondaryColor+" !important", "border":  "1px solid "+ this.secondaryColor+" !important", }      
      }
    },         
    AppSecondaryColor(){
      if(this.isApp){
          return { "color": this.secondaryColor+" !important"}      
      }
    },
    isActive(status){
      if(this.isApp){
        if(status){
          return 	{"color": this.secondaryColor+" !important" ,"border-bottom":this.primaryColor+" 3px solid  !important" }
        
        }      
      }
    },   
    ckeditorState(text){
      if(text==null || text == ""){
        return 	{"border":"red 1px solid  !important" }
      } else {
        return 	{"border":"green 1px solid  !important" }

      }
    }

  }})