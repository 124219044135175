<template>
	<div class="popup">

        <div class="popup-header">
            <div>
                 {{header}}
            </div>
            <div  class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body" style="overflow:hidden">
          <b-container>
          <h4>{{ message }}</h4>
          <a v-if="isAndroid" href="https://play.google.com/store/apps/details?id=nl.ff.portal&gl=NL&gl=NL"><img src="../../assets/google-play.png"></a>
          <a v-if="isiOS" href="https://apps.apple.com/nl/app/ff-portal-app/id6447968838"><img src="../../assets/appstore.png"></a>
          </b-container>
        </div>
        <div class="popup-footer">
            <div class="btn btn-primary" @click="$emit('close')">Sluiten</div>
        </div>
    </div>
</template>

<script>
import { Capacitor } from '@capacitor/core';

export default {
    name: 'apprelease-confirm-modal',
    data() {
        return {
          confirmed:false
        }
    },
    props: {
        message: String ,
        header:String
    },
    computed: {
      isAndroid(){
        console.log("Check android" , Capacitor.getPlatform()=='android')
        return Capacitor.getPlatform()=='android'
      },
      isiOS(){
        console.log("Check iOS" , Capacitor.getPlatform()=='ios')
        return Capacitor.getPlatform()=='ios'
      },

    },
    methods: {

    }
}
</script>