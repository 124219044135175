<template>
	<div class="popup">

        <div class="popup-header">
            <div>
                 {{ $t("labels.editplanning") }}
            </div>
            <div  class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body" style="overflow:hidden">
          <b-container>
          <div  class = "row" v-for="(res, idx) in modify_resources" :key="res+idx">
            <div class="col-2">{{$t('labels.resource')}}</div>
            <div class="col-5"><b-form-checkbox  v-model="resourcecheck[res.id]">{{$t('labels.planresourcechange')}}</b-form-checkbox></div>
            <div class="col-5">{{ res.name }} - {{ res.identification }}</div>  
          </div>                 
          </b-container>
        </div>
        <div class="popup-footer">
            <div class="btn btn-primary" @click="$emit('close')">{{ $t("buttons.cancel") }}</div>
            <div @click="save_and_close" class="btn btn-primary">{{ $t("buttons.saveandclose") }}</div>
        </div>
    </div>
</template>

<script>
import {  mapGetters, mapState, mapActions} from 'vuex'

export default {
    name: 'modifyresourceonusermove-modal',
    data() {
        return {
          confirmed:false,
          initstart:null,
          initend:null,
          initstartt:null,
          initendt:null,          
          resource_id:null,
          resourcecheck:[]

        }
    },
    props: {
      modify_resources:[],
      ffwaxid:Number    }
      ,
    mounted() {
      console.log(this.ffwax_by_id(this.ffwaxid))
    },
    computed: {
      ...mapGetters ([
      'zzpers',
      'project_by_id',
      'user_by_id',
      'username_by_id',
       'createAny',
       'ffwax_by_id'

  ]),
  ...mapState ([
      'ffwax',
      'projectresources',
      'resources'

    ]), 
    thiswax(){
      return this.ffwax_by_id(this.ffwaxid)
    },
    user() {
     return this.user_by_id(this.thiswax.user_id)
    },
    project() {
     return this.project_by_id(this.thiswax.project_id)
    },
    start_offset(){
    return Math.round(( new Date(this.initstart).getTime() - new Date(this.project.startdate).getTime())/86400000)
    },
    end_offset() {
      return Math.round(( new Date(this.initend).getTime() - new Date(this.initstart).getTime())/86400000)
    },    
    },
    methods: {
      ...mapActions([
      'add_ffwax',
      'save_ffwax',
      'delete_ffwax'
    ]),      
        save_and_close() {
          for(var i=0; i<this.modify_resources.length;i++){
            if(this.resourcecheck[this.modify_resources[i].id]==true){
              // get ffwax
              let ffw = this.ffwax.filter(ff => ff.resource_id == this.modify_resources[i].id && ff.project_id==this.thiswax.project_id)[0]
                ffw.start_offset = this.thiswax.start_offset
                ffw.end_offset = this.thiswax.end_offset
                ffw.start_time = this.thiswax.start_time
                ffw.end_time = this.thiswax.end_time
                this.save_ffwax(ffw.id)
              }
          }        
          this.$emit('close')
        },

    }
}
</script>