<template>
  <b-container>
    <form v-if="this.contractinfo.length>0" v-on:submit='formSubmit'>
      <div v-html='buttons(this.contractinfo[0])'></div>
      <input type='hidden' name='contract_id' :value='contract_id'>
      <button ref="submit" class='action-item' type='submit'><font-awesome-icon icon="save" /></button>
    </form>
    <div v-else-if="this.user_by_id(this.user_id).role=='user'">
        Not signed by company
    </div>
    <div v-else>
        Not signed ZZPer
    </div>
  </b-container>
</template>
<script>
import { mapState,mapGetters,mapActions } from 'vuex'

export default {
    name: 'schedule_screen',
    data() {
        return {
          agreemode:false

        }
    },
    props: {
        user_id: Number,
        contract_id: Number
    },    
    mounted() {
      
          },
    computed: {
      contractinfo(){
          let values =[];
          let contract = this.contract_by_id(this.contract_id)
          let userplan = this.userplanning(contract.user_id)
            for(var p=0;p<userplan.length;p++){
              let row ={};
              // signed by company not neccesary
              // if(contract.signed_zzpr==1 && contract.signed_company==1 &&userplan[p].project_id==contract.project_id){
              if(userplan[p].project_id==contract.project_id){
                let project = this.project_by_id(contract.project_id)
                if(project){
                  row.project = userplan[p].project_name
                  row.contract_id = contract.id
                  row.user_id = contract.user_id
                  row.startdate = userplan[p].startdate
                  row.enddate = userplan[p].enddate
                }
                if(contract.user_id==this.user_id){
                  values.push(row)
               }
              }
          }
        return values
      },

      ...mapState([
          'projects',
          'users',
          'companies',
          'contracts',
          'branches',
          'planning',
          'settings',
          'current_user',
          'hours'
      ]), 
      ...mapGetters([
          'project_by_id',
          'company_by_id',
          'contract_by_id',
          'zzpers',
          'user_by_id',
          'username_by_id',
          'hours_by_id'
      ]),  

    },
    methods:{
      submitForm(){
        if(this.$refs.submit){
          this.$refs.submit.click();
        }
      },
      agreeForm(){
        this.agreemode = true;
        if(this.$refs.submit){
          this.$refs.submit.click();
        }
      },      
      hideSubmit(){
        if(this.$refs.submit){
          this.$refs.submit.style.display = 'none'
        }
      },

      ...mapActions([
          'save_hours',
          'add_hours'
      ]),      

      getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        return new Date(d.setDate(diff));
      },

      userplanning(user_id){
        let _planning =[]
        for(var i=0; i<this.planning.length;i++){
          if(this.planning[i].user_id== user_id){
            _planning.push(this.planning[i])
          }
        }
        return _planning
      },
      buttons(item){
        let result=""
        let startdate = new Date(item.startdate);
        let enddate = new Date(item.enddate);
        let startmonday = this.getMonday(startdate);
        var loop = new Date(startmonday);
        while(loop <= enddate){
          if(loop.getDay()==1){
            if(result==""){
              result+="<table><tr><th>MON</th><th>TUE</th><th>WED</th><th>THU</th><th>FRI</th></tr><tr>"
            } else {
              result+="</tr><tr>"
            }
          }
          if(loop.getDay()>=1 && loop.getDay()<=5){
            result+="<td>"
            if(loop>=startdate) {
              let thisday = this.dateToYYYYMMDD(loop)
              result+="<a href='javascript:fillhours("+thisday+");' class='action-item' ><font-awesome-icon icon='signature'/>"+thisday+"</a>"
            }
            result+="</td>"   
          }       
          var newDate = loop.setDate(loop.getDate() + 1);
          loop = new Date(newDate);
        }
        return result
      },
      fillhours(day){
        console.log('fill',day)
      },
      hourschedule(item){
        let result=""
        let startdate = new Date(item.startdate);
        let enddate = new Date(item.enddate);
        let startmonday = this.getMonday(startdate);
        var loop = new Date(startmonday);
        while(loop <= enddate){
          if(loop.getDay()==1){
            if(result==""){
              result+="<table><tr><th>MON</th><th>TUE</th><th>WED</th><th>THU</th><th>FRI</th></tr><tr>"
            } else {
              result+="</tr><tr>"
            }
          }
          if(loop.getDay()>=1 && loop.getDay()<=5){
            result+="<td>"
            if(loop>=startdate) {
              let thisday = this.dateToYYYYMMDD(loop)//+"-"+(loop.getMonth()+1)+"-"+loop.getDate()
              result+=thisday
              result+="<input type='number' name='hours' "
              let has_hours = this.hashours(thisday);
              if(has_hours.available){
                result+="value='"+has_hours.hours+"'"
                if(has_hours.agree_company==1){
                  if(this.current_user.role=="admin"){
                    result+= " style='background-color:#ff0000' "
                  }else{
                    result+= " disabled "
                  }
                }
              }
              result+="><input type='hidden' name='date' value='"
              result+=thisday
              result+="'>"
              
            }
            result+="</td>"   
          }       
          var newDate = loop.setDate(loop.getDate() + 1);
          loop = new Date(newDate);
        }
        return result
      },
      hashours(date){
        let result={}
        result.available=false;
        for(var i=0;i<this.hours.length;i++){
          if(this.hours[i].workdate==date){
            if(this.hours[i].contract_id==this.contract_id && this.hours[i].user_id==this.user_id){
              result.available=true;
              result.id = this.hours[i].id;
              result.hours = this.hours[i].hours;
              result.agree_company = this.hours[i].agree_company;
              break;
            }
          }
        }
        return result

      },
      formSubmit(e) {
        e.preventDefault() // it prevent from page reload
        for(var i =0;i<e.target.elements.length-2;i+=2){
          let has_hours = this.hashours(e.target.elements[i+1].value)
          if(has_hours.available){
            if(has_hours.hours!=e.target.elements[i].value||this.agreemode){
              let hours = this.hours_by_id(has_hours.id);
              if(this.agreemode&&hours.agree_company!=1){
                hours.agree_company = 1
                hours.agree_date = new Date().toUTCString()
              }else{
                hours.hours=e.target.elements[i].value
              }
              this.save_hours(hours.id)
            }
          }else{
            if(e.target.elements[i].value){
              var newhours={id:0,user_id:this.user_id, contract_id:this.contract_id,workdate:e.target.elements[i+1].value, hours:e.target.elements[i].value, added_date:new Date().toUTCString()}
              this.add_hours(newhours)

            }
          }
        }
      },
   
    },
    watch:{

    }
}
</script>