
<template>
	<div class="popup">

        <div class="popup-header">
            <div >
              <h2>{{ $t("buttons.adddocument") }}</h2>
            </div>
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
          <b-container>
            <div class="form-row">
                <div class="col-3">
                    <label for=""> {{ $t("labels.name") }}</label>
                </div>
                <div class="col-1">
                    <font-awesome-icon icon="exclamation" color="red" v-if="checkfilecharacters(this)==false"></font-awesome-icon>
                    <font-awesome-icon icon="check" color="green" v-else></font-awesome-icon>
                </div>

                <div class="col">
                    <b-form-input type="text" class="form-control" v-model="name" v-on:keypress="isLetter($event)"  @blur="removeInvalidChars()"  />              
                </div>
            </div>    
            <div class="form-row">
                <div class="col-4">
                    <label for=""> {{ $t("meta.aangemaakt") }}</label>
                </div>
                <div class="col">
                    <b-form-input type="date" class="form-control" v-model="expires"  />            
                </div>
            </div>
            <div class="form-row">
                <div class="col-3">
                    <label for=""> {{ $t("labels.file") }}</label>
                </div>
                <div class="col-1">
                    <font-awesome-icon icon="exclamation" color="red" v-if="file1==null || checkfilecharacters(file1)==false"></font-awesome-icon>
                    <font-awesome-icon icon="check" color="green" v-else></font-awesome-icon>
                </div>                
                <div class="col">
                    <b-form-file ref="file-input" class="form-control" accept="*/*" v-model="file1" :state="Boolean(file1)" />
                </div>
                
            </div> 
          </b-container>
        </div>
        <div class="popup-footer">
            <div @click="sendFile" class="btn btn-primary"><font-awesome-icon icon="plus" /> {{ validateText() }}</div>
        </div>
    </div>          
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import axios from 'axios'

export default {
    name: 'addcompanydocumentssscreen',
    data() {
        return {
          name:"new",
          expires:null,
          file1:null,
          required:0
        }
    },
    props: {
        project_id: Number
    },
    computed: {
        company() {
            return this.project_id > 0 ? this.project_by_id(this.project_id) : this.project_new
        },
        ...mapGetters([
            'user_metas',
            'project_by_id',
            'activebranches',
            'user_new',
            'server_url'
        ]),
        ...mapState([
            'users',
            'branches',
            'avatars'
        ]),   
        
    },
    methods: {
        validateText(){
            if(this.name!="" &&this.checkfilecharacters(this)&&this.file1!=null&&this.checkfilecharacters(this.file1)){
                return this.$i18n.t("buttons.adddocument")

        } else {
                return this.$i18n.t("buttons.invalid")
            }
        },
      sendFile(){
        if(this.name!="" &&this.checkfilecharacters(this)&&this.file1!=null&&this.checkfilecharacters(this.file1)){
        const formData = new FormData();
        formData.append('name', this.name.replace(/[^ a-zA-Z0-9 -]/g, ''));
        formData.append('expires', this.expires)
        formData.append('file', this.file1)
        formData.append('project_id', this.project_id)
        formData.append('required', this.required)
        let adoc={}
        for (var pair of formData.entries()) {
            adoc[pair[0]] = pair[1]
        }  
        adoc.link = "project_documents/"+this.project_id+"-"+this.name+"-"+this.file1.name
        this.company.documents.push(adoc)
        try{
          axios.post('/api/project/document/', formData)  .then((response) => {
              console.log(response)
              this.close_modal();        
          })
              this.close_modal();        

        } catch(err){
          console.log (err)
        }
    }
      },
        close_modal() {
            this.$emit('close')
        } ,      
        ...mapActions([
            'update_user_document'
        ]),
        isLetter(e) {
            this.name = this.name.replace(/[^ a-zA-Z0-9 -]/g, '')
            let char = String.fromCharCode(e.keyCode); 
            if(/^[A-Za-z0-9 -]+$/.test(char)) return true;
            else e.preventDefault(); 
        },
        removeInvalidChars() {
            this.name = this.name.replace(/\//g,'')
        }        
    },
    mounted(){
        const dateObj = new Date();
        const month = String(dateObj.getMonth()+1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        const year = dateObj.getFullYear();            
        this.expires = year+"-"+month+"-"+day
    }

}    
</script>
