<template>
  <b-container v-if="createAny('users')" fluid>
    <!-- Title bar -->
    <div class="title-bar">
      <h1>{{ $t("titles.planning") }}</h1>
    </div>
    <!-- Filter bar -->
    <b-row class="filter-bar">
      <b-col md="4" xl>
        <b-form-group :label="$t('labels.from')">
          <b-form-input type="week" v-model="startw" />
        </b-form-group>
      </b-col>
      <b-col md="4" xl>
        <b-form-group :label="$t('labels.to')">
          <b-form-input type="week" v-model="endw" />
        </b-form-group>
      </b-col>
      <b-col md="4" xl>
        <b-form-group :label="$t('labels.branche')">
          <b-form-select name="branches" class="form-control" v-model="currentbranche">
            <option value="-1"> {{ $t("option.all") }}</option>
            <option v-for="(branche,idx) in branches" :key="idx" v-bind:value="branche.id">
              {{ branche["name"]}}
            </option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-table sort-icon-left :items="contractinfo" :fields="fields" :tbody-tr-attr="rowClass" :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc" :current-page="currentPage" :per-page="perPage">
      <template #cell(color)="row">
        <a href="javascript:;" class="float-right colorset"
          v-bind:style="{ color: classcolor1, 'background-color':'#ffffff', border: '2px solid white'}"
          @click="color1(row.item)">
          <font-awesome-icon icon="square" />
        </a>
        <a href="javascript:;" class="float-right colorset"
          v-bind:style="{ color: classcolor2, 'background-color':'#ffffff',border: '2px solid white'}"
          @click="color2(row.item)">
          <font-awesome-icon icon="square" />
        </a>
        <a href="javascript:;" class="float-right colorset"
          v-bind:style="{ color: classcolor3, 'background-color':'#ffffff',border: '2px solid white'}"
          @click="color3(row.item)">
          <font-awesome-icon icon="square" />
        </a>
      </template>
      <template #row-details="row">
        <b-card class="row-detail">
          <b-row class="mb-2">
            <b-col>
              <a v-if="row.item.hours>0" href="javascript:;" class="btn" @click="checkHoursModal(row.item.contract_id)">
                <font-awesome-icon icon="signature" />{{$t("labels.edithours")}}
              </a>
            </b-col>
            <b-col>
              <a href="javascript:;" class="btn" @click="editContractModal(row.item.project_id,row.item.user_id)">
                <font-awesome-icon icon="cog" />{{$t("labels.editcontract")}}
              </a>
            </b-col>

            <b-col v-if="row.item.ending==true">
              <font-awesome-icon icon="exclamation" style="color: red;" />
              {{$t("labels.agreement_ends")}}
            </b-col>
            <b-col v-else>
              <font-awesome-icon icon="check" style="color: green;" />
              {{$t("labels.agreement_doesnotend")}}
            </b-col>
            <b-col v-if="row.item.signed_zzpr==0">
              <font-awesome-icon icon="hammer" style="color: red;" />{{$t("labels.contractnotsignedzzp")}}
            </b-col>
            <b-col v-else>
              <font-awesome-icon icon="hammer" style="color: green;" />{{$t("labels.contractsignedzzp")}}
            </b-col>
            <b-col v-if="row.item.signed_company==0">
              <font-awesome-icon icon="pen" style="color: red;" />{{$t("labels.contractnotsignedcompany")}}
            </b-col>
            <b-col v-else>
              <font-awesome-icon icon="pen" style="color: green;" />{{$t("labels.contractsignedcompany")}}
            </b-col>
          </b-row>
        </b-card>
      </template>
      <template #cell(actions)="row">
        <div class="action-items">
          <a v-if="row.item.hours>0" href="javascript:;" class="action-item"
            @click="checkHoursModal(row.item.contract_id)">
            <font-awesome-icon icon="signature" />
          </a>
          <a href="javascript:;" class="action-item" @click="editContractModal(row.item.project_id,row.item.user_id)">
            <font-awesome-icon icon="cog" />
          </a>
        </div>
        <font-awesome-icon v-if="row.item.ending==true" icon="exclamation" style="color: red;" />
        <font-awesome-icon v-else icon="check" style="color: green;" /><br />
        <font-awesome-icon v-if="row.item.signed_zzpr==0" icon="hammer" style="color: red;" />
        <font-awesome-icon v-else icon="hammer" style="color: green;" />
        <font-awesome-icon v-if="row.item.signed_company==0" icon="pen" style="color: red;" />
        <font-awesome-icon v-else icon="pen" style="color: green;" />
      </template>
      <template #cell(lastname)="row" @click.native.capture="show_user()">
        <p v-html="userinfo(row)" />
      </template>
    </b-table>
    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>

  </b-container>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'planningscreen',
  data() {
    return {
      sortBy: 'company_name',
      sortDesc: false,
      currentbranche: -1,
      startw: "",
      endw: "",
      classcolor1: "#ff0000",
      classcolor2: "#ff0000",
      classcolor3: "#ff0000",
      currentPage: 1,
      perPage: 10,       
      totalRows:0,
      generateInfo:[]
    }
  },
  mounted() {
    this.startweekinit();
    this.classcolor1 = this.settings.status_colors[0].color
    this.classcolor2 = this.settings.status_colors[1].color
    this.classcolor3 = this.settings.status_colors[2].color

  },
  computed: {
    contractinfo() {
      if(this.generateInfo.length<=0){
        this.generateContractinfo()
        return this.generateInfo;
      } else {
        return this.generateInfo
      }
    },

    fields() {
      return [
        { key: 'lastname', label: this.$i18n.t('labels.ZZPer'), sortable: true, sortDirection: 'desc' },
        { key: 'project', label: this.$i18n.t('labels.project'), sortable: false, sortDirection: 'desc' },
        { key: 'startdate', label: this.$i18n.t('labels.startdate'), sortable: true, sortDirection: 'desc' },
        { key: 'enddate', label: this.$i18n.t('labels.enddate'), sortable: true, sortDirection: 'desc' },
        { key: 'company_name', label: this.$i18n.t('labels.companyname'), sortable: true, sortDirection: 'desc' },
        { key: 'description', label: this.$i18n.t('labels.description') },
        { key: 'color', label: this.$i18n.t('labels.statuscolor') },
        // { key: 'actions', label: this.$i18n.t('labels.actions') },
      ]
    },
    ...mapState([
      'projects',
      'users',
      'companies',
      'contracts',
      'branches',
      'planning',
      'settings',
      'hours'
    ]),
    ...mapGetters([
      'project_by_id',
      'company_by_id',
      'contract_by_id',
      'zzpers',
      'user_by_id',
      'username_by_id',
      'createAny'
    ]),

  },
  methods: {
    ...mapActions([
      'save_contract',
    ]),
    generateContractinfo() {
      let startyear = this.startw.substring(0, 4)
      let startweek = this.startw.substring(6)
      var d = new Date(startyear, 0, 1);
      d.setDate(d.getDate() + (startweek * 7));
      var startdate = this.getMonday(d).setHours(0,0,0,0)
      let endyear = this.endw.substring(0, 4)
      let endweek = Number(this.endw.substring(6))
      d = new Date(endyear, 0, 1);
      d.setDate(d.getDate() + (endweek * 7));
      var enddate = this.getMonday(d.getTime() + 7 * 24 * 60 * 60 * 1000).setHours(0,0,0,0)
      let thisweeksend = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
      let values = [];
      for (var i = 0; i < this.contracts.length; i++) {
        if (this.user_by_id(this.contracts[i].user_id) != null) {
          let userplan = this.userplanning(this.contracts[i].user_id)
          userplan.filter(p =>p.project_id == this.contracts[i].project_id).forEach(userplan => {
            let row = {};
            let betweendates = false
            row.name = this.username_by_id(this.contracts[i].user_id)
              let project = this.project_by_id(this.contracts[i].project_id)
              if (project) {
                row.project = userplan.project_name
                row.id = userplan.id
                row.lastname = this.user_by_id(userplan.user_id).lastname
                row.user_id = userplan.user_id
                row.project_id = userplan.project_id
                row.startdate = this.formatDate(userplan.startdate)
                row.enddate = this.formatDate(userplan.enddate)
                row.signed_zzpr = this.contracts[i].signed_zzpr
                row.signed_company = this.contracts[i].signed_company
                let projstartdate = new Date(userplan.startdate).setHours(0,0,0,0)
                let projenddate = new Date(userplan.enddate).setHours(0,0,0,0)
                if ((projstartdate <= enddate && projenddate >= startdate)) {
                  betweendates = true
                }
                let company = this.company_by_id(project.company_id)
                row.company_name = company.company_name
                row.description = userplan.project_description
                row.branche_id = company.branche_id
                row.ending = projenddate < thisweeksend
                row.color = this.contracts[i].color
                row.contract_id = this.contracts[i].id
                row.hours = this.has_hours(row.contract_id)
                row._showDetails = true
                if (betweendates && row.branche_id == this.currentbranche || betweendates && this.currentbranche == -1) {
                  values.push(row)
                }
              }
          });
        }
      }
      this.totalRows = values.length
      this.generateInfo = values
    },

    color1(item) {
      this.contract_by_id(item.contract_id).color = this.settings.status_colors[0].color
      this.save_contract(item.contract_id)
      item.color = this.settings.status_colors[0].color
    },

    color2(item) {
      this.contract_by_id(item.contract_id).color = this.settings.status_colors[1].color
      this.save_contract(item.contract_id)
      item.color = this.settings.status_colors[1].color
    },
    color3(item) {
      this.contract_by_id(item.contract_id).color = this.settings.status_colors[2].color
      this.save_contract(item.contract_id)
      item.color = this.settings.status_colors[2].color
    },
    rowClass(item) {
      //  return { "style":"box-shadow:0px 0px 0px 5px "+item.color+" inset"}

      return { "style": "background-color:" + item.color }
    },
    userinfo(row) {
      return '<a href="/#/user/' + row.item.user_id + '" :style="{ cursor: \'pointer\'}" class="link-item"> ' + this.username_by_id(row.item.user_id) + '</a>'
    },
    startweekinit() {
      let now = new Date()
      let sweek = this.getWeekNumber(now)
     this.startw = sweek[1]<10?sweek[0] + "-W0" + sweek[1]:sweek[0] + "-W" + sweek[1]
      let eweek = this.getWeekNumber(new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000))
     this.endw = eweek[1]<10?eweek[0] + "-W0" + eweek[1]:eweek[0] + "-W" + eweek[1]
    },
    userplanning(user_id) {
      return this.planning.filter(p =>p.user_id==user_id)
    },

    has_hours(contract_id) {
      return this.hours.filter(h =>h.contract_id == contract_id).length
    },

  },
  watch: {
    startw: function () {
      let startyear = this.startw.substring(0, 4)
      let startweek = this.startw.substring(6)
      var sd = new Date(startyear, 0, 1);
      sd.setDate(sd.getDate() + (startweek * 7));
      let endyear = this.endw.substring(0, 4)
      let endweek = Number(this.endw.substring(6))
      let ed = new Date(endyear, 0, 1);
      ed.setDate(ed.getDate() + (endweek * 7));
      if (ed < sd) {
        //correct endweek to startweek +1
        let eweek = this.getWeekNumber(new Date(sd.getTime() + 7 * 24 * 60 * 60 * 1000))
        this.endw = eweek[0] + "-W" + eweek[1]
      }
     this.generateContractinfo()
    },
    endw: function () {
      let startyear = this.startw.substring(0, 4)
      let startweek = this.startw.substring(6)
      var sd = new Date(startyear, 0, 1);
      sd.setDate(sd.getDate() + (startweek * 7));
      let endyear = this.endw.substring(0, 4)
      let endweek = Number(this.endw.substring(6))
      let ed = new Date(endyear, 0, 1);
      ed.setDate(ed.getDate() + (endweek * 7));
      if (ed < sd) {
        //correct endweek to startweek +1
        let eweek = this.getWeekNumber(new Date(sd.getTime()))
        this.endw = eweek[0] + "-W" + eweek[1]
      }
      this.generateContractinfo()

    }
  }
}
</script>