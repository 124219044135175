<template>
  <b-modal id="BSSignHoursID" centered size="lg" @show="showMe" @hide="close_modal">
    <template #modal-header>
      <h2 class="modal-title">{{ $t("buttons.hoursvalidated") }}</h2>
      {{ contract_id }} {{weeknumber}} {{yearnumber}}
    </template>

    <form class="form">
      <div v-if="createAny('users')" class="form-row">
        <div class="col-4">
          <label for="">{{ $t("labels.note") }}</label>
        </div>
        <div class="col">
          <b-input-group v-if="createAny('users')">
            <b-form-textarea size="sm" rows="8" v-bind:type="comment" v-bind:id="'bind-remarks'" :ref="comment"
              class="form-control" v-model="comment" />
            <b-input-group-append>
              <b-button squared class="multi-append"  href="javascript:;"
                @click="append_data_multi('comment')">
                <font-awesome-icon icon="plus-circle" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </form>

    <template #modal-footer>
      <div @click="saveData" class="btn btn-primary" :disabled="comment.length==0">
       {{ btnText() }}
      </div>
      <div @click="close_modal()" class="btn btn-secondary">
       {{ $t("buttons.cancel") }}
      </div>
    </template>
  </b-modal>
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex'
// import axios from 'axios'

export default {
  name: 'sign-hours-modal',
  data() {
    return {
      comment:"",
      hourid:0
    }
  },
  props: {
    contract_id: Number,
    weeknumber: Number,
    yearnumber: Number
  },
  computed: {

    ...mapState([
      'hours'

    ]),
    ...mapGetters([
      'confirm_modal',
      'createAny',
      'append_data_string',
      'hours_by_id'


    ])
  },
  methods: {
    showMe() {
      this.$store.commit('modal_confirm',{ id: 0})
      let foundSign = this.hours.filter(h => h.workdate == "sign"+this.contract_id+"-"+this.weeknumber+"-"+this.yearnumber);
      if(foundSign && foundSign.length>0){
        this.comment = foundSign[0].comment
        this.hourid = foundSign[0].id
      }

    },
    btnText(){
      if (this.comment != "") {
          return this.$i18n.t("buttons.hoursvalidated")
        } else {
          return this.$i18n.t("buttons.invalid")
        }
    },
    ...mapActions([
        'save_hours',
        'add_hours'
      ]),      
    saveData(){
      if(this.comment.length>0) {
        this.$store.commit('modal_confirm',{ id: 1})
        if(this.hourid==0){
          var newhours={id:this.hourid,
                      workdate:"sign"+this.contract_id+"-"+this.weeknumber+"-"+this.yearnumber,  
                      comment: this.comment,
                      added_date:new Date().toUTCString()}
          this.add_hours(newhours)
        } else {
          let h = this.hours_by_id(this.hourid)
          h.comment = this.comment
          this.save_hours(this.hourid)

        }
      //  axios.post('/api/set_invoice_extra',{contract_id:this.contract_id, year:this.yearnumber, week:this.weeknumber, description:this.description, extra:this.extra})
       this.close_modal()
      }
    },

    close_modal() {
      this.$emit('prehide')
      this.$bvModal.hide('BSSignHoursID')
    },
    append_data_multi(key) {

      var current = ""
      if (this.comment) {
        current = "\n" + this.comment
      }
      this.comment = this.append_data_string + current
      this.componentKey += 1;
      if (this.$refs[key]) {
        if (this.$refs[key][0]) {
          this.$refs[key][0].focus()
          this.$nextTick(() => {
            this.$refs[key][0].selectionEnd = 9
          })
        } else {
          this.$refs[key].focus()
          this.$nextTick(() => {
            this.$refs[key].selectionEnd = 9
          })
        }
      }
    },    
  },
}    
</script>
