<template>
	<div class="popup">
        <div class="popup-header">
              {{$t('titles.contact')}} @ {{this.username_by_id(this.user_id)}}
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
          <b-container fluid>        
            
            <div class="form-row" >
                <div class="col-3">
                    <label for=""> {{$t("meta.mobiel")}}</label>
                </div>  
  
                <div class="col-8" v-if="this.user_by_id(this.user_id).mobile">
                    {{this.user_by_id(this.user_id).mobile}}
                </div>    
                <div class="col-8" v-else >
                    <font-awesome-icon color="red" icon="exclamation"/>
                </div>             
            </div>             
            <div class="form-row" >
                <div class="col-3">
                    <label for=""> {{$t("labels.message")}}</label>
                </div>  
                <div class="col-8">
                    <b-form-textarea  rows="3" v-model="smsmessage"></b-form-textarea>
                </div>               
            </div>             
            <div class="form-row" >
                <div class="col-3">
                    <label for=""> {{$t("labels.messagesms")}}</label>
                </div>  
                <div class="col-8">
                    {{smsmessage.length}} <font-awesome-icon v-if="smsmessage.length>160" color="red" icon="exclamation"/>
                </div>               
            </div>             
        <div class="form-row">
            <div class="col-3">
                <label for=""> {{ $t("labels.sendsmsas") }}</label>
            </div>
            <div class="col">
                <select name="admin" class="form-control admins" v-model="sender" >
                    <option  v-for="(user,idx) in owners"  :key="idx" v-bind:value="user.id">
                    {{ user["firstname"]}} {{ user["middlename"] }} {{ user["lastname"] }} - {{ user["mobile"] }}
                    </option>
                </select>                
            </div>
        </div>
          </b-container>
        </div>
        <div class="popup-footer">
            <div @click="save_and_close" class="btn btn-primary"><font-awesome-icon icon="sms" /> {{ modalValid()  }}</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

// const querystring = require("querystring");

export default {
    name: 'send_sms_modal',
    data() {
        return {
            smsmessage:"",
            sender:null
        }
    },
    props: {
        message: String,
        user_id: Number
    },
    
    mounted() {
        this.smsmessage = this.message
    },
    computed: {
        ...mapGetters([
            'username_by_id',
            'user_by_id',
            'owners'
            
        ]),        
    },
    methods: {
        valid(){
            if(this.smsmessage.length<=160 && this.user_by_id(this.user_id).mobile && this.sender){
                return true
            } else{
                return false
            }
        },
        modalValid(){
            if(this.valid()){
                return this.$i18n.t("buttons.sendmessage")

            } else { 
                return this.$i18n.t("buttons.invalid") 
            }
        },
        save_and_close() {
            if(this.valid()){

              let message ={}
              message.sms = this.smsmessage
              message.sendto = this.user_by_id(this.user_id).mobile
              message.sendfrom  = this.user_by_id(this.sender).mobile
              message.touser  = this.username_by_id(this.user_id)
              message.fromuser  = this.username_by_id(this.sender)

            try{
                axios.post('/api/send_sms', message)  .then((response) => {
                    console.log('succes',response)
                    if (response.data==200) {
                        //send mail
                        message.email = this.user_by_id(this.sender).email
                        message.name = message.fromuser
                        message.message = this.smsmessage
                        message.subject = "SMS verstuurd naar " + message.touser
                        message.maindocuments = []
                        // console.log("MAIL>", message)
                        try{
                            axios.post('/api/contact_user', message)  .then((response) => {
                                console.log('succes',response)
                                this.$emit('close')
                            })
                        } catch(err){
                            console.log (err)
                        }                          
                    }
                })
            } catch(err){
                console.log (err)
            }  

            }
        },
    },
}
</script>