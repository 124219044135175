<template>
	<div class="popup">

        <div class="popup-header">
            <div>
                 {{ $t("labels.confirm") }} lalala
            </div>
            <div  class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body" style="overflow:hidden">
          <b-container>
          <div class="row">
            <div class="col-3">{{$t('labels.resource')}}</div>
            <div class="col">{{ resource_by_id(resource_id).name }}</div>
          </div>
          <div class="row">
            <div class="col-3">{{$t('labels.project')}}</div>
            <div class="col">{{ project.title }}</div>
          </div>
          <div class="row">
            <div class="col">{{$t('labels.startdate')}} {{ start_offset }}</div>
            <div class="col"><input type="date" v-model='initstart'></div>
            <div class="col">{{$t('labels.enddate')}}{{ end_offset }}</div>
            <div class="col"><input type="date" v-model='initend'></div>
          </div>
          <div class="row">
            <div class="col">{{$t('meta.starttijd')}}</div>
            <div class="col"><input type="time" v-model='initstartt'></div>
            <div class="col">{{$t('meta.eindtijd')}}</div>
            <div class="col"><input type="time" v-model='initendt'></div>
          </div>          
          <CheckResourcePlanning :resource_id="resource_id" :startdate="initstart" :enddate="initend" />  
          </b-container>
        </div>
        <div class="popup-footer">
            <div class="btn btn-primary" @click="$emit('close')">{{ $t("buttons.cancel") }}</div>
            <div @click="save_and_close" class="btn btn-primary">{{ $t("buttons.saveandclose") }}</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {  mapGetters, mapState, mapActions} from 'vuex'
import CheckResourcePlanning from './CheckResourcePlanning.vue'

export default {
    name: 'dropresourceonreource-modal',
    data() {
        return {
          confirmed:false,
          initstart:null,
          initend:null,
          user_id:null,
        }
    },
    props: {
        workfield_id:Number,
        resource_id: Number
    },
    mounted() {
      this.project_id = this.projectresource_by_id(this.workfield_id).project_id
        this.initstartt = this.projectresource_by_id(this.workfield_id).start_time
        this.initendt = this.projectresource_by_id(this.workfield_id).end_time    

        let initstart = new Date(this.project.startdate)  
        initstart.setHours(0,0,0,0)
        initstart.setDate(initstart.getDate() + this.projectresource_by_id(this.workfield_id).start_offset);
        this.initstart = this.dateToYYYYMMDD(initstart)
        let initend = new Date(this.initstart)
        initend.setDate(initend.getDate() + this.projectresource_by_id(this.workfield_id).end_offset);
        this.initend = this.dateToYYYYMMDD(initend)    
    },
    computed: {
      ...mapGetters ([
      'zzpers',
      'project_by_id',
      'user_by_id',
      'username_by_id',
       'createAny'

  ]),
  ...mapState ([
      'ffwax',
    ]), 
    project(){
      return this.project_by_id(this.project_id)
    },    
    start_offset(){
      return Math.round(( new Date(this.initstart).getTime() - new Date(this.project.startdate).getTime())/86400000)
    },
    end_offset() {
      return Math.round(( new Date(this.initend).getTime() - new Date(this.initstart).getTime())/86400000)
    },       
    },
    methods: {
      ...mapActions([
      'add_ffwax'
    ]),      
        save_and_close() {
          let ffwax = {id:0, user_id:this.user_id,project_id:this.project_id, resource_id:this.resource_id, start_offset:this.start_offset,end_offset:this.end_offset, start_time:this.initstartt,end_time:this.initendt}
          this.add_ffwax(ffwax)
          // save user_id in projectresources
          axios.put('/api/set_projectresource_filled', {id:this.workfield_id, filled: this.resource_id})
          this.projectresource_by_id(this.workfield_id).filled=this.resource_id          
          this.$emit('close')
        },

    },
    components:{CheckResourcePlanning}
}
</script>