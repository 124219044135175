<template>
	<div class="popup">

        <div class="popup-header">
            <div>
                 {{ $t("labels.editplanning") }}
            </div>
            <div  class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body" style="overflow:hidden">
          <b-container>

          <div class="row">
            <div class="col-3">{{$t('labels.resource')}}</div>
            <div class="col">{{resource.name }}</div>
            </div>
            <div class="row">

            <div class="col-3">{{$t('labels.project')}}</div>
            <div class="col">{{project.title }}</div>
          </div>
          <div class="row">
            <div class="col">{{$t('labels.startdate')}} </div>
            <div class="col"><input type="date" v-model='initstart'></div>
            <div class="col">{{$t('labels.enddate')}} </div>
            <div class="col"><input type="date" v-model='initend'></div>
          </div>
          <div class="row">
            <div class="col">{{$t('meta.starttijd')}}</div>
            <div class="col"><input type="time" v-model='thiswax.start_time'></div>
            <div class="col">{{$t('meta.eindtijd')}}</div>
            <div class="col"><input type="time" v-model='thiswax.end_time'></div>
          </div>          
          </b-container>
        </div>
        <div class="popup-footer">
            <div class="btn btn-primary" @click="$emit('close')">{{ $t("buttons.cancel") }}</div>
            <div @click="delete_wax" class="btn btn-primary">{{ $t("labels.deleteproject") }}</div>
            <div @click="save_and_close" class="btn btn-primary">{{ $t("buttons.saveandclose") }}</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {  mapGetters, mapState, mapActions} from 'vuex'

export default {
    name: 'clickonresource-modal',
    data() {
        return {
          confirmed:false,
          initstart:null,
          initend:null,
          resource_id:null,
        }
    },
    props: {
        ffwax_id:Number,
    },
    mounted() {
        // console.log(this.thiswax)
        let initstart = new Date(this.project.startdate)
        initstart.setHours(0,0,0,0)
        initstart.setDate(initstart.getDate() + this.thiswax.start_offset);
        this.initstart = this.dateToYYYYMMDD(initstart)
        let initend = new Date(this.initstart)
        initend.setDate(initend.getDate() + this.thiswax.end_offset);
        this.initend = this.dateToYYYYMMDD(initend)

    },
    computed: {
      ...mapGetters ([
      'zzpers',
      'project_by_id',
      'user_by_id',
      'username_by_id',
       'createAny',
       'ffwax_by_id'

  ]),
  ...mapState ([
      'ffwax',
    ]), 
    thiswax(){
      return this.ffwax_by_id(this.ffwax_id)
    },
    resource() {
     return this.resource_by_id(this.thiswax.resource_id)
    },
    project() {
     return this.project_by_id(this.thiswax.project_id)
    },
    start_offset(){
    return Math.round(( new Date(this.initstart).getTime() - new Date(this.project.startdate).getTime())/86400000)
    },
    end_offset() {
      return Math.round(( new Date(this.initend).getTime() - new Date(this.initstart).getTime())/86400000)
    },        
    },
    methods: {
      ...mapActions([
      'add_ffwax',
      'save_ffwax',
      'delete_ffwax'
    ]),      
        save_and_close() {
          //overwrite offset
          this.thiswax.start_offset = this.start_offset
          this.thiswax.end_offset = this.end_offset          
          this.save_ffwax(this.thiswax.id)
          this.$emit('close')
        },
        delete_wax() {
          let restoclear = this.projectresources.filter(pr => pr.filled==this.thiswax.resource_id && pr.project_id==this.thiswax.project_id)[0]
          if(restoclear){
            axios.put('/api/set_projectresource_filled', {id:restoclear.id, filled: 0})
            this.projectresource_by_id(restoclear.id).filled=0      
          }
          this.delete_ffwax(this.thiswax.id)

          this.$emit('close')
        }

    }
}
</script>