<template>
  <div class="popup">
      <div class="popup-header">
          {{ $t('titles.contact') }} {{ this.username_by_id(this.user_id) }}
          <div class="popup-close" @click="$emit('close')">
              <font-awesome-icon icon="times" />
          </div>
      </div>
      <div class="popup-body">
          <b-container fluid>
              <div class="form-row">
                  <div class="col-3">
                      {{ $t("titles.subject") }}
                  </div>
                  <div class="col-8">
                      <b-input  :state="subject!=null&&subject!=''" v-model="subject" required :placeholder='$t("titles.subject")' />
                  </div>
              </div>
              <textarea style="width:100%; height:250px"  v-model="message" required></textarea>

          </b-container>
      </div>
      <div class="popup-footer">
          <div @click="save_and_close" class="btn btn-primary"><font-awesome-icon icon="bell" /> {{ $t("buttons.sendmessage") }}</div>
      </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import axios from 'axios';

export default {
  name: 'notify_user_modal',
  data() {
      return {
          subject: "",
          message:""
      }
  },
  props: {
      user_id: Number,
  },

  mounted() {
  },
  computed: {


      user() {
          return this.user_by_id(this.user_id)
      },

      ...mapGetters([
          'username_by_id',
          'user_by_id',
      ]),
      ...mapState([
          'settings',
          'current_user',
      ])
  },
  methods: {
      save_and_close() {
          let message = {}
          message.message = this.message
          message.subject = this.subject
          message.user_id = this.user_id
          if(this.settings.PMHistory) {
              this.add_pm( { id: 0, from: this.current_user.id, to: this.user_id, message:this.subject+"<br>"+message.message })
          }
          try {
              axios.post('/api/notify', message).then((response) => {
                  console.log('succes', response)
              })
          } catch (err) {
              console.log(err)
          }
          this.$emit('close')
      },
      ...mapActions([
        'add_pm'

      ]),

  },
}
</script>