<template>
  <div class="timerlayer" >
    <div class="indicatortimer" @click="showTimer"><font-awesome-icon v-if="!running" icon="play" /><font-awesome-icon v-else icon="pause" />
    </div>
    <span v-if="false && buildtarget=='apprelease'"><br/><br/>{{distance}}</span>
  
    
    <BSTimer  ref="bsmodal" id="mymodal" :worktimer="timerdata" @madeChoice="modalClose"/>
    <!-- <UserHoursModal :day="showday" :contract_id="contract_id" :select="selecthours" ref="myuserhours" /> -->
    <UserHoursModal v-if="this.$route.name!='UserHours'"  ref="myuserhours" />
    <BSTimerSelectProject :validcontracts="possiblecontracts" @choosecontract="contractchoosen"/>
  </div>
</template>
<script>

import axios from 'axios'
import { mapGetters, mapState, mapActions } from 'vuex'
import BSTimer from './modals/BSTimer.vue'
import UserHoursModal from './modals/UserHoursModal.vue'
import BSTimerSelectProject from './modals/BSTimerSelectProject.vue'
import { Geolocation } from '@capacitor/geolocation';

export default {
    name: 'timerlayer',
    data() {
        return {
          running:false,
          timerid:null,
          timerdata:null,
          showday:"",
          contract_id:0,
          selecthours:false,
          possiblecontracts:[],
          distance:0


        }
    },
    async mounted(){
      await axios.get('/api/get_timer').then(response => {
        // console.log("Response", response.data)
        if(response.data.length==0){
          this.timerid=0
        } else {
          this.timerid = response.data[0].id
          this.running = response.data[0].active==1
          this.timerdata = response.data[0]
        }
      })
 
      


    },
    computed: {
    ...mapGetters([
      'createAny',
      'readAny',
      'updateAny',
      'deleteAny',
      'user_by_id',
      'is_logged_in',
      'notifications',
      'planning_by_id',
      'pms',

    ]),
    ...mapState([
        'current_user',
        'settings',
        'contracts',
        'timerposition'

    ]),
  },
    methods: {
        ...mapActions([
            'logout',
        ]),
        async contractchoosen(c){
          this.contract_id=c.id
          await this.$bvModal.hide('BSTimerSelectProjectsID')
          this.showUserHours()
        },
        async modalClose(e){
          switch(e){
            case "StopIt":
              console.log("JustStopTimer")
              this.running=false
              await axios.put('/api/set_timer', {id:this.timerid, active:this.running?1:0})

              break;
            case "StopItAndBook":
              var today = new Date();
              this.possiblecontracts=[]
              this.contracts.filter(acontract => acontract.user_id == this.current_user.id).forEach(c =>
                {
                  let p = this.planning_by_id(c.planning_id)
                  if(today>= new Date(p.startdate) && today<=new Date(p.enddate)){
                    this.possiblecontracts.push(c)
                    this.contract_id=c.id
                  }
                }
              )
              this.running=false
              await axios.put('/api/set_timer', {id:this.timerid, active:this.running?1:0})

              this.showday = this.dateToYYYYMMDD(today)
              if(this.possiblecontracts.length==1){
                this.showUserHours()
              }
              if(this.possiblecontracts.length>1){
                this.$bvModal.show('BSTimerSelectProjectsID')
              }
              break;
          }
        },
        showUserHours(){
          this.selecthours = true
          // this.$refs.myuserhours.show()
          var delta = Math.abs(new Date()-new Date(this.timerdata.added_date)) / 1000;
          var days = Math.floor(delta / 86400);
          delta -= days * 86400;
          var hours = Math.floor(delta / 3600) % 24;
          delta -= hours * 3600;
          var minutes = Math.floor(delta / 60) % 60;
          delta -= minutes * 60;


          // this.$refs.myuserhours.fillnormalhours(hours+"."+minutes)
          // //fill workhours
          // this.$refs.myuserhours.fillhourworks(new Date(this.timerdata.added_date).toTimeString().split(' ')[0],new Date().toTimeString().split(' ')[0])
          var workhoursstart = new  Date(this.timerdata.added_date).toTimeString().split(' ')[0]
          var workhoursend = new Date().toTimeString().split(' ')[0]
          var normalhours = parseFloat(hours)+parseFloat((minutes/60).toFixed(2))
          let decimal = (Math.round(normalhours * 4) / 4).toFixed(2)
          normalhours = decimal.split('.')[0] + '.' + decimal.split('.')[1].slice(0, 2)
          this.$store.commit('userhourmodal_settings',{contract_id:this.contract_id, day:this.showday, select:true,fromLayer:true,normalhours:parseFloat(normalhours),workhoursstart:workhoursstart,workhoursend:workhoursend})

          this.$bvModal.show('userHoursModalID')

        },
        async showTimer(){
          if(this.running){
            this.$bvModal.show('BSTimerID')
          } else {
            this.running = !this.running
            let timerobject = {id:this.timerid, active:this.running?1:0, added_date:new Date().toUTCString()}
            if (this.buildtarget=="apprelease") {
              const coordinates = await Geolocation.getCurrentPosition();
              timerobject.lat =  coordinates.coords.latitude 
              timerobject.long =  coordinates.coords.longitude
              this.timerposition.lat = coordinates.coords.latitude
              this.timerposition.long = coordinates.coords.longitude
              // console.log(">>>>", coordinates.coords.latitude, coordinates.coords.longitude)
              // setInterval(this.determinedistance, 60000)
            }

            await axios.put('/api/set_timer', timerobject)
            .then(response =>{
              // console.log("set", response)
              if(response.data){
                this.timerid = response.data.id
                this.timerdata = response.data
                // console.log("TIMERDATA", this.timerdata)
              }
            })
          }

        },
        async determinedistance(){
          // console.log("determinedistance")
          if (this.buildtarget=="apprelease") {
              const coordinates = await Geolocation.getCurrentPosition()
              var R = 6371; // Radius of the earth in km
              var dLat = this.deg2rad(coordinates.coords.latitude-this.timerposition.lat);  // deg2rad below
              var dLon = this.deg2rad(coordinates.coords.longitude-this.timerposition.long);  // deg2rad below
              var a = 
                Math.sin(dLat/2) * Math.sin(dLat/2) +
                Math.cos(this.deg2rad(this.timerposition.lat)) * Math.cos(this.deg2rad(coordinates.coords.latitude)) * 
                Math.sin(dLon/2) * Math.sin(dLon/2)
                ; 
              var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
              var d = R * c; // Distance in km
              this.distance = d.toString();
            // console.log(this.distance)
           
          }
        },
         deg2rad(deg) {
              return deg * (Math.PI/180)
            }   
    },
    components: { BSTimer, UserHoursModal, BSTimerSelectProject },

}
</script>
<style>
.timerlayer{
	height:2.5rem; 
	width:12.5rem;
	position:fixed; 
	right:15px;
	top:35px;
	z-index:12;
  /* background-color: blue;  */
}
.indicatortimer {
	position:fixed;
	right:4rem;
	top:35px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #00ff00;
	color: #fff;
	border-radius: 50%;
	font-size: .8rem;
	width: 2rem;
	height: 2rem;
  cursor: pointer;
}
  </style>