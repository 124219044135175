<template>
  <b-container fluid :style="AppSecondaryColor()">
  <div id="user-project-overview">
    <input type="hidden" :value="componentKey" />
    <input type="hidden" :value="'p'+totalPending" />
    <input type="hidden" :value="'c'+totalClosed" />
    <input type="hidden" :value="'s'+totalSigned" />
    <!-- Open hours -->
    <div v-if="index==1">
      <div v-for="(project, index) in tab1_my_user_projects" :key="index" class="project" :class="{ active: (pendingFor(project.contract_id)>0 || project.signed_zzpr==0) || inFuture(project)}"  :style="projectTitleClass(false)" >
        <div class="project-title" v-if="true ||pendingFor(project.contract_id)>0 || project.signed_zzpr==0" :style="projectTitleAppClass()">
          <h4 :style="AppPrimaryColor()">{{companyname_by_id(project.company_id)}}</h4>
          <div class="projectnumber">{{ project.projectnumber }}</div>
          <p>{{ project.project_name }}</p>
          <div class="row">
            <div class="col-6" v-if="project.signed_zzpr==0">
              <b-form-checkbox v-if="project.signed_zzpr==0" v-model="project.agreed" name="checkbox-1" value="1" unchecked-value="0">
                {{ $t('labels.sign_this_contract')}}
              </b-form-checkbox>
            </div>
            <div class="col" v-if="project.signed_zzpr==0">
              <b-button :style="AppPrimaryButton()" :disabled="project.signed_zzpr==0 && project.agreed=='0'" variant="primary"
                @click="sign_by_zzpr(project)" :title="$t('labels.sign_this_contract')">
                <font-awesome-icon icon="signature" />
              </b-button>                
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <b-button variant="link" @click="generate_pdf(project); setviewed(project);" :title="$t('labels.view')" :style="AppPrimaryColor()" >
                <font-awesome-icon icon="file-pdf" :style="AppPrimaryColor()"/>{{ $t("labels.showcontract") }}
              </b-button>
            </div>
          </div>
        </div>


          <!-- TODO: Tabel van maken -->

          <b-row v-if="pendingFor(project.contract_id)>0 && project.signed_zzpr!=0">
            <b-col  cols="1"></b-col>
            <b-col  cols="3"><strong>{{$t('labels.week')}}</strong></b-col>
            <b-col></b-col>
          </b-row>
          <div v-if="project.signed_zzpr!=0">
            <div v-for="(weeks, windex) in contractHours(project.contract_id)" :key="windex">
              <div v-if="((invoiceextraweek(project.contract_id,weeks.week, weeks.year).length>0 &&invoiceextraweek(project.contract_id,weeks.week, weeks.year )[0].absent==0)||invoiceextraweek(project.contract_id,weeks.week, weeks.year ).length==0)&&!weeks.closed" @click="pageUserHours(project.contract_id,weeks.week, weeks.year)">
                <b-row class="fill-hours">
                  <b-col cols="2">
                    <b-button variant="link">
                      <font-awesome-icon icon='pen' :style="AppPrimaryColor()" class="table-contacts-roles"  />
                    </b-button>
                  </b-col>
                  <b-col cols="2">{{weeks.week}}</b-col>
                  <b-col>{{$t('labels.hoursstatusfill')}}</b-col>
                </b-row>
              </div>
            </div>
          </div>

          <em v-else>{{$t('labels.agree_contract_hours')}}</em>

    </div>      
  </div>


    <!-- Waiting hours -->
    <div v-if="index==2" >
      <div v-for="(project, index) in my_user_projects" :key="index" class="project tab2"  :style="projectTitleClass(closedFor(project.contract_id)==0)" >
        <div class="project-title" v-if="closedFor(project.contract_id)>0" :style="projectTitleAppClass()">
          <h4 :style="AppPrimaryColor()">{{companyname_by_id(project.company_id)}}</h4>
          <div class="projectnumber">{{ project.projectnumber }}</div>
          <p>{{ project.project_name }}</p>
          <div class="row">
              <div class="col-4">
                <b-button variant="link" @click="generate_pdf(project);" :title="$t('labels.view')" :style="AppPrimaryColor()" >
                  <font-awesome-icon icon="file-pdf" />{{ $t("labels.showcontract") }}
                </b-button>
                </div>
            </div>          
        </div>

        <div v-if="closedFor(project.contract_id)>0">
          <b-row>
            <b-col  cols="2"></b-col>
            <b-col cols="2"><strong>{{$t('labels.week')}}</strong></b-col>
            <b-col></b-col>
          </b-row>
        </div>
        <div v-for="(weeks, windex) in contractHours(project.contract_id)" :key="windex">
          <div v-if="((invoiceextraweek(project.contract_id,weeks.week, weeks.year).length>0 &&invoiceextraweek(project.contract_id,weeks.week, weeks.year )[0].absent==0)||invoiceextraweek(project.contract_id,weeks.week, weeks.year ).length==0) &&weeks.closed&&weeks.signed==0" @click="pageUserHours(project.contract_id,weeks.week, weeks.year)">
            <b-row class="fillhours">
              <b-col cols="2">
                <b-button variant="link">
                  <font-awesome-icon icon='eye' :style="AppPrimaryColor()" class="table-contacts-roles"  />
                </b-button>
              </b-col>
              <b-col cols="2">{{weeks.week}}</b-col>
              <b-col>{{$t('labels.hourswaiting')}}</b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Aproved hours     -->
    <div v-if="index==3">
      <b-row>
        <b-col>
          <dl v-if="showweek!=-1 || showyear!=-1">
            <dt v-if="showweek!=-1">{{ $t("labels.week") }}</dt>
            <dd  v-if="showweek!=-1">{{ showweek }}</dd>
            <dt  v-if="showyear!=-1">{{ $t("labels.year") }}</dt>
            <dd  v-if="showyear!=-1">{{ showyear }}</dd>
          </dl>
       </b-col>
          <b-col v-if="showweek==-1 || showyear==-1" >
            <div class="form-row">
              <div class="col-3">
                <label for="">{{ $t("labels.week") }}</label>
              </div>
              <div class="col">
                <select v-model="showweek" class="form-control">
                  <option v-for="n in 53 " :key="n" :value="n">{{n}}</option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="col-3">
                <label for="">{{ $t("labels.year") }}</label>
              </div>
              <div class="col">
                <select v-model="showyear" class="form-control">
                  <option v-for="n in 20 " :key="n"> {{2022+n}}</option>
                </select>            
              </div>
            </div>
          </b-col>
          <b-col v-else>
            <b-button :style="AppPrimaryButton()" @click="showAll()">{{ $t("buttons.showall") }}</b-button>
          </b-col>       
      </b-row>
      <hr>      
      <div v-for="(project, index) in my_user_projects" :key="index" class="project" :style="projectTitleClass(signedFor(project.contract_id)==0)"  :class="{ active: signedFor(project.contract_id)>0 }" >
        <div class="project-title" :style="projectTitleAppClass()">
          <h4 :style="AppPrimaryColor()">{{companyname_by_id(project.company_id)}}</h4>
          <div class="projectnumber">{{ project.projectnumber }}</div>
          
          <p>{{ project.project_name }}</p>
          <div class="row">
              <div class="col-4">
                <b-button variant="link" @click="generate_pdf(project);" :title="$t('labels.view')" :style="AppPrimaryColor()" >
                  <font-awesome-icon icon="file-pdf" :style="AppPrimaryColor()"/>{{ $t("labels.showcontract") }}
                </b-button>
                </div>
            </div>              
        </div>
        <div v-if="signedFor(project.contract_id)>0">
          <b-row>
            <b-col cols="2"></b-col>
            <b-col cols="2"><strong>{{$t('labels.week')}}</strong></b-col>
            <b-col></b-col>
          </b-row>
        </div>
        <div v-for="(weeks, windex) in contractHours(project.contract_id)" :key="windex">
          <div v-if="weeks.signed!=0 && (showweek==-1 || weeks.week==parseInt(showweek))" >
            <b-row class="fillhours">
              <b-col cols="2">
                <b-button variant="link">
                  <font-awesome-icon :style="AppPrimaryColor()" icon='check' class="table-contacts-roles"  @click="pageUserHours(project.contract_id,weeks.week, weeks.year)"/>
                </b-button>
              </b-col>
              <b-col cols="2">{{weeks.week}}</b-col>
              <b-col>{{$t('labels.hoursstatuschecked')}}</b-col>
              <b-col v-if="inkoopfactuur(project)">
                <div @click=" generate_factuur(project.contract_id,weeks.week, weeks.year)">
                  <font-awesome-icon :style="AppPrimaryColor()" v-if="!generate_inkoop" icon='euro-sign'  />
                  <b-spinner :style="AppPrimaryColor()" small v-else></b-spinner> {{ $t('labels.invoice') }}
                </div>
              </b-col>
            </b-row>

          </div>
        </div>

      </div>
    </div>

  </div>
  <BSSignContract :user_id="user_id" :contract_id="signContract_id" @saveDone="saveDone" />

  </b-container>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions, mapState } from 'vuex'
import BSSignContract from '../modals/BSSignContract.vue'

export default {
  name: 'user-project-overview',
  data() {
    return {
      showweek: -1,
      showyear:-1,
      componentKey: 0,
      tab: 0,
      generated_my_projects:[],
      routeUrl:"",
      generate_inkoop:false,
      checkcolor:"",
      signContract_id:0
    }
  },
  props: {
    user_id: Number,
    show_archive: String,
    index: Number
  },
  async mounted() {
    this.checkcolor = this.primaryColor

    // this.showyear = new Date().getFullYear()
    
  },  
  computed: {
    user: {
      get() {
        return this.user_by_id(this.user_id)
      },
    },
    totalPending() {
      var count = 0
      this.generated_my_projects.forEach(project => {
        // console.log("pending", project.project_name, this.contractHours(project.contract_id).filter(aweek => {return !aweek.closed && aweek.waiting>0}))
        let notclosedweeks = this.contractHours(project.contract_id).filter(aweek => {return !aweek.closed && aweek.waiting==0})
        let projectcount = notclosedweeks.length
        notclosedweeks.forEach(aweek =>{
          if(this.invoiceextraweek(project.contract_id,aweek.week, aweek.year).length>0 ){
            if (this.invoiceextraweek(project.contract_id,aweek.week, aweek.year)[0].absent!=1){
              // console.log("add week ", aweek.week)
            } else{
              projectcount--
            }
          } 

          })
          count += projectcount  
       })
       this.$store.commit('totalPending',count)
       
      return count
    },
    totalClosed() {
      var count = 0
      this.generated_my_projects.forEach(project => {
        count += this.contractHours(project.contract_id).filter(aweek => {return (aweek.closed && aweek.signed == 0)}).length
      })
      this.$store.commit('totalClosed',count)

      return count
    },
    totalSigned() {
      var count = 0
      this.generated_my_projects.forEach(project => {
        // console.log("signed", project.project_name, this.contractHours(project.contract_id).filter(aweek => {return aweek.signed > 0}))

        count += this.contractHours(project.contract_id).filter(aweek => {return aweek.signed > 0}).length
      })      
      this.$store.commit('totalSigned',count)

      return count
    },
    myuserfields() {
      return [
        { key: 'startdate', label: this.$i18n.t('tableheaders.startdate'), sortable: true, sortDirection: 'desc' },
        { key: 'enddate', label: this.$i18n.t('tableheaders.enddate'), sortable: true, sortDirection: 'desc' },
        { key: 'company_id', label: this.$i18n.t('tableheaders.company_id'), formatter: this.companyname_by_id, sortable: true, sortDirection: 'desc' },
        { key: 'project_name', label: this.$i18n.t('tableheaders.project_name'), sortable: true, sortDirection: 'desc' },
        { key: 'id', label: this.$i18n.t('titles.project_hours'), },
        { key: 'signed_zzpr', label: "" },
      ]
    },
    my_user_projects() {
      if(this.generated_my_projects.length>0){
        return this.generated_my_projects
      } else {
        this.build_myprojects()
        return this.generated_my_projects
      }
    },
    tab1_my_user_projects() {
      return this.my_user_projects.filter(p => {
       return  this.pendingFor(p.contract_id)>0 || p.signed_zzpr==0 || new Date(p.sortdate) > new Date()
      })
    },
    ...mapGetters([
      'user_metas',
      'user_by_id',
      'activebranches',
      'user_new',
      'branche_by_id',
      'createAny',
      'planning_by_id',
      'contract_by_id',
      'project_by_id',
      'username_by_id',
      'find_contract_id',
      'user_photo_by_id',
      'server_url',
      'append_data_string',
      'confirm_modal',
      'company_by_id',
      'find_last_planning_user',
      'companyname_by_id'



    ]),
    ...mapState([
      'users',
      'current_user',
      'branches',
      'workfields',
      'avatars',
      'projects',
      'contracts',
      'hours',
      'companies',
      'planning',
      'facturen',
      'invoiceextra'

    ]),
   
  },
  methods: {
    async build_myprojects(){
      let myprojects = []
      this.contracts.filter(acontract => acontract.user_id == this.user_id).forEach(contract =>{
          var count = 0
          this.contractHours(contract.id).filter(ahour => {!ahour.closed}).length
          let row = {}
              row.id = contract.id;
              row.project_id = contract.project_id;
              let localproject = this.project_by_id(contract.project_id)
              if (localproject && localproject.title.substring(0,8)!="(CREDIT)") {
                // console.log(localproject.title.substring(0,8)!="(CREDIT)")
                row.company_id = localproject.company_id;
                row.contract_id = contract.id;
                row.planning_id = contract.planning_id;
                row.agreed = "0"
                let localplanning = this.planning_by_id(contract.planning_id)
                if (localplanning) {
                  row.id = contract.id;
                  row.project_name = localplanning.project_name
                  row.project_location = localplanning.project_location
                  row.projectnumber = localplanning.project_reference_number
                  row.sortdate = localplanning.startdate
                  row.startdate = this.formatDate(localplanning.startdate)
                  row.enddate = this.formatDate(localplanning.enddate)
                  row.signed_zzpr = contract.signed_zzpr
                  row.agreed = row.signed_zzpr == 0?"0":"1"
                  //check old projects
                  var now = new Date();
                  // console.log("><",this.show_archive, count, now < this.plus1week(localplanning.enddate), localplanning.project_name)
                  if (now.setHours(0,0,0) < this.plus1week(localplanning.enddate).setHours(0,0,0) || this.show_archive != "0" || count > 0) {
                    if(this.index==3){
                      if(this.showweek==-1||this.showyear==-1){
                        myprojects.push(row)
                      } else {
                        let startweek = parseInt(this.showweek)      
                        let startyear = parseInt(this.showyear)    
                        if(this.contractHours(row.id).filter(ch =>ch.week==startweek && ch.year==startyear && ch.signed !=0).length>0){
                          myprojects.push(row)
                        }
                      }
                    } else{
                      if(now.setHours(0,0,0) < this.plus1week(localplanning.enddate).setHours(0,0,0) || this.pendingFor(contract.id)>0|| this.closedFor(contract.id)>0|| this.signedFor(contract.id)>0){
                        myprojects.push(row)

                      }

                    }                  
                  }
                }
              }
        })
      this.generated_my_projects = this.sortprojects(myprojects)
      this.$emit('totalsdone', "done")      
      this.componentKey++
    },
    invoiceextraweek(contract_id, weeknumber,yearnumber) {
      return this.invoiceextra.filter(i => i.contract_id == contract_id && i.week == weeknumber && i.year == yearnumber)
    },  
    inkoopfactuur(project) {
      let realplanning = this.planning_by_id(this.contract_by_id(project.contract_id).planning_id)
      return realplanning.inkoopfactuur&&realplanning.inkoopfactuur=="yes"
    },   
    generate_factuur(contract_id, week, year) {
      this.generate_inkoop = true
      let planning = this.planning_by_id(this.contract_by_id(contract_id).planning_id)
      // get dates valid in this week for this contract
      let contractstart = new Date(planning.startdate);
      let contractend = new Date(planning.enddate);
      let startdate = new Date(year, 0, 1);
      let use_week = Math.max(1, (week % 53))
      startdate.setDate(startdate.getDate() + (use_week * 7));
      if (startdate < new Date(planning.startdate)) {
        startdate = new Date(planning.startdate)
      }
      let startmonday = this.getMonday(startdate);
      var loop = new Date(startmonday);
      let alldates = []
      var tddate = new Date(loop);
      tddate.setDate(tddate.getDate() + 7)
      while (loop < tddate) {
        let thisday = this.dateToYYYYMMDD(loop)
        if (loop.setHours(0,0,0) < contractstart.setHours(0,0,0) || loop.setHours(0,0,0) > contractend.setHours(0,0,0)) {
          alldates.push("0")
        } else {
          alldates.push(thisday)
        }
        var newDate = loop.setDate(loop.getDate() + 1);

        loop = new Date(newDate);

      }      
      let data = {}
      data.dates = alldates
      data.user_id = this.user_id
      data.contract_id = contract_id
      data.week = week
      // console.log(data);
      
      try {
        axios.post('/api/generate_inkoop', data).then(() => {
          let routeUrl = this.server_url + '/media_invoices/factuur-' + contract_id + "-" + this.user_id + "-" + week + ".pdf"
          window.open(routeUrl, '_blank')
          this.generate_inkoop = false

        })
      } catch (err) {
        console.log(err)
      }
      
    },        
    pdfLink(item) {
      this.server_url + '/media_contracts/overeenkomst-' + item.contract_id + ".pdf"
    },
    setviewed(item) {
      var f = this
      setTimeout(function () {
        item.viewedpdf = true
        f.componentKey++
      }, 750);
    },
    inFuture(p) {
        return new Date(p.sortdate) > new Date()
    },
    pendingFor(contract_id) {
      var count = 0
      this.contractHours(contract_id).forEach(weeks => {
        if (!weeks.closed) {
          if(this.invoiceextraweek(contract_id,weeks.week, weeks.year).length>0 ){
            if (this.invoiceextraweek(contract_id,weeks.week, weeks.year)[0].absent!=1){
              // console.log("add week ", weeks.week)
              count++
            } 
          } else{
            count++
          }
        }
      })
      return count
    },
    closedFor(contract_id) {
      var count = 0
      this.contractHours(contract_id).forEach(weeks => {
        if (weeks.closed && weeks.signed == 0) {
          count++
        }
      })
      return count
    },
    signedFor(contract_id) {
      var count = 0
      this.contractHours(contract_id).forEach(weeks => {
        if (weeks.closed && weeks.signed > 0) {
          count++
        }
      })
      return count
    },
    usershours(contract_id, user_id) {
      return this.hours.filter(obj => {
        return obj.user_id === user_id
      }).filter(obj2 => {
        return obj2.contract_id === contract_id
      })
    },

    sortprojects(myprojects) {
      let a = myprojects.sort((a, b) => (a.sortdate > b.sortdate) ? -1 : ((b.sortdate > a.sortdate) ? 1 : 0))
      return a
    },

    pageUserHours(contract, week, year) {
      this.$router.push({ path: '/userhours/' + contract + "/" + week + "/" + year });

    },
    ...mapActions([
      'save_user',
      'add_user',
      'save_contract',
    ]),
    sign_by_zzpr(contract) {
      if(this.settings.SignContract){
        this.signContract_id = contract.id
        this.$bvModal.show('BSSignContractID')


      } else {
        this.contract_by_id(contract.id).signed_zzpr = 1
        this.my_user_projects.filter(c => c.contract_id == contract.id)[0].signed_zzpr = 1
        this.componentKey++
        this.save_contract(contract.id);
      }
    },
    saveDone(res){
        console.log("savedone",res.data.ok)
        if(res.data.ok){
          this.contract_by_id(res.data.contract_id).signed_zzpr = 1
          this.my_user_projects.filter(c => c.contract_id == res.data.contract_id)[0].signed_zzpr = 1
          this.componentKey++
          this.save_contract(res.data.contract_id);      
        }
    },
    showAll(){
      this.showweek = -1
      this.showyear = new Date().getFullYear()
    }
    // classes for app version
 
  },
  components: {BSSignContract},
  watch: {
    show_archive: function () {
      if (this.show_archive == 1) {
        this.tab = 2
      }
    },
    hours: function () {
      this.generated_my_projects = [];
      this.flushContractHours()
      this.build_myprojects()
      this.$emit('totalsdone', "done")
    },
    showweek: function () {
      this.generated_my_projects = [];
      this.flushContractHours()
      this.build_myprojects()
      this.$emit('totalsdone', "done")
    },
    showyear: function () {
      this.generated_my_projects = [];
      this.flushContractHours()
      this.build_myprojects()
      this.$emit('totalsdone', "done")
    },
    settings(){
      this.checkcolor = this.primaryColor

    }    

  }

}
</script>
<style>
#user-project-overview {
	.active{
		display:block !important;
	}
  .project{
    display:none;
  }
  .tab2{
    display:block !important;
  }
}
</style>