<template>
  <b-modal id="BSManageUrenbonnenModalID" centered size="lg">
    <template #modal-header>
      <h2 class="modal-title">{{$t('titles.urenbon')}}</h2>
    </template>
    <div>
    <b-table :items="weekurenbonnen" :fields="fields"  @row-clicked="toggleActive" >
      <template #cell(id)="row">
        <font-awesome-icon v-if="ispublic(row)" icon="check" style="color: green;" />
        <font-awesome-icon v-else icon="times" style="color: red;" />
      </template>
      <template #cell(file)="row">
        {{ row.item.file.substring(8).replace('bon_','').replace('intern_','') }}
      </template>
      <template #cell(actions)="row">
        <a :href="docto(row)" v-bind:target="'_blank'" class='action-item float-left'
          :title="$t('labels.view')+ ' '+ row.item.file">
          <font-awesome-icon icon="eye" />
        </a>
        <!-- <a v-if="row.item.id!=undefined &&  (current_user.role=='user' || current_user.role=='admin')"
          href="javascript:;" class="action-item" @click="deleteurenbon(row)"
          :title="$t('labels.deletefile')+ ' '+ row.item.file">
          <font-awesome-icon icon="trash" />
        </a> -->
      </template>
    </b-table>
    </div>
    <template #modal-footer>
      <div class="btn btn-primary" @click="close_modal">
        {{ $t("buttons.close") }}
      </div>
    </template>
  </b-modal>
</template>


<script>
import { mapGetters, mapState } from 'vuex'
import popup_modal from '../modals/PopupModal.vue'
import axios from 'axios'

export default {
  name: 'public-urenbon-modal',
  data() {
    return {
      
    }
  },
  props: {
    weekurenbonnen: Array
  },
  computed: {
    fields() {
      return [ { key: 'file' },  { key: 'id' } , { key: 'actions' }  ]
       },
       
       ...mapGetters([
      'server_url',
      'createAny'

    ]),
    ...mapState([
      'users',
      'branches',
      'avatars',
      'urenbonnen'
    ]),

  },
  methods: {
    toggleActive(row) {
      if(row.name.substring(0,7)!="intern_"){
        row.name=row.name.replace('bon_', 'intern_')
      } else {
        row.name = row.name.replace('intern_', 'bon_')        
      }
      axios.post('/api/urenbonrename', { "id":row.id, "name":row.name})
    },    
    close_modal() {
      this.$bvModal.hide('BSManageUrenbonnenModalID')
      this.$store.dispatch('get_urenbonnen');      
    },
    ispublic(row){
      // console.log( row)
      return !(row.item.name.substring(0,7)=="intern_")
    },
    docto(id) {
      return this.server_url + '/media_' + id.item.file;
    },
    deleteurenbon(id) {
      this.selectedID = id.item.id
      let message = this.$i18n.t('labels.confirmdeletehead') + id.item.name + " " + this.$i18n.t('labels.confirmdeletetail')
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
        message: message,
      }, {
        name: 'popup_modal',
        draggable: false,
        height: 'auto',
        width: '90%'
      }, {
        'before-close': this.modalCloseDeleteUrenBon
      })

      this.$emit('close')
    },    
  },
}    
</script>
