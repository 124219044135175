<template>
  <b-container v-if="createAny('users') && (currentUserIsSuperAdmin() || specialRights('URapppages'))" fluid>

    <!-- TItle bar -->
    <div class="title-bar">
      <h1>{{$t('buttons.appsettings')}}</h1>
    </div>
    <b-row>
      <b-col>{{ $t('labels.colorprimary') }}</b-col>
      <b-col><input type="color" v-model="settings.primarycolor"></b-col>
      <b-col>{{ $t('labels.colorsecondary') }}</b-col>
      <b-col><input type="color" v-model="settings.secondarycolor"></b-col>
    </b-row>

    <h2 class="mt-5">{{$t('titles.projects')}}</h2>
    <div id="app">
      <ckeditor :editor="editor" v-model="settings.projectspage" :config="editorConfig"></ckeditor>
    </div>    
    <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="save" /> {{ $t("buttons.savepages") }}
    </div>    

    <h2 class="mt-5">{{$t('labels.project')}}</h2>
    <div id="app">
      <ckeditor :editor="editor" v-model="settings.projectpage" :config="editorConfig"></ckeditor>
    </div>   
    <h4>{{ $t('labels.projectbutton1') }}</h4>
    <b-row>
      <b-col>{{ $t('labels.title') }}</b-col>
      <b-col><b-form-input type="text" v-model="settings.projectpagebutton1text"/></b-col>
      <b-col>{{ $t('labels.link') }}</b-col>
      <b-col><b-form-input type="text" v-model="settings.projectpagebutton1link"/></b-col>
    </b-row> 
     <h4>{{ $t('labels.projectbutton2') }}</h4>
    <b-row>
      <b-col>{{ $t('labels.title') }}</b-col>
      <b-col><b-form-input type="text" v-model="settings.projectpagebutton2text"/></b-col>
      <b-col>{{ $t('labels.link') }}</b-col>
      <b-col><b-form-input type="text" v-model="settings.projectpagebutton2link"/></b-col>
    </b-row>     
    <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="save" /> {{ $t("buttons.savepages") }}
    </div>        

    <h2 class="mt-5">{{$t('titles.register')}}</h2>
    <div id="app">
      <ckeditor :editor="editor" v-model="settings.registerpage" :config="editorConfig"></ckeditor>
    </div>    
    <h2 class="mt-5">{{$t('titles.registerthankyou')}}</h2>
    <div id="app">
      <ckeditor :editor="editor" v-model="settings.registerthankyou" :config="editorConfig"></ckeditor>
    </div>       
    <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="save" /> {{ $t("buttons.savepages") }}
    </div>    

    <h2 class="mt-5">{{$t('titles.newproject')}}</h2>
    <div id="app">
      <ckeditor :editor="editor" v-model="settings.newprojectpage" :config="editorConfig"></ckeditor>
    </div>   
    <h2 class="mt-5">{{$t('titles.newprojectthankyou')}}</h2>
    <div id="app">
      <ckeditor :editor="editor" v-model="settings.newprojectthankyou" :config="editorConfig"></ckeditor>
    </div>        
    <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="save" /> {{ $t("buttons.savepages") }}
    </div>  

    <h2 class="mt-5">{{$t('titles.aboutapp')}}</h2>
    <div id="app">
      <ckeditor :editor="editor" v-model="settings.aboutapppage" :config="editorConfig"></ckeditor>
    </div>    
    <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="save" /> {{ $t("buttons.savepages") }}
    </div>     

    <h2 class="mt-5">{{$t('titles.aboutzzpr')}}</h2>
    <b-row>
      <b-col>
        {{ $t('labels.title') }}
      </b-col>
      <b-col>
        <b-form-input type="text" v-model="settings.abouttitle"/>
      </b-col>
    </b-row>
    <div id="app">
      <ckeditor :editor="editor" v-model="settings.aboutzzprpage" :config="editorConfig"></ckeditor>
    </div>    
    <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="save" /> {{ $t("buttons.savepages") }}
    </div>     
    
    <h2 class="mt-5">{{$t('titles.privacy')}}</h2>
    <b-row>
      <b-col>
        {{ $t('labels.title') }}
      </b-col>
      <b-col>
        <b-form-input type="text" v-model="settings.privacytitle"/>
      </b-col>
    </b-row>
    <div id="app">
      <ckeditor :editor="editor" v-model="settings.privacypage" :config="editorConfig"></ckeditor>
    </div>  
      
    <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="save" /> {{ $t("buttons.savepages") }}
    </div>         

    <h2 class="mt-5">{{$t('titles.termsandconditions')}}</h2>
    <b-row>
      <b-col>
        {{ $t('labels.title') }}
      </b-col>
      <b-col>
        <b-form-input type="text" v-model="settings.termstitle"/>
      </b-col>
    </b-row>
    <div id="app">
      <ckeditor :editor="editor" v-model="settings.termspage" :config="editorConfig"></ckeditor>
    </div>  
      
    <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="save" /> {{ $t("buttons.savepages") }}
    </div>      

    <h2 class="mt-5">{{$t('titles.login')}}</h2>
    <p> {{$t('validation.loginterms') }}</p>
    <b-row>
      <b-col size = "3">
        {{ $t('labels.title') }}
      </b-col>
      <b-col>
        <b-form-input type="text" class="form-control" v-model="settings.loginterms" />
      </b-col>
    </b-row>
    <b-row>
      <b-col size = "3">
        {{ $t('labels.loginnote') }}
      </b-col>
      <b-col>
        <b-form-input type="text" class="form-control" v-model="settings.loginnote" />
      </b-col>
    </b-row>
      
    <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="save" /> {{ $t("buttons.savepages") }}
    </div>       
  </b-container>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import axios from 'axios'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: 'app-pages-screen',
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items:[ 'heading', '|', 'undo', 'redo', '-', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ,'insertTable'],
          shouldNotGroupWhenFull: true
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
          ]
        },
        table: {
            contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        }
      },
      subject: ",mn,ndn nfklajljflaj kls",
      selected: [],
      file1:null

    }
  },

  computed: {

    ...mapState([
      'settings',
    ]),
    ...mapGetters([
      'server_url',
      'createAny'
    ]),
  },
  methods: {
    ...mapActions([
    ]),

    save_settings() {
      try {
        axios.patch('/api/settings/',
          {
            registerpage: this.settings.registerpage,
            registerthankyou: this.settings.registerthankyou,
            newprojectpage: this.settings.newprojectpage,
            newprojectthankyou: this.settings.newprojectthankyou,
            aboutapppage:this.settings.aboutapppage,
            aboutzzprpage:this.settings.aboutzzprpage,
            abouttitle:this.settings.abouttitle,
            privacypage:this.settings.privacypage,
            privacytitle:this.settings.privacytitle,
            termspage:this.settings.termspage,
            termstitle:this.settings.termstitle,
            loginterms:this.settings.loginterms,
            loginnote:this.settings.loginnote,
            projectspage:this.settings.projectspage,
            projectpagebutton1text:this.settings.projectpagebutton1text,
            projectpagebutton1link:this.settings.projectpagebutton1link,
            projectpagebutton2text:this.settings.projectpagebutton2text,
            projectpagebutton2link:this.settings.projectpagebutton2link,
            projectpage:this.settings.projectpage,
            primarycolor:this.settings.primarycolor,
            secondarycolor:this.settings.secondarycolor
          },
        )
          .then((response) => {
            console.log(response)
          })
      } catch (err) {
        console.log(err)
      }
    },
  },

}
</script>