<template>
  <b-container class="pmsprivate"  v-if="this.$route.params.id ">
    <template>
    <!-- Title bar -->
    <div class="row pmusers" ref="personname">
      <!-- User photo -->
      <div class="col-1" >
        <b-img class="user-photo-holder" :src="profileto(this.$route.params.id)" fluid></b-img>
      </div>
      <div class="col-11">
            {{  username_by_id(this.$route.params.id) }} 
      </div>      
    </div>
    <div class="message-container" >
      <div v-for="(pm,idx) in userspms" :key="idx" class="row messages" >
        <div :class="leftright(pm)">
          <b-img class="user-photo-holder" :src="profileto(pm.from_id)" fluid></b-img>
          <div v-html="parsemessage(pm.message)" />
          <span class="time">{{ timeSent(pm.sent) }}</span>
          <span :style="isRead(pm)"><font-awesome-icon icon="check-double" /></span>
          <button class="btn btn-secondary" v-if="pm.isHourPage" @click="goLink(pm)"><font-awesome-icon icon="link" /></button>
        </div>
      </div>
      <span ref="Logo"/>
    </div>
    <div class="row sendmessage">
      <div class="col-8">
        <b-form-textarea rows="2" v-model="newmessage"/>
      </div>
      <div class="col-2">
        <div  :disabled="newmessage==''" class="btn btn-secondary" @click="send_message()">Send <font-awesome-icon icon="arrow-circle-right" /></div>
        <div class="btn btn-secondary" @click="refresh()"><font-awesome-icon icon="sync" /></div>
      </div>


    </div>
    </template>
  </b-container>
  <b-container v-else class="pmsoverview">
    <font-awesome-icon  v-if="settings.NewChat" class="newchat"  size="2x" icon="edit" @click="showNewChat()" />

    <template>
      <div class="userscrolller">
        <div v-for="(user_id, index) in getusers" :key="user_id" class="row pmusers" @click="onUserClicked(index)">
          <div class="col-1">
              <b-img class="user-photo-holder" :src="profileto(user_id)" fluid></b-img>
          </div>        
          <div class="col-10" >
              {{ username_by_id(user_id) }}
              <div class="pmcounter" :style="AppCounter()">{{pms.filter(pm=>pm.from_id==getusers[index]&&pm.isread==0).length}}</div>
            </div>   
            <div class="col-1 " >
              <font-awesome-icon class="arrow"  icon="chevron-right" />
            </div>               
          </div>
        </div>
    </template>
    <BSNewChat />
  </b-container>
</template>
<script>
import axios from 'axios';
import { mapGetters, mapState, mapActions } from 'vuex'
import BSNewChat from './modals/BSNewChat.vue'
export default {
  name: 'pms',
  data() {
    return {
      newmessage:"",
      timer: ''


    }
  },
  created () {
        this.fetchPMS();
        this.timer = setInterval(this.fetchPMS, 30000);
    },  
  mounted() {

  },  
  computed: {
    ...mapGetters([
      'createAny',
      'project_by_id',
      'username_by_id',
      'user_photo_by_id'
    ]),
    ...mapState([
      'pms',
      'current_user',
      'settings'
    ]),
    userspms(){
      let barepms =  this.pms.filter(pm=>pm.to_id==this.$route.params.id || pm.from_id==this.$route.params.id)
      barepms.forEach(element => {
        let baremessage = JSON.parse(element.message)
        if(baremessage.contract_id){
          element.isHourPage = true
        }
        if(baremessage.project_id){
          element.isHourPage = true
        }        
        if(element.isread==0 && element.to_id==this.current_user.id){
         axios.post('/api/pms_read', {id:element.id,read:1})

        }
      });
      return barepms
    },
    getusers(){
      let from = [...new Set(this.pms.map(item => item.from_id))];
      let to = [...new Set(this.pms.map(item => item.to_id))]
      let result =  [...new Set(from.concat(to))].filter(p=>p!=this.current_user.id)

      return  result.filter(u =>this.user_by_id(u) != null)//.filter(p=>p.form_id!=this.current_user.id).filter(p=>p!= this.current_user.id)
    }

  },
  methods: {
    ...mapActions([
      'add_pm'
    ]),
    fetchPMS(){
      this.$store.dispatch('get_pms')
    },
    cancelAutoUpdate () {
      clearInterval(this.timer);
    },
    onUserClicked(index){
      let messagesfromuser = this.pms.filter(pm => pm.to_id==this.getusers[index] || pm.from_id == this.getusers[index])
      if(messagesfromuser[0].to_id==this.current_user.id){
        this.$router.push({ path: '/pms/' + messagesfromuser[0].from_id });
      } else {
        this.$router.push({ path: '/pms/' + messagesfromuser[0].to_id });
      }
    },
    parsemessage(msg){
      console.log(msg)
      let decode = JSON.parse(msg)
      if(decode){
        let baremessage = decode.message.replaceAll("\n", "<br>");
        if(decode.subject){
          baremessage = "<strong>"+decode.subject+"</strong><br>"+baremessage;
        }
        return  baremessage
      } else {
        return msg.replaceAll("\n", "<br>")
      }

      
    },
    timeSent(time){
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const d = new Date(time)
      return d.toLocaleDateString("nl-NL", options)+" "+d.toLocaleTimeString()
    },
    send_message(){
      this.add_pm( { id: 0, from: this.current_user.id, to: parseInt(this.$route.params.id), message:this.newmessage })
      this.newmessage=""
    },
    refresh(){
      this.$store.dispatch('get_pms')

    },
    isRead(pm){
      if(pm.isread==1){
        return {"color":"#4477e3"}
      } else{
        return {"color":"#cccccc"}
      }
    },
    leftright(pm){
      if(pm.from_id==this.current_user.id){
        return "bubble left"
      } else{
        return "bubble right"
      }
    },
    goLink(pm){
      let decode = JSON.parse(pm.message)
      if(pm.isHourPage){
        if(decode.contract_id){
          this.$router.push({ path: '/userhours/' + decode.contract_id +"/"+ decode.week +"/"+ decode.year });
        }
        if(decode.project_id){
          this.$router.push({ path: '/public_project/' + decode.project_id});
        }        

      }
    },
    showNewChat(){
      this.$bvModal.show('BSNewChatID')
    }
  },
  destroyed() {
    this.cancelAutoUpdate();
  },
  components: {BSNewChat},
  watch:{
    pms(){
		if(this.$refs["Logo"]){
				this.$refs["Logo"].scrollIntoView({ behavior: "smooth" })
			}
    }
  }
}
</script>    

