<template>

  <b-container v-if="(currentUserIsSuperAdmin() || specialRights('URnewsletter'))" fluid :style="AppSecondaryColor()" class="apppage">

    <!-- Title bar -->
    <div class="title-bar">
      <h1>{{ $t("titles.newsletter") }}</h1>
      <div @click="edit_newsletter({ id: 0 })" class="btn btn-primary">
        <font-awesome-icon icon="plus" /> {{ $t("buttons.addnewsletter") }}
      </div>
    </div>
    <select name="licenses" class="form-control admins" v-model="current_newsletter">
      <option v-for="option in newsletters" :value="option.id" :key="option.id">
        {{ option.name }}
      </option>
    </select>
    <b-tabs v-if="newsletters.length>0">
      <b-tab>
        <template v-slot:title>{{ $t("labels.editnewsletter") }}</template>
        <textarea rows="25" style="width:100%" v-model="editdata"></textarea>
        <div @click="save_newsletter({ id: 0 })" class="btn btn-primary">
        <font-awesome-icon icon="plus" /> {{ $t("buttons.savepages") }}
      </div>
      </b-tab>
      <b-tab style="min-height:800px">
        <template v-slot:title>{{ $t("labels.preview") }}</template>
        <div style="min-height:800px">
          <iframe 
            height="800px"
            width="100%"
            frameborder="0"
            :src="current_newsletter_file"
          /></div>
      </b-tab>
    </b-tabs>

  </b-container>
</template>
<script>
import {  mapGetters, mapState  } from 'vuex'
import addnewsletter from './modals/AddNewsletter.vue'
import axios from 'axios'

export default {
  name: 'newsletter',
  data() {
    return {
      current_newsletter:0,
      editdata:""
    }
    
  },
  mounted() {
    this.current_newsletter = this.newsletters[0].id

  },
  computed: {
    ...mapGetters([
      'is_logged_in',
      'newsletter_by_id'
    ]),   
    ...mapState([
      'settings',
      'newsletters'
    ]),
    content(){
      return this.header+ "<tr><td style='padding:5%'>" +this.editorData  + "</td></tr><tr>" +this.footer
    },
    current_newsletter_file(){
      return "/api/media_"+this.newsletter_by_id(this.current_newsletter).file
    },
    header(){
      return this.settings.generalheader.replace(/\n|\r/g, "").replace(/\[headerimage\]/gi,  "/api/media_main_documents/ALG_Main_header.png")
    },
    footer(){
     var newf = this.settings.generalfooter.replace(/\n|\r/g, "").replace(/"/g, '')
      newf = newf.replace(/\[logo_image\]/gi, "/api/media_main_documents/ALG_Main_logo.gif")
    newf = newf.replace(/\[twitter_image\]/gi, "/api/media_main_documents/ALG_Main_twitter.gif")
    return newf.replace(/\[facebook_image\]/gi, "/api/media_main_documents/ALG_Main_facebook.gif")
    
    }
  },
  methods: {
    edit_newsletter(tag) {
      this.$modal.hide('addnewsletter')
      this.$modal.show(addnewsletter, {
        tag_id: tag.id,
      }, {
        name: 'addnewsletter',
        draggable: '.popup-header',
        height: 'auto',
        width: '800px'
      })

      this.$emit('close')
    },
    save_newsletter(){
      axios.post('/api/changenewsletter',{id:this.current_newsletter, content:this.editdata,file:this.newsletter_by_id(this.current_newsletter).file})
    }

  },
  watch:{
    current_newsletter(){
      axios.get(this.current_newsletter_file).then(response => {
        this.editdata = response.data
      })
 
    }
  }
}
</script>
