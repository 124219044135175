<template>
  <b-container v-if="createAny('users') && currentUserIsSuperAdmin()" fluid>
    <h1>{{$t('titles.smssettings')}}</h1>
    <p>{{ $t('templates.smsinfo') }}</p>
    <b-form-group :label="$t('labels.defaultsmsuser')">
      <b-form-input type="text" class="form-control" v-model="settings.smsuser" />
    </b-form-group>
    <b-form-group :label="$t('labels.defaultsmspassword')">
      <b-form-input type="password" class="form-control" v-model="settings.smspassword" />
    </b-form-group>        

    <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="plus" /> {{ $t("buttons.savesettings") }}
    </div>


  </b-container>
</template>
<script>

import axios from 'axios'
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'emailsenders',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'createAny',
    ]),
    ...mapState([
      'current_user',
    ]),
    ip(){
      return location.host
    }
  },
  methods: {
    ...mapActions([
    ]),
    save_settings() {
      try {
        axios.patch('/api/settings/',
          {
            smsuser: this.settings.smsuser,
            smspassword: this.settings.smspassword,
          },
        )
          .then((response) => {
            console.log(response)
          })
      } catch (err) {
        console.log(err)
      }
    },    

  },
  components: {},
}
</script>