<template>
  <div>

    <h2>{{$t("labels.blockingcompanies")}}</h2>
    <b-table :items=this.user.blockcompanies :fields=fields>
<input type="hidden" v-model="componentKey">
      <template #cell(actions)="row">
        <a href="javascript:;" class="action-item" @click="delete_company(row.item.id)">
          <font-awesome-icon icon="trash" />
        </a>
      </template>
      <template #cell(note)="row">
        <b-row><b-col>
          <b-input-group>
                    <b-form-input type="text" class="form-control" v-model="row.item.note" :ref="row.item.id"  @change="changeNote(row.item)"/>
                    <b-input-group-append v-if="createAny('users')">
                        <b-button squared class="multi-append"  href="javascript:;"
                            @click="append_data(row.item.id)">
                            <font-awesome-icon icon="plus-circle" />
                        </b-button>
                    </b-input-group-append>
                </b-input-group>

        </b-col><b-col cols="1"><a href="javascript:;" class="action-item" @click="changeNote(row.item)">
          <font-awesome-icon icon="save" />
        </a></b-col></b-row>
      </template>
    </b-table>
    <div class="row">
      <div class="col">
        <b-form-group :label="$t('labels.companyname')">
          <v-select :options="other_companies" class="contactpersoon" v-model="company_id"></v-select>
        </b-form-group>
      </div>

    </div>
    <!-- <div class="form-row">
      <div class="col-3">
        <div v-if="company_id!=null" @click="save_company" class="btn btn-primary">{{ $t("buttons.addcompany") }}</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions, mapState } from 'vuex'


const Dutch = require("flatpickr/dist/l10n/nl.js").default.nl;

export default {
  name: 'userblocking_screen',
  data() {
    return {
      componentKey: 0,
      available_hours_remarks: "available_hours_remarks",
      config: {
        weekNumbers: true,
        locale: Dutch,
        altInput: true,
        altFormat: "d-m-Y",
        dateFormat: "d-m-Y",
      },
      company_id: null
    }
  },
  props: {
    user_id: Number
  },
  computed: {
    user() {
      return this.user_id > 0 ? this.user_by_id(this.user_id) : this.user_new
    },
    fields() {
      return [
        { key: 'company_id', label: this.$i18n.t('labels.name'), formatter: "companyname_by_id", sortable: true, sortDirection: 'desc' },
        { key: 'note'},
        { key: 'actions', label: this.$i18n.t('labels.actions') },
      ]
    },
    ...mapGetters([
      'user_settings_metas',
      'user_by_id',
      'activebranches',
      'user_settings_metasCB',
      'createAny',
      'append_data_string',
      'companyname_by_id'

    ]),
    ...mapState([
      'users',
      'settings',
      'current_user',
      'branches',
      'companies'
    ]),
    other_companies() {
      let other = []
      for (const acomp of this.companies) {
        let addcomp = true;
        if (this.user.blockcompanies && this.user.blockcompanies.length > 0) {
          for (const blockcomp of this.user.blockcompanies) {
            if (acomp.id == blockcomp.company_id) {
              addcomp = false;
            }
          }
        }
        if (addcomp) {
          let newcomp = {}
          newcomp.id = acomp.id
          newcomp.label = this.companyname_by_id(acomp.id)
          other.push(newcomp)
        }
      }
      return other
    },
  },
  methods: {
    append_data(key) {
      // console.log("append",key,this.user.blockcompanies.filter(b=>b.id===key)[0])
      this.user.blockcompanies.filter(b=>b.id===key)[0].note = this.append_data_string
      // this.user[key] = this.append_data_string
      this.componentKey += 1;
      if (this.$refs[key]) {
        if (this.$refs[key][0]) {
          this.$refs[key][0].focus()
        } else {
          this.$refs[key].focus()
        }
      }
    },
    changeNote(row){
      axios.put('/api/blocking_note',{id:row.id, note:row.note})
    },
    append_data_multi(key) {
      var current = ""
      if (this.user[key]) {
        current = "\n" + this.user[key]
      }
      this.user[key] = this.append_data_string + current
      this.componentKey += 1;
      if (this.$refs[key]) {
        if (this.$refs[key][0]) {
          this.$refs[key][0].focus()
          this.$nextTick(() => {
            this.$refs[key][0].selectionEnd = 9
          })
        } else {
          this.$refs[key].focus()
          this.$nextTick(() => {
            this.$refs[key].selectionEnd = 9
          })
        }
      }
    },
    ...mapActions([
      'delete_blocking_company',
      'add_user'
    ]),
    delete_company(id) {
      for (let i = 0; i < this.user.blockcompanies.length; i++) {
        if (this.user.blockcompanies[i].id == id) {
          this.user.blockcompanies.splice(i, 1);
        }
      }
      this.delete_blocking_company(id);
    },
    save_company() {
      // var datatosend = { company_id: this.company_id.id, user_id: this.user_id, id: 0 }
      // this.user.blockcompanies.push(datatosend)
      // this.$store.dispatch('save_blocking_company', datatosend);
      axios.put("/api/usercompany/" + this.user_id, { company_id: this.company_id.id, id:0 }).then((res)=>{
          var datatosend = { company_id: res.data.company_id, id: res.data.id, note:'' }
          this.user.blockcompanies.push(datatosend)
        })

    },

  },
  mounted() {
  },
  components: {

  },
  watch:{
    company_id(){
      if(this.company_id!=null){
        this.save_company()
      }
      this.company_id=null
    }
  }
}
</script>