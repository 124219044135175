<template>
  <div>
      <div class="row planning_row planning_header"> 
        <div class="col-2"><h3>{{ $t("titles.users") }}</h3></div>
        <div class="col-1"><b-button v-if="createAny('project')" @click="editUserModal({ id: 0 })" class="btn btn-primary"><font-awesome-icon icon="plus" /> </b-button></div>
        <div class="col-1">
          <b-form-checkbox v-if="settings.EnableEmployees" v-model="showemployee">{{$t('meta.isemployee')}}</b-form-checkbox>
          <b-form-checkbox  v-model="showrealplanning">{{$t('labels.planning')}}</b-form-checkbox>
        </div>
        
        <div class="col-2">
          <v-select :options="workfields" v-model="wff"  :placeholder="$t('labels.choose')" label="name"/>
        </div>
          <div class="col-2">
              <b-form-input ref="searchbox" id="filter-input" v-model="filter" type="search" :placeholder="$t('strings.typetosearch')"></b-form-input>
        </div>
        <div class="col"><button v-if="workfieldfilter!=null" class="btn btn-primary" @click="clearFilter()"><font-awesome-icon icon="trash" /> {{ workfield_by_id(workfieldfilter.workfield_id).name }}</button></div>
      </div>
      <div class="people_section">
        <div class = "planning_row" v-for="(zzp, idx)  in filteredzzp" :key="'people'+idx">
          <div class="planning_title" draggable @dragstart="startDrag($event, zzp, 'user')"
              @drop="onDropUser($event, zzp.id, 0)"
              @dragover.prevent
              @dragenter.prevent
          >{{ zzp.fullname}} <div class="planning-item" style="float:right" @click="editUserModal(zzp.id)"> <font-awesome-icon icon="cog" /> </div> <font-awesome-icon v-for="r in  resources.filter(r=>r.user_id==zzp.id)" :key="r.id" style="float:right;margin:5px;" :icon="resource_icon(r.type)" :title="r.name+' '+r.identification"></font-awesome-icon> </div>
          <div v-for="n in weeks " :key="n" :value="n" :class="divclass" 
          @drop="onDropUser($event, zzp.id, n)"
          @dragover.prevent
          @dragenter.prevent
          :style="realplanning(n,zzp.id)"> 
          <div v-for="(task, idx) in waxes(n,zzp)" :key="waxes+idx" draggable 
          @dragstart="dragUser($event,n, task, 'task')" 
          @click="onClickTaskUser(task)"
          :style="taskStyle(n,task, waxes(n,zzp).length, idx)"
          :title="taskTooltip(n,task)">
            {{ label(task) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {  mapGetters, mapState} from 'vuex'

export default {
  name: 'people-planning',
  data() {
    return {
      filter:"",
      workfieldfilter:null,
      showrealplanning:false,
      showemployee:false,
      wff:null
    }
  },
  props: {
    numweeks: Number,
    startdate:String
  },  
  computed:{
    ...mapGetters ([
      'zzpers',
      'project_by_id',
       'createAny',
       'workfield_by_id'

  ]),
  ...mapState ([
      'ffwax',
      'resources',
      'planning',
      'workfields'

    ]),    

    weeks(){
      return this.numweeks*7;
    },
    divclass(){
      console.log("numweeks ",this.numweeks)
      return "planning_day planning_day"+this.numweeks
    }, 
    filteredzzp(){
      if(this.filter!=""){
        return this.workfieldFilter(this.zzpers).filter(row=>
          (row.email && row.email.toLowerCase().includes(this.filter)) ||
          (row.fullname && row.fullname.toLowerCase().includes(this.filter)) ||
          (row.firstname && row.firstname.toLowerCase().includes(this.filter)) ||
          (row.firstname && row.lastname && (row.firstname.toLowerCase()+ " "+ row.lastname.toLowerCase()).includes(this.filter)) ||
          (row.lastname && row.lastname.toLowerCase().includes(this.filter)) ||
          (row.mobile && row.mobile.includes(this.filter))||
          (row.companyname && row.companyname.toLowerCase().includes(this.filter)) 
        )
      } else {
        return this.workfieldFilter(this.zzpers)
      }
    },

  },
  methods:{
    waxes(n,user){
      let day = new Date(this.startdate)
      day.setDate(day.getDate()+n-1)
      return this.ffwax.filter(f => f.user_id ==user.id).filter(ff => {
        var res = false
        var startdate
        if(ff.project_id>9990000){
          let year = ff.project_id - 9990000
          startdate = new Date(Date.UTC(year, 0, 1))
        } else {
          startdate = new Date(this.project_by_id(ff.project_id).startdate)
        }
        startdate.setHours(0,0,0,0)
        startdate.setDate(startdate.getDate() + ff.start_offset);
        let enddate = new Date(startdate)
        enddate.setDate(enddate.getDate() + ff.end_offset);
          if(day.setHours(0,0,0,0)>=startdate &&  day.setHours(0,0,0,0)<=enddate){
            res =  true
          }
          return res
        })

    },
    resource_icon(i){
      var type = "cog"
      switch(parseInt(i)){
        case 1 : type = "truck";
        break
        case 3 : type = "hammer";
        break;
        case 2 : type = "box";
        break;
      }
      return type
    },
    taskStyle( n, task, numtasks, idx){
      let startpers = task.start_time?parseFloat(parseInt(task.start_time.split(":")[0])/24)*100:10
      let  endpers = task.end_time?parseFloat((parseInt(task.end_time.split(":")[0])-parseInt(task.start_time.split(":")[0]))/24)*100:80
          // tooltip =  this.project_by_id(ff.project_id).title + "\n"+this.dateToNL(startdate) + " - "+ this.dateToNL(enddate) +"\n"+ff.start_time + " - "+ ff.end_time +"\n"
      
      var color    
      if(task.project_id>9990000){
        color='#000'
      } else {
        color = this.project_by_id(task.project_id).planningcolor
      }
      let height = 100/numtasks;
      return "position:absolute; user-select: none;cursor:pointer; background-color:"+color+";left:"+startpers+"%; width:"+endpers+"%; height:"+height+"%; top: "+idx*height+"%;"

    },
    realplanning(n,user_id){
      if(this.showrealplanning){
        let hasplanning = false
        let d = new Date(this.startdate)
        d.setDate(d.getDate()+(n-1))
        this.planning.filter(c =>c.user_id == user_id).forEach(c =>{
          if(d>=new Date(c.startdate) && d<=new Date(c.enddate)){
          hasplanning = true
          }
        })
        if(hasplanning){
          return "background-color: #aaaaaa"
        }
       }
          return ""
      
    },
    taskTooltip( n, task){
      if(task.project_id>9990000){
        return "vrij"
      } else {
        let startdate = new Date(this.project_by_id(task.project_id).startdate)
        startdate.setHours(0,0,0,0)
        startdate.setDate(startdate.getDate() + task.start_offset);
        let enddate = new Date(startdate)
        enddate.setDate(enddate.getDate() + task.end_offset);
        return  this.project_by_id(task.project_id).title + "\n"+this.dateToNL(startdate) + " - "+ this.dateToNL(enddate) +"\n"+task.start_time + " - "+ task.end_time +"\n"
      }
    },

    filterPeople(filter){
      if(filter.workfield_id==null){
        this.workfieldfilter = null
      } else{
        this.workfieldfilter = filter

      }
    },
    clearFilter(){
      this.workfieldfilter = null
    },
    workfieldFilter(items){
      if(this.workfieldfilter!=null){
        return this.employeefilter(items.filter(i => i.workfields.indexOf(this.workfieldfilter.workfield_id)!=-1))
      }
      return this.employeefilter(items)
    },
    employeefilter(items){
      if(this.showemployee){
        return items.filter(i => i.isemployee==1)
      }
      return items
    },    
    projectTitle(n ,user){
    var ret = ""
    var color ="";
    var tooltip=""
    var startpers
    var endpers
    let day = new Date(this.startdate)
      day.setDate(day.getDate()+n-1)
      this.ffwax.filter(f => f.user_id ==user.id).forEach(ff => {
        var startdate 
        var enddate
        if(ff.project_id>9990000){
          let year = ff.project_id - 9990000
          startdate = new Date(Date.UTC(year, 0, 1))
        } else {
          startdate = new Date(this.project_by_id(ff.project_id).startdate)
        }
        startdate.setHours(0,0,0,0)
        startdate.setDate(startdate.getDate() + ff.start_offset);
        enddate = new Date(startdate)
        enddate.setDate(enddate.getDate() + ff.end_offset);

        if(day.setHours(0,0,0,0)>=startdate &&  day.setHours(0,0,0,0)<=enddate){
          // let pt =  this.project_by_id(ff.project_id).title.substring(0,2)
          startpers = parseFloat(parseInt(ff.start_time.split(":")[0])/24)*100
          endpers = parseFloat((parseInt(ff.end_time.split(":")[0])-parseInt(ff.start_time.split(":")[0]))/24)*100
          if(ff.project_id>9990000){
            tooltip = "vrij"
            color ='#000'
          } else {
            tooltip =  this.project_by_id(ff.project_id).title + "\n"+this.dateToNL(startdate) + " - "+ this.dateToNL(enddate) +"\n"+ff.start_time + " - "+ ff.end_time +"\n"
            color = this.project_by_id(ff.project_id).planningcolor
          }
          ret +="<div draggable=\"true\" @dragstart=\"startDrag($event, zzp, 'task')\" style='position:absolute; user-select: none;cursor:pointer; background-color:"+color+";margin-left:"+startpers+"%; width:"+endpers+"%' title='"+ tooltip+ "'>"+ this.label(ff)+"</div>"
        }
      })
      return ret

    },
    label(task){
      if(task.project_id>9990000){
        return "vrij"
      }
      return this.project_by_id(task.project_id).title.substring(0,2)
    }    
  },
  watch:{
    wff(){
      if(this.wff==null){
        this.workfieldfilter =null  
      } else {
        this.workfieldfilter = {workfield_id:this.wff.id }
      }
    }
  }

  
}
</script>