
<template>
  <b-container>
    <b-table :items=this.user.tags :fields=fields>
      <template #cell(actions)="row">
        <a v-if="row.item.id!=0" href="javascript:;" class="action-item" @click="delete_tag(row.item.id)">
          <font-awesome-icon icon="trash" />
        </a>
      </template>
    </b-table>
    <b-row>
      <b-col>
        <h2>{{ $t("buttons.addtag") }}</h2>
      </b-col>
    </b-row>
    <div class="row">
      <div class="col">
        <b-form-group :label="$t('labels.tag')">
          <v-select label="tag" :options="other_tags" class="contactpersoon" v-model="tag_id"></v-select>
        </b-form-group>
      </div>

    </div>
    <!-- <div class="form-row">
      <div class="col-3">
        <div v-if="tag_id!=null" @click="save_tag" class="btn btn-primary">{{ $t("buttons.addtag") }}</div>
      </div>
    </div> -->
  </b-container>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'usertagssscreen',
  data() {
    return {
      tag_id: null,
      selectedtag: null
    }
  }, 
  props: {
    user_id: Number
  },
  computed: {
    fields() {
      return [
        { key: 'tag_id', label: this.$i18n.t('labels.name'), formatter: "tagname", sortable: true, sortDirection: 'desc' },
        { key: 'actions', label: this.$i18n.t('labels.actions') },
      ]
    },
    user() {
      return this.user_id > 0 ? this.user_by_id(this.user_id) : this.user_new
    },
    other_tags() {
      let other = []
      for (let i = 0; i < this.tags.length; i++) {
        let addtag = true;
        for (var f = 0; f < this.user.tags.length; f++) {
          if (this.user.tags[f].tag_id == this.tags[i].id) {
            addtag = false;
            break;
          }
        }
        if (addtag) {
          other.push(this.tags[i]);
        }
      }
      return other
    },
    ...mapGetters([
      'user_metas',
      'user_by_id',
      'activebranches',
      'user_new',
      "tag_by_id"
    ]),
    ...mapState([
      'users',
      'branches',
      'avatars',
      'tags'
    ]),

  },
  methods: {
    tagname(id) {
      return this.tag_by_id(id).tag

    },
    delete_tag(id) {
      for (let i = 0; i < this.user.tags.length; i++) {
        if (this.user.tags[i].id == id) {
          this.user.tags.splice(i, 1);
        }
      }
      this.delete_user_tag(id);
    },


    async save_tag() {
      axios.put("/api/usertag/" + this.user_id, { 'tag_id': this.tag_id.id }).then((response)=>{ 
        this.user.tags.push({ tag_id: response.data.tag_id, user_id: this.user_id, id: response.data.id })
      })
    },
    ...mapActions([
      'save_user_tag',
      'delete_user_tag'
    ]),



  },
  watch:{
    tag_id(){
      if(this.tag_id!=null){
        this.save_tag()
      }
      this.tag_id=null
    }
  }

}    
</script>
