<template>
  <b-container v-if="createAny('users')" fluid>

    <!-- Title bar -->
    <div class="title-bar">
      <h1>{{ $t("titles.branches") }}</h1>
      <div @click="edit_branche({ id: 0})" class="btn btn-primary">
        <font-awesome-icon icon="plus" /> {{ $t("buttons.addbranche") }}
      </div>
    </div>

    <!-- Filter bar -->
    <b-row class="filter-bar">
      <b-col>
        <b-form-group :label="$t('labels.filter')">
          <b-form-input id="filter-input" v-model="filter" type="search" :placeholder="$t('strings.typetosearch')">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <input type="hidden" v-model="refresh">
    <b-table :items="branches" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter"
      :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection" @filtered="onFiltered" @row-clicked="edit_workfield">
      <template #cell(active)="row">
        <font-awesome-icon v-if="row.item.active" icon="check" style="color: green;"
          :title="$t('labels.editbrancheoff')" />
        <font-awesome-icon v-else icon="times" style="color: red;" :title="$t('labels.editbrancheon')" />
      </template>
      <template #cell(pulldown)="row">
        <b-dropdown id="dropdown-dropup" dropup :text="$t('buttons.doaction')" variant="primary">
          <b-dropdown-item v-if="users.filter(u => u.branche_id==row.item.id).length==0"  @click="confirm_delete_branche(row.item.id)">
            <font-awesome-icon icon="trash" /> {{$t('labels.deletebranche')}}
          </b-dropdown-item>
          <b-dropdown-item @click="edit_branche(row.item)">
            <font-awesome-icon icon="cog" /> {{$t('labels.editbranche')}}
          </b-dropdown-item>
          <b-dropdown-item @click="edit_workfield(row.item)">
            <font-awesome-icon icon="tools" /> {{$t('labels.editbrancheworkfields')}}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <b-pagination v-if="totalRows > perPage" v-model="currentPage" :total-rows="totalRows" :per-page="perPage">
    </b-pagination>
  </b-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import branche_details_modal from './modals/BrancheModal'
import popup_modal from './modals/PopupModal.vue'

export default {
  name: 'branchesscreen',
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      selectedID: null,
      refresh:0


    }
  },
  computed: {
    totalRows() {
      return this.branches.length
    },
    fields() {
      return [
        { key: 'name', label: this.$i18n.t('labels.branchename'), sortable: true, sortDirection: 'desc' },
        { key: 'active', label: this.$i18n.t('labels.active') },
        { key: 'pulldown', label: this.$i18n.t('labels.actions') },
      ]
    },
    ...mapState([
      'branches',
      'workfields',
      'users'
    ]),
    ...mapGetters([
      'branche_by_id',
      'confirm_modal',
      'createAny'

    ]),

  },
  methods: {
    confirm_delete_branche(id) {
      this.selectedID = id
      let message = this.$i18n.t('labels.confirmdeletehead') + this.branche_by_id(id).name + this.$i18n.t('labels.confirmdeletetail')
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
        message: message,
      }, {
        name: 'popup_modal',
        draggable: false,
        height: 'auto',
        width: '500px'
      }, {
        'before-close': this.modalClose
      })

      this.$emit('close')

    },
    modalClose() {
      if (this.confirm_modal.id == 1) {
        this.delete_branche(this.selectedID);
        for (let i = 0; i < this.branches.length; i++) {
          if (this.branches[i].id == this.selectedID) {
            this.branches.splice(i, 1);
          }
        }
      }
    },
    updateview(){
      this.$store.dispatch('get_branches')

      this.refresh++
    },
    ...mapActions([
      'delete_branche',
      'save_branche'
    ]),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    edit_workfield(row) {
      this.$router.push({ path: '/branche/' + row.id });
    },
    onBrancheClicked(row) {
      let brancheclicked = this.branche_by_id(row.id)
      if (brancheclicked.active == 1) {
        brancheclicked.active = 0
      } else {
        brancheclicked.active = 1
      }
      this.save_branche(row.id)
    },
    edit_branche(branche) {
      this.$modal.hide('branche_details_modal')
      this.$modal.show(branche_details_modal, {
        branche_id: branche.id,
      }, {
        name: 'branche_details_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '800px'
      }, {
        'before-close': this.updateview
      })

      this.$emit('close')
    },
  },
  watch:{
    branches : function () {
      this.refresh++
    }
  }
}
</script>
