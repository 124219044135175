<template>
  <b-container fluid>
    <h1>{{statustext}}</h1>
    <div v-if="status=='validated'" class="form-row" >
      <div class="col-3">
          <label for="">{{ $t("labels.password") }}</label>
      </div>
      <div class="col">
          <b-input-group class="mt-3">
              <b-form-input :type="pwtype" v-model="newpw" :state="passwordcheck"  placeholder="" />
              <b-input-group-append>
                  <b-button class="multi-append" href="javascript:;" @click="reveal()"><font-awesome-icon :icon = btnText /></b-button>
              </b-input-group-append>
                  <b-form-invalid-feedback >
                      {{ $t("validation.password")}}
                  </b-form-invalid-feedback>         
          </b-input-group>
      </div>
  </div>  
  <div v-if="status=='validated'" class="form-row" >
      <div class="col-3">
          <label for="">{{ $t("labels.passwordrepeat") }}</label>
      </div>
      <div class="col">
          <b-form-input  :state="passwordequal" type="password" class="form-control" v-model="newpw2"/>
      </div>
  </div>       
  <div v-if="passwordequal"  @click="savenewpass" class="btn btn-primary">{{ $t("labels.confirmpass") }}</div>

  </b-container>

</template>

<script>
// import axios from 'axios'

import axios from 'axios'
import { mapActions } from 'vuex'

export default {
    name: 'password_reset_request',
    data() {
      return {
        token:"",
        status:"",
        statustext:"",
        newpw:null,
        newpw2:null,
        pwtype:"password",
        btnText : 'eye',        
        user_id :0,
        email:"",
        form: {
          username: '',
          password: ''
        },        
      }
    },
    beforeMount() {
      this.token = this.$route.params.token
      this.statustext = this.$i18n.t('labels.check_token')
    },
    mounted() {
      console.log("mounted")
      this.validatetoken()
    } ,
    computed: {
      passwordcheck(){
        return this.newpw!=undefined &&this.newpw.length>5;
      },     
      passwordequal(){
        return this.newpw!=undefined && this.newpw==this.newpw2
      }   
    },    
    methods:{
      ...mapActions([
        'login'
      ]),
      validatetoken(){
        axios.post('/api/user/findtoken', {token:this.token})  .then((response) => {
          console.log(response.data)
            if(response.data.status==1){
                this.status="validated"
                this.statustext=this.$i18n.t('labels.choosepassword')
                this.user_id = response.data.user_id
                this.email = response.data.email
            } else {
                this.statustext=this.$i18n.t('labels.invalid_token')
            }
        })
      },
      reveal(){
            if(this.pwtype === 'password') {
                this.pwtype = 'text'
                this.btnText = 'eye-slash'
            } else {
                this.pwtype = 'password'
                this.btnText = 'eye'
            }
        },          
      savenewpass(){
        axios.post('/api/user/changepassword',{token:this.token,user_id:this.user_id,newpassword:this.newpw})
        .then((response) => {
          console.log(response.data)
            if(response.status!=-1){
                this.$emit('close');
            }
            this.form.username = this.email;
            this.form.password = this.newpw
            this.login(this.form)

        })
      }       
    }   
  }
  </script>