<template>
  <div>
    <input type="hidden" value="is_portal_selected">
    <div v-if="buildtarget=='apprelease'">
      <b-img v-if="buildtarget=='apprelease'" :src="portal_logo" alt="ZZPR" class="logo" style="display:block; margin:auto; max-width:10rem;max-height: 10rem;" />
    </div>
    <div v-else>
      <b-img v-if="frontend=='ffdemo'" src="../../assets/flying-freelancers-logo.svg" alt="Flying Freelancers" id="logo" class="logo"  />
      <b-img v-if="frontend=='ffintern'" src="../../assets/flying-freelancers-logo.svg" alt="Flying Freelancers" id="logo" class="logo"  />
      <b-img v-if="frontend=='zzpr'" src="../../assets/zzpr-logo.svg" alt="ZZPR" id="logo" class="logo"  />
      <b-img v-if="frontend=='fvdl'" src="../../assets/fvdl-logo.svg" alt="Fried van de Laar" id="logo" class="logo"  />
      <b-img v-if="frontend=='vddungen'" src="../../assets/vddungen.svg" alt="Vd Dungen" id="logo" class="logo"  />
      <b-img v-if="frontend=='heywin'" src="../../assets/heywin.svg" alt="Heywin" id="logo" class="logo"  />
      <b-img v-if="frontend=='vanoort'" src="../../assets/vanoort.svg" alt="van Oort" id="logo" class="logo"  />
      <b-img v-if="frontend=='tsb'" src="../../assets/tsb.svg" alt="TSB Timmermans Steigerbouw" id="logo" class="logo"  />
      <b-img v-if="frontend=='snel'" src="../../assets/snel.svg" alt="Snel Projectservice" id="logo" class="logo"  />
      <b-img v-if="frontend=='dakcontrol'" src="../../assets/dakcontrol.svg" alt="Dakcontrol B.V." id="logo" class="logo"  />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'frontenlogo',
  computed: {
    ...mapGetters([    
      'is_logged_in',
      'is_portal_selected',
      'portal_logo'
    ]),
  }
}
</script>