<template>
	<div class="popup">
        <div class="popup-header">
            <div v-if="project.id > 0">
                {{project.title}}
            </div>
            <div v-else>
                 {{ $t("labels.newproject") }}
            </div>
            <div v-if="project.id == 0" class="popup-close" @click="close_modal">
                <font-awesome-icon icon="times" />
            </div>
            <div v-else class="popup-close" @click="save_and_close">
                <font-awesome-icon icon="times" />
            </div>
        </div>

        <div class="popup-body">
          <input type="hidden" :key="componentKey" />

            <b-tabs vertical>
                <b-tab active >
                    <template v-slot:title>{{ $t("labels.settings") }}</template>                    
                    <ProjectDefault v-bind:project_id="project_id" ref="default"  @change="projectcheck"></ProjectDefault>
                </b-tab>
                <b-tab title="Werkgebieden">
                    <template v-slot:title>{{ $t("labels.workfields") }}</template>                    
                    <ProjectWorkfieldSettings  v-bind:project_id="project_id" ></ProjectWorkfieldSettings>
                </b-tab>
                <b-tab v-if="project_id!=0" >
                    <template v-slot:title>{{ $t("labels.ZZPersinproject") }}</template>                    
                    <ProjectZZPers  v-bind:project_id="project_id" ></ProjectZZPers>
                </b-tab>  
                <b-tab v-if="project_id != 0">
                    <template v-slot:title>{{ $t("labels.UserDocuments") }}</template>
                    <ProjectDocuments v-bind:project_id="project_id" ref="document"></ProjectDocuments>
                </b-tab>                 
                <b-tab v-if="settings.EnableScheduler && specialRights('URSchedule') && project_id != 0">
                    <template v-slot:title>Planning People</template>
                    <ProjectPlanningPeople v-bind:project_id="project_id" ></ProjectPlanningPeople>
                </b-tab>  
                <b-tab v-if="settings.EnableScheduler && specialRights('URSchedule') && project_id != 0">
                    <template v-slot:title>Planning Resources</template>
                    <ProjectPlanningResources v-bind:project_id="project_id" ></ProjectPlanningResources>
                </b-tab>                                              
            </b-tabs>
        </div>

        <div class="popup-footer">
            <div v-if="project.id == 0" class="btn btn-primary" @click="my_add_project();">{{ addprojectText() }}</div>
            <div v-else @click="save_and_close" class="btn btn-primary">{{ addprojectText()}}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import ProjectWorkfieldSettings from './settings-screens/ProjectWorkfield'
import ProjectDefault from './settings-screens/ProjectDefault'
import ProjectZZPers from './settings-screens/ProjectZZPers'
import ProjectDocuments from './settings-screens/ProjectDocuments.vue'
import ProjectPlanningPeople from './settings-screens/ProjectPlanningPeople.vue'
import ProjectPlanningResources from './settings-screens/ProjectPlanningResources.vue'

export default {
    name: 'project-modal',
    data() {
        return {
            componentKey:0
        }        
    },
    props: {
        project_id: Number
    },
    computed: {
        project() {
            return this.project_id > 0 ? this.project_by_id(this.project_id) : this.project_new
        },
        ...mapGetters([
            'project_metas',
            'project_by_id',
            'project_new'
        ]),
        ...mapState([
            'projects',
            'settings',
            'companies',
        ])
    },
    mounted: function () {
        this.$nextTick(function () {
            this.componentKey++
        })	
	},  
    methods: {
        addprojectText(){
            if (this.$refs.default && this.$refs.default.validated()==true){
                if(this.project.id == 0){
                    return this.$i18n.t("buttons.add") 
                } else {
                    return this.$i18n.t("buttons.saveandclose")
                }
            } else { 
                return this.$i18n.t("buttons.invalid") 
            }
        },     
        projectcheck(){
            this.componentKey++

        },        
        translate(path){
            return this.$i18n.t('meta.'+path.toLowerCase())
        },      
        save_and_close() {
            if(this.$refs.default.validated()==true){
                this.save_project(this.project.id)
                this.close_modal()
            }
        },
        ...mapActions([
            'save_project',
            'add_project'
        ]),
        close_modal() {
            this.$store.commit('new_project',{ id: 0,ispublic:0, company_id:0, contactpersons:[],workfields:[]})
            this.$emit('close')
        },
        my_add_project(){
            if(this.$refs.default.validated()==true){
                this.add_project(this.project)
                this.close_modal()
            }
        }
    },
    components:{ProjectWorkfieldSettings,ProjectDefault,ProjectZZPers,ProjectDocuments,ProjectPlanningPeople,ProjectPlanningResources}
}
</script>