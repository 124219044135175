<template>
	<div class="popup">
        <div class="popup-header">
            {{ $t("labels.addcontract") }}
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
          <input type="hidden" :key="componentKey" />

            <b-tabs v-model="tab">
                <b-tab >
                    <template v-slot:title>ProjectZZPerOverview</template>                    
                    <ProjectZZPerOverview v-bind:project_id="project_id"></ProjectZZPerOverview>
                </b-tab>
                <b-tab  :title="this.planningtitle" ref="planningstab" lazy>
                    <ProjectZZPerPlanning v-bind:project_id="project_id" ref="planning" @change="planningcheck"></ProjectZZPerPlanning>
                </b-tab>
            </b-tabs>
        </div>

        <div class="popup-footer">
          <div v-if="this.selectedZZPr!=null" @click="save_and_close" class="btn btn-primary">{{ addcontractText() }}</div>

        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import ProjectZZPerOverview from './settings-screens/ProjectZZPerOverview'
import ProjectZZPerPlanning from './settings-screens/ProjectZZPerPlanning'

export default {
    name: 'project-zzper-modal',
    data() {
        return {
            tab:0,
            startdate:null,
            componentKey:0,

        }        
    },
    mounted() {
        this.onMounted();
    },
    props: {
        project_id: Number,
        company_id:Number
    },
    computed: {
        project() {
            return this.project_by_id(this.project_id) 
        },
        ...mapGetters([
            'project_metas',
            'project_by_id',
            'project_new',
            'selectedZZPr',
            'user_by_id',
            'username_by_id'
        ]),
        ...mapState([
            'projects',
            'settings',
            'companies',
        ]),
        planningtitle() {
            if(this.selectedZZPr){
                return this.username_by_id(this.selectedZZPr)
            } else { 
                return ""
            }
        }
    },
    watch :{
        planningtitle:function(){
            this.tab=1
        }
    },

    methods: {
        addcontractText(){
            if (this.$refs.planning && this.$refs.planning.validated()==true){
                return this.$i18n.t("buttons.add") 
            } else { 
                return this.$i18n.t("buttons.invalid") 
            }
        },    
        planningcheck(){
            this.componentKey++
        },
        onMounted() {
            this.$store.commit('selectedZZPr',null);
        },
        translate(path){
            return this.$i18n.t('meta.'+path.toLowerCase())
        },      

        ...mapActions([
            'save_project',
            'add_planning'
        ]),
        close_modal() {

            this.$store.commit('new_planning',{ id: 0 , project_id:0, user_id:0, pointcontact_id:0,sign_id:0})
            this.$emit('close')
        },  
        async save_and_close() {
            if(this.$refs.planning.validated()){
                if(this.selectedZZPr!=null){
                    console.log("save start")
                    await this.$refs.planning.save_data();
                    console.log("save done")
                    if(this.project.zzprusers.indexOf(this.selectedZZPr)==-1){
                        var datatosendrel={project_id:this.project.id, user_id:this.selectedZZPr}
                        this.$store.dispatch('save_project_relations',datatosendrel);
                        this.project.zzprusers.push(this.selectedZZPr)
                        console.log("add zzper to project")
                    }
                }
                this.$store.commit('selectedZZPr',null)
                this.close_modal()
            }
        }   
            
    },
    components:{ProjectZZPerOverview,ProjectZZPerPlanning}
}
</script>