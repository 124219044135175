<template>
	<div class="popup">

        <div class="popup-header">
            <div>
                 {{ $t("labels.confirm") }}
            </div>
            <div  class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body" style="overflow:hidden">
          <b-container>
          <div class="row">
            <div class="col-3">{{$t('labels.resource')}}</div>
            <div class="col">{{resource_by_id(resource_id).name }}</div>
            </div>
            <div class="row">

            <div class="col-3">{{$t('labels.project')}}</div>
            <div class="col">{{ project_by_id(project_id).title }}</div>
          </div>
          <div class="row">
            <div class="col">{{$t('labels.startdate')}} </div>
            <div class="col"><input type="date" v-model='initstart'></div>
            <div class="col">{{$t('labels.enddate')}} </div>
            <div class="col"><input type="date" v-model='initend'></div>
          </div>
          <div class="row">
            <div class="col">{{$t('meta.starttijd')}}</div>
            <div class="col"><input type="time" v-model='initstartt'></div>
            <div class="col">{{$t('meta.eindtijd')}}</div>
            <div class="col"><input type="time" v-model='initendt'></div>
          </div>

          </b-container>
        </div>
        <div class="popup-footer">
            <div class="btn btn-primary" @click="$emit('close')">{{ $t("buttons.cancel") }}</div>
            <div @click="save_and_close" class="btn btn-primary">{{ $t("buttons.saveandclose") }}</div>
        </div>
    </div>
</template>

<script>
import {  mapGetters, mapState, mapActions} from 'vuex'

export default {
    name: 'dropresourceonproject-modal',
    data() {
        return {
          confirmed:false,
          initstart:null,
          initend:null,
          initstartt:null,
          initendt:null,          
          user_id:null,
        }
    },
    props: {
        project_id:Number,
        resource_id: Number
    },
    mounted() {
        this.$store.commit('modal_confirm',{ id: 0})
        this.initstart = this.project.startdate
        this.initend = this.project.enddate
        this.initstartt = this.project.starttime
        this.initendt = this.project.endtime       
    },
    computed: {
      ...mapGetters ([
      'zzpers',
      'project_by_id',
      'user_by_id',
      'username_by_id',
       'createAny'

  ]),
  ...mapState ([
      'ffwax',
    ]), 
    project(){
    return this.project_by_id(this.project_id)
    },
    start_offset(){
      return Math.round(( new Date(this.initstart).getTime() - new Date(this.project.startdate).getTime())/86400000)
    },
    end_offset() {
      return Math.round(( new Date(this.initend).getTime() - new Date(this.initstart).getTime())/86400000)

    },    
    },
    methods: {
      ...mapActions([
      'add_ffwax'
    ]),      
        save_and_close() {
          let ffwax = {id:0, user_id:this.user_id,project_id:this.project_id, resource_id:this.resource_id, start_offset:this.start_offset,end_offset:this.end_offset, start_time:this.initstartt,end_time:this.initendt}
          this.add_ffwax(ffwax)
          this.$emit('close')
        },

    }
}
</script>