<template>
  <b-modal id="BSTimerID" centered  @show="showMe" @hide="hideMe">
    <template #modal-header>
      <h2 class="modal-title">{{ $t("labels.timerrunning") }}</h2>
    </template>
    <div class="container">
      <div class="row">
        <div class="col">
          <strong>{{ $t('labels.workhoursstart') }}</strong>
        </div>
        <div class="col">
          <strong>{{ $t('labels.timepast') }}</strong>
        </div>
      </div>
      <div class="row">
        <div class="col" v-if="worktimer">
        {{new Date(worktimer.added_date).toTimeString().split(' ')[0]}}
      </div>
        <div class="col">
          <strong>{{ this.timerunning }}</strong>
        </div>
      </div>
      <button class="btn btn-secondairy" @click="stoptimer"><font-awesome-icon icon="stop" />{{$t('buttons.stoptimer')}}</button>
      <button class="btn btn-primary" @click="stoptimerandbook"><font-awesome-icon icon="book" />{{$t('buttons.stoptimerandbook')}}</button>
    </div>
    
    

  <template #modal-footer>
    <button class="btn btn-secondairy" @click="close_modal">{{ $t( "buttons.close") }}</button>
  </template>

  </b-modal>
</template>


<script>
import { mapGetters,mapActions,  mapState } from 'vuex'

export default {
  name: 'timer-modal',
  data() {
    return {
      timerunning:"",
      timer:null
    }
  },
  props:{
    worktimer:Object,
  },
  async mounted() {  

  },

  computed: {
    ...mapGetters([
    'user_photo_by_id',
    'username_by_id',
    'zzpers'

    ]),
    ...mapState([
      'users',
    ]),

  },
  methods: {
    ...mapActions([ 
    ]),
    secondtick(){
      var delta = Math.abs(new Date()-new Date(this.worktimer.added_date)) / 1000;
      var days = Math.floor(delta / 86400);
      delta -= days * 86400;
      var hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;
      var minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;
      var seconds = delta % 60;  
      this.timerunning = hours<10?"0"+hours:hours
      this.timerunning+=":"
      this.timerunning += minutes<10?"0"+minutes:minutes
      this.timerunning+=":"
      this.timerunning += seconds<10?"0"+seconds.toFixed() :seconds.toFixed() 
      if(days>0){this.timerunning+= "("+days+" Dagen)"}

    },
    showMe(){
      if(this.timer==null){
        this.timer = setInterval(this.secondtick, 1000);
      }
    },
    hideMe(){
      this.close_modal();
    },
    stoptimerandbook(){
      this.$emit('madeChoice', "StopItAndBook")
      this.close_modal()
    },
    stoptimer(){
      this.$emit('madeChoice', "StopIt")

      this.close_modal()

    },
    close_modal() {
      this.$bvModal.hide('BSTimerID')
      clearInterval(this.timer)
      this.timer=null
    },
  },
}    
</script>
