<template>
  <form class="form">
      <div class="form-row">
      <input type="hidden" :key="componentKey" />

        <div class="col-3">
          <font-awesome-icon v-if="project.branche_id>0" icon="check" style="color: green;"/>
          <font-awesome-icon v-else icon="times" style="color: red;"/>           
          <label for="">{{ $t("labels.branche") }}</label>
        </div>
        <div class="col">
          <b-form-select :state="project.branche_id>0" name="branches" class="form-control company" v-model="project.branche_id" >
            <option  v-for="(branche,idx) in activebranches"  :key="idx" v-bind:value="branche.id">
              {{ branche["name"]}}
            </option>
          </b-form-select>
            <b-form-invalid-feedback >
                {{ $t("validation.branche")}}
            </b-form-invalid-feedback>                 
        </div>
      </div>
      <div class="form-row">
        <div class="col-3">
          <font-awesome-icon v-if="project.company_id!=0" icon="check" style="color: green;"/>
          <font-awesome-icon v-else icon="times" style="color: red;"/>                
          <label for="">{{ $t("labels.client") }}</label>
        </div>
        <div class="col">
          <v-select :options="companies_in_branche" class="contactpersoon" v-model="company_id"></v-select>
          <b-form-invalid-feedback >
              {{ $t("validation.clientselected")}}
          </b-form-invalid-feedback>            
        </div>
      </div>      
      <div class="form-row">
        <div class="col-3">
          <font-awesome-icon v-if="project.user_id!=undefined" icon="check" style="color: green;"/>
          <font-awesome-icon v-else icon="times" style="color: red;"/>                
          <label for="">{{ $t("option.contactperson") }}</label>
        </div>
        <div class="col">
          <select name="contactperson" class="form-control contactperson" v-model="project.user_id" >
            <option  v-for="(user,idx) in contacts_of_company"  :key="idx" v-bind:value="user.id">
              {{ user["firstname"]}} {{ user["middlename"] }} {{ user["lastname"] }}
              </option>
          </select>  
        </div>
      </div> 
      <!-- Project title -->
      <div class="form-row">
          <div class="col-3">
            <font-awesome-icon v-if="titlecheck" icon="check" style="color: green;"/>
            <font-awesome-icon v-else icon="times" style="color: red;"/>            
              <label for="">{{ $t("labels.projectname") }}</label>
          </div>
          <div class="col">
                <b-form-input type="text" class="form-control" :state="titlecheck" v-model="project.title"  />
                    <b-form-invalid-feedback >
                        {{ $t("validation.projecttitle")}}
                    </b-form-invalid-feedback>                 
          </div>
      </div>       
      <!-- public project -->
      <div class="form-row">
          <div class="col-3">
            <label for="checkbox-1">{{ $t("labels.showproject") }}</label>

          </div>    
          <div class="col">
            <b-form-checkbox  
              v-model="project.ispublic"
              name="checkbox-1"
              value="1" unchecked-value="0"></b-form-checkbox>   
          </div>
      </div>
      <div v-if="project.id!=0" class="form-row">
        <div class="col-3">
            <label >{{ $t("meta.projectrefnumber") }}</label>
        </div>
        <div  class="col">
            {{project.id}}
        </div>      
      </div>
      <div class="form-row">
      <div class="col-3">
        <font-awesome-icon v-if="project.urencodering!=undefined" icon="check" style="color: green;"/>
          <font-awesome-icon v-else icon="times" style="color: red;"/>     
        <label>{{ $t("labels.hourcoding") }}</label>
      </div>
      <div class="col-3">
        <b-form-group>
          <b-form-radio :state="project.urencodering!=null" v-model="project.urencodering" value="no">{{ $t("labels.no") }}
          </b-form-radio>
          <b-form-radio :state="project.urencodering!=null" v-model="project.urencodering" value="yes">{{ $t("labels.mandatory") }}
          </b-form-radio>
          <b-form-radio :state="project.urencodering!=null" v-model="project.urencodering" value="both">{{ $t("labels.mandatory") }} & {{ $t("labels.free") }}
          </b-form-radio>  
          <b-form-radio :state="project.urencodering!=null" v-model="project.urencodering" value="free">{{ $t("labels.free") }}
          </b-form-radio>      
        
        </b-form-group>
      </div>

    </div>
    <div class="form-row" v-if="project.urencodering=='yes' || project.urencodering=='both'">
      <div class="col-3" v-if="settings.InvoiceByHourcode">
      <label>  {{ $t("labels.invoicebyhourcode") }}</label> 
      </div>
      <div class="col-2" v-if="settings.InvoiceByHourcode">
        <b-form-group>
          <b-form-radio v-model="project.invoiceperhourcode" value="no">{{ $t("labels.no") }}</b-form-radio>
          <b-form-radio v-model="project.invoiceperhourcode" value="yes">{{ $t("labels.yes") }}</b-form-radio>
        </b-form-group>
      </div>
      <div class="col-2">
        {{ $t("labels.hourcategoryname") }}
      </div>
      <div class="col-4">
        <select name="branche" class="form-control opdrachtgever" v-model="project.hourcategory">
          <option v-for="(hourcategories,idx) in activehourcategories" :key="'hourcategory'+idx"
            v-bind:value="hourcategories.id">
            {{ hourcategories["name"]}}
          </option>
        </select>
      </div>
    </div>

    
    <div class="form-row" v-if="project.urencodering=='free' || project.urencodering=='both'">
      <div class="col-3">
        <label>{{ $t("labels.label1") }}</label>
      </div>
      <div class="col-9">
        <b-form-input type=text class="form-control" v-model="project.label1" />
      </div>
    </div>          
    <div class="form-row" v-if="project.urencodering=='free' || project.urencodering=='both'">
      <div class="col-3">
        <label>{{ $t("labels.label2") }}</label>
      </div>
      <div class="col-9">
        <b-form-input type=text class="form-control" v-model="project.label2" />
      </div>
    </div>              
      <!-- dynamic ordermetas -->
      <div v-for="meta in project_metas" v-bind:key="meta.key">
          <div class="form-row" v-if="(project[meta.toggle] || !meta.toggle) && metastoskip.indexOf(meta.key) ==-1">
            <div class="col-3" v-if="meta.label!='projectnotes' && meta.label!='Einddatum'">
              <label v-bind:for="meta.key">{{translate(meta.label)}} </label>
            </div>
            <div class="col-3" v-if="meta.label=='Einddatum'">
              <font-awesome-icon v-if="datecheck" icon="check" style="color: green;"/>
              <font-awesome-icon v-else icon="times" style="color: red;"/>                
              <label v-bind:for="meta.key">{{translate(meta.label)}} </label>
            </div>            
            <div v-if="meta.key=='projectnumber'" class="col">
              <input v-bind:type="meta.type" v-bind:placeholder=translate(meta.label) v-bind:id="meta.key" class="form-control" v-model="project[meta.key]">
            </div>
            <div v-else-if="meta.key=='startdate' || meta.key=='enddate'|| meta.key=='publishdate'" class="col" v-bind:class="{'col-4': meta.type == 'date', 'col-2': meta.type == 'number'}">
              <flat-pickr 
                v-model="project[meta.key]"
                :config="config"
                class="form-control"
                :placeholder="$t('labels.selectdate')"
              />       <div v-if="meta.key=='enddate'">{{checkdates()}}</div>
             </div>
             <div v-else-if="meta.key=='starttime' || meta.key=='endtime'" class="col" v-bind:class="{'col-4': meta.type == 'date', 'col-4': meta.type == 'string'}">
              <b-form-input type="time"
                v-model="project[meta.key]" />
             </div>             
            <div v-else-if="meta.key!='omschrijvingwebsite' && meta.key!='extra' && meta.key!='description'" class="col" v-bind:class="{'col-4': meta.type == 'date', 'col-2': meta.type == 'number'}">
              <input v-if="meta.key!='projectnotes'" v-bind:type="meta.type" v-bind:placeholder=translate(meta.label) v-bind:id="meta.key" class="form-control" v-model="project[meta.key]">
            </div> 
            <div v-else-if="meta.key!='planningscolor'" class="col" v-bind:class="{'col-4': meta.type == 'date', 'col-2': meta.type == 'number'}">
              <b-input-group>
                <b-form-textarea size="sm"  rows="3" v-bind:type="meta.type" v-bind:placeholder=translate(meta.label) v-bind:id="'bind-'+meta.key" :ref="meta.key" class="form-control" v-model="project[meta.key]"  />
                    <b-input-group-append v-if="createAny('users')">
                        <b-button squared class="multi-append"  href="javascript:;" @click="append_data_multi(meta.key)"><font-awesome-icon icon="plus-circle" /> </b-button>
                    </b-input-group-append>                         
              </b-input-group>                
            </div>
          </div>

      </div>     
      <div class="form-row" v-if="settings.EnableHourPlanning || specialRights('URSchedule')">
            <div class="col-3" >
              <label>{{ $t('meta.planningcolor') }}</label>
            </div>
            <div class="col">
              <input type="color" v-model="project.planningcolor"/>
            </div>
          </div>                 
  </form>  
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

const Dutch = require("flatpickr/dist/l10n/nl.js").default.nl;

export default {
    name: 'project-modal-default',
    data() {
        return {
          componentKey:0,
          company_id:null,
          config:{
            weekNumbers:true,
            locale: Dutch,
            altInput: true,
            altFormat: "d-m-Y",              
            dateFormat: "Y-m-d",
          },
          metastoskip:["planningcolor","urencodering","label1","label2","invoiceperhourcode","hourcategory"]       
        }
    },
    props: {
      project_id:Number,
    },

    computed: {
        project() {
          return this.project_id > 0 ? this.project_by_id(this.project_id) : this.project_new
        },


        companies_in_branche(){
          // let companiesinbranche =[]
          // for(var i=0;i<this.companies.length;i++){
          //   if(this.companies[i].branche_id == this.project.branche_id){
          //     companiesinbranche.push({ name: this.companies[i].company_name, id: this.companies[i].id })
          //   }
          // }
          // return companiesinbranche
          return this.companies.filter(c =>c.branche_id == this.project.branche_id).map(item => {
              const container = {};

              container.label = item.company_name;
              container.id = item.id;

              return container;
          })
        },
        ...mapGetters([
            'project_metas',
            'project_by_id',
            'company_by_id',
            'activebranches',
            'project_new',
            'username_by_id',
            'append_data_string',
            'activehourcategories',
            'createAny'

        ]),
        ...mapState([
            'projects',
            'settings',
            'companies',
            'users',
            'branches'
        ]),
        contacts_of_company(){
          let contacts_of_company = []
          if(this.project.company_id != 0 && this.project.company_id!=null){
            let current_company = this.company_by_id(this.project.company_id);
            if(current_company){
              for(var i=0;i<this.users.length;i++){
                if(current_company.contactpersons.indexOf(this.users[i].id)!=-1 || this.users[i].id==current_company.user_id){
                  contacts_of_company.push(this.users[i])
                }
              } 
            }
          } 

          return contacts_of_company.sort(function(a, b) {
            if (a.lastname < b.lastname) return -1;
            if (a.lastname> b.lastname) return 1;
            return 0;
          });

        },
        titlecheck(){
            return this.project.title!=undefined && this.project.title.length>3;
        },    
        datecheck(){
          if(this.project.enddate!=undefined && this.project.startdate!=undefined){
            if(new Date(this.project.enddate)>= new Date (this.project.startdate)){
              return true
            }        
          } else {
            return false
          }
          return false
        }    
    },
    methods: {
        translate(path){
          if(path.toLowerCase()=="beschrijving"||path.toLowerCase()=="extra"){
            return this.$i18n.t('meta.'+path.toLowerCase()+'project')
          } else{
            return this.$i18n.t('meta.'+path.toLowerCase())

          }
        }, 
        onMounted(){
          this.company_id={}
          this.company_id.id=this.project.company_id
          this.company_id.label=this.company_by_id(this.project.company_id).company_name
          if(this.project_id==0 && this.company_by_id(this.project.company_id).hourcategory_id==null){
              this.project.urencodering = 'no'
          }
        },
        validated(){
            return this.titlecheck&&this.project.company_id!=0&&this.project.user_id!=undefined&&this.datecheck&&this.project.urencodering!=undefined
        },     
        append_data_multi(key){
            var current= ""
            if(this.project[key]){
                current="\n"+this.project[key]
            }
            this.project[key]=this.append_data_string+current
            this.componentKey += 1;
            if(this.$refs[key]){
                if(this.$refs[key][0]){
                    this.$refs[key][0].focus()
                    this.$nextTick(() => {
                      this.$refs[key][0].selectionEnd = 9
                    })                    
                }else{
                    this.$refs[key].focus()
                    this.$nextTick(() => {
                      this.$refs[key].selectionEnd = 9
                    })                    
                }
            }
        },  
        checkdates(){
          if(this.project.enddate!=undefined && this.project.startdate!=undefined){
            if(new Date(this.project.enddate)< new Date (this.project.startdate)){
              return "Endate before startdate"
            }
          }
          return ""
        }           
    },
    mounted() {
      this.onMounted()
    },
    watch:{
      company_id:function(){
        this.project.company_id = this.company_id.id
        if(this.project_id==0 && this.company_by_id(this.project.company_id).hourcategory_id==null){
              this.project.urencodering = 'no'
          }
        
      }
    },

    components: {
            flatPickr
        }     
}
</script>