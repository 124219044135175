<template>
  <div class="popup">

    <div class="popup-header">
      <div v-if="emailsender.id > 0">
        {{ emailsender.name }}
      </div>
      <div v-else>
        {{ $t("labels.emailsendersnew") }}
      </div>
      <div v-if="emailsender.id == 0" class="popup-close" @click="$emit('close')">
        <font-awesome-icon icon="times" />
      </div>
      <div v-else class="popup-close" @click="save_and_close">
        <font-awesome-icon icon="times" />
      </div>
    </div>
    <div class="popup-body">
      <b-container>
        <form class="form" v-on:submit='save_and_close'>
          <div class="form-row">
            <div class="col-3">
              <font-awesome-icon v-if="checkemailsender" icon="check" style="color: green;" />
              <font-awesome-icon v-else icon="times" style="color: red;" />
              <label for="">{{ $t("labels.emailsendersname") }}</label>
            </div>
            <div class="col">
              <b-input ref="deffield" :state="checkemailsender" type="text" class="form-control"
                v-model="emailsender.name"></b-input>
              <b-form-invalid-feedback>
                {{ $t("validation.emailsendercheck") }}
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="form-row">
            <div class="col-3">
              <label for="">{{ $t("labels.emailsendersemail") }}</label>
            </div>
            <div class="col">
              <b-input disabled type="email" class="form-control" v-model="emailsender.email" />
              <b-form-invalid-feedback>
                {{ $t("validation.emailsendercheck") }}
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="form-row" v-if="false">
            <div class="col-3">
              <font-awesome-icon v-if="checkemailsenderpassword" icon="check" style="color: green;" />
              <font-awesome-icon v-else icon="times" style="color: red;" />
              <label for="">{{ $t("labels.emailsenderspassword") }}</label>
            </div>
            <div class="col">
              <b-input-group class="mt-3">
                <b-form-input :type="pwtype" v-model="emailsender.password" :state="checkemailsenderpassword"
                  placeholder="" />
                <b-input-group-append>
                  <b-button class="multi-append"  href="javascript:;" @click="reveal()">
                    <font-awesome-icon :icon=btnText />
                  </b-button>
                </b-input-group-append>
                <b-form-invalid-feedback>
                  {{ $t("validation.password") }}
                </b-form-invalid-feedback>
              </b-input-group>
            </div>
          </div>
          <div class="form-row" v-if="false">
            <div class="col-3">
              <label for="">{{ $t("labels.active") }}</label>
            </div>
            <div class="col">
              <select v-model="emailsender.active" class="form-control">
                <option value="1">{{ $t("option.active") }}</option>
                <option value="0">{{ $t("option.inactive") }}</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="col-3">
              <label for="">{{ $t("labels.emailsenderssignature") }}</label>
            </div>
            <div class="col">
              <ckeditor :editor="editor" v-model="emailsender.signature" :config="editorConfig"></ckeditor>

            </div>
          </div>

        </form>
      </b-container>
    </div>
    <div class="popup-footer">
      <div v-if="emailsender.id == 0" class="btn btn-primary" @click="my_add_emailsender();">{{ addemailsenderText()
      }}</div>
      <div v-else @click="save_and_close" class="btn btn-primary">{{ $t("buttons.saveandclose") }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: 'emailsender-modal',
  data() {
    return {
      pwtype: 'password',
      btnText: 'eye',
      new_emailsender: { id: 0, active: 1 },
      //eslint-disable-next-line
      reg: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items:[ 'heading', '|', 'undo', 'redo', '-', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ,'insertTable'],
          shouldNotGroupWhenFull: true
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
          ]
        },
        table: {
            contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        }
      },
    }
  },
  props: {
    emailsender_id: Number
  },
  mounted() {
    this.focusInput()
  },
  computed: {
    emailsender() {
      return this.emailsender_id > 0 ? this.emailsender_by_id(this.emailsender_id) : this.new_emailsender
    },
    ...mapGetters([
      'emailsender_by_id',
    ]),
    ...mapState([
      'emailsenders',
    ]),
    checkemailsender() {
      if (this.emailsender.id > 0) {
        return true
      }
      let found = false;
      if (this.emailsender.name != undefined) {
        for (var i = 0; i < this.emailsenders.length; i++) {
          if (this.emailsender.name.toLowerCase() == this.emailsenders[i].name.toLowerCase()) {
            found = true;
            break;
          }
        }
      }
      return !found && this.emailsender.name != undefined && this.emailsender.name.length > 2
    },
    checkemailsenderpassword() {

      return this.emailsender.password != undefined && this.emailsender.password.length > 4
    },
    checkemailsenderemail() {
      let found = false;
      if (this.emailsender.email != undefined) {
        for (var i = 0; i < this.emailsenders.length; i++) {
          if (this.emailsender.email.toLowerCase() == this.emailsenders[i].email.toLowerCase() && this.emailsenders[i].id != this.emailsender.id) {
            found = true;
            break;
          }
        }
      }
      return !found && this.emailsender.email != undefined && this.reg.test(this.emailsender.email)
    }
  },
  methods: {
    addemailsenderText() {
      if (this.checkemailsender) {
        return this.$i18n.t("buttons.add")
      } else {
        return this.$i18n.t("buttons.invalid")
      }
    },
    save_and_close(e) {
      e.preventDefault() // it prevent from page reload
      if (this.emailsender.id == 0) {
        this.my_add_emailsender()
      } else {
        this.save_emailsender(this.emailsender.id)
        this.$emit('close')
      }
    },
    ...mapActions([
      'save_emailsender',
      'add_emailsender'
    ]),
    my_add_emailsender() {
      if (this.checkemailsender) {
        this.add_emailsender(this.emailsender);
        this.$emit('close')
      }
    },
    focusInput() {
      this.$refs.deffield.focus();
    },
    reveal() {
      if (this.pwtype === 'password') {
        this.pwtype = 'text'
        this.btnText = 'eye-slash'
      } else {
        this.pwtype = 'password'
        this.btnText = 'eye'
      }
    },

  }
}
</script>