<template>
  <b-modal id="BSWeekPlanningID" centered size="lg" @show="fillLocal" @hide="clearlocal">
    <template #modal-header>
      <h2 class="modal-title">{{ username_by_id(user_id) }} </h2>
    </template>
    <b-container ref="container">
    <div class="row">
      <div class="col-3">
        <a v-if="current_user.role == 'admin'" href="javascript:;" class="action-item float-left" @click="prevweek()"
        :title="$t('buttons.previousweek')">
        <font-awesome-icon size="2x" icon="arrow-circle-left" />
      </a>
      </div>
      <div class="col-6"> {{$t('labels.week')}} {{ week }} {{ year }}</div>
      <div class="col-3">
        <a v-if="current_user.role == 'admin'" href="javascript:;" class="action-item" @click="nextweek()"
        :title="$t('buttons.nextweek')">
        <font-awesome-icon size="2x" icon="arrow-circle-right" />
      </a>
      </div>
    </div>
    <b-form-radio-group id="radio-group-1" v-model="selectedproject" name="radio-options">
      <b-form-radio name="project" :value="-1" v-if="settings.EnableVacationPlanning">
            Vakantie <strong>( {{ projectCount(-1) }} )</strong>
          </b-form-radio>       
      <div class="row" v-for="(project, idx) in thisWeeksProjects" :key="idx">
        <div class="col-9">
          <b-form-radio name="project" :value="project.id" >
            {{ project_by_id(project.project_id).title }} <strong>( {{ projectCount(project.project_id) }} )</strong>
          </b-form-radio> 
        </div>
        <div class="col-3"><input type="color" v-model="project_by_id(project.project_id).planningcolor" @change="saveprojectcolor(project.id)"></div>

      </div>
    </b-form-radio-group>
    <div class="row" v-for="(date, index) in dates"  :key="index" style="height:40px">
      <div class="col-4" v-if="index<=4">{{date}} {{ dayOfWeek(new Date(date)) }}</div>
      <div class="col-4" v-else style="color:red">{{date}} {{ dayOfWeek(new Date(date)) }}</div>
      <div class="col-1 hourplanning" v-for="n in 8" :key="n" :ref="'field'+index" @click="clicked(index, n, event)" :title="project_title(index,n)"></div>

    </div>
    <div id="menupop" ref="menupop" style="position: absolute; left:10; top:10;"> 
      <ul class="dayplanning-menu" v-if="clearmenu==1">
        <li class="dayplanning-item" @click="add_hours(1)"><font-awesome-icon icon="minus" /> 1 {{$t('titles.project_hours')}}</li>
        <li class="dayplanning-item" @click="add_hours(2)"><font-awesome-icon icon="minus" /> 2 {{$t('titles.project_hours')}}</li>
        <li class="dayplanning-item" @click="add_hours(4)"><font-awesome-icon icon="minus" /> 4 {{$t('titles.project_hours')}}</li>
        <li class="dayplanning-item" @click="add_hours(8)"><font-awesome-icon icon="minus" /> 8 {{$t('titles.project_hours')}}</li>
        <li class="dayplanning-item" @click="add_hours(16)"><font-awesome-icon icon="minus" /> 16 {{$t('titles.project_hours')}}</li>
        <li class="dayplanning-item" @click="add_hours(24)"><font-awesome-icon icon="minus" /> 24 {{$t('titles.project_hours')}}</li>
        <li class="dayplanning-item" @click="add_hours(32)"><font-awesome-icon icon="minus" /> 32 {{$t('titles.project_hours')}}</li>
        <li class="dayplanning-item" @click="add_hours(40)"><font-awesome-icon icon="minus" /> 40 {{$t('titles.project_hours')}}</li>
      </ul>
      <ul class="dayplanning-menu" v-if="addmenu==1">
        <li class="dayplanning-item" @click="add_hours(1)"><font-awesome-icon icon="plus" /> 1 {{$t('titles.project_hours')}}</li>
        <li class="dayplanning-item" @click="add_hours(2)"><font-awesome-icon icon="plus" /> 2 {{$t('titles.project_hours')}}</li>
        <li class="dayplanning-item" @click="add_hours(4)"><font-awesome-icon icon="plus" /> 4 {{$t('titles.project_hours')}}</li>
        <li class="dayplanning-item" @click="add_hours(8)"><font-awesome-icon icon="plus" /> 8 {{$t('titles.project_hours')}}</li>
        <li class="dayplanning-item" @click="add_hours(16)"><font-awesome-icon icon="plus" /> 16 {{$t('titles.project_hours')}}</li>
        <li class="dayplanning-item" @click="add_hours(24)"><font-awesome-icon icon="plus" /> 24 {{$t('titles.project_hours')}}</li>
        <li class="dayplanning-item" @click="add_hours(32)"><font-awesome-icon icon="plus" /> 32 {{$t('titles.project_hours')}}</li>
        <li class="dayplanning-item" @click="add_hours(40)"><font-awesome-icon icon="plus" /> 40 {{$t('titles.project_hours')}}</li>
      </ul>      
    </div>
    </b-container>
    <template #modal-footer>
      <div @click="saveData" class="btn btn-primary">
       {{ $t("buttons.savesettings") }}
      </div>
    </template>
  </b-modal>
</template>


<script>
import axios from 'axios'
import { mapGetters,mapActions,  mapState } from 'vuex'

export default {
  name: 'userweekplanning-modal',
  data() {
    return {
      week: null,
      year:null,
      startweekdate:null,
      endweekdate:null,
      senddate: null,
      dates:[],
      selectedproject:null,
      fill:null,
      day_planning:null,
      showmenu:0,
      addmenu:0,
      clearmenu:0,
      clickedindex:0,
      clickedn:0,
      randomColors:['#ff0000','#00ff00','#0000ff','#ffff00','#ff00ff','#00ffff','#000000','#ffffff','#333333','#777777','#aaaaaa','#dddddd']
    }
  },
  props: {
    user_id: Number,
  },
  mounted(){},
  created(){this.processfill()},
  computed: {
    ...mapGetters([
      'server_url',
      'factuur_by_id',
      'contract_by_id',
      'username_by_id',
      'project_by_id'
    ]),
    ...mapState([
      'users',
      'branches',
      'planning',
      'urenbonnen',
      'projects'
    ]),
    user() {
        return this.user_id==0?null:this.user_by_id(this.user_id)
    },
    thisWeeksProjects(){
      var res = 
      this.planning.filter(p => p.user_id==this.user_id)
        .filter(p =>
            new Date(p.startdate) <= this.endweekdate && new Date(p.enddate) >= this.startweekdate
        )
      res.forEach(planningitem => {
        console.log(planningitem,this.project_by_id(planningitem.project_id).planningcolor)
        if(this.project_by_id(planningitem.project_id).planningcolor==undefined) {
          this.project_by_id(planningitem.project_id).planningcolor = this.randomColors[Math.floor(Math.random()*this.randomColors.length)]
          this.saveprojectcolor(planningitem.project_id)
          console.log("And now?",this.project_by_id(planningitem.project_id).planningcolor)
        }
        
      });
      return res
    },
    dayplanning(){
      return this.day_planning!=null?this.day_planning.filter(dp => dp.user_id == this.user_id && dp.week==this.week && dp.year ==this.year)[0]:[]
    }
  },
  methods: {
    ...mapActions([ 
      'save_project'
    ]),
    async fillLocal() {
      this.week = this.getWeekNumbervalue(new Date())
      this.year = this.getYearvalue(new Date())
      this.day_planning = await axios.get('/api/get_dayplanning/'+this.user_id)
      this.day_planning = this.day_planning.data
      this.$nextTick(() => {
        this.processfill()
        this.selectedproject = this.thisWeeksProjects.length>0?this.thisWeeksProjects[0].id:null
      })
    },
    saveprojectcolor(id){
      this.selectedproject = id
      this.save_project(this.thisWeeksProjects.filter(p => p.id==this.selectedproject)[0].project_id)

    },
    projectCount(id){
      var count=0
      for(let i=0;i<7;i++){
        for (let j=0;j<8;j++){
          if(this.fill[i][j]==id){
            count++
          }
        }
      }
     return count

    },    
    processfill(){
      if(this.dayplanning&&this.dayplanning.day_planning){
        this.fill = JSON.parse(this.dayplanning.day_planning)
      } else{
        this.fill = JSON.parse("[[0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0]]")
      }
      if(this.$refs["field0"]){
        for(let i=0;i<7;i++){
          for (let j=0;j<8;j++){
            if(this.$refs["field"+i][j] && this.fill[i][j]!=undefined){
                if(this.fill[i][j]!=0){
                this.$refs["field"+i][j].style["background-color"] = this.project_by_id(this.fill[i][j]).planningcolor
              } else{
                this.$refs["field"+i][j].style["background-color"] = '#fff'
              }
            }
          }
        }
      }
    },
    clicked(index, n){
      this.clickedindex = index
      this.clickedn = n
      if(this.fill[index][n-1]==this.thisWeeksProjects.filter(p => p.id==this.selectedproject)[0].project_id){
        //clear
        this.clearmenu=1
        this.addmenu=0
        // this.fill[index][n-1]=0
        // this.$refs["field"+index][n-1].style["background-color"] = '#fff'
      } else {
        // this.fill[index][n-1]=this.thisWeeksProjects.filter(p => p.id==this.selectedproject)[0].project_id
        // this.$refs["field"+index][n-1].style["background-color"] = this.project_by_id(this.fill[index][n-1]).planningcolor
        this.clearmenu=0
        this.addmenu=1
      }
      this.showmenu=1
      this.$refs["menupop"].style.left = (event.x-this.$refs["container"].getBoundingClientRect().x-40)+"px"
      this.$refs["menupop"].style.top = (event.y-this.$refs["container"].getBoundingClientRect().y)+"px"

    },
    project_title(index, n){
      if(this.fill.length>0){
        if(this.fill[index][n-1]==0||this.fill[index][n-1]==undefined){ 
          return ""
        } else {
          return this.project_by_id(this.fill[index][n-1]).title
        }
      } else{
        return ""
      }
    },
    clearlocal(){
    },
    add_hours(count){
      this.addmenu=0
      this.clearmenu=0
      let localn = this.clickedn-1;
      let localindex = this.clickedindex;
      var fillvalue = 0
      var fillcolor = 0
      if(this.fill[localindex][localn]==this.thisWeeksProjects.filter(p => p.id==this.selectedproject)[0].project_id){
        //clear
        this.fill[localindex][localn]=0
        this.$refs["field"+localindex][localn].style["background-color"] = '#fff'
        fillcolor =  '#fff'
        fillvalue = 0
      } else {
        this.fill[localindex][localn]=this.thisWeeksProjects.filter(p => p.id==this.selectedproject)[0].project_id
        this.$refs["field"+localindex][localn].style["background-color"] = this.project_by_id(this.fill[localindex][localn]).planningcolor
        fillcolor = this.project_by_id(this.fill[localindex][localn]).planningcolor
        fillvalue = this.thisWeeksProjects.filter(p => p.id==this.selectedproject)[0].project_id
      }
      for(var i=0;i<count; i++) {
        if(localindex<=6 && localn<=7) {
          this.fill[localindex][localn]=fillvalue
          this.$refs["field"+localindex][localn].style["background-color"] = fillcolor
        }
        localn++
        if(localn==8){
          localn=0
          localindex++
        }

      }

    },
    async saveData(){
      var res
      let dp = this.dayplanning
      if(dp && dp.day_planning){
        dp.day_planning = JSON.stringify(this.fill)
        res =  await axios.put('/api/save_dayplanning',dp)
      } else {
        res =  await axios.put('/api/save_dayplanning', {id:0, user_id:this.user_id, week:this.week, year:this.year, day_planning:JSON.stringify(this.fill)})
        this.day_planning.push(res.data)
      }
      // this.close_modal()
    },

    close_modal() {
    this.$bvModal.hide('BSWeekPlanningID')
    },
    prevweek() {
      this.week--
      this.processfill()
    },
    nextweek() {
      this.week++
      this.processfill()

    }    
  },
  watch:{
    week(){
      this.dates = this.getWeekDates(this.week, this.year)
      this.startweekdate = new Date(this.dates[0])
      this.endweekdate = new Date(this.dates[6])
    }
  }
}    
</script>
