<template>
  <b-container v-if="(currentUserIsSuperAdmin() || specialRights('URdashboard'))" fluid>

    <!-- TItle bar -->
    <div class="title-bar">
      <h1>{{ $t("titles.dashboard") }}</h1>
    </div>
    <!-- Filter bar -->
    <b-row class="filter-bar">
      <b-col md="4" xl>
        <b-form-group :label="$t('labels.from')">
          <b-form-input type="week" v-model="startwl" />
        </b-form-group>
      </b-col>
      <b-col md="4" xl>
        <b-form-group :label="$t('labels.to')">
          <b-form-input type="week" v-model="endwl" />
        </b-form-group>
      </b-col>
      <b-col cols="12">
      </b-col>      
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group :label="$t('labels.search')">
          <b-form-input ref="searchbox" id="filter-input" v-model="filter" type="search" :placeholder="$t('strings.typetosearch')">
          </b-form-input>
        </b-form-group>
      </b-col>      
      <b-col cols="6" md="3">
          <b-form-group :label="$t('labels.showby')">
            <b-form-radio-group id="radio-group-1" v-model="bycompany" name="radio-options">
              <b-form-radio name="showby" value="no">{{ $t("labels.byzzpr") }}</b-form-radio>
              <b-form-radio name="showby" value="yes">{{ $t("labels.bycompany") }}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col>
          <button v-if="startw!=startwl ||endw!= endwl" class="btn btn-primary" @click="reloadData">{{ $t('buttons.refresh') }}</button><br/>
          {{ dateToNL(allhours[0]) }} {{ $t('labels.to') }} {{ dateToNL(allhours[allhours.length-1]) }}
        </b-col>
    </b-row>
    <b-spinner v-if="loading" small></b-spinner>



    <div v-if="startw==startwl && endw==endwl">
      <b-row>
        <b-col>
          <strong>{{ $t('labels.dashhours') }}</strong>
        {{ totalHours }}
        </b-col>
        <b-col>
          <strong>{{ $t('labels.purchase') }}</strong>
        {{ totalInkoop.toLocaleString('nl-NL', { minimumFractionDigits: 2, style: "currency", currency: "EUR" })  }}
        </b-col>
        <b-col>
          <strong>{{ $t('labels.sale') }}</strong>
        {{ totalVerkoop.toLocaleString('nl-NL', { minimumFractionDigits: 2, style: "currency", currency: "EUR" }) }}
        </b-col>
        <b-col>
          <strong>{{ $t('labels.dashmarge') }}</strong>
        {{ (totalVerkoop-totalInkoop).toLocaleString('nl-NL', { minimumFractionDigits: 2, style: "currency", currency: "EUR" }) }}
        </b-col>
        <b-col>
          <strong>{{ $t('labels.dashgemmarge') }}</strong>
        {{ ((totalVerkoop-totalInkoop)/totalHours).toLocaleString('nl-NL', { minimumFractionDigits: 2, style: "currency", currency: "EUR" }) }}
        </b-col>      
    </b-row>    

      <div v-if="bycompany=='no'">
      <LineChartGenerator v-if="chartData"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height" />      
        <b-table sort-icon-left :filter="filter" :items="dashboardzzpr" :fields="zzpritems" :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :current-page="currentPage" :per-page="perPage">
            <template #cell(lastname)="row" @click.native.capture="show_user()">
              <p v-html="userinfo(row)" />
            </template>
            <template #cell(inkoop)="row">
              {{ row.item.inkoop.toLocaleString('nl-NL', { minimumFractionDigits: 2, style: "currency", currency: "EUR" })}}
            </template>
            <template #cell(verkoop)="row">
              {{ row.item.verkoop.toLocaleString('nl-NL', { minimumFractionDigits: 2, style: "currency", currency: "EUR" })}}
            </template>        
            <template #cell(marge)="row">
              {{ row.item.marge.toLocaleString('nl-NL', { minimumFractionDigits: 2, style: "currency", currency: "EUR" })}}
            </template>        
            <template #cell(gemmarge)="row">
              {{ row.item.gemmarge.toLocaleString('nl-NL', { minimumFractionDigits: 2, style: "currency", currency: "EUR" })}}
            </template>        
            
        </b-table>
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
      </div>
      <div v-else>
        <Bar v-if="chartDataCompany"
      :chart-options="chartOptions"
      :chart-data="chartDataCompany"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height" />      
        <b-table sort-icon-left :filter="filter" :items="dashboardcompany" :fields="companyitems" :sort-by.sync="sortByCompany"
            :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :current-page="currentPage" :per-page="perPage">
          <template #cell(companyname)="row" >
              <p v-html="companyinfo(row)" />
            </template>
            <template #cell(inkoop)="row">
              {{ row.item.inkoop.toLocaleString('nl-NL', { minimumFractionDigits: 2, style: "currency", currency: "EUR" })}}
            </template>
            <template #cell(verkoop)="row">
              {{ row.item.verkoop.toLocaleString('nl-NL', { minimumFractionDigits: 2, style: "currency", currency: "EUR" })}}
            </template>        
            <template #cell(marge)="row">
              {{ row.item.marge.toLocaleString('nl-NL', { minimumFractionDigits: 2, style: "currency", currency: "EUR" })}}
            </template>        
            <template #cell(gemmarge)="row">
              {{ row.item.gemmarge.toLocaleString('nl-NL', { minimumFractionDigits: 2, style: "currency", currency: "EUR" })}}
            </template>        

        </b-table>
        <b-pagination v-model="currentPage" :total-rows="totalRowsCompany" :per-page="perPage"></b-pagination>

      </div>
    </div>
  </b-container>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'

// https://vue-chartjs.org/examples/#vue-3-charts
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import { Bar } from 'vue-chartjs/legacy'

// import {
//   Chart as ChartJS,
//   Title,
//   Tooltip,
//   Legend,
//   LineElement,
//   LinearScale,
//   CategoryScale,
//   PointElement
// } from 'chart.js'
// ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement)

export default {
  name: 'Dashboard',
  data() {
    return {
      startw: null,
      endw: null,
      startwl: null,
      endwl: null,
      allhours: [],
      sortBy: 'lastname',
      sortByCompany: 'companyname',
      sortDesc: false,
      sortDirection: 'desc', 
      currentPage: 1,
      perPage: 20,   
      totalInkoop:0,
      totalVerkoop:0,
      totalHours:0,
      bycompany: 'yes',
      filter:"",
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',

        // scales: {
        //   x: 
        //     {
        //       type: 'linear',
        //       position: 'bottom',
        //     },
          
        //   y: 
        //     {
        //       type: 'linear',
        //       position: 'left'
        //     },
            
        // }
      }

    }
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 1200
      
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    // plugins: {
    //   type: Array,
    //   default: () => []
    // }
  },  

  mounted() {
    let now = new Date()
    let sweek = this.getWeekNumber(now)
    this.endw = parseInt(sweek[1]) < 10 ? sweek[0] + "-W0" + sweek[1] : sweek[0] + "-W" + sweek[1]
    now.setDate(now.getDate() - 28);
    sweek = this.getWeekNumber(now)
    this.startw = parseInt(sweek[1] - 1) < 10 ? sweek[0] + "-W0" + (sweek[1]) : sweek[0] + "-W" + (sweek[1] - 1)
    this.allhours = this.thisweekdays()
    this.startwl = this.startw
    this.endwl = this.endw

    // this.startw="2023-W13"
    // this.endw="2023-W13"
  },
  computed: {
    ...mapState([
      'contracts',
      'branches',
      'avatars',
      'planning',
      'workfields',
      'tags',
      'current_user',
      'showBlacklist',
      'hours',
      'urenbonnen',
      'invoiceextra',
      'users',
      'companies',
      'projects',
      'loading'
    ]),
    ...mapGetters([
      'branche_by_id',
      'project_by_id',
      'company_by_id',
      'user_by_id',
      'user_photo_by_id',
      'activebranches',
      'username_by_id',
      'confirm_modal',
      'owners',
      'find_last_planning_user',
      'contract_by_id',
      'server_url',
      'createAny'
    ]),
    totalRows() {
      return this.dashboardzzpr.length
    },
    totalRowsCompany() {
      return this.dashboardcompany.length
    },
    chartData() {
        let labels =[]
        let inkoop = []
        let verkoop = []
        let marge = []
        let projects = []
        let hours =[]

        let data = this.dashboardzzpr
        data.sort((a, b) => this.username_by_id(a.user_id) > this.username_by_id(b.user_id) && 1 || -1)
        if(data) {
        data.forEach(row => {
          labels.push(this.username_by_id(row.user_id))
          inkoop.push(row.inkoop)
          verkoop.push(row.verkoop)
          marge.push(row.marge)
          projects.push(row.projects)
          hours.push(row.totalHours)
        })
      }
          // this.dataAvailable=true
        return {
        labels: labels,
        datasets: [
          {
            label: 'Inkoop',
            backgroundColor: '#f87979',
            data: inkoop
          },{
            label: 'Verkoop',
            backgroundColor: '#74aaaa',
            data: verkoop            
          },{
            label: 'Marge',
            backgroundColor: '#2274aa',
            data: marge            
          },{
            label: 'Projects',
            backgroundColor: '#aa7422',
            data: projects            
          },{
            label: 'Uren',
            backgroundColor: '#f87422',
            data: hours            
          }      
        ]}
    }, 
    chartDataCompany() {
        let labels =[]
        let inkoop = []
        let verkoop = []
        let marge = []
        let zzprs = []
        let hours =[]

        let data = this.dashboardcompany
        data.sort((a, b) => a.companyname > b.companyname && 1 || -1)
        if(data) {
        data.forEach(row => {
          labels.push(row.companyname)
          inkoop.push(row.inkoop)
          verkoop.push(row.verkoop)
          marge.push(row.marge)
          zzprs.push(row.projects)
          hours.push(row.totalHours)

        })
      }
          // this.dataAvailable=true
        return {
        labels: labels,
        datasets: [
          {
            label: 'Inkoop',
            backgroundColor: '#f87979',
            data: inkoop
          },{
            label: 'Verkoop',
            backgroundColor: '#74aaaa',
            data: verkoop            
          },{
            label: 'Marge',
            backgroundColor: '#2274aa',
            data: marge            
          },{
            label: 'ZZP-ers',
            backgroundColor: '#aa7422',
            data: zzprs            
          },{
            label: 'Uren',
            backgroundColor: '#f87422',
            data: hours            
          }        
        ]}
    },      
    dashboardzzpr() {
      let values = [];
      if (this.startw) {
        let startyear = this.startw.substring(0, 4)
        let startweek = parseInt(this.startw.substring(6))
        var d = new Date(startyear, 0, 1);
        d.setDate(d.getDate() + (startweek * 7));
        var startdate = this.getMonday(d).setHours(0,0,0)
        let endyear = this.endw.substring(0, 4)
        let endweek = parseInt(this.endw.substring(6))
        d = new Date(endyear, 0, 1);
        d.setDate(d.getDate() + (endweek * 7));
        var enddate = this.getMonday(d.getTime() + 7 * 24 * 60 * 60 * 1000).setHours(0,0,0)
        this.users.filter(user => user.role=='user').forEach(user =>{
          let row = {};
          row.user_id = user.id
          row.lastname = user.lastname
          row.totalHours = 0
          row.inkoop = 0
          row.verkoop = 0
          row.numberhours = 0
          let contracts_user = this.contracts.filter(contract => 
                contract.user_id == user.id &&
                new Date(this.planning_by_id(this.contract_by_id(contract.id).planning_id).startdate).setHours(0,0,0)<=enddate &&
                new Date(this.planning_by_id(this.contract_by_id(contract.id).planning_id).enddate).setHours(0,0,0)>=startdate 
              )

          row.projects =  contracts_user.length
          contracts_user.forEach(contract =>{
            let contracthours = 0
            let numberhours = 0
            this.hours.filter(hours => hours.contract_id == contract.id && hours.user_id == user.id && this.allhours.includes(hours.workdate)).forEach(hours =>{
              if(!isNaN(hours.hours)){
                contracthours += parseFloat(hours.hours)
                row.contract_id = hours.contract_id
              }
              numberhours++
            }) 
            row.totalHours+=contracthours
            row.numberhours+=numberhours
            if(!isNaN(this.planning_by_id(this.contract_by_id(contract.id).planning_id).tarief)) {
              row.inkoop += contracthours*parseFloat(this.planning_by_id(this.contract_by_id(contract.id).planning_id).tarief)
            }
            if(!isNaN(this.planning_by_id(this.contract_by_id(contract.id).planning_id).tarief)) {
              row.verkoop += contracthours*parseFloat(this.planning_by_id(this.contract_by_id(contract.id).planning_id).verkooptarief)
            }

          })
          row.marge = row.verkoop-row.inkoop
          row.gemmarge = row.marge/row.totalHours
          if(row.totalHours>0) {
            values.push(row)
          }
        })
      }
      this.cleartotals()
      values.forEach(row =>{
        this.totalInkoop += row.inkoop
        this.totalVerkoop += row.verkoop
        this.totalHours += row.totalHours
      })
      return values//.sort((a, b) => (a.projects > b.projects) ? -1 : ((b.projects > a.projects) ? 1 : 0))
    },
    dashboardcompany(){
      let values = [];
      if (this.startw) {
        this.companies.forEach(company =>{
          let row = {};
          row.company_id = company.id
          row.companyname = company.company_name
          row.totalHours = 0
          row.inkoop = 0
          row.verkoop = 0
          row.numberhours = 0
          row.projects = 0
          row.zzprs = []
          this.projects.filter(project => project.company_id == company.id).forEach(project =>{
            this.contracts.filter(contract => contract.project_id == project.id && contract.user_id!=null && contract.planning_id !=null).forEach(contract =>{
              let contracthours = 0
              let numberhours = 0
              this.hours.filter(hours => hours.contract_id == contract.id && hours.user_id == contract.user_id && this.allhours.includes(hours.workdate)).forEach(hours =>{
                if(!isNaN(hours.hours)){
                  contracthours += parseFloat(hours.hours)
                  numberhours++
                }
              }) 
              if(contracthours>0){
                row.projects++
              }
              row.totalHours+=contracthours
              row.numberhours+=numberhours
              // console.log(contracthours, parseFloat(this.planning_by_id(this.contract_by_id(contract.id).planning_id).tarief))
              if(!isNaN(parseFloat(this.planning_by_id(this.contract_by_id(contract.id).planning_id).tarief))) {
                row.inkoop += contracthours*parseFloat(this.planning_by_id(this.contract_by_id(contract.id).planning_id).tarief)
              } else{
                console.log("else inkoop",this.planning_by_id(this.contract_by_id(contract.id).planning_id).tarief)
              }
              if(!isNaN(parseFloat(this.planning_by_id(this.contract_by_id(contract.id).planning_id).verkooptarief))) {
                row.verkoop += contracthours*parseFloat(this.planning_by_id(this.contract_by_id(contract.id).planning_id).verkooptarief)
              }else{
                console.log("else verkooptarief",this.planning_by_id(this.contract_by_id(contract.id).planning_id).verkooptarief)
              }
            })
          })
          row.marge = row.verkoop-row.inkoop
          row.gemmarge = row.marge/row.totalHours
          if(row.totalHours>0) {
            values.push(row)
          }           
        })

      }
      this.cleartotals()
      values.forEach(row =>{
        // console.log(row.inkoop, row.verkoop, row.totalHours)
        this.totalInkoop += row.inkoop
        this.totalVerkoop += row.verkoop
        this.totalHours += row.totalHours
      })      
      return values
    },

    static_weekdays() {
      return this.allhours
    },
    zzpritems() {
        return [
          { key: 'lastname', label: this.$i18n.t('tableheaders.name'),sortable: true, sortDirection: 'desc' },
          { key: 'projects', label: this.$i18n.t('titles.projects'), sortable: true, sortDirection: 'desc' },
          { key: 'totalHours', label: this.$i18n.t('labels.dashhours'), sortable: true, sortDirection: 'desc' },
          { key: 'inkoop', label: this.$i18n.t('labels.purchase'), sortable: true, sortDirection: 'desc' },
          { key: 'verkoop', label: this.$i18n.t('labels.sale'), sortable: true, sortDirection: 'desc' },
          { key: 'marge', label: this.$i18n.t('labels.dashmarge'), sortable: true, sortDirection: 'desc' },
          { key: 'gemmarge', label: this.$i18n.t('labels.dashgemmarge'), sortable: true, sortDirection: 'desc' },
        ]
    },
    companyitems() {
        return [
          { key: 'companyname', label: this.$i18n.t('tableheaders.name'),sortable: true, sortDirection: 'desc' },
          { key: 'projects', label: this.$i18n.t('labels.ZZPer'), sortable: true, sortDirection: 'desc' },
          { key: 'totalHours', label: this.$i18n.t('labels.dashhours'), sortable: true, sortDirection: 'desc' },
          { key: 'inkoop', label: this.$i18n.t('labels.purchase'), sortable: true, sortDirection: 'desc' },
          { key: 'verkoop', label: this.$i18n.t('labels.sale'), sortable: true, sortDirection: 'desc' },
          { key: 'marge', label: this.$i18n.t('labels.dashmarge'), sortable: true, sortDirection: 'desc' },
          { key: 'gemmarge', label: this.$i18n.t('labels.dashgemmarge'), sortable: true, sortDirection: 'desc' },
        ]
    },    
  },
  methods: {
    // onClickLegend(e){
    //   // console.log("onclick", e)

    // },
    cleartotals(){
      this.totalInkoop = 0
      this.totalVerkoop =0
      this.totalHours = 0
    },
    userinfo(row) {
      return '<a href="/#/user/' + row.item.user_id + '" :style="{ cursor: \'pointer\'}" class="link-item"> ' + this.username_by_id(row.item.user_id) + '</a><br/>' +
        '<a href="tel:' + this.user_by_id(row.item.user_id).mobile + '" class="link-item">' + this.user_by_id(row.item.user_id).mobile + '</a>'
    },
    companyinfo(row) {
      return '<a href="/#/company/' + row.item.company_id + '" :style="{ cursor: \'pointer\'}" class="link-item"> ' + this.company_by_id(row.item.company_id).company_name + '</a><br/>'
    },
    ...mapActions([
      'delete_user',
      'save_user'
    ]),
    thisweekdays() {
      let startyear = this.startw.substring(0, 4)
      let startweek = parseInt(this.startw.substring(6))
      var d = new Date(startyear, 0, 1);
      if(d.getDay()==1){
        d.setDate(d.getDate() + ((startweek-1) * 7));

      } else {
        d.setDate(d.getDate() + (startweek * 7));

      }
      var startdate = this.getMonday(d)
      let endyear = this.endw.substring(0, 4)
      let endweek = parseInt(this.endw.substring(6))
      d = new Date(endyear, 0, 1);
      if(d.getDay()==1){
        d.setDate(d.getDate() + ((endweek-1) * 7));
      }else{
        d.setDate(d.getDate() + (endweek * 7));

      }
      // console.log("!", d)
      var enddate
      if(d.getDay()==1){
        enddate = new Date(d.getTime() + 7 * 24 * 60 * 60 * 1000)
      } else{
        enddate = this.getMonday(d.getTime() + 6 * 24 * 60 * 60 * 1000)
      }
      
      // console.log("@", enddate)
      var loop = new Date(startdate);
      let alldates = []
      // console.log("Period",loop, enddate)
      while (loop < enddate) {
        let thisday = this.dateToYYYYMMDD(loop)
        var newDate = loop.setDate(loop.getDate() + 1);
        alldates.push(thisday)
        loop = new Date(newDate);
      }
      // console.log(">ALLDATES",alldates)
      return alldates
    },
    reloadData(){
      this.startw = this.startwl
      this.endw = this.endwl

      this.allhours = this.thisweekdays()
      let startyear = this.startw.substring(0, 4)
      let startweek = parseInt(this.startw.substring(6))
      var d = new Date(startyear, 0, 1);
      if(d.getDay()==1){
        d.setDate(d.getDate() + ((startweek-1) * 7));

      } else {
        d.setDate(d.getDate() + (startweek * 7));

      }
      var startdate = this.getMonday(d)
      console.log(">>", startdate)
      let now = new Date()
      let weeksago = Math.round(Math.abs(startdate-now)/(7*24*60*60*1000))
      this.$store.commit('weekshistory', weeksago);

      // let sweek = this.getWeekNumber(now)
      // console.log(startweek, sweek, sweek[1]-startweek)
      // console.log(this.startw, now.getFullYear())
      // if( (sweek[1]-startweek)<0){
      //   this.$store.commit('weekshistory', (52+(sweek[1]-startweek)));

      // } else{
      //   this.$store.commit('weekshistory', sweek[1]-startweek);

      // }
      this.$store.dispatch('get_hours')
      this.cleartotals();

    }

  },
  watch: {
  //   startw: function () {
  //     this.allhours = this.thisweekdays()
  //     let startweek = parseInt(this.startw.substring(6))

  //     let now = new Date()
  //     let sweek = this.getWeekNumber(now)
  //     // console.log(startweek, sweek, sweek[1]-startweek)
  //     if( (sweek[1]-startweek)<0){
  //       this.$store.commit('weekshistory', (52+([1]-startweek)));

  //     } else{
  //       this.$store.commit('weekshistory', sweek[1]-startweek);

  //     }
  //     this.$store.dispatch('get_hours')
  //     this.cleartotals();

  //   },
  //   endw: function () {
  //     this.allhours = this.thisweekdays()
  //     this.cleartotals();
  //   },
    bycompany: function(){
      this.cleartotals();
    }
  },
  components:{ Bar, LineChartGenerator}
}
</script>
    