<template>
  <b-container fluid>
    <h1>{{$t('buttons.portalusage')}}</h1>
    <b-tabs v-model="step" >
      <b-tab>
        <template v-slot:title>{{ $t("labels.settings") }}</template>
        <b-form-group :label="$t('meta.invoice')">
          <b-form-input type="number" class="form-control" v-model="settings.usageinvoicecounter" />
        </b-form-group> 
        <b-form-group :label="$t('labels.usagecosts')">
          <b-form-input type="number" class="form-control" v-model="settings.usagecosts" />
        </b-form-group>      
        <b-form-group :label="$t('labels.usageconditions')">
          <textarea style="width:100%; height:50px"  v-model="settings.usageconditions"></textarea>
        </b-form-group>   
        <b-form-checkbox  v-model="settings.usageheader">{{ $t("labels.usageheader") }}</b-form-checkbox>

        
        <!-- Purchase invoice -->
        <h2 class="mt-5">{{$t('templates.usage')}}</h2>
        <p>{{$t('templates.usageexplain')}}</p>
        <b-form-group :label="$t('templates.subject')">
          <b-form-input type="text" class="form-control" v-model="settings.usagesubject" />
        </b-form-group>
        <b-form-group :label="$t('templates.message')">
          <ckeditor :editor="editor" v-model="settings.usagemessage" :config="editorConfig"></ckeditor>
        </b-form-group>
        <b-form-group :label="$t('labels.sendcc')">
          <b-form-input type="text" class="form-control" v-model="settings.usagecc" />
        </b-form-group>    
        <!-- Save button -->
        <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
          <font-awesome-icon icon="plus" /> {{ $t("buttons.savesettings") }}
        </div>           
      </b-tab>
      <b-tab>
        <template v-slot:title>{{ $t("labels.period") }}</template>
        <b-form-group :label="$t('labels.startweek')">
          <b-form-input type="number" class="form-control" v-model="startweek" />
        </b-form-group>  
        <b-form-group :label="$t('labels.numberofweeks')">
          <b-form-input type="number" class="form-control" v-model="numberofweeks" />
        </b-form-group>
        <b-form-group :label="$t('labels.description')">
          <b-form-input type="text" class="form-control" v-model="settings.usagedescription" />
        </b-form-group>  
        <!-- Save button -->
        <div @click="save_period" class="btn btn-primary d-table ml-auto mt-5">
          <font-awesome-icon icon="plus" /> {{ $t("buttons.savesettings") }}
        </div>           

      </b-tab>
      <b-tab>
        <template v-slot:title>{{ $t("labels.ZZPer") }} ({{ zzpers.filter(z =>contractsinperoid.indexOf(z.id)!=-1).length }})</template>

        <div @click="select_all()" class="btn btn-primary">
          <font-awesome-icon icon="plus" /> {{$t('buttons.selectall')}}
        </div>
        <div @click="select_none()" class="btn btn-primary">
          <font-awesome-icon icon="minus" /> {{$t('buttons.selectnone')}}
        </div>
        <div class="row">
          <div v-for="user in zzpers.filter(z =>contractsinperoid.indexOf(z.id)!=-1)" :key="'user'+user.id" class="col-3" >
            <div class="row">
              <div class="col-9" style="overflow: hidden;">
                <b-form-checkbox v-model="user.alert" >
                  {{user.fullname}}
                </b-form-checkbox>
              </div>
              <div class="col-1">
                <font-awesome-icon v-if="hasmatchinginvoices(user)" icon="exclamation" color="red"/> 
                <font-awesome-icon v-else icon="check" color="green" /> 
              </div>
              <div class="col-1">
                <a href="#" @click="test(user.id)" class="action-item">
                  <font-awesome-icon icon="eye" /> 
                </a>
                </div>
            </div>

          </div>
        </div>        
        <div @click="email_invoices()" class="btn btn-primary">
          <font-awesome-icon icon="envelope" v-if="generate_inkoop==false"/>
          <b-spinner small v-else></b-spinner>
          {{ $t("buttons.emailinvoices") }}
        </div>
   
      </b-tab>
      <b-tab>
        <template v-slot:title>{{ $t('titles.invoices') }} </template>
        <b-table sort-icon-left  :items="usage_invoices" :fields="tablefields"  :current-page="currentPage" :per-page="perPage"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
          <template #cell(user_id)="row">
            {{username_by_id(row.item.user_id)}}
          </template>   
          <template #cell(actions)="row">
            <a href="#" @click="openinvoice(row)" class="action-item">
              <font-awesome-icon icon="eye" /> 
            </a>
          </template>       
        </b-table>
        <b-pagination v-model="currentPage" :total-rows="usage_invoices.length" :per-page="perPage"></b-pagination>

      </b-tab>
    </b-tabs>
    <input type="hidden" v-model="redraw">
    </b-container>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapState} from 'vuex'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: 'navigation',
  data() {
    return {
      // description: "",
      startweek:0,
      numberofweeks:4,
      redraw:0,
      generate_inkoop:false,
      step:0,
      editor: ClassicEditor,
      usage_invoices:[],
      currentPage: 1,
      perPage: 10,
      sortBy: 'invoice_id',
      sortDesc: false,
      sortDirection: 'desc',      
      editorConfig: {
        toolbar: {
          items:[ 'heading', '|', 'undo', 'redo', '-', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ,'insertTable'],
          shouldNotGroupWhenFull: true
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
          ]
        },
        table: {
            contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        }
      },      
    }
  },
  mounted(){
    this.startweek = this.getWeekNumber(new Date())[1]
    axios.get('/api/usage_invoices')
    .then((response) => {
          this.usage_invoices = response.data
        })
  },
  computed:{
    ...mapGetters([
      'project_by_id',
      'username_by_id',
      'zzpers',

    ]),
    ...mapState([
    'settings',
    'contracts',
    'planning'

    ]),
    tablefields(){
      return [
        { key: 'invoice_id', label: this.$i18n.t('labels.invoice_number'),  sortable: true, sortDirection: 'desc' },
        { key: 'user_id', label: this.$i18n.t('labels.name'), sortable: true, sortDirection: 'desc' },
        { key: 'week', label: this.$i18n.t('labels.week'), sortable: true, sortDirection: 'desc' },
        { key: 'actions', label: this.$i18n.t('labels.actions') },

      ]    },
    mondaystartweek(){
      let onejan = new Date(new Date().getFullYear(), 0, 1);
      var day = onejan.getDay(),
      diff = onejan.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      let monday = new Date(onejan.setDate(diff))
      monday.setDate(monday.getDate() + (7 * parseInt(this.startweek)));
      return monday
      // var mdd = String(monday.getDate()).padStart(2, '0');
      // var mmm = String(monday.getMonth() + 1).padStart(2, '0'); //January is 0!
      // var myyyy = monday.getFullYear();   
      // return mdd+"-"+mmm+"-"+myyyy   
    },  
    fridayendweek(){
      let endmonday = new Date(this.mondaystartweek)
      endmonday.setDate(endmonday.getDate()+(this.numberofweeks*7)-1)
      return endmonday
    } ,
    contractsinperoid(){
      let zzpers = [...new Set(this.planning.filter(c => new Date(c.startdate)<=this.fridayendweek && new Date(c.enddate)>= this.mondaystartweek).map(item => item.user_id))]

      return  zzpers
    }

  },
  methods:{
    save_settings() {
      try {
        axios.patch('/api/settings/',
          {
            usageinvoicecounter: this.settings.usageinvoicecounter,
            usagecosts: this.settings.usagecosts,
            usagecc: this.settings.usagecc,
            usagemessage:this.settings.usagemessage,
            usagesubject:this.settings.usagesubject,
            usageheader: this.settings.usageheader,
            usageconditions:this.settings.usageconditions
          },
        )
          .then((response) => {
            console.log(response)
          })
      } catch (err) {
        console.log(err)
      }
      this.step = 1
    },
    save_period(){
      try {
        axios.patch('/api/settings/',
          {
            usagedescription:this.settings.usagedescription
          },
        )
          .then((response) => {
            console.log(response)
          })
      } catch (err) {
        console.log(err)
      }
      this.step = 2
    },
    async email_invoices(){
      this.generate_factuur= true
      let generate_for =  this.zzpers.filter(z =>this.contractsinperoid.indexOf(z.id)!=-1 && z.alert== true)
      let nowYYYYMMDD = this.dateToYYYYMMDD(new Date())
      for await (const zzper of generate_for) {
        try {
          await axios.post('/api/usage_invoice',{
            invoice_id:this.settings.usageinvoicecounter,
            user_id:zzper.id, 
            force:true,
            week:parseInt(this.startweek),
            numberofweeks:parseInt(this.numberofweeks),
            omschrijving: this.settings.usagedescription
          }).then(async () => {
            let message = {}
            message.email = zzper.email
            message.name = zzper.fullname
            message.message = this.settings.usagemessage
            message.message = message.message.replaceAll(/\[week\]/gi, this.startweek)
            message.message = message.message.replaceAll(/\[first_name\]/gi, zzper.firstname)

            message.subject = this.settings.usagesubject
            message.subject = message.subject.replaceAll(/\[first_name\]/gi, zzper.firstname)
            message.subject = message.subject.replaceAll(/\[week\]/gi, this.startweek)
            // add default cc
            if(this.settings.usagecc){
              message.cc = []
              message.cc.push(this.settings.usagecc)
            }
            message.maindocuments = []
            message.maindocuments.push({ file: '/invoices/usage_invoice-' + this.settings.usageinvoicecounter + "-" + zzper.id + ".pdf", name: "Factuur" });
            try {
                await axios.post('/api/contact_user', message).then((response) => {
                  // if (response.status == 200) {
                    console.log("mailsent",response)
                  })
              } catch (err) {
                console.log(err)
              }
            })
            //save invoice
         
            await axios.put("/api/usage_invoice/", {
              invoice_id:this.settings.usageinvoicecounter,
              user_id:zzper.id, 
              week:this.startweek,
              numberofweeks:this.numberofweeks,
              invoice_date:nowYYYYMMDD
            }).then((response) => {
              this.usage_invoices.push(response.data)
            })

          } catch (err) {
            console.log(err)
          }      
          this.settings.usageinvoicecounter++

      //   await axios.post('/api/usage_invoice',{
      //     invoice_id:this.settings.usageinvoicecounter,
      //     user_id:zzper.id, 
      //     force:true,
      //     week:this.startweek,
      //     numberofweeks:this.numberofweeks,
      //     omschrijving: this.description
      // })
      }
      axios.patch('/api/settings/',
          {usageinvoicecounter: this.settings.usageinvoicecounter})     
    },
    async test(uid) {
      try {
          await axios.post('/api/usage_invoice',{
            invoice_id:this.settings.usageinvoicecounter,
            user_id:uid, 
            force:true,
            week:parseInt(this.startweek),
            numberofweeks:parseInt(this.numberofweeks),
            omschrijving: this.settings.usagedescription
          }).then(async () => {
            let routeUrl = this.server_url + '/media_invoices/usage_invoice-' + this.settings.usageinvoicecounter + "-" + uid + ".pdf"
            setTimeout(function () {
              window.open(routeUrl, '_blank')
            }, 100);            
          })
        }
        catch {console.log("error in test")}
    },
    openinvoice(row){
      let routeUrl = this.server_url + '/media_invoices/usage_invoice-' + row.item.invoice_id + "-" +  row.item.user_id + ".pdf"
            setTimeout(function () {
              window.open(routeUrl, '_blank')
            }, 100);  
    },
    select_all() {
      this.zzpers.filter(z =>this.contractsinperoid.indexOf(z.id)!=-1).forEach(u => {
        u.alert = true
        console.log("u.fu", u.fullname)
      })
      this.redraw++
    },
    select_none() {
      this.zzpers.filter(z =>this.contractsinperoid.indexOf(z.id)!=-1).forEach(u => {
        u.alert = false
      })
      this.redraw++
    }, 
    hasmatchinginvoices(user){
      let currentperiod = [];
      for (var i = parseInt(this.startweek); i<parseInt(this.startweek)+parseInt(this.numberofweeks); i++){
        currentperiod.push(i)
      }
      let earlierperiods = [];
      this.usage_invoices.filter(ui => ui.user_id==user.id).forEach(ui=>
      {
        for (var j=ui.week;j<ui.week+ui.numberofweeks;j++){
          if(earlierperiods.indexOf(j)==-1){
            earlierperiods.push(j)
          }
        }
      })
      const hasmatch = currentperiod.filter(value => earlierperiods.includes(value));

      return hasmatch.length!=0
    }     ,
    async generate_factuur(user_id){
      this.generate_inkoop = true
      await axios.post('/api/usage_invoice',{
        invoice_id:this.settings.usageinvoicecounter,
        user_id:user_id, 
        force:true,
        week:this.startweek,
        numberofweeks:this.numberofweeks,
        omschrijving: this.settings.usagedescription
      })
      this.generate_inkoop = false


    }
  }
}
</script>