<template>
	<div class="popup">
        <div class="popup-header">
            {{ $t("buttons.addcontract") }}
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body" style="min-height:300px">
          <input type="hidden" :key="componentKey" />
            <b-container>
                <div v-if="project_id==null">
                    {{ $t("titles.select_project") }}
                    <v-select label="title"  :options="public_projects" class="contactpersoon" v-model="newproject"></v-select>
                    <div v-if="preview_project_id!=null">
                        <h3>{{previewproject.title}}</h3>
                        {{previewproject.description}}<br/>
                        {{previewproject.startdate}} - {{previewproject.enddate}} @ {{previewproject.location}}<br/>
                        <strong>{{this.company_by_id(previewproject.company_id).company_name}}</strong><br/>
                        <b-button variant="primary" @click="add_contract()"><font-awesome-icon icon="plus"/> {{ $t("labels.addcontract") }}</b-button>

                    </div>
                </div>
                <b-tabs v-if="project_id!=null" v-model="tab">
                    <b-tab  :title="this.planningtitle" ref="planningstab" lazy>
                        <ProjectZZPerPlanning v-bind:project_id="project_id" ref="planning" @change="planningcheck"></ProjectZZPerPlanning>
                    </b-tab> 
                </b-tabs>
            </b-container>
        </div>

        <div class="popup-footer">
          <div v-if="project_id!=null" @click="save_and_close" class="btn btn-primary">{{ addcontractText() }}</div>

        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import ProjectZZPerPlanning from './settings-screens/ProjectZZPerPlanning'

export default {
    name: 'project-zzper-modal',
    data() {
        return {
            tab:0,
            startdate:null,
            newproject:null,
            project_id:null,
            preview_project_id:null,
            selectedproject:null,
            componentKey:0,

        }        
    },
    props: {
        user_id: Number
    },    
    mounted() {
        this.onMounted();
    },


    computed: {
        project() {
            return this.project_by_id(this.project_id) 
        },
        previewproject(){
            return this.project_by_id(this.preview_project_id) 


        },
        ...mapGetters([
            'project_metas',
            'project_by_id',
            'project_new',
            'selectedZZPr',
            'user_by_id',
            'username_by_id',
            'company_by_id'
        ]),
        ...mapState([
            'projects',
            'settings',
            'companies',
        ]),
        public_projects(){
            let projs =[];
            for (let index = 0; index < this.projects.length; index++) {
                if(this.projects[index].ispublic===1){
                    let previewproj={}
                    previewproj.id = this.projects[index].id
                    previewproj.title = this.company_by_id(this.projects[index].company_id).company_name+" - "+ this.projects[index].title
                    if(this.projects[index].title.indexOf("CREDIT")==-1)
                        projs.push(previewproj);
                }
            }
            projs.sort(this.titlesort)
            return projs
        },

        planningtitle() {
            if(this.selectedZZPr){
                return this.username_by_id(this.selectedZZPr)
            } else { 
                return ""
            }
        },

    },
    watch :{
        planningtitle:function(){
        //    this.tab=1
        },
        searchZZPR:function(){
        //  this.tab=1
        },
        newproject:function(){
         this.preview_project_id=this.newproject.id

        }
    },

    methods: {
        planningcheck(){
            this.componentKey++
        },        
        addcontractText(){
            if (this.$refs.planning && this.$refs.planning.validated()==true){
                return this.$i18n.t("buttons.add") 
            } else { 
                return this.$i18n.t("buttons.invalid") 
            }
        },
        add_contract(){
            this.project_id = this.preview_project_id
        },
        onMounted() {
            this.$store.commit('selectedZZPr',this.user_id);
        },
        translate(path){
            return this.$i18n.t('meta.'+path.toLowerCase())
        },      
        titlesort( a, b ) {
            if ( a.title < b.title ){
            return -1;
            }
            if ( a.title > b.title ){
            return 1;
            }
            return 0;
        },  
        ...mapActions([
            'save_project',
            'add_planning'
        ]),
        close_modal() {
            this.$store.commit('new_planning',{ id: 0 , project_id:0, user_id:0, pointcontact_id:0,sign_id:0})
            this.$emit('close')
        },  
        async save_and_close() {
            if (this.$refs.planning && this.$refs.planning.validated()==true){
                if(this.selectedZZPr!=null){
                    console.log("start save")
                    await this.$refs.planning.save_data();
                    console.log("done save")
                    if(this.project.zzprusers.indexOf(this.selectedZZPr)==-1){
                        var datatosendrel={project_id:this.project.id, user_id:this.selectedZZPr}
                        this.$store.dispatch('save_project_relations',datatosendrel);
                        this.project.zzprusers.push(this.selectedZZPr)
                        console.log("add zzper to project")
                    }
                }
                this.$store.commit('selectedZZPr',null)
                //get new items
                console.log("reload data")
                this.$store.dispatch('get_contracts');        
                this.$store.dispatch('get_projects');
                this.$store.dispatch('get_planning');                
                this.close_modal()
            }

        }   
            
    },
    components:{ProjectZZPerPlanning}
}
</script>