<template>
  <b-modal id="BSSignContractID" centered size="lg" @show="showMe">
    <template #modal-header>
      <h2 class="modal-title">{{ $t("buttons.sign") }} {{username_by_id(user_id)}} {{ planning_by_id(contract_by_id(contract_id).planning_id).project_name}}</h2>
    </template>
    <div class="container">

      <div class="form-row" v-if="false">
        <div class="col-4">
          {{ $t('labels.sign_status') }}
        </div>
        <div class="col">
          <b-form-input type=text class="form-control" v-model="signed_by" />
        </div>
      </div>
      <VueSignaturePad style="border:solid" 
          id="signature"
          width="100%"
          height="300px"

          ref="signaturePad"
        />
    </div>
    
    

<template #modal-footer>
  <button class="btn btn-secondary" v-if="!isEmpty()" @click="empty()">{{ $t('buttons.clear') }}</button>
  <button class="btn btn-secondary" v-if="!isEmpty()" @click="saveSignature()"><font-awesome-icon icon="signature" />{{ $t("buttons.sign") }}</button>

  <span v-if="isEmpty()">{{$t('validation.signforagree')}}</span>
  <button class="btn btn-secondairy" @click="close_modal">{{ $t( "buttons.close") }}</button>
</template>

  </b-modal>
</template>


<script>
import axios from 'axios';
import { mapGetters,mapActions } from 'vuex'

export default {
  name: 'signature-modal',
  data() {
    return {
      signed_by:"",
      filter:"",
      modalshown:false
    }
  },
  props:{
    user_id:Number,
    contract_id:Number
  },
  async mounted() {  
  },

  computed: {
    ...mapGetters([
    'contract_by_id',
    'username_by_id',
    'planning_by_id'

    ]),

  },
  methods: {
    ...mapActions([ 
    ]),
    signatureImage(){
      return "/api/signcontract/"+this.signature.user_id+"-"+this.signature.contract_id+".png"
    },
    isEmpty(){
      if(this.$refs.signaturePad){
        const { isEmpty } = this.$refs.signaturePad.saveSignature();
        return isEmpty;
      } else {
         return false
        }
    },
    empty(){
      this.$refs.signaturePad.clearSignature()

    },
    showMe(){
      setTimeout(this.prepareCanvas, 150)
    },
    prepareCanvas(){
      // var vm = this
      this.$nextTick(() => {
        this.$refs.signaturePad.resizeCanvas()
        // this.$nextTick(() => {
        // if(this.signature.id!=0){
        //   fetch(this.signatureImage())
        //   .then((raw) => raw.blob())
        //   .then(blob => {
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //       this.$refs.signaturePad.fromDataURL(reader.result)
        //     }
        //     reader.readAsDataURL(blob)

        //   })
        // }
        // })
      })
    },
    async saveSignature(){
      const { data } = this.$refs.signaturePad.saveSignature();
      let clone = {}
      clone.data = data
      clone.user_id = this.user_id
      clone.contract_id = this.contract_id
      await axios.put('/api/signcontract/', clone)
      .then((response) => {
            console.log(response)
            this.$emit('saveDone', response)
          })
      this.close_modal()
    },
    close_modal() {

      this.$bvModal.hide('BSSignContractID')
    },
  },
}    
</script>
