import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);
const messages = {
  'en': require('./locales/en.json'),
  'nl': require('./locales/nl.json'),
  'pl': require('./locales/pl.json'),
};

const i18n = new VueI18n({
  locale: 'nl', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
});
export default i18n