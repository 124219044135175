<template>

  <b-container fluid :style="AppSecondaryColor()" class="apppage">
    <b-button-group vertical>
      <b-button :style="AppPrimaryButton()" active-class="active" @click="$router.go(-1)">
        <font-awesome-icon icon="chevron-left" /> {{ $t("titles.registeras") }}
      </b-button>
    </b-button-group>
    <div v-html="settings.registerpage"/> 
    <form v-if="!send" class="form" @submit="onSubmit">
      <div class="form-row">
        <b-form-group>
          <b-form-radio v-model="sex" value="man">{{ $t("meta.aanhefman") }}</b-form-radio>
          <b-form-radio v-model="sex" value="vrouw">{{ $t("meta.aanhefvrouw") }}</b-form-radio>
        </b-form-group>
      </div>
      <div class="form-row">
          <strong>{{ $t("labels.workfield") }}</strong>
      </div>
      <div class="form-row">
        <b-form-group>
          <b-form-radio v-model="vakgebied" value="timmerman">Timmerman</b-form-radio>
          <b-form-radio v-model="vakgebied" value="overig">Overig</b-form-radio>
        </b-form-group>
      </div>
      <div class="form-row">
        <div class="col-3">
          <label for=""> {{ $t("labels.firstname") }}</label>
        </div>
        <div class="col">
          <b-form-input type="text" class="form-control" v-model="firstname" required/>
        </div>
      </div>    
      <div class="form-row">
        <div class="col-3">
          <label for=""> {{ $t("labels.middlename") }}</label>
        </div>
        <div class="col">
          <b-form-input type="text" class="form-control" v-model="middlename" />
        </div>
      </div>    
      <div class="form-row">
        <div class="col-3">
          <label for=""> {{ $t("labels.lastname") }}</label>
        </div>
        <div class="col">
          <b-form-input type="text" class="form-control" v-model="lastname" required/>
        </div>
      </div>    
      <div class="form-row">
        <div class="col-3">
          <label for=""> {{ $t("meta.woonplaats") }}</label>
        </div>
        <div class="col">
          <b-form-input type="text" class="form-control" v-model="city" />
        </div>
      </div>    
      <div class="form-row">
        <div class="col-3">
          <label for=""> {{ $t("labels.email") }}</label>
        </div>
        <div class="col">
          <b-form-input type="email" class="form-control" v-model="email" required/>
        </div>
      </div>    
      <div class="form-row">
        <div class="col-3">
          <label for=""> {{ $t("tableheaders.mobile") }}</label>
        </div>
        <div class="col">
          <b-form-input type="tel" class="form-control" v-model="mobile" required/>
        </div>
      </div>              
      <div class="form-buttons">
        <b-button :style="AppPrimaryButton()" type="submit" variant="primary">
          {{ $t('buttons.sendmessage') }} <font-awesome-icon icon="arrow-right" />
        </b-button>
      </div>

    </form>
    <div v-if="send" v-html="settings.registerthankyou">
    </div>
  </b-container>
</template>
<script>
import axios from 'axios';
import {  mapState, mapGetters } from 'vuex'

export default {
  name: 'register',
  data() {
    return {
      sex : "man",
      vakgebied : "timmerman",
      firstname:"",
      lastname:"",
      middlename:"",
      city:"",
      email:"",
      mobile:"",
      send:false
    }
  },
  mounted() {

  },
  computed: {
    
    ...mapState([
      'settings',
    ]),
    ...mapGetters([
    ]),

  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let data = {}
      data.Sex = this.sex
      data.Vakgebied = this.vakgebied
      data.Firstname = this.firstname
      data.Middlename = this.middlename
      data.Lastname = this.lastname
      data.City = this.city
      data.Email = this.email
      data.Mobile = this.mobile
      let message ={}
      message.message = "ZZPer Register:<br>";
      var key
      for (key in data) {
        message.message += key + " : <strong> " + data[key]+"</strong><br>"
      }

      message.subject = "New registration from app"
      try{
          axios.post('/api/app_contact', message)  .then((response) => {
              console.log('succes',response)
              this.send = true
          })
      } catch(err){
          console.log (err)
      }       

      console.log(data)
      
    },    
  },
}
</script>
