<template>
  <b-container>
    <b-table :items="userplanning" :fields="fields" @row-clicked="onPlanningClicked">
      <template #cell(actions)="row">
        <a v-if="hascontract(row.item.id)!=0" href="javascript:;" class="action-item"
          @click="sign_contract(row.item.id)">
          <font-awesome-icon v-if="contract_signed(row.item.id)==2" icon="check-double" />
          <font-awesome-icon v-else-if="contract_signed(row.item.id)==1" icon="check" />
          <font-awesome-icon v-else icon="question" />
        </a>
      </template>

    </b-table>
  </b-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import contract_modal from '../ContractModal'

export default {
  name: 'contract_modal_default',
  data() {
    return {

    }
  },

  props: {
    user_id: Number,
  },
  computed: {
    fields() {
      return [
        { key: 'project_name', label: this.$i18n.t('labels.project'), sortable: true, sortDirection: 'desc' },
        { key: 'startdate', formatter: this.formatDate, label: this.$i18n.t('labels.startdate'), sortable: true, sortDirection: 'desc' },
        { key: 'enddate', formatter: this.formatDate, label: this.$i18n.t('labels.enddate'), sortable: true, sortDirection: 'desc' },
        { key: 'project_description', label: this.$i18n.t('labels.description') },
        { key: 'actions', label: this.$i18n.t('labels.actions') },
      ]
    },
    ...mapGetters([
      'project_by_id',
    ]),
    ...mapState([
      'projects',
      'planning',
      'contracts'
    ]),
    userplanning() {
      let _planning = []
      for (var i = 0; i < this.planning.length; i++) {
        if (this.planning[i].user_id == this.user_id) {
          let aplanning = this.planning[i]
          aplanning.project = this.project_by_id(this.planning[i].project_id).title
          _planning.push(aplanning)
        }
      }
      return _planning
    }
  },
  methods: {
    ...mapActions([
      'save_contract',
    ]),
    onPlanningClicked(id) {
      this.$store.commit('selectedZZPr', id.user_id)
      this.$modal.hide('contract_modal')
      this.$modal.show(contract_modal, {
        project_id: id.project_id,
        user_id: id.user_id,
      }, {
        name: 'contract_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '1000px'
      })
      this.$emit('close')
    },
    edit_zzpr_in_project(id) {
      this.$store.commit('selectedZZPr', id.user_id)
      this.$modal.hide('contract_modal')
      this.$modal.show(contract_modal, {
        project_id: id.project_id,
        user_id: id.user_id,
      }, {
        name: 'contract_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '1000px'
      })
      this.$emit('close')
    },
    hascontract(planning_id) {
      let contractid = 0
      for (var i = 0; i < this.contracts.length; i++) {
        if (this.contracts[i].planning_id == planning_id) {
          contractid = this.contracts[i].id
        }
      }
      return contractid
    },
    contract_signed(planning_id) {
      let signed = 0;
      for (var i = 0; i < this.contracts.length; i++) {
        if (this.contracts[i].planning_id == planning_id) {
          if (this.contracts[i].signed_zzpr == 1) {
            signed++
          }
          if (this.contracts[i].signed_company == 1) {
            signed++
          }
        }
      }
      return signed
    },
    sign_contract(user_id) {
      let contractid = this.hascontract(user_id)
      if (contractid != 0) {
        for (var i = 0; i < this.contracts.length; i++) {
          if (this.contracts[i].id == contractid) {
            if (this.contracts[i].signed_zzpr == 1 && this.contracts[i].signed_company == 1) {
              this.sendContractModal(contractid)
            }


          }
        }
      }
    }
  }
}
</script>