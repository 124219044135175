
<template>
    <b-container>
        <b-table :items=this.company.documents :fields=fields>
            <template #cell(link)="row">
                <strong v-if="row.item.required==1" style="color:red">!</strong>
                <a :href=docto(row.value) v-bind:target="'_blank'">{{row.item.name}}</a>
            </template>
            <template #cell(actions)="row">
                <a href="javascript:;" class="action-item" @click="delete_file(row.item.id)">
                    <font-awesome-icon icon="trash" />
                </a>
                <a href="javascript:;" class="action-item" @click="edit_file(row.item.id)">
                    <font-awesome-icon icon="cog" />
                </a>
            </template>
        </b-table>
        <div class="form-row">
            <div class="col-6">
                <div @click="showDocModal" class="btn btn-primary">
                    <font-awesome-icon icon="plus" /> {{ $t("buttons.adddocument") }}
                </div>
            </div>
        </div>


    </b-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import docdetails from '../DocumentModal'
import addcompanydocumentssscreen from '../AddCompanyDocumentModal.vue'

export default {
    name: 'companydocumentssscreen',
    data() {
        return {
            name: "",
            expires: null,
            file1: null
        }
    },
    props: {
        company_id: Number
    },
    mounted() {
    },
    computed: {
        company() {
            return this.company_id > 0 ? this.company_by_id(this.company_id) : this.company_new
        },
        fields() {
            return [
                { key: 'name', label: this.$i18n.t('labels.name'), sortable: true, sortDirection: 'desc' },
                { key: 'expires', formatter: this.formatDate, label: this.$i18n.t('meta.aangemaakt') },
                { key: 'link', label: this.$i18n.t('labels.link') },
                { key: 'actions', label: this.$i18n.t('labels.actions') },
            ]
        },

        ...mapGetters([
            'user_metas',
            'company_by_id',
            'activebranches',
            'user_new',
            'server_url'
        ]),
        ...mapState([
            'companies',
            'branches',
            'avatars'
        ]),

    },
    methods: {
        docto(id) {
            return this.server_url + '/media_' + id;
        },
        showDocModal() {
            this.$modal.hide('addcompanydocumentssscreen')
            this.$modal.show(addcompanydocumentssscreen, {
                company_id: this.company_id
            }, {
                name: 'addcompanydocumentssscreen',
                draggable: '.popup-header',
                height: 'auto',
                width: '600px'
            })
            this.$emit('close')
        },
        delete_file(id) {
            for (let i = 0; i < this.company.documents.length; i++) {
                if (this.company.documents[i].id == id) {
                    let id = this.company.documents[i].id
                    this.delete_company_document(id)
                    this.company.documents.splice(i, 1)
                }
            }

        },
        edit_file(id) {
            for (let i = 0; i < this.company.documents.length; i++) {
                if (this.company.documents[i].id == id) {
                    let docname = this.company.documents[i].name
                    let docdate = this.company.documents[i].expires
                    let docreq = parseInt(this.company.documents[i].required)
                    this.$modal.hide('docdetails')
                    this.$modal.show(docdetails, {
                        document_name: docname,
                        document_date: docdate,
                        document_id: i,
                        document_required:docreq
                    }, {
                        name: 'docdetails',
                        draggable: '.popup-header',
                        height: 'auto',
                        width: '600px'
                    }, {
                        'before-close': this.modalClose
                    })
                    this.$emit('close')

                }
            }
        },
        modalClose(e) {
            let newname = e.params["dname"]
            let newdate = e.params["ddate"]
            let docid = e.params["id"]
            let docreq = parseInt(e.params["dreq"])
            console.log(docreq, newname, docid)
            // if(docid) {
                this.company.documents[docid].name = newname
                this.company.documents[docid].expires = newdate
                this.company.documents[docid].required = docreq
                var datatosend = { document_id: this.company.documents[docid].id, name: newname, expire: newdate, required:docreq }
                console.log("updeed", datatosend)
                this.$store.dispatch('update_company_document', datatosend);
            // }
            //this.update_user_document(,newname, newdate)
        },
        ...mapActions([
            'save_avatar',
            'delete_company_document',
            'update_company_document'
        ]),



    }

}    
</script>
