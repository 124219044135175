<template>

  <b-container fluid :style="AppSecondaryColor()" class="apppage">
    <b-button-group vertical>
      <b-button :style="AppPrimaryButton()" active-class="active" @click="$router.go(-1)">
        <font-awesome-icon icon="chevron-left" /> {{ $t("titles.projects") }}
      </b-button>
    </b-button-group>

    <div v-html="settings.projectspage"/> 

    <!-- Table -->
    <div v-for="project in projects" :style="projectTitleAppClass()" :key="project.id" class="public_project" @click="projectInfo(project.id)">
      <h4 class="projectnumber">{{$t('meta.projectnumber')}} {{project.projectnumber}}</h4><br>
      <p class="title" :style="AppPrimaryColor()">{{project.omschrijvingapp}}</p>
      <p class="date" :style="AppPrimaryColor()">{{$t('labels.date')}}</p>
      <p>{{$t('labels.from')}} {{project.startdate}} {{$t('labels.to')}} {{project.enddate}}</p>
      <p class="location" :style="AppPrimaryColor()">{{$t('labels.location')}}</p>
      <p>{{project.location}}</p>
      <button :style="AppPrimaryColor()" class="btn btn-link" @click="projectInfo(project.id)">{{$t('buttons.moreinfo')}}</button>
      <br>
    </div>


  </b-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex'


export default {
  name: 'publicprojects',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'is_logged_in',
    ]),       
    ...mapState([
      'projects',
      'settings'
    ]),


  },
  methods: {
    projectInfo(row) {
      this.$router.push({ path: '/public_project/' + row });
    },
  },
}
</script>
