<template>
  <div>
      <div class="row planning_row planning_header"> 
        <div class="col-2"><h3>{{ $t("titles.resources") }}</h3></div>
        <div class="col-1"><b-button v-if="createAny('project')" @click="edit_resource({ id: 0 })" class="btn btn-primary"><font-awesome-icon icon="plus" /> </b-button></div>
        <div class="col-2">
              <b-form-input ref="searchbox" id="filter-input" v-model="filter" type="search" :placeholder="$t('strings.typetosearch')">
              </b-form-input>
        </div>
      </div>
      <div class="resource_section">
        <div class = "planning_row" v-for="(resource, idx)  in filter_resources" :key="'resource'+idx">
          <div class="planning_title" draggable @dragstart="startDrag($event, resource,'resource')" 
            @drop="onDropResource($event, resource.id, 0)"
            @dragover.prevent
            @dragenter.prevent            
          >{{ resource.name}} {{ resource.identification}}  <div class="planning-item" style="float:right" @click="edit_resource(resource)"> <font-awesome-icon icon="cog" /> </div>  <font-awesome-icon style="float:right;margin:5px;" :icon="resource_icon(resource.type)" :title="resource.name+' '+resource.identification"></font-awesome-icon></div>
          <div v-for="n in weeks " :key="n"  :class="divclass" 
          @drop="onDropResource($event, resource.id, n)"
          @dragover.prevent
          @dragenter.prevent >
          <div v-for="(task, idx) in waxes(n,resource)" :key="waxes+idx" draggable 
          @dragstart="dragUser($event,n, task, 'task')" 
          @click="onClickTaskResource(task)"
          :style="taskStyle(n,task,waxes(n,resource).length,idx)"
          :title="taskTooltip(n,task)">
            {{ project_by_id(task.project_id).title.substring(0,2) }}
          </div>        
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {  mapState, mapGetters} from 'vuex'
import resources_details_modal from '../modals/ResourceModal'

export default {
  name: 'resource-planning',
  data() {
    return {
      filter:"",
      resourcefilter:null

    }
  },
  props: {
    numweeks: Number,
    startdate:String
  },  
  computed:{
    ...mapState ([
      'resources',
      'ffwax',

    ]),
    ...mapGetters ([
    'createAny'
  ]),    
    filter_resources(){
      if(this.filter!=""){
        return this.resourceFiltered(this.resources).filter(p=>
        (p.name && p.name.toLowerCase().includes(this.filter)) 
        ||(p.identification && p.identification.toLowerCase().includes(this.filter)) 
        )
      } else {
        return this.resourceFiltered(this.resources)
      }
    },
    resourceFilter(items){
      if(this.workfieldfilter!=null){
        return items.filter(i => i.workfields.indexOf(this.workfieldfilter.workfield_id)!=-1)
      }
      return items

    }, 
    weeks(){
      return this.numweeks*7;
    },
    divclass(){
      return "planning_day planning_day"+this.numweeks
    },

  },
  methods:{
    waxes(n,resource){
      let day = new Date(this.startdate)
      day.setDate(day.getDate()+n-1)
      return this.ffwax.filter(f => f.resource_id ==resource.id).filter(ff => {
        var res = false
        let startdate = new Date(this.project_by_id(ff.project_id).startdate)
        startdate.setHours(0,0,0,0)
        startdate.setDate(startdate.getDate() + ff.start_offset);
        let enddate = new Date(startdate)
        enddate.setDate(enddate.getDate() + ff.end_offset);
          if(day.setHours(0,0,0,0)>=startdate &&  day.setHours(0,0,0,0)<=enddate){
            res =  true
          }
          return res
        })

    },
    filterResource(filter){
      console.log("Resource", filter)
      this.resourcefilter = filter
    },
    clearFilter(){
      this.resourcefilter = null
    },
    resourceFiltered(items){
      if(this.resourcefilter!=null){
        return items.filter(i => i.type == this.resourcefilter)
      }
      return items

    },   
    // taskStyle( n, task){
    //   let startpers = task.start_time?parseFloat(parseInt(task.start_time.split(":")[0])/24)*100:10
    //   let  endpers = task.end_time?parseFloat((parseInt(task.end_time.split(":")[0])-parseInt(task.start_time.split(":")[0]))/24)*100:80
    //       // tooltip =  this.project_by_id(ff.project_id).title + "\n"+this.dateToNL(startdate) + " - "+ this.dateToNL(enddate) +"\n"+ff.start_time + " - "+ ff.end_time +"\n"
    //   let color = this.project_by_id(task.project_id).planningcolor
    //     return "position:absolute; user-select: none;cursor:pointer; background-color:"+color+";left:"+startpers+"%; width:"+endpers+"%;"

    // },
    taskStyle( n, task, numtasks, idx){
      let startpers = task.start_time?parseFloat(parseInt(task.start_time.split(":")[0])/24)*100:10
      let  endpers = task.end_time?parseFloat((parseInt(task.end_time.split(":")[0])-parseInt(task.start_time.split(":")[0]))/24)*100:80
          // tooltip =  this.project_by_id(ff.project_id).title + "\n"+this.dateToNL(startdate) + " - "+ this.dateToNL(enddate) +"\n"+ff.start_time + " - "+ ff.end_time +"\n"
      let color = this.project_by_id(task.project_id).planningcolor
      let height = 100/numtasks;
      return "position:absolute; user-select: none;cursor:pointer; background-color:"+color+";left:"+startpers+"%; width:"+endpers+"%; height:"+height+"%; top: "+idx*height+"%;"

    },

    taskTooltip( n, task){
      let startdate = new Date(this.project_by_id(task.project_id).startdate)
        startdate.setHours(0,0,0,0)
        startdate.setDate(startdate.getDate() + task.start_offset);
        let enddate = new Date(startdate)
        enddate.setDate(enddate.getDate() + task.end_offset);
        return  this.project_by_id(task.project_id).title + "\n"+this.dateToNL(startdate) + " - "+ this.dateToNL(enddate) +"\n"+task.start_time + " - "+ task.end_time +"\n"

    },

    projectTitle(n ,resource){
      var ret = ""
    var color ="";
    var tooltip=""
    var startpers
    var endpers    
    let day = new Date(this.startdate)
      day.setDate(day.getDate()+n-1)
      this.ffwax.filter(f => f.resource_id ==resource.id).forEach(ff => {
        let startdate = new Date(this.project_by_id(ff.project_id).startdate)
        startdate.setHours(0,0,0,0)
        startdate.setDate(startdate.getDate() + ff.start_offset);
        let enddate = new Date(startdate)
        enddate.setDate(enddate.getDate() + ff.end_offset);

        if(day.setHours(0,0,0,0)>=startdate &&  day.setHours(0,0,0,0)<=enddate){
          
          let pt =  this.project_by_id(ff.project_id).title.substring(0,2)
          startpers = parseFloat(parseInt(ff.start_time.split(":")[0])/24)*100
          endpers = parseFloat((parseInt(ff.end_time.split(":")[0])-parseInt(ff.start_time.split(":")[0]))/24)*100

          tooltip =  this.project_by_id(ff.project_id).title + "\n"+this.dateToNL(startdate) + " - "+ this.dateToNL(enddate) +"\n"+ff.start_time + " - "+ ff.end_time +"\n"
          color = this.project_by_id(ff.project_id).planningcolor
          ret +="<div style='position:absolute; user-select: none;cursor:pointer; background-color:"+color+";margin-left:"+startpers+"%; width:"+endpers+"%' title='"+ tooltip+ "'>"+ pt+"</div>"

        }
      })

      return ret

    }  ,  
    edit_resource(resource) {
      this.$modal.hide('resources_details_modal')
      this.$modal.show(resources_details_modal, {
        resource_id: resource.id,
      }, {
        name: 'resources_details_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '800px'
      })

      this.$emit('close')
    },    
    resource_icon(i){
      var type = "cog"
      switch(parseInt(i)){
        case 1 : type = "truck";
        break
        case 3 : type = "hammer";
        break;
        case 2 : type = "box";
        break;
      }
      return type
    },
  }
}
</script>