<template>
  <b-modal id="BSTimerSelectProjectsID" centered  @show="showMe" @hide="hideMe">
    <template #modal-header>
      <h2 class="modal-title">{{ $t("titles.chooseproject") }}</h2>
    </template>
    <div class="container">
      <div class="row" v-for="(c, idx) in validcontracts" :key="idx">
        <div class="col" style="padding:5px;maring:auto">
          <button class="btn btn-primary" @click="selectcontract(c)">{{ planning_by_id(c.planning_id).project_name }}</button>
        </div>
      </div>
 </div>

  <template #modal-footer>
    <button class="btn btn-secondairy" @click="close_modal">{{ $t( "buttons.close") }}</button>
  </template>

  </b-modal>
</template>


<script>
import { mapGetters,mapActions,  mapState } from 'vuex'

export default {
  name: 'timer-select-project-modal',
  data() {
    return {
      timerunning:"",
      timer:null
    }
  },
  props:{
    validcontracts:Array,
  },
  async mounted() {  

  },

  computed: {
    ...mapGetters([
      'planning_by_id'

    ]),
    ...mapState([
    ]),

  },
  methods: {
    ...mapActions([ 
    ]),
    selectcontract(c){
      this.$emit('choosecontract', c)
    },
    showMe(){
    },
    hideMe(){
      this.close_modal();
    },
    close_modal() {
      this.$bvModal.hide('BSTimerSelectProjectsID')
    },
  },
}    
</script>
