<template>
  <b-modal id="BSNewChatID" centered size="lg" @show="showusers" @hide="clearlocal">
    <template #modal-header>
      <h2 class="modal-title">{{$t('titles.newchat')}}</h2>
    </template>

    <div class="newchatuserlist" >
      <div class="filter-bar">
          <b-col md="4" xl>
            <b-form-group :label="$t('labels.search')">
              <b-form-input ref="searchbox" id="filter-input" v-model="filter" type="search" :placeholder="$t('strings.typetosearch')">
              </b-form-input>
            </b-form-group>
          </b-col>
          </div>

      <table v-if="modalshown">
        <tr v-for="u in zzpsort()"  :key="u.id" @click="userclicked(u.id)">
          <!-- User photo -->
          <td>
            <b-img class="user-photo-holder" :src="profileto(u.id)" fluid></b-img>
          </td>
          <td>
            <span class="username">{{  username_by_id(u.id) }} </span>
          </td>    
        </tr>  
      </table>
    </div>
    

<template #modal-footer>
  <button class="btn btn-secondary" v-if="selectedID" @click="goUser()"><font-awesome-icon icon="comments" />{{ username_by_id(selectedID) }}</button>

  <span v-else>{{$t('labels.newchatfooter')}}</span>
</template>

  </b-modal>
</template>


<script>
import { mapGetters,mapActions,  mapState } from 'vuex'

export default {
  name: 'new-chat-modal',
  data() {
    return {
      selectedID:null,
      filter:"",
      modalshown:false
    }
  },
  computed: {
    ...mapGetters([
    'user_photo_by_id',
    'username_by_id',
    'zzpers'

    ]),
    ...mapState([
      'users',
    ]),
    fields() {
      return [
        { key: 'photo', label: this.$i18n.t('labels.photo'), },
        { key: 'lastname', label: this.$i18n.t('labels.name'), sortable: true, sortDirection: 'desc' },
      ]
    },

  },
  methods: {
    ...mapActions([ 
    ]),
    userclicked(id){
      this.selectedID=id
    },
    goUser(){
      console.log(this.selectedID)
      this.$router.push('/pms/'+this.selectedID)
      this.close_modal()

    },
    clearlocal(){
      this.selectedID = null
      this.filter = ""
    },
    showusers(){
      this.modalshown = true

    },
    zzpsort(){
      let filter = this.filter.toLowerCase()
      return this.zzpers.sort(function compareFunction(x, y) {
          if (x.lastname > y.lastname) {
            return 1;
          }
          if (x.lastname < y.lastname) {
            return -1;
          }
          return 0;}).filter(row=>
            (
        (row.email && row.email.toLowerCase().includes(filter)) ||
        (row.fullname && row.fullname.toLowerCase().includes(filter)) ||
        (row.firstname && row.firstname.toLowerCase().includes(filter)) ||
        (row.firstname && row.lastname && (row.firstname.toLowerCase()+ " "+ row.lastname.toLowerCase()).includes(filter)) ||
        (row.lastname && row.lastname.toLowerCase().includes(filter)) ||
        (row.mobile && row.mobile.includes(filter))||
        (row.companyname && row.companyname.toLowerCase().includes(filter)))
        )
      
    },
    close_modal() {

      this.$bvModal.hide('BSNewChatID')
    },
  },
}    
</script>
