<template>
  <b-modal id="BSSetinvoiceExtraID" centered size="lg" >
    <template #modal-header>
      <h2 class="modal-title">{{ $t("buttons.invoiceextrainfo") }}</h2>
      {{ contract_id }} {{weeknumber}} {{yearnumber}} 
    </template>

    <form class="form">
      <div class="form-row">
        <div class="col-3">
          <label for=""> {{ $t("labels.invoice_extra_description") }}</label>
        </div>
        <div class="col">
          <b-form-input  class="form-control" v-model="description"  />
        </div>
      </div>
      <div class="form-row">
        <div class="col-3">
          <label for=""> {{ $t("labels.invoice_extra_various") }}</label>
        </div>
        <div class="col">
          <b-form-input  class="form-control"  v-model="extra"  />
        </div>
      </div>
    </form>
    <template #modal-footer>
      <div @click="saveData" class="btn btn-primary">
       {{ $t("buttons.saveandclose") }}
      </div>
    </template>
  </b-modal>
</template>


<script>
import { mapState, mapGetters} from 'vuex'
import axios from 'axios'

export default {
  name: 'invoice-data-modal',
  data() {
    return {
      description: null,
      extra: null,
    }
  },
  props: {
    contract_id: Number,
    weeknumber: Number,
    yearnumber: Number
  },
  
  computed: {
    ...mapGetters([
      'contract_by_id'
    ]),

    ...mapState([
      'invoiceextra',
      'facturen'

    ]),
  },
  methods: {


    async saveData(){
     await axios.post('/api/set_invoice_extra',{contract_id:this.contract_id, year:this.yearnumber, week:this.weeknumber, description:this.description, extra:this.extra})
      let sendinvoice = {}
      sendinvoice.id = this.facturen.filter(f=>f.contract_id==this.contract_id && f.week==this.weeknumber)[0].id
      sendinvoice.user_id = this.contract_by_id(this.contract_id).user_id
      sendinvoice.contract_id = this.contract_id
      sendinvoice.week = this.weeknumber
      sendinvoice.year = this.yearnumber
      sendinvoice.force = true
      sendinvoice.hourcode = this.facturen.filter(f=>f.contract_id==this.contract_id && f.week==this.weeknumber)[0].hourcode?parseInt(this.facturen.filter(f=>f.contract_id==this.contract_id && f.week==this.weeknumber)[0].hourcode):null
      sendinvoice.dates = this.workdaysincontractweek(this.contract_id, this.weeknumber)
      await  axios.post('/api/generate_verkoop', sendinvoice)
      this.close_modal()      


    },

    close_modal() {
      this.$bvModal.hide('BSSetinvoiceExtraID')
    },
  },
  watch:{
    weeknumber(){
      let data = this.invoiceextra.filter(i => i.contract_id == this.contract_id && i.week == this.weeknumber && i.year == this.yearnumber)
      if(data.length>0){
        this.description = data[0].description
        this.extra = data[0].extra
      }
    }
  }
}    
</script>
