<template>
  <b-container v-if="current_user.id == '1'">

  <h1>Superadmins</h1>
    <div v-for="wf in superAdmins" :key="'wf'+wf.id" class="col-12">
      <b-form-checkbox  v-model="wf.selected">
            {{wf.name}}
      </b-form-checkbox>
      </div>
      <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="plus" /> {{ $t("buttons.savesettings") }} Superadmins
    </div>     
      <h2>Paginarechten (user_ids)</h2>
      <b-row>
          <b-col md="4" xl>
            <b-form-group :label="$t('buttons.emailsenders')">  
              <b-form-input r v-model="settings.URsenderemail" type="search" >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>   
        <b-row>
          <b-col md="4" xl>
            <b-form-group :label="$t('titles.newsletter')">  
              <b-form-input r v-model="settings.URnewsletter" type="search" >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>   
        <b-row>
          <b-col md="4" xl>
            <b-form-group :label="$t('buttons.appsettings')">  
              <b-form-input r v-model="settings.URapppages" type="search" >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>   
        <b-row>
          <b-col md="4" xl>
            <b-form-group :label="$t('titles.dashboard')">  
              <b-form-input r v-model="settings.URdashboard" type="search" >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>     
        <b-row>
          <b-col md="4" xl>
            <b-form-group :label="$t('titles.schedule')">  
              <b-form-input r v-model="settings.URSchedule" type="search" >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>          
    <!-- Save button -->
    <div @click="save_rights" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="plus" /> {{ $t("buttons.savesettings") }}
    </div>          
  </b-container>
</template>
<script>
import axios from 'axios';
import {  mapState, mapGetters } from 'vuex'


export default {
  name: 'usersscreen',
  data() {
    return {
      superAdmins: [],
    }
  },
  mounted() {
    this.owners.forEach(element => {
      this.superAdmins.push({id:element.id, name:element.fullname, selected:this.isSuperAdmin(element.id)})
    });
  },
  computed: {
    ...mapState([
      'settings',
    ]),    
    ...mapGetters([
      'owners',
    ]),
  },
  methods: {
    save_settings() {

console.log(this.superAdmins.filter(user => user.selected).map(a => a.id));
try {
  axios.patch('/api/settings/',
    {
      superadmins: JSON.stringify(this.superAdmins.filter(user => user.selected).map(a => a.id)),
    }
  )
    .then((response) => {
      console.log(response)
    })
} catch (err) {
  console.log(err)
}
},
save_rights() {

console.log(this.superAdmins.filter(user => user.selected).map(a => a.id));
try {
  axios.patch('/api/settings/',
    {
      URsenderemail:this.settings.URsenderemail,
      URnewsletter:this.settings.URnewsletter,
      URapppages:this.settings.URapppages,
      URdashboard:this.settings.URdashboard,
      URSchedule:this.settings.URSchedule,
    }
  )
    .then((response) => {
      console.log(response)
    })
} catch (err) {
  console.log(err)
}
},

  },

}
</script>