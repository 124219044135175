<template>

  <b-container fluid :style="AppSecondaryColor()" class="apppage">
    <b-button-group vertical>
      <b-button :style="AppPrimaryButton()" active-class="active" @click="$router.go(-1)">
        <font-awesome-icon icon="chevron-left" /> {{ $t("titles.aboutapp") }}
      </b-button>
    </b-button-group>
    <div v-html="settings.aboutapppage"/> 

    <b-button :style="AppPrimaryButton()" variant="primary" style="width:100% !important; margin-bottom: 1rem !important;"  to="/register">{{ $t("titles.register") }} </b-button>
    <b-button :style="AppSecondaryButton()" variant="secondary" style="width:100% !important"  to="/new_project">{{ $t("titles.newproject") }} </b-button>

  </b-container>
</template>
<script>
import {  mapState, mapGetters } from 'vuex'

export default {
  name: 'aboutapp',
  data() {
    return {
    }
  },
  mounted() {

  },
  computed: {
    ...mapGetters([
      'is_logged_in',
    ]),    
    ...mapState([
      'settings',
    ]),

  },
  methods: {
  },
}
</script>
