<template>
  <b-container>
    <b-row>
      <b-col sm="4" md="4">
        <b-form-group :label="$t('labels.search')">
          <b-form-input id="filter-input" v-model="filter" type="search" :placeholder="$t('strings.typetosearch')">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="4" md="4">
        <b-form-group :label="$t('labels.branche')">
          <b-form-select name="branches" class="form-control" v-model="currentbranche">
            <option value="-1"> All</option>
            <option v-for="(branche,idx) in activebranches" :key="idx" v-bind:value="branche.id">
              {{ branche["name"]}}
            </option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col v-if="false" sm="4" md="4">
        <b-form-group :label="$t('labels.distance')">
          <b-form-select name="distance" class="form-control" v-model="currentdistance">
            <option value="-1"> All</option>
            <option value="10"> 10 KM</option>
            <option value="25"> 25 KM</option>
            <option value="50"> 50 KM</option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-table hover :items="zzprnotinproject" :filter-function="filterTable" :fields="fields" :filter="filter" :tbody-tr-class="rowClass">
      <template #cell(photo)="row">
        <img class="profile-photo" :src="profileto(row.item.id)" />
      </template>
      <template #cell(workfields)="data">
        <span v-html="data.value"></span>
      </template>
      <template #cell(actions)="row">
        <a v-if="!row.item.hasBlockage" href="javascript:;" class="action-item" @click="select_user(row.item.id)">
          <font-awesome-icon icon="plus" />
        </a>
        <font-awesome-icon v-else :title="$t('labels.hasblockage')" icon="exclamation" style="color: red;" />
      </template>
      <template #cell(isplanned)="row">
        <font-awesome-icon v-if="row.item.isplanned" icon="question" style="color: orange;" />
        <font-awesome-icon v-else icon="check" style="color: green;" />
      </template>
      <template #cell(hasBlockage)="row">
        <font-awesome-icon v-if="row.item.hasBlockage" :title="$t('labels.hasblockage')" icon="exclamation"
          style="color: red;" />
        <font-awesome-icon v-else icon="check" :title="$t('labels.hasnoblockage')" style="color: green;" />
      </template>
    </b-table>
  </b-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import findLatLong from 'find-lat-lng';

const GOOGLE_API_KEY = 'AIzaSyC7rbXZD31AsZV08O6nQKmuTRNsVf3SsJI'; // (https://developers.google.com/maps/documentation/javascript/get-api-key)
const client = findLatLong(GOOGLE_API_KEY);


export default {
  name: 'projectzzper-overview',
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      sortBy: 'role',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      currentbranche: -1,
      currentrole: -1,
      latlng: [],
      currentdistance: -1

    }
  },
  props: {
    project_id: Number,
  },
  mounted() {
    this.onMounted();
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: this.$i18n.t('labels.name'), formatter: "username_by_id", sortable: true, sortDirection: 'desc' },
        { key: 'phone', label: this.$i18n.t('meta.phone') },
        // { key: 'hasBlockage', label: "" },
        // { key: 'photo', label: this.$i18n.t('labels.photo') },
        // { key: 'workfields', label: this.$i18n.t('labels.workfields'), formatter: "workFields" },
        // { key: 'distance', label: this.$i18n.t('labels.distance'), sortable: true, sortDirection: 'asc' },
        // { key: 'isplanned', label: this.$i18n.t('labels.planning') },
        { key: 'actions', label: this.$i18n.t('labels.actions') },
      ]
    },

    zzprnotinproject() {
        // let users = []
        //let positionProject = this.latlng[0];
        // let enddate = new Date(this.project.enddate)
        // let startdate = new Date(this.project.startdate)
        return  this.zzpers.filter(zzp => {
            let branche = (this.currentbranche == -1 || zzp.branche_id == this.currentbranche || this.checkExtraBranche(zzp,this.currentbranche ))
            let notInProject = this.project.zzprusers.filter(pzzp => 
              pzzp == zzp.id
            )
            let blocking = zzp.blockcompanies.filter(bzzpr =>
              bzzpr.company_id == this.project.company_id
            )
            return branche && notInProject.length == 0 && blocking.length == 0
          }
        )


        // for (var i = 0; i < this.zzpers.length; i++) {
        //   let found = false
        //   for (var j = 0; j < this.project.zzprusers.length; j++) {
        //     if (this.zzpers[i].id == this.project.zzprusers[j]) {
        //       found = true
        //       break;
        //     }
        //   }
        //   if (!found) {
        //     let lastuser = this.zzpers[i]
        //     lastuser.hasBlockage = false
        //     if (this.currentbranche == -1 || this.zzpers[i].branche_id == this.currentbranche) {
        //       // find distance
        //       if (this.currentdistance == -1) {//all distances 
        //         users.push(this.zzpers[i])
        //       } else {
        //         if (this.project.lat != undefined && this.zzpers[i].lat != undefined) {
        //           let dist = this.getDistance(this.project.lat, this.project.lng, this.zzpers[i].lat, this.zzpers[i].lng)
        //           if (dist <= this.currentdistance) {
        //             let lastuser = this.zzpers[i]
        //             lastuser.distance = Math.round(dist) + " KM"
        //             users.push(lastuser)
        //           }
        //         } else {
        //           // distance unknown
        //           let lastuser = this.zzpers[i]
        //           lastuser.distance = "xxx KM"
        //           users.push(this.zzpers[i])
        //         }
        //       }
        //       //planning
        //       for (var p = 0; p < this.planning.length; p++) {
        //         let auser = this.zzpers[i]
        //         let betweendates = false;
        //         if (this.planning[p].user_id == auser.id) {
        //           let userstartdate = new Date(this.planning[p].startdate)
        //           let userenddate = new Date(this.planning[p].enddate)
        //           if ((userstartdate <= enddate && userenddate >= startdate)) {
        //             betweendates = true
        //           }
        //           auser.isplanned = betweendates
        //           break;

        //         }
        //       }
        //       //blocking
        //       for (let index = 0; index < this.zzpers[i].blockcompanies.length; index++) {
        //         if (this.zzpers[i].blockcompanies[index].company_id == this.project.company_id) {
        //           let lastuser = this.zzpers[i]
        //           lastuser.hasBlockage = true
        //         }

        //       }
        //     }
        //   }
        // }
        // return users
    },
    project: {
      get() {
        let proj = this.project_by_id(this.project_id)
        return proj
      },
    },
    ...mapGetters([
      'project_metas',
      'project_by_id',
      'company_by_id',
      'opdrachtgevers',
      'contactpersonen',
      'zzpers',
      'user_by_id',
      'workfield_by_id',
      'user_photo_by_id',
      'username_by_id',
      'activebranches',
      'selectedZZPr'
    ]),
    ...mapState([
      'selectedZZPr',
      'planning'
    ]),
  },
  methods: {
    onMounted() {
      setTimeout(() => {
        let company = this.company_by_id(this.project.company_id)
        this.currentbranche = company.branche_id;
      }, 10)
      //  this.determineLatLng();
    },
    filterTable(row, fil) {
      let filter = fil.toLowerCase()
      if (
        (row.email && row.email.toLowerCase().includes(filter)) ||
        (row.fullname && row.fullname.toLowerCase().includes(filter)) ||
        (row.firstname && row.firstname.toLowerCase().includes(filter)) ||
        (row.firstname && row.lastname && (row.firstname.toLowerCase()+ " "+ row.lastname.toLowerCase()).includes(filter)) ||
        (row.lastname && row.lastname.toLowerCase().includes(filter)) ||
        (row.mobile && row.mobile.includes(filter))) {
        return true;
      } else {
        return false;
      }
    },  
    select_user(user) {
      this.$store.commit('selectedZZPr', user)
    },

    workFields(value) {
      let workfields = "";
      for (var i = 0; i < value.length; i++) {
        if (this.project.workfields.indexOf(value[i]) == -1) {
          let workfield = this.workfield_by_id(value[i])
          workfields += workfield.name + "<br/> "
        } else {
          let workfield = this.workfield_by_id(value[i])
          workfields += "<strong>" + workfield.name + " </strong><br/>"

        }
      }
      workfields += "";
      return workfields
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      var hasworkfield = item.workfields.filter(value => this.project.workfields.includes(value));
      if (hasworkfield.length > 0) {
        return 'status-active'
      } else {
        let company = this.company_by_id(this.project.company_id)
        if (item.branche_id == company.branche_id) return 'status-inactive'
      }
    },
    async determineLatLng() {
      let places = []
      places.push(this.project.location + ", NL");
      for (var i = 0; i < this.zzpers.length; i++) {
        if (this.zzpers[i].city != undefined) {
          places.push(this.zzpers[i].city + ", NL ")
        }
      }
      this.latlng = await client(places, { debug: false });
    },


  }
}
</script>
