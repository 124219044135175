<template>
	<div class="popup">
        <div class="popup-header">
              {{$t('titles.contact')}}  {{ user_set.length }} {{ $t('titles.users') }}
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
          <b-container fluid>        
            <div class="form-row" >
                <div class="col-3">
                    <label for=""> {{$t("labels.message")}}</label>
                </div>  
                <div class="col-8">
                    {{ $t('templates.searchemail') }}            

                    <b-form-textarea  rows="3" v-model="smsmessage"></b-form-textarea>
                </div>               
            </div>             
            <div class="form-row" >
                <div class="col-3">
                    <label for=""> {{$t("labels.messagesms")}}</label>
                </div>  
                <div class="col-8">
                    {{smsmessage.length}} <font-awesome-icon v-if="smsmessage.length>160" color="red" icon="exclamation"/>
                </div>               
            </div>             
        <div class="form-row">
            <div class="col-3">
                <label for=""> {{ $t("labels.sendsmsas") }}</label>
            </div>
            <div class="col">
                <select name="admin" class="form-control admins" v-model="sender" >
                    <option  v-for="(user,idx) in owners"  :key="idx" v-bind:value="user.id">
                    {{ user["firstname"]}} {{ user["middlename"] }} {{ user["lastname"] }} - {{ user["mobile"] }}
                    </option>
                </select>                
            </div>
        </div>
          </b-container>
        </div>
        <div class="popup-footer">
            <div @click="save_and_close" class="btn btn-primary"><font-awesome-icon icon="sms" /> {{ modalValid()  }}</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

// const querystring = require("querystring");

export default {
    name: 'sms_search_modal',
    data() {
        return {
            smsmessage:"",
            sender:null
        }
    },
    props: {
      user_set: Array,
    },
    
    mounted() {
        this.smsmessage = this.message
    },
    computed: {
        ...mapGetters([
            'username_by_id',
            'user_by_id',
            'owners'
            
        ]),        
    },
    methods: {
        modalValid(){
          return this.$i18n.t("buttons.sendmessage")
        },
        async save_and_close() {
          let index = 0;
          var sendto=[];

          while (index<this.user_set.length){
              let message ={}
              message.sms = this.smsmessage.replaceAll(/\[voornaam\]/gi, this.user_set[index].firstname)
              message.sendto = this.user_set[index].mobile
              message.sendfrom  = this.user_by_id(this.sender).mobile
              message.touser  = this.user_set[index].fullname
              sendto.push(message.touser)
              message.fromuser  = this.username_by_id(this.sender)
            //   console.log(message)
  
            try{
                await axios.post('/api/send_sms', message)  .then((response) => {
                    console.log('succes',response)
                    if (response.data==200) {
                      index++
                    }
                })
            } catch(err){
                console.log (err)
                index++

            }  
          }
          // email sender
          let message ={}

            message.email = this.user_by_id(this.sender).email
            message.name = this.username_by_id(this.sender)
            message.message = "<p>"+this.smsmessage+"</p>"
            sendto.forEach(a =>{
                message.message += a+"<br/>"
            })
            message.subject = "SMS verstuurd naar " + sendto.length + " personen"
            message.maindocuments = []
            // console.log("MAIL>", message)
            try{
                axios.post('/api/contact_user', message)  .then((response) => {
                    this.$emit('close')
                        console.log('succes',response)
            })
            } catch(err){
                console.log (err)
            }

          this.$emit('close')
        },
    },
}
</script>