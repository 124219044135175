<template>
	<div class="popup">

        <div class="popup-header">
            <div>
                 {{header}}
            </div>
            <div  class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body" style="overflow:hidden">
          <b-container>
            <span v-html="message"></span>
          </b-container>
        </div>
        <div class="popup-footer">
            <div class="btn btn-primary" @click="$emit('close')">Sluiten</div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'simple-confirm-modal',
    data() {
        return {
          confirmed:false
        }
    },
    props: {
        message: String ,
        header:String
    },
    computed: {

    },
    methods: {

    }
}
</script>