<template>
  <b-container v-if="current_user.id == '1'">

    <h1>License</h1>
    
    <select name="licenses" class="form-control admins" v-model="license">
      <option value="-1">Kies pakket</option>
      <option value="1">Pakket 1: Flex</option>
      <option value="2">Pakket 2: Growth</option>
      <option value="3">Pakket 3: Pro</option>
    </select>     
    <b-row>
      <b-col cols="3">Gebruikskorting</b-col>
      <b-col><b-form-input type="number" step='0.01'  v-model="settings.LicenseDiscount"/></b-col>
    </b-row>    
    <b-row>
      <b-col cols="3"></b-col>
      <b-col><b-form-checkbox  v-model="settings.CountZZPers">Tel ZZPers op factuur</b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3"></b-col>
      <b-col>
        <b-form-checkbox  v-model="settings.CountSigners">Tel Tekenbevoegde op factuur</b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3"></b-col>
      <b-col>
        <b-form-checkbox  v-model="settings.CountModules">Tel Modules op factuur</b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3">Email voor gebruiksfactuur</b-col>
      <b-col>
        <b-form-input type="email" v-model="settings.invoiceEmail"/>
      </b-col>
    </b-row>    
    <h2>Factuuradres informatie afnemer</h2>
    <div id="app">
      <b-form-textarea v-model="settings.adminadres" />
    </div>  
    <h2>Afleveradres informatie afnemer</h2>
    <div id="app">
      <b-form-textarea v-model="settings.adminadresaflever" />
    </div> 
    <h2>Factuur informatieblok afnemer</h2>
    <div id="app">
      <b-form-textarea v-model="settings.invoiceinfo" />
    </div>               
    <h2>Factuur btw verlegd info</h2>
    <div id="app">
      <b-form-input v-model="settings.btwverlegdinfo" />
    </div> 
    <!-- Save button -->
    <div v-if="current_user.id == '1'" @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="plus" /> {{ $t("buttons.savesettings") }}
    </div>          
    </b-container>
</template>
<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import {  mapState, mapGetters } from 'vuex'


export default {
  name: 'licensescreen',
  data() {
    return {
      license:-1,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items:[ 'heading', '|', 'undo', 'redo', '-', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ,'insertTable'],
          shouldNotGroupWhenFull: true
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
          ]
        },
        table: {
            contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        }
      },      
    }
  },
  mounted() {
    this.license = this.settings.license?this.settings.license:-1
  },
  computed: {
    ...mapState([
      'settings',
    ]),    
    ...mapGetters([
      'owners',
    ]),
  },
  methods: {
    save_settings() {

      try {
        axios.patch('/api/settings/',
          {
            license: this.license,
            adminadres :  this.settings.adminadres,
            adminadresaflever: this.settings.adminadresaflever,
            CountZZPers: this.settings.CountZZPers,
            CountSigners: this.settings.CountSigners,
            CountModules:this.settings.CountModules,
            invoiceinfo:this.settings.invoiceinfo,
            LicenseDiscount:this.settings.LicenseDiscount,
            invoiceEmail:this.settings.invoiceEmail,
            btwverlegdinfo:this.settings.btwverlegdinfo

          },
        )
          .then((response) => {
            console.log(response)
          })
      } catch (err) {
        console.log(err)
      }
    },

  },

}
</script>