<template>
  <b-container fluid>
    <div v-if="!is_portal_selected">
      <!-- <div v-for="portal in portals" class="rootview" :key="'portal'+portal.url"  @click="select(portal.url, portal.extraoptions)">
        <h2 class="title">{{ portal.name }}</h2>
        <img class="logo" :src="`${portal.url}`+'logo.svg'"/>
      </div> -->
      <img class="rootlogo" src="../assets/flying-freelancers-logo.svg" alt="Flying Freelancers" id="logo"  />
      <b-form-group :label="$t('titles.rootview')">
        <b-form-input type="text" class="form-control" v-model="portalcode" />
      </b-form-group>
      <b-alert variant="danger" :show="codeFail">{{ $t('validation.codeinvalid') }}</b-alert>

      <b-button class="rootlogin" @click="choosePortal" v-if="portalcode!=''">{{$t('titles.login')}}</b-button>
    </div>
    <div v-else>
      <FrontendLogo />
        <b-button :style="AppRootViewButton()" class= "rootbutton" active-class="active" to="/login">
          <font-awesome-icon icon="sign-in-alt" :style="AppSecondaryColor()" /> <p >{{ $t("titles.login") }}</p>
        </b-button>
        <b-button :style="AppRootViewButton()" class= "rootbutton"  v-if="extraoptions" active-class="active" to="/public_projects">
          <font-awesome-icon icon="list" :style="AppSecondaryColor()" /> <p>{{ $t("titles.projects") }}</p>
        </b-button>
        <b-button :style="AppRootViewButton()" class= "rootbutton" v-if="extraoptions" active-class="active" to="/register">
          <font-awesome-icon icon="user-plus" :style="AppSecondaryColor()"/> <p>{{ $t("titles.register") }}</p>
        </b-button>
        <b-button :style="AppRootViewButton()" class= "rootbutton" v-if="extraoptions" active-class="active" to="/new_project">
          <font-awesome-icon icon="file-plus" :style="AppSecondaryColor()"/> <p>{{ $t("titles.newproject") }}</p>
        </b-button>    
        <b-button :style="AppRootViewButton()" class= "rootbutton" v-if="extraoptions" active-class="active" to="/aboutapp">
          <font-awesome-icon icon="question" :style="AppSecondaryColor()"/> <p>{{ $t("titles.aboutapp") }}</p>
        </b-button>    
        <b-button :style="AppRootViewButton()" class= "rootbutton" active-class="active" to="/aboutcompany">
          <font-awesome-icon icon="question-circle" :style="AppSecondaryColor()"/> <p>{{ settings.abouttitle }}</p>
        </b-button>                             
        <b-button :style="AppRootViewButton()" class= "rootbutton" active-class="active" to="/privacy">
          <font-awesome-icon icon="key" :style="AppSecondaryColor()"/> <p>{{ settings.privacytitle }}</p>
        </b-button>  
        <b-button :style="AppRootViewButton()" class= "rootbutton" active-class="active" to="/terms">
          <font-awesome-icon icon="asterisk" :style="AppSecondaryColor()"/> <p>{{ settings.termstitle }}</p>
        </b-button>            
        <b-button :style="AppRootViewButton()" class= "rootbutton"  href="#" active-class="active" @click="switchPortal()">
          <font-awesome-icon icon="portal-exit" :style="AppSecondaryColor()"/> <p>{{ $t("titles.switchportal") }}</p>
        </b-button>                    
    </div>
  </b-container>
</template>
<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import FrontendLogo from './parts/FrontendLogo.vue'
import { AppIcon } from '@capacitor-community/app-icon';
import { Capacitor } from '@capacitor/core'

require('dotenv').config()


export default {
  name: 'navigation',
  data() {
    return {
      portals:[],
      extraoptions:true,
      portalcode:"",
      codeFail:false,
      currentIcon:null,
      switchIcon:false
    }
  },
  computed: {
    ...mapGetters([    
      'is_logged_in',
      'is_portal_selected',
      'portal_logo'
    ]),
  },   
  async mounted() {
    await fetch(`${'https://admin.flyingfreelancers.nl/api/portals/all'}`)
      .then((response) => response.json())
      .then((data) => this.portals = data)


      console.log(this.portals)

    if (this.buildtarget=="apprelease") {
      // let portal = this.getCookie('PORTAL')
      // console.log(">>KOEKJE created123 PORTAL>>>>",portal)
      if(this.switchIcon){
        this.currentIcon = await (await AppIcon.getName()).value
        console.log(">CURRENT APP ICON",this.currentIcon);
        await AppIcon.reset({suppressNotification:true})
      }
      let portalstorage = await this.getObject("PORTAL")
      console.log("Get storage returned : ", JSON.stringify(portalstorage))

      let portalextra = await this.getObject("PORTALEXTRA")
      console.log("Get storage returned : ", JSON.stringify(portalextra))

      for (const [key, value] of Object.entries(portalstorage)) {
        console.log(">portalstorage>",`${key}: ${value}`);
      }

      if(portalstorage.name!=null&& portalstorage.name!="null"&& portalstorage.name!="") {
        console.log( "SeLECTING PORTAL!!!!!!!!", portalstorage.name)
      await  this.select(portalstorage.name, portalextra.name)
      }
      if(portalextra.name!=null&& portalextra.name!="null"&& portalextra.name!="") {
        console.log( "SeLECTING Extra PORTAL!!!!!!!!", portalextra.name)
        this.extraoptions = portalextra.name == "yes"
      }      

    }
				
		if(this.is_portal_selected) {
      let usernamestorage = await this.getObject("username")
      let passwordstorage = await this.getObject("password")

      console.log(">>KOEKJEROOT USER>>>>",usernamestorage.name)
      console.log(">>KOEKJEROOT PASSWORD>>>>",passwordstorage.name)

      if(usernamestorage.name && passwordstorage.name){
            console.log("LOGIN ROOTVIEW")
            this.login({"username":usernamestorage.name, "password":passwordstorage.name})
        }
		}    
  } ,
  methods:{
    ...mapActions([
            'logout',
            'resetPortal',
            'login'
        ]),    
   
    async choosePortal(){

      let choosenportal = this.portals.filter(p=>p.code.toLowerCase()==this.portalcode.trim().toLowerCase())
      if(choosenportal.length==1){
        this.select(choosenportal[0].url, choosenportal[0].extraoptions)
     } else {
      this.codeFail =true
     }
    },
    select: async function(portal, extraoptions) {
      if (this.buildtarget=="apprelease") {
       axios.defaults.baseURL = portal
      }
      let choosenportal = this.portals.filter(p=>p.url==portal)[0]

      console.log("<>SELECT", JSON.stringify(choosenportal))
      var iconName = 'flyfree'
      switch(choosenportal.code.trim().toLowerCase()){
        case 'zzpr.nl' :
          iconName = 'zzpr'
          break;
        case 'friedvdlaar':
          iconName = 'fvdl'
          break;
        default:
          iconName = 'flyfree'
          break;
      }
      if(this.switchIcon){

        console.log("SET APP ICON select ",iconName)
        const isSupported = await AppIcon.isSupported();
        if(this.currentIcon!=iconName && isSupported){    

          if(Capacitor.getPlatform()=='ios'){
            console.log(' IS iOS')
            await AppIcon.change({name: iconName, suppressNotification: true})
          } else {
            console.log(' IS Android')

            let iconNames= ['zzpr', 'fvdl', 'flyfree']        
            var disableIcons = iconNames.filter(name => name !== iconName)
            await AppIcon.change({name: iconName, suppressNotification: true,disable:disableIcons});

          }
        } 
        this.currentIcon = await (await AppIcon.getName()).value
        console.log(">CURRENT APP ICON",this.currentIcon);
      }
      this.$store.commit('selectedPortal',portal);
      this.$store.commit('setPortalExtra', extraoptions=="yes")
      this.$store.dispatch('get_public_projects')
      this.$store.dispatch('public_settings')
      // document.cookie = 'PORTAL'+'='+portal    
      this.setObject('PORTAL', portal)
      this.setObject('PORTALEXTRA', extraoptions)
      
    },

  },
  components:{FrontendLogo},
  watch: {
    is_logged_in: function () {
      if(this.is_logged_in && this.is_portal_selected) {
        this.$router.push({ path: '/tasks/2' });
      }
    }
  },
}
</script>
<style>
.rootlogo{
  width: 70%;
  margin: auto;
  display: block;
  padding-bottom: 5rem;
  padding-top: 5rem;
}
.rootlogin{
  margin-top: 2rem;
  float: right;
}
</style>