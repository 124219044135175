<template>
    <footer class="row footer" id="footer" v-if="true||(is_logged_in && buildtarget=='apprelease')" >
        <div class="col bottom-nav" :class="{ active: checkActive(0) }" @click="locprofile()"><font-awesome-icon icon="user-circle" /></div>

        <div v-if="current_user.role=='user'" class="col bottom-nav" :class="{ active: checkActive(1) }" @click="onTaskClicked(1)"><font-awesome-icon icon="list" /><div class="counter" :style="AppCounter()">{{ totalPending }}</div></div>
        
        <div class="col bottom-nav" :class="{ active: checkActive(2) }" @click="onTaskClicked(2)"><font-awesome-icon icon="hourglass-half" />
            <div class="counter" v-if="current_user.role=='user'" :style="AppCounter()">{{ totalClosed }}</div>
            <div class="counter" v-else :style="AppCounter()">{{ signerTotalClosed }}</div>
        </div>
        
        <div class="col bottom-nav" :class="{ active: checkActive(3) }" @click="onTaskClicked(3)"><font-awesome-icon icon="check" />
            <div class="counter" v-if="current_user.role=='user'" :style="AppCounter()">{{ totalSigned }}</div>
            <div class="counter" v-else :style="AppCounter()">{{ signerTotalSigned }}</div>
        </div>
    </footer>
</template>
<script>

import { mapGetters, mapState, mapActions } from 'vuex'
require('dotenv').config()

export default {
    name: 'navigation',
    data() {
        return {
            jumpdate: ""
        }
    },
    computed: {
    ...mapGetters([
      'createAny',
      'readAny',
      'updateAny',
      'deleteAny',
      'user_by_id',
      'is_logged_in',

    ]),
    ...mapState([
        'current_user',
        'totalClosed',
        'totalPending',
        'totalSigned',
        'signerTotalClosed',
        'signerTotalSigned'
    ]),
  },
    methods: {
        ...mapActions([
            'logout',
        ]),
        onTaskClicked(id) {
            if(id!= this.$route.params.id){
                this.$router.push({ path: '/tasks/' + id });
            }
        },     
        checkActive(id){
            if(this.$route.name=='User' && id ==0){
                return true
            } else {
                return this.$route.params.id==id    
            }
        },
        locprofile(){
            this.$router.push({ path: '/user/'+this.current_user.id });

        }   
    },
    components: {},

}
</script>