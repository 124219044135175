<template>
  <b-container v-if="createAny('users')" fluid>
    <div class="title-bar">
      <h1>{{ $t("titles.declaraties") }}</h1>
      <div @click="edit_declaratie({ id: 0 })" class="btn btn-primary">
        <font-awesome-icon icon="plus" /> {{ $t("buttons.adddeclaratie") }}
      </div>
    </div>
    <b-table :items=declaraties :fields=fields @row-clicked="toggleActive">
      <template #cell(actions)="row">
        <a href="javascript:;" class="btn btn-link" @click="delete_a_declaratie(row.item.id)"
          :title="$t('labels.deletecategorie')">
          <font-awesome-icon icon="trash" /> {{$t('labels.deletecategorie')}}
        </a>
        <a href="javascript:;" class="btn btn-link" @click="onTagClicked(row.item)" :title="$t('labels.editcategorie')">
          <font-awesome-icon icon="cog" /> {{$t('labels.editcategorie')}}
        </a>
      </template>
      <template #cell(active)="row">
        <font-awesome-icon v-if="row.item.active" icon="check" style="color: green;" />
        <font-awesome-icon v-else icon="times" style="color: red;" />
      </template>
      <template #cell(type)="row">
        <font-awesome-icon v-if="row.item.type=='hours'" icon="clock" />
        <font-awesome-icon v-else-if="row.item.type=='distance'" icon="box-check" />
        <font-awesome-icon v-else icon="euro-sign" />
      </template>
    </b-table>
  </b-container>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import declaraties_details_modal from './modals/DeclaratieModal'
import popup_modal from './modals/PopupModal.vue'

export default {
  name: 'declaratiesscreen',
  data() {
    return {
      selectedID: null
    }
  },
  computed: {
    fields() {
      return [
        { key: 'name', label: this.$i18n.t('labels.name'), sortable: true, sortDirection: 'desc' },
        { key: 'type', label: this.$i18n.t('labels.declaratietype') },
        { key: 'active', label: this.$i18n.t('labels.active') },
        { key: 'actions', label: this.$i18n.t('labels.actions') },
      ]
    },

    ...mapState([
      'declaraties',
    ]),
    ...mapGetters([
      'declaratie_by_id',
      'confirm_modal',
      'createAny'

    ]),
  },
  methods: {
    ...mapActions([
      'delete_declaratie',
      'save_declaratie'
    ]),
    delete_a_declaratie(id) {
      this.selectedID = id
      let message = this.$i18n.t('labels.confirmdeletehead') + this.declaratie_by_id(id).name + " " + this.$i18n.t('labels.confirmdeletetail')
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
        message: message,
      }, {
        name: 'popup_modal',
        draggable: false,
        height: 'auto',
        width: '500px'
      }, {
        'before-close': this.modalClose
      })

      this.$emit('close')

    },
    modalClose() {
      if (this.confirm_modal.id == 1) {
        this.delete_declaratie(this.selectedID);
        for (let i = 0; i < this.declaraties.length; i++) {
          if (this.declaraties[i].id == this.selectedID) {
            this.declaraties.splice(i, 1);
          }
        }
      }
    },
    onTagClicked(row) {
      this.$modal.hide('declaraties_details_modal')
      this.$modal.show(declaraties_details_modal, {
        declaratie_id: row.id,
      }, {
        name: 'declaraties_details_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '800px'
      })

      this.$emit('close')
    },
    toggleActive(row) {
      let brancheclicked = this.declaratie_by_id(row.id)
      if (brancheclicked.active == 1) {
        brancheclicked.active = 0
      } else {
        brancheclicked.active = 1
      }
      this.save_declaratie(row.id)
    },
    edit_declaratie(tag) {
      this.$modal.hide('declaraties_details_modal')
      this.$modal.show(declaraties_details_modal, {
        declaratie_id: tag.id,
      }, {
        name: 'declaraties_details_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '800px'
      })

      this.$emit('close')
    },
  },
}
</script>